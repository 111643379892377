import { Ffr } from '@api';
import theme from '@/utils/styles/Theme';
import { ResultsRads } from './ResultsRads';

const getColorBorder = (ffr: Ffr) => {
    let result = '';
    ffr === Ffr.FfrPlus
        ? (result = 'solid 2px ' + theme.colors.strongdarkred)
        : (result = 'solid 2px ' + theme.colors.selected);
    return result;
};

const getColorBackground = (ffr: Ffr) => {
    let result = '';
    ffr === Ffr.FfrPlus ? (result = theme.colors.darkred) : (result = theme.colors.notSelected);
    return result;
};

// Take CAD-RADS enum as parameter
export const getFfr = (coronary: ResultsRads, type?: string) => {
    let result = '';

    const negative = '≤ 0.8';
    const positive = '> 0.8';

    if (type === 'border') {
        result = getColorBorder(coronary.RadsAnalysisResponse.ffrHardcodeResult);
    } else if (type === 'background') {
        result = getColorBackground(coronary.RadsAnalysisResponse.ffrHardcodeResult);
    } else {
        if (coronary.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrPlus) {
            result = negative;
        } else if (coronary.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrMinus) {
            result = positive;
        } else {
            result = 'No FFR';
        }
    }
    return result;
};

