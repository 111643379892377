import React, { Suspense, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { CameraControls } from './3d/camera-controls';
import { useGLTF } from '@react-three/drei';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';

import { ClassificationResult, Coronary, Ffr } from '@api';
import { ResultsRads } from '@/utils/ResultsRads';
import theme from '@/utils/styles/Theme';

import { t } from '@/i18n.js';
import { Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';
import { getColorByStenosis3D, getHoverColorByStenosis } from '@/utils/ResultsRadsStenosisColors';

//Icons
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import HideSourceIcon from '@mui/icons-material/HideSource';

// 3D Model
import HeartGlb from '@/img/3D/heartcompressed_v4.glb';

//MUI
import { Tooltip } from '@mui/material';
import { isElectronApp } from '@/utils/contexts/SessionContext';

// Draco read .gltf files: replace default https://www.gstatic.com/draco/versioned/decoders/1.4.3/ link with a local link (see webpack)
const pathToDecoders = './utils/3DHeartDecoder/';

//Les valeurs afin de paramétrer les couleurs et affichage
type heartValueProps = {
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    setCurrentCoronary: (value: Coronary) => void;
    medicalProps: MedicalProps;
    isMedicalReportValidationDisplayed: boolean;
    displayMuscle: boolean;
    ffrAvailable: boolean;
};

type GLTFResult = GLTF & {
    nodes: {
        Aorte: THREE.Mesh;
        IVA: THREE.Mesh;
        IVA_Muscle: THREE.Mesh;
        CD: THREE.Mesh;
        CD_Muscle: THREE.Mesh;
        CX: THREE.Mesh;
        CX_Muscle: THREE.Mesh;
    };
    materials: {
        Aorte: THREE.MeshStandardMaterial;
        IVA: THREE.MeshStandardMaterial;
        IVA_Muscle: THREE.MeshStandardMaterial;
        CD: THREE.MeshStandardMaterial;
        CD_Muscle: THREE.MeshStandardMaterial;
        CX: THREE.MeshStandardMaterial;
        CX_Muscle: THREE.MeshStandardMaterial;
    };
};

/** 3D Heart with parameters :
 * - FFR and CAD RADS classification for each coronary
 */
const Heart3DCreation = (
    {
        ladResults,
        cxResults,
        rcaResults,
        setCurrentCoronary,
        medicalProps,
        isMedicalReportValidationDisplayed,
        displayMuscle,
        ffrAvailable
    }: heartValueProps,
    { ...props }
) => {
    const group = useRef();

    // Chargement du modèle 3D
    const { nodes, materials } = useGLTF(HeartGlb, pathToDecoders, false) as unknown as GLTFResult;

    // Cursor showing current color
    const [hovered, setHovered] = useState(false);
    useEffect(() => {
        document.body.style.cursor = hovered ? 'pointer' : 'auto'; // Changement du style du pointer lors du hover
    }, [hovered]);

    // Color - Coronary
    const [colorRCA, setColorRCA] = useState<string>('');
    const [colorRCAHover, setColorRCAHover] = useState<string>('');
    const [colorRCAUnhover, setColorRCAUnhover] = useState<string>('');
    const [colorCX, setColorCX] = useState<string>('');
    const [colorCXHover, setColorCXHover] = useState<string>('');
    const [colorCXUnhover, setColorCXUnhover] = useState<string>('');
    const [colorLAD, setColorLAD] = useState<string>('');
    const [colorLADHover, setColorLADHover] = useState<string>('');
    const [colorLADUnhover, setColorLADUnhover] = useState<string>('');

    // Color - Muscle
    const [colorRCAMuscle, setColorRCAMuscle] = useState<string>('');
    const [colorCXMuscle, setColorCXMuscle] = useState<string>('');
    const [colorLADMuscle, setColorLADMuscle] = useState<string>('');

    /* Choix des couleurs */
    const native = '#FEFEE2';
    const green = theme.colors.green;
    const yellow = theme.colors.yellow;
    const orange = theme.colors.orange;
    const red = theme.colors.red;
    const darkred = theme.colors.darkred;
    const strongdarkred = theme.colors.strongdarkred;
    const grey = theme.colors.grey3d;

    /* On hover */
    const greenHover = theme.colors.greenHover;
    const yellowHover = theme.colors.yellowHover;
    const orangeHover = theme.colors.orangeHover;
    const redHover = theme.colors.redHover;
    const darkredHover = theme.colors.darkredHover;
    const strongdarkredHover = theme.colors.strongdarkredHover;

    /** Choix des couleurs des coronaires */
    const getCoronaryColor = (coronary: ResultsRads) => {
        let color = grey;
        let colorHover = grey;
        if (coronary.RadsAnalysisResponse.classificationResult !== ClassificationResult.NotSet) {
            if (coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads0) {
                color = green;
                colorHover = greenHover;
            } else if (coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads1) {
                color = yellow;
                colorHover = yellowHover;
            } else if (
                coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads12 ||
                coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads2
            ) {
                color = orange;
                colorHover = orangeHover;
            } else if (
                coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads23 ||
                coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads3
            ) {
                color = red;
                colorHover = redHover;
            } else if (
                coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads345 ||
                coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads34 ||
                coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads4
            ) {
                color = darkred;
                colorHover = darkredHover;
            } else if (coronary.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads5) {
                color = strongdarkred;
                colorHover = strongdarkredHover;
            }
        }
        return [color, colorHover];
    };

    /** Choix des couleurs des territoires du muscle */
    const getMuscleColor = (coronary: ResultsRads) => {
        let color = native;
        if (coronary.Images.length && coronary.RadsAnalysisResponse.imageScores.length) {
            if (coronary.RadsAnalysisResponse.ffrHardcodeResult === Ffr.Null) {
                color = native;
            } else if (coronary.RadsAnalysisResponse.ffrHardcodeResult === Ffr.FfrPlus) {
                color = theme.colors.red;
            } else if (coronary.RadsAnalysisResponse.ffrHardcodeResult === Ffr.FfrMinus) {
                color = native;
            }
        }
        return color;
    };

    const applyAiResultsColor = () => {
        // Set color for the RCA coronary mesh based on the AI results
        const colorsRCA = getCoronaryColor(rcaResults);
        setColorRCA(colorsRCA[0]);
        setColorRCAUnhover(colorsRCA[0]);
        setColorRCAHover(colorsRCA[1]);

        // Set color for the RCA coronary mesh based on the AI results
        const colorsLAD = getCoronaryColor(ladResults);
        setColorLAD(colorsLAD[0]);
        setColorLADUnhover(colorsLAD[0]);
        setColorLADHover(colorsLAD[1]);

        // Set color for the RCA coronary mesh based on the AI results
        const colorsCX = getCoronaryColor(cxResults);
        setColorCX(colorsCX[0]);
        setColorCXUnhover(colorsCX[0]);
        setColorCXHover(colorsCX[1]);

        // Muscle Colors
        setColorRCAMuscle(getMuscleColor(rcaResults));
        setColorLADMuscle(getMuscleColor(ladResults));
        setColorCXMuscle(getMuscleColor(cxResults));
    };

    useEffect(() => {
        applyAiResultsColor();
    }, [ladResults, rcaResults, cxResults]);

    useEffect(() => {
        if (isMedicalReportValidationDisplayed) {
            // Set color for the RCA coronary mesh based on the medical report validation
            const colorRCA = getColorByStenosis3D(medicalProps.rcaStenosis, grey);
            setColorRCA(colorRCA);
            setColorRCAUnhover(colorRCA);
            setColorRCAHover(getHoverColorByStenosis(medicalProps.rcaStenosis, grey));

            // Set color for the LAD coronary mesh based on the medical report validation
            const colorLAD = getColorByStenosis3D(medicalProps.ladStenosis, grey);
            setColorLAD(colorLAD);
            setColorLADUnhover(colorLAD);
            setColorLADHover(getHoverColorByStenosis(medicalProps.ladStenosis, grey));

            // Set color for the CX coronary mesh based on the medical report validation
            const colorCX = getColorByStenosis3D(medicalProps.cxStenosis, grey);
            setColorCX(colorCX);
            setColorCXUnhover(colorCX);
            setColorCXHover(getHoverColorByStenosis(medicalProps.cxStenosis, grey));

            // Muscle Colors
            setColorRCAMuscle(getMuscleColor(rcaResults));
            setColorLADMuscle(getMuscleColor(ladResults));
            setColorCXMuscle(getMuscleColor(cxResults));
        } else {
            applyAiResultsColor();
        }
    }, [ladResults, rcaResults, cxResults, medicalProps, isMedicalReportValidationDisplayed]);

    return (
        <group ref={group} {...props} dispose={null} position={[0, 0.5, 0]} rotation={[1.5, 0, -0.2]} scale={1.2}>
            <mesh
                geometry={(nodes.Aorte as THREE.Mesh).geometry}
                material={materials.Aorte}
                material-color={native}
                position={[-0.01, 0.35, 0.55]}
                rotation={[-1.77, -1.29, -0.57]}
                scale={0.03}
            ></mesh>
            <mesh
                geometry={(nodes.CD_Muscle as THREE.Mesh).geometry}
                material={materials.CD_Muscle}
                material-color={colorRCAMuscle}
                position={[-0.01, 0.55, 0.75]}
                rotation={[-1.77, -1.5, -0.57]}
                scale={0.023}
                visible={ffrAvailable && displayMuscle ? true : false}
            >
                <meshPhongMaterial opacity={0.7} transparent />
            </mesh>
            <mesh
                geometry={(nodes.IVA_Muscle as THREE.Mesh).geometry}
                material={materials.IVA_Muscle}
                material-color={colorLADMuscle}
                position={[-0.01, 0.55, 0.75]}
                rotation={[-1.77, -1.5, -0.57]}
                scale={0.023}
                visible={ffrAvailable && displayMuscle ? true : false}
            >
                <meshPhongMaterial opacity={0.7} transparent />
            </mesh>
            <mesh
                geometry={(nodes.CX_Muscle as THREE.Mesh).geometry}
                material={materials.CX_Muscle}
                material-color={colorCXMuscle}
                position={[-0.01, 0.55, 0.75]}
                rotation={[-1.77, -1.5, -0.57]}
                scale={0.023}
                visible={ffrAvailable && displayMuscle ? true : false}
            >
                <meshPhongMaterial opacity={0.7} transparent />
            </mesh>

            {/* Coronary RCA Mesh */}
            <mesh
                geometry={(nodes.CD as THREE.Mesh).geometry}
                material={materials.CD}
                material-color={colorRCA}
                position={[-0.01, 0.35, 0.55]}
                rotation={[-1.77, -1.29, -0.57]}
                scale={0.03}
                onPointerDown={(e) => {
                    e.stopPropagation();
                }}
                onPointerOver={(e) => {
                    e.stopPropagation();
                    if (rcaResults.Images.length > 0 && rcaResults.RadsAnalysisResponse.imageScores.length > 0) {
                        setHovered(true);
                        setColorRCA(colorRCAHover);
                    }
                }}
                onPointerOut={(e) => {
                    e.stopPropagation();
                    if (rcaResults.Images.length > 0 && rcaResults.RadsAnalysisResponse.imageScores.length > 0) {
                        setHovered(false);
                        setColorRCA(colorRCAUnhover);
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (rcaResults.Images.length > 0 && rcaResults.RadsAnalysisResponse.imageScores.length > 0) {
                        setCurrentCoronary(Coronary.RCA);
                    }
                }}
            >
                {!(rcaResults.Images.length && rcaResults.RadsAnalysisResponse.imageScores.length) ? (
                    <meshPhongMaterial color="#FFFFFF" opacity={0.2} transparent />
                ) : (
                    <></>
                )}
            </mesh>
            {/* Coronary LAD Mesh */}
            <mesh
                geometry={(nodes.IVA as THREE.Mesh).geometry}
                material={materials.IVA}
                material-color={colorLAD}
                position={[-0.01, 0.35, 0.55]}
                rotation={[-1.77, -1.29, -0.57]}
                scale={0.03}
                onPointerDown={(e) => {
                    e.stopPropagation();
                }}
                onPointerOver={(e) => {
                    e.stopPropagation();
                    if (ladResults.Images.length > 0) {
                        setHovered(true);
                        setColorLAD(colorLADHover);
                    }
                }}
                onPointerOut={(e) => {
                    e.stopPropagation();
                    if (ladResults.Images.length > 0) {
                        setHovered(false);
                        setColorLAD(colorLADUnhover);
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (ladResults.Images.length > 0) {
                        setCurrentCoronary(Coronary.LAD);
                    }
                }}
            >
                {!ladResults.Images.length ? <meshPhongMaterial color="#FFFFFF" opacity={0.2} transparent /> : <></>}
            </mesh>
            {/* Coronary Cx Mesh */}
            <mesh
                geometry={(nodes.CX as THREE.Mesh).geometry}
                material={materials.CX}
                material-color={colorCX}
                position={[-0.01, 0.35, 0.55]}
                rotation={[-1.77, -1.29, -0.57]}
                scale={0.03}
                onPointerDown={(e) => {
                    e.stopPropagation();
                }}
                onPointerOver={(e) => {
                    e.stopPropagation();
                    if (cxResults.Images.length > 0) {
                        setHovered(true);
                        setColorCX(colorCXHover);
                    }
                }}
                onPointerOut={(e) => {
                    e.stopPropagation();
                    if (cxResults.Images.length > 0) {
                        setHovered(false);
                        setColorCX(colorCXUnhover);
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (cxResults.Images.length > 0) {
                        setCurrentCoronary(Coronary.Cx);
                    }
                }}
            >
                {!cxResults.Images.length ? <meshPhongMaterial color="#FFFFFF" opacity={0.2} transparent /> : <></>}
            </mesh>
        </group>
    );
};

export const Heart3D = ({
    ladResults,
    cxResults,
    rcaResults,
    setCurrentCoronary,
    medicalProps,
    isMedicalReportValidationDisplayed,
    ffrAvailable
}: heartValueProps) => {
    const cameraControls = useRef<CameraControls | null>(null);
    const isHybrid = isElectronApp();

    const [displayMuscle, setDisplayMuscle] = useState<boolean>(true);

    return (
        <SRow>
            <SHeart3D>
                {/* {isHybrid ? (
                    <Canvas gl={{ preserveDrawingBuffer: true }}>
                        <directionalLight intensity={2.1} color={'#FFFFFF'} />
                        <directionalLight intensity={1.9} position={[0.8, 1, 1]} color={'#FFFFFF'} />
                        <directionalLight intensity={1.7} position={[-0.8, -1, -1]} color={'#FFFFFF'} />
                        <directionalLight intensity={1.5} position={[2, 0, 0]} color={'#FFFFFF'} />
                        <directionalLight intensity={1.5} position={[-2, 0, 0]} color={'#FFFFFF'} />

                        <Suspense fallback={null}>
                            <Heart3DCreation
                                ladResults={ladResults}
                                cxResults={cxResults}
                                rcaResults={rcaResults}
                                setCurrentCoronary={setCurrentCoronary}
                                medicalProps={medicalProps}
                                isMedicalReportValidationDisplayed={isMedicalReportValidationDisplayed}
                                displayMuscle={displayMuscle}
                                ffrAvailable={ffrAvailable}
                            />
                        </Suspense>
                        <CameraControls ref={cameraControls} />
                    </Canvas>
                ) : ( */}
                <Canvas gl={{ preserveDrawingBuffer: true }}>
                    <directionalLight intensity={0.9} />
                    <directionalLight intensity={0.7} position={[0.8, 1, 1]} />
                    <directionalLight intensity={0.5} position={[-0.8, -1, -1]} />
                    <directionalLight intensity={0.3} position={[2, 0, 0]} />
                    <directionalLight intensity={0.3} position={[-2, 0, 0]} />
                    <Suspense fallback={null}>
                        <Heart3DCreation
                            ladResults={ladResults}
                            cxResults={cxResults}
                            rcaResults={rcaResults}
                            setCurrentCoronary={setCurrentCoronary}
                            medicalProps={medicalProps}
                            isMedicalReportValidationDisplayed={isMedicalReportValidationDisplayed}
                            displayMuscle={displayMuscle}
                            ffrAvailable={ffrAvailable}
                        />
                    </Suspense>
                    <CameraControls ref={cameraControls} />
                </Canvas>
            </SHeart3D>
            <SContainerOptions>
                <SContainerOptionsItem>
                    <Tooltip title={t('resetCamera') || ''} placement="top">
                        <SReset
                            onClick={() => {
                                cameraControls.current?.reset(true);
                            }}
                        />
                    </Tooltip>
                </SContainerOptionsItem>
                <SContainerOptionsItem>
                    {ffrAvailable && (
                        <Tooltip title={t('hideMuscle') || ''}>
                            <SHide
                                onClick={() => {
                                    setDisplayMuscle(!displayMuscle);
                                }}
                            />
                        </Tooltip>
                    )}
                </SContainerOptionsItem>
            </SContainerOptions>
        </SRow>
    );
};

useGLTF.preload(HeartGlb, pathToDecoders, false);

const SRow = styled.div`
    display: flex;
    flex-direction: column;
`;

const SHeart3D = styled.div`
    display: flex;
    width: 29.5vw;
    height: 80vh;
`;

const SReset = styled(ThreeDRotationIcon)`
    color: ${(props) => props.theme.analysisDetails.color};
    &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.darkblue};
        transform: scale(1.1);
        transition: 0.25s;
    }
`;

const SHide = styled(HideSourceIcon)`
    color: ${(props) => props.theme.analysisDetails.color};
    &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.darkblue};
        transform: scale(1.1);
        transition: 0.25s;
    }
`;

const SContainerOptions = styled.div`
    display: flex;
    flex-direction: column;
    top: 29vh;
    left: -6px;
    bottom: 0;
    position: absolute;
    z-index: 1000;
`;

const SContainerOptionsItem = styled.div`
    display: flex;
    padding: ${(props) => props.theme.getSpacing(1, 0, 1, 0)};
`;

export default Heart3D;

