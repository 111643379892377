import React from 'react';
import styled from 'styled-components';
import { Button, SvgIconProps } from '@mui/material';
import Typography from '../Typography';

type Props = {
    icon: React.ReactElement<SvgIconProps>;
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled: boolean;
};

export const ButtonBlueWithIcon = (props: Props) => {
    const { icon, text, onClick, disabled } = props;

    return (
        <SButton onClick={onClick} disabled={disabled}>
            <SIcon>{icon}</SIcon>
            <Typography>{text}</Typography>
        </SButton>
    );
};

export default ButtonBlueWithIcon;

const SButton = styled(Button)`
    && {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-transform: none;
        border-radius: 10px;
        color: ${(props) => props.theme.colors.white};
        padding: ${(props) => props.theme.getSpacing(1, 1, 1, 1)};
        background-color: ${(props) => props.theme.colors.electricblue};
        transition: 0.2s background-color ease-in-out;
        &&:hover {
            cursor: pointer;
            background-color: ${(props) => props.theme.colors.darkblue};
        }
        &&:disabled {
            color: ${(props) => props.theme.colors.lightgrey};
            background-color: ${(props) => props.theme.colors.darkgrey};
            background: ${(props) => props.theme.colors.darkgrey};
        }
    }
`;

const SIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
`;
