import { ResultsRads } from '@/utils/ResultsRads';
import { t } from '@/i18n.js';

export function getEntropyStr(results: ResultsRads): string {
    let entropy = results.RadsAnalysisResponse.entropy;
    let result = 'Null';
    if (entropy == 0) {
        result = t('analysis.analysisReportComp.coronaryInformation.confidenceLevel.high');
    } else if (entropy == 1) {
        result = t('analysis.analysisReportComp.coronaryInformation.confidenceLevel.medium');
    } else if (entropy == 2) {
        result = t('analysis.analysisReportComp.coronaryInformation.confidenceLevel.low');
    } else if (entropy == 3) {
        result = t('analysis.analysisReportComp.coronaryInformation.confidenceLevel.null');
    }
    return result;
}

