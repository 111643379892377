import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n, { t } from '@/i18n.js';

import { Calcification, ClassificationResult, Ffr, RadsAnalysisResponse } from '@api';

import { ResultsRads } from '@/utils/ResultsRads';
import { getFfr } from '@/utils/FFRHardcode';
import { getEntropyStr } from '@/utils/GetEntropy';
import { Language } from '@/utils/Language';

import { getColorByStenosis, getBackgroundColorByStenosis, getStenosisByRads } from '@/utils/ResultsRadsStenosisColors';
import { Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Session } from '@/utils/contexts/SessionContext';
import { Information } from '@/components/atoms';

type Props = {
    medicalReportId: number;
    medicalProps: MedicalProps;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    /**Indicateur si la fonctionnalité FFR est disponible pour l'utilisateur */
    ffrAvailable: boolean;
    isMedicalReportValidationDisplayed: boolean;
    setIsMedicalReportValidationDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
    currentSessionInCookie: Session;
};

const dangerousRads = [
    ClassificationResult.Rads345,
    ClassificationResult.Rads34,
    ClassificationResult.Rads3,
    ClassificationResult.Rads4,
    ClassificationResult.Rads5
];

export const hasStenosisWarning = (results: ResultsRads): boolean => {
    if (dangerousRads.includes(results.RadsAnalysisResponse.classificationResult)) {
        return true;
    }
    return false;
};

export const hasEntropyWarning = (radsResponse: RadsAnalysisResponse): boolean => {
    if (radsResponse.entropy == 2 || radsResponse.entropy == 3) {
        return true;
    }
    return false;
};

export const hasFfrWarning = (radsResponse: RadsAnalysisResponse): boolean => {
    if (radsResponse.ffrHardcodeResult == Ffr.FfrPlus) {
        return true;
    }
    return false;
};

type IsResultAWarningProps = {
    isWarning: boolean;
};

type StenosisColorProps = {
    color: string;
    backgroundColor: string;
};

export const CoronariesDetails = (props: Props) => {
    const {
        medicalReportId,
        medicalProps,
        ladResults,
        cxResults,
        rcaResults,
        ffrAvailable,
        isMedicalReportValidationDisplayed,
        setIsMedicalReportValidationDisplayed,
        currentSessionInCookie
    } = props;

    const [hasLadResults, setHasLadResults] = useState<boolean>(false);
    const [hasCxResults, setHasCxResults] = useState<boolean>(false);
    const [hasRcaResults, setHasRcaResults] = useState<boolean>(false);

    const [ffrCalculated, setFfrCalculated] = useState<boolean>(false);
    const [entropyLad, setEntropyLad] = useState<string>('');
    const [entropyCx, setEntropyCx] = useState<string>('');
    const [entropyRca, setEntropyRca] = useState<string>('');
    const en = { lng: Language.English };
    const fr = { lng: Language.French };

    const getStenosis = (results: ResultsRads) => {
        let classificationResult = results.RadsAnalysisResponse.classificationResult;
        return getStenosisByRads(classificationResult);
    };

    const getCalcification = (results: ResultsRads) => {
        let result = 'Null';
        let calcification = results.RadsAnalysisResponse.calcificationResult;
        if (calcification === Calcification.Minimal)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.minimal');
        else if (calcification === Calcification.Moderate)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.moderate');
        else if (calcification === Calcification.Severe)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.severe');
        else if (calcification === Calcification.Null)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.null');
        else if (calcification === Calcification.NotSpecified)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.notSpecified');
        return result;
    };

    const getFfrText = (results: ResultsRads) => {
        if (ffrAvailable && ffrCalculated) {
            return getFfr(results);
        }
        return t('ffrUnavailableShort');
    };

    useEffect(() => {
        if (
            ladResults.RadsAnalysisResponse.ffrResult !== Ffr.Null ||
            cxResults.RadsAnalysisResponse.ffrResult !== Ffr.Null ||
            rcaResults.RadsAnalysisResponse.ffrResult !== Ffr.Null
        ) {
            setFfrCalculated(true);
        } else {
            setFfrCalculated(false);
        }
    }, [ladResults, cxResults, rcaResults]);

    useEffect(() => {
        // ClassificationResult.Error when there is no coronary result in Coronary_xx
        if (ladResults.Images.length > 0 && ladResults.RadsAnalysisResponse.imageScores.length != 0)
            setHasLadResults(true);
        else setHasLadResults(false);
        setEntropyLad(getEntropyStr(ladResults));
    }, [ladResults, i18n.language]);

    useEffect(() => {
        // ClassificationResult.Error when there is no coronary result in Coronary_xx
        if (cxResults.Images.length > 0 && cxResults.RadsAnalysisResponse.imageScores.length != 0)
            setHasCxResults(true);
        else setHasCxResults(false);
        setEntropyCx(getEntropyStr(cxResults));
    }, [cxResults, i18n.language]);

    useEffect(() => {
        // ClassificationResult.Error when there is no coronary result in Coronary_xx
        if (rcaResults.Images.length > 0 && rcaResults.RadsAnalysisResponse.imageScores.length != 0)
            setHasRcaResults(true);
        else setHasRcaResults(false);
        setEntropyRca(getEntropyStr(rcaResults));
    }, [rcaResults, i18n.language]);

    return (
        <SContainer>
            <SCard>
                <STableContainer>
                    <STableColumnFlex>
                        <STableHeaderSwitch>
                            {medicalReportId > 0 ? (
                                <>
                                    <FormGroup style={{ padding: 0 }}>
                                        <div
                                            style={{
                                                margin: 0,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <SGrayIcon>
                                                <SLabelAI>{t('analysis.analysisReportComp.ai')}</SLabelAI>
                                            </SGrayIcon>
                                            <FormControlLabel
                                                style={{ margin: 0 }}
                                                control={<IaDrSwitch sx={{ m: 1 }} />}
                                                checked={isMedicalReportValidationDisplayed}
                                                onChange={() => {
                                                    setIsMedicalReportValidationDisplayed(
                                                        !isMedicalReportValidationDisplayed
                                                    );
                                                }}
                                                label=""
                                            />
                                            <SBlueIcon>
                                                <SHowToRegIcon />
                                            </SBlueIcon>
                                        </div>
                                    </FormGroup>
                                </>
                            ) : (
                                <>{t('analysis.analysisReportComp.aiResults')}</>
                            )}
                        </STableHeaderSwitch>
                        <STableColumnRow>
                            {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                <>
                                    <STableHeaderBlue>
                                        {t('analysis.analysisReportComp.coronaryInformation.stenosisClassification')}
                                    </STableHeaderBlue>
                                    <STableHeader>
                                        {t('analysis.analysisReportComp.coronaryInformation.confidenceLevel.title')}
                                        <Information
                                            message={t(
                                                'analysis.analysisReportComp.coronaryInformation.confidenceLevel.description'
                                            )}
                                        />
                                    </STableHeader>
                                    <STableHeader>
                                        {t('analysis.analysisReportComp.coronaryInformation.ffrPrediction')}
                                    </STableHeader>
                                    <STableHeaderBlue>
                                        {t('analysis.analysisReportComp.coronaryInformation.calcificationResult')}
                                    </STableHeaderBlue>
                                </>
                            ) : (
                                <>
                                    <STableHeader>
                                        {t('analysis.analysisReportComp.coronaryInformation.stenosisClassification')}
                                    </STableHeader>
                                    <STableHeader>
                                        {t('analysis.analysisReportComp.coronaryInformation.confidenceLevel.title')}
                                        <Information
                                            message={t(
                                                'analysis.analysisReportComp.coronaryInformation.confidenceLevel.description'
                                            )}
                                        />
                                    </STableHeader>
                                    <STableHeader>
                                        {t('analysis.analysisReportComp.coronaryInformation.ffrPrediction')}
                                    </STableHeader>
                                    <STableHeader>
                                        {t('analysis.analysisReportComp.coronaryInformation.calcificationResult')}
                                    </STableHeader>
                                </>
                            )}
                        </STableColumnRow>
                    </STableColumnFlex>

                    {hasRcaResults && (
                        <STableColumn>
                            <STableHeaderTop>{t('RCA')}</STableHeaderTop>
                            <STableColumnRow>
                                <div>
                                    {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                        <>
                                            <SResultStenosis
                                                color={getColorByStenosis(medicalProps.rcaStenosis)}
                                                backgroundColor={getBackgroundColorByStenosis(medicalProps.rcaStenosis)}
                                            >
                                                {medicalProps.rcaStenosis || '-'}
                                            </SResultStenosis>
                                        </>
                                    ) : (
                                        <SResultStenosis
                                            color={getColorByStenosis(getStenosis(rcaResults))}
                                            backgroundColor={getBackgroundColorByStenosis(getStenosis(rcaResults))}
                                        >
                                            {getStenosis(rcaResults)}
                                        </SResultStenosis>
                                    )}
                                </div>
                                <div>
                                    <SResultWithBackground
                                        isWarning={hasEntropyWarning(rcaResults.RadsAnalysisResponse)}
                                    >
                                        {entropyRca}
                                    </SResultWithBackground>
                                </div>
                                <div>
                                    <SResultWithBackground isWarning={hasFfrWarning(rcaResults.RadsAnalysisResponse)}>
                                        {getFfrText(rcaResults)}
                                    </SResultWithBackground>
                                </div>
                                <div>
                                    {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                        <SResultWithBackground isWarning={false}>
                                            {medicalProps.rcaCalcification || '-'}
                                        </SResultWithBackground>
                                    ) : (
                                        <SResultWithBackground isWarning={false}>
                                            {getCalcification(rcaResults)}
                                        </SResultWithBackground>
                                    )}
                                </div>
                            </STableColumnRow>
                        </STableColumn>
                    )}
                    {hasLadResults && (
                        <STableColumn>
                            <STableHeaderTop>{t('LAD')}</STableHeaderTop>
                            <STableColumnRow>
                                <div>
                                    {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                        <>
                                            <SResultStenosis
                                                color={getColorByStenosis(medicalProps.ladStenosis)}
                                                backgroundColor={getBackgroundColorByStenosis(medicalProps.ladStenosis)}
                                            >
                                                {medicalProps.ladStenosis || '-'}
                                            </SResultStenosis>
                                        </>
                                    ) : (
                                        <SResultStenosis
                                            color={getColorByStenosis(getStenosis(ladResults))}
                                            backgroundColor={getBackgroundColorByStenosis(getStenosis(ladResults))}
                                        >
                                            {getStenosis(ladResults)}
                                        </SResultStenosis>
                                    )}
                                </div>
                                <div>
                                    <SResultWithBackground
                                        isWarning={hasEntropyWarning(ladResults.RadsAnalysisResponse)}
                                    >
                                        {entropyLad}
                                    </SResultWithBackground>
                                </div>
                                <div>
                                    <SResultWithBackground isWarning={hasFfrWarning(ladResults.RadsAnalysisResponse)}>
                                        {getFfrText(ladResults)}
                                    </SResultWithBackground>
                                </div>
                                <div>
                                    {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                        <SResultWithBackground isWarning={false}>
                                            {medicalProps.ladCalcification || '-'}
                                        </SResultWithBackground>
                                    ) : (
                                        <SResultWithBackground isWarning={false}>
                                            {getCalcification(ladResults)}
                                        </SResultWithBackground>
                                    )}
                                </div>
                            </STableColumnRow>
                        </STableColumn>
                    )}
                    {hasCxResults && (
                        <STableColumn>
                            <STableHeaderTop>{t('Cx')}</STableHeaderTop>
                            <STableColumnRow>
                                <div>
                                    {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                        <>
                                            <SResultStenosis
                                                color={getColorByStenosis(medicalProps.cxStenosis)}
                                                backgroundColor={getBackgroundColorByStenosis(medicalProps.cxStenosis)}
                                            >
                                                {medicalProps.cxStenosis || '-'}
                                            </SResultStenosis>
                                        </>
                                    ) : (
                                        <SResultStenosis
                                            color={getColorByStenosis(getStenosis(cxResults))}
                                            backgroundColor={getBackgroundColorByStenosis(getStenosis(cxResults))}
                                        >
                                            {getStenosis(cxResults)}
                                        </SResultStenosis>
                                    )}
                                </div>
                                <div>
                                    <SResultWithBackground
                                        isWarning={hasEntropyWarning(cxResults.RadsAnalysisResponse)}
                                    >
                                        {entropyCx}
                                    </SResultWithBackground>
                                </div>
                                <div>
                                    <SResultWithBackground isWarning={hasFfrWarning(cxResults.RadsAnalysisResponse)}>
                                        {getFfrText(cxResults)}
                                    </SResultWithBackground>
                                </div>
                                <div>
                                    {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                        <SResultWithBackground isWarning={false}>
                                            {medicalProps.cxCalcification || '-'}
                                        </SResultWithBackground>
                                    ) : (
                                        <SResultWithBackground isWarning={false}>
                                            {getCalcification(cxResults)}
                                        </SResultWithBackground>
                                    )}
                                </div>
                            </STableColumnRow>
                        </STableColumn>
                    )}
                </STableContainer>
            </SCard>
        </SContainer>
    );
};

export default CoronariesDetails;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    height: 100%;
    width: 100%;
    max-width: 30vw;
    overflow: auto;
`;

const SCard = styled.div`
    background-color: ${(props) => props.theme.analysisDetails.tableResults.background};
    border-radius: 10px;
`;

const STableContainer = styled.div`
    display: flex;
    cursor: default;
`;

const STableColumn = styled.div`
    flex: 1 1 0;
    display: inline-grid;
    background-color: ${(props) => props.theme.analysisDetails.tableResults.background};
    border-radius: 10px;
    & > div {
        display: inline-grid;
        flex-direction: column;
        align-items: center;
    }
    & > div > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 50px;
    }
    & > div > div:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.analysisDetails.tableResults.borderColor};
    }
`;

const STableColumnFlex = styled.div`
    flex: 1.4 1 0;
    display: inline-grid;
    & > div {
        display: inline-grid;
        flex-direction: column;
    }
    & > div > div:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.analysisDetails.tableResults.borderColor};
    }
`;

const STableColumnRow = styled.div`
    display: inline-block;
`;

const STableHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding-left: ${(props) => props.theme.getSpacing(4)};
    color: ${(props) => props.theme.analysisDetails.tableResults.title};
`;

const STableHeaderBlue = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding-left: ${(props) => props.theme.getSpacing(4)};
    color: ${(props) => props.theme.analysisDetails.tableResults.title};
`;

const STableHeaderSwitch = styled.div`
    height: 50px;
    border-bottom: 1px solid ${(props) => props.theme.analysisDetails.tableResults.borderColor};
    align-items: center;
    font-size: 14px;
    padding-left: 8px;
    color: ${(props) => props.theme.analysisDetails.tableResults.title}88;
    & > label {
        width: fit-content;
    }
`;

const SResultWithBackground = styled.div<IsResultAWarningProps>`
    padding: 6px 9px 6px 9px;
    margin: 3px;
    border-radius: 10px;
    color: ${(propsWarning: IsResultAWarningProps) =>
        propsWarning.isWarning == true
            ? (props) => props.theme.analysisDetails.tableResults.warning.color
            : (props) => props.theme.analysisDetails.tableResults.content};
    background-color: ${(propsWarning: IsResultAWarningProps) =>
        propsWarning.isWarning == true
            ? (props) => props.theme.analysisDetails.tableResults.warning.background
            : (props) => props.theme.analysisDetails.tableResults.backgroundContent};
`;

const SResultStenosis = styled.div<StenosisColorProps>`
    padding: 6px 9px 6px 9px;
    margin: 3px;
    border-radius: 10px;
    color: ${(propsColor: StenosisColorProps) =>
        propsColor.color != ''
            ? (props) => propsColor.color
            : (props) => props.theme.analysisDetails.tableResults.content};
    background: ${(propsColor: StenosisColorProps) =>
        propsColor.backgroundColor != ''
            ? (props) => propsColor.backgroundColor
            : (props) => props.theme.analysisDetails.tableResults.backgroundContent};
`;

const STableHeaderTop = styled.div`
    padding-left: 3px;
    height: 50px;
    border-bottom: 1px solid ${(props) => props.theme.analysisDetails.tableResults.borderColor};
    color: ${(props) => props.theme.analysisDetails.tableResults.title};
`;

const SBlueIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    margin-right: 5px;
    background: ${(props) => props.theme.colors.blue};
    color: white;
`;

const SHowToRegIcon = styled(HowToRegIcon)`
    font-size: 1.1rem !important;
`;

const SGrayIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19px;
    height: 19px;
    border-radius: 30px;
    background-color: ${(props) => props.theme.analysisDetails.tableResults.switchMode.aiBackground};
    border: 1px solid #959595;
    color: white;
`;

const SLabelAI = styled.div`
    display: flex;
    justify-content: center;
    align-items: inherit;
    font-size: 0.72rem !important;
`;

const IaDrSwitch = styled(Switch)(({ theme }) => ({
    width: '52px !important',
    height: '26px !important',
    padding: '0 !important',
    margin: '5px !important',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '170ms',
        '&.Mui-checked': {
            transform: 'translateX(26px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.analysisDetails.tableResults.switchMode.backgroundSwitchChecked,
                opacity: 1,
                border: 0
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.analysisDetails.tableResults.switchMode.backgroundSwitchNotChecked,
        opacity: 1
    }
}));

