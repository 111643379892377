import { File } from '@/components/molecules';

export const filterImages = (allFiles: File[], coronaryFiles: File[], is27: boolean, indexFoundForCoronary: number) => {
    if (is27) {
        return reOrderImagesWithAnalysisResult(allFiles, indexFoundForCoronary);
    } else {
        return coronaryFiles;
    }
};

const reOrderImagesWithAnalysisResult = (images: File[], indexFoundForCoronary: number) => {
    let res: File[] = [];
    switch (indexFoundForCoronary) {
        case 0:
            res = images.filter((_, index) => index < 9).flat();
            return res;
        case 1:
            res = images.filter((_, index) => 9 <= index && index < 18).flat();
            return res;
        case 2:
            res = images.filter((_, index) => 18 <= index && index < 27).flat();
            return res;
        default:
            return res;
    }
};
