import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Button, Typography } from '@/components/atoms';

import { t } from '@/i18n.js';

import LogoIcon from '@/img/logo_Spimed_4_Total.png';

export const ResetPasswordValidation = () => {
    const currentHistory = useHistory();

    return (
        <SContainer>
            <Typography title variant="h1">
                {t('resetPassword.titleValidation')}
            </Typography>
            <STypographyThin paragraph variant="subtitle2">
                <Simg src={LogoIcon} />
                <br />
                <br />
                <STypographyBold>{t('resetPassword.validationReset')}</STypographyBold>
                <br />
                <br />
            </STypographyThin>
            <SButtonDiv>
                <Button onClick={() => currentHistory.push('/login')}> {t('resetPassword.redirection')}</Button>
            </SButtonDiv>
        </SContainer>
    );
};

export default ResetPasswordValidation;

const SContainer = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    padding: ${(props) => props.theme.getSpacing(10, 10, 10, 10)};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 20px 20px 60px #f0f0f0, -20px -20px 60px #ffffff;
    margin-top: ${(props) => props.theme.getSpacing(4)};
`;

const STypographyThin = styled(Typography)`
    && {
        font-weight: 100;
        text-align: center;
    }
`;

const STypographyBold = styled(Typography)`
    && {
        font-weight: 500;
        text-align: center;
    }
`;

const Simg = styled.img`
    width: 50%;
`;

const SButtonDiv = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
`;

