import React, { useState } from 'react';
import styled from 'styled-components';
import { Session } from '@/utils/contexts/SessionContext';
import { t } from '@/i18n.js';
import { TextField, Select, FormControl, MenuItem } from '@mui/material';
import { ButtonBlue, Typography, CircularProgress } from '@/components/atoms';
import { useScreenshotContext } from '@/utils/contexts/ScreenshotContext';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { SendClient } from '@/services/BugsService';
import { useHistory } from 'react-router';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';

type Props = {
    currentSessionInCookie: Session;
};

export const ReportBugForm = (props: Props) => {
    const { currentSessionInCookie } = props;

    const history = useHistory();

    const [themeContext, updateTheme] = useThemeContext();
    const [screenshotContext] = useScreenshotContext();
    const [snackbarContext, updateSnackbar] = useSnackbarContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<{ isSuccess: boolean; message: string }>({ isSuccess: false, message: '' });

    const [classificationError, setClassificationError] = useState<{ isClassificationError: boolean; message: string }>(
        { isClassificationError: false, message: '' }
    );
    const [commentError, setCommentError] = useState<{ isCommentError: boolean; message: string }>({
        isCommentError: false,
        message: ''
    });

    const [isClassificationOpened, setIsClassificationOpened] = useState<boolean>(false);

    const [classification, setClassification] = React.useState('');
    const [classificationOther, setClassificationOther] = React.useState('');
    const [comment, setComment] = React.useState('');
    let image = screenshotContext.screenString;

    const other = 'Other';

    const isFormDisabled = () => {
        return (
            image === '' ||
            classification === '' ||
            (classification === other && classificationOther === '') ||
            comment === '' ||
            (classification === other && classificationError.isClassificationError) ||
            commentError.isCommentError
        );
    };

    const handleChangeClassification = (event: { target: { value: string } }) => {
        setClassification(event.target.value);
        if (event.target.value == other) {
            setIsClassificationOpened(true);
        } else {
            setIsClassificationOpened(false);
        }
    };
    const handleChangeClassificationOther = (event: { target: { value: string } }) => {
        if (event.target.value.length > 200) {
            setClassificationError({
                isClassificationError: true,
                message: t('bug.message.error.classificationOther')
            });
        } else {
            setClassificationError({ isClassificationError: false, message: '' });
        }
        setClassificationOther(event.target.value);
    };

    const handleChangeComment = (event: { target: { value: string } }) => {
        if (event.target.value.length > 500) {
            setCommentError({ isCommentError: true, message: t('bug.message.error.comment') });
        } else {
            setCommentError({ isCommentError: false, message: '' });
        }
        setComment(event.target.value);
    };

    const handleClick = () => {
        if (loading || isFormDisabled()) {
            return;
        }

        setLoading(true);

        var finalClassification = '';
        if (classification == other && classificationOther == '') {
            setSuccess({ isSuccess: false, message: t('bug.message.error.missingClassification') });
            return;
        }
        if (classification == other && classificationOther != '') {
            finalClassification = classificationOther;
        } else if (classification != other) {
            finalClassification = classification;
        }
        const sendJira = new SendClient(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );

        var id_email_name =
            'User ID: ' +
            currentSessionInCookie.authResponse.id +
            ' \n  ' +
            'Email: ' +
            currentSessionInCookie.authResponse.email +
            ' \n  ' +
            'Name: ' +
            currentSessionInCookie.authResponse.firstName +
            ' ' +
            currentSessionInCookie.authResponse.lastName;
        var commentWithEmailName = id_email_name + '\n \n' + comment;

        sendJira
            .send({
                sessionContext: currentSessionInCookie,
                screenshotContext,
                finalClassification,
                comment: commentWithEmailName
            })
            .then((response) => {
                setLoading(false);
                if (response === 500 || response === 422 || response === 400) {
                    setSuccess({ isSuccess: false, message: t('errorOccured') });
                } else {
                    //Will open the snackbar in navBarLeft component
                    updateSnackbar({
                        snackString: t('bug.message.success')
                    });
                    history.goBack();
                }
            })
            .catch((err) => {
                setLoading(false);
                setSuccess({ isSuccess: false, message: t('errorOccured') });
            });
    };

    return (
        <>
            {image !== '' ? (
                <FormControl>
                    <SContainer>
                        <SRowLarge>
                            <Typography title="true" variant="h3">
                                {t('bug.image')}
                            </Typography>
                            <SImg src={image} alt="DoctorImage" />
                        </SRowLarge>

                        <SRow>
                            <Typography title="true" variant="h3">
                                {t('bug.classification.title')}
                            </Typography>
                            <Select
                                labelId="classification-label"
                                id="classification"
                                value={classification}
                                label="Classification"
                                onChange={handleChangeClassification}
                                sx={{
                                    color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: themeContext.isLightMode
                                            ? lightTheme.page.text
                                            : darkTheme.page.text
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: themeContext.isLightMode
                                            ? lightTheme.page.text
                                            : darkTheme.page.text
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: themeContext.isLightMode
                                            ? lightTheme.page.text
                                            : darkTheme.page.text
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: `${
                                            themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        } !important`
                                    }
                                }}
                            >
                                <MenuItem value="RADS">{t('bug.classification.rads')}</MenuItem>
                                <MenuItem value="Interface">{t('bug.classification.interface')}</MenuItem>
                                <MenuItem value="FFR">{t('bug.classification.ffr')}</MenuItem>
                                <MenuItem value="Medical report">{t('bug.classification.medicalReport')}</MenuItem>
                                <MenuItem value="Other">{t('bug.classification.other')}</MenuItem>
                            </Select>
                            {isClassificationOpened ? (
                                <TextField
                                    id="classification-other"
                                    label={t('bug.classification.fieldOther')}
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={handleChangeClassificationOther}
                                    value={classificationOther}
                                    inputProps={{
                                        style: {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {classificationError.message && (
                                <STypography red variant="h4">
                                    {classificationError.message}
                                </STypography>
                            )}
                        </SRow>

                        <SRow>
                            <Typography title="true" variant="h3">
                                {t('bug.comment')}
                            </Typography>
                            <TextField
                                id="comment"
                                value={comment}
                                variant="standard"
                                multiline
                                maxRows={4}
                                onChange={handleChangeComment}
                                sx={{
                                    color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                    '.MuiSvgIcon-root ': {
                                        fill: `${
                                            themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        } !important`
                                    },
                                    '& label.Mui-focused': {
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                    },
                                    '& .MuiInput-underline:after': {
                                        borderBottomColor: themeContext.isLightMode
                                            ? lightTheme.page.text
                                            : darkTheme.page.text
                                    },
                                    '& .MuiFilledInput-underline:after': {
                                        borderBottomColor: themeContext.isLightMode
                                            ? lightTheme.page.text
                                            : darkTheme.page.text
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        }
                                    }
                                }}
                                inputProps={{
                                    style: {
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                    }
                                }}
                            />
                            {commentError.message && (
                                <STypography red variant="h4">
                                    {commentError.message}
                                </STypography>
                            )}
                        </SRow>

                        <SBottomContainer>
                            <ButtonBlue disabled={isFormDisabled()} onClick={handleClick}>
                                {!loading ? (
                                    <Typography style={{ pointerEvents: isFormDisabled() ?? 'none' }} variant="button">
                                        {t('bug.sendForm')}
                                    </Typography>
                                ) : (
                                    <CircularProgress />
                                )}
                            </ButtonBlue>
                            {success.message && (
                                <STypography red={!success.isSuccess} blue={success.isSuccess} variant="h3">
                                    {success.message}
                                </STypography>
                            )}
                        </SBottomContainer>
                    </SContainer>
                </FormControl>
            ) : (
                <div>{t('bug.howToTakeScreen')}</div>
            )}
        </>
    );
};

export default ReportBugForm;

const SContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const SRow = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.getSpacing(2)};
    width: 50%;
`;

const SRowLarge = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.getSpacing(2)};
    width: 90%;
`;

const SImg = styled.img`
    width: 100%;
    margin-top: 15px;
`;

const SBottomContainer = styled.div`
    margin: ${(props) => props.theme.getSpacing(10, 2, 4, 2)};
    width: 50%;
`;

const STypography = styled(Typography)`
    && {
        padding-top: ${(props) => props.theme.getSpacing(2)};
    }
`;

