import React from 'react';
import styled from 'styled-components';
import i18n from '@/i18n.js';

import { Typography } from '@/components/atoms';
import TermsText from '@/utils/TermsText';
import { Language } from '@/utils/Language';

export const Terms = () => {
    return (
        <SContainer>
            <SItem>
                {i18n.language === Language.English && (
                    <Typography title variant="h1">
                        Legal Information and Terms and Conditions of Use
                    </Typography>
                )}
                {i18n.language === Language.French && (
                    <Typography title variant="h1">
                        Conditions Générales d'Utilisation et Mentions Légales
                    </Typography>
                )}
            </SItem>
            {TermsText({ isFrench: i18n.language === Language.French })}
        </SContainer>
    );
};

export default Terms;

const SContainer = styled.div`
    display: flex;
    width: 70vw;
    padding: ${(props) => props.theme.getSpacing(10)};
    color: ${(props) => props.theme.page.text};
    background-color: ${(props) => props.theme.page.background};
    margin-top: ${(props) => props.theme.getSpacing(4)};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    flex-direction: column;
    overflow-y: auto;
`;

const SItem = styled.div`
    width: 80%;
`;

