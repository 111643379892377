import React, { useEffect, useState } from 'react';

// Translation
import i18n, { t } from '@/i18n.js';

import styled from 'styled-components';

//MaterialUI
import { Tooltip } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { Calcification, ClassificationResult, IStoredAnalysis, Ffr, Coronary } from '@api';
import { ResultsRads } from '@/utils/ResultsRads';
import { getFfr } from '@/utils/FFRHardcode';
import { formatBarData } from '@/utils/Graphs';
import { getEntropyStr } from '@/utils/GetEntropy';
import { getBackgroundColorByRads, getColorByRadsBis } from '@/utils/ResultsRadsStenosisColors';
import { BarChartSVG, Information } from '@/components/atoms';
import { File } from '@/components/molecules';
import { stenosisClassification } from '@/components/organisms/AnalysisReportCompImages';
import { barChartIndexBy, chartKeys, colors } from '../molecules/DialogAnalysis';
import { hasEntropyWarning, hasFfrWarning } from '@/components/molecules/CoronariesDetails';

type Display = {
    display: string;
};

type StenosisColorProps = {
    color: string;
    backgroundColor: string;
};

type ImageInformation = {
    id: string;
    title: string;
    value: string;
    tooltip: string;
    color: string;
};

type Props = {
    storedAnalysis: IStoredAnalysis;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    currentCoronary: Coronary;
    ffrAvailable: boolean;
    selectedImage: File;
};

type ImageWithGraphs = {
    coronaryResults: ResultsRads;
    coronaryName: string;
    barGraph: JSX.Element;
};
const initialImage = {
    base64: '',
    fileName: '',
    data: ''
};

export const CoronaryImageDetails = (props: Props) => {
    const { storedAnalysis, ladResults, cxResults, rcaResults, currentCoronary, ffrAvailable, selectedImage } = props;

    const [indexCurrentImage, setIndexCurrentImage] = useState<number>(0);
    const [currentCoronaryName, setCurrentCoronaryName] = useState<string>('');
    const [scoreImage, setScoreImage] = useState<string>('');
    const [image, setImage] = useState<File>(initialImage);
    /* Use state pour l'ensemble des scores d'une image */
    const [rad0Score, setRad0Score] = useState<string>('');
    const [rad1Score, setRad1Score] = useState<string>('');
    const [rad2Score, setRad2Score] = useState<string>('');
    const [rad3Score, setRad3Score] = useState<string>('');
    const [rad4Score, setRad4Score] = useState<string>('');
    const [rad5Score, setRad5Score] = useState<string>('');

    const [imageWithGraphs, setImageWithGraphs] = useState<Array<ImageWithGraphs>>([]);
    /** On actualise lors de la présentation des images d'une coronaire l'index de la première image visualisée */
    useEffect(() => {
        switch (currentCoronary) {
            case Coronary.LAD:
                setCurrentCoronaryName(t('LAD'));
                break;
            case Coronary.Cx:
                setCurrentCoronaryName(t('CX'));
                break;
            case Coronary.RCA:
                setCurrentCoronaryName(t('RCA'));
                break;
            default:
                break;
        }
    }, [currentCoronary, storedAnalysis]);

    useEffect(() => {
        if (selectedImage.base64 != '' && selectedImage.fileName != '') {
            switch (currentCoronary) {
                case Coronary.LAD:
                    setIndexCurrentImage(
                        ladResults.Images.findIndex((item) => item.fileName === selectedImage.fileName)
                    );
                    setImage(selectedImage);
                    findScoreImage(selectedImage, ladResults);
                    findTotalScoreImage(selectedImage, ladResults);
                    // findPathology(ladResults);
                    break;
                case Coronary.Cx:
                    setIndexCurrentImage(
                        cxResults.Images.findIndex((item) => item.fileName === selectedImage.fileName)
                    );
                    setImage(selectedImage);
                    findScoreImage(selectedImage, cxResults);
                    findTotalScoreImage(selectedImage, cxResults);
                    // findPathology(cxResults);
                    break;
                case Coronary.RCA:
                    setIndexCurrentImage(
                        rcaResults.Images.findIndex((item) => item.fileName === selectedImage.fileName)
                    );
                    setImage(selectedImage);
                    findScoreImage(selectedImage, rcaResults);
                    findTotalScoreImage(selectedImage, rcaResults);
                    // findPathology(rcaResults);
                    break;
                default:
                    break;
            }
        }
    }, [selectedImage, currentCoronary, storedAnalysis]);

    useEffect(() => {
        switch (currentCoronary) {
            case Coronary.LAD:
                setCurrentCoronaryName(t('LAD'));
                break;
            case Coronary.Cx:
                setCurrentCoronaryName(t('CX'));
                break;
            case Coronary.RCA:
                setCurrentCoronaryName(t('RCA'));
                break;
            default:
                break;
        }
    }, [i18n.language]);

    useEffect(() => {
        setImageWithGraphs([
            {
                coronaryResults: ladResults,
                coronaryName: t('LAD'),
                barGraph: (
                    <BarChartSVG
                        data={formatBarData(ladResults, chartKeys, barChartIndexBy)}
                        keys={chartKeys}
                        indexBy={barChartIndexBy}
                        colors={colors}
                        legendLeft={t('analysis.analysisReportComp.coronaryInformation.graph.left')}
                        legendBottom={t('analysis.analysisReportComp.coronaryInformation.graph.bottom')}
                        maxValue={1}
                    />
                )
            },
            {
                coronaryResults: cxResults,
                coronaryName: t('CX'),
                barGraph: (
                    <BarChartSVG
                        data={formatBarData(cxResults, chartKeys, barChartIndexBy)}
                        keys={chartKeys}
                        indexBy={barChartIndexBy}
                        colors={colors}
                        legendLeft={t('analysis.analysisReportComp.coronaryInformation.graph.left')}
                        legendBottom={t('analysis.analysisReportComp.coronaryInformation.graph.bottom')}
                        maxValue={1}
                    />
                )
            },
            {
                coronaryResults: rcaResults,
                coronaryName: t('RCA'),
                barGraph: (
                    <BarChartSVG
                        data={formatBarData(rcaResults, chartKeys, barChartIndexBy)}
                        keys={chartKeys}
                        indexBy={barChartIndexBy}
                        colors={colors}
                        legendLeft={t('analysis.analysisReportComp.coronaryInformation.graph.left')}
                        legendBottom={t('analysis.analysisReportComp.coronaryInformation.graph.bottom')}
                        maxValue={1}
                    />
                )
            }
        ]);
    }, [ladResults, cxResults, rcaResults]);

    /** Détermination du score de l'image */
    const findScoreImage = (image: File, currentCoro: ResultsRads) => {
        if (
            image.base64 == '' ||
            image.fileName == '' ||
            currentCoro.Images.length == 0 ||
            currentCoro.Images.findIndex((item) => item.fileName === image.fileName) == -1 ||
            currentCoro.RadsAnalysisResponse.imageScores.length == 0
        ) {
            setScoreImage('Error');
        } else {
            const score =
                Math.max(
                    currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads0).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length,
                    currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads1).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length,
                    currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads2).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length,
                    currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads3).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length,
                    currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads4).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length,
                    currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads5).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length
                ) * 100;
            setScoreImage(score.toFixed(1));
        }
    };

    /** Détermination de l'ensemble des score d'une image */
    const findTotalScoreImage = (image: File, currentCoro: ResultsRads) => {
        if (
            image.base64 == '' ||
            image.fileName == '' ||
            currentCoro.Images.length == 0 ||
            currentCoro.Images.findIndex((item) => item.fileName === image.fileName) == -1 ||
            currentCoro.RadsAnalysisResponse.imageScores.length == 0
        ) {
            setRad0Score('Err');
            setRad1Score('Err');
            setRad2Score('Err');
            setRad3Score('Err');
            setRad4Score('Err');
            setRad5Score('Err');
        } else {
            setRad0Score(
                (
                    (currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads0).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length) *
                    100
                ).toFixed(1)
            );
            setRad1Score(
                (
                    (currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads1).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length) *
                    100
                ).toFixed(1)
            );
            setRad2Score(
                (
                    (currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads2).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length) *
                    100
                ).toFixed(1)
            );
            setRad3Score(
                (
                    (currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads3).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length) *
                    100
                ).toFixed(1)
            );
            setRad4Score(
                (
                    (currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads4).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length) *
                    100
                ).toFixed(1)
            );
            setRad5Score(
                (
                    (currentCoro.RadsAnalysisResponse.imageScores.map((score) => score.rads5).reduce((a, b) => a + b) /
                        currentCoro.RadsAnalysisResponse.imageScores.length) *
                    100
                ).toFixed(1)
            );
        }
    };

    const getCurrentCoronaryRads = () => {
        let coronary = null;
        if (currentCoronary == Coronary.LAD) {
            coronary = ladResults;
        } else if (currentCoronary == Coronary.RCA) {
            coronary = rcaResults;
        } else if (currentCoronary == Coronary.Cx) {
            coronary = cxResults;
        }
        return coronary;
    };

    /** Does the FFR is calculated ? */
    const ffrCalculated =
        ladResults.RadsAnalysisResponse.ffrResult !== Ffr.Null ||
        cxResults.RadsAnalysisResponse.ffrResult !== Ffr.Null ||
        rcaResults.RadsAnalysisResponse.ffrResult !== Ffr.Null;

    // ! Keep for futur Quality
    // const getCurrentQuality = () => {
    //     let coronary = getCurrentCoronaryRads();
    //     if (coronary == null) {
    //         return '';
    //     }
    //     return coronary.RadsAnalysisResponse.qualityResult === Quality.High
    //         ? t('analysis.analysisReportComp.coronaryInformation.quality.high')
    //         : coronary.RadsAnalysisResponse.qualityResult === Quality.Low
    //         ? t('analysis.analysisReportComp.coronaryInformation.quality.low')
    //         : t('analysis.analysisReportComp.coronaryInformation.quality.notSpecified');
    // };

    const getCurrentCalcification = () => {
        let coronary = getCurrentCoronaryRads();
        if (coronary == null) {
            return '';
        }
        let result = 'Null';
        let calcification = coronary.RadsAnalysisResponse.calcificationResult;
        if (calcification === Calcification.Minimal)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.minimal');
        else if (calcification === Calcification.Moderate)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.moderate');
        else if (calcification === Calcification.Severe)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.severe');
        else if (calcification === Calcification.Null)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.null');
        else if (calcification === Calcification.NotSpecified)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.notSpecified');
        return result;
    };

    const getCurrentFfr = () => {
        let coronary = getCurrentCoronaryRads();
        if (coronary == null) {
            return '';
        }
        return ffrAvailable && ffrCalculated ? getFfr(coronary) : t('ffrUnavailable');
    };

    const getCurrentFfrColor = () => {
        let coronary = getCurrentCoronaryRads();
        if (coronary == null) {
            return 'basic';
        }
        if (ffrAvailable && ffrCalculated && hasFfrWarning(coronary.RadsAnalysisResponse)) {
            return 'red';
        }
        return 'basic';
    };

    const getCurrentEntropyStr = () => {
        let coronary = getCurrentCoronaryRads();
        return coronary != null ? getEntropyStr(coronary) : '';
    };

    const getCurrentConfidenceColor = () => {
        let coronary = getCurrentCoronaryRads();
        if (coronary == null) {
            return 'basic';
        }
        if (ffrAvailable && ffrCalculated && hasEntropyWarning(coronary.RadsAnalysisResponse)) {
            return 'red';
        }
        return 'basic';
    };

    const getCurrentColorByRads = () => {
        let coronary = getCurrentCoronaryRads();
        return coronary != null ? getColorByRadsBis(coronary.RadsAnalysisResponse.classificationResult) : '';
    };

    const getCurrentBackgroundColorByRads = () => {
        let coronary = getCurrentCoronaryRads();
        return coronary != null ? getBackgroundColorByRads(coronary.RadsAnalysisResponse.classificationResult) : '';
    };

    const getCurrentStenosisClassification = (): string => {
        let coronary = getCurrentCoronaryRads();
        return coronary != null ? stenosisClassification(coronary.RadsAnalysisResponse.classificationResult) : '';
    };

    const getPathology = (): string => {
        let coronary = getCurrentCoronaryRads();
        if (!coronary) {
            return '';
        }

        let data = coronary.RadsAnalysisResponse.classificationResult;
        if (data === ClassificationResult.Rads0) {
            return t('normalPathology');
        } else if (
            data === ClassificationResult.Rads12 ||
            data === ClassificationResult.Rads1 ||
            data === ClassificationResult.Rads2
        ) {
            return t('nonObstruPathology');
        } else if (
            data === ClassificationResult.Rads345 ||
            data === ClassificationResult.Rads34 ||
            data === ClassificationResult.Rads23 ||
            data === ClassificationResult.Rads3 ||
            data === ClassificationResult.Rads4
        ) {
            return t('obstruPathology');
        } else if (data === ClassificationResult.Rads5) {
            return t('obstructed');
        }
        return '';
    };

    /** JSON array information d'une image */
    let imageInformation: Array<ImageInformation> = [
        {
            id: 'aiScore',
            title: t('analysis.analysisReportComp.sliderImages.aiScore'),
            value: scoreImage,
            tooltip: `Rad0 :
                ${rad0Score} |
                Rad1 :
                ${rad1Score} |
                Rad2 :
                ${rad2Score} |
                Rad3 :
                ${rad3Score} |
                Rad4 :
                ${rad4Score} |
                Rad5 :
                ${rad5Score}`,
            color: 'basic'
        },
        {
            id: 'confidence',
            title: t('analysis.analysisReportComp.sliderImages.confidence'),
            value: getCurrentEntropyStr(),
            tooltip: t('analysis.analysisReportComp.sliderImages.confidence'),
            color: getCurrentConfidenceColor()
        },
        {
            id: 'ffr',
            title: t('analysis.analysisReportComp.sliderImages.ffrResult'),
            value: getCurrentFfr(),
            tooltip: t('analysis.analysisReportComp.sliderImages.ffrResult'),
            color: getCurrentFfrColor()
        },
        {
            id: 'calcification',
            title: t('analysis.analysisReportComp.sliderImages.calcificationResult'),
            value: getCurrentCalcification(),
            tooltip: t('analysis.analysisReportComp.sliderImages.calcificationResult'),
            color: 'basic'
        },
        // ! Keep for futur Quality
        // {
        //     id: 'quality',
        //     title: t('analysis.analysisReportComp.coronaryInformation.quality.image'),
        //     value: getCurrentQuality(),
        //     tooltip: t('analysis.analysisReportComp.coronaryInformation.quality.image')
        // },
        {
            id: 'typeOfPathology',
            title: t('analysis.analysisReportComp.sliderImages.typeOfPathology'),
            value: getPathology(),
            tooltip: t('analysis.analysisReportComp.sliderImages.typeOfPathology'),
            color: 'basic'
        }
    ];

    return (
        <SListRow>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                <SCardTitle>{t('analysis.analysisReportComp.sliderImages.aiResults')}</SCardTitle>
                <SCurrentCoronaryName>{currentCoronaryName}</SCurrentCoronaryName>
            </div>
            <SListItem key="stenosisResults">
                <STitle>{t('analysis.analysisReportComp.sliderImages.aiStenosisSeverity')}</STitle>
                <SResultStenosis color={getCurrentColorByRads()} backgroundColor={getCurrentBackgroundColorByRads()}>
                    {getCurrentStenosisClassification()}
                </SResultStenosis>
            </SListItem>
            {imageInformation.map((item, index) =>
                item.id != 'aiScore' ? (
                    <SListItem key={index}>
                        <STitle>
                            {item.title}{' '}
                            {item.id == 'confidence' ? (
                                <Information
                                    message={t(
                                        'analysis.analysisReportComp.coronaryInformation.confidenceLevel.description'
                                    )}
                                />
                            ) : (
                                <></>
                            )}{' '}
                        </STitle>
                        {item.color == 'red' ? <SValueRed>{item.value}</SValueRed> : <SValue>{item.value}</SValue>}
                    </SListItem>
                ) : (
                    <div key={index}>
                        <SListItem>
                            <STooltip title={item.tooltip}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <STitle>
                                            {item.title}{' '}
                                            <Information
                                                message={t(
                                                    'analysis.analysisReportComp.coronaryInformation.aiScore.description'
                                                )}
                                            />
                                        </STitle>
                                        <SValue>{item.value}</SValue>
                                    </div>
                                </div>
                            </STooltip>
                        </SListItem>
                        {imageWithGraphs.map((item, index) => (
                            <SSubColumn
                                key={index}
                                display={
                                    item.coronaryResults.Images.length > 0 &&
                                    item.coronaryResults.RadsAnalysisResponse.classificationResult !=
                                        ClassificationResult.Error &&
                                    item.coronaryResults.RadsAnalysisResponse.coronary == currentCoronary
                                        ? 'flex'
                                        : 'none'
                                }
                            >
                                <SBarChart>{item.barGraph}</SBarChart>
                            </SSubColumn>
                        ))}
                    </div>
                )
            )}
        </SListRow>
    );
};

export default CoronaryImageDetails;

const ListRow = styled.div<typeof List>`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const STooltip = styled(Tooltip)`
    &:hover {
        cursor: help;
    }
`;

const STitle = styled.div`
    display: flex;
    font-weight: 400;
    color: ${(props) => props.theme.analysisDetails.imageDetails.statistics.titleColor};
    align-items: center;
`;

const SValue = styled.div`
    font-weight: 500;
    color: ${(props) => props.theme.analysisDetails.imageDetails.statistics.valueColor};
`;

const SValueRed = styled.div`
    font-weight: 500;
    color: #f90240;
`;

const SListItem = styled(ListItem)`
    line-height: 1.3;
    align-items: start !important;
    padding: 4px 0 4px 15px !important;
    flex-direction: column;
`;

const SListRow = styled(ListRow)`
    background-color: ${(props) => props.theme.analysisDetails.imageDetails.statistics.cardBackground};
    height: fit-content;
    border-radius: 10px;
    border: ${(props) => props.theme.analysisDetails.imageDetails.statistics.cardBorder};
    padding-bottom: 7px;
`;

const SCurrentCoronaryName = styled.div`
    background-color: white;
    color: black;
    border-radius: 10px;
    padding: 2px 7px;
    width: fit-content;
    display: flex;
    align-items: flex-start;
    margin: 10px 12px;
`;

const SResultStenosis = styled.div<StenosisColorProps>`
    display: flex;
    border-radius: ${(props) => props.theme.getSpacing(1)};
    color: ${(props) => props.theme.colors.white};
    padding: ${(props) => props.theme.getSpacing(1, 2, 1, 2)};
    user-select: none;
    width: fit-content;
    margin-top: 5px;
    color: ${(propsColor: StenosisColorProps) => propsColor.color};
    background-color: ${(propsColor: StenosisColorProps) => propsColor.backgroundColor};
    border: 0.05em solid;
`;

const SCardTitle = styled.div`
    color: ${(props) => props.theme.analysisDetails.medicalReport.textColor};
    display: flex;
    margin: 10px 12px;
    align-items: center;
    font-weight: 600;
`;

const SBarChart = styled.div`
    height: 210px;
    width: 100%;
    display: flex;
    svg text {
        fill: ${(props) => props.theme.analysisDetails.medicalReport.textColor} !important;
    }
    svg {
        width: 98%;
    }
`;
const SSubColumn = styled.div<Display>`
    display: ${(props: Display) => props.display};
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
`;

