import { File } from '@/components/molecules';

import {
    Coronary,
    RadsAnalysisResponse,
    Classification,
    ClassificationResult,
    Quality,
    Calcification,
    Ffr
} from '@api';

export interface LocationStateRads {
    /**Promise containing the Lad results */
    promiseResultsRads: Promise<RadsAnalysisResponse[]>;
    /**If true then allFiles will be filled, otherwise ladFiles/cxFiles/rcaFiles will be */
    is27: boolean;
    /**Files uploaded (images in base64 and fileName and blob) */
    allFiles: File[];
    /**Files for Lad artery */
    ladFiles: File[];
    /**Files for Cx artery */
    cxFiles: File[];
    /**Files for RCA artery */
    rcaFiles: File[];
}

export type ResultsRads = {
    isLoading: Boolean;
    Images: File[];
    RadsAnalysisResponse: RadsAnalysisResponse;
    BarGraph?: string;
    BulletGraph?: string;
    PieGraph?: string;
};

export const initialResultRads: ResultsRads = {
    isLoading: true,
    Images: [],
    RadsAnalysisResponse: {
        coronary: Coronary.NotSpecified,
        imageScores: [],
        classification: Classification.SixClasses,
        averageRads: { rads0: 0, rads1: 0, rads2: 0, rads3: 0, rads4: 0, rads5: 0, init: () => 0, toJSON: () => 0 },
        bestRadsScoreImageIndex: 0,
        bestRadsScore: 0,
        classificationResult: ClassificationResult.Error,
        imageClassifications: [ClassificationResult.Error],
        qualityScores: [],
        ffrScores: [],
        averageQualityScores: { q123: 0, q45: 0, init: () => 0, toJSON: () => 0 },
        qualityResult: Quality.NotSpecified,
        calcificationScores: [],
        averageCalcificationScores: { sc0: 0, sc1: 0, sc2: 0, sc3: 0, init: () => 0, toJSON: () => 0 },
        calcificationResult: Calcification.NotSpecified,
        averageFfrScores: { fFrPlus: 0, fFrMinus: 0, init: () => 0, toJSON: () => 0 },
        ffrResult: Ffr.FfrPlus,
        ffrHardcodeResult: Ffr.Null,
        entropy: 0,
        init: () => 0,
        toJSON: () => 0
    },
    BarGraph: '',
    PieGraph: '',
    BulletGraph: ''
};

let classificationIntByEnumString = [
    {
        enumString: ClassificationResult.Rads0,
        enumInt: '0'
    },
    {
        enumString: ClassificationResult.Rads12,
        enumInt: '1'
    },
    {
        enumString: ClassificationResult.Rads345,
        enumInt: '2'
    },
    {
        enumString: ClassificationResult.Rads34,
        enumInt: '3'
    },
    {
        enumString: ClassificationResult.Rads1,
        enumInt: '4'
    },
    {
        enumString: ClassificationResult.Rads2,
        enumInt: '5'
    },
    {
        enumString: ClassificationResult.Rads3,
        enumInt: '6'
    },
    {
        enumString: ClassificationResult.Rads4,
        enumInt: '7'
    },
    {
        enumString: ClassificationResult.Rads5,
        enumInt: '8'
    },
    {
        enumString: ClassificationResult.Error,
        enumInt: '9'
    },
    {
        enumString: ClassificationResult.NotSet,
        enumInt: '10'
    },
    {
        enumString: ClassificationResult.Rads23,
        enumInt: '11'
    }
];

export const getClassificationIntStringByEnumString = (enumString: string) => {
    let enumInt = '10';
    for (var i in classificationIntByEnumString) {
        if (classificationIntByEnumString[i].enumString == enumString) {
            enumInt = classificationIntByEnumString[i].enumInt;
            break;
        }
    }
    return enumInt;
};

