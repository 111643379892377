import React from 'react';
import styled from 'styled-components';
import { Button as DefaultMaterialButton } from '@mui/material/';

export const UploadButton = styled(({ ...otherProps }) => <DefaultMaterialButton {...otherProps} />)`
    && {
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.black};
        border-radius: 8px;
        border: solid 1px;
        &:hover {
            background-color: ${(props) => props.theme.colors.grey};
            box-shadow: inset 2px 2px 4px #e3e3e3, inset -2px -2px 4px #ffffff;
        }
        &&:disabled {
            pointer-events: none;
        }
    }
`;

export default UploadButton;

