import React, { SetStateAction, Dispatch } from 'react';
import styled from 'styled-components';
import { t } from '@/i18n.js';
import { useHistory } from 'react-router-dom';

//ICONS
import SearchIcon from '@mui/icons-material/Search';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Chip } from '@mui/material';

type SWidthProps = {
    customwidth: string;
} & TextFieldProps;

type Props = {
    onChange: Dispatch<SetStateAction<string>>;
    searchToOpen: string;
    label?: string;
    url?: string;
    width?: string;
};

export const SearchField = (props: Props) => {
    const { onChange, searchToOpen, label, url, width } = props;
    const history = useHistory();

    var customLabel = label;
    if (customLabel == null) {
        customLabel = t('analysisExplorer.analysisTable.searchAnalysis');
    }

    var customUrl = url;
    if (customUrl == null) {
        customUrl = '/analysis-explorer/';
    }

    var customWidth = width;
    if (customWidth == null) {
        customWidth = '15vw';
    }

    return (
        <SDiv>
            <STextField
                type="text"
                size="small"
                onChange={(event) => onChange(event.target.value)}
                label={customLabel}
                variant="outlined"
                customwidth={customWidth}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchToOpen === '0' ? <></> : <Chip label={'ID: ' + searchToOpen} />}
                            <IconButton
                                onClick={() => (searchToOpen === '0' ? {} : history.push(customUrl + searchToOpen))}
                            >
                                <SSearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </SDiv>
    );
};
export default SearchField;

const STextField = styled(TextField)<SWidthProps>`
    width: ${(props: SWidthProps) => props.customwidth};
    & .MuiInput-root {
        color: ${(props) => props.theme.table.search.text};
        margin-left: 10;
        flex-direction: row;
    }
    & label {
        color: ${(props) => props.theme.table.search.label};
    }
    & .MuiIconButton-root {
        padding: ${(props) => props.theme.getSpacing(1)};
    }
    & .MuiTextField-root {
        color: darkgray;
    }
    & label.Mui-focused {
        color: darkgray;
    }
    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiOutlinedInput-root {
        & input {
            color: ${(props) => props.theme.table.search.text};
        }
        & fieldset {
            border: ${(props) => props.theme.table.search.border};
            border-radius: 10px;
        }
        &:hover fieldset {
            border: ${(props) => props.theme.table.search.borderHover};
        }
        &.Mui-focused fieldset {
            border: ${(props) => props.theme.table.search.borderHover};
        }
        &.MuiInput-input {
            color: ${(props) => props.theme.table.search.text};
        }
    }
`;

const SDiv = styled.div`
    background-color: ${(props) => props.theme.table.search.background};
    border-radius: 10px;
`;

const SSearchIcon = styled(SearchIcon)`
    color: ${(props) => props.theme.table.search.text};
`;

