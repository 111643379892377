import React from 'react';
import { Page, Text, View } from '@react-pdf/renderer';

import medicalStyles from '@/pdf/rads/MedicalReportStyles';

import { renderFooter, renderDisclaimerPageNumber } from '@/pdf/rads/PdfDocument';
import { ClassificationResult, Coronary } from '@api';
import { t } from '@/i18n.js';
import { getBackgroundColorByStenosisBlock } from '@/utils/ResultsRadsStenosisColors';

export type Props = {
    /**The doctor name */
    doctorName: string;
    /**Technique set by user */
    technique: string;
    /**Quantity of contrast used, set by user */
    contrastQuantity: number | undefined;
    /**Dose dlp, set by user */
    dlp: number | undefined;
    /**Indication, set by user */
    indication: string;
    /**Cad rads for LAD set by user */
    ladStenosis: string;
    /**Cad rads for CX set by user */
    cxStenosis: string;
    /**Cad rads for RCA set by user */
    rcaStenosis: string;
    /** Conclusion for all Cad-Rads*/
    conclusionCadRads: string;
    classificationResult: ClassificationResult;
    /**Commentaries written by the doctor */
    commentaries: string;
    /** Cavités cardiaques (valeurs possible: Normales ou définit par l'utilisateur)*/
    cavite: string;
    /**Valve mitrale  (valeurs possible: Normale ou définit par l'utilisateur) */
    mitrale: string;
    /**Valve aortique  (valeurs possible: Normale ou définit par l'utilisateur) */
    aortique: string;
    /**Racine aortique  (valeurs possible: Non dilaté ou définit par l'utilisateur) */
    racine: string;
    /**Péricarde  (valeurs possible: Normal ou définit par l'utilisateur) */
    pericarde: string;
    ladCalcification: string;
    cxCalcification: string;
    rcaCalcification: string;
};

export type MedicalReportPageProps = {
    /**The doctor name */
    doctorName: string;
    /**Technique set by user */
    technique: string;
    /**Quantity of contrast used, set by user */
    contrastQuantity: number | undefined;
    /**Dose dlp, set by user */
    dlp: number | undefined;
    /**Indication, set by user */
    indication: string;
    /**Cad rads for LAD set by user */
    ladStenosis: string;
    /**Cad rads for CX set by user */
    cxStenosis: string;
    /**Cad rads for RCA set by user */
    rcaStenosis: string;
    /** Conclusion for all Cad-Rads*/
    conclusionCadRads: string;
    /**Commentaries written by the doctor */
    commentaries: string;
    /** Cavités cardiaques (valeurs possible: Normales ou définit par l'utilisateur)*/
    cavite: string;
    /**Valve mitrale  (valeurs possible: Normale ou définit par l'utilisateur) */
    mitrale: string;
    /**Valve aortique  (valeurs possible: Normale ou définit par l'utilisateur) */
    aortique: string;
    /**Racine aortique  (valeurs possible: Non dilaté ou définit par l'utilisateur) */
    racine: string;
    /**Péricarde  (valeurs possible: Normal ou définit par l'utilisateur) */
    pericarde: string;
    ladCalcification: string;
    cxCalcification: string;
    rcaCalcification: string;
};

export const renderBlackBar = () => (
    <>
        <Text style={medicalStyles.blackbar} fixed>
            {' '}
        </Text>
    </>
);

const getBackgroundColorForCoronary = (classRes: string) => {
    let color = getBackgroundColorByStenosisBlock(classRes);
    return (
        <View
            style={{
                backgroundColor: color,
                color: 'white',
                width: 10,
                height: 10,
                borderRadius: 100,
                marginRight: 5,
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Text>{'  '}</Text>
        </View>
    );
};

const initialLocalisation = {
    [Coronary.LAD]: false,
    [Coronary.Cx]: false,
    [Coronary.RCA]: false
};

export const initialMedicalPropsValue: Props = {
    doctorName: '',
    technique: '',
    contrastQuantity: undefined,
    dlp: undefined,
    indication: '',
    ladStenosis: '',
    cxStenosis: '',
    rcaStenosis: '',
    conclusionCadRads: '',
    classificationResult: ClassificationResult.NotSet,
    commentaries: '',
    cavite: '',
    mitrale: '',
    aortique: '',
    racine: '',
    pericarde: '',
    ladCalcification: '',
    cxCalcification: '',
    rcaCalcification: ''
};

const MedicalReportPage = (props: MedicalReportPageProps) => {
    const {
        doctorName,
        technique,
        contrastQuantity,
        dlp,
        indication,
        ladStenosis,
        cxStenosis,
        rcaStenosis,
        conclusionCadRads,
        commentaries,
        cavite,
        mitrale,
        aortique,
        racine,
        pericarde,
        ladCalcification,
        cxCalcification,
        rcaCalcification
    } = props;

    const AreOtherNotEmpty = () => {
        return [cavite, mitrale, aortique, racine, pericarde].some((val) => val !== '');
    };
    const AreCalcificationNotEmpty = () => {
        return [ladCalcification, cxCalcification, rcaCalcification].some((val) => val !== '');
    };
    return (
        <Page style={medicalStyles.page}>
            {/* ------------------------- Header medical report ------------------------- */}
            <View style={medicalStyles.header}>
                <Text style={medicalStyles.maintitle}>{t('medicalReportForm.medicReport')}</Text>
                <Text style={medicalStyles.title}>{t('medicalReportForm.subtitleMedicReport')}</Text>
            </View>

            <View>
                {/* ------------------------- Author ------------------------- */}
                <View style={medicalStyles.slot}>
                    <Text style={medicalStyles.title}>{t('medicalReportForm.author')}</Text>
                    {renderBlackBar()}
                    <View style={medicalStyles.slotResponse}>
                        <Text style={medicalStyles.text}>{doctorName}</Text>
                    </View>
                </View>

                {/* ------------------------- Technique ------------------------- */}
                <View style={medicalStyles.slot}>
                    <Text style={medicalStyles.title}>{t('medicalReportForm.technique')}</Text>
                    {renderBlackBar()}
                    <View style={medicalStyles.slotResponse}>
                        <Text style={medicalStyles.text}>{technique}</Text>
                        {contrastQuantity != undefined && (
                            <Text style={medicalStyles.text}>
                                {t('medicalReportForm.quantityContrast')} {contrastQuantity} ml
                            </Text>
                        )}
                        {dlp != undefined && (
                            <Text style={medicalStyles.text}>
                                {t('medicalReportForm.doseDlp')} {dlp} mGy*cm
                            </Text>
                        )}
                    </View>
                </View>

                {/* ------------------------- Indication ------------------------- */}
                <View style={medicalStyles.slot}>
                    <Text style={medicalStyles.title}>{t('medicalReportForm.indication.title')}</Text>
                    {renderBlackBar()}
                    <View style={medicalStyles.slotResponse}>
                        <Text style={medicalStyles.text}>{indication}</Text>
                    </View>
                </View>

                {/* ------------------------- Coronary ------------------------- */}
                <View style={medicalStyles.slot}>
                    <Text style={medicalStyles.title}>{t('medicalReportForm.coronary.title')}</Text>
                    {renderBlackBar()}
                    <View style={medicalStyles.slotResponse}>
                        <View style={medicalStyles.row}>
                            {getBackgroundColorForCoronary(rcaStenosis)}
                            <Text style={medicalStyles.text}>
                                {t('medicalReportForm.coronary.rca')} :<Text> {rcaStenosis}</Text>
                            </Text>
                        </View>
                        <View style={medicalStyles.row}>
                            {getBackgroundColorForCoronary(ladStenosis)}
                            <Text style={medicalStyles.text}>
                                {t('medicalReportForm.coronary.lad')} :<Text> {ladStenosis}</Text>
                            </Text>
                        </View>
                        <View style={medicalStyles.row}>
                            {getBackgroundColorForCoronary(cxStenosis)}
                            <Text style={medicalStyles.text}>
                                {t('medicalReportForm.coronary.cx')} :<Text> {cxStenosis}</Text>
                            </Text>
                        </View>
                    </View>
                </View>

                {/* ------------------------- Calcium Charge ------------------------- */}
                {AreCalcificationNotEmpty() && (
                    <View style={medicalStyles.slot}>
                        <Text style={medicalStyles.title}>{t('medicalReportForm.calciumCharge.titleSection')}</Text>
                        {renderBlackBar()}
                        <View style={medicalStyles.slotResponse}>
                            {rcaCalcification !== '' && (
                                <>
                                    <Text style={medicalStyles.text}>{`${t(
                                        'medicalReportForm.coronary.rca'
                                    )} : ${rcaCalcification}`}</Text>
                                </>
                            )}
                            {ladCalcification !== '' && (
                                <Text style={medicalStyles.text}>{`${t(
                                    'medicalReportForm.coronary.lad'
                                )} : ${ladCalcification}`}</Text>
                            )}
                            {cxCalcification !== '' && (
                                <Text style={medicalStyles.text}>{`${t(
                                    'medicalReportForm.coronary.cx'
                                )} : ${cxCalcification}`}</Text>
                            )}
                        </View>
                    </View>
                )}

                {/* ------------------------- Other ------------------------- */}
                {AreOtherNotEmpty() && (
                    <View style={medicalStyles.slot}>
                        <Text style={medicalStyles.title}>{t('medicalReportForm.other')}</Text>
                        {renderBlackBar()}
                        <View style={medicalStyles.slotResponse}>
                            {cavite !== '' && (
                                <Text style={medicalStyles.text}>{`${t('medicalReportForm.cavite')} : ${cavite}`}</Text>
                            )}
                            {mitrale !== '' && (
                                <Text style={medicalStyles.text}>{`${t(
                                    'medicalReportForm.mitrale'
                                )} : ${mitrale}`}</Text>
                            )}
                            {aortique !== '' && (
                                <Text style={medicalStyles.text}>{`${t(
                                    'medicalReportForm.aortique'
                                )} : ${aortique}`}</Text>
                            )}
                            {racine !== '' && (
                                <Text style={medicalStyles.text}>{`${t('medicalReportForm.racine')} : ${racine}`}</Text>
                            )}
                            {pericarde !== '' && (
                                <Text style={medicalStyles.text}>{`${t(
                                    'medicalReportForm.pericarde'
                                )} : ${pericarde}`}</Text>
                            )}
                        </View>
                    </View>
                )}

                {/* ------------------------- Conclusion ------------------------- */}
                {commentaries !== '' && (
                    <View style={medicalStyles.slot}>
                        <Text style={medicalStyles.title}>
                            {t('medicalReportForm.coronaryCadsRadsConclusion.validated')}
                        </Text>
                        {renderBlackBar()}
                        <View style={medicalStyles.slotResponse}>
                            <Text style={medicalStyles.text}>{commentaries}</Text>
                        </View>
                    </View>
                )}
            </View>

            {/* ------------------------- Classification CAD-RADS ------------------------- */}
            <View style={medicalStyles.centerContainer}>
                <View style={medicalStyles.block}>
                    <Text style={medicalStyles.title}>{t('medicalReportForm.classification')}</Text>
                    <Text style={medicalStyles.text}>{conclusionCadRads}</Text>
                </View>
            </View>

            {renderDisclaimerPageNumber()}
            {renderFooter()}
        </Page>
    );
};

export default MedicalReportPage;

