import { Language } from '@/utils/Language';
import { t } from '@/i18n.js';
import { Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';
import { Calcification, ClassificationResult } from '@api';
import { stenosisClassificationList } from '@/utils/ResultsRadsStenosisColors';

const nonObstructive = [ClassificationResult.Rads1, ClassificationResult.Rads12, ClassificationResult.Rads2];
const obstructive = [
    ClassificationResult.Rads23,
    ClassificationResult.Rads3,
    ClassificationResult.Rads4,
    ClassificationResult.Rads34,
    ClassificationResult.Rads345,
    ClassificationResult.Rads5
];

export const getCoronaryTranslationFromClassificationResult = (value: ClassificationResult, language: string) => {
    let returnedString = '';
    if (value == ClassificationResult.Rads0) returnedString = t('medicalReportForm.coronaryCadsRadsLesion.normal');
    else if (nonObstructive.includes(value))
        returnedString = t('medicalReportForm.coronaryCadsRadsLesion.nonObstructive');
    else if (obstructive.includes(value)) returnedString = t('medicalReportForm.coronaryCadsRadsLesion.obstructive');
    return returnedString;
};

export const getConclusionTranslationFromAnalysisClassificationResult = (value: string | undefined) => {
    if (value == undefined) {
        return '';
    }
    if (value == ClassificationResult.Rads0) return t('medicalReportForm.coronaryCadsRadsConclusion.rads0');
    else if (value == ClassificationResult.Rads1) return t('medicalReportForm.coronaryCadsRadsConclusion.rads1');
    else if (value == ClassificationResult.Rads2) return t('medicalReportForm.coronaryCadsRadsConclusion.rads2');
    else if (value == ClassificationResult.Rads3) return t('medicalReportForm.coronaryCadsRadsConclusion.rads3');
    else if (value == ClassificationResult.Rads4) return t('medicalReportForm.coronaryCadsRadsConclusion.rads4');
    else if (value == ClassificationResult.Rads5) return t('medicalReportForm.coronaryCadsRadsConclusion.rads5');
    else if (value == ClassificationResult.Rads12) return t('medicalReportForm.coronaryCadsRadsConclusion.rads12');
    else if (value == ClassificationResult.Rads23) return t('medicalReportForm.coronaryCadsRadsConclusion.rads23');
    else if (value == ClassificationResult.Rads34) return t('medicalReportForm.coronaryCadsRadsConclusion.rads34');
    else if (value == ClassificationResult.Rads345) return t('medicalReportForm.coronaryCadsRadsConclusion.rads5');
    else {
        return '';
    }
};

export const getCalcificationTranslation = (value: Calcification) => {
    let returnedString = '';
    if (value == Calcification.Null) returnedString = t('medicalReportForm.calciumCharge.null');
    else if (value == Calcification.Minimal) returnedString = t('medicalReportForm.calciumCharge.minimal');
    else if (value == Calcification.Moderate) returnedString = t('medicalReportForm.calciumCharge.moderate');
    else if (value == Calcification.Severe) returnedString = t('medicalReportForm.calciumCharge.severe');
    return returnedString;
};

export const adaptTechniqueToLocale = (value: string | undefined) => {
    if (value == undefined) return '';
    if (
        value == t('medicalReportForm.techniqueDefault', { lng: Language.French }) ||
        value == t('medicalReportForm.techniqueDefault', { lng: Language.English })
    ) {
        return t('medicalReportForm.techniqueDefault');
    } else {
        return value;
    }
};

export const adaptCalcificationToLocale = (value: string | undefined) => {
    if (value == undefined) return '';
    if (
        value == t('medicalReportForm.calciumCharge.null', { lng: Language.French }) ||
        value == t('medicalReportForm.calciumCharge.null', { lng: Language.English })
    ) {
        return t('medicalReportForm.calciumCharge.null');
    } else if (
        value == t('medicalReportForm.calciumCharge.minimal', { lng: Language.French }) ||
        value == t('medicalReportForm.calciumCharge.minimal', { lng: Language.English })
    ) {
        return t('medicalReportForm.calciumCharge.minimal');
    } else if (
        value == t('medicalReportForm.calciumCharge.moderate', { lng: Language.French }) ||
        value == t('medicalReportForm.calciumCharge.moderate', { lng: Language.English })
    ) {
        return t('medicalReportForm.calciumCharge.moderate');
    } else if (
        value == t('medicalReportForm.calciumCharge.severe', { lng: Language.French }) ||
        value == t('medicalReportForm.calciumCharge.severe', { lng: Language.English })
    ) {
        return t('medicalReportForm.calciumCharge.severe');
    } else {
        return value;
    }
};

export const adaptResultConclusionToLocale = (value: string | undefined) => {
    if (value == undefined) return '';

    if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads0', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads0', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads0');
    } else if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads1', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads1', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads1');
    } else if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads2', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads2', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads2');
    } else if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads3', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads3', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads3');
    } else if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads4', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads4', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads4');
    } else if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads5', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads5', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads5');
    } else if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads12', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads12', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads12');
    } else if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads23', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads23', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads23');
    } else if (
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads34', { lng: Language.French }) ||
        value == t('medicalReportForm.coronaryCadsRadsConclusion.rads34', { lng: Language.English })
    ) {
        return t('medicalReportForm.coronaryCadsRadsConclusion.rads34');
    } else {
        return value;
    }
};

export const adaptNormalToLocale = (value: string | undefined) => {
    if (value == undefined) return '';

    if (
        value == t('medicalReportForm.normal', { lng: Language.French }) ||
        value == t('medicalReportForm.normal', { lng: Language.English })
    ) {
        return t('medicalReportForm.normal');
    } else {
        return value;
    }
};

export const adaptNormaleToLocale = (value: string | undefined) => {
    if (value == undefined) return '';

    if (
        value == t('medicalReportForm.normale', { lng: Language.French }) ||
        value == t('medicalReportForm.normale', { lng: Language.English })
    ) {
        return t('medicalReportForm.normale');
    } else {
        return value;
    }
};

export const adaptNormalesToLocale = (value: string | undefined) => {
    if (value == undefined) return '';

    if (
        value == t('medicalReportForm.normales', { lng: Language.French }) ||
        value == t('medicalReportForm.normales', { lng: Language.English })
    ) {
        return t('medicalReportForm.normales');
    } else {
        return value;
    }
};

export const adaptNonDilatedToLocale = (value: string | undefined) => {
    if (value == undefined) return '';

    if (
        value == t('medicalReportForm.noDilatation', { lng: Language.French }) ||
        value == t('medicalReportForm.noDilatation', { lng: Language.English })
    ) {
        return t('medicalReportForm.noDilatation');
    } else {
        return value;
    }
};

export const adaptIndicationToLocale = (value: string | undefined) => {
    if (value == undefined) return t('medicalReportForm.indication.checkUp');

    if (
        value == t('medicalReportForm.indication.checkUp', { lng: Language.French }) ||
        value == t('medicalReportForm.indication.checkUp', { lng: Language.English })
    ) {
        return t('medicalReportForm.indication.checkUp');
    }
    if (
        value == t('medicalReportForm.indication.coronaryStenosisScreening', { lng: Language.French }) ||
        value == t('medicalReportForm.indication.coronaryStenosisScreening', { lng: Language.English })
    ) {
        return t('medicalReportForm.indication.coronaryStenosisScreening');
    }
    if (
        value == t('medicalReportForm.indication.cardiovascularRisk', { lng: Language.French }) ||
        value == t('medicalReportForm.indication.cardiovascularRisk', { lng: Language.English })
    ) {
        return t('medicalReportForm.indication.cardiovascularRisk');
    }
    if (
        value == t('medicalReportForm.indication.atypicalChestPain', { lng: Language.French }) ||
        value == t('medicalReportForm.indication.atypicalChestPain', { lng: Language.English })
    ) {
        return t('medicalReportForm.indication.atypicalChestPain');
    }
    if (
        value == t('medicalReportForm.indication.typicalChestPain', { lng: Language.French }) ||
        value == t('medicalReportForm.indication.typicalChestPain', { lng: Language.English })
    ) {
        return t('medicalReportForm.indication.typicalChestPain');
    }
    if (
        value == t('medicalReportForm.indication.shortbreath', { lng: Language.French }) ||
        value == t('medicalReportForm.indication.shortbreath', { lng: Language.English })
    ) {
        return t('medicalReportForm.indication.shortbreath');
    } else {
        return value;
    }
};

type MedicalPropsAndSelectBoundaries = {
    medicalPropsTranslated: MedicalProps;
    authorizedLadStenosis: Array<string>;
    authorizedCxStenosis: Array<string>;
    authorizedRcaStenosis: Array<string>;
    authorizedIndicationList: Array<string>;
    authorizedLadCalcification: Array<string>;
    authorizedCxCalcification: Array<string>;
    authorizedRcaCalcification: Array<string>;
};

export const translateAllMedicalPropsAndGetSelectBoundaries = (
    medicalProps: MedicalProps
): MedicalPropsAndSelectBoundaries => {
    let medicalPropsTranslated = medicalProps;

    // CAD RADS calcification
    let lad = medicalProps.ladStenosis;
    let cx = medicalProps.cxStenosis;
    let rca = medicalProps.rcaStenosis;

    let authorizedLadStenosis = ['', ...stenosisClassificationList];
    let authorizedCxStenosis = ['', ...stenosisClassificationList];
    let authorizedRcaStenosis = ['', ...stenosisClassificationList];

    if (!authorizedLadStenosis.includes(lad) && lad != '') {
        authorizedLadStenosis.push(lad);
    }
    if (!authorizedCxStenosis.includes(cx) && cx != '') {
        authorizedCxStenosis.push(cx);
    }
    if (!authorizedRcaStenosis.includes(rca) && rca != '') {
        authorizedRcaStenosis.push(rca);
    }

    // RADS Calcification
    let ladCalcification = adaptCalcificationToLocale(medicalProps.ladCalcification);
    let cxCalcification = adaptCalcificationToLocale(medicalProps.cxCalcification);
    let rcaCalcification = adaptCalcificationToLocale(medicalProps.rcaCalcification);

    let authorizedLadCalcification = [
        '',
        t('medicalReportForm.calciumCharge.null'),
        t('medicalReportForm.calciumCharge.minimal'),
        t('medicalReportForm.calciumCharge.moderate'),
        t('medicalReportForm.calciumCharge.severe')
    ];
    let authorizedCxCalcification = [
        '',
        t('medicalReportForm.calciumCharge.null'),
        t('medicalReportForm.calciumCharge.minimal'),
        t('medicalReportForm.calciumCharge.moderate'),
        t('medicalReportForm.calciumCharge.severe')
    ];
    let authorizedRcaCalcification = [
        '',
        t('medicalReportForm.calciumCharge.null'),
        t('medicalReportForm.calciumCharge.minimal'),
        t('medicalReportForm.calciumCharge.moderate'),
        t('medicalReportForm.calciumCharge.severe')
    ];

    if (!authorizedLadCalcification.includes(ladCalcification)) {
        authorizedLadCalcification.push(ladCalcification);
    }
    if (!authorizedCxCalcification.includes(cxCalcification)) {
        authorizedCxCalcification.push(cxCalcification);
    }
    if (!authorizedRcaCalcification.includes(rcaCalcification)) {
        authorizedRcaCalcification.push(rcaCalcification);
    }

    let indication = adaptIndicationToLocale(medicalProps.indication);

    let authorizedIndicationList = [
        t('medicalReportForm.indication.checkUp'),
        t('medicalReportForm.indication.coronaryStenosisScreening'),
        t('medicalReportForm.indication.cardiovascularRisk'),
        t('medicalReportForm.indication.atypicalChestPain'),
        t('medicalReportForm.indication.typicalChestPain'),
        t('medicalReportForm.indication.shortbreath')
    ];
    if (!authorizedIndicationList.includes(indication) && indication != '') {
        authorizedIndicationList.push(indication);
    }

    medicalPropsTranslated.ladStenosis = lad;
    medicalPropsTranslated.cxStenosis = cx;
    medicalPropsTranslated.rcaStenosis = rca;
    medicalPropsTranslated.indication = indication;
    medicalPropsTranslated.conclusionCadRads = adaptResultConclusionToLocale(medicalProps.conclusionCadRads);
    medicalPropsTranslated.technique = adaptTechniqueToLocale(medicalProps.technique);
    medicalPropsTranslated.cavite = adaptNormalesToLocale(medicalProps.cavite);
    medicalPropsTranslated.mitrale = adaptNormaleToLocale(medicalProps.mitrale);
    medicalPropsTranslated.aortique = adaptNormaleToLocale(medicalProps.aortique);
    medicalPropsTranslated.racine = adaptNonDilatedToLocale(medicalProps.racine);
    medicalPropsTranslated.pericarde = adaptNormalToLocale(medicalProps.pericarde);

    medicalPropsTranslated.ladCalcification = ladCalcification;
    medicalPropsTranslated.cxCalcification = cxCalcification;
    medicalPropsTranslated.rcaCalcification = rcaCalcification;

    return {
        medicalPropsTranslated,
        authorizedLadStenosis,
        authorizedCxStenosis,
        authorizedRcaStenosis,
        authorizedIndicationList,
        authorizedLadCalcification,
        authorizedCxCalcification,
        authorizedRcaCalcification
    };
};
