import { getSpacing } from '@/utils/Spacing';
import colors from '@/utils/colors';

const spacing = { unit: 'px', size: 4 };

export default {
    app: {
        background: '#F2F2F2',
        buttonBackgroundHover: '#dadada',
        buttonBackground: '#FFFFFF',
        switchDarkMode: '#6D859C',
        switchDarkModeHover: '#506170'
    },
    navbar: {
        menuBackground: '#dadada',
        menuText: '#4a4a4a',
        drawerBackground: '#f6f6f6',
        textColor: '#474747',
        dividerColor: '#e0e0e0',
        languageSwitcher: {
            background: '#f2f2f2',
            backgroundSelected: '#dadada',
            backgroundChoice: '#ebebeb',
            backgroundHover: '#dadada',
            color: '#494949'
        }
    },
    test: {
        background: '#ECEFF4',
        cardBackground: '#E5E9F0',
        buttonBackground: '#0000001a',
        textColor: '#2E3440',
        buttonColor: '#ECEFF4'
    },
    switchMode: {
        MuiSwitchTrackBackgroundColor: '#aab4be',
        MuiSwitchThumbBackgroundColor: '#6d879f'
    },
    card: {
        headerBackground: '#dadada',
        headerBlueTitle: '#0CB4FF',
        contentBackground: '#ededed',
        contentText: '#474747',
        contentLightText: '#a5a5a5',
        dividerBorder: '2px solid #bebebe',
        dividerBorderRadius: '10px'
    },
    table: {
        iconSortColor: '#494949',
        headerBackground: '#ededed',
        headerText: '#767676',
        contentBackground: '#f6f6f6',
        contentDivider: '2px solid #0000001a',
        contentText: '#494949',
        contentLightText: '#7b7b7b',
        hover: '#ededed',
        checkbox: '#7b7b7b',
        search: {
            background: '#f6f6f6',
            border: '1px solid #949494',
            borderHover: '1px solid #696969',
            borderRadius: '10px',
            text: '#4a4a4a',
            label: '#767676'
        },
        editBackground: '#f9f9f9',
        select: {
            backgroundButton: '#f6f6f6',
            background: '#f6f6f6',
            backgroundHover: '#dedede61',
            iconFill: 'black',
            text: '#4a4a4a',
            borderColor: '#949494',
            borderColorHover: '#696969',
            list: {
                background: '#f6f6f6'
            }
        },
        noteTextField: {
            outline: '#e6e5e5',
            background: '#ededed'
        },
        scrollbar: {
            corner: '#ededed',
            color: '#c7c7c7'
        }
    },
    miniTable: {
        arrow: {
            background: '#00000057',
            border: '0px'
        },
        cardBackground: '#ffffff',
        cardBoxShadow: ' 5px 5px 10px #d3d3d3, -5px -5px 10px #ffffff',
        contentText: '#494949',
        title: '#343434',
        currentId: '#e1e1e1'
    },
    buttonActionFilled: {
        background: '#737373',
        text: '#e3e3e3',
        border: '1px solid #737373',
        filter: 'drop-shadow(0px 2px 2px #6b6b6b)',
        hover: {
            background: '#545454'
        }
    },
    buttonActionOutlined: {
        background: '#e6e6e6',
        text: '#454545',
        border: '1px solid #454545',
        filter: 'drop-shadow(0px 2px 2px #6B6B6B);',
        hover: {
            background: '#d3d3d3'
        },
        disabled: {
            background: '#c5c5c5',
            text: '#6e6e6e'
        }
    },
    buttonLDMode: {
        background: '#3660e3',
        text: '#FFFFFF',
        border: '1px solid #F1f1F1',
        filter: 'drop-shadow(0px 2px 2px #6B6B6B);',
        hover: {
            background: '#124c92'
        },
        disabled: {
            background: '#c5c5c5',
            text: '#6e6e6e'
        }
    },
    buttonDarkShadow: {
        background: '#f9f9f9ff',
        dropShadowRgba: 'drop-shadow(0px 4px 2px #00000040)',
        borderRadius: '7px',
        text: '#00000080',
        hover: {
            background: '#e1e1e1ff'
        }
    },
    analysisDetails: {
        cardBackground: '#e6e6e6',
        color: '#3e3e3e',
        tableResults: {
            switchMode: {
                backgroundSwitchChecked: '#55b9f3',
                backgroundSwitchNotChecked: '#b3b3b3',
                aiBackground: '#a9a9a9'
            },
            background: '#e6e6e6',
            title: '#737373',
            content: '#111111',
            borderColor: '#cfcfcf',
            backgroundContent: '#e8e8e8',
            warning: {
                background: '#e8cfd5',
                color: '#f80140'
            }
        },
        status: {
            title: '#737373',
            value: '#454545'
        },
        imageComparative: {
            explicabilityBackground: '#0000008c'
        },
        imageDetails: {
            statistics: {
                cardBackground: '#dadada',
                cardBorder: '1px solid #d6d6d6',
                titleColor: '#727272',
                valueColor: '#454545'
            },
            currentImageName: {
                color: '#454545'
            }
        },
        pdfInput: {
            patientNameBackground: '#f2f2f2'
        },
        medicalReport: {
            textColor: '#111111',
            button: { shadow: '0px 2px 5px #6B6B6B' },
            tableResultsUpdate: {
                background: '#f0f0f0',
                title: '#737373',
                content: '#111111',
                borderColor: '#cfcfcf',
                backgroundContent: '#f9f9f9'
            },
            inputFields: {
                inputText: '#111111',
                borderColor: '#737373',
                label: '#9f9f9f',
                comment: {
                    border: '1px solid #111111',
                    text: '#111111',
                    backgroundDefault: '#e6e6e6',
                    backgroundActive: '#f0f0f0'
                }
            }
        }
    },
    buttonLightShadow: {
        borderBackground: '#ffffff4d',
        boxShadow: '0px 4px 2px #dadada',
        borderRadius: '10px',
        backgroundIcon: '#FFFFFF',
        backgroundText: '#f2f2f2ff',
        contentText: '#000000b3',
        iconColor: '#4b4b4b',
        filter: 'drop-shadow(0px 4px 2px #6B6B6B)',
        hover: {
            iconColor: '#e3e3e3',
            backgroundIcon: '#737373',
            boxShadow: '0px 2px 2px #141414'
        }
    },
    patientBlock: {
        hoverColor: 'rgba(0, 0, 0, 0.12)'
    },
    account: {
        textColor: '#000000',
        background: '#F2F2F2',
        container: '#E6E6E6',
        iconColor: '#4B4B4B'
    },
    upload: {
        text: '#000000',
        textAlternative: '#000000',
        background: '#FFFFFF',
        backgroundButton: '#F2F2F2'
    },
    page: {
        background: '#FFFFFF',
        text: '#000000',
        invertedText: '#CFCFCF',
        lightText: '#3b3b3b',
        hypertext: '#0748cc'
    },
    colors,
    getSpacing: getSpacing(spacing)
};

