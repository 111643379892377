import React, { useCallback, useContext, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { t } from '@/i18n.js';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import styled from 'styled-components';

import { Typography } from '@/components/atoms';
import { Dialog } from '@/components/molecules';

import { IStoredAnalysis } from '@api';
import { HasOpenedService } from '@/services/HasOpenedService';
import { Session } from '@/utils/contexts/SessionContext';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Props = {
    /**The analysis to open*/
    selectedR: number[];
    currentSessionInCookie: Session;
};

export const MarkAsReadSelected = (props: Props) => {
    const { selectedR, currentSessionInCookie } = props;
    const { setStoredAnalysisListContext, storedAnalysisListContext } = useContext(StoredAnalysisListContext);

    const [isLoading, setIsLoading] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(!dialogOpen);
    };

    const onClickDialog = useCallback(async () => {
        setIsLoading(true);
        const stored = new Array<IStoredAnalysis | undefined>(0);
        for (const selectedRow of selectedR) {
            stored.push(storedAnalysisListContext.find((analysis) => analysis.id == selectedRow));
        }
        const hasOpenedService = new HasOpenedService(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        let analysisIdList: number[] = [];
        for (const a of stored) {
            if (a != undefined) {
                analysisIdList.push(a.id);
            }
        }
        await hasOpenedService.hasBeenOpened({ analysisIdList: analysisIdList }).then((markedAsNotReadIdList) => {
            let updatedRows = [];
            for (const stored of storedAnalysisListContext) {
                let s = stored;
                if (markedAsNotReadIdList.includes(s.id)) {
                    s.hasBeenOpened = true;
                }
                updatedRows.push(s);
            }
            setStoredAnalysisListContext(updatedRows);
        });
        setIsLoading(false);
        setDialogOpen(false);
    }, [storedAnalysisListContext, currentSessionInCookie, selectedR]);

    return (
        <>
            <Dialog
                open={dialogOpen}
                customTitle={t('analysisExplorer.analysisTable.deleteDialogWarning')}
                text={t('analysisExplorer.analysisTable.areYouSureYouWantToMarkSelected')}
                onClose={onClickDialog}
                disableChildrenButton={isLoading}
                childrenButton={
                    isLoading ? (
                        <Typography variant="button">{t('analysisExplorer.analysisTable.loading')}</Typography>
                    ) : (
                        <Typography red variant="button">
                            {t('analysisExplorer.analysisTable.markSelected')}
                        </Typography>
                    )
                }
                withCancel
                disableBackdropClick
                disableEscapeKeyDown
                handleCancel={handleClick}
            />
            {selectedR.length == 0 ? (
                <></>
            ) : (
                <Tooltip title={t('analysisExplorer.analysisTable.markSelected') || ''}>
                    <IconButton onClick={handleClick}>
                        <SDoneAllIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default MarkAsReadSelected;

const SDoneAllIcon = styled(DoneAllIcon)`
    color: ${(props) => props.theme.table.contentLightText};
`;

