import { DateTime } from 'luxon';

import { ClassificationResult, IStoredAnalysis } from '@api';
import { ILineData, ILineObject } from '@/components/atoms/LineChart';
import { CadRadsStats } from '@/components/molecules/PieChartStatistics';

export interface IStatistics {
    cadRadsStats: CadRadsStats;
    lineData: ILineData;
    oldestStoredDate: Date | null;
    mostRecentDate: Date | null;
    storedAnalysisLength: number;
}

export const statisticsInitial: IStatistics = {
    cadRadsStats: {
        [ClassificationResult.Rads0]: 0,
        [ClassificationResult.Rads1]: 0,
        [ClassificationResult.Rads12]: 0,
        [ClassificationResult.Rads2]: 0,
        [ClassificationResult.Rads23]: 0,
        [ClassificationResult.Rads3]: 0,
        [ClassificationResult.Rads34]: 0,
        [ClassificationResult.Rads4]: 0,
        [ClassificationResult.Rads5]: 0
    },
    lineData: {
        lineDataCadRads0: [],
        lineDataCadRads1: [],
        lineDataCadRads12: [],
        lineDataCadRads2: [],
        lineDataCadRads23: [],
        lineDataCadRads3: [],
        lineDataCadRads34: [],
        lineDataCadRads4: [],
        lineDataCadRads5: []
    },
    oldestStoredDate: new Date('2100-01-01'),
    mostRecentDate: new Date(),
    storedAnalysisLength: 0
};

const generateLineData = (stAnalysis: IStoredAnalysis, curLineData: ILineObject[]) => {
    if (stAnalysis.createdOn) {
        const curDateToLookFor = DateTime.fromJSDate(stAnalysis.createdOn || new Date()).toISODate();
        const curDateIndex = curLineData.findIndex((tmp) => tmp.x === curDateToLookFor);
        if (curDateIndex !== -1) {
            curLineData[curDateIndex].y++;
        } else {
            curLineData.push({
                x: curDateToLookFor,
                y: 1
            });
        }
    }
};

const byDate = (a: ILineObject, b: ILineObject) => {
    const aDate = DateTime.fromISO(a.x);
    const bDate = DateTime.fromISO(b.x);
    if (aDate < bDate) {
        return -1;
    }
    if (aDate > bDate) {
        return 1;
    }
    return 0;
};

const generateStats = (st: IStoredAnalysis, statisticsObject: IStatistics) => {
    if (st.classificationResult === ClassificationResult.Rads0) {
        statisticsObject.cadRadsStats['CAD-RADS 0']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads0);
    } else if (st.classificationResult === ClassificationResult.Rads1) {
        statisticsObject.cadRadsStats['CAD-RADS 1']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads1);
    } else if (st.classificationResult === ClassificationResult.Rads12) {
        statisticsObject.cadRadsStats['CAD-RADS 2']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads12);
    } else if (st.classificationResult === ClassificationResult.Rads2) {
        statisticsObject.cadRadsStats['CAD-RADS 2']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads2);
    } else if (st.classificationResult === ClassificationResult.Rads23) {
        statisticsObject.cadRadsStats['CAD-RADS 3']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads2);
    } else if (st.classificationResult === ClassificationResult.Rads3) {
        statisticsObject.cadRadsStats['CAD-RADS 3']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads3);
    } else if (st.classificationResult === ClassificationResult.Rads34) {
        statisticsObject.cadRadsStats['CAD-RADS 4']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads34);
    } else if (st.classificationResult === ClassificationResult.Rads4) {
        statisticsObject.cadRadsStats['CAD-RADS 4']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads4);
    } else if (st.classificationResult === ClassificationResult.Rads5) {
        statisticsObject.cadRadsStats['CAD-RADS 5']++;
        statisticsObject.storedAnalysisLength++;
        generateLineData(st, statisticsObject.lineData.lineDataCadRads5);
    }
};

export const gatherStatistics = (
    stAnalysis: IStoredAnalysis[],
    withDateRange?: { from: Date | null; to: Date | null }
): IStatistics => {
    const statisticsObject: IStatistics = {
        cadRadsStats: {
            [ClassificationResult.Rads0]: 0,
            [ClassificationResult.Rads1]: 0,
            [ClassificationResult.Rads12]: 0,
            [ClassificationResult.Rads2]: 0,
            [ClassificationResult.Rads23]: 0,
            [ClassificationResult.Rads3]: 0,
            [ClassificationResult.Rads34]: 0,
            [ClassificationResult.Rads4]: 0,
            [ClassificationResult.Rads5]: 0
        },
        lineData: {
            lineDataCadRads0: [],
            lineDataCadRads1: [],
            lineDataCadRads12: [],
            lineDataCadRads2: [],
            lineDataCadRads23: [],
            lineDataCadRads3: [],
            lineDataCadRads34: [],
            lineDataCadRads4: [],
            lineDataCadRads5: []
        },
        oldestStoredDate: new Date('2100-01-01'),
        mostRecentDate: new Date(),
        storedAnalysisLength: 0
    };

    loopThroughStoredAnalysis(stAnalysis, statisticsObject, withDateRange);

    statisticsObject.lineData.lineDataCadRads0 = statisticsObject.lineData.lineDataCadRads0.sort(byDate);
    statisticsObject.lineData.lineDataCadRads1 = statisticsObject.lineData.lineDataCadRads1.sort(byDate);
    statisticsObject.lineData.lineDataCadRads12 = statisticsObject.lineData.lineDataCadRads12.sort(byDate);
    statisticsObject.lineData.lineDataCadRads2 = statisticsObject.lineData.lineDataCadRads2.sort(byDate);
    statisticsObject.lineData.lineDataCadRads23 = statisticsObject.lineData.lineDataCadRads23.sort(byDate);
    statisticsObject.lineData.lineDataCadRads3 = statisticsObject.lineData.lineDataCadRads3.sort(byDate);
    statisticsObject.lineData.lineDataCadRads34 = statisticsObject.lineData.lineDataCadRads34.sort(byDate);
    statisticsObject.lineData.lineDataCadRads4 = statisticsObject.lineData.lineDataCadRads4.sort(byDate);
    statisticsObject.lineData.lineDataCadRads5 = statisticsObject.lineData.lineDataCadRads5.sort(byDate);
    return statisticsObject;
};

const loopThroughStoredAnalysis = (
    storedAnalysis: IStoredAnalysis[],
    statisticsObject: IStatistics,
    withDateRange?: { from: Date | null; to: Date | null }
) => {
    if (withDateRange) {
        storedAnalysis.forEach((st) => {
            if (st.createdOn) {
                const currentIsoDate = new Date(st.createdOn);
                if (
                    withDateRange.from &&
                    withDateRange.from <= currentIsoDate &&
                    withDateRange.to &&
                    currentIsoDate <= withDateRange.to
                ) {
                    generateStats(st, statisticsObject);
                }
            }
        });
    } else {
        storedAnalysis.forEach((st) => {
            generateStats(st, statisticsObject);
            if (st.createdOn) {
                const currentIsoDate = new Date(st.createdOn);
                if (statisticsObject.oldestStoredDate && currentIsoDate < statisticsObject.oldestStoredDate) {
                    statisticsObject.oldestStoredDate = currentIsoDate;
                }
            }
        });
    }
};

