import React, { useState } from 'react';
import styled from 'styled-components';

import { getCookieConsentValue } from 'react-cookie-consent';
import Cookies from 'js-cookie';

import { t } from '@/i18n.js';
import { Button, Typography } from '@/components/atoms';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';

import Checkbox from '@mui/material/Checkbox';

import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';

type Props = {
    openCookieManagement: boolean;
    setOpenCookieManagement: (arg: boolean) => void;
};

export const CookieManagement = (props: Props) => {
    const { openCookieManagement, setOpenCookieManagement } = props;

    const [themeContext, updateTheme] = useThemeContext();

    const isConsent = getCookieConsentValue();
    const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(true ? isConsent === 'true' : false);

    const handleClose = () => {
        setOpenCookieManagement(!openCookieManagement);
    };

    const handleChange = () => {
        if (cookiesAccepted) {
            Cookies.set('CookieConsent', 'false');
        } else {
            Cookies.set('CookieConsent', 'true');
        }
        setCookiesAccepted(!cookiesAccepted);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={openCookieManagement}
            PaperProps={{
                style: {
                    backgroundColor: themeContext.isLightMode ? lightTheme.page.background : darkTheme.page.background
                }
            }}
        >
            <DialogTitle
                sx={{
                    color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                    backgroundColor: themeContext.isLightMode ? lightTheme.page.background : darkTheme.page.background
                }}
            >
                <Typography lightbold>{t('cookie.management.title').toLocaleUpperCase()}</Typography>
            </DialogTitle>
            <SContainer>
                <SColumn>
                    <Typography>{t('cookie.management.text')}</Typography>
                    <br></br>
                    <SRowContainer>
                        <SLeft>
                            <Typography thin size2>
                                {t('cookie.management.googleAnalytics')}
                            </Typography>
                        </SLeft>
                        <SRight>
                            <Checkbox checked={cookiesAccepted} onClick={() => handleChange()}></Checkbox>
                        </SRight>
                    </SRowContainer>
                </SColumn>
            </SContainer>
            <DialogActions
                sx={{
                    color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                    backgroundColor: themeContext.isLightMode ? lightTheme.page.background : darkTheme.page.background
                }}
            >
                <Button onClick={handleClose}>{t('dialog.close')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CookieManagement;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.getSpacing(2)};
    background-color: ${(props) => props.theme.page.background};
    color: ${(props) => props.theme.page.text};
    text-align: justify;
`;

const SColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.getSpacing(2)};
    background-color: ${(props) => props.theme.page.background};
    color: ${(props) => props.theme.page.text};
`;

const SRowContainer = styled.div`
    display: flex;
    margin: ${(props) => props.theme.getSpacing(1)};
    background-color: ${(props) => props.theme.page.background};
    color: ${(props) => props.theme.page.text};
    align-items: center;
    justify-content: space-between;
`;

const SLeft = styled.div`
    display: flex;
    max-width: 85%;
`;

const SRight = styled.div`
    display: flex;
    max-width: 10%;
`;

