import React from 'react';
import { ResponsiveLine, Serie } from '@nivo/line';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';
import uuid from 'uuidv4';

type Props = {
    /**Data which will be displayed, be carefull a special formatting is requried (see nivo library for more info) */
    data: Serie[];
    colors: Array<string>;
};

const margin = { top: 40, right: 200, bottom: 40, left: 40 };

export const LineChartHome = (props: Props) => {
    const { data, colors } = props;

    const [themeContext, updateTheme] = useThemeContext();

    const getValue = (val: any) => {
        if (typeof val == 'number') {
            return val;
        }
        return 0;
    };

    const getMaxData = (dt: Serie[]) => {
        let max = 0;
        for (let coroId = 0; coroId < dt.length; coroId++) {
            for (let monthId = 0; monthId < dt[coroId].data.length; monthId++) {
                max = Math.max(max, getValue(dt[coroId].data[monthId].y));
            }
        }
        return max;
    };

    return (
        <ResponsiveLine
            data={data}
            sliceTooltip={({ slice }) => (
                <div style={{ margin: '5px', backgroundColor: 'white' }}>
                    {slice.points.map((point) => (
                        <div key={uuid()} style={{ margin: '5px', fontSize: '14px' }}>
                            <span style={{ color: point.color }}>{point.serieId}</span>: {point.data.y}
                        </div>
                    ))}
                </div>
            )}
            enableSlices="x"
            useMesh={true}
            margin={margin}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                reverse: false
            }}
            enableGridX={false}
            yFormat=" >-.1f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: getMaxData(data)
            }}
            gridYValues={getMaxData(data)}
            lineWidth={4}
            colors={colors}
            pointBorderWidth={6}
            pointLabelYOffset={-12}
            legends={[
                {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 78,
                    itemHeight: 50,
                    itemOpacity: 1,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    itemTextColor: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                }
            ]}
        />
    );
};

export default LineChartHome;

