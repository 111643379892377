import { createContext } from 'react';
import React from 'react';
import { useContextFactory } from '@/utils/contexts/ContextFactory';

export interface Screenshot {
    screenString: string; //bas64
}

export const nullScreenshot = {
    screenString: ''
};

export const ScreenshotContext = createContext<[Screenshot, (screen: Screenshot) => void]>([nullScreenshot, () => {}]);

export const useScreenshotContext = useContextFactory('ScreenshotContext', ScreenshotContext);

export const ScreenshotContextProvider: React.FC = (props) => {
    const initScreenshot = () => {
        return nullScreenshot;
    };

    const [screenshotInStorage, setScreenshotInStorage] = React.useState<Screenshot>(initScreenshot);

    const updateScreenshot = (screenshot: Screenshot) => {
        setScreenshotInStorage(screenshot);
    };

    const defaultScreenshotContext: [Screenshot, typeof updateScreenshot] = [screenshotInStorage, updateScreenshot];
    return <ScreenshotContext.Provider value={defaultScreenshotContext}>{props.children}</ScreenshotContext.Provider>;
};
