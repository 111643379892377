let containsUL = false;
let containsLL = false;
let containsN = false;
let containsSC = false;
let contains8C = false;
let allValid = true;

export type PasswordValidation = {
    /**All conditions are valid */
    allValid: boolean;
    /** Contains a label and if it has been validated or not  */
    mustContainData: [string, boolean][];
};

export const validatePassword = (password: string) => {
    containsUL = password.toLowerCase() != password;
    containsLL = password.toUpperCase() != password;
    containsN = /\d/.test(password);
    containsSC = /[~`!#$%\^&*+=.\-\[\]\\';,/{}|\\":<>\?]/g.test(password);
    contains8C = password.length >= 8;

    allValid = containsUL && containsLL && containsN && containsSC && contains8C;

    const mustContainData: [string, boolean][] = [
        ['An uppercase letter (A-Z)', containsUL],
        ['A lowercase letter (a-z)', containsLL],
        ['A number (0-9)', containsN],
        ['A special character (!@#$)', containsSC],
        ['At least 8 characters', contains8C]
    ];

    const passwordValidation: PasswordValidation = { allValid, mustContainData };
    return passwordValidation;
};
