import React from 'react';
import { Button as DefaultMaterialButton } from '@mui/material/';
import styled from 'styled-components';

export const WhiteButton = styled(({ ...otherProps }) => <DefaultMaterialButton {...otherProps} />)`
    && {
        justify-content: center;
        text-align: center;
        color: ${(props) => props.theme.colors.darkgrey};
        background-color: ${(props) => props.theme.colors.white};
        border: 1px solid transparent;
        border-radius: 8px;
        width: 100%;
        margin: ${(props) => props.theme.getSpacing(4, 4, 4, 4)};
        &:disabled {
            color: ${(props) => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.darkgrey};
            background: ${(props) => props.theme.colors.darkgrey};
        }
        &:hover {
            background-color: ${(props) => props.theme.colors.lightgrey};
        }
    }
`;

export default WhiteButton;

