import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { ButtonBlueDarkBack, Typography } from '@/components/atoms';

import { t } from '@/i18n.js';
import Flower_Spimed_Neumorph from '@/img/flower_Spimed_Neumorph.png';

export const ForgotPasswordValidation = () => {
    const currentHistory = useHistory();

    return (
        <SContainer>
            <Typography title variant="h1">
                {t('settings.resetPswd')}
            </Typography>
            <STypographyThin paragraph variant="subtitle2">
                <Simg src={Flower_Spimed_Neumorph} />
                <div>{t('resetPassword.emailSent')}</div>
                <STypographyBold>{t('resetPassword.emailSentHour')}</STypographyBold>
                <STypographyYellow>{t('resetPassword.verifySpam')}</STypographyYellow>
            </STypographyThin>
            <SButtonBlueDarkBackContainer>
                <ButtonBlueDarkBack onClick={() => currentHistory.push('/login')}>
                    {t('resetPassword.redirection')}
                </ButtonBlueDarkBack>
            </SButtonBlueDarkBackContainer>
        </SContainer>
    );
};

export default ForgotPasswordValidation;

const SContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.darkblue};
    color: ${(props) => props.theme.colors.white};
`;

const STypographyThin = styled(Typography)`
    && {
        font-weight: 100;
        text-align: center;
    }
`;

const STypographyBold = styled(Typography)`
    && {
        font-weight: 500;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
    }
`;

const STypographyYellow = styled(Typography)`
    && {
        font-weight: 500;
        color: #f0ff00;
    }
`;

const SButtonBlueDarkBackContainer = styled.div`
    width: 30%;
`;

const Simg = styled.img`
    height: 20vh;
    margin-bottom: 20px;
`;

