import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import {
    DataGrid,
    GridCellParams,
    GridColumnHeaderParams,
    GridColumns,
    GridKeyValue,
    GridRowParams,
    GridSortCellParams,
    GridSortModel,
    GridValueGetterParams,
    GridCellModesModel,
    GridCellModes,
    GridRowModel
} from '@mui/x-data-grid';
import i18n, { t } from '@/i18n.js';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import ErrorIcon from '@mui/icons-material/Error';

import { DoctorEntity, Session } from '@/utils/contexts/SessionContext';
import { SaveReader, StoredAnalysisServiceAxios } from '@/services/StoredAnalysisService';
import { HybridService } from '@/services/HybridService';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import {
    ClassificationResultField,
    DateTimeField,
    DownloadAnalysisField,
    ReaderField,
    HighlightedField,
    Loader,
    MemoLabelField,
    OpenAnalysisField,
    PatientBlock,
    Typography,
    NoteField,
    ClassificationResultFieldDoctor,
    RouterLink
} from '@/components/atoms';

import { Operation } from '@/utils/hooks/useSuccess';
import { ClassificationResult, IStoredAnalysis, StoredAnalysis } from '@api';

import noDataRows from '@/utils/animations/no-data-rows.json';

import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';

import DataGridHeaderSelected from '@/components/molecules/DataGridHeaderSelected';
import { DataObject } from '@/components/organisms/AnalysisExplorerComponent';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

export type DbEntry = {
    guid: string;
    label: string;
};

type Props = {
    /**Use to handle the status of the fetch all analysis */
    success: Operation;
    storedAnalysis: IStoredAnalysis[];
    storedAnalysisIdToReview: number[];
    medicalReportIdAndClassificationResult: DataObject;
    /** Columns to display */
    columnsNeeded: string[];
    nbPages: number;
    isLoading: boolean;
    currentId: number;
    setCurrentId: Dispatch<SetStateAction<number>>;
    onlyNew: boolean;
    multipleFilter: string[];
    doctorsFilter: string[];
    searchFilter: string;
    startDate: Date | null;
    endDate: Date | null;
    setFirstAnalysisIDfromGrid: Dispatch<SetStateAction<number>>;
    currentSessionInCookie: Session;
    themeContext: any;
    isTableHome: boolean;
};

export interface StyleProps {
    root_icon_sort: string;
    root_table_header_background: string;
    root_table_header_text: string;
    root_table_content_background: string;
    root_table_content_text: string;
    root_table_content_divider: string;
    root_cell_edit_background: string;
    row_current_id: string;
}

export const AnalysisTable = (props: Props) => {
    const {
        success,
        storedAnalysis,
        storedAnalysisIdToReview,
        medicalReportIdAndClassificationResult,
        columnsNeeded,
        nbPages,
        isLoading,
        currentId,
        setCurrentId,
        onlyNew,
        multipleFilter,
        doctorsFilter,
        searchFilter,
        startDate,
        endDate,
        setFirstAnalysisIDfromGrid,
        currentSessionInCookie,
        themeContext,
        isTableHome
    } = props;
    const { setStoredAnalysisListContext } = React.useContext(StoredAnalysisListContext);
    const [modificationOfCurrentPage, setModificationOfCurrentPage] = useState<number>(0);

    const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
    const [error, setError] = useState<boolean | null>();
    const [pageSize, setPageSize] = useState(nbPages);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'id', sort: 'desc' }]);

    const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

    const [snackbarContext, updateSnackbar] = useSnackbarContext();
    const [isLocalDbFetched, setIsLocalDbFetched] = useState<boolean>(false);

    const [firstAnalysisID, updateFirstAnalysisID] = useState<number>(0);

    const doctorsEntityString = localStorage.getItem('doctors_entity');
    const doctorsEntity = doctorsEntityString
        ? (JSON.parse(doctorsEntityString) as DoctorEntity[])
        : ([] as DoctorEntity[]);

    const paginationSize = JSON.parse(localStorage.getItem('pagination_size') || '"30"');

    let doctorArray = doctorsEntity.filter(
        (item) => item.name !== '' && item.name !== null && item.mail !== '' && item.mail !== null
    );
    let doctorNames = doctorArray.map((doctor: DoctorEntity) => doctor.name);

    const propsStyle: StyleProps = {
        root_icon_sort: themeContext.isLightMode ? lightTheme.table.iconSortColor : darkTheme.table.iconSortColor,
        root_table_header_background: themeContext.isLightMode
            ? lightTheme.table.headerBackground
            : darkTheme.table.headerBackground,
        root_table_header_text: themeContext.isLightMode ? lightTheme.table.headerText : darkTheme.table.headerText,
        root_table_content_background: themeContext.isLightMode
            ? lightTheme.table.contentBackground
            : darkTheme.table.contentBackground,
        root_table_content_text: themeContext.isLightMode ? lightTheme.table.contentText : darkTheme.table.contentText,
        root_table_content_divider: themeContext.isLightMode
            ? lightTheme.table.contentDivider
            : darkTheme.table.contentDivider,
        root_cell_edit_background: themeContext.isLightMode
            ? lightTheme.table.editBackground
            : darkTheme.table.editBackground,
        row_current_id: themeContext.isLightMode ? lightTheme.miniTable.currentId : darkTheme.miniTable.currentId
    };
    const classes = useStyles(propsStyle);

    const handlePageChange = (event: any) => {
        localStorage.setItem('current_pagination_analysis_table', event.detail.newPage);
        // Called in memo of analysis tabl to apply the pagination of local storage
        setModificationOfCurrentPage(event.detail.newPage);
    };

    // Hack to store the current pagination and reapply it without mui bug https://github.com/mui/mui-x/issues/5071
    useEffect(() => {
        window.addEventListener('pageChange', handlePageChange);

        return () => {
            window.removeEventListener('pageChange', handlePageChange);
        };
    }, []);

    useEffect(() => {
        if (!success.isSuccess && !success.isLoading) {
            setError(true);
        } else {
            setError(null);
        }
    }, [success.isSuccess, success.isLoading]);

    // Load guid from local file db
    useEffect(() => {
        if (process.env.DeploymentMode && process.env.DeploymentMode == ModeConstant.HYBRID) {
            (async function iifeFunction() {
                setIsLocalDbFetched(false);
                const getLocalDbService = new HybridService({ token: currentSessionInCookie.authResponse.token });
                const localDb = await getLocalDbService.GetLocalDb();
                localDb.map((dbEntry: DbEntry) => {
                    storedAnalysis.forEach((stAnalysis) => {
                        if (stAnalysis.analysisGuid === dbEntry.guid) {
                            stAnalysis.label = dbEntry.label;
                        }
                    });
                });
                setIsLocalDbFetched(true);
            })();
        }
    }, [success.isLoading, storedAnalysis]);

    useEffect(() => {
        setFirstAnalysisIDfromGrid(firstAnalysisID);
    }, [firstAnalysisID]);

    const onSortModelChange = useCallback((params) => {
        setSortModel(params);
    }, []);

    // Activate modification with one click instead of a double click
    const handleCellClick = React.useCallback((params: GridCellParams) => {
        setCellModesModel((prevModel) => {
            return {
                // Revert the mode of the other cells from other rows
                ...Object.keys(prevModel).reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: Object.keys(prevModel[id]).reduce(
                            (acc2, field) => ({
                                ...acc2,
                                [field]: { mode: GridCellModes.View }
                            }),
                            {}
                        )
                    }),
                    {}
                ),
                [params.id]: {
                    // Revert the mode of other cells in the same row
                    ...Object.keys(prevModel[params.id] || {}).reduce(
                        (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                        {}
                    ),
                    [params.field]: {
                        mode: params.isEditable ? GridCellModes.Edit : GridCellModes.View
                    }
                }
            };
        });
    }, []);

    const handleRowEnter = (event: Event) => {
        if (event.currentTarget) {
            if (themeContext.isLightMode) {
                (event.currentTarget as HTMLTextAreaElement).classList.add('row-hover-light-css');
            } else {
                (event.currentTarget as HTMLTextAreaElement).classList.add('row-hover-dark-css');
            }
        }
    };
    const handleRowLeave = (event: Event) => {
        if (event.currentTarget) {
            if (themeContext.isLightMode) {
                (event.currentTarget as HTMLTextAreaElement).classList.remove('row-hover-light-css');
            } else {
                (event.currentTarget as HTMLTextAreaElement).classList.remove('row-hover-dark-css');
            }
        }
    };

    const handleCellModesModelChange = React.useCallback((newModel) => {
        setCellModesModel(newModel);
    }, []);

    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) =>
            new Promise<GridRowModel>((resolve, reject) => {
                if (newRow.label != oldRow.label) {
                    (async () => {
                        const storedAnalysisService = new StoredAnalysisServiceAxios(
                            { token: currentSessionInCookie.authResponse.token },
                            process.env.BackendServiceURL
                        );
                        try {
                            storedAnalysisService
                                .saveLabel({
                                    analysisId: newRow.id as number,
                                    value: newRow.label
                                })
                                .then((response) => {
                                    if (response == 400) {
                                        updateSnackbar({
                                            snackString: t('snackbar.error.analysisTable.label.validation'),
                                            severity: 'error'
                                        });
                                        return resolve(oldRow);
                                    }
                                    if (response == 500) {
                                        updateSnackbar({
                                            snackString: t('snackbar.error.analysisTable.label.generic'),
                                            severity: 'error'
                                        });
                                        return resolve(oldRow);
                                    }
                                    const updatedRows = storedAnalysis.map((row: IStoredAnalysis) => {
                                        if (row.id === newRow.id) {
                                            return { ...row, label: newRow.label };
                                        }
                                        return row;
                                    });
                                    setStoredAnalysisListContext(updatedRows);
                                    return resolve(newRow);
                                });
                        } catch (err) {
                            console.log(err);
                            return resolve(oldRow);
                        }
                    })();
                } else if (newRow.reader != oldRow.reader) {
                    var error = false;
                    (async () => {
                        try {
                            await SaveReader({
                                sessionContext: currentSessionInCookie,
                                analysisId: newRow.id as number,
                                value: newRow.reader
                            });
                        } catch (e) {
                            error = true;
                        } finally {
                            if (error) {
                                updateSnackbar({
                                    snackString: t('snackbar.error.analysisTable.reader'),
                                    severity: 'error'
                                });
                                return resolve(oldRow);
                            }
                            const updatedRows = storedAnalysis.map((row: IStoredAnalysis) => {
                                if (row.id === newRow.id) {
                                    return { ...row, reader: newRow.reader };
                                }
                                return row;
                            });
                            setStoredAnalysisListContext(updatedRows);
                            return resolve(newRow);
                        }
                    })();
                } else if (newRow.memo != oldRow.memo) {
                    (async () => {
                        const storedAnalysisService = new StoredAnalysisServiceAxios(
                            { token: currentSessionInCookie.authResponse.token },
                            process.env.BackendServiceURL
                        );
                        try {
                            storedAnalysisService
                                .saveMemo({
                                    analysisId: newRow.id as number,
                                    value: newRow.memo
                                })
                                .then((response) => {
                                    if (response == 400) {
                                        updateSnackbar({
                                            snackString: t('snackbar.error.analysisTable.memo.validation'),
                                            severity: 'error'
                                        });
                                        return resolve(oldRow);
                                    }
                                    if (response == 500) {
                                        updateSnackbar({
                                            snackString: t('snackbar.error.analysisTable.memo.generic'),
                                            severity: 'error'
                                        });
                                        return resolve(oldRow);
                                    }
                                    const updatedRows = storedAnalysis.map((row: IStoredAnalysis) => {
                                        if (row.id === newRow.id) {
                                            return { ...row, memo: newRow.memo };
                                        }
                                        return row;
                                    });
                                    setStoredAnalysisListContext(updatedRows);

                                    return resolve(newRow);
                                });
                        } catch (err) {
                            console.log(err);
                            return resolve(oldRow);
                        }
                    })();
                }
                return resolve(oldRow); // No changes
            }),
        [storedAnalysis]
    );

    const customComparator = (
        _v1: GridKeyValue,
        _v2: GridKeyValue,
        _cellParams1: GridSortCellParams,
        _cellParams2: GridSortCellParams,
        _property: string
    ) => {
        const aOrder = _v1;
        const bOrder = _v2;

        if (aOrder != null && bOrder != null) {
            if (bOrder < aOrder) {
                return -1;
            }
            if (bOrder > aOrder) {
                return 1;
            }
        }
        return 0;
    };

    let columns: GridColumns = useMemo(() => {
        let col: GridColumns = [];
        let columnsRaw: GridColumns = [];
        if (isTableHome) {
            columnsRaw = [
                {
                    field: 'id',
                    width: 125,
                    align: 'left',
                    headerAlign: 'center',
                    headerName: t('analysisExplorer.analysisTable.analysis'),
                    renderCell: (params) => (
                        <SRouterLinkTableHome to={'/analysis-explorer/' + (params.row as StoredAnalysis).id}>
                            <PatientBlock
                                id={(params.row as StoredAnalysis).id.toString()}
                                storedAnalysis={params.row as StoredAnalysis}
                            />
                        </SRouterLinkTableHome>
                    ),
                    disableColumnMenu: true
                },

                {
                    field: 'classificationResult',
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.classificationResult'),
                    filterable: true,
                    sortable: true,
                    disableColumnMenu: true,
                    editable: false,
                    minWidth: 130,
                    flex: 2,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <SRouterLinkTableHome to={'/analysis-explorer/' + (params.row as StoredAnalysis).id}>
                                {medicalReportIdAndClassificationResult[(params.row as StoredAnalysis).id] ? (
                                    <>
                                        <ClassificationResultFieldDoctor
                                            classificationResult={
                                                medicalReportIdAndClassificationResult[
                                                    (params.row as StoredAnalysis).id
                                                ]
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <ClassificationResultField
                                            storedAnalysis={params.row as StoredAnalysis}
                                            currentSessionInCookie={currentSessionInCookie}
                                        />
                                    </>
                                )}
                            </SRouterLinkTableHome>
                        );
                    },
                    valueGetter: (params: GridValueGetterParams) =>
                        medicalReportIdAndClassificationResult[(params.row as StoredAnalysis).id]
                            ? medicalReportIdAndClassificationResult[(params.row as StoredAnalysis).id]
                            : (params.row as StoredAnalysis).classificationResult
                },
                {
                    field: 'createdOn',
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.createDate'),
                    disableColumnMenu: true,
                    type: 'date',
                    minWidth: 80,
                    flex: 1.5,
                    renderCell: (params: GridCellParams) => (
                        <SRouterLinkTableHome to={'/analysis-explorer/' + (params.row as StoredAnalysis).id}>
                            <DateTimeField
                                date={(params.row as StoredAnalysis).createdOn}
                                hasBeenOpened={(params.row as StoredAnalysis).hasBeenOpened}
                            />
                        </SRouterLinkTableHome>
                    )
                },
                {
                    field: 'label',
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.label'),
                    sortable: true,
                    minWidth: 115,
                    flex: 2,
                    editable: true,
                    valueGetter: (params: GridValueGetterParams) => (params.row as StoredAnalysis).label ?? '',
                    disableColumnMenu: true,
                    renderCell: (params: GridCellParams) => (
                        <MemoLabelField text={(params.row as StoredAnalysis).label} />
                    )
                },
                {
                    field: 'reader',
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.reader'),
                    sortable: true,
                    minWidth: 90,
                    flex: 1.8,
                    editable: true,
                    type: 'singleSelect',
                    description: t('analysisExplorer.analysisTable.dblReader'),
                    valueGetter: (params: GridValueGetterParams) => (params.row as StoredAnalysis).reader ?? '',
                    valueOptions: doctorNames,
                    disableColumnMenu: true,
                    renderCell: (params: GridCellParams) => (
                        <ReaderField
                            reader={(params.row as StoredAnalysis).reader}
                            hasBeenOpened={(params.row as StoredAnalysis).hasBeenOpened}
                        />
                    )
                },
                {
                    field: 'memo',
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.memo'),
                    sortable: true,
                    minWidth: 115,
                    flex: 2.1,
                    editable: true,
                    description: t('analysisExplorer.analysisTable.dblMemo'),
                    valueGetter: (params: GridValueGetterParams) => (params.row as StoredAnalysis).memo ?? '',
                    disableColumnMenu: true,
                    renderCell: (params: GridCellParams) => (
                        <MemoLabelField text={(params.row as StoredAnalysis).memo} />
                    )
                },
                {
                    field: 'status',
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.status'),
                    sortable: true,
                    disableColumnMenu: true,
                    hideSortIcons: false,
                    filterable: false,
                    type: 'string',
                    minWidth: 110,
                    flex: 1.8,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <STypographyReview>
                                {storedAnalysisIdToReview.includes((params.row as StoredAnalysis).id)
                                    ? t('medicalReportStatus.awaiting')
                                    : t('medicalReportStatus.completed')}
                            </STypographyReview>
                        );
                    }
                },
                {
                    field: 'Notes',
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.notes'),
                    sortable: false,
                    minWidth: 70,
                    flex: 1,
                    disableColumnMenu: true,
                    valueGetter: (params: GridValueGetterParams) => (params.row as StoredAnalysis).note ?? '',
                    filterable: false,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <NoteField
                                currentStoredAnalysis={params.row as StoredAnalysis}
                                storedAnalysisNote={(params.row as StoredAnalysis).note}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    }
                },
                {
                    field: 'actions',
                    minWidth: 170,
                    flex: 1.8,
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.actions'),
                    sortable: false,
                    disableColumnMenu: true,
                    description: t('analysisExplorer.analysisTable.actions'),
                    filterable: false,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <>
                                <OpenAnalysisField
                                    storedAnalysis={params.row as StoredAnalysis}
                                    currentSessionInCookie={currentSessionInCookie}
                                />
                                <DownloadAnalysisField
                                    id={(params.row as StoredAnalysis).id}
                                    currentSessionInCookie={currentSessionInCookie}
                                />
                                <HighlightedField
                                    storedAnalysisId={(params.row as StoredAnalysis).id}
                                    currentSessionInCookie={currentSessionInCookie}
                                />
                            </>
                        );
                    }
                },
                {
                    field: 'openAnalysis',
                    width: 60,
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.see'),
                    sortable: false,
                    disableColumnMenu: true,
                    description: t('analysisExplorer.analysisTable.see'),
                    filterable: false,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <OpenAnalysisField
                                storedAnalysis={params.row as StoredAnalysis}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    }
                },
                {
                    field: 'Download',
                    width: 60,
                    align: 'center',
                    headerAlign: 'center',
                    headerName: t('analysisExplorer.analysisTable.download'),
                    sortable: false,
                    disableColumnMenu: true,
                    filterable: false,
                    renderHeader: (_params: GridColumnHeaderParams) => <></>,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <DownloadAnalysisField
                                id={(params.row as StoredAnalysis).id}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    }
                },
                {
                    field: 'highLighted',
                    align: 'center',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.stars'),
                    sortable: true,
                    disableColumnMenu: true,
                    hideSortIcons: false,
                    filterable: false,
                    type: 'string',
                    minWidth: 70,
                    flex: 1,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <HighlightedField
                                storedAnalysisId={(params.row as StoredAnalysis).id}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    },
                    renderHeader: (_params: GridColumnHeaderParams) => <></>,
                    sortComparator: (
                        v1: GridKeyValue,
                        v2: GridKeyValue,
                        cellParams1: GridSortCellParams,
                        cellParams2: GridSortCellParams
                    ) => customComparator(v1, v2, cellParams1, cellParams2, 'highLighted')
                }
            ];
        } else {
            columnsRaw = [
                {
                    field: 'id',
                    width: 125,
                    align: 'left',
                    headerName: t('analysisExplorer.analysisTable.analysis'),
                    renderCell: (params) => (
                        <div
                            onClick={() => {
                                setCurrentId(parseInt((params.row as StoredAnalysis).id.toString()));
                            }}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <SRouterLink to={'/analysis-explorer/' + (params.row as StoredAnalysis).id}>
                                {medicalReportIdAndClassificationResult[(params.row as StoredAnalysis).id] ? (
                                    <>
                                        <div style={{ alignItems: 'center', display: 'flex' }}>
                                            {!(params.row as StoredAnalysis).hasBeenOpened && (
                                                <SFiberManualRecordIcon fontSize="small" />
                                            )}
                                            <SDivId>n° {(params.row as StoredAnalysis).id}</SDivId>
                                        </div>
                                        <ClassificationResultFieldDoctor
                                            removeStenosisText={true}
                                            classificationResult={
                                                medicalReportIdAndClassificationResult[
                                                    (params.row as StoredAnalysis).id
                                                ]
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div style={{ alignItems: 'center', display: 'flex' }}>
                                            {!(params.row as StoredAnalysis).hasBeenOpened && (
                                                <SFiberManualRecordIcon fontSize="small" />
                                            )}
                                            <SDivId>n° {(params.row as StoredAnalysis).id}</SDivId>
                                        </div>
                                        <ClassificationResultField
                                            removeStenosisText={true}
                                            storedAnalysis={params.row as StoredAnalysis}
                                            currentSessionInCookie={currentSessionInCookie}
                                        />
                                    </>
                                )}
                            </SRouterLink>
                        </div>
                    ),
                    disableColumnMenu: true
                },
                {
                    field: 'createdOn',
                    align: 'right',
                    headerAlign: 'right',
                    headerName: t('analysisExplorer.analysisTable.createDate'),
                    disableColumnMenu: true,
                    type: 'date',
                    minWidth: 60,
                    flex: 1.5,
                    renderCell: (params: GridCellParams) => (
                        <DateTimeField date={(params.row as StoredAnalysis).createdOn} />
                    )
                },
                {
                    field: 'label',
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.label'),
                    sortable: true,
                    minWidth: 115,
                    flex: 2.5,
                    disableColumnMenu: true,
                    renderCell: (params: GridCellParams) => (
                        <div
                            onClick={() => {
                                setCurrentId(parseInt((params.row as StoredAnalysis).id.toString()));
                            }}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <SRouterLink to={'/analysis-explorer/' + (params.row as StoredAnalysis).id}>
                                <SLabel>
                                    {(params.row as StoredAnalysis).label ? (params.row as StoredAnalysis).label : ' '}
                                </SLabel>
                            </SRouterLink>
                        </div>
                    )
                },
                {
                    field: 'memo',
                    align: 'left',
                    headerName: t('analysisExplorer.analysisTable.memo'),
                    sortable: true,
                    minWidth: 115,
                    flex: 2.5,
                    editable: true,
                    description: t('analysisExplorer.analysisTable.dblMemo'),
                    valueGetter: (params: GridValueGetterParams) => (params.row as StoredAnalysis).memo ?? '',
                    disableColumnMenu: true
                },
                {
                    field: 'classificationResult',
                    align: 'right',
                    headerAlign: 'right',
                    headerName: t('analysisExplorer.analysisTable.classificationResult'),
                    filterable: true,
                    sortable: true,
                    disableColumnMenu: true,
                    type: 'singleSelect',
                    valueOptions: [
                        ClassificationResult.Rads0,
                        ClassificationResult.Rads12,
                        ClassificationResult.Rads345,
                        ClassificationResult.Rads34,
                        ClassificationResult.Rads5
                    ],
                    editable: false,
                    minWidth: 115,
                    flex: 2.5,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <ClassificationResultField
                                storedAnalysis={params.row as StoredAnalysis}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    }
                },
                {
                    field: 'highLighted',
                    align: 'center',
                    headerAlign: 'center',
                    headerName: t('analysisExplorer.analysisTable.stars'),
                    sortable: true,
                    disableColumnMenu: true,
                    hideSortIcons: false,
                    filterable: false,
                    minWidth: 70,
                    flex: 1,
                    type: 'bool',
                    renderCell: (params: GridCellParams) => {
                        return (
                            <HighlightedField
                                storedAnalysisId={(params.row as StoredAnalysis).id}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    },
                    renderHeader: (_params: GridColumnHeaderParams) => <></>,
                    sortComparator: (
                        v1: GridKeyValue,
                        v2: GridKeyValue,
                        cellParams1: GridSortCellParams,
                        cellParams2: GridSortCellParams
                    ) => customComparator(v1, v2, cellParams1, cellParams2, 'highLighted')
                },
                {
                    field: 'openAnalysis',
                    width: 70,
                    headerAlign: 'center',
                    align: 'center',
                    headerName: t('analysisExplorer.analysisTable.see'),
                    sortable: false,
                    disableColumnMenu: true,
                    description: t('analysisExplorer.analysisTable.see'),
                    filterable: false,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <OpenAnalysisField
                                storedAnalysis={params.row as StoredAnalysis}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    }
                },
                {
                    field: 'Download',
                    minWidth: 100,
                    flex: 2,
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.download'),
                    sortable: false,
                    disableColumnMenu: true,
                    description: t('analysisExplorer.analysisTable.download'),
                    filterable: false,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <DownloadAnalysisField
                                id={(params.row as StoredAnalysis).id}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    }
                },
                {
                    field: 'reader',
                    // type: 'singleSelect',
                    // valueOptions: ['ALL', 'Doctor1'],
                    align: 'left',
                    headerAlign: 'left',
                    headerName: t('analysisExplorer.analysisTable.reader'),
                    sortable: true,
                    minWidth: 115,
                    flex: 2.5,
                    editable: false,
                    disableColumnMenu: true,
                    description: t('analysisExplorer.analysisTable.dblReader'),
                    valueGetter: (params: GridValueGetterParams) => (params.row as StoredAnalysis).reader ?? '',
                    renderCell: (params: GridCellParams) => (
                        <SReaderDiv>{(params.row as StoredAnalysis).reader}</SReaderDiv>
                    )
                },
                {
                    field: 'Notes',
                    align: 'center',
                    headerName: t('analysisExplorer.analysisTable.notes'),
                    sortable: false,
                    minWidth: 50,
                    flex: 0.5,
                    disableColumnMenu: true,
                    filterable: false,
                    renderCell: (params: GridCellParams) => {
                        return (
                            <NoteField
                                currentStoredAnalysis={params.row as StoredAnalysis}
                                storedAnalysisNote={(params.row as StoredAnalysis).note}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        );
                    },
                    renderHeader: (_params: GridColumnHeaderParams) => <></>
                }
            ];
        }

        for (let i = 0; i < columnsRaw.length; i++) {
            if (columnsNeeded.includes(columnsRaw[i].field)) {
                col.push(columnsRaw[i]);
            }
        }
        return col;
    }, [
        i18n.language,
        selectedRows,
        storedAnalysisIdToReview,
        medicalReportIdAndClassificationResult,
        isLocalDbFetched
    ]);

    const AnalysisTableMemoized = useMemo(() => {
        let analysis = storedAnalysis;
        let analysisNonValidated = storedAnalysis.filter(
            (a) => !(a.id.toString() in medicalReportIdAndClassificationResult)
        );
        let analysisValidated = storedAnalysis.filter((a) => a.id.toString() in medicalReportIdAndClassificationResult);

        /* Mise en place des filtres */
        // MultiFiltre CAD-RADS (0 / 1-2 / 3-4 / 5)
        if (multipleFilter.length > 0) {
            analysis = analysisNonValidated
                .filter((a) => multipleFilter.includes(a.classificationResult.toString()))
                .concat(
                    analysisValidated.filter((a) =>
                        multipleFilter.includes(medicalReportIdAndClassificationResult[a.id.toString()])
                    )
                );
            if (multipleFilter.includes(ClassificationResult.Rads2)) {
                analysis = analysis
                    .concat(analysisNonValidated.filter((a) => a.classificationResult === ClassificationResult.Rads12))
                    .concat(
                        analysisValidated.filter(
                            (a) =>
                                medicalReportIdAndClassificationResult[a.id.toString()] == ClassificationResult.Rads12
                        )
                    );
            }
            if (multipleFilter.includes(ClassificationResult.Rads3)) {
                analysis = analysis
                    .concat(analysisNonValidated.filter((a) => a.classificationResult === ClassificationResult.Rads23))
                    .concat(
                        analysisValidated.filter(
                            (a) =>
                                medicalReportIdAndClassificationResult[a.id.toString()] == ClassificationResult.Rads23
                        )
                    );
            }
            if (multipleFilter.includes(ClassificationResult.Rads4)) {
                analysis = analysis
                    .concat(
                        analysisNonValidated.filter((a) =>
                            [ClassificationResult.Rads34, ClassificationResult.Rads345].includes(a.classificationResult)
                        )
                    )
                    .concat(
                        analysisValidated.filter((a) =>
                            [ClassificationResult.Rads34, ClassificationResult.Rads345].includes(
                                medicalReportIdAndClassificationResult[a.id.toString()]
                            )
                        )
                    );
            }
        }

        // Filtre analyses non visionnées ou marquées comme non vues
        if (onlyNew) {
            analysis = analysis.filter((a) => !a.hasBeenOpened);
        }
        // Filtre Doctors
        if (doctorsFilter.length > 0) {
            analysis = analysis.filter((a) => doctorsFilter.indexOf(a.reader) !== -1);
        }
        // Filtre de recherche (ID + Label + Memo)
        if (searchFilter !== '') {
            var analysisTemp = analysis.length;
            analysis = analysis.filter((a) =>
                (a.id.toString() + a.label?.toLocaleLowerCase() + a.memo?.toLocaleLowerCase()).includes(
                    searchFilter.toLocaleLowerCase().trim()
                )
            ); //.trim -> supprime les espaces au début et à la fin du string à comparer

            if (analysis.length === analysisTemp) updateFirstAnalysisID(0); // setFirstAnalysisIDfromGrid(0);
            else if (analysis.length > 0) updateFirstAnalysisID(analysis[analysis.length - 1]?.id);
            else if (analysis.length === 0) updateFirstAnalysisID(0);
        } else {
            updateFirstAnalysisID(0);
        }

        // Filtre Date
        if (startDate !== null) {
            if (endDate !== null) {
                // On compare les deux dates avec l'heure réinitialisée à minuit
                analysis = analysis.filter(
                    (a) =>
                        a.createdOn!.setHours(0, 0, 0, 0) >= startDate?.setHours(0, 0, 0, 0) &&
                        a.createdOn!.setHours(0, 0, 0, 0) <= endDate?.setHours(0, 0, 0, 0)
                );
            }
            // On filtre les analyses plus récentes que le début de l'intervalle date le temps de choisir la fin de l'intervalle
            analysis = analysis.filter((a) => a.createdOn!.setHours(0, 0, 0, 0) >= startDate?.setHours(0, 0, 0, 0));
        }
        let localSt = parseInt(localStorage.getItem('current_pagination_analysis_table') || '"0"');
        let currentPage = isNaN(localSt) ? 0 : localSt;

        return (
            <>
                <div style={{ width: '100%' }}>
                    {selectedRows.length == 0 ? (
                        <></>
                    ) : (
                        <SDataGridHeaderContainer>
                            <DataGridHeaderSelected
                                selectedR={selectedRows}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        </SDataGridHeaderContainer>
                    )}
                    <DataGrid
                        className={classes.root}
                        rowHeight={60}
                        rows={analysis}
                        getRowId={(row) => row.id}
                        rowSpacingType={'border'}
                        pageSize={parseInt(paginationSize)}
                        onPageSizeChange={(newPageSize) => {
                            setPageSize(newPageSize);
                            localStorage.setItem('pagination_size', newPageSize + '');
                        }}
                        rowsPerPageOptions={[1, 5, 15, 30, 50, 100]}
                        columns={columns}
                        scrollbarSize={17}
                        loading={success.isLoading}
                        error={error}
                        checkboxSelection={isTableHome ? true : false}
                        disableSelectionOnClick
                        onSelectionModelChange={(ids) => {
                            setSelectedRows(ids.map((id) => (typeof id == 'string' ? parseInt(id) : id)));
                        }}
                        onSortModelChange={onSortModelChange}
                        cellModesModel={cellModesModel}
                        onCellModesModelChange={handleCellModesModelChange}
                        onCellClick={handleCellClick}
                        processRowUpdate={processRowUpdate}
                        experimentalFeatures={{ newEditingApi: true }}
                        sortModel={sortModel}
                        getRowClassName={(params: GridRowParams) => {
                            if (isTableHome) {
                                return classes.root;
                            } else {
                                if (params.id !== currentId) {
                                    return classes.root;
                                }
                                return classes.row;
                            }
                        }}
                        sx={{
                            '& .MuiDataGrid-cell--editing': {
                                backgroundColor: themeContext.isLightMode
                                    ? lightTheme.table.editBackground + ' !important'
                                    : darkTheme.table.editBackground + ' !important',
                                '&:focus': {
                                    outline: 'solid ' + lightTheme.colors.blue + ' 1px !important'
                                },
                                '&:focus-within': {
                                    outline: 'solid ' + lightTheme.colors.blue + ' 1px !important'
                                }
                            },
                            '& .MuiCheckbox-root .MuiSvgIcon-root': {
                                fill: themeContext.isLightMode ? lightTheme.table.checkbox : darkTheme.table.checkbox
                            }
                        }}
                        componentsProps={{
                            row: { onMouseEnter: handleRowEnter, onMouseLeave: handleRowLeave },
                            pagination: {
                                labelRowsPerPage: isTableHome
                                    ? t('analysisExplorer.analysisTable.pagination.rowsPerPage')
                                    : t('analysisExplorer.analysisTable.pagination.rows')
                            }
                        }}
                        components={{
                            NoRowsOverlay: () =>
                                isTableHome ? (
                                    <SLoaderDiv>
                                        <Loader jsonToUse={noDataRows} width="30vw" />
                                    </SLoaderDiv>
                                ) : (
                                    <SLoaderDiv>
                                        <Loader jsonToUse={noDataRows} width="15vw" />
                                    </SLoaderDiv>
                                ),
                            ErrorOverlay: () => (
                                <SErrorContainer>
                                    <ErrorIcon />
                                    <Typography variant="h3">{t('An error occured...')}</Typography>
                                </SErrorContainer>
                            )
                        }}
                        page={currentPage}
                        onPageChange={(newPage) => {
                            const pageChangeEvent = new CustomEvent('pageChange', {
                                detail: { newPage: newPage }
                            });
                            window.dispatchEvent(pageChangeEvent);
                        }}
                    />
                </div>
            </>
        );
    }, [
        storedAnalysis,
        storedAnalysisIdToReview,
        medicalReportIdAndClassificationResult,
        error,
        success.isLoading,
        i18n.language,
        pageSize,
        selectedRows,
        onlyNew,
        multipleFilter,
        doctorsFilter,
        currentId,
        sortModel,
        searchFilter,
        startDate,
        endDate,
        cellModesModel,
        modificationOfCurrentPage,
        handleCellModesModelChange,
        handleCellClick,
        processRowUpdate,
        isLocalDbFetched,
        themeContext
    ]);

    return (
        <>
            <SContainer>{AnalysisTableMemoized}</SContainer>
        </>
    );
};

export default AnalysisTable;

const SContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    border-radius: ${(props) => props.theme.getSpacing(2)};
    width: 100%;
    user-select: none; //Empêche l'utilisateur de sélectionner le texte
    *::-webkit-scrollbar,
    *::-webkit-scrollbar-thumb {
        width: 17px;
        border-radius: 13px;
        background-clip: padding-box;
        border: 5px solid transparent;
        color: ${(props) => props.theme.table.scrollbar.color};
    }

    *::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 10px;
    }
    *::-webkit-scrollbar-thumb:hover {
        color: ${(props) => props.theme.table.scrollbar.color};
    }
    *::-webkit-scrollbar-thumb:active {
        color: ${(props) => props.theme.table.scrollbar.color};
    }
    *::-webkit-scrollbar-corner {
        opacity: 0;
    }
`;

const SErrorContainer = styled.div`
    text-align: center;
    width: 50%;
    margin: auto;
    padding: ${(props) => props.theme.getSpacing(4, 0)};
`;

const SDataGridHeaderContainer = styled.div`
    /* right: 5%; */
    height: 40px;
`;

const SLoaderDiv = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const STypographyReview = styled(Typography)`
    color: ${(props) => props.theme.table.contentLightText};
    font-size: 0.875rem !important;
`;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    root: {
        '& .MuiDataGrid-sortIcon': {
            color: ({ root_icon_sort }) => root_icon_sort
        },
        '& .row-hover-light-css': {
            '&:hover': {
                backgroundColor: '#ededed !important'
            }
        },
        '& .row-hover-dark-css': {
            '&:hover': {
                backgroundColor: '#393939 !important'
            }
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-colCell:focus': {
            outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            visibility: 'hidden'
        },
        '&.MuiDataGrid-root': {
            backgroundColor: ({ root_table_header_background }) => root_table_header_background,
            color: ({ root_table_header_text }) => root_table_header_text,
            border: 'none',
            width: '100%',
            borderRadius: '10px'
        },
        '& .MuiDataGrid-root, .MuiDataGrid-cell': {
            borderBottom: '0px !important'
        },
        '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
            marginLeft: '8px'
        },
        '& .MuiDataGrid-columnHeader': {
            padding: '0px !important'
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: '0px solid'
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: '0px solid'
        },
        '& .MuiDataGrid-row': {
            border: 'none',
            outline: 'none',
            maxWidth: '98%',
            backgroundColor: ({ root_table_content_background }) => root_table_content_background,
            color: ({ root_table_content_text }) => root_table_content_text,
            borderBottom: ({ root_table_content_divider }) => root_table_content_divider
        },
        '& .MuiDataGrid-cell': {
            border: 'none'
        },
        '& .MuiDataGrid-cell[data-field="createdOn"], .MuiDataGrid-cell[data-field="classificationResult"], .MuiDataGrid-cell[data-field="id"]':
            {
                padding: '0 !important'
            },
        '& .MuiDataGrid-cell--editable': {
            cursor: 'text'
        },
        '& .MuiDataGrid-cellCheckbox': {
            paddingLeft: '18px !important'
        },
        '& .MuiDataGrid-footerContainer': {
            borderTop: 'none'
        },
        '& .MuiTablePagination-root': {
            color: ({ root_table_header_text }) => root_table_header_text
        },
        '& .MuiTablePagination-selectIcon': {
            color: ({ root_table_header_text }) => root_table_header_text
        },
        '& .MuiButtonBase-root': {
            color: '#55B9F3 !important'
        },
        '& .Mui-disabled': {
            color: ({ root_table_header_text }) => root_table_header_text + ' !important'
        },
        minHeight: '75vh'
    },
    row: {
        '&.MuiDataGrid-row': {
            backgroundColor: ({ row_current_id }) => row_current_id
        }
    }
}));

const SRouterLinkTableHome = styled(RouterLink)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
`;

const SReaderDiv = styled.div`
    color: ${(props) => props.theme.miniTable.contentText};
`;

const SRouterLink = styled(RouterLink)`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 0px 0 15px;
    flex-direction: column;
    justify-content: center;
`;

const SLabel = styled.div`
    color: ${(props) => props.theme.miniTable.contentText};
    font-size: 13px !important;
    display: flex;
    align-items: center;
    height: 100%;
`;

const SFiberManualRecordIcon = styled(FiberManualRecordIcon)`
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 0)};
    color: ${(props) => props.theme.colors.blue};
`;

const SDivId = styled.div`
    color: ${(props) => props.theme.table.contentText};
`;

