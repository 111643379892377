import React, { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { FormControlLabel } from '@mui/material/';
import ReactGA from 'react-ga';
import { useHistory, useLocation } from 'react-router';
import uuid from 'uuidv4';
import { t } from '@/i18n.js';

import { ButtonBlue, Typography, RouterLink, CircularProgress, Switch } from '@/components/atoms';
import { File, InputFile } from '@/components/molecules';
import { Coronary, Classification, StoredAnalysisCreationRequest, StoredAnalysisClient } from '@api';

import { ALL_ACCEPTED } from '@/utils/FileValidation';

import { RadsAnalysisFor27, RadsAnalysis } from '@/services/UploadService';
import { CreateStoredAnalysis } from '@/services/StoredAnalysisService';
import { Session, getSessionCookie } from '@/utils/contexts/SessionContext';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

type Props = {
    currentSessionInCookie: Session;
};

export const Upload = (props: Props) => {
    const { currentSessionInCookie } = props;
    const { refreshList } = useContext(StoredAnalysisListContext);

    const [displayErrorMessage, setDisplayErrorMessage] = useState<boolean>(false);

    const [allFiles, setFiles] = useState<File[]>([]);

    const is27Stored = localStorage.getItem('is27') !== null;
    const [is27, setIs27] = useState<boolean>(is27Stored ?? true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [ladFiles, setLAD] = useState<File[]>([]);
    const [cxFiles, setCX] = useState<File[]>([]);
    const [rcaFiles, setRCA] = useState<File[]>([]);

    const historyRads = useHistory();
    const location = useLocation();

    const setIs27StoredAndEmptyUploads = () => {
        is27 ? localStorage.removeItem('is27') : localStorage.setItem('is27', 'true');
        setIs27(!is27);

        setFiles([]);
        setLAD([]);
        setCX([]);
        setRCA([]);
    };

    const setUploadedFiles = (identifier: string) => {
        switch (identifier) {
            case Coronary.LAD:
                return setLAD;
            case Coronary.Cx:
                return setCX;
            case Coronary.RCA:
                return setRCA;
            default:
                return useCallback(() => {}, []);
        }
    };

    const disabledProcessButton = () =>
        isLoading || is27
            ? allFiles.length === 0
            : ladFiles.length === 0 && cxFiles.length === 0 && rcaFiles.length === 0;

    const handleRadsAnalysis = async () => {
        setIsLoading(true);
        setDisplayErrorMessage(false);
        const analysisGuid = uuid();
        const analysisCreationRequest = new StoredAnalysisCreationRequest({
            analysisGuid,
            classification: Classification.SixClasses
        });
        const storedAnalysis = await CreateStoredAnalysis({
            sessionContext: currentSessionInCookie,
            analysisCreationRequest
        });
        const storedAnalysisId = storedAnalysis.id;
        try {
            is27
                ? await RadsAnalysisFor27({
                      storedAnalysisId,
                      analysisGuid,
                      allFiles,
                      sessionContext: currentSessionInCookie
                  }).then((response) => {
                      var sessionInCookie = getSessionCookie();
                      var client = new StoredAnalysisClient(
                          { token: sessionInCookie.authResponse.token },
                          process.env.BackendServiceURL
                      );
                      refreshList(client, true); // refresh list will occur even if the user leave the upload page

                      if (historyRads.location.pathname == '/upload') {
                          historyRads.push({
                              pathname: '/analysis-explorer'
                          });
                      }
                  })
                : await RadsAnalysis({
                      storedAnalysisId,
                      analysisGuid,
                      ladFiles,
                      cxFiles,
                      rcaFiles,
                      sessionContext: currentSessionInCookie
                  }).then((response) => {
                      var sessionInCookie = getSessionCookie();
                      var client = new StoredAnalysisClient(
                          { token: sessionInCookie.authResponse.token },
                          process.env.BackendServiceURL
                      );

                      refreshList(client, true); // refresh list will occur even if the user leave the upload page

                      if (historyRads.location.pathname == '/upload') {
                          historyRads.push({
                              pathname: '/analysis-explorer'
                          });
                      }
                  });

            ReactGA.event({
                category: 'Upload files',
                action: 'Rads analysis'
            });
        } catch (e) {
            setIsLoading(false);
            setDisplayErrorMessage(true);
        }
    };

    return (
        <SPage>
            {currentSessionInCookie.authResponse.isLicenseExpired ? (
                <SContainer>
                    <SItem>
                        <Typography title variant="h1">
                            {t('navbar.upload')}
                        </Typography>
                    </SItem>
                    <Typography>
                        Oops... Your license is expired
                        <br />
                        Please contact us to renew your license
                        <br /> <br />
                    </Typography>
                </SContainer>
            ) : (
                <SContainer>
                    <SItem>
                        <Typography title variant="h1">
                            {t('navbar.upload')}
                        </Typography>
                    </SItem>
                    {is27 ? (
                        <SFrame>
                            <Typography variant="caption">{t('upload.description')}</Typography>
                            <SDiv27>
                                <InputFile
                                    maxFileUpload={27}
                                    minFileUpload={27}
                                    onUpload={(files) => setFiles(files)}
                                    acceptedTypes={ALL_ACCEPTED}
                                    coronary={Coronary.NotSpecified}
                                />
                            </SDiv27>
                        </SFrame>
                    ) : (
                        <>
                            {Object.values(Coronary)
                                .filter((coronary) => coronary !== Coronary.NotSpecified)
                                .map((coronary) => (
                                    <SUploadContainer key={coronary}>
                                        <STypographyForPng paragraph variant="h3">
                                            {t(`${coronary}`)}
                                        </STypographyForPng>
                                        <SInputDiv>
                                            <InputFile
                                                maxFileUpload={9}
                                                minFileUpload={9}
                                                onUpload={setUploadedFiles(coronary)}
                                                acceptedTypes={ALL_ACCEPTED}
                                                coronary={coronary}
                                            />
                                        </SInputDiv>
                                    </SUploadContainer>
                                ))}
                        </>
                    )}
                    <SModeContainer>
                        <SItemDiv>
                            <SFormControlLabel
                                control={<Switch checked={is27} onChange={setIs27StoredAndEmptyUploads} />}
                                label={t('upload.labelFor27')}
                            />
                        </SItemDiv>
                    </SModeContainer>
                    <Typography variant="caption">
                        {t('upload.onlyAnonymized')}
                        <SRouterLink underline to="/terms">
                            {t('footer.terms')}
                        </SRouterLink>
                        .
                    </Typography>
                    <SButton>
                        <ButtonBlue onClick={() => handleRadsAnalysis()} disabled={disabledProcessButton()}>
                            {isLoading ? (
                                <CircularProgress />
                            ) : (
                                <Typography variant="button">{t('upload.processButton')}</Typography>
                            )}
                        </ButtonBlue>
                    </SButton>
                    {displayErrorMessage ? <SErrorDiv>{t('snackbar.error.analysis.creationError')}</SErrorDiv> : <></>}
                </SContainer>
            )}
        </SPage>
    );
};

export default Upload;

const SPage = styled.div`
    display: flex;
    height: 90%;
    align-items: center;
`;

const SContainer = styled.div`
    width: 35vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${(props) => props.theme.getSpacing(10, 10, 2, 10)};
    background-color: ${(props) => props.theme.upload.background};
    color: ${(props) => props.theme.upload.text};
    margin-top: ${(props) => props.theme.getSpacing(4)};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
`;

const SItem = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 35vw;
    margin: ${(props) => props.theme.getSpacing(2, 0, 2, 0)};
`;

const SFrame = styled.div`
    padding-top: ${(props) => props.theme.getSpacing(7.5)};
`;

const SDiv27 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-left: 9rem;
    padding-top: ${(props) => props.theme.getSpacing(12)};
    padding-bottom: ${(props) => props.theme.getSpacing(12)};
`;

const SInputDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.theme.getSpacing(0, 10)};
`;

const STypographyForPng = styled(Typography)`
    min-width: ${(props) => props.theme.getSpacing(10)};
`;

const SUploadContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: baseline;
    padding-left: 5rem;
    flex-direction: row;
`;

const SModeContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: ${(props) => props.theme.getSpacing(7, 0)};
`;

const SItemDiv = styled.div`
    padding-right: ${(props) => props.theme.getSpacing(3)};
`;

const SFormControlLabel = styled(FormControlLabel)`
    padding-left: ${(props) => props.theme.getSpacing(0)};
`;

const SButton = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${(props) => props.theme.getSpacing(5, 0, 5, 0)};
`;

const SErrorDiv = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    color: orange;
`;

const SRouterLink = styled(RouterLink)`
    && {
        color: ${(props) => props.theme.page.hypertext};
    }
`;
