import * as React from 'react';
import styled from 'styled-components';

import i18n from '@/i18n.js';
import { Language } from '@/utils/Language';
import HowToText from '@/utils/HowToText';

export const HowTo = () => {
    return (
        <SContainer>
            <SItem>
                {i18n.language === Language.English && <STypography>How to use</STypography>}
                {i18n.language === Language.French && <STypography>Tutoriel</STypography>}
            </SItem>
            {HowToText({ isFrench: i18n.language === Language.French })}
        </SContainer>
    );
};

export default HowTo;

const STypography = styled.div`
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 15px;
`;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    height: calc(90vh - 50px);
    padding: ${(props) => props.theme.getSpacing(10, 10, 2, 10)};
    background-color: ${(props) => props.theme.page.background};
    color: ${(props) => props.theme.page.text};
    margin-top: ${(props) => props.theme.getSpacing(4)};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    overflow-y: auto;
    margin-bottom: 15px;
`;

const SItem = styled.div`
    width: 80%;
`;

