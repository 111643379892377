import React from 'react';
import styled from 'styled-components';
import { ToggleButton as DefaultMaterialButton } from '@mui/material/';

export const FilterButton = styled(({ ...otherProps }) => <DefaultMaterialButton value="left" {...otherProps} />)`
    && {
        height: 40px;
        border: 1px solid;
        border-radius: 10px;
        color: ${(props) => props.theme.table.select.text};
        border-color: ${(props) => props.theme.table.select.borderColor};
        background-color: ${(props) => props.theme.table.select.backgroundButton};
        margin: ${(props) => props.theme.getSpacing(0, 1, 0, 0)};
        &:hover {
            border-color: ${(props) => props.theme.table.select.borderColorHover};
            background-color: ${(props) => props.theme.table.select.backgroundHover};
        }
        &.Mui-selected {
            border: 2px solid;
            border-color: ${(props) => props.theme.colors.blue};
            background-color: ${(props) => props.theme.table.select.backgroundButton};
            &:hover {
                border-color: ${(props) => props.theme.table.select.borderColorHover};
            }
        }
    }
`;

export default FilterButton;

