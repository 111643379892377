import React, { Dispatch, useState, SetStateAction } from 'react';
import styled from 'styled-components';

import ReactGA from 'react-ga';

import { ButtonBlue, ButtonLDMode, ButtonCancel } from '@/components/atoms';
import { Typography } from '@/components/atoms';
import { CircularProgress } from '@/components/atoms';
import { Loader } from '@/components/atoms';
import { Snackbar } from '@/components/atoms';

import { PasswordModification } from '@/components/organisms';

import { t } from '@/i18n.js';
import { InputAdornment, TextField } from '@mui/material';

import {
    DoctorsListClient,
    DoctorsListCreateRequest,
    DoctorsListUpdateRequest,
    UsersClient
} from '@/../generated/api.client';
import { DoctorEntity, Session, nullDoctorEntity } from '@/utils/contexts/SessionContext';
import theme from '@/utils/styles/Theme';

//ICONS
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useCurrentDoctorContext } from '@/utils/contexts/CurrentDoctorContext';
import { Dialog } from '@/components/molecules';

//Utils
import circleLoader from '@/utils/animations/circle-loader.json';

type SWidthProps = {
    width: string;
};

type SHeightProps = {
    height: string;
};

type SDisplayProps = {
    display: string;
};

type SCurrentDoctorProps = {
    colors: string;
    hovercolor: string;
    cursor: string;
};

type SIconProps = {
    visibility: string;
};

type Props = {
    /**Set boolean only after success */
    onSuccess?: Dispatch<SetStateAction<boolean>>;
    currentSessionInCookie: Session;
};

export const Account = (props: Props) => {
    const { currentSessionInCookie } = props;

    const [currentDoctorContext, updateCurrentDoctor] = useCurrentDoctorContext();

    const [loading, setLoading] = useState<boolean>(false);

    // Open the alert dialog
    const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const doctorsEntity = JSON.parse(localStorage.getItem('doctors_entity') || '""') as DoctorEntity[];

    const [doctorsArray, setDoctorsArray] = useState<DoctorEntity[]>(doctorsEntity);

    // Initialization of a doctor temporary to compare modifications before save
    const [doctorsArrayTemp, setDoctorsArrayTemp] = useState<DoctorEntity[]>(doctorsEntity);

    //Use state current Doctor
    const [currentDoctor, setCurrentDoctor] = useState<DoctorEntity>({
        id: JSON.parse(localStorage.getItem('current_doctor') || '""').id ?? 0,
        name: JSON.parse(localStorage.getItem('current_doctor') || '""').name ?? '',
        mail: JSON.parse(localStorage.getItem('current_doctor') || '""').mail ?? ''
    });
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [openSnackbarDelete, setOpenSnackbarDelete] = useState<boolean>(false);
    const [openSnackbarError, setOpenSnackbarError] = useState<boolean>(false);

    // We store the informations of the account from the current session
    const firstName = currentSessionInCookie.authResponse.firstName;
    const lastName = currentSessionInCookie.authResponse.lastName;
    const mail = currentSessionInCookie.authResponse.email;

    const [displayNewDocForm, setDisplayNewDocForm] = useState<boolean>(false);
    const [newDoctorName, setNewDoctorName] = useState<string>('');
    const addDoctorName = (name: string) => {
        setNewDoctorName(name);
    };

    const [newDoctorMail, setNewDoctorMail] = useState<string>('');
    const addDoctorMail = (mail: string) => {
        setNewDoctorMail(mail);
    };

    /**
     * Change the current Doctor for the session - in the localstorage / context / database
     * @param {number} futurDoctorId The id of the futur Doctor when we click the account icon
     */
    const changeCurrentDoctor = (futurDoctorId: number) => {
        ReactGA.event({
            category: 'Doctors',
            action: 'Change current Doctor'
        });

        var doctor = doctorsArray.find((item) => item.id == futurDoctorId);

        if (doctor == undefined) {
            setOpenSnackbarError(true);
            return;
        }
        if (doctor != undefined) {
            const usersClient = new UsersClient(
                { token: currentSessionInCookie.authResponse.token },
                process.env.BackendServiceURL
            );
            usersClient
                .updateCurrentDoctor(doctor.id)
                .then((authenticateResponse) => {
                    const currentDoctor: DoctorEntity = doctor || ({} as DoctorEntity);
                    localStorage.setItem('current_doctor', JSON.stringify(currentDoctor));
                    updateCurrentDoctor(currentDoctor);
                    setCurrentDoctor(currentDoctor);
                })
                .catch((err) => {
                    setOpenSnackbarError(true);
                });
        }
    };

    //Set name depending on idDoctor
    const [doctorToDeleteInformation, setDoctorToDeleteInformation] = useState({ id: 0, name: '', mail: '' });

    /**
     * Call the function to store information about the doctor the user chose to delete and open the delete dialog validation
     * @param idDoctor Doctor to delete ID
     */
    const handleDelete = (idDoctor?: number) => {
        var doctor = doctorsArray.find((item) => item.id == idDoctor);
        if (doctor == undefined) {
            setOpenSnackbarError(true);
            setDoctorToDeleteInformation({ id: 0, name: '', mail: '' });
            return;
        }
        setDoctorToDeleteInformation(doctor);

        setOpenDialogDelete(true);
    };

    /**
     * Delete a doctor (depending on its id) - Only doctor 2 and 3 can be deleted
     * @param idDoctor Doctor to delete ID
     */
    const deleteDoctor = async (idDoctor: number) => {
        let doctor1 = doctorsArray[0];
        if (doctor1?.id == idDoctor) {
            setOpenDialogDelete(false);
            setOpenSnackbarError(true);
            return;
        }

        setIsDeleting(true);
        const doctorsClient = new DoctorsListClient(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        doctorsClient
            .delete(idDoctor)
            .then(async (deleteResponse) => {
                if (idDoctor == currentDoctor.id) {
                    // Current doctor has been deleted
                    await changeCurrentDoctor(doctor1.id);
                }
                const updatedDoctorsList = doctorsArray.filter((doctor) => doctor.id !== idDoctor);

                setDoctorsArray(updatedDoctorsList);
                setDoctorsArrayTemp(updatedDoctorsList);
                localStorage.setItem('doctors_entity', JSON.stringify(updatedDoctorsList));

                setIsDeleting(false); // Stop the loading on the button
                setOpenDialogDelete(false); // Close the delete confirmation window
                setOpenSnackbarDelete(true); // Open the snackbar to notify the user of the successful deletion
            })
            .catch((err) => {
                setOpenDialogDelete(false);
                setOpenSnackbarError(true);
            });
    };

    /**
     * Save changes for any new information or modifications
     */
    const updateDoctor = async (idDoctor: number, newName: string, newEmail: string) => {
        let doctor = doctorsArray.filter((doctor) => doctor.id !== idDoctor);
        if (doctor == undefined) {
            setOpenSnackbarError(true);
            return;
        }

        setLoading(true);
        const doctorClient = new DoctorsListClient(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        const CDRequest = new DoctorsListUpdateRequest({
            id: idDoctor,
            name: newName,
            email: newEmail
        });
        doctorClient
            .update(CDRequest)
            .then((docResponse) => {
                setLoading(false);
                setOpenSnackbar(true);
                localStorage.setItem('doctors_entity', JSON.stringify(doctorsArrayTemp));
                setDoctorsArray(doctorsArrayTemp);
            })
            .catch((err) => {
                setLoading(false);
                setOpenSnackbarError(true);
            });
    };

    /**
     * Create doctor
     */
    const createDoctor = async (newName: string, newEmail: string) => {
        if (doctorsArray.length == 15) {
            setOpenSnackbarError(true);
            return;
        }

        setLoading(true);
        const doctorClient = new DoctorsListClient(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        const CDRequest = new DoctorsListCreateRequest({
            name: newName,
            email: newEmail
        });
        doctorClient
            .create(CDRequest)
            .then((docResponse) => {
                setLoading(false);
                setOpenSnackbar(true);

                const doctor: DoctorEntity = {
                    id: docResponse.id,
                    name: docResponse.name,
                    mail: docResponse.email
                };
                let newDoctorsArray = [...doctorsArray, doctor];
                localStorage.setItem('doctors_entity', JSON.stringify(newDoctorsArray));

                setDoctorsArray(newDoctorsArray);
                setDoctorsArrayTemp(newDoctorsArray);
                // doctor array updated in use effect
                setDisplayNewDocForm(false);
                setNewDoctorName('');
                setNewDoctorMail('');
            })
            .catch((err) => {
                setLoading(false);
                setOpenSnackbarError(true);
            });
    };

    /**
     * Snackbar control
     * @param event
     * @param reason
     * @returns
     */
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
        setOpenSnackbarDelete(false);
    };

    /**
     * Change the name of the doctor - we call it on textfield input
     * @param {number} idDoctor ID of the doctor the user want to change information
     * @param {string} value Value of the modification - name
     */
    const changeName = (idDoctor: Number, value: string) => {
        const nextNames = doctorsArrayTemp.map((doctorEntity, i) => {
            if (doctorEntity.id == idDoctor) {
                return {
                    id: doctorEntity.id,
                    name: value,
                    mail: doctorEntity.mail
                };
            } else {
                return doctorEntity;
            }
        });
        setDoctorsArrayTemp(nextNames);
    };

    /**
     * Change the mail of the doctor - we call it on textfield input
     * @param {number} idDoctor ID of the doctor the user want to change information
     * @param value Value of the modification - mail
     */
    const changeMail = (idDoctor: Number, value: string) => {
        const nextMails = doctorsArrayTemp.map((doctorEntity, i) => {
            if (doctorEntity.id == idDoctor) {
                return {
                    id: doctorEntity.id,
                    name: doctorEntity.name,
                    mail: value
                };
            } else {
                return doctorEntity;
            }
        });
        setDoctorsArrayTemp(nextMails);
    };

    const isInvalidDoctorArrayTemp = (doctorsArrayTemp: { mail: string; name: string }[]) => {
        for (let i = 0; i < doctorsArrayTemp.length; i++) {
            if (
                (doctorsArrayTemp[i].mail !== '' && doctorsArrayTemp[i].name === '') ||
                (doctorsArrayTemp[i].mail === '' && doctorsArrayTemp[i].name !== '')
            ) {
                return true;
            }
        }
        return false;
    };

    const saveChanges = async () => {
        for (let i = 0; i < doctorsArray.length; i++) {
            if (
                (doctorsArrayTemp[i].id == doctorsArray[i].id && doctorsArrayTemp[i].name != doctorsArray[i].name) ||
                (doctorsArrayTemp[i].mail != doctorsArray[i].mail &&
                    doctorsArrayTemp[i].name != '' &&
                    doctorsArrayTemp[i].mail != '')
            ) {
                // Need to update doctor i
                await updateDoctor(doctorsArrayTemp[i].id, doctorsArrayTemp[i].name, doctorsArrayTemp[i].mail);
            }
        }
    };

    return (
        <STotalContainer>
            <SRow>
                <SContainer width="50%">
                    <SContainerTitle>
                        <STypographyTitle>{t('settings.account')}</STypographyTitle>
                    </SContainerTitle>
                    <SContainerNeumorphism height="fit-content">
                        <Typography bold>{t('name')}</Typography>
                        <Typography>{firstName + ' ' + lastName}</Typography>
                        <Typography bold>{t('mail')}</Typography>
                        <Typography>{mail}</Typography>
                    </SContainerNeumorphism>

                    <SContainerNeumorphismScroll>
                        <SRowAlt>
                            <Typography bold>
                                {t('doctors')} ({doctorsArray.length})
                            </Typography>
                            {doctorsArray.length < 15 ? (
                                <SAddIcon
                                    onClick={() => {
                                        setDisplayNewDocForm(!displayNewDocForm);
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </SRowAlt>

                        {displayNewDocForm ? (
                            <>
                                <SSubRow display="flex">
                                    <STextField
                                        width="48%"
                                        label={t('doctor.new')}
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(event) => {
                                            addDoctorName(event.target.value);
                                        }}
                                        InputLabelProps={{ required: false }}
                                    />
                                    <STextField
                                        width="48%"
                                        label={t('mail')}
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(event) => {
                                            addDoctorMail(event.target.value);
                                        }}
                                        InputLabelProps={{ required: false }}
                                    />
                                </SSubRow>

                                <SButtonDivLittleMargin>
                                    <ButtonBlue
                                        /** Disabled if one field of a doctor is filled but not the other
                                         */
                                        disabled={newDoctorName == '' || newDoctorMail == ''}
                                        onClick={() => createDoctor(newDoctorName, newDoctorMail)}
                                    >
                                        {!loading ? (
                                            <Typography variant="button">{t('settings.createNewDoctor')}</Typography>
                                        ) : (
                                            <CircularProgress />
                                        )}
                                    </ButtonBlue>
                                    <ButtonCancel onClick={() => setDisplayNewDocForm(false)}>
                                        <Typography variant="button">{t('settings.cancel')}</Typography>
                                    </ButtonCancel>
                                </SButtonDivLittleMargin>

                                <hr style={{ margin: '20px 0 20px 0' }} />
                            </>
                        ) : (
                            <></>
                        )}
                        <SContainerListDoctors height={displayNewDocForm ? '30%' : '80%'}>
                            {doctorsArray.map((item, index) => (
                                <div key={item.id}>
                                    {item.id == 0 ? (
                                        <></>
                                    ) : (
                                        <SSubRow display="flex" key={item.id}>
                                            <SCurrentDoctorIcon
                                                colors={
                                                    currentDoctor.id === item.id ? theme.colors.electricblue : '#aebdd1'
                                                }
                                                hovercolor={
                                                    currentDoctor.id === item.id ? theme.colors.electricblue : '#0048a9'
                                                }
                                                cursor={currentDoctor.id === item.id ? 'auto' : 'pointer'}
                                                onClick={() =>
                                                    currentDoctor.id === item.id ? '' : changeCurrentDoctor(item.id)
                                                }
                                            />

                                            <STextField
                                                id={'title' + item.id}
                                                width="40%"
                                                label={t('name')}
                                                variant="outlined"
                                                autoComplete="off"
                                                size="small"
                                                defaultValue={item.name}
                                                onChange={(event) => {
                                                    changeName(item.id, event.target.value);
                                                }}
                                                InputLabelProps={{ required: false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SEditIcon fontSize="small" />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <STextField
                                                id={'mail' + item.id}
                                                width="40%"
                                                label={t('mail')}
                                                variant="outlined"
                                                autoComplete="off"
                                                size="small"
                                                defaultValue={item.mail}
                                                onChange={(event) => {
                                                    changeMail(item.id, event.target.value);
                                                }}
                                                InputLabelProps={{ required: false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SMailIcon fontSize="small" />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            <SDeleteIcon
                                                onClick={() => handleDelete(item.id)}
                                                visibility={index === 0 ? 'hidden' : 'visible'} // First doctor (first index because first createdOn) cannot be deleted
                                            />
                                        </SSubRow>
                                    )}
                                </div>
                            ))}
                        </SContainerListDoctors>

                        <SButtonDiv>
                            <ButtonLDMode
                                /** Disabled if
                                 * - No modifications (comparaison with new DoctorsArray and the DoctorsArrayTemp)
                                 * - No informations for Doctor 1 (if we try to give a null string to name or password)
                                 * - One field of a doctor is filled but not the other
                                 */
                                disabled={
                                    JSON.stringify(doctorsArray) === JSON.stringify(doctorsArrayTemp) ||
                                    doctorsArrayTemp[0].mail.length === 0 ||
                                    doctorsArrayTemp[0].name.length === 0 ||
                                    isInvalidDoctorArrayTemp(doctorsArrayTemp)
                                }
                                onClick={() => saveChanges()}
                            >
                                {!loading ? (
                                    <STypographyButton variant="button">{t('settings.saveChanges')}</STypographyButton>
                                ) : (
                                    <CircularProgress />
                                )}
                            </ButtonLDMode>
                        </SButtonDiv>
                    </SContainerNeumorphismScroll>
                </SContainer>

                <SContainer width="50%">
                    <SContainerTitle>
                        <STypographyTitle>{t('settings.changePswd')}</STypographyTitle>
                    </SContainerTitle>

                    <SContainerNeumorphism height="fit-content">
                        <PasswordModification currentSessionInCookie={currentSessionInCookie} />
                    </SContainerNeumorphism>
                </SContainer>
            </SRow>

            <Snackbar open={openSnackbar} handleClose={handleClose} severity="success" message={t('settings.saved')} />
            <Snackbar
                open={openSnackbarDelete}
                handleClose={handleClose}
                severity="success"
                message={t('settings.doctors.deleteSuccessful', { name: doctorToDeleteInformation.name })}
            />
            <Snackbar open={openSnackbarError} handleClose={handleClose} severity="error" message={t('errorOccured')} />

            <Dialog
                open={openDialogDelete}
                customTitle={t('settings.doctors.deleteTitle')}
                text={t('settings.doctors.deleteValidation', {
                    name: doctorToDeleteInformation.name,
                    mail: doctorToDeleteInformation.mail
                })}
                onClose={() => deleteDoctor(doctorToDeleteInformation.id)}
                disableChildrenButton={isDeleting}
                childrenButton={
                    isDeleting ? (
                        <Loader jsonToUse={circleLoader} width="10%" />
                    ) : (
                        <Typography red variant="button">
                            {t('settings.doctors.delete')}
                        </Typography>
                    )
                }
                withCancel
                disableEscapeKeyDown
                handleCancel={handleClose}
            ></Dialog>
        </STotalContainer>
    );
};

export default Account;

const STypographyButton = styled(Typography)`
    padding: 5px;
    width: 100%;
`;

const STotalContainer = styled.div`
    display: flex;
    width: 100%;
    height: 90%;
    justify-content: center;
    align-items: flex-start;
    background-color: ${(props) => props.theme.account.background};
`;

const SRow = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 10px;
    background-color: ${(props) => props.theme.account.background};
`;

const SRowAlt = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SSubRow = styled.div<SDisplayProps>`
    width: 100%;
    display: ${(props: SDisplayProps) => props.display};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: ${(props) => props.theme.getSpacing(3, 0, 3, 0)};
    color: ${(props) => props.theme.account.textColor};
`;

const SButtonDiv = styled.div`
    width: 100%;
    display: flex;
    margin: ${(props) => props.theme.getSpacing(3, 2, 3, 2)};
`;

const SButtonDivLittleMargin = styled.div`
    width: 100%;
    display: flex;
    margin: ${(props) => props.theme.getSpacing(3, 2, 3, 2)};
`;

const SContainer = styled.div<SWidthProps>`
    /* display: flex; */
    display: block;
    /* height: 100%; */
    /* flex-direction: column; */
    width: ${(props: SWidthProps) => props.width};
    padding: ${(props) => props.theme.getSpacing(0, 10, 0, 10)};
    color: ${(props) => props.theme.account.textColor};
`;

const SContainerTitle = styled.div`
    /* height: 50px; */
    display: flex;
    font-size: 15px !important;
`;

const STypographyTitle = styled(Typography)`
    font-size: 30px !important;
`;

const SContainerNeumorphism = styled.div<SHeightProps>`
    height: ${(props: SHeightProps) => props.height};
    padding: ${(props) => props.theme.getSpacing(5, 10, 2, 10)};
    color: ${(props) => props.theme.account.textColor};
    background-color: ${(props) => props.theme.account.container};
    border-radius: 10px;
`;

const SContainerNeumorphismScroll = styled.div`
    height: 70%;
    padding: ${(props) => props.theme.getSpacing(5, 10, 5, 10)};
    margin: ${(props) => props.theme.getSpacing(5, 0, 5, 0)};
    color: ${(props) => props.theme.account.textColor};
    background-color: ${(props) => props.theme.account.container};
    border-radius: 10px;
`;

const SContainerListDoctors = styled.div<SHeightProps>`
    /* height: 80%; */
    height: ${(props: SHeightProps) => props.height};
    padding: ${(props) => props.theme.getSpacing(2, 0, 2, 0)};
    overflow: auto;
`;

const SDeleteIcon = styled(DeleteIcon)<SIconProps>`
    color: ${(props) => props.theme.colors.darkgrey};
    visibility: ${(props: SIconProps) => props.visibility};
    &:hover {
        color: ${(props) => props.theme.colors.darkred};
        cursor: pointer;
        transition: 0.2s;
    }
`;

const SAddIcon = styled(AddIcon)`
    margin-left: ${(props) => props.theme.getSpacing(2)};
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.lightgrey};
    color: ${(props) => props.theme.colors.darkgrey};
    &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.darkblue};
    }
`;

const SCurrentDoctorIcon = styled(AccountCircleIcon)<SCurrentDoctorProps>`
    color: ${(props: SCurrentDoctorProps) => props.colors};
    transition: all 0.2s ease-in;
    &:hover {
        color: ${(props: SCurrentDoctorProps) => props.hovercolor};
        cursor: ${(props: SCurrentDoctorProps) => props.cursor};
        transition: all 0.2s ease-in;
    }
`;

const SMailIcon = styled(MailIcon)`
    color: ${(props) => props.theme.account.iconColor};
`;

const SEditIcon = styled(EditIcon)`
    color: ${(props) => props.theme.account.iconColor};
`;

const STextField = styled(TextField)<SWidthProps>`
    width: ${(props: SWidthProps) => props.width};
    border-radius: 5px;
    margin: ${(props) => props.theme.getSpacing(8, 4, 4, 4)};
    & .MuiInput-root {
        color: ${(props) => props.theme.account.textColor};
    }
    & label {
        color: ${(props) => props.theme.account.textColor};
    }
    & .MuiTextField-root {
        color: ${(props) => props.theme.account.textColor};
    }
    & label.Mui-focused {
        color: ${(props) => props.theme.account.textColor};
    }
    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiOutlinedInput-root {
        & input {
            color: ${(props) => props.theme.account.textColor};
        }
        & fieldset {
            border-color: white;
            border-radius: 5px;
        }
        &:hover fieldset {
            border-color: white;
        }
        &.Mui-focused fieldset {
            border-color: white;
        }
        &.MuiInput-input {
            color: ${(props) => props.theme.account.textColor};
        }
    }
`;

