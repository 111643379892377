import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { t } from '@/i18n.js';
import styled from 'styled-components';

type Props = {
    /**The analysis date to display*/
    date?: Date | undefined;
    hasBeenOpened?: boolean | undefined;
};

export const DateTimeField = (props: Props) => {
    const { date, hasBeenOpened } = props;

    return (
        <Tooltip title={t('dateTimeFull', { date: date }) || ''}>
            {!hasBeenOpened ? (
                date ? (
                    <SBoldDiv>{t('dateTimeShort', { date: date })}</SBoldDiv>
                ) : (
                    <SBoldDiv></SBoldDiv>
                )
            ) : date ? (
                <SLightDiv>{t('dateTimeShort', { date: date })}</SLightDiv>
            ) : (
                <SLightDiv></SLightDiv>
            )}
        </Tooltip>
    );
};

export default DateTimeField;

const SBoldDiv = styled.div`
    font-weight: bold;
    color: ${(props) => props.theme.table.contentLightText};
`;

const SLightDiv = styled.div`
    font-weight: normal;
    color: ${(props) => props.theme.table.contentLightText};
`;

