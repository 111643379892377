import React from 'react';
import DeleteAnalysisSelected from '@/components/atoms/AnalysisTable/DeleteAnalysisSelected';
import MarkAsReadSelected from '@/components/atoms/AnalysisTable/MarkAsReadSelected';
import MarkAsUnreadSelected from '@/components/atoms/AnalysisTable/MarkAsUnreadSelected';
import styled from 'styled-components';
import DownloadSelectedFiles from '@/components/atoms/AnalysisTable/DownloadSelectedFiles';
import { Session } from '@/utils/contexts/SessionContext';

type Props = {
    /**The analysis to open*/
    selectedR: number[];
    currentSessionInCookie: Session;
};

export const DataGridHeaderSelected = (props: Props) => {
    const { selectedR, currentSessionInCookie } = props;

    return (
        <>
            {selectedR.length == 0 ? (
                <></>
            ) : (
                <SHeaderContainer>
                    <DeleteAnalysisSelected selectedR={selectedR} currentSessionInCookie={currentSessionInCookie} />
                    <DownloadSelectedFiles selectedR={selectedR} currentSessionInCookie={currentSessionInCookie} />
                    <MarkAsReadSelected selectedR={selectedR} currentSessionInCookie={currentSessionInCookie} />
                    <MarkAsUnreadSelected selectedR={selectedR} currentSessionInCookie={currentSessionInCookie} />
                </SHeaderContainer>
            )}
        </>
    );
};

export default DataGridHeaderSelected;

const SHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

