import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {AuthorizedApiBase, IConfig} from "@api";
import { cleanLocalStorage, logoutFunc } from "@/utils/contexts/SessionContext";


type Props = {
    storedAnalysisList: number[];
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export class DeleteAnalysisService extends AuthorizedApiBase {

    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    async DeleteStoredAnalysis (props: Props) {
        const {storedAnalysisList} = props;
        try {
            const url_ = this.baseUrl + `/StoredAnalysis/delete`;

            let options_ = <AxiosRequestConfig>{
                method: "POST",
                url: url_,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                data: {
                    storedAnalysisList: storedAnalysisList
                }
            };
            var response = await this.transformOptions(options_).then(transformedOptions_ => {
                return this.instance.request(transformedOptions_);
            }).catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    if (_error.response.headers['www-authenticate']?.includes("Bearer")) {
                        console.log('Invalid Bearer Token');
                        logoutFunc();
                        cleanLocalStorage();
                        location.reload();
                    }
                    return _error.response;
                } else {
                    throw _error;
                }
            });

            if (response.status != 200) {
                return response.data;
            }
            return response.data;
        } catch (err) {
            return null;
        }
    }
}