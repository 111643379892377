import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { DateTime } from 'luxon';
import { Language } from '@/utils/Language';
import enTranslation from 'locales/English/translation.json';
import frTranslation from 'locales/Français/translation.json';

const resources = {
    'en-US': {
        translation: enTranslation
    },
    'fr-FR': {
        translation: frTranslation
    }
};
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        resources,
        fallbackLng: [Language.English, Language.French],
        supportedLngs: [Language.French, Language.English],
        load: 'currentOnly',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            // @ts-ignore
            format: (value, format, lng) => {
                if (value instanceof Date) {
                    return DateTime.fromJSDate(value)
                        .setLocale(lng === Language.French ? 'fr-FR' : 'en-US')
                        .toFormat(format);
                }
                return value;
            }
        }
    });

export const t = i18n.t;

export default i18n;

