import React, { useEffect, useState } from 'react';
import i18n, { t } from '@/i18n.js';
import { Classification, ClassificationResult, ImageOrderInStorage, IStoredAnalysis, StoredAnalysis } from '@api';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';

import { PDFViewer } from '@react-pdf/renderer';

import { Snackbar, Tooltip, Switch, InputBase, Alert } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import { Loader, NoteFieldStoredAnalysis, Typography } from '@/components/atoms';
import { ErrorMessage, File } from '@/components/molecules';
import { MedicalReportCreationForm } from '@/components/organisms';

import analysisCreation from '@/utils/animations/analysis-creation.json';

import useDebounce from '@/utils/hooks/useDebounce';
import { Session } from '@/utils/contexts/SessionContext';
import { LocationStateRads, ResultsRads } from '@/utils/ResultsRads';
import PdfDocument from '@/pdf/rads/PdfDocument';
import { Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';

type Props = {
    currentSessionInCookie: Session;
    storedAnalysis: IStoredAnalysis;
    LAD: ResultsRads;
    CX: ResultsRads;
    RCA: ResultsRads;
    files3D: File[];
    currentId: number;
    medicalReportId: number;
    setMedicalReportId: React.Dispatch<React.SetStateAction<number>>;
    displayMedicalReportInPdf: boolean;
    setDisplayMedicalReportInPdf: React.Dispatch<React.SetStateAction<boolean>>;
    setPatientNameVar: React.Dispatch<React.SetStateAction<string>>;
    medicalProps: MedicalProps;
    setMedicalProps: React.Dispatch<React.SetStateAction<MedicalProps>>;
};

export interface LocationStateStoredAnalysis extends LocationStateRads {
    /**StoredAnalysis to display*/
    storedAnalysis: StoredAnalysis;
}

export const initialStoredAnalysis: IStoredAnalysis = {
    id: 0,
    label: '',
    classificationResult: ClassificationResult.Error,
    classification: Classification.SixClasses,
    imageOrderInStorage: ImageOrderInStorage.LadCxRca,
    analysisGuid: '',
    note: '',
    highLighted: false,
    reader: '',
    memo: '',
    isFromPacs: false,
    isExplicabilityComputed: false,
    hasBeenOpened: false,
    token: ''
};

export const DisplayPdf = (props: Props) => {
    const {
        currentSessionInCookie,
        storedAnalysis,
        LAD,
        CX,
        RCA,
        files3D,
        currentId,
        medicalReportId,
        setMedicalReportId,
        displayMedicalReportInPdf,
        setDisplayMedicalReportInPdf,
        setPatientNameVar,
        medicalProps,
        setMedicalProps
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isFailure, setIsFailure] = useState(false);

    const [ladResults, setLadResults] = useState(LAD);
    const [cxResults, setCxResults] = useState(CX);
    const [rcaResults, setRcaResults] = useState(RCA);

    const [patientName, setPatientName] = useState('');
    const patientNameDebounced = useDebounce(patientName, 500);

    const [openPdfViewer, setOpenPdfViewer] = useState(true);

    const [openMedicFeedback, setOpenMedicFeedback] = useState(false);
    const [openRemovedFromPdfFeedback, setOpenRemovedFromPdfFeedback] = useState(false);

    const ffrAvailable =
        //@ts-ignore
        JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7)))).isFFRAvailable ==
        'True';

    // Display the FFR on the PDF
    let displayFfrOnPdf = false;
    const localStorageDisplayFfr = JSON.parse(localStorage.getItem('ffr_prediction_displayed_on_pdf') || '"false"');
    if (localStorageDisplayFfr == true) {
        displayFfrOnPdf = true;
    }

    const [displayFFR, setDisplayFFR] = useState<boolean>(displayFfrOnPdf);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMedicFeedback(false);
        setOpenRemovedFromPdfFeedback(false);
    };

    const updateFfr = (displayFfrValue: boolean) => {
        setDisplayFFR(displayFfrValue);
        if (displayFfrValue == true) {
            localStorage.setItem('ffr_prediction_displayed_on_pdf', 'true');
        } else {
            localStorage.setItem('ffr_prediction_displayed_on_pdf', 'false');
        }
    };

    useEffect(() => {
        setPatientNameVar(patientNameDebounced);
    }, [patientNameDebounced]);

    return (
        <>
            {isLoading ? (
                <>
                    <Loader jsonToUse={analysisCreation} width="24rem" />
                    <Typography variant="h2">{t('storedAnalysisDetail.fetching')}</Typography>
                </>
            ) : !isFailure ? (
                <>
                    <div style={{ visibility: 'hidden', position: 'fixed' }}>
                        <canvas width="300px" height="200px" id="canvasForCharts" />
                        <canvas width="300px" height="300px" id="canvasForBullet" />
                    </div>
                    <SContainerContent>
                        <SForm>
                            <SContainerTitle>
                                <Typography variant="h3">
                                    {t('dateTimeFull', { date: storedAnalysis?.createdOn })}
                                </Typography>
                            </SContainerTitle>
                            <SContainerLabel>
                                <Typography variant="h2" style={{ marginLeft: '20px' }}>
                                    {storedAnalysis?.label}
                                </Typography>
                                <SRowPadding>
                                    <Tooltip title={t('storedAnalysisDetail.patientNameNotStored') || ''}>
                                        <STextField
                                            // @ts-ignore
                                            onChange={(event) => setPatientName(event.target.value)}
                                            placeholder={t('storedAnalysisDetail.patientName')}
                                        />
                                    </Tooltip>
                                </SRowPadding>
                            </SContainerLabel>
                            {ffrAvailable ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignContent: 'flex-start',
                                        padding: '0px 0px 20px 0px'
                                    }}
                                >
                                    <SRow onClick={() => updateFfr(!displayFFR)}>
                                        <StyledSwitch checked={displayFFR} />
                                        <SContainerTypography>
                                            <Typography>{t('medicalReportCreationForm.displayFfrOnPdf')}</Typography>
                                        </SContainerTypography>
                                    </SRow>
                                </div>
                            ) : (
                                <> </>
                            )}
                            <STopRow>
                                <SColumn>
                                    <SContainer>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <SBlueIcon>
                                                <SHowToRegIcon />
                                            </SBlueIcon>

                                            <SFormTitle blue variant="h2">
                                                {medicalReportId != 0
                                                    ? t('storedAnalysisDetail.modifyMedicalReport')
                                                    : t('storedAnalysisDetail.geneMedicalReport')}
                                            </SFormTitle>
                                        </div>

                                        {medicalReportId != -1 && (
                                            <MedicalReportCreationForm
                                                setDisplayMedicalReportInPdf={setDisplayMedicalReportInPdf}
                                                displayMedicalReportInPdf={displayMedicalReportInPdf}
                                                setOpenMedicFeedback={setOpenMedicFeedback}
                                                setOpenRemovedFromPdfFeedback={setOpenRemovedFromPdfFeedback}
                                                medicalProps={medicalProps}
                                                setMedicalProps={setMedicalProps}
                                                storedAnalysis={storedAnalysis}
                                                ladResults={ladResults}
                                                cxResults={cxResults}
                                                rcaResults={rcaResults}
                                                medicalReportId={medicalReportId}
                                                setMedicalReportId={setMedicalReportId}
                                                currentSessionInCookie={currentSessionInCookie}
                                            />
                                        )}
                                    </SContainer>

                                    <Snackbar open={openMedicFeedback} autoHideDuration={5000} onClose={handleClose}>
                                        <div>
                                            {/* Do not remove this dev else snackbar fails */}
                                            <SAlert onClose={handleClose} severity="info">
                                                {t('storedAnalysisDetail.medicalReportAppend')}
                                            </SAlert>
                                        </div>
                                    </Snackbar>
                                    <Snackbar
                                        open={openRemovedFromPdfFeedback}
                                        autoHideDuration={5000}
                                        onClose={handleClose}
                                    >
                                        <div>
                                            {/* Do not remove this dev else snackbar fails */}
                                            <SAlert onClose={handleClose} severity="info">
                                                {t('storedAnalysisDetail.medicalReportRemove')}
                                            </SAlert>
                                        </div>
                                    </Snackbar>
                                </SColumn>
                            </STopRow>
                        </SForm>
                        <>
                            {openPdfViewer && (
                                <SPDFDivViewer>
                                    <SPDFViewer>
                                        <PdfDocument
                                            storedAnalysis={storedAnalysis}
                                            ladResults={ladResults}
                                            cxResults={cxResults}
                                            rcaResults={rcaResults}
                                            coverImages={files3D}
                                            patientName={patientNameDebounced}
                                            medicalProps={
                                                medicalReportId != 0 && displayMedicalReportInPdf
                                                    ? medicalProps
                                                    : undefined
                                            }
                                            language={i18n.language}
                                            date={storedAnalysis?.createdOn}
                                            displayFFR={displayFFR}
                                        />
                                    </SPDFViewer>
                                </SPDFDivViewer>
                            )}
                        </>
                        <NoteFieldStoredAnalysis
                            currentStoredAnalysis={storedAnalysis as StoredAnalysis}
                            isFab
                            currentSessionInCookie={currentSessionInCookie}
                        />
                    </SContainerContent>
                </>
            ) : (
                <ErrorMessage message={t('storedAnalysisDetail.error')} />
            )}
        </>
    );
};

export default DisplayPdf;

const SContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    margin: 15px 0 15px 0;
    align-items: center;
    color: ${(props) => props.theme.analysisDetails.medicalReport.textColor};
`;

const SForm = styled.div`
    width: 49vw;
    height: calc(100vh - 70px);
    overflow: auto;
    display: block;
    justify-content: center;
    align-items: center;
`;

const SContainerContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;

const STopRow = styled.div`
    display: block;
`;

const SColumn = styled.div`
    display: block;
`;

const SPDFViewer = styled(PDFViewer)`
    width: 100%;
    height: calc(100vh - 70px);
`;

const SAlert = styled(({ isSuccess, isWarning, ...otherProps }) => (
    <Alert elevation={6} variant="filled" {...otherProps} />
))`
    && {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) =>
            props.isSuccess
                ? props.theme.colors.green
                : props.isWarning
                ? props.theme.colors.orange
                : props.theme.colors.blue};
    }
`;

const SPDFDivViewer = styled.div`
    width: 49vw;
`;

const SContainer = styled.div`
    display: block;
    align-items: baseline;
    flex-direction: column;
    padding: ${(props) => props.theme.getSpacing(3)};
    background-color: ${(props) => props.theme.analysisDetails.cardBackground};
    color: ${(props) => props.theme.analysisDetails.color};
    margin-right: ${(props) => props.theme.getSpacing(3)};
    border-radius: 20px;
`;

const SRowPadding = styled.div`
    width: 90%;
    display: flex;
    padding-top: 20px;
    margin-left: 20px;
`;

const STextField = styled(InputBase)`
    && {
        padding: 5px 20px;
        margin-right: auto;
        max-width: 50%;
        flex: 1;
        border: 1px solid;
        border-color: ${(props) => props.theme.table.select.borderColor};
        background-color: ${(props) => props.theme.analysisDetails.pdfInput.patientNameBackground};
        border-radius: 10px;
        &:focus {
            box-shadow: ${(props) => props.theme.analysisDetails.medicalReport.button.shadow};
        }
    }
`;

const SFormTitle = styled(Typography)`
    && {
        margin: 15px 0 15px 0;
    }
`;

const SContainerLabel = styled.div`
    color: ${(props) => props.theme.analysisDetails.medicalReport.textColor};
    padding-bottom: 20px;
`;

const SBlueIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: inherit;
    width: 18px;
    height: 18px;
    border-radius: 30px;
    margin-right: 5px;
    background: ${(props) => props.theme.colors.blue};
    color: white;
`;

const SHowToRegIcon = styled(HowToRegIcon)`
    font-size: 1rem !important;
`;

const SRow = styled.div`
    flex: 0 1 auto; /* Allows flex container to shrink to fit content */
    display: flex;
    padding: ${(props) => props.theme.getSpacing(1, 0)};
    align-items: center;
    cursor: pointer;
    margin-left: 20px;
`;

const SContainerTypography = styled.div`
    padding: 6px 12px;
    font-size: medium;
    border: 1px solid transparent;
    border-radius: 10px;
    border-color: ${(props) => props.theme.table.select.borderColor};
    color: ${(props) => props.theme.table.select.text};
    transition: border-color 0.3s ease; /* Optional: Smooth transition for border color change */

    &:hover {
        cursor: pointer; /* Show pointer cursor on hover */
        border-color: ${(props) =>
            props.theme.table.select.hoverBorderColor}; /* Optional: Change border color on hover */
    }
`;

const StyledSwitch = styled(Switch)(({ theme }) => ({
    width: '52px !important',
    height: '26px !important',
    padding: '0 !important',
    margin: '5px !important',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '170ms',
        '&.Mui-checked': {
            transform: 'translateX(26px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.analysisDetails.tableResults.switchMode.backgroundSwitchChecked,
                opacity: 1,
                border: 0
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.analysisDetails.tableResults.switchMode.backgroundSwitchNotChecked,
        opacity: 1
    }
}));

