import { getSpacing } from '@/utils/Spacing';
import colors from '@/utils/colors';

const spacing = { unit: 'px', size: 4 };

export default {
    app: {
        background: '#000000',
        buttonBackgroundHover: '#515151',
        buttonBackground: '#d5d5d5',
        switchDarkMode: '#082963',
        switchDarkModeHover: '#061d47'
    },
    navbar: {
        menuBackground: '#111111',
        menuText: '#d1d1d1',
        drawerBackground: '#111111',
        textColor: '#e4e4e4',
        dividerColor: '#5f5f5f',
        languageSwitcher: {
            background: '#2b2b2b',
            backgroundSelected: '#545454',
            backgroundChoice: '#8f8f8f',
            backgroundHover: '#545454',
            color: '#e5e5e5'
        }
    },
    test: {
        background: '#ECEFF4',
        cardBackground: '#E5E9F0',
        buttonBackground: 'rgba(161, 35, 35, 1)',
        textColor: '#0748cc',
        buttonColor: '#ECEFF4'
    },
    switchMode: {
        MuiSwitchTrackBackgroundColor: '#8796A5',
        MuiSwitchThumbBackgroundColor: '#003892'
    },
    card: {
        headerBackground: '#191919ff',
        headerBlueTitle: '#0CB4FF',
        contentBackground: '#303030ff',
        contentText: 'white',
        contentLightText: '#d6d6d6ff',
        dividerBorder: '2px solid #000000b3;',
        dividerBorderRadius: '10px'
    },
    table: {
        test: 'white',
        iconSortColor: '#d5d5d5',
        headerBackground: '#141414',
        headerText: '#8a8a8a',
        contentBackground: '#404040',
        contentDivider: '2px solid #00000080',
        contentText: 'white',
        contentLightText: '#d5d5d5',
        hover: '#6d6d6dff',
        checkbox: '#d5d5d5',
        search: {
            background: '#141414',
            border: '1px solid #d5d5d5',
            borderHover: '1px solid #e1e1e1',
            borderRadius: '10px',
            text: '#d5d5d5',
            label: '#8a8a8a'
        },
        editBackground: '#bdbdbd',
        select: {
            backgroundButton: '#090909',
            background: '#090909',
            backgroundHover: '#bdbdbd3a',
            iconFill: '#eeeeee',
            text: '#d5d5d5',
            reader: '#ffffff',
            borderColor: '#c3c3c3',
            borderColorHover: '#e1e1e1',
            list: {
                background: '#666666'
            }
        },
        noteTextField: {
            outline: '#828282',
            background: '#cfcfcf'
        },
        scrollbar: {
            corner: '#141414',
            color: '#666666'
        }
    },
    miniTable: {
        arrow: {
            background: '#00000057',
            border: '1px solid #2f2f2f'
        },
        cardBackground: '#424242',
        cardBoxShadow: ' 5px 5px 10px #686767, -5px -5px 10px #686767',
        contentText: 'white',
        title: '#e3e3e3',
        currentId: '#545454'
    },
    buttonActionFilled: {
        background: '#737373',
        text: '#e3e3e3',
        border: '1px solid #737373',
        filter: 'drop-shadow(0px 2px 2px #6b6b6b)',
        hover: {
            background: '#e6e6e6',
            text: '#454545',
            border: '1px solid #454545',
            filter: 'drop-shadow(0px 2px 2px #6b6b6b)'
        }
    },
    buttonActionOutlined: {
        background: '#121212',
        text: '#d0d0d0',
        border: '1px solid #121212',
        filter: 'drop-shadow(0px 2px 2px #000000);',
        activeBlue: '#55b9f3',
        hover: {
            background: '#2d2d2d'
        },
        disabled: {
            background: '#686868',
            text: '#a8a8a8'
        }
    },
    buttonLDMode: {
        background: '#3660e3',
        text: '#d0d0d0',
        border: '1px solid #d0d0d0',
        filter: 'drop-shadow(0px 2px 2px #000000);',
        hover: {
            background: '#124c92'
        },
        disabled: {
            background: '#686868',
            text: '#a8a8a8'
        }
    },
    buttonDarkShadow: {
        background: '#363636ff',
        dropShadowRgba: 'drop-shadow(0px 4px 2px #00000040)',
        borderRadius: '7px',
        text: '#ffffffcc',
        hover: {
            background: '#696969ff'
        }
    },
    analysisDetails: {
        cardBackground: '#2c2c2c',
        color: '#d1d1d1',
        tableResults: {
            switchMode: {
                backgroundSwitchChecked: '#55b9f3',
                backgroundSwitchNotChecked: '#6c6c6c',
                aiBackground: '#666666'
            },
            background: '#2c2c2c',
            title: '#8c8c8c',
            content: '#d6d6d6',
            borderColor: '#191919',
            backgroundContent: '#303030',
            warning: {
                background: '#2f161d',
                color: '#f80040'
            }
        },
        status: {
            title: '#737373',
            value: '#959595'
        },
        imageComparative: {
            explicabilityBackground: '#a6a6a68c'
        },
        imageDetails: {
            statistics: {
                cardBackground: '#2c2c2c',
                cardBorder: '1px solid #373737',
                titleColor: '#bebebe',
                valueColor: '#e0e0e0'
            },
            currentImageName: {
                color: '#d1d1d1'
            }
        },
        pdfInput: {
            patientNameBackground: '#cfcfcf'
        },
        medicalReport: {
            textColor: '#cfcfcf',
            button: { shadow: '0px 3px 6px #797979' },
            tableResultsUpdate: {
                background: '#363636',
                title: '#a9a9a9',
                content: '#d6d6d6',
                borderColor: '#191919',
                backgroundContent: '#424242'
            },
            inputFields: {
                inputText: 'white',
                borderColor: '#e0e0e0',
                label: '#7a7a7a',
                comment: {
                    border: '1px solid white',
                    text: 'white',
                    backgroundDefault: '#2c2c2c',
                    backgroundActive: '#363636'
                }
            }
        }
    },
    buttonLightShadow: {
        borderBackground: 'rgba(0, 0, 0, 0.5);',
        boxShadow: '0px 4px 2px #000000',
        borderRadius: '10px',
        backgroundIcon: '#151515',
        backgroundText: '#202020',
        contentText: '#d2d2d2',
        iconColor: '#d0d0d0',
        filter: 'drop-shadow(0px 4px 2px #000000)',
        hover: {
            iconColor: '#cff0ff',
            backgroundIcon: '#0eb4ff',
            boxShadow: '0px 1px 2px #000000'
        }
    },
    patientBlock: {
        hoverColor: 'rgba(0, 0, 0, 0.3)'
    },
    account: {
        textColor: '#CFCFCF',
        background: '#191919ff',
        container: '#2C2C2C',
        iconColor: '#CFCFCF'
    },
    upload: {
        text: '#CFCFCF',
        textAlternative: '#3d3d3d',
        background: '#2b2b2b',
        backgroundButton: '#CFCFCF'
    },
    page: {
        background: '#2b2b2b',
        text: '#CFCFCF',
        invertedText: '#000000',
        lightText: '#acacac',
        hypertext: '#55b9f3'
    },
    colors,
    getSpacing: getSpacing(spacing)
};

