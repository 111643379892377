import React from 'react';
import styled from 'styled-components';
import { useHistory as useHistoryDom } from 'react-router-dom';

import { ButtonBlueDarkBack, Typography } from '@/components/atoms';
import { ForgotPasswordComp } from '@/components/organisms';

import { t } from '@/i18n.js';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useInView } from 'react-intersection-observer';

export const ForgotPassword = () => {
    const history = useHistoryDom();

    const { ref } = useInView({});

    return (
        <>
            <SBackContainer>
                <SBackButtonContainer>
                    <ButtonBlueDarkBack variant="contained" component="span" onClick={() => history.push('/login')}>
                        <ArrowBack ref={ref} />
                        <Typography variant="button">{t('Login')}</Typography>
                    </ButtonBlueDarkBack>
                </SBackButtonContainer>
            </SBackContainer>
            <SContainer>
                <Typography title variant="h1">
                    {t('settings.resetPswd')}
                </Typography>
                <ForgotPasswordComp />
            </SContainer>
        </>
    );
};

export default ForgotPassword;

const SContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.darkblue};
`;

const SBackContainer = styled.div`
    top: 0;
    left: 0;
    width: 100vw;
    padding-top: ${(props) => props.theme.getSpacing(5)};
    padding-bottom: ${(props) => props.theme.getSpacing(5)};
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.darkblue};
`;

const SBackButtonContainer = styled.div`
    width: 10vw;
    padding-top: ${(props) => props.theme.getSpacing(5)};
    padding-bottom: ${(props) => props.theme.getSpacing(5)};
    padding-left: ${(props) => props.theme.getSpacing(5)};
`;

