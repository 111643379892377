import React from 'react';

import ReactGA from 'react-ga';
import FilterButton from '@/components/atoms/Button/FilterButton';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styled from 'styled-components';
import { Typography } from '@/components/atoms';

type Props = {
    onlyNew: boolean;
    setOnlyNew: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FilterNew = (props: Props) => {
    const { onlyNew, setOnlyNew } = props;

    const setOnlyNewStored = () => {
        onlyNew ? localStorage.removeItem('onlyNew') : localStorage.setItem('onlyNew', 'true');
        ReactGA.event({
            category: 'Filter',
            action: 'New'
        });
        setOnlyNew(!onlyNew);
    };

    return (
        <SDiv>
            <FilterButton onClick={() => setOnlyNewStored()} selected={onlyNew}>
                <SFiberManualRecordIcon fontSize="small" />
                <STypography>NEW</STypography>
            </FilterButton>
        </SDiv>
    );
};

export default FilterNew;

const SDiv = styled.div`
    width: 87px;
    text-align: center;
`;

const SFiberManualRecordIcon = styled(FiberManualRecordIcon)`
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 0)};
    color: ${(props) => props.theme.colors.blue};
`;

const STypography = styled(Typography)`
    color: ${(props) => props.theme.table.select.text};
    font-size: 0.9rem !important;
    font-weight: 500 !important;
`;

