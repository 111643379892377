import { logoutFunc, cleanLocalStorage, Session } from '@/utils/contexts/SessionContext';
import { AuthorizedApiBase, IConfig, UserCreationRequestByAdmin, UserUpdateRequestByAdmin } from '@api';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type PropsUpdate = {
    sessionContext: Session;
    updateRequest: UserUpdateRequestByAdmin;
};

type PropsCreate = {
    sessionContext: Session;
    createRequest: UserCreationRequestByAdmin;
};

type PropsDelete = {
    sessionContext: Session;
    userId: number;
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export class SendAdminForm extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    async updateUser(props: PropsUpdate) {
        const { sessionContext, updateRequest } = props;

        let formData = new FormData();

        formData.append('UserId', updateRequest.userId.toString());
        formData.append('IsFFRAvailable', updateRequest.isFFRAvailable.toString());
        formData.append('IsExplicabilityAvailable', updateRequest.isExplicabilityAvailable.toString());

        updateRequest.containerName != undefined ? formData.append('ContainerName', updateRequest.containerName) : '';
        updateRequest.aeTitle != undefined ? formData.append('AeTitle', updateRequest.aeTitle) : '';
        updateRequest.hospital != undefined ? formData.append('Hospital', updateRequest.hospital) : '';
        updateRequest.firstName != undefined ? formData.append('FirstName', updateRequest.firstName) : '';
        updateRequest.lastName != undefined ? formData.append('LastName', updateRequest.lastName) : '';
        updateRequest.email != undefined ? formData.append('Email', updateRequest.email) : '';
        updateRequest.passwordUpdate != undefined
            ? formData.append('PasswordUpdate', updateRequest.passwordUpdate)
            : '';
        updateRequest.licenseExpireDate != undefined // @ts-ignore
            ? formData.append('LicenseExpireDate', updateRequest.licenseExpireDate)
            : '';
        updateRequest.forceLicenceExpireDateToNull != undefined
            ? formData.append('ForceLicenceExpireDateToNull', updateRequest.forceLicenceExpireDateToNull.toString())
            : '';

        if (updateRequest.newDoctorName != undefined && updateRequest.newDoctorEmail != undefined) {
            formData.append('NewDoctorName', updateRequest.newDoctorName);
            formData.append('NewDoctorEmail', updateRequest.newDoctorEmail);
        }

        try {
            const url_ = this.baseUrl + `/admin/users/update`;

            let options_ = <AxiosRequestConfig>{
                data: formData,
                method: 'POST',
                url: url_,
                headers: {
                    Accept: 'application/json'
                }
            };

            return this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                            console.log('Invalid Bearer Token');
                            logoutFunc();
                            cleanLocalStorage();
                            location.reload();
                        }
                        return _error.response;
                    } else {
                        throw _error;
                    }
                })
                .then((_response: AxiosResponse) => {
                    return this.transformResult(url_, _response, (_response: AxiosResponse) =>
                        this.processSend(_response)
                    );
                });
        } catch (err) {
            return err;
        }
    }
    async createUser(props: PropsCreate) {
        const { sessionContext, createRequest } = props;

        let formData = new FormData();

        formData.append('IsFFRAvailable', createRequest.isFFRAvailable.toString());
        formData.append('IsExplicabilityAvailable', createRequest.isExplicabilityAvailable.toString());
        formData.append('PasswordCreation', createRequest.passwordCreation);
        formData.append('FirstName', createRequest.firstName);
        formData.append('LastName', createRequest.lastName);
        formData.append('Email', createRequest.email);

        createRequest.containerName != undefined ? formData.append('ContainerName', createRequest.containerName) : '';
        createRequest.aeTitle != undefined ? formData.append('AeTitle', createRequest.aeTitle) : '';
        createRequest.hospital != undefined ? formData.append('Hospital', createRequest.hospital) : '';

        createRequest.licenseExpireDate != undefined // @ts-ignore
            ? formData.append('LicenseExpireDate', createRequest.licenseExpireDate)
            : '';
        createRequest.forceLicenceExpireDateToNull != undefined
            ? formData.append('ForceLicenceExpireDateToNull', createRequest.forceLicenceExpireDateToNull.toString())
            : '';

        if (createRequest.firstDoctorName != undefined && createRequest.firstDoctorEmail != undefined) {
            formData.append('FirstDoctorName', createRequest.firstDoctorName);
            formData.append('FirstDoctorEmail', createRequest.firstDoctorEmail);
        }

        try {
            const url_ = this.baseUrl + `/admin/users/create`;

            let options_ = <AxiosRequestConfig>{
                data: formData,
                method: 'POST',
                url: url_,
                headers: {
                    Accept: 'application/json'
                }
            };

            return this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        return _error.response;
                    } else {
                        throw _error;
                    }
                })
                .then((_response: AxiosResponse) => {
                    return this.transformResult(url_, _response, (_response: AxiosResponse) =>
                        this.processSend(_response)
                    );
                });
        } catch (err) {
            return err;
        }
    }

    async deleteUser(props: PropsDelete) {
        const { sessionContext, userId } = props;

        try {
            const url_ = this.baseUrl + `/admin/users/delete?userId=` + userId;

            let options_ = <AxiosRequestConfig>{
                method: 'POST',
                url: url_,
                headers: {
                    Accept: 'application/json'
                }
            };

            return this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        return _error.response;
                    } else {
                        throw _error;
                    }
                })
                .then((_response: AxiosResponse) => {
                    return this.transformResult(url_, _response, (_response: AxiosResponse) =>
                        this.processSend(_response)
                    );
                });
        } catch (err) {
            return err;
        }
    }
    protected processSend(response: AxiosResponse) {
        return response;
    }
}

