import { cleanLocalStorage, logoutFunc, Session } from '@/utils/contexts/SessionContext';
import { Screenshot } from '@/utils/contexts/ScreenshotContext';
import { AuthorizedApiBase, IConfig } from '@/../generated/api.client';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type Props = {
    /**Session context containing token and container name */
    sessionContext: Session;
    /**The png to send */
    screenshotContext: Screenshot,
    finalClassification: string,
    comment: string,
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

function makeblob(dataUrl: String) {
    const BASE64_MARKER = ';base64,';
    const parts = dataUrl.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
}

// Cannot call api.client : send an empty [Object object] because does request.toString()
export class SendClient extends AuthorizedApiBase {

    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    async send (props: Props) { 
        const {sessionContext, screenshotContext, finalClassification, comment} = props;
    
        let formData = new FormData();
        let blobImage = makeblob(screenshotContext.screenString);
        formData.append('Image', blobImage);
        formData.append('Classification', finalClassification);
        formData.append('Comment', comment);
    
        try {
            const url_ = this.baseUrl + `/Bugs/send`;

            let options_ = <AxiosRequestConfig>{
                data: formData,
                method: "POST",
                url: url_,
                headers: {
                    "Accept": "application/json"
                }
            };

            return this.transformOptions(options_).then(transformedOptions_ => {
                return this.instance.request(transformedOptions_);
            }).catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    if (_error.response.headers['www-authenticate']?.includes("Bearer")) {
                        console.log('Invalid Bearer Token');
                        logoutFunc();
                        cleanLocalStorage();
                        location.reload();
                    }
                    return _error.response;
                } else {
                    throw _error;
                }
            }).then((_response: AxiosResponse) => {
                return this.transformResult(url_, _response, (_response: AxiosResponse) => this.processSend(_response));
            });
        } catch (err) {
            return err;
        }
    }
    
    protected processSend(response: AxiosResponse) {
        const status = response.status;
        if (status === 200) {
            return status;
        } else {
            return 500;
        }
    }
};
