import { File } from '@/components/molecules';

export const convertPngsToBase64 = async (files: FileList, allFiles: File[]) => {
    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const fileReader = new FileReader();
        const base64 = await new Promise((resolve, reject) => {
            fileReader.onerror = () => {
                fileReader.abort();
                reject(new DOMException('Problem parsing input file.'));
            };

            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
        });

        allFiles.push({
            base64: base64 as string,
            fileName: file.name,
            data: file
        });
    }
};
