import * as React from 'react';
import styled from 'styled-components';

import { Typography } from '@/components/atoms';

type Props = {
    /**set french language*/
    isFrench: boolean;
};

const HowToText = (props: Props) => {
    const { isFrench } = props;

    return isFrench ? FrenchText() : EnglishText();
};

export default HowToText;

const FrenchText = () => (
    <>
        <STitleNoMargin variant="h2">CorEx de Spimed-AI</STitleNoMargin>
        <STitle variant="h2">L’Intelligence artificielle appliquée au Scanner Coronaire</STitle>
        <STypographyThin paragraph variant="subtitle2">
            CorEx est un outil d’intelligence artificielle, développé par Spimed-AI, qui détecte et classe
            automatiquement les lésions coronariennes à partir du scanner coronaire. L'IA prédit la valeur FFR associée
            à la lésion qui permet de choisir le traitement le plus adapté au patient.
            <br />
            <br />
            L’algorithme de CorEx n’a pas encore été entraîné sur des images{' '}
            <STypographyThinUnderline>de stents ou de pontages coronaires</STypographyThinUnderline>. Il n’est donc pas
            validé pour ce type d’images.
            <br />
            <br />
            Pour garantir des performances optimales de CorEx, il est fortement recommandé{' '}
            <STypographyThinUnderline>
                d’utiliser un vasodilatateur par voie sublinguale (dérivés nitrés)
            </STypographyThinUnderline>{' '}
            juste avant l’acquisition des images au scanner.
        </STypographyThin>

        <STitle variant="h2">Comment utiliser CorEx ?</STitle>
        <STypographyThin paragraph variant="subtitle2">
            Recommandations d’usage pour garantir les performances du logiciel :
        </STypographyThin>
        <STypographyBulletPoint variant="subtitle2">&bull; Reconstructions</STypographyBulletPoint>
        <STypographyThin paragraph variant="subtitle2">
            Pour chaque cas, il est nécessaire d’envoyer 9 images MPR curvilignes des trois artères coronaires
            principales avec des reconstructions tous les 40 degrés autour de la ligne centrale.
            <br />
            Il vous suffit d’envoyer les 27 (3x9) images directement sur le lien CorEx via un nœud DICOM configuré sur
            votre poste de travail.
            <br />
            <br />
            - Apportez une attention particulière à la ligne centrale lors de la création des MPR curvilignes, surtout
            lorsque les artères sont calcifiées. Celle-ci doit passer bien au centre de la lumière circulante du
            vaisseau concerné.
            <br />
            <br />- Il est important de zoomer suffisamment les reconstructions, en évitant de couper les coronaires, de
            manière à <STypographyThinUnderline>réduire au mieux les marges noires</STypographyThinUnderline> autour des
            images.
            <br />
            <br />- Les vues MPR curvilignes sont à utiliser de préférence aux vues MPR étirées.
            <br />
        </STypographyThin>
        <STypographyBulletPoint variant="subtitle2">&bull; Fenêtrages</STypographyBulletPoint>
        <STypographyThin paragraph variant="subtitle2">
            <STypographyThinItalicUnderline>Artères non calcifiées :</STypographyThinItalicUnderline>
            <br />
            Niveau de fenêtre (C) : 300
            <br />
            Largeur de fenêtre (W) : 1000
            <br />
            <STypographyThinItalicUnderline>Artères calcifiées / Stents :</STypographyThinItalicUnderline>
            <br />
            Niveau de fenêtre (C) : 500
            <br />
            Largeur de fenêtre (W) : 1500
            <br />
        </STypographyThin>

        <STypographyBulletPoint variant="subtitle2">&bull; Qualité d’acquisition TDM</STypographyBulletPoint>
        <STypographyThin paragraph variant="subtitle2">
            Pour visualiser correctement les coronaires, en matière de contraste, il faut obtenir, par un protocole
            d’injection d’iode adapté, un minimum de{' '}
            <STypographyThinUnderline>
                320 unités Hounsfield (UH) dans la racine aortique, avec un écart type (SD) inférieur à 30. L’écart type
                indiquant le niveau de bruit.
            </STypographyThinUnderline>
        </STypographyThin>
        <br />
    </>
);

const EnglishText = () => (
    <>
        <STitleNoMargin variant="h2">CorEx by Spimed-AI</STitleNoMargin>
        <STitle variant="h2">Artificial Intelligence Applied to Coronary CT Angiography</STitle>
        <STypographyThin paragraph variant="subtitle2">
            CorEx is an artificial intelligence tool developed by Spimed-AI, which automatically detects and classifies
            coronary lesions from coronary CT angiography scans. The AI predicts the fractional flow reserve (FFR)
            related to the stenosis, aiding in selecting the most appropriate treatment for the patient.
            <br />
            <br />
            The CorEx algorithm has not yet been trained on images of{' '}
            <STypographyThinUnderline>stents or coronary bypass grafts</STypographyThinUnderline>, and therefore is not
            validated for this situation.
            <br />
            <br />
            To ensure optimal performance of CorEx, it is strongly recommended to use{' '}
            <STypographyThinUnderline>a sublingual vasodilator (nitrate derivatives)</STypographyThinUnderline> just
            before acquiring images with the scanner.
        </STypographyThin>

        <STitle variant="h2">How to Use CorEx?</STitle>
        <STypographyThin paragraph variant="subtitle2">
            Guidelines for Usage to Ensure Software Performance:
        </STypographyThin>
        <STypographyBulletPoint variant="subtitle2">&bull; Reconstructions</STypographyBulletPoint>
        <STypographyThin paragraph variant="subtitle2">
            For each case, it is necessary to send 9 curved multiplanar reformatted (MPR) images of the three main
            coronary arteries with reconstructions every 40 degrees around the centerline.
            <br />
            Simply send the 27 (3x9) images directly to the CorEx link via a DICOM node configured on your workstation.
            <br />
            <br />
            - Pay particular attention to the centerline extraction when creating curved MPR, especially when arteries
            are calcified. The centerline should pass through the center of the vessel lumen.
            <br />
            <br />- It is important to zoom in sufficiently on the reconstructions, avoiding cutting off the coronary
            arteries, <STypographyThinUnderline>to minimize black margins</STypographyThinUnderline> around the images.
            <br />
            <br />- Curved MPR views are preferable to stretched MPR views.
            <br />
        </STypographyThin>
        <STypographyBulletPoint variant="subtitle2">&bull; Windowing</STypographyBulletPoint>
        <STypographyThin paragraph variant="subtitle2">
            <STypographyThinItalicUnderline>Non-calcified Arteries:</STypographyThinItalicUnderline>
            <br />
            Window Level (C): 300
            <br />
            Window Width (W): 1000
            <br />
            <STypographyThinItalicUnderline>Calcified Arteries / Stents:</STypographyThinItalicUnderline>
            <br />
            Window Level (C): 500
            <br />
            Window Width (W): 1500
            <br />
        </STypographyThin>

        <STypographyBulletPoint variant="subtitle2">&bull; CT Acquisition Quality</STypographyBulletPoint>
        <STypographyThin paragraph variant="subtitle2">
            To visualize coronary arteries correctly in terms of contrast, it is necessary to achieve a minimum of{' '}
            <STypographyThinUnderline>
                320 Hounsfield units (HU) in the aortic root through an appropriate iodine injection protocol, with a
                standard deviation (SD) of less than 30. The standard deviation indicates the level of noise.
            </STypographyThinUnderline>
        </STypographyThin>
        <br />
    </>
);

const STitleNoMargin = styled(Typography)`
    display: flex;
    text-decoration: underline;
    justify-content: center;
    font-style: italic !important;
    margin-bottom: 6px !important;
`;

const STitle = styled(Typography)`
    display: flex;
    text-decoration: underline;
    justify-content: center;
    font-style: italic !important;
    margin-bottom: 20px !important;
`;

const STypographyBulletPoint = styled(Typography)`
    display: flex;
    text-decoration: underline;
    font-style: italic;
`;

const STypographyThin = styled(Typography)`
    && {
        font-weight: 100;
        margin-top: 10px !important;
    }
`;

const STypographyThinItalicUnderline = styled(Typography)`
    && {
        font-weight: 100;
        font-size: 1rem;
        text-decoration: underline;
        font-style: italic;
        display: inline;
    }
`;
const STypographyThinUnderline = styled(Typography)`
    && {
        font-weight: 100;
        font-size: 1rem;
        text-decoration: underline;
        display: inline;
    }
`;

