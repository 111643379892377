import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import { getSessionCookie, useSessionContext } from '@/utils/contexts/SessionContext';
import jwt_decode from 'jwt-decode';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

export const ProtectedRouteAdmin = ({ component: Component, ...rest }: any) => {
    const [, , logout] = useSessionContext();
    const { eraseStoredAnalysisListContext } = useContext(StoredAnalysisListContext);

    return (
        <Route
            {...rest}
            render={(props) => {
                const currentSessionInCookie = getSessionCookie();
                var userRole = '';
                if (currentSessionInCookie?.authResponse?.token) {
                    // Check if the token exists
                    const token = currentSessionInCookie.authResponse.token;

                    if (token.length > 7) {
                        // Ensure that the token is long enough before slicing
                        const slicedToken = token.slice(7);

                        if (slicedToken) {
                            // Check if the sliced token is not empty
                            const decodedToken = jwt_decode(slicedToken);

                            //@ts-ignore
                            if (decodedToken && decodedToken.user_role) {
                                // Check if user_role is present in the decoded token
                                //@ts-ignore
                                userRole = decodedToken.user_role;
                            }
                        }
                    }
                }
                if (!currentSessionInCookie.isAuthenticated || userRole != 'admin') {
                    logout();
                    eraseStoredAnalysisListContext();
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                }
                return <Component {...props} currentSessionInCookie={currentSessionInCookie} />;
            }}
        />
    );
};

export default ProtectedRouteAdmin;

