import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Typography from '../Typography';

type Props = {
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled: boolean;
};

export const ButtonFilled = (props: Props) => {
    const { text, onClick, disabled } = props;

    return (
        <SButton onClick={onClick} disabled={disabled}>
            <Typography>{text}</Typography>
        </SButton>
    );
};

export default ButtonFilled;

const SButton = styled(Button)`
    && {
        text-transform: none;
        background-color: ${(props) => props.theme.buttonActionFilled.background};
        color: ${(props) => props.theme.buttonActionFilled.text};
        filter: ${(props) => props.theme.buttonActionFilled.filter};
        border: ${(props) => props.theme.buttonActionFilled.border};
        padding: ${(props) => props.theme.getSpacing(1, 3)};
        // margin-top: ${(props) => props.theme.getSpacing(2)};
        border-radius: 10px;
        &:hover {
            background-color: ${(props) => props.theme.buttonActionFilled.hover.background};
        }
    }
`;

