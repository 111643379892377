import { AuthorizedApiBase, IConfig } from '@/../generated/api.client';
import { cleanLocalStorage, logoutFunc } from '@/utils/contexts/SessionContext';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

type Props = {
    analysisGuid: string;
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export class RequestExplicability extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    async computeExplicability(props: Props) {
        const { analysisGuid } = props;
        try {
            const url_ = this.baseUrl + `/Explicability`;

            let options_ = <AxiosRequestConfig>{
                method: 'POST',
                url: url_,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                data: {
                    AnalysisGuid: analysisGuid
                }
            };

            var response = await this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                            console.log('Invalid Bearer Token');
                            logoutFunc();
                            cleanLocalStorage();
                            location.reload();
                        }
                        return _error.response;
                    } else {
                        throw _error;
                    }
                });

            if (response.status != 200) {
                return -1;
            }
            return 1;
        } catch (err) {
            return -1;
        }
    }
}
