import React, { useEffect, useMemo, useState } from 'react';
import i18n, { t } from '@/i18n.js';
import { Document, Image, Page, Text, View, Font } from '@react-pdf/renderer';
import { getPathByRads, getPathByStenosis } from '@/utils/ResultsRadsStenosisColors';

import styles from '@/pdf/rads/Styles';
import { ResultsRads } from '@/utils/ResultsRads';
import MedicalReportPage, { Props as medicalProps } from '@/pdf/rads/MedicalReportPage';
import { Language } from '@/utils/Language';
import { Calcification, Classification, ClassificationResult, Coronary, Ffr, IStoredAnalysis, Score } from '@api';
import { File } from '@/components/molecules';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { overrideIntermediateCadRads } from '@/components/atoms/CadRadsDisplay';
import { stenosisClassification } from '@/components/organisms/AnalysisReportCompImages';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

// 2D HEART
import TreeGrey from '@/img/2DHeart/tree/grey.png';
import RcaGrey from '@/img/2DHeart/coronaryLeft/grey.png';
import LadGrey from '@/img/2DHeart/coronaryMiddle/grey.png';
import CxGrey from '@/img/2DHeart/coronaryRight/grey.png';
import RcaNoFFR from '@/img/2DHeart/ffrLeft/noFFR.png';
import LadNoFFR from '@/img/2DHeart/ffrMiddle/noFFR.png';
import CxNoFFR from '@/img/2DHeart/ffrRight/noFFR.png';
import RcaFFR from '@/img/2DHeart/ffrLeft/FFR.png';
import LadFFR from '@/img/2DHeart/ffrMiddle/FFR.png';
import CxFFR from '@/img/2DHeart/ffrRight/FFR.png';
import CoronaryConclusionTableFR from '@/img/conclusion-rads-board-fr.png';
import CoronaryConclusionTableEN from '@/img/conclusion-rads-board-en.png';
import FullLogo from '@/img/full_logo.png';

type Props = {
    storedAnalysis: IStoredAnalysis;
    /**Results for LAD files */
    ladResults: ResultsRads;
    /**Results for Cx files */
    cxResults: ResultsRads;
    /**Results for RCA files */
    rcaResults: ResultsRads;
    coverImages: File[];
    /**The patient name chosen by the user */
    patientName: string;
    medicalProps?: medicalProps;
    /**On language update, reload the React.memo */
    language: string;
    date: Date | undefined;
    // currentSessionInCookie: Session | undefined;
    /**Display the FFR prediction */
    displayFFR: boolean;
};

export type PdfProps = {
    /**The images chosen by the user */
    coverImages: File[];
    /**The patient name chosen by the user */
    patientName: string;
    /**Language chosen by the user */
    language: Language;
};

enum TypeOfPathology {
    OBSTRU = 'obstructive',
    NON_OBSTRU = 'non obstructive',
    NORM = 'normal'
}

export const renderDisclaimerPageNumber = () => (
    <>
        <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber - 1} / ${totalPages - 1}`}
            fixed
        />
    </>
);

export const renderHeader = () => (
    <>
        <Text style={styles.blueBar} fixed>
            {' '}
        </Text>
    </>
);

export const renderFooter = () => (
    <>
        <Text style={styles.redBar} fixed>
            {' '}
        </Text>
    </>
);

const PdfDocument = React.memo((props: Props) => {
    // Memo to prevent rerender when closing medical report dialog
    const {
        storedAnalysis,
        ladResults,
        cxResults,
        rcaResults,
        coverImages,
        /**The patient name chosen by the user */
        patientName,
        medicalProps,
        date,
        displayFFR
    } = props;

    const [conclusionTablePath, setConclusionTablePath] = useState<string>(CoronaryConclusionTableEN);

    const [hasRcaResults, setHasRcaResults] = useState<boolean>(false);
    const [hasLadResults, setHasLadResults] = useState<boolean>(false);
    const [hasCxResults, setHasCxResults] = useState<boolean>(false);

    const [treePath, setTreePath] = useState<string>(TreeGrey); ///('images/2DHeart/tree/grey.png');
    const [rcaFFRPath, setRcaFFRPath] = useState<string>(RcaNoFFR);
    const [ladFFRPath, setLadFFRPath] = useState<string>(LadNoFFR);
    const [cxFFRPath, setCxFFRPath] = useState<string>(CxNoFFR);
    const [rcaCoronaryPath, setRcaCoronaryPath] = useState<string>(RcaGrey);
    const [ladCoronaryPath, setLadCoronaryPath] = useState<string>(LadGrey);
    const [cxCoronaryPath, setCxCoronaryPath] = useState<string>(CxGrey);
    const [medicalRcaCoronaryPath, setMedicalRcaCoronaryPath] = useState<string>(RcaGrey);
    const [medicalLadCoronaryPath, setMedicalLadCoronaryPath] = useState<string>(LadGrey);
    const [medicalCxCoronaryPath, setMedicalCxCoronaryPath] = useState<string>(CxGrey);

    // Use memo so when changing the medical validation, we only call the new png, we don't call all the pngs to create the 2D heart
    const memoizedTreePath = useMemo(() => treePath, [treePath]);
    const memoizedRcaFFRPath = useMemo(() => rcaFFRPath, [rcaFFRPath]);
    const memoizedLadFFRPath = useMemo(() => ladFFRPath, [ladFFRPath]);
    const memoizedCxFFRPath = useMemo(() => cxFFRPath, [cxFFRPath]);
    const memoizedRcaCoronaryPath = useMemo(() => rcaCoronaryPath, [rcaCoronaryPath]);
    const memoizedLadCoronaryPath = useMemo(() => ladCoronaryPath, [ladCoronaryPath]);
    const memoizedCxCoronaryPath = useMemo(() => cxCoronaryPath, [cxCoronaryPath]);
    const memoizedMedicalRcaCoronaryPath = useMemo(() => medicalRcaCoronaryPath, [medicalRcaCoronaryPath]);
    const memoizedMedicalLadCoronaryPath = useMemo(() => medicalLadCoronaryPath, [medicalLadCoronaryPath]);
    const memoizedMedicalCxCoronaryPath = useMemo(() => medicalCxCoronaryPath, [medicalCxCoronaryPath]);

    useEffect(() => {
        if ((i18n.language as Language) === Language.French) setConclusionTablePath(CoronaryConclusionTableFR);
        else if ((i18n.language as Language) === Language.English) setConclusionTablePath(CoronaryConclusionTableEN);
    }, [i18n.language]);

    useEffect(() => {
        setHasRcaResults(rcaResults.Images.length > 0 && rcaResults?.RadsAnalysisResponse?.imageScores?.length > 0);
        if (rcaResults.Images.length > 0 && rcaResults?.RadsAnalysisResponse?.imageScores?.length > 0) {
            setRcaCoronaryPath(
                getPathByRads(
                    rcaResults.RadsAnalysisResponse.classificationResult,
                    rcaResults.RadsAnalysisResponse.coronary
                )
            );
            if (rcaResults.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrMinus) {
                setRcaFFRPath(RcaNoFFR);
            } else if (rcaResults.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrPlus) {
                setRcaFFRPath(RcaFFR);
            }
        }
    }, [rcaResults]);

    useEffect(() => {
        setHasLadResults(ladResults.Images.length > 0 && ladResults?.RadsAnalysisResponse?.imageScores?.length > 0);
        if (ladResults.Images.length > 0 && ladResults?.RadsAnalysisResponse?.imageScores?.length > 0) {
            setLadCoronaryPath(
                getPathByRads(
                    ladResults.RadsAnalysisResponse.classificationResult,
                    ladResults.RadsAnalysisResponse.coronary
                )
            );
            if (ladResults.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrMinus) {
                setLadFFRPath(LadNoFFR);
            } else if (ladResults.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrPlus) {
                setLadFFRPath(LadFFR);
            }
        }
    }, [ladResults]);

    useEffect(() => {
        setHasCxResults(cxResults.Images.length > 0 && cxResults?.RadsAnalysisResponse?.imageScores?.length > 0);
        if (cxResults.Images.length > 0 && cxResults?.RadsAnalysisResponse?.imageScores?.length > 0) {
            setCxCoronaryPath(
                getPathByRads(
                    cxResults.RadsAnalysisResponse.classificationResult,
                    cxResults.RadsAnalysisResponse.coronary
                )
            );

            if (cxResults.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrMinus) {
                setCxFFRPath(CxNoFFR);
            } else if (cxResults.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrPlus) {
                setCxFFRPath(CxFFR);
            }
        }
    }, [cxResults]);

    useEffect(() => {
        if (medicalProps) {
            if (medicalProps.rcaStenosis != 'Null' && medicalProps.rcaStenosis != '') {
                setMedicalRcaCoronaryPath(
                    getPathByStenosis(medicalProps.rcaStenosis, rcaResults.RadsAnalysisResponse.coronary)
                );
            }
            if (medicalProps.ladStenosis != 'Null' && medicalProps.ladStenosis != '') {
                setMedicalLadCoronaryPath(
                    getPathByStenosis(medicalProps.ladStenosis, ladResults.RadsAnalysisResponse.coronary)
                );
            }
            if (medicalProps.cxStenosis != 'Null' && medicalProps.cxStenosis != '') {
                setMedicalCxCoronaryPath(
                    getPathByStenosis(medicalProps.cxStenosis, cxResults.RadsAnalysisResponse.coronary)
                );
            }
        }
    }, [medicalProps]);

    const getMedicalReportPage = () => {
        if (medicalProps) {
            const {
                doctorName,
                technique,
                contrastQuantity,
                dlp,
                indication,
                ladStenosis,
                cxStenosis,
                rcaStenosis,
                conclusionCadRads,
                commentaries,
                cavite,
                mitrale,
                aortique,
                racine,
                pericarde,
                ladCalcification,
                cxCalcification,
                rcaCalcification
            } = medicalProps;
            return (
                <MedicalReportPage
                    doctorName={doctorName}
                    technique={technique}
                    contrastQuantity={contrastQuantity}
                    dlp={dlp}
                    indication={indication}
                    ladStenosis={ladStenosis}
                    cxStenosis={cxStenosis}
                    rcaStenosis={rcaStenosis}
                    conclusionCadRads={conclusionCadRads}
                    commentaries={commentaries}
                    cavite={cavite}
                    mitrale={mitrale}
                    aortique={aortique}
                    racine={racine}
                    pericarde={pericarde}
                    ladCalcification={ladCalcification}
                    cxCalcification={cxCalcification}
                    rcaCalcification={rcaCalcification}
                />
            );
        }
    };

    // ! Keep for futur GlobalImage Quality
    // const getGlobalImageQuality = () => {
    //     const ladClassif = ladResults?.RadsAnalysisResponse?.qualityResult;
    //     const cxClassif = cxResults?.RadsAnalysisResponse?.qualityResult;
    //     const rcaClassif = rcaResults?.RadsAnalysisResponse?.qualityResult;
    //     let result = t('medicalReportForm.imageQuality.low');
    //     if (ladClassif === Quality.Low || cxClassif === Quality.Low || rcaClassif === Quality.Low) {
    //         result = t('medicalReportForm.imageQuality.low');
    //     } else if (ladClassif === Quality.High || cxClassif === Quality.High || rcaClassif === Quality.High) {
    //         result = t('medicalReportForm.imageQuality.high');
    //     }
    //     return result;
    // };

    const getGlobalConclusion = () => {
        return overrideIntermediateCadRads(storedAnalysis.classificationResult);
    };

    const getGlobalMedicalConclusion = () => {
        if (medicalProps?.classificationResult) {
            return overrideIntermediateCadRads(medicalProps?.classificationResult);
        }
    };

    const extractRadsFromResult = (data: Score, classif: Classification) => {
        if (classif == Classification.ThreeClasses || classif == Classification.FourClasses) {
            const maxValue = Math.max(data.rads0, data.rads1, data.rads2, data.rads5);
            switch (maxValue) {
                case data.rads0:
                    return TypeOfPathology.NORM;
                case data.rads1:
                    return TypeOfPathology.NON_OBSTRU;
                case data.rads2:
                    return TypeOfPathology.OBSTRU;
                default:
                    return TypeOfPathology.OBSTRU;
            }
        } else if (classif == Classification.SixClasses) {
            const maxValue = Math.max(data.rads0, data.rads1, data.rads2, data.rads3, data.rads4, data.rads5);
            switch (maxValue) {
                case data.rads0:
                    return TypeOfPathology.NORM;
                case data.rads1:
                case data.rads2:
                    return TypeOfPathology.NON_OBSTRU;
                case data.rads3:
                case data.rads4:
                    return TypeOfPathology.OBSTRU;
                default:
                    return TypeOfPathology.OBSTRU;
            }
        } else {
            // should never be called
            return TypeOfPathology.OBSTRU;
        }
    };

    const getColorForConclusionFfr = (ffrRes: string) => {
        switch (ffrRes) {
            case Ffr.FfrMinus:
                return (
                    <>
                        <Text>{t('medicalReportForm.ischemie.negative')}</Text>
                        <Text> ({'>'} 0.8)</Text>
                    </>
                );
            case Ffr.FfrPlus:
                return (
                    <>
                        <Text style={{ color: '#a81925' }}>{t('medicalReportForm.ischemie.positive')}</Text>
                        <Text> ({'≤'} 0.8)</Text>
                    </>
                );
            case Ffr.Null:
                return <Text style={{ color: '#000000' }}>{t('medicalReportForm.ischemie.notAvailable')}</Text>;
        }
    };

    const getBackgroundColorForConclusionCover = (classRes: ClassificationResult) => {
        let color = getColorByRads(classRes.toString());
        let conclusion = overrideIntermediateCadRads(classRes);
        return (
            <View
                style={{
                    backgroundColor: color,
                    color: 'white',
                    width: 225,
                    height: 30,
                    borderRadius: 10,
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Text style={{ fontSize: 18, fontWeight: 'bold' }}>{conclusion}</Text>
            </View>
        );
    };

    const getBackgroundColorForConclusion = (classRes: ClassificationResult | undefined) => {
        let color = getColorByRads(classRes!.toString());
        let conclusion = overrideIntermediateCadRads(classRes!);
        return (
            <View
                style={{
                    backgroundColor: color,
                    color: 'white',
                    width: 100,
                    height: 25,
                    borderRadius: 4,
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Text>{conclusion}</Text>
            </View>
        );
    };

    const getImageCalcificationIndex = (calcification: Calcification) => {
        let result = t('medicalReportForm.calciumCharge.null');
        if (calcification == Calcification.Minimal) {
            result = t('medicalReportForm.calciumCharge.minimal');
        }
        if (calcification == Calcification.Moderate) {
            result = t('medicalReportForm.calciumCharge.moderate');
        }
        if (calcification == Calcification.Severe) {
            result = t('medicalReportForm.calciumCharge.severe');
        }

        return result;
    };

    const filterImage = (
        results: ResultsRads,
        medicalResultStenosis: string | undefined,
        medicalResultCalcification: string | undefined
    ) => {
        if (!results.RadsAnalysisResponse) {
            return;
        }
        const mostRepresentativeImageIndex = results.RadsAnalysisResponse.bestRadsScoreImageIndex;
        if (results.Images[mostRepresentativeImageIndex] == null) {
            return <Text style={styles.probaTitle}>{t('medicalReportForm.noData')}</Text>;
        }

        // Keep index in memory
        var storeIndexesFromResults = new Map<number, [TypeOfPathology, number]>();
        results.RadsAnalysisResponse.imageScores.forEach((res, index) =>
            storeIndexesFromResults.set(index, [
                extractRadsFromResult(res, results.RadsAnalysisResponse.classification),
                Math.max(res.rads0, res.rads1, res.rads2, res.rads3, res.rads4, res.rads5)
            ])
        );
        // Create specific order => obstructive, then non obstructive then normal
        const customOrder = {
            [TypeOfPathology.OBSTRU]: 1,
            [TypeOfPathology.NON_OBSTRU]: 2,
            [TypeOfPathology.NORM]: 3
        };
        // Reorder the map using the new order and the result maximum
        var mapOrderedWithTypeOfPathology = new Map(
            [...storeIndexesFromResults.entries()].sort(
                (a, b) =>
                    customOrder[a[1][0] as TypeOfPathology] - customOrder[b[1][0] as TypeOfPathology] ||
                    b[1][1] - a[1][1]
            )
        );

        // * ----------------- Structure for each coronary -----------------
        return (
            <>
                <View style={styles.resultImageContainer}>
                    <View style={styles.tableCoronary}>
                        {/* ----------------- Title ----------------- */}
                        <View style={styles.rowTitleCoronary}>
                            <Text>
                                {medicalProps ? (
                                    <>{t('medicalReportForm.doctorresults')}</>
                                ) : (
                                    <>{t('medicalReportForm.airesults')}</>
                                )}
                            </Text>
                            <View style={styles.coronaryName}>
                                <Text>{t(results?.RadsAnalysisResponse.coronary)}</Text>
                            </View>
                        </View>

                        {/* ----------------- Results table ----------------- */}
                        <View style={styles.resultsTable}>
                            {/* ----------------- Stenosis result ----------------- */}
                            <View style={styles.resultsTableRow}>
                                <Text style={styles.resultsTableCoronaryRowElemTitle}>
                                    {t('medicalReportForm.stenosis')}
                                </Text>
                                <Text style={styles.resultsTableRowElem}>
                                    {medicalProps ? (
                                        <>{medicalResultStenosis}</>
                                    ) : (
                                        <>{stenosisClassification(results.RadsAnalysisResponse.classificationResult)}</>
                                    )}
                                </Text>
                            </View>

                            {/* ----------------- Calcification result ----------------- */}
                            <View style={styles.resultsTableRow}>
                                <Text style={styles.resultsTableCoronaryRowElemTitle}>
                                    {t('medicalReportForm.calcification')}
                                </Text>
                                <Text style={styles.resultsTableRowElem}>
                                    {medicalProps ? (
                                        <>{medicalResultCalcification}</>
                                    ) : (
                                        <>
                                            {getImageCalcificationIndex(
                                                results.RadsAnalysisResponse.calcificationResult
                                            )}
                                        </>
                                    )}
                                </Text>
                            </View>

                            {/* ----------------- FFR Prediction result ----------------- */}
                            {displayFFR && (
                                <View style={styles.resultsTableRow}>
                                    <Text style={styles.resultsTableCoronaryRowElemTitle}>
                                        {t('medicalReportForm.ffrPrediction')}
                                    </Text>
                                    <Text style={styles.resultsTableRowElem}>
                                        {getColorForConclusionFfr(results.RadsAnalysisResponse.ffrHardcodeResult)}
                                    </Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>

                {/* ----------------- MPR Images ----------------- */}
                <View style={styles.rowTitleMpr}>
                    <Text>{t('medicalReportForm.mprImages')}</Text>
                </View>
                <View style={styles.smallImageContainer}>
                    {results.Images.map((file: File, index: number) => {
                        return (
                            <View key={index}>
                                {file?.base64 && (
                                    <Image
                                        style={styles.smallImage}
                                        src={file?.base64?.replace(
                                            'data:application/octet-stream;base64',
                                            'data:image/jpeg;base64'
                                        )}
                                    />
                                )}
                            </View>
                        );
                    })}
                </View>
            </>
        );
    };

    // * ----------------- All PDF structure -----------------
    return (
        <Document title={'CorEx - ' + t('medicalReportForm.report')} author={'Spimed-AI'}>
            {/* ------------------------- Cover Page ------------------------- */}
            <Page style={styles.coverPage}>
                {renderHeader()}

                {/* ------------------------- Title ------------------------- */}
                <View style={styles.coverPageMainTitle}>
                    <Text>{t('medicalReportForm.corexReport')}</Text>
                </View>

                {/* ------------------------- Patient name of medical report form, label, date Report of the scan  ------------------------- */}
                <View style={styles.analysisInfo}>
                    <Text>{patientName}</Text>

                    {(!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) &&
                        storedAnalysis.label &&
                        storedAnalysis.label.length > 0 && <Text>{storedAnalysis.label}</Text>}

                    <Text>{t('medicalReportForm.dateReport', { date: date })}</Text>
                </View>

                {/* ------------------------- Global conclusion based on CAD-RADS ------------------------- */}
                {!medicalProps ? (
                    <>{getBackgroundColorForConclusionCover(getGlobalConclusion())}</>
                ) : (
                    <>{getBackgroundColorForConclusionCover(medicalProps.classificationResult)}</>
                )}

                {/* ------------------------- 3D Heart ------------------------- */}
                <View style={styles.coverPageHeartParentpng}>
                    <Image style={styles.coverPageHeartpng} src={memoizedTreePath} />
                    {medicalProps ? (
                        <>
                            <Image style={styles.coverPageHeartpng} src={memoizedMedicalRcaCoronaryPath} />
                            <Image style={styles.coverPageHeartpng} src={memoizedMedicalCxCoronaryPath} />
                            <Image style={styles.coverPageHeartpng} src={memoizedMedicalLadCoronaryPath} />
                        </>
                    ) : (
                        <>
                            <Image style={styles.coverPageHeartpng} src={memoizedRcaCoronaryPath} />
                            <Image style={styles.coverPageHeartpng} src={memoizedCxCoronaryPath} />
                            <Image style={styles.coverPageHeartpng} src={memoizedLadCoronaryPath} />
                        </>
                    )}
                </View>

                {/* ------------------------- Analysis Id ------------------------- */}
                <View>
                    <Text style={styles.smallTextGrey}>
                        {t('pdf.analysisNumber')}
                        {storedAnalysis.id}
                    </Text>
                </View>

                {/* ------------------------- Spimed-AI Logo ------------------------- */}
                <View>
                    <Image style={styles.coverPageLogo} src={FullLogo} />
                </View>

                {renderFooter()}
            </Page>

            {/* ------------------------- Overall Result Page ------------------------- */}
            <Page bookmark={t('medicalReportForm.overallResult')} style={styles.page}>
                {renderHeader()}
                <View style={styles.tableAllCoronaries}>
                    {/* ------------------------- AI Results part ------------------------- */}
                    <View style={styles.rowTitle}>
                        <Text>
                            {medicalProps ? (
                                <>{t('medicalReportForm.doctorresults')}</>
                            ) : (
                                <>{t('medicalReportForm.airesults')}</>
                            )}
                        </Text>
                        <View style={styles.tableAllCoronariesConclusion}>
                            {medicalProps ? (
                                <>{getBackgroundColorForConclusion(getGlobalMedicalConclusion())}</>
                            ) : (
                                <>{getBackgroundColorForConclusion(getGlobalConclusion())}</>
                            )}
                        </View>
                    </View>
                    <View style={styles.resultsTable}>
                        <View style={styles.resultsTableRow}>
                            <Text style={styles.resultsTableRowElemTitleOverall}>{''}</Text>
                            {/* ------------------------- RCA name (Global) ------------------------- */}
                            {hasRcaResults && (
                                <Text style={styles.resultsTableRowElemRads}>
                                    {t(rcaResults.RadsAnalysisResponse.coronary)}
                                </Text>
                            )}

                            {/* ------------------------- LAD name (Global) ------------------------- */}
                            {hasLadResults && (
                                <Text style={styles.resultsTableRowElemRads}>
                                    {t(ladResults.RadsAnalysisResponse.coronary)}
                                </Text>
                            )}

                            {/* ------------------------- CX name (Global) ------------------------- */}
                            {hasCxResults && (
                                <Text style={styles.resultsTableRowElemRads}>
                                    {t(cxResults.RadsAnalysisResponse.coronary)}
                                </Text>
                            )}
                        </View>

                        {/* ------------------------- Stenosis row ------------------------- */}
                        <View style={styles.resultsTableRow}>
                            <Text style={styles.resultsTableRowElemTitleOverall}>
                                {t('medicalReportForm.stenosis')}
                            </Text>
                            {/* ------------------------- RCA result (Global) ------------------------- */}
                            {hasRcaResults && (
                                <>
                                    {medicalProps ? (
                                        <Text style={styles.resultsTableRowElemOverall}>
                                            {medicalProps.rcaStenosis}
                                        </Text>
                                    ) : (
                                        <Text style={styles.resultsTableRowElemOverall}>
                                            {stenosisClassification(
                                                rcaResults.RadsAnalysisResponse.classificationResult
                                            )}
                                        </Text>
                                    )}
                                </>
                            )}
                            {/* ------------------------- LAD result (Global) ------------------------- */}
                            {hasLadResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    <>
                                        {medicalProps ? (
                                            <Text style={styles.resultsTableRowElemOverall}>
                                                {medicalProps.ladStenosis}
                                            </Text>
                                        ) : (
                                            <Text style={styles.resultsTableRowElemOverall}>
                                                {stenosisClassification(
                                                    ladResults.RadsAnalysisResponse.classificationResult
                                                )}
                                            </Text>
                                        )}
                                    </>
                                </Text>
                            )}
                            {/* ------------------------- CX result (Global) ------------------------- */}
                            {hasCxResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    <>
                                        {medicalProps ? (
                                            <Text style={styles.resultsTableRowElemOverall}>
                                                {medicalProps.cxStenosis}
                                            </Text>
                                        ) : (
                                            <Text style={styles.resultsTableRowElemOverall}>
                                                {stenosisClassification(
                                                    cxResults.RadsAnalysisResponse.classificationResult
                                                )}
                                            </Text>
                                        )}
                                    </>
                                </Text>
                            )}
                        </View>
                        {/* ------------------------- Calcification part ------------------------- */}
                        <View style={styles.resultsTableRow}>
                            <Text style={styles.resultsTableRowElemTitleOverall}>
                                {t('medicalReportForm.calcification')}
                            </Text>
                            {/* ------------------------- RCA calcification (Global) ------------------------- */}
                            {hasRcaResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {medicalProps ? (
                                        <>{medicalProps.rcaCalcification}</>
                                    ) : (
                                        <>
                                            {getImageCalcificationIndex(
                                                rcaResults.RadsAnalysisResponse.calcificationResult
                                            )}
                                        </>
                                    )}
                                </Text>
                            )}
                            {/* ------------------------- LAD calcification (Global) ------------------------- */}
                            {hasLadResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {medicalProps ? (
                                        <>{medicalProps.ladCalcification}</>
                                    ) : (
                                        <>
                                            {getImageCalcificationIndex(
                                                ladResults.RadsAnalysisResponse.calcificationResult
                                            )}
                                        </>
                                    )}
                                </Text>
                            )}
                            {/* ------------------------- CX calcification (Global) ------------------------- */}
                            {hasCxResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {medicalProps ? (
                                        <>{medicalProps.cxCalcification}</>
                                    ) : (
                                        <>
                                            {getImageCalcificationIndex(
                                                cxResults.RadsAnalysisResponse.calcificationResult
                                            )}
                                        </>
                                    )}
                                </Text>
                            )}
                        </View>
                        {/* ------------------------- FFR Prediction part ------------------------- */}
                        {displayFFR && (
                            <View style={styles.resultsTableRow}>
                                <Text style={styles.resultsTableRowElemTitleOverall}>
                                    {t('medicalReportForm.ffrPrediction')}
                                </Text>
                                {/* ------------------------- RCA FFR Prediction (Global) ------------------------- */}
                                {hasRcaResults && (
                                    <Text style={styles.resultsTableRowElemOverall}>
                                        {getColorForConclusionFfr(rcaResults.RadsAnalysisResponse.ffrHardcodeResult)}
                                    </Text>
                                )}
                                {/* ------------------------- LAD FFR Prediction (Global) ------------------------- */}
                                {hasLadResults && (
                                    <Text style={styles.resultsTableRowElemOverall}>
                                        {getColorForConclusionFfr(ladResults.RadsAnalysisResponse.ffrHardcodeResult)}
                                    </Text>
                                )}
                                {/* ------------------------- CX FFR Prediction (Global) ------------------------- */}
                                {hasCxResults && (
                                    <Text style={styles.resultsTableRowElemOverall}>
                                        {getColorForConclusionFfr(cxResults.RadsAnalysisResponse.ffrHardcodeResult)}
                                    </Text>
                                )}
                            </View>
                        )}
                    </View>
                </View>
                {/* ------------------------- MPR Images part ------------------------- */}
                <View style={styles.column}>
                    <View style={styles.rowTitle}>
                        <Text>{t('medicalReportForm.mprImages')}</Text>
                    </View>
                    <View style={styles.ctImagesContainerParent}>
                        <View style={styles.ctImagesContainer}>
                            {/* ------------------------- RCA Images part ------------------------- */}
                            {hasRcaResults &&
                                rcaResults.Images[rcaResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64 && (
                                    <View style={styles.ctImagesAndNameParent}>
                                        <Image
                                            style={styles.ctImages}
                                            src={rcaResults.Images[
                                                rcaResults.RadsAnalysisResponse.bestRadsScoreImageIndex
                                            ]?.base64?.replace(
                                                'data:application/octet-stream;base64',
                                                'data:image/jpeg;base64'
                                            )}
                                        />
                                        <View style={styles.ctImagesCoronaryName}>
                                            <Text>{t(rcaResults.RadsAnalysisResponse.coronary)}</Text>
                                        </View>
                                    </View>
                                )}

                            {/* ------------------------- LAD Images part ------------------------- */}
                            {hasLadResults &&
                                ladResults.Images[ladResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64 && (
                                    <View style={styles.ctImagesAndNameParent}>
                                        <Image
                                            style={styles.ctImages}
                                            src={ladResults.Images[
                                                ladResults.RadsAnalysisResponse.bestRadsScoreImageIndex
                                            ]?.base64?.replace(
                                                'data:application/octet-stream;base64',
                                                'data:image/jpeg;base64'
                                            )}
                                        />
                                        <View style={styles.ctImagesCoronaryName}>
                                            <Text>{t(ladResults.RadsAnalysisResponse.coronary)}</Text>
                                        </View>
                                    </View>
                                )}

                            {/* ------------------------- CX Images part ------------------------- */}
                            {hasCxResults &&
                                cxResults.Images[cxResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64 && (
                                    <View style={styles.ctImagesAndNameParent}>
                                        <Image
                                            style={styles.ctImages}
                                            src={cxResults.Images[
                                                cxResults.RadsAnalysisResponse.bestRadsScoreImageIndex
                                            ]?.base64?.replace(
                                                'data:application/octet-stream;base64',
                                                'data:image/jpeg;base64'
                                            )}
                                        />
                                        <View style={styles.ctImagesCoronaryName}>
                                            <Text>{t(cxResults.RadsAnalysisResponse.coronary)}</Text>
                                        </View>
                                    </View>
                                )}
                        </View>
                    </View>
                </View>

                {/* ------------------------- CAD-RADS Classification part ------------------------- */}
                <View style={styles.columnCadRadsConclusion}>
                    <View style={styles.rowTitle}>
                        <Text>{t('medicalReportForm.cadRadsConclusion')}</Text>
                    </View>
                    <View style={styles.classificationImage}>
                        <Image style={styles.conclusionBoard} src={conclusionTablePath} />
                    </View>
                </View>
                {renderDisclaimerPageNumber()}
                {renderFooter()}
            </Page>

            {/* ------------------------- RCA Results part ------------------------- */}
            {rcaResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('medicalReportForm.reportRca')} style={styles.page}>
                    {renderHeader()}
                    <View style={styles.imageContainer}>
                        {filterImage(rcaResults, medicalProps?.rcaStenosis, medicalProps?.rcaCalcification)}
                    </View>
                    {renderDisclaimerPageNumber()}
                    {renderFooter()}
                </Page>
            )}

            {/* ------------------------- LAD Results part ------------------------- */}
            {ladResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('medicalReportForm.reportLad')} style={styles.page}>
                    {renderHeader()}
                    <View style={styles.imageContainer}>
                        {filterImage(ladResults, medicalProps?.ladStenosis, medicalProps?.ladCalcification)}
                    </View>
                    {renderDisclaimerPageNumber()}
                    {renderFooter()}
                </Page>
            )}

            {/* ------------------------- CX Results part ------------------------- */}
            {cxResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('medicalReportForm.reportCx')} style={styles.page}>
                    {renderHeader()}
                    <View style={styles.imageContainer}>
                        {filterImage(cxResults, medicalProps?.cxStenosis, medicalProps?.cxCalcification)}
                    </View>
                    {renderDisclaimerPageNumber()}
                    {renderFooter()}
                </Page>
            )}

            {/* ------------------------- Medical report page part ------------------------- */}
            {medicalProps && getMedicalReportPage()}
        </Document>
    );
});

export default PdfDocument;

