import * as React from 'react';
import styled from 'styled-components';
import { t } from '@/i18n.js';

import { Button, Typography } from '@/components/atoms';
import { DarkModeSwitcher, LanguageSwitcher } from '@/components/molecules';

export const Footer = () => {
    return (
        <SContainer>
            <SBottomLeftContainer>
                <DarkModeSwitcher display={false} />
                <LanguageSwitcher />
            </SBottomLeftContainer>
            <SBottomRightContainer>
                <Button href="mailto:support@spimed-ai.com">
                    <Typography variant="button">{t('footer.contactUs')}</Typography>
                </Button>
            </SBottomRightContainer>
        </SContainer>
    );
};
export default Footer;

const SContainer = styled.div`
    position: relative;
    width: 100%;
    height: 2.5vh;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const SBottomRightContainer = styled.div`
    && {
        display: none; // ATTENTION A CHANGER
        position: absolute;
        right: 0;
        margin-right: ${(props) => props.theme.getSpacing(5)};
        margin-bottom: ${(props) => props.theme.getSpacing(5)};
    }
`;

const SBottomLeftContainer = styled.div`
    && {
        position: absolute;
        left: 0;
        margin-left: ${(props) => props.theme.getSpacing(10)};
        margin-bottom: ${(props) => props.theme.getSpacing(5)};
    }
`;

