import React from 'react';
import { Button as DefaultMaterialButton } from '@mui/material/';
import styled from 'styled-components';

export const Button = styled(({ ...otherProps }) => <DefaultMaterialButton {...otherProps} />)`
    && {
        background-color: ${(props) => props.theme.buttonLightShadow.backgroundIcon};
        box-shadow: ${(props) => props.theme.buttonLightShadow.boxShadow};
        &:hover {
            box-shadow: ${(props) => props.theme.buttonLightShadow.hover.boxShadow};
        }
        border: 1px solid transparent;
        border-radius: 16px;
        color: ${(props) => props.theme.colors.blue};
        padding: ${(props) => props.theme.getSpacing(1, 3)};
        margin: ${(props) => props.theme.getSpacing(0, 4, 0, 0)};
    }
`;

export default Button;

