import React, { useEffect, useState, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';

import { navBarLeft as NavBarLeft, navBarRight as NavBarRight } from '@/components/organisms';

import { Home } from '@/pages/Home';
import { HowTo } from '@/pages/HowTo';
import { Terms } from '@/pages/Terms';
import { Upload } from '@/pages/Upload';
import { Login } from '@/pages/Login';
import { Account } from '@/pages/Account';
import { Statistics } from '@/pages/Statistics';
import { ProtectedRoute, ProtectedRouteAdmin } from '@/components/molecules';
import { ForgotPassword } from '@/pages/ForgotPassword';
import { ForgotPasswordValidation } from '@/pages/transition/ForgotPasswordValidation';
import { ResetPassword } from '@/pages/ResetPassword';
import { ResetPasswordValidation } from '@/pages/transition/ResetPasswordValidation';
import { Analysis } from '@/pages/Analysis';
import { AnalysisRedirect } from '@/pages/AnalysisRedirect';
import { DoctorSelection } from '@/pages/DoctorSelection';
import { DownloadAnalysis } from '@/pages/DownloadAnalysis';
import { AdminUsersList } from '@/pages/AdminUsersList';
import { AdminModifyUser } from '@/pages/AdminModifyUser';
import { AdminCreateUser } from '@/pages/AdminCreateUser';
import { AdminUserStatistics } from '@/pages/AdminUserStatistics';
import { ReportBug } from '@/pages/ReportBug';
import { Information } from '@/pages/Information';

import { CookieBanner, Typography } from '@/components/atoms';
import { useParams } from 'react-router-dom';

import CookiePolicy from './CookiePolicy';
import jwt_decode from 'jwt-decode';
import {
    useSessionContext,
    getSessionCookie,
    cleanLocalStorage,
    initialSession
} from '@/utils/contexts/SessionContext';
import { hasSetCurrentDoctorCookieIsTrue } from '@/utils/contexts/CurrentDoctorContext';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';
import { useTranslation } from 'react-i18next';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

declare const VERSION: string;

type HideMenuProps = {
    musthidemenu: number;
};

export const App = () => {
    const { t } = useTranslation(); // Hook useTranslation on App so the locales are updated in the front

    const [, , logout] = useSessionContext();
    const { eraseStoredAnalysisListContext } = useContext(StoredAnalysisListContext);

    const isCloud = process.env.DeploymentMode == ModeConstant.CLOUD;

    const [renderDisclaimer, setRenderDisclaimer] = useState(false);
    const history = useHistory();
    const [sessioninCookie, setSessionInCookie] = useState(initialSession);

    const pagesToHideAll = ['doctor-selection', 'download-analysis'];
    const nonConnectedPaths = [
        '/login',
        '/forgot-password',
        '/forgot-password-validation',
        '/reset-password/:id',
        '/reset-password-validation',
        '/information',
        '/download-analysis',
        'login',
        'forgot-password',
        'forgot-password-validation',
        'reset-password/:id',
        'reset-password-validation',
        'information',
        'download-analysis'
    ];

    const currentUrl = window.location.href.split('/')[window.location.href.split('/').length - 1];
    const [currentUrlWithId, setCurrentUrlWithId] = useState<string>(
        window.location.href.split('/')[window.location.href.split('/').length - 3]
    );
    const url = useParams();

    // During the loading of the application
    useEffect(() => {
        if (localStorage.getItem('doctors') != null) {
            // To delete after all storage "doctors" has been cleaned (CorEx v2.1.0 or 2.2.0), for now it creates white empty page
            logout();
            eraseStoredAnalysisListContext();
            cleanLocalStorage();
            history.push('/login');
        }
        // For case when user goes to /login instead of accepting CGU
        var currentSessionInCookie = getSessionCookie();
        setSessionInCookie(currentSessionInCookie);
        if (nonConnectedPaths.includes(history.location.pathname)) {
            if (currentSessionInCookie.isAuthenticated == true) {
                logout();
                eraseStoredAnalysisListContext();
                cleanLocalStorage();
            }
        }

        // Compare the version to force a re-login if version too old
        const appVersion = localStorage.getItem('version');
        if (appVersion === null || appVersion !== VERSION) {
            logout();
            eraseStoredAnalysisListContext();
            localStorage.setItem('version', VERSION);
            history.push('/login');
        }
    }, []);

    const [isLightMode, setIsLightMode] = useState<boolean>(true);
    const [themeContext, updateTheme] = useThemeContext();
    useEffect(() => {
        setIsLightMode(themeContext.isLightMode);
    }, [themeContext.isLightMode]);

    // Force reconnection if trying to dodge terms/password check
    useEffect(() => {
        history.listen((location) => {
            var currentSessionInCookie = getSessionCookie();
            setSessionInCookie(currentSessionInCookie);
            if (currentSessionInCookie.isAuthenticated == true) {
                let hasAcceptedTerms =
                    JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7))))
                        .hasAcceptedTerms == 'True';
                let isPasswordUpdateRequired =
                    JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7))))
                        .isPasswordUpdateRequired == 'True';
                let renderDisclaimerLoc =
                    JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7))))
                        .disclaimer == 'True';
                setRenderDisclaimer(renderDisclaimerLoc);
                if (location.pathname != '/login' && location.pathname != '/doctor-selection') {
                    if (!hasAcceptedTerms || isPasswordUpdateRequired) {
                        logout();
                        eraseStoredAnalysisListContext();
                        history.push('/login');
                        window.location.reload(); // reload is updating contexts
                    }
                }
                if (location.pathname == '/login') {
                    if (!(!hasAcceptedTerms || isPasswordUpdateRequired)) {
                        history.push('/home');
                    } else {
                        logout();
                        eraseStoredAnalysisListContext();
                        window.location.reload(); // reload is updating contexts
                    }
                }
                // Force to choose a doctor if trying to dodge the page choice
                if (location.pathname != '/doctor-selection' && !hasSetCurrentDoctorCookieIsTrue()) {
                    history.push('/doctor-selection');
                }
            } else {
                setRenderDisclaimer(false);
                if (!nonConnectedPaths.includes(history.location.pathname)) {
                    logout();
                    eraseStoredAnalysisListContext();
                    window.location.reload();
                    cleanLocalStorage();
                } else {
                    cleanLocalStorage();
                }
            }
        });
    }, [history]);

    useEffect(() => {
        setCurrentUrlWithId(window.location.href.split('/')[window.location.href.split('/').length - 2]);
    }, [currentUrl, currentUrlWithId, url]);

    return (
        <>
            <ThemeProvider theme={isLightMode ? lightTheme : darkTheme}>
                <SApp>
                    <SPages>
                        <NavBarLeft currentSessionInCookie={sessioninCookie} />
                        <SPageContentWithoutHeader
                            musthidemenu={
                                pagesToHideAll.includes(currentUrl) ||
                                pagesToHideAll.includes(currentUrlWithId) ||
                                nonConnectedPaths.includes(currentUrl)
                                    ? 1
                                    : 0
                            }
                        >
                            <Switch>
                                <Route path="/login" component={Login} />
                                <Route path="/forgot-password" component={ForgotPassword} />
                                <Route path="/forgot-password-validation" component={ForgotPasswordValidation} />
                                <Route path="/reset-password/:url" component={ResetPassword} />
                                <Route path="/reset-password-validation" component={ResetPasswordValidation} />
                                {isCloud && (
                                    <Route
                                        path="/download-analysis/:url"
                                        render={(props) => (
                                            <DownloadAnalysis {...props} currentSessionInCookie={sessioninCookie} />
                                        )}
                                    />
                                )}

                                <Route path="/cookie-policy" component={CookiePolicy} />
                                <Route path="/information" component={Information} />
                                <ProtectedRoute path="/terms" component={Terms} />
                                <ProtectedRoute path="/doctor-selection" component={DoctorSelection} />
                                <ProtectedRoute path="/how-to" component={HowTo} />
                                <ProtectedRoute path="/upload" component={Upload} />
                                <ProtectedRoute path="/account" component={Account} />
                                <ProtectedRoute path="/analysis-explorer/:id" component={Analysis} />
                                <ProtectedRoute path="/analysis-explorer" component={AnalysisRedirect} />
                                <ProtectedRoute path="/statistics" component={Statistics} />
                                {isCloud && <ProtectedRoute path="/bugs/send-email" component={ReportBug} />}
                                <ProtectedRouteAdmin path="/admin/users" component={AdminUsersList} />
                                <ProtectedRouteAdmin path="/admin/user/:id" component={AdminModifyUser} />
                                <ProtectedRouteAdmin path="/admin/new-user" component={AdminCreateUser} />
                                <ProtectedRouteAdmin
                                    path="/admin/statistics/user/:id"
                                    component={AdminUserStatistics}
                                />
                                <ProtectedRoute path="/" component={Home} />
                                <ProtectedRoute path="/home" component={Home} />
                            </Switch>
                        </SPageContentWithoutHeader>
                        {renderDisclaimer ? (
                            <SFooter>
                                <Typography>{t('footer.disclaimer')}</Typography>
                            </SFooter>
                        ) : (
                            <></>
                        )}
                        <CookieBanner />
                    </SPages>
                </SApp>
            </ThemeProvider>
        </>
    );
};

export default App;

const SApp = styled.div`
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: ${(props) => props.theme.app.background};
`;

const SPages = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 0;
`;

const SPageContentWithoutHeader = styled.div<HideMenuProps>`
    margin-top: ${(propsHide: HideMenuProps) => (propsHide.musthidemenu == 1 ? '0 px' : '50px')};
    height: ${(propsHide: HideMenuProps) => (propsHide.musthidemenu == 1 ? '100%' : 'calc(100vh - 50px)')};
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    overflow: auto;
    -ms-overflow-style: none; /* Hide scrollbar : IE and Edge */
    scrollbar-width: none; /* Hide scrollbar : Firefox */
    ::-webkit-scrollbar {
        display: none;
    }
`;

const SFooter = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    user-select: none;
    z-index: 9999;
    color: ${(props) => props.theme.page.text};
`;

