import React, { useCallback, useContext, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { t } from '@/i18n.js';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';

import { Typography } from '@/components/atoms';
import { Dialog } from '@/components/molecules';

import { Session } from '@/utils/contexts/SessionContext';

import { IStoredAnalysis } from '@api';
import { DeleteAnalysisService } from '@/services/DeleteAnalysisService';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Props = {
    /**The analysis to open*/
    selectedR: number[];
    currentSessionInCookie: Session;
};

export const DeleteAnalysisSelected = (props: Props) => {
    const { selectedR, currentSessionInCookie } = props;
    const { setStoredAnalysisListContext, storedAnalysisListContext } = useContext(StoredAnalysisListContext);

    const [isLoading, setIsLoading] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(!dialogOpen);
    };

    const onDelete = useCallback(async () => {
        setIsLoading(true);
        const stored = new Array<IStoredAnalysis | undefined>(0);
        for (const selectedRow of selectedR) {
            stored.push(storedAnalysisListContext.find((analysis) => analysis.id == selectedRow));
        }
        let storedAnalysisList: number[] = [];
        for (const a of stored) {
            if (a != undefined) {
                storedAnalysisList.push(a.id);
            }
        }
        const deleteStoredAnalysis = new DeleteAnalysisService(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        await deleteStoredAnalysis.DeleteStoredAnalysis({ storedAnalysisList });
        const updatedRows = storedAnalysisListContext.filter(
            (row: IStoredAnalysis) => !storedAnalysisList.includes(row.id)
        );
        setStoredAnalysisListContext(updatedRows);
        setIsLoading(false);
        setDialogOpen(false);
    }, [storedAnalysisListContext, currentSessionInCookie, selectedR]);

    return (
        <>
            <Dialog
                open={dialogOpen}
                customTitle={t('analysisExplorer.analysisTable.deleteDialogWarning')}
                text={t('analysisExplorer.analysisTable.areYouSureYouWantToDeleteSelected')}
                onClose={onDelete}
                disableChildrenButton={isLoading}
                childrenButton={
                    isLoading ? (
                        <Typography variant="button">{t('analysisExplorer.analysisTable.loading')}</Typography>
                    ) : (
                        <Typography red variant="button">
                            {t('analysisExplorer.analysisTable.delete')}
                        </Typography>
                    )
                }
                withCancel
                disableEscapeKeyDown
                handleCancel={handleClick}
            />
            {selectedR.length == 0 ? (
                <></>
            ) : (
                <Tooltip title={t('analysisExplorer.analysisTable.deleteAnalysis') || ''}>
                    <IconButton onClick={handleClick}>
                        <SDeleteIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default DeleteAnalysisSelected;

const SDeleteIcon = styled(DeleteIcon)`
    color: ${(props) => props.theme.table.contentLightText};
`;

