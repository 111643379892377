import React from 'react';
import { useParams } from 'react-router-dom';

import { Session } from '@/utils/contexts/SessionContext';
import { AdminUserForm } from '@/components/organisms';

type AdminUserRoute = {
    id: string;
};

type Props = {
    currentSessionInCookie: Session;
};

export const AdminModifyUser = (props: Props) => {
    const { currentSessionInCookie } = props;
    const { id } = useParams<AdminUserRoute>();

    return <AdminUserForm currentSessionInCookie={currentSessionInCookie} isUpdate={true} id={id}></AdminUserForm>;
};

export default AdminModifyUser;

