import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Session } from '@/utils/contexts/SessionContext';
import { PieChartStatisticsHome } from '@/components/molecules/PieChartStatisticsHome';
import { LineChartStatisticsHome } from '@/components/molecules/LineChartStatisticsHome';
import { initialStoredAnalysis } from '@/pages/DisplayPdf';
import { AdminClient } from '@api';

type Props = {
    currentSessionInCookie: Session;
    userId: number;
};

export const AdminStatisticsHome = (props: Props) => {
    const { currentSessionInCookie, userId } = props;

    const [isStatsLoading, setIsStatsLoading] = useState(false);
    const [stAnalysis, setStAnalysis] = useState([initialStoredAnalysis]);

    const [year, setYear] = useState<string>(new Date().getFullYear().toString());

    useEffect(() => {
        (async function iieFunction() {
            setIsStatsLoading(true);

            const adminClient = new AdminClient(
                { token: currentSessionInCookie.authResponse.token },
                process.env.BackendServiceURL
            );
            adminClient.getUserStatistics(userId).then((response) => {
                setStAnalysis(response);
                setIsStatsLoading(false);
            });
        })();
    }, [userId]);

    return (
        <SStatisticsContainer>
            <SPieContainer>
                <PieChartStatisticsHome
                    isStatsLoading={isStatsLoading}
                    stAnalysis={stAnalysis}
                    year={year}
                    setYear={setYear}
                />
            </SPieContainer>
            <SLineContainer>
                <LineChartStatisticsHome
                    isStatsLoading={isStatsLoading}
                    stAnalysis={stAnalysis}
                    year={year}
                    setYear={setYear}
                />
            </SLineContainer>
        </SStatisticsContainer>
    );
};

export default AdminStatisticsHome;

const SStatisticsContainer = styled.div`
    display: flex;
    align-items: start;
    flex-direction: row;
    justify-content: space-around;
`;

const SPieContainer = styled.div`
    background-color: ${(props) => props.theme.page.background};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    margin: 2%;
    width: 28vw;
`;

const SLineContainer = styled.div`
    background-color: ${(props) => props.theme.page.background};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    margin: 2%;
`;

