import React from 'react';
import styled from 'styled-components';
import { t } from '@/i18n.js';

import StatisticsHome from '@/components/organisms/StatisticsHome';
import { Typography } from '@/components/atoms';

import { Session } from '@/utils/contexts/SessionContext';

type Props = {
    currentSessionInCookie: Session;
};

export const Statistics = (props: Props) => {
    const { currentSessionInCookie } = props;

    return (
        <>
            <STypographyDiv>
                <Typography subtitle="true" variant="h2">
                    {t('statistics.title')}
                </Typography>
            </STypographyDiv>

            <StatisticsHome currentSessionInCookie={currentSessionInCookie} />
        </>
    );
};

const STypographyDiv = styled.div`
    width: 90%;
    display: flex;
    color: ${(props) => props.theme.page.text};
`;

export default Statistics;

