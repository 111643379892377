import { Switch } from '@mui/material';
import styled from 'styled-components';

const SwitchUpload = 'main_window/images/switch_upload.png';

export const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-root': {
        padding: 0
    },
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        boxShadow: 'inset 6px 6px 13px #bababa, inset -6px -6px 13px #ffffff',
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16
        },
        '&:before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.colors.darkblue
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12
        },
        '&:after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.colors.black
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'box-shadow:  3px 3px 5px #cccccc, -3px -3px 5px #ffffff;',
        width: 20,
        height: 20,
        marginLeft: 2,
        borderRadius: '20%',
        backgroundColor: theme.colors.white,
        backgroundImage: `<img src=${SwitchUpload} />`
    }
}));

export default Android12Switch;

