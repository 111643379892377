import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
require('dotenv').config();
import styled from 'styled-components';
import '@/i18n.js';

import '@fontsource/inter';

import theme from '@/utils/styles/Theme';
import { MuiOverrideTheme } from '@/utils/styles/MuiOverrideTheme';

import { App } from '@/pages/App';
import { SessionContextProvider } from '@/utils/contexts/SessionContext';
import { StoredAnalysisListProvider } from '@/utils/contexts/StoredAnalysisListContext';
import { CurrentDoctorContextProvider } from '@/utils/contexts/CurrentDoctorContext';
import { ScreenshotContextProvider } from '@/utils/contexts/ScreenshotContext';
import { SnackbarContextProvider } from '@/utils/contexts/SnackbarContext';
import { CircularProgress } from '@/components/atoms';
import { HashRouter } from 'react-router-dom';
import { ThemeContextProvider } from '@/utils/contexts/ThemeContext';

const GlobalStyles = createGlobalStyle`
    body {
        font-family: 'Inter', sans-serif;
        color: hsla(0, 0%, 0%, 0.8);
        background-color: #dadada;
        -ms-overflow-style: none;  /* Hide scrollbar : IE and Edge */
        scrollbar-width: none;  /* Hide scrollbar : Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    body::-webkit-scrollbar {
        display: none;
    }
`;

const SCenter = styled.div`
    display: 'flex';
    justify-content: 'center';
    width: '100%';
    height: '100%';
`;

ReactDOM.render(
    <MuiThemeProvider theme={MuiOverrideTheme}>
        <ThemeContextProvider>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <SessionContextProvider>
                    <SnackbarContextProvider>
                        <CurrentDoctorContextProvider>
                            <StoredAnalysisListProvider>
                                <ScreenshotContextProvider>
                                    <Suspense
                                        fallback={
                                            <SCenter>
                                                <CircularProgress />
                                            </SCenter>
                                        }
                                    >
                                        <HashRouter>
                                            <App />
                                        </HashRouter>
                                    </Suspense>
                                </ScreenshotContextProvider>
                            </StoredAnalysisListProvider>
                        </CurrentDoctorContextProvider>
                    </SnackbarContextProvider>
                </SessionContextProvider>
            </ThemeProvider>
        </ThemeContextProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
);

