import React, { useCallback } from 'react';
import styled from 'styled-components';

import { CircularProgress, PieChart } from '@/components/atoms';
import { ClassificationResult } from '@api';
import { IPieInfo } from '@/utils/Graphs';
import theme from '@/utils/styles/Theme';

export type CadRadsStats = {
    [ClassificationResult.Rads0]: number;
    [ClassificationResult.Rads1]: number;
    [ClassificationResult.Rads12]: number;
    [ClassificationResult.Rads2]: number;
    [ClassificationResult.Rads23]: number;
    [ClassificationResult.Rads3]: number;
    [ClassificationResult.Rads34]: number;
    [ClassificationResult.Rads4]: number;
    [ClassificationResult.Rads5]: number;
};

type Props = {
    /**if the stats are still loading */
    isStatsLoading: boolean;
    /***The value for each cad-rads */
    cadRadsStats: CadRadsStats;
    /**The number of analysis (depends of the date range in the parent component) */
    storedAnalysisLength: number;
};
export const PieChartStatistics = (props: Props) => {
    const { isStatsLoading, cadRadsStats, storedAnalysisLength } = props;

    const getPercentage = (
        rads:
            | ClassificationResult.Rads0
            | ClassificationResult.Rads1
            | ClassificationResult.Rads12
            | ClassificationResult.Rads2
            | ClassificationResult.Rads23
            | ClassificationResult.Rads3
            | ClassificationResult.Rads34
            | ClassificationResult.Rads4
            | ClassificationResult.Rads5
    ) => ((cadRadsStats[rads] / storedAnalysisLength) * 100) | 0;

    const getPieData = useCallback(() => {
        let res: IPieInfo = { pieData: [], colors: [] };

        if (cadRadsStats[ClassificationResult.Rads0] !== 0) {
            res.pieData.push({ id: [ClassificationResult.Rads0], value: getPercentage(ClassificationResult.Rads0) });
            res.colors.push(theme.colors.green);
        }
        if (cadRadsStats[ClassificationResult.Rads1] !== 0) {
            res.pieData.push({
                id: [ClassificationResult.Rads1],
                value: getPercentage(ClassificationResult.Rads1)
            });
            res.colors.push(theme.colors.yellow);
        }
        if (cadRadsStats[ClassificationResult.Rads12] !== 0) {
            res.pieData.push({
                id: [ClassificationResult.Rads12],
                value: getPercentage(ClassificationResult.Rads12)
            });
            res.colors.push(theme.colors.yellowHover);
        }
        if (cadRadsStats[ClassificationResult.Rads2] !== 0) {
            res.pieData.push({
                id: [ClassificationResult.Rads2],
                value: getPercentage(ClassificationResult.Rads2)
            });
            res.colors.push(theme.colors.orange);
        }
        if (cadRadsStats[ClassificationResult.Rads23] !== 0) {
            res.pieData.push({
                id: [ClassificationResult.Rads23],
                value: getPercentage(ClassificationResult.Rads23)
            });
            res.colors.push(theme.colors.orangeHover);
        }
        if (cadRadsStats[ClassificationResult.Rads3] !== 0) {
            res.pieData.push({
                id: [ClassificationResult.Rads3],
                value: getPercentage(ClassificationResult.Rads3)
            });
            res.colors.push(theme.colors.red);
        }
        if (cadRadsStats[ClassificationResult.Rads34] !== 0) {
            res.pieData.push({
                id: [ClassificationResult.Rads34],
                value: getPercentage(ClassificationResult.Rads34)
            });
            res.colors.push(theme.colors.redHover);
        }
        if (cadRadsStats[ClassificationResult.Rads4] !== 0) {
            res.pieData.push({
                id: [ClassificationResult.Rads4],
                value: getPercentage(ClassificationResult.Rads4)
            });
            res.colors.push(theme.colors.darkred);
        }
        if (cadRadsStats[ClassificationResult.Rads5] !== 0) {
            res.pieData.push({
                id: [ClassificationResult.Rads5],
                value: getPercentage(ClassificationResult.Rads5)
            });
            res.colors.push(theme.colors.strongdarkred);
        }

        return res;
    }, [cadRadsStats]);

    return (
        <>
            {isStatsLoading ? (
                <CircularProgress />
            ) : (
                <SPie>
                    <PieChart data={getPieData().pieData} needPercentage={true} colors={getPieData().colors} />
                </SPie>
            )}
        </>
    );
};

export default PieChartStatistics;

const SPie = styled.div`
    width: 20rem;
    height: 20rem;
`;

