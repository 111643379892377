import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { CircularProgress, SelectCharts } from '@/components/atoms';
import { ClassificationResult, IStoredAnalysis } from '@api';
import styled from 'styled-components';
import { gatherStatistics } from '@/services/StatisticsService';
import { t } from '@/i18n.js';
import { IPieInfo } from '@/utils/Graphs';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { Doughnut } from 'react-chartjs-2';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

// Analyses are now only 0, 1, 2, 3, 4, 5.
// We do no longer have intermediate (1-2, 2-3, 3-4), the intermediate are shown as 2, 3, 4, with a range percentage.
// In the stats page, the 1-2 become 2, 2-3 become 3, 3-4 become 4
export type CadRadsStats = {
    [ClassificationResult.Rads0]: number;
    [ClassificationResult.Rads1]: number;
    [ClassificationResult.Rads2]: number;
    [ClassificationResult.Rads3]: number;
    [ClassificationResult.Rads4]: number;
    [ClassificationResult.Rads5]: number;
};

export type DatasetsDataChartJSType = {
    label: string;
    data: Array<number>;
    backgroundColor: Array<string>;
    borderColor: Array<string>;
    borderWidth: number;
};

export type DataChartJSType = {
    labels: Array<string>;
    datasets: DatasetsDataChartJSType[];
};

const InitialCadRadsStats: CadRadsStats = {
    [ClassificationResult.Rads0]: 0,
    [ClassificationResult.Rads1]: 0,
    [ClassificationResult.Rads2]: 0,
    [ClassificationResult.Rads3]: 0,
    [ClassificationResult.Rads4]: 0,
    [ClassificationResult.Rads5]: 0
};

type Props = {
    /**if the stats are still loading */
    isStatsLoading: boolean;
    /***The value for each CAD-RADS */
    stAnalysis: IStoredAnalysis[];
    /*** Year for the statistics */
    year: string;
    /*** Set the year for the statistics */
    setYear: Dispatch<SetStateAction<string>>;
};

export const PieChartStatisticsHome = (props: Props) => {
    const { isStatsLoading, stAnalysis, year, setYear } = props;

    const [cadRadsStats, setCadRadsStats] = useState<CadRadsStats>(InitialCadRadsStats);
    const [previousYear, setPreviousYear] = useState<string>(new Date().getFullYear().toString());
    const [key, setKey] = useState(0); // Key to force chart redraw
    const [yearValues, setYearValues] = useState<string[]>(['No Analysis']);
    const [storedAnalysisLength, setStoredAnalysisLength] = useState<number>(0);
    const [textValue, setTextValue] = useState<string>('');
    const today = new Date();

    useEffect(() => {
        (async function iieFunction() {
            const statisticsFetched = gatherStatistics(stAnalysis);
            const oldestStoredDate = statisticsFetched.oldestStoredDate;
            if (oldestStoredDate && oldestStoredDate.getFullYear() != 2100) {
                const oldest_year = oldestStoredDate.getFullYear();
                const current_year = today.getFullYear();
                setYearValues(
                    [...Array(current_year - oldest_year + 1).keys()].map((x) => (x + oldest_year).toString())
                );
            }
            updatePieChart();
        })();
    }, [stAnalysis]);

    useEffect(() => {
        (async function iieFunction() {
            if (year !== previousYear) {
                updatePieChart();
            }
        })();
    }, [year, storedAnalysisLength]);

    const updatePieChart = () => {
        let yearStats;
        if (year === 'all') {
            yearStats = gatherStatistics(stAnalysis, { from: new Date(1900, 0, 1), to: today });
        } else {
            const yearInt = parseInt(year);
            setPreviousYear(year);
            if (yearInt == today.getFullYear()) {
                yearStats = gatherStatistics(stAnalysis, { from: new Date(yearInt, 0, 1), to: today });
            } else {
                yearStats = gatherStatistics(stAnalysis, {
                    from: new Date(yearInt, 0, 1),
                    to: new Date(yearInt + 1, 0, 1)
                });
            }
        }
        let newStoredAnalysisLength = yearStats.storedAnalysisLength;

        setStoredAnalysisLength(newStoredAnalysisLength);

        let newValuePatient = yearStats.storedAnalysisLength;
        setTextValue(newValuePatient > 1 ? 'patients' : 'patient');

        setCadRadsStats(yearStats.cadRadsStats);
        redrawChart();
    };

    const getValue = (
        rads:
            | ClassificationResult.Rads0
            | ClassificationResult.Rads1
            | ClassificationResult.Rads2
            | ClassificationResult.Rads3
            | ClassificationResult.Rads4
            | ClassificationResult.Rads5
    ) => cadRadsStats[rads] | 0;

    const getPercentage = (
        rads:
            | ClassificationResult.Rads0
            | ClassificationResult.Rads1
            | ClassificationResult.Rads2
            | ClassificationResult.Rads3
            | ClassificationResult.Rads4
            | ClassificationResult.Rads5
    ) => ((cadRadsStats[rads] / storedAnalysisLength) * 100) | 0;

    const getPieData = useCallback(() => {
        let res: IPieInfo = { pieData: [], colors: [] };

        if (cadRadsStats[ClassificationResult.Rads0] !== 0) {
            res.pieData.push({
                id: [
                    ClassificationResult.Rads0 +
                        ': ' +
                        getPercentage(ClassificationResult.Rads0) +
                        '% (' +
                        getValue(ClassificationResult.Rads0) +
                        ')'
                ],
                value: getValue(ClassificationResult.Rads0)
            });
            res.colors.push(getColorByRads(ClassificationResult.Rads0));
        }
        if (cadRadsStats[ClassificationResult.Rads1] !== 0) {
            res.pieData.push({
                id: [
                    ClassificationResult.Rads1 +
                        ': ' +
                        getPercentage(ClassificationResult.Rads1) +
                        '% (' +
                        getValue(ClassificationResult.Rads1) +
                        ')'
                ],
                value: getValue(ClassificationResult.Rads1)
            });
            res.colors.push(getColorByRads(ClassificationResult.Rads1));
        }
        if (cadRadsStats[ClassificationResult.Rads2] !== 0) {
            res.pieData.push({
                id: [
                    ClassificationResult.Rads2 +
                        ': ' +
                        getPercentage(ClassificationResult.Rads2) +
                        '% (' +
                        getValue(ClassificationResult.Rads2) +
                        ')'
                ],
                value: getValue(ClassificationResult.Rads2)
            });
            res.colors.push(getColorByRads(ClassificationResult.Rads2));
        }
        if (cadRadsStats[ClassificationResult.Rads3] !== 0) {
            res.pieData.push({
                id: [
                    ClassificationResult.Rads3 +
                        ': ' +
                        getPercentage(ClassificationResult.Rads3) +
                        '% (' +
                        getValue(ClassificationResult.Rads3) +
                        ')'
                ],
                value: getValue(ClassificationResult.Rads3)
            });
            res.colors.push(getColorByRads(ClassificationResult.Rads3));
        }
        if (cadRadsStats[ClassificationResult.Rads4] !== 0) {
            res.pieData.push({
                id: [
                    ClassificationResult.Rads4 +
                        ': ' +
                        getPercentage(ClassificationResult.Rads4) +
                        '% (' +
                        getValue(ClassificationResult.Rads4) +
                        ')'
                ],
                value: getValue(ClassificationResult.Rads4)
            });
            res.colors.push(getColorByRads(ClassificationResult.Rads4));
        }
        if (cadRadsStats[ClassificationResult.Rads5] !== 0) {
            res.pieData.push({
                id: [
                    ClassificationResult.Rads5 +
                        ': ' +
                        getPercentage(ClassificationResult.Rads5) +
                        '% (' +
                        getValue(ClassificationResult.Rads5) +
                        ')'
                ],
                value: getValue(ClassificationResult.Rads5)
            });
            res.colors.push(getColorByRads(ClassificationResult.Rads5));
        }

        let dataChartJS: DataChartJSType = {
            labels: [],
            datasets: [
                {
                    label: 'Patients',
                    data: [],
                    backgroundColor: res.colors,
                    borderColor: res.colors,
                    borderWidth: 1
                }
            ]
        };
        //@ts-ignore
        if (res.pieData.length > 0) {
            for (let i = 0; i < res.pieData.length; i++) {
                if (res.pieData[i].id !== undefined) {
                    dataChartJS.labels.push(res.pieData[i].id as string);
                    //@ts-ignore
                    dataChartJS.datasets[0].data.push(res.pieData[i].value as number);
                }
            }
        }
        return { res, dataChartJS };
    }, [cadRadsStats]);

    // Data for the doughnut
    const pieData = getPieData()['res'];

    const data = {
        labels: pieData.pieData.map((item) => item.id),
        datasets: [
            {
                label: '#',
                data: pieData.pieData.map((item) => item.value),
                backgroundColor: pieData.colors
            }
        ]
    };

    const options: any = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        }
    };

    const textCenter = {
        id: 'textCenter',
        beforeDraw: function (chart: any) {
            const width = chart.chartArea?.width;
            const height = chart.chartArea?.height;
            const ctx = chart.ctx as CanvasRenderingContext2D;
            if (!width) {
                console.error('width undefined in pie chart');
                return;
            }
            if (!height) {
                console.error('height undefined in pie chart');
                return;
            }
            if (!ctx) {
                console.error('ctx undefined in pie chart');
                return;
            }

            const fontSize = (height / 100).toFixed(2);

            ctx.restore();

            ctx.font! = fontSize + 'em Inter, sans-serif';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';

            const numberPatientText = `${storedAnalysisLength}`;
            const numberPatientTextX = Math.round(width / 2);
            const numberPatientTextY = height / 2.2;
            ctx.fillText(numberPatientText, numberPatientTextX, numberPatientTextY);

            const patientText = t(textValue);
            const patientTextX = Math.round(width / 2);
            const patientTextY = height / 1.8;
            const fontSizePatientText = (height / 200).toFixed(2);
            ctx.font! = fontSizePatientText + 'em Inter, sans-serif';
            ctx.fillText(patientText, patientTextX, patientTextY);

            ctx.save();
        }
    };

    const redrawChart = () => {
        // This function will change the key, triggering a redraw
        setKey((prevKey) => prevKey + 1);
    };

    return (
        <>
            {isStatsLoading ? (
                <SMiddle>
                    <CircularProgress />
                </SMiddle>
            ) : (
                <SPie>
                    <SSearchDate>
                        <SelectCharts
                            disabled={false}
                            width="6vw"
                            height="2vh"
                            fontSize="100%"
                            onChange={setYear}
                            values={yearValues}
                            value={year.toString()}
                        />
                    </SSearchDate>
                    <SContainer>
                        <Doughnut key={key} data={data} options={options} plugins={[textCenter]} redraw={true} />
                    </SContainer>
                </SPie>
            )}
        </>
    );
};

export default PieChartStatisticsHome;

const SMiddle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    height: 75vh;
    left: 10px;
`;

const SPie = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 75vh;
`;

const SSearchDate = styled.div`
    display: flex;
    justify-content: end;
`;

const SContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
`;

