import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Tooltip, Popper, IconButton, ClickAwayListener, TextField, Paper, Fab } from '@mui/material';
import { t } from '@/i18n.js';

import NotesIcon from '@mui/icons-material/Notes';

import { Session } from '@/utils/contexts/SessionContext';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { StoredAnalysisServiceAxios } from '@/services/StoredAnalysisService';

import { StoredAnalysis, IStoredAnalysis } from '@api';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Props = {
    currentSessionInCookie: Session;
    /**The analysis to open*/
    currentStoredAnalysis: StoredAnalysis;
    /**Change component displayed (for StoredAnalysisDetail page)*/
    isFab?: boolean;
};

export const NoteFieldStoredAnalysis = (props: Props) => {
    const { currentStoredAnalysis, currentSessionInCookie, isFab } = props;
    const { setStoredAnalysisListContext, storedAnalysisListContext } = useContext(StoredAnalysisListContext);

    const [open, setOpen] = useState(false);
    const [note, setNote] = useState(currentStoredAnalysis.note);
    const [snackbarContext, updateSnackbar] = useSnackbarContext();

    let ref = useRef(null);

    const handleClick = () => {
        setOpen(!open);
        (async function iie() {
            if (note !== null && note !== currentStoredAnalysis.note) {
                let oldNote = currentStoredAnalysis.note;

                const storedAnalysisService = new StoredAnalysisServiceAxios(
                    { token: currentSessionInCookie.authResponse.token },
                    process.env.BackendServiceURL
                );
                try {
                    storedAnalysisService
                        .saveNote({
                            analysisId: currentStoredAnalysis.id,
                            value: note
                        })
                        .then((response) => {
                            if (response == 400 || response == 500) {
                                if (response == 400) {
                                    updateSnackbar({
                                        snackString: t('snackbar.error.note.validation'),
                                        severity: 'error'
                                    });
                                } else {
                                    updateSnackbar({
                                        snackString: t('snackbar.error.note.generic'),
                                        severity: 'error'
                                    });
                                }
                                const updatedRows = storedAnalysisListContext.map((row: IStoredAnalysis) => {
                                    if (row.id === currentStoredAnalysis.id) {
                                        return { ...row, note: oldNote };
                                    }
                                    return row;
                                });
                                setStoredAnalysisListContext(updatedRows);
                                setNote(oldNote);
                                setOpen(true);
                            } else {
                                const updatedRows = storedAnalysisListContext.map((row: IStoredAnalysis) => {
                                    if (row.id === currentStoredAnalysis.id) {
                                        return { ...row, note: note };
                                    }
                                    return row;
                                });
                                setStoredAnalysisListContext(updatedRows);
                                setNote(note);
                                currentStoredAnalysis.note = note;
                            }
                        });
                } catch (err) {
                    console.log(err);
                }
            }
        })();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNote(event.target.value);
    };

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lengthOfInput = event.target.value.length;
        return event.target.setSelectionRange(lengthOfInput, lengthOfInput);
    };

    return (
        <SContainer>
            <Tooltip title={t('analysisExplorer.analysisTable.openNotes') || ''}>
                {isFab ? (
                    <SStickyFab onClick={handleClick} color="primary">
                        <NotesIcon ref={ref} />
                    </SStickyFab>
                ) : (
                    <IconButton onClick={handleClick}>
                        <NotesIcon ref={ref} />
                    </IconButton>
                )}
            </Tooltip>
            <Popper open={open} anchorEl={ref.current}>
                <ClickAwayListener onClickAway={handleClick}>
                    <SPaper elevation={20}>
                        <STextField
                            defaultValue={note ? note : currentStoredAnalysis.note}
                            label={t('analysisExplorer.analysisTable.noteNumber', { id: currentStoredAnalysis.id })}
                            variant="outlined"
                            multiline
                            onChange={handleChange}
                            placeholder={t('analysisExplorer.analysisTable.addNotes')}
                            rows={3}
                            autoFocus={true}
                            inputProps={{ maxLength: 64500 }} // Front and back validation can differ depending on bytes => ask a lower max in the front
                            onFocus={handleFocus}
                        />
                    </SPaper>
                </ClickAwayListener>
            </Popper>
        </SContainer>
    );
};

export default NoteFieldStoredAnalysis;

const SContainer = styled.div`
    display: flex;
    z-index: 100;
`;

const SPaper = styled(Paper)`
    && {
        padding: ${(props) => props.theme.getSpacing(3)};
        border-radius: 10px;
        width: 28rem;
    }
`;

const STextField = styled(TextField)`
    && {
        width: 100%;
    }
`;

const SStickyFab = styled(Fab)`
    && {
        position: fixed;
        bottom: ${(props) => props.theme.getSpacing(6)};
        right: 6vw;
    }
`;

