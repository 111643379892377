import React, { useEffect, useState, useRef, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import styled from 'styled-components';
import { t } from '@/i18n.js';

import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { Button, ButtonGroup } from '@mui/material';

// DB.JSON handle function
import { HybridService } from '@/services/HybridService';
import { DbEntry } from '@/components/molecules/AnalysisTable';

//Components
import { Loader, Typography } from '@/components/atoms';
import { ErrorMessage, File } from '@/components/molecules';
import {
    AnalysisReportCompImages,
    AnalysisReportCompDetails,
    AnalysisTableMini,
    Heart3D
} from '@/components/organisms';
//Animation
import loadingHeart from '@/utils/animations/loading_heart.json';
import loadingHeartDark from '@/utils/animations/darkmode/loading_heart.json';
import noDataRows from '@/utils/animations/no-data-rows.json';

import { filterImages } from '@/utils/FileOrdering';
import { Session } from '@/utils/contexts/SessionContext';

import { initialResultRads, ResultsRads } from '@/utils/ResultsRads';

import { GetStoredAnalysisDetail, SaveReader } from '@/services/StoredAnalysisService';
import { DownloadImagesFromBack, DownloadExplicabilityFromBack } from '@/services/ImageService';
// MedicalReport
import { initialMedicalPropsValue, Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';
import { translateAllMedicalPropsAndGetSelectBoundaries } from '@/utils/MedicalReportFormTranslation';

//Icons
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import StenosisSeverityDark from '@/img/SVG/darkmode/stenosis_severity.svg';
import StenosisSeverity from '@/img/SVG/stenosis_severity.svg';
import FFRRangeDark from '@/img/SVG/darkmode/ffr_range.svg';
import FFRRange from '@/img/SVG/ffr_range.svg';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import {
    Classification,
    ClassificationResult,
    Coronary,
    ImageOrderInStorage,
    IStoredAnalysis,
    MedicalReportClient
} from '@api';
import { Alert, Snackbar } from '@mui/material';
import { HasOpenedService } from '@/services/HasOpenedService';
import jwt_decode from 'jwt-decode';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import DisplayPdf from './DisplayPdf';
import AnalysisReportImagesList from '@/components/organisms/AnalysisReportImagesList';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';
import { DownloadImagesFromVmBack } from '@/services/VmBlobService';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

type SColumnProps = {
    display: string;
};

type AnalysisRoute = {
    /**Analysis ID is passed as an URL parameters*/
    id: string;
};

type IsCurrentButtonProps = {
    iscurrent: number;
};

const SubPageResults = 'results';
const SubPageImages = 'images';
const SubPagePDF = 'pdf';

const initialStoredAnalysis: IStoredAnalysis = {
    id: 0,
    label: '',
    classificationResult: ClassificationResult.Error,
    classification: Classification.SixClasses,
    imageOrderInStorage: ImageOrderInStorage.LadCxRca,
    analysisGuid: '',
    note: '',
    highLighted: false,
    reader: '',
    memo: '',
    isFromPacs: false,
    isExplicabilityComputed: false,
    hasBeenOpened: false,
    token: ''
};

type Props = {
    currentSessionInCookie: Session;
};

export const Analysis = (props: Props) => {
    const { currentSessionInCookie } = props;

    const { id } = useParams<AnalysisRoute>();
    const [subPageInUrl, setSubPageInUrl] = useState<string>('');
    const location = useLocation();

    const { storedAnalysisListContext, setStoredAnalysisListContext } = useContext(StoredAnalysisListContext);

    const [hasBeenOpened, setHasBeenOpened] = useState<boolean>(false);
    const [hasBeenReadBy, setHasBeenReadBy] = useState<string>('');

    const history = useHistory();
    const [themeContext, updateTheme] = useThemeContext();

    const [snackbarContext, updateSnackbar] = useSnackbarContext();

    const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingGraphs, setIsLoadingGraphs] = useState(true);
    const [isLoadingTotal, setIsLoadingTotal] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [isNoAnalysis, setIsNoAnalysis] = useState(false);

    const [currentId, setCurrentId] = useState(parseInt(id));
    const [previousId, setPreviousId] = useState(parseInt(id));

    const [currentSubPageOpened, setCurrentSubPageOpened] = useState<string>(SubPageResults); // for now : 'results', 'images'. Future: add 'dicomViewer'

    /* Résultats par coronaire */
    const [ladResults, setLAD] = useState<ResultsRads>(initialResultRads);
    const [cxResults, setCX] = useState<ResultsRads>(initialResultRads);
    const [rcaResults, setRCA] = useState<ResultsRads>(initialResultRads);
    const coronaryArray: Array<Coronary> = [Coronary.LAD, Coronary.Cx, Coronary.RCA];

    /** PDF 3D files and medical report */
    const [files3D, setFiles3D] = useState<File[]>([]);
    const [medicalReportId, setMedicalReportId] = useState<number>(-1);
    const [displayMedicalReportInPdf, setDisplayMedicalReportInPdf] = useState<boolean>(false);
    const [medicalProps, setMedicalProps] = useState<MedicalProps>(initialMedicalPropsValue);

    const [patientNameVar, setPatientNameVar] = useState<string>('');

    /* Positionnement des coronaires */
    const [currentCoronary, setCurrentCoronary] = useState<Coronary>(Coronary.NotSpecified);
    const [leftCoronary, setLeftCoronary] = useState<Coronary>(Coronary.NotSpecified);
    const [rightCoronary, setRightCoronary] = useState<Coronary>(Coronary.NotSpecified);

    /* Image explicabilité */
    const [ladResultsExplicability, setLadExplicability] = useState<string>('');
    const [cxResultsExplicability, setCxExplicability] = useState<string>('');
    const [rcaResultsExplicability, setRcaExplicability] = useState<string>('');

    /* Chargement des graphes */
    const [globalGraph, setGlobalGraph] = useState<string>('');
    const [pdfBlob, setPdfBlob] = useState<Blob>(new Blob());
    const [storedAnalysis, setStoredAnalysis] = useState<IStoredAnalysis>(initialStoredAnalysis);

    /* Usestate Ouverture du modal */
    const [open, setOpen] = useState(false);

    /* Couleur du CAD RADS de l'analyse complète */
    const [colorAnalysis, setColorAnalysis] = useState<string>('');

    /* Affichage du modal sans le coeur 3D */
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    /* Affichage des détails d'un coronaire à la place du coeur 3D */
    const [coronaryImagesListOpen, setCoronaryImagesListOpen] = useState<string>('null');

    /* Le switch IA / Doctor pour CoronariesDetails est par défaut sur Doctor. Le switch s'affiche si medicalReportId > 0 */
    const [isMedicalReportValidationDisplayed, setIsMedicalReportValidationDisplayed] = useState(true);

    const updateModal = (statusModal: boolean): void => {
        setModalOpen(statusModal);
    };

    const updateCoronaryImagesList = (statusCoronaryImageList: string): void => {
        setCoronaryImagesListOpen(statusCoronaryImageList);
    };

    /* Réduction du panneau de liste des analyses */
    const [hideAnalysisTable, setHideAnalysisTable] = useState<boolean>(true);

    /** Afficher la FFR */
    const [showFFR, setShowFFR] = useState<boolean>(false);

    const ffrAvailable =
        //@ts-ignore
        JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7)))).isFFRAvailable ==
        'True';

    useEffect(() => {
        setMedicalReportId(-1);
        (async function iifeFunction() {
            if (isInitialLoadingDone && (isLoading || isLoadingTotal) && currentId != previousId) {
                // Cannot click on analysisB if analysisA is already loading and we are waiting for the results
                history.push('/analysis-explorer/' + previousId.toString());
                setCurrentId(previousId);
                return;
            }
            setPreviousId(currentId);
            try {
                setIsLoading(true);
                setIsLoadingTotal(true);
                if (id == '-1') {
                    setIsNoAnalysis(true);
                    return;
                }
                setIsNoAnalysis(false);
                let resultsStoredDetails = await GetStoredAnalysisDetail({
                    sessionContext: currentSessionInCookie,
                    analysisId: currentId
                }).catch((err) => {
                    history.push('/home');
                    updateSnackbar({
                        snackString: t('snackbar.error.analysis.server'),
                        severity: 'error'
                    });
                });
                /** Avoid to create a TS(2339) Error - Property 'X' does not exist on type 'void' since :
                 * let resultsStoredDetails: void | StoredAnalysisResponse
                 * Now with the if statement we only have a resultStoredDetails as a StoredAnalysisResponse type
                 */
                if (resultsStoredDetails !== undefined) {
                    if (resultsStoredDetails.radsAnalysisResponsesList.length == 0) {
                        history.push('/home');
                        updateSnackbar({
                            snackString: t('snackbar.error.analysis.server'),
                            severity: 'error'
                        });
                    }

                    const currentStoredAnalysis = resultsStoredDetails.storedAnalysis;
                    setStoredAnalysis(currentStoredAnalysis);
                    const results = resultsStoredDetails.radsAnalysisResponsesList;
                    let downloadedF;
                    if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
                        const imageService = new DownloadImagesFromVmBack(
                            { token: currentSessionInCookie.authResponse.token },
                            process.env.BackendServiceURL
                        );

                        downloadedF = await imageService
                            .downloadAllBlobs({
                                sessionContext: currentSessionInCookie,
                                analysisGuid: currentStoredAnalysis.analysisGuid,
                                isFromPacs: currentStoredAnalysis.isFromPacs
                            })
                            .catch((err: any) => {
                                updateSnackbar({
                                    snackString: t('snackbar.error.analysis.server'),
                                    severity: 'error'
                                });
                            });
                    } else {
                        const imageService = new DownloadImagesFromBack(
                            { token: currentSessionInCookie.authResponse.token },
                            process.env.BackendServiceURL
                        );
                        downloadedF = await imageService
                            .downloadAllBlobs({
                                sessionContext: currentSessionInCookie,
                                analysisId: currentId
                            })
                            .catch((err) => {
                                history.push('/home');
                                updateSnackbar({
                                    snackString: t('snackbar.error.analysis.server'),
                                    severity: 'error'
                                });
                            });
                    }
                    let downloadedFiles = downloadedF as File[];

                    const allFiles: File[] = await FilterBlobsByCoronary(
                        Coronary.NotSpecified,
                        downloadedFiles,
                        currentStoredAnalysis.isFromPacs
                    );
                    const ladFiles: File[] = await FilterBlobsByCoronary(
                        Coronary.LAD,
                        downloadedFiles,
                        currentStoredAnalysis.isFromPacs
                    );
                    const cxFiles: File[] = await FilterBlobsByCoronary(
                        Coronary.Cx,
                        downloadedFiles,
                        currentStoredAnalysis.isFromPacs
                    );
                    const rcaFiles: File[] = await FilterBlobsByCoronary(
                        Coronary.RCA,
                        downloadedFiles,
                        currentStoredAnalysis.isFromPacs
                    );

                    if (currentStoredAnalysis.isExplicabilityComputed) {
                        let downloadedF;
                        if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
                            const imageService = new DownloadImagesFromVmBack(
                                { token: currentSessionInCookie.authResponse.token },
                                process.env.BackendServiceURL
                            );

                            downloadedF = await imageService
                                .downloadExplicability({
                                    sessionContext: currentSessionInCookie,
                                    analysisGuid: currentStoredAnalysis.analysisGuid,
                                    isFromPacs: currentStoredAnalysis.isFromPacs
                                })
                                .catch((err: any) => {
                                    console.log('err analysis explicability', err);
                                    history.push('/home');
                                    updateSnackbar({
                                        snackString: t('snackbar.error.analysis.server'),
                                        severity: 'error'
                                    });
                                });
                        } else {
                            const imageService = new DownloadExplicabilityFromBack(
                                { token: currentSessionInCookie.authResponse.token },
                                process.env.BackendServiceURL
                            );
                            downloadedF = await imageService
                                .downloadAllBlobs({
                                    sessionContext: currentSessionInCookie,
                                    analysisId: currentStoredAnalysis.id
                                })
                                .catch((err: any) => {
                                    console.log('err analysis explicability', err);
                                    history.push('/home');
                                    updateSnackbar({
                                        snackString: t('snackbar.error.analysis.server'),
                                        severity: 'error'
                                    });
                                });
                        }

                        const explicabilityFiles = downloadedF as File[];

                        let hasLADExplicability = false;
                        let hasCxExplicability = false;
                        let hasRCAExplicability = false;

                        for (const explicabilityFile of explicabilityFiles) {
                            if (explicabilityFile['fileName'].includes('LAD')) {
                                if (explicabilityFile['fileName'].includes('FFR')) {
                                } else {
                                    setLadExplicability(
                                        explicabilityFile?.base64?.replace(
                                            'data:application/octet-stream;base64',
                                            'data:image/jpeg;base64'
                                        )
                                    );
                                    hasLADExplicability = true;
                                }
                            } else if (explicabilityFile['fileName'].includes('Cx')) {
                                if (explicabilityFile['fileName'].includes('FFR')) {
                                    // setCxExplicabilityFFR(explicabilityFile.base64);
                                    // hasCxExplicabilityFFR = true;
                                } else {
                                    setCxExplicability(
                                        explicabilityFile?.base64?.replace(
                                            'data:application/octet-stream;base64',
                                            'data:image/jpeg;base64'
                                        )
                                    );
                                    hasCxExplicability = true;
                                }
                            } else if (explicabilityFile['fileName'].includes('RCA')) {
                                if (explicabilityFile['fileName'].includes('FFR')) {
                                    // setRcaExplicabilityFFR(explicabilityFile.base64);
                                    // hasRCAExplicabilityFFR = true;
                                } else {
                                    setRcaExplicability(
                                        explicabilityFile?.base64?.replace(
                                            'data:application/octet-stream;base64',
                                            'data:image/jpeg;base64'
                                        )
                                    );
                                    hasRCAExplicability = true;
                                }
                            }
                        }
                        if (!hasLADExplicability) setLadExplicability('null');
                        if (!hasCxExplicability) setCxExplicability('null');
                        if (!hasRCAExplicability) setRcaExplicability('null');
                    } else {
                        setLadExplicability('null');
                        setCxExplicability('null');
                        setRcaExplicability('null');
                    }

                    const is27 = allFiles.length > 0;
                    let indexLad: number = -1;
                    let indexCx: number = -1;
                    let indexRca: number = -1;
                    let indexForImagesLad: number = -1;
                    let indexForImagesCx: number = -1;
                    let indexForImagesRca: number = -1;
                    if (is27) {
                        const { ladI, cxI, rcaI } = getIndexesFromStoredAnalysis(
                            currentStoredAnalysis.imageOrderInStorage
                        );
                        indexForImagesLad = ladI;
                        indexForImagesCx = cxI;
                        indexForImagesRca = rcaI;
                    }
                    indexLad = results.findIndex((pred) => pred.coronary === Coronary.LAD);
                    indexCx = results.findIndex((pred) => pred.coronary === Coronary.Cx);
                    indexRca = results.findIndex((pred) => pred.coronary === Coronary.RCA);

                    let intermediateLad = {
                        isLoading: false,
                        Images: is27
                            ? indexForImagesLad !== -1
                                ? filterImages(allFiles, ladFiles, is27, indexForImagesLad)
                                : []
                            : indexLad !== -1
                            ? filterImages(allFiles, ladFiles, is27, indexLad)
                            : [],
                        RadsAnalysisResponse:
                            indexLad !== -1 ? results[indexLad] : initialResultRads.RadsAnalysisResponse
                    };
                    let intermediateCx = {
                        isLoading: false,
                        Images: is27
                            ? indexForImagesCx !== -1
                                ? filterImages(allFiles, cxFiles, is27, indexForImagesCx)
                                : []
                            : indexCx !== -1
                            ? filterImages(allFiles, cxFiles, is27, indexCx)
                            : [],
                        RadsAnalysisResponse: indexCx !== -1 ? results[indexCx] : initialResultRads.RadsAnalysisResponse
                    };
                    let intermediateRca = {
                        isLoading: false,
                        Images: is27
                            ? indexForImagesRca !== -1
                                ? filterImages(allFiles, rcaFiles, is27, indexForImagesRca)
                                : []
                            : indexRca !== -1
                            ? filterImages(allFiles, rcaFiles, is27, indexRca)
                            : [],
                        RadsAnalysisResponse:
                            indexRca !== -1 ? results[indexRca] : initialResultRads.RadsAnalysisResponse
                    };

                    setLAD((lad) => ({
                        ...lad,
                        ...intermediateLad
                    }));
                    setCX((cx) => ({
                        ...cx,
                        ...intermediateCx
                    }));
                    setRCA((rca) => ({
                        ...rca,
                        ...intermediateRca
                    }));

                    setFiles3D(allFiles.filter((file) => file.fileName.includes('3D')));

                    if (!currentStoredAnalysis.hasBeenOpened && currentStoredAnalysis.analysisGuid != undefined) {
                        const hasOpenedService = new HasOpenedService(
                            { token: currentSessionInCookie.authResponse.token },
                            process.env.BackendServiceURL
                        );
                        await hasOpenedService
                            .hasBeenOpened({ analysisIdList: [currentStoredAnalysis.id] })
                            .then(() => {
                                currentStoredAnalysis.hasBeenOpened = true;
                            });

                        setHasBeenOpened(true);
                    }

                    if (currentStoredAnalysis.hasBeenOpened) {
                        // Verify the analysis is "has been opened" in the storage
                        let updatedRows = [];
                        let needToUpdateStorage = false;
                        for (const stored of storedAnalysisListContext) {
                            let s = stored;
                            if (currentId == s.id && s.hasBeenOpened == false) {
                                needToUpdateStorage = true;
                                s.hasBeenOpened = true;
                            }
                            updatedRows.push(s);
                        }
                        if (needToUpdateStorage) {
                            setStoredAnalysisListContext(updatedRows);
                        }
                    }

                    const currentDoctorName = JSON.parse(localStorage.getItem('current_doctor') || '""').name;
                    if (
                        (currentStoredAnalysis.reader == '' || currentStoredAnalysis.reader == null) &&
                        currentStoredAnalysis.analysisGuid != undefined &&
                        currentDoctorName != '' &&
                        currentDoctorName != undefined
                    ) {
                        await SaveReader({
                            sessionContext: currentSessionInCookie,
                            analysisId: currentStoredAnalysis.id,
                            value: currentDoctorName
                        })
                            .catch((_error: any) => {
                                console.log('could not save reader');
                            })
                            .then((resp) => {
                                if (resp == currentDoctorName) {
                                    setHasBeenReadBy(currentDoctorName);
                                }
                            });
                    }

                    if (resultsStoredDetails.medicalReport.id != undefined) {
                        const medicalReportClient = new MedicalReportClient(
                            { token: currentSessionInCookie.authResponse.token },
                            process.env.BackendServiceURL
                        );
                        const medicalReport = medicalReportClient
                            .getByStoredAnalysisId(currentId)
                            .then((result) => {
                                let medicalPropsTranslatedAndSelectBoundaries =
                                    translateAllMedicalPropsAndGetSelectBoundaries({
                                        doctorName: result.doctor,
                                        technique: result.techniqueName || '',
                                        contrastQuantity: result.techniqueContrastAgentQuantityML || undefined,
                                        dlp: result.techniqueDlpMgyCm || undefined,
                                        indication: result.indication || '',
                                        ladStenosis: result.lad || '',
                                        cxStenosis: result.cx || '',
                                        rcaStenosis: result.rca || '',
                                        conclusionCadRads: result.conclusion || '',
                                        classificationResult:
                                            result.classificationResult || ClassificationResult.NotSet,
                                        commentaries: result.comments || '',
                                        cavite: result.cardiacCavities || '',
                                        mitrale: result.mitralValve || '',
                                        aortique: result.aorticValve || '',
                                        racine: result.aorticRoot || '',
                                        pericarde: result.pericardium || '',
                                        ladCalcification: result.ladCalcification || '',
                                        cxCalcification: result.cxCalcification || '',
                                        rcaCalcification: result.rcaCalcification || ''
                                    });
                                let medicalPropsTranslated =
                                    medicalPropsTranslatedAndSelectBoundaries.medicalPropsTranslated;
                                setMedicalProps(medicalPropsTranslated);
                                setMedicalReportId(result.id);
                                setDisplayMedicalReportInPdf(result.displayInPdf);
                            })
                            .catch((err) => {
                                // Error 404 : no corresponding report, medicalProps is still initialMedicalPropsValue.
                                // Add technique default + indication default to display it in dialog.
                                let updatedInitialMedicalProps = initialMedicalPropsValue;
                                updatedInitialMedicalProps.technique = t('medicalReportForm.techniqueDefault');
                                updatedInitialMedicalProps.indication = t('medicalReportForm.indication.checkUp');

                                // medicalProps also have initialisation of classification rads and conclusion in useEffect(isLoading) below
                                setMedicalProps(updatedInitialMedicalProps);
                                setMedicalReportId(0);
                                setDisplayMedicalReportInPdf(false);
                            });
                    } else {
                        let updatedInitialMedicalProps = initialMedicalPropsValue;
                        updatedInitialMedicalProps.technique = t('medicalReportForm.techniqueDefault');
                        updatedInitialMedicalProps.indication = t('medicalReportForm.indication.checkUp');

                        // medicalProps also have initialisation of classification rads and conclusion in useEffect(isLoading) below
                        setMedicalProps(updatedInitialMedicalProps);
                        setMedicalReportId(0);
                        setDisplayMedicalReportInPdf(false);
                    }
                    setIsLoading(false);
                    setIsInitialLoadingDone(true);

                    let queryParameters = new URLSearchParams(location.search);
                    let currentSubPage = queryParameters.get('subpage') || '';
                    if (currentSubPage != '') {
                        setSubPageInUrl(currentSubPage);
                    }
                } else {
                    setIsLoading(false);
                    setIsFailure(true);
                }
            } catch (err) {
                setIsLoading(false);
                setIsFailure(true);
            }
        })();
    }, [currentId]);

    useEffect(() => {
        if (subPageInUrl == 'PDF') {
            setCurrentSubPageOpened(SubPagePDF);
        }
    }, [subPageInUrl]);

    // Load guid from local file db
    useEffect(() => {
        if (process.env.DeploymentMode && process.env.DeploymentMode == ModeConstant.HYBRID) {
            (async function iifeFunction() {
                // setIsLocalDbFetched(false);
                const getLocalDbService = new HybridService({ token: currentSessionInCookie.authResponse.token });
                const localDb = await getLocalDbService.GetLocalDb();
                localDb.map((dbEntry: DbEntry) => {
                    if (storedAnalysis.analysisGuid === dbEntry.guid) {
                        storedAnalysis.label = dbEntry.label;
                    }
                });
                // setIsLocalDbFetched(true);
            })();
        }
    }, [isLoading]);

    /** Array d'ordre */
    let order1b = [ClassificationResult.Rads0, ClassificationResult.Rads1];
    let order2b = [ClassificationResult.Rads0, ClassificationResult.Rads1, ClassificationResult.Rads12];

    let order3a = [
        ClassificationResult.Rads345,
        ClassificationResult.Rads3,
        ClassificationResult.Rads34,
        ClassificationResult.Rads4,
        ClassificationResult.Rads5
    ];
    let order3b = [
        ClassificationResult.Rads0,
        ClassificationResult.Rads12,
        ClassificationResult.Rads1,
        ClassificationResult.Rads2
    ];

    let order4b = [
        ClassificationResult.Rads34,
        ClassificationResult.Rads3,
        ClassificationResult.Rads4,
        ClassificationResult.Rads345
    ];

    let order5b = [
        ClassificationResult.Rads0,
        ClassificationResult.Rads1,
        ClassificationResult.Rads12,
        ClassificationResult.Rads2,
        ClassificationResult.Rads3,
        ClassificationResult.Rads34
    ];

    let order6b = [
        ClassificationResult.Rads0,
        ClassificationResult.Rads1,
        ClassificationResult.Rads12,
        ClassificationResult.Rads2,
        ClassificationResult.Rads3
    ];

    /** Ordre en fonction la sévérité de la sténose */
    const order = (aCoro: Coronary, bCoro: Coronary) => {
        let a = { ...initialResultRads };
        let b = { ...initialResultRads };

        if (aCoro == Coronary.LAD) {
            a = { ...ladResults };
        } else if (aCoro == Coronary.Cx) {
            a = { ...cxResults };
        } else if (aCoro == Coronary.RCA) {
            a = { ...rcaResults };
        }
        if (bCoro == Coronary.LAD) {
            b = { ...ladResults };
        } else if (bCoro == Coronary.Cx) {
            b = { ...cxResults };
        } else if (bCoro == Coronary.RCA) {
            b = { ...rcaResults };
        }
        if (
            a.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads12 &&
            order1b.includes(b.RadsAnalysisResponse.classificationResult)
        ) {
            return -1;
        } else if (
            a.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads2 &&
            order2b.includes(b.RadsAnalysisResponse.classificationResult)
        ) {
            return -1;
        } else if (
            order3a.includes(a.RadsAnalysisResponse.classificationResult) &&
            order3b.includes(b.RadsAnalysisResponse.classificationResult)
        ) {
            return -1;
        } else if (
            a.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads5 &&
            order4b.includes(b.RadsAnalysisResponse.classificationResult)
        ) {
            return -1;
        } else if (
            a.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads4 &&
            order5b.includes(b.RadsAnalysisResponse.classificationResult)
        ) {
            return -1;
        } else if (
            a.RadsAnalysisResponse.classificationResult === ClassificationResult.Rads3 &&
            order6b.includes(b.RadsAnalysisResponse.classificationResult)
        ) {
            return -1;
        } else if (
            (a.RadsAnalysisResponse.coronary === Coronary.Cx ||
                a.RadsAnalysisResponse.coronary === Coronary.RCA ||
                a.RadsAnalysisResponse.coronary === Coronary.LAD) &&
            b.RadsAnalysisResponse.coronary === Coronary.NotSpecified
        ) {
            return -1;
        } else if (
            // Necessary to work on firefox
            a.RadsAnalysisResponse.coronary === Coronary.NotSpecified &&
            (b.RadsAnalysisResponse.coronary === Coronary.Cx ||
                b.RadsAnalysisResponse.coronary === Coronary.RCA ||
                b.RadsAnalysisResponse.coronary === Coronary.LAD)
        ) {
            return 1;
        }
        return 0;
    };

    /** Choix de la current coronary + Détermination des couleurs */
    useEffect(() => {
        if (!isLoading) {
            (async function iieFunction() {
                /** Chargement de la current coronary */
                coronaryArray.sort(order);
                setCurrentCoronary(coronaryArray[0]);
                setLeftCoronary(coronaryArray[1]);
                setRightCoronary(coronaryArray[2]);
                setColorAnalysis(getColorByRads(storedAnalysis.classificationResult));
                setIsLoadingGraphs(false);
                setIsLoadingTotal(false);
            })();
        }
    }, [isLoading, globalGraph]);

    /** Update the list of stored analysis when there is one analysis opened */
    useEffect(() => {
        if (!hasBeenOpened) return;
        let updatedRows = [];
        for (const stored of storedAnalysisListContext) {
            let s = stored;
            if (currentId == s.id) {
                s.hasBeenOpened = true;
            }
            updatedRows.push(s);
        }
        setStoredAnalysisListContext(updatedRows);
    }, [hasBeenOpened]);

    /** Update the list of stored analysis when one analysis has been read by an user */
    useEffect(() => {
        if (hasBeenReadBy == '') return;
        let updatedRows = [];
        for (const stored of storedAnalysisListContext) {
            let s = stored;
            if (currentId == s.id) {
                s.reader = hasBeenReadBy;
            }
            updatedRows.push(s);
        }
        setStoredAnalysisListContext(updatedRows);
    }, [hasBeenReadBy]);

    /** Close the mini analysis table when the user click outside the container */
    function closeMiniAnalysisTableIfOutsideClick(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target) &&
                    !/\b(Srow?|MuiTypography-root?|MuiBackdrop-root?|MuiButtonBase-root?)\b/.test(
                        event.target.className
                    ) //Do not close if click on CAD-RADS scrolling menu selection : here are the list of classes on this menu
                ) {
                    setHideAnalysisTable(true);
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    closeMiniAnalysisTableIfOutsideClick(wrapperRef);

    /** Speed Dial */
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    return (
        <>
            <div style={{ visibility: 'hidden', position: 'fixed' }}>
                <canvas width="300px" height="200px" id="canvasForCharts" />
                <canvas width="300px" height="300px" id="canvasForBullet" />
            </div>
            {(isLoading || isLoadingGraphs || isLoadingTotal || medicalReportId == -1 || !isInitialLoadingDone) &&
            !isNoAnalysis ? (
                <>
                    <SGrid>
                        <SColumnMainMini>
                            <SContainerTable>
                                <SContainerHideButton
                                    ref={wrapperRef}
                                    onClick={() => setHideAnalysisTable(!hideAnalysisTable)}
                                >
                                    <SArrowDiv>
                                        <SArrowForward
                                            style={{
                                                transition: 'transform 0.6s',
                                                transform: `rotate(${hideAnalysisTable ? '0deg' : '180deg'})`
                                            }}
                                        />
                                    </SArrowDiv>
                                </SContainerHideButton>
                                {!hideAnalysisTable && (
                                    <AnalysisTableMini
                                        hideAnalysisTable={hideAnalysisTable}
                                        isLoading={isLoading}
                                        currentId={currentId}
                                        setCurrentId={setCurrentId}
                                        pdfBlob={pdfBlob}
                                        currentSessionInCookie={currentSessionInCookie}
                                    />
                                )}
                            </SContainerTable>
                        </SColumnMainMini>
                        <SLoaderDiv>
                            {themeContext.isLightMode ? (
                                <Loader jsonToUse={loadingHeart} width="85vw" speed={1.3} />
                            ) : (
                                <Loader jsonToUse={loadingHeartDark} width="85vw" speed={1.3} />
                            )}
                        </SLoaderDiv>
                    </SGrid>
                </>
            ) : isNoAnalysis && !isFailure ? (
                <>
                    <SGrid>
                        <SContainer>
                            <SLoaderDiv>
                                <Loader jsonToUse={noDataRows} width="30vw" />
                            </SLoaderDiv>
                        </SContainer>
                    </SGrid>
                </>
            ) : !isFailure ? (
                <>
                    <ButtonGroup
                        variant="text"
                        aria-label="text button group"
                        sx={{
                            position: 'fixed',
                            zIndex: 100000,
                            top: '0 !important',
                            left: '40%',
                            height: '46px',
                            alignItems: 'center',
                            '& > *': {
                                m: 1
                            }
                        }}
                    >
                        <SButtonCurrentPage
                            iscurrent={currentSubPageOpened == SubPageResults ? 1 : 0}
                            onClick={() => setCurrentSubPageOpened(SubPageResults)}
                        >
                            {t('analysis.foldersTitle.results')}
                        </SButtonCurrentPage>
                        <SButtonCurrentPage
                            iscurrent={currentSubPageOpened == SubPageImages ? 1 : 0}
                            onClick={() => setCurrentSubPageOpened(SubPageImages)}
                        >
                            {t('analysis.foldersTitle.images')}
                        </SButtonCurrentPage>
                        <SButtonCurrentPage
                            iscurrent={currentSubPageOpened == SubPagePDF ? 1 : 0}
                            onClick={() => setCurrentSubPageOpened(SubPagePDF)}
                        >
                            {t('analysis.foldersTitle.pdf')}
                        </SButtonCurrentPage>
                    </ButtonGroup>
                    <div style={{ display: currentSubPageOpened == SubPageResults ? 'contents' : 'none' }}>
                        <SGrid>
                            <SColumnMainMini ref={wrapperRef}>
                                <SContainerTable>
                                    <SContainerHideButton onClick={() => setHideAnalysisTable(!hideAnalysisTable)}>
                                        <SArrowDiv>
                                            <SArrowForward
                                                style={{
                                                    transition: 'transform 0.6s',
                                                    transform: `rotate(${hideAnalysisTable ? '0deg' : '180deg'})`
                                                }}
                                            />
                                        </SArrowDiv>
                                    </SContainerHideButton>
                                    <AnalysisTableMini
                                        hideAnalysisTable={hideAnalysisTable}
                                        isLoading={isLoading}
                                        currentId={currentId}
                                        setCurrentId={setCurrentId}
                                        pdfBlob={pdfBlob}
                                        currentSessionInCookie={currentSessionInCookie}
                                    />
                                </SContainerTable>
                            </SColumnMainMini>
                            <SColumnLeft>
                                <SContainer>
                                    <AnalysisReportCompDetails
                                        medicalReportId={medicalReportId}
                                        medicalProps={medicalProps}
                                        storedAnalysis={storedAnalysis}
                                        ladResults={ladResults}
                                        cxResults={cxResults}
                                        rcaResults={rcaResults}
                                        ffrAvailable={ffrAvailable}
                                        currentSessionInCookie={currentSessionInCookie}
                                        setCurrentSubPageOpened={setCurrentSubPageOpened}
                                        isMedicalReportValidationDisplayed={isMedicalReportValidationDisplayed}
                                        setIsMedicalReportValidationDisplayed={setIsMedicalReportValidationDisplayed}
                                        displayMedicalReportInPdf={displayMedicalReportInPdf}
                                        patientNameVar={patientNameVar}
                                    />
                                </SContainer>
                            </SColumnLeft>
                            <SColumnMiddle display="flex">
                                <SRowTitle>
                                    {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <STypography>{t('stenosisSeverityValidated')}</STypography>
                                            <SBlueIcon>
                                                <SHowToRegIcon />
                                            </SBlueIcon>
                                        </div>
                                    ) : (
                                        <STypography>{t('stenosisSeverity')}</STypography>
                                    )}
                                </SRowTitle>
                                <SRangeImage>
                                    {themeContext.isLightMode ? (
                                        <>
                                            <SImg src={StenosisSeverity} alt="Stenosis Severity" />
                                            {ffrAvailable && <SImg src={FFRRange} alt="FFR Range" />}
                                        </>
                                    ) : (
                                        <>
                                            <SImg src={StenosisSeverityDark} alt="Stenosis Severity" />
                                            {ffrAvailable && <SImg src={FFRRangeDark} alt="FFR Range" />}
                                        </>
                                    )}
                                </SRangeImage>
                                <SContainerHeart3D>
                                    <Heart3D
                                        ladResults={ladResults}
                                        cxResults={cxResults}
                                        rcaResults={rcaResults}
                                        setCurrentCoronary={setCurrentCoronary}
                                        medicalProps={medicalProps}
                                        isMedicalReportValidationDisplayed={
                                            isMedicalReportValidationDisplayed && medicalReportId > 0
                                        }
                                        displayMuscle={showFFR}
                                        ffrAvailable={ffrAvailable}
                                    />
                                </SContainerHeart3D>
                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={5000}
                                    onClose={() => setSnackbarOpen(!snackbarOpen)}
                                >
                                    <div>
                                        <Alert
                                            onClose={() => setSnackbarOpen(!snackbarOpen)}
                                            severity="success"
                                            sx={{ width: '100%' }}
                                        >
                                            {t('options.share.pdfSent')}
                                        </Alert>
                                    </div>
                                </Snackbar>
                            </SColumnMiddle>
                            <SColumnRight>
                                <AnalysisReportCompImages
                                    storedAnalysis={storedAnalysis}
                                    setCurrentSubPageOpened={setCurrentSubPageOpened}
                                    ladResults={ladResults}
                                    cxResults={cxResults}
                                    rcaResults={rcaResults}
                                    ladResultsExplicability={ladResultsExplicability}
                                    cxResultsExplicability={cxResultsExplicability}
                                    rcaResultsExplicability={rcaResultsExplicability}
                                    setLadExplicability={setLadExplicability}
                                    setCxExplicability={setCxExplicability}
                                    setRcaExplicability={setRcaExplicability}
                                    currentCoronary={currentCoronary}
                                    setCurrentCoronary={setCurrentCoronary}
                                    leftCoronary={leftCoronary}
                                    setLeftCoronary={setLeftCoronary}
                                    rightCoronary={rightCoronary}
                                    setRightCoronary={setRightCoronary}
                                    modalOpen={modalOpen}
                                    updateModal={updateModal}
                                    coronaryImagesListOpen={coronaryImagesListOpen}
                                    updateCoronaryImagesList={updateCoronaryImagesList}
                                    medicalReportId={medicalReportId}
                                    isMedicalReportValidationDisplayed={isMedicalReportValidationDisplayed}
                                    currentSessionInCookie={currentSessionInCookie}
                                />
                            </SColumnRight>
                        </SGrid>
                    </div>
                    <>
                        <div style={{ display: currentSubPageOpened == SubPageImages ? 'contents' : 'none' }}>
                            <AnalysisReportImagesList
                                open={open}
                                setOpen={setOpen}
                                storedAnalysis={storedAnalysis}
                                ladResults={ladResults}
                                cxResults={cxResults}
                                rcaResults={rcaResults}
                                ladResultsExplicability={ladResultsExplicability}
                                cxResultsExplicability={cxResultsExplicability}
                                rcaResultsExplicability={rcaResultsExplicability}
                                currentCoronary={currentCoronary}
                                setCurrentCoronary={setCurrentCoronary}
                                leftCoronary={leftCoronary}
                                setLeftCoronary={setLeftCoronary}
                                rightCoronary={rightCoronary}
                                setRightCoronary={setRightCoronary}
                                modalOpen={modalOpen}
                                updateModal={updateModal}
                                coronaryImagesListOpen={coronaryImagesListOpen}
                                updateCoronaryImagesList={updateCoronaryImagesList}
                                ffrAvailable={ffrAvailable}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        </div>
                    </>
                    <>
                        <div style={{ display: currentSubPageOpened == SubPagePDF ? 'contents' : 'none' }}>
                            <DisplayPdf
                                currentSessionInCookie={currentSessionInCookie}
                                storedAnalysis={storedAnalysis}
                                LAD={ladResults}
                                CX={cxResults}
                                RCA={rcaResults}
                                files3D={files3D}
                                currentId={currentId}
                                medicalReportId={medicalReportId}
                                setMedicalReportId={setMedicalReportId}
                                displayMedicalReportInPdf={displayMedicalReportInPdf}
                                setDisplayMedicalReportInPdf={setDisplayMedicalReportInPdf}
                                setPatientNameVar={setPatientNameVar}
                                medicalProps={medicalProps}
                                setMedicalProps={setMedicalProps}
                            />
                        </div>
                    </>
                </>
            ) : (
                <ErrorMessage message={'Analysis Error'} />
            )}
        </>
    );
};

export default Analysis;

const FilterBlobsByCoronary = async (
    coronary: Coronary,
    downloadFiles: File[],
    analysisComesFromDicomServer: boolean
) => {
    let files: File[] = [];

    if (analysisComesFromDicomServer && coronary === Coronary.NotSpecified) {
        for (let i = 0; i < downloadFiles.length; i++) {
            const fileName = downloadFiles[i].fileName.split('/');
            const imageNumber = fileName[2].split('.')[0];
            files.push({
                base64: downloadFiles[i]?.base64?.replace(
                    'data:application/octet-stream;base64',
                    'data:image/jpeg;base64'
                ),
                fileName: imageNumber,
                data: ''
            });
        }
    } else {
        for (let i = 0; i < downloadFiles.length; i++) {
            const fileName = downloadFiles[i].fileName.split('/');
            const coronaryInFileName = fileName[0];
            const imageNumber = fileName[1].split('.')[0];
            if (coronaryInFileName === coronary) {
                files.push({
                    base64: downloadFiles[i]?.base64?.replace(
                        'data:application/octet-stream;base64',
                        'data:image/jpeg;base64'
                    ),
                    fileName: imageNumber,
                    data: ''
                });
            }
        }
    }

    return files.sort((a, b) => (a.fileName as unknown as number) - (b.fileName as unknown as number));
};

const getIndexesFromStoredAnalysis = (imageOrderInStorage: ImageOrderInStorage) => {
    switch (imageOrderInStorage) {
        case ImageOrderInStorage.LadCxRca:
            return { ladI: 0, cxI: 1, rcaI: 2 };
        case ImageOrderInStorage.LadRcaCx:
            return { ladI: 0, rcaI: 1, cxI: 2 };
        case ImageOrderInStorage.CxLadRca:
            return { cxI: 0, ladI: 1, rcaI: 2 };
        case ImageOrderInStorage.CxRcaLad:
            return { cxI: 0, rcaI: 1, ladI: 2 };
        case ImageOrderInStorage.RcaCxLad:
            return { rcaI: 0, cxI: 1, ladI: 2 };
        case ImageOrderInStorage.RcaLadCx:
            return { rcaI: 0, ladI: 1, cxI: 2 };
        default:
            return { ladI: 0, cxI: 1, rcaI: 2 };
    }
};

// ---- Styled Components ----
const SGrid = styled.div`
    padding-top: 15px;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-around;
`;

const SColumnMainMini = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.5 ease-in-out; //animation lors du changement de width
    margin-right: 3vw;
    position: absolute;
    left: 0.2vw;
    top: 46vh;
`;

const SColumnLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.5 ease-in-out; //animation lors du changement de width
    margin-left: 10px;
`;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: width 0.5s ease-in-out; //animation lors du changement de width
`;

const SContainerTable = styled.div`
    display: flex;
`;

const SContainerHideButton = styled.div`
    display: flex;
    position: absolute;
    top: 46%;
    z-index: 10;
`;

const SContainerHeart3D = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: width 0.5s ease-in-out; //animation lors du changement de width
    z-index: 1;
    @media (min-width: 800px) {
        width: 25vw;
    }
    @media (max-width: 950px) {
        width: 0vw;
    }
`;

const SArrowDiv = styled.div`
    display: flex;
    padding: 4px;
    border-radius: 50%;
    border: ${(props) => props.theme.miniTable.arrow.border};
    background-color: ${(props) => props.theme.miniTable.arrow.background};
    flex-direction: column;
    text-align: center;
    margin: auto;
    align-items: center;
    user-select: none;
    &:hover {
        cursor: pointer;
        background: ${(props) => props.theme.colors.darkblue};
    }
`;

const SArrowBack = styled(ArrowBackIosRoundedIcon)`
    font-size: 19px;
    color: ${(props) => props.theme.colors.white};
    &:hover {
        color: ${(props) => props.theme.colors.lightgrey};
    }
`;

const SArrowForward = styled(ArrowForwardIosRoundedIcon)`
    font-size: 19px;
    color: ${(props) => props.theme.colors.white};

    &:hover {
        color: ${(props) => props.theme.colors.lightgrey};
    }
`;

const SColumnMiddle = styled.div<SColumnProps>`
    display: ${(props: SColumnProps) => props.display};
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-height: 100vh;
    margin-right: 3vw;
    position: relative;
    @media (max-width: 950px) {
        display: none;
    }
`;

const SLoaderDiv = styled.div`
    display: flex;
    width: 75vw;
    justify-content: space-around;
    align-items: center;
`;

const SRowTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    padding-top: 1px;
`;

const SRangeImage = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    z-index: 0;
    top: 5%;
    left: 0;
    right: 0;
`;

const SImg = styled.img`
    user-select: none;
    width: 5vh;
    margin: ${(props) => props.theme.getSpacing(1, 0, 1, 0)};
`;

const SColumnRight = styled.div`
    display: flex;
`;

const SButtonCurrentPage = styled(Button)<IsCurrentButtonProps>`
    color: ${(propsCurrent: IsCurrentButtonProps) =>
        propsCurrent.iscurrent == 1
            ? (props) => props.theme.colors.blue + ' !important'
            : (props) => props.theme.navbar.menuText + ' !important'};
    border-color: ${(props) => props.theme.navbar.menuText} !important;
    padding: 15px;
    font-size: 15px !important;
    margin: 0 !important;
`;

const STypography = styled(Typography)`
    color: ${(props) => props.theme.analysisDetails.color} !important;
    font-size: 17px !important;
    font-family: 'Inter', sans-serif !important;
`;

const SHowToRegIcon = styled(HowToRegIcon)`
    font-size: 1rem !important;
`;

const SBlueIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: inherit;
    width: 18px;
    height: 18px;
    border-radius: 30px;
    background: ${(props) => props.theme.colors.blue};
    color: white;
    margin-left: 5px;
`;

