import { cleanLocalStorage, logoutFunc, Session } from '@/utils/contexts/SessionContext';
import { AuthorizedApiBase, IConfig } from '@/../generated/api.client';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { File } from '@/components/molecules';

type Props = {
    /**Session context containing token and container name */
    sessionContext: Session;
    analysisId: number;
};

type PropsToken = {
    analysisId: number;
    analysisToken: string;
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export class DownloadImagesFromBack extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }
    // Download images or images for dicom servcer, depending on the isFromPacs value of analysis
    async downloadAllBlobs(props: Props) {
        const { sessionContext, analysisId } = props;

        const url_ = this.baseUrl + `/AzureFileStorage/download-images?analysisId=` + analysisId;

        let options_ = <AxiosRequestConfig>{
            method: 'GET',
            url: url_,
            headers: {
                Accept: 'application/json'
            }
        };

        var response = await this.transformOptions(options_)
            .then((transformedOptions_) => {
                return this.instance.request(transformedOptions_);
            })
            .catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                        console.log('Invalid Bearer Token');
                        logoutFunc();
                        cleanLocalStorage();
                        location.reload();
                    }
                    throw 500;
                } else {
                    throw _error;
                }
            });

        if (response.data == '') {
            throw 500; // Empty images => error
        }

        const allFiles: File[] = [];
        for (let item of Object.keys(response.data)) {
            allFiles.push({
                base64: ('data:application/octet-stream;base64,' + response.data[item]) as string,
                fileName: item,
                data: null
            });
        }

        return allFiles;
    }

    protected processSend(response: AxiosResponse) {
        const status = response.status;
        if (status === 200) {
            return status;
        } else {
            return 500;
        }
    }
}

export class DownloadImagesFromBackWithToken extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }
    // Download images or images for dicom servcer, depending on the isFromPacs value of analysis
    async downloadAllBlobsWithAnalysisToken(props: PropsToken) {
        const { analysisId, analysisToken } = props;

        const url_ =
            this.baseUrl +
            `/AzureFileStorage/download-images-with-analysis-token?analysisId=` +
            analysisId +
            `&token=` +
            encodeURIComponent('' + analysisToken);

        let options_ = <AxiosRequestConfig>{
            method: 'GET',
            url: url_,
            headers: {
                Accept: 'application/json'
            }
        };

        var response = await this.transformOptions(options_)
            .then((transformedOptions_) => {
                return this.instance.request(transformedOptions_);
            })
            .catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                        console.log('Invalid Bearer Token');
                        logoutFunc();
                        cleanLocalStorage();
                        location.reload();
                    }
                    throw 500;
                } else {
                    throw _error;
                }
            });

        if (response.data == '') {
            throw 500; // Empty images => error
        }

        const allFiles: File[] = [];
        for (let item of Object.keys(response.data)) {
            allFiles.push({
                base64: ('data:application/octet-stream;base64,' + response.data[item]) as string,
                fileName: item,
                data: null
            });
        }

        return allFiles;
    }

    protected processSend(response: AxiosResponse) {
        const status = response.status;
        if (status === 200) {
            return status;
        } else {
            return 500;
        }
    }
}

export class DownloadExplicabilityFromBack extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }
    // Download images or images for dicom servcer, depending on the isFromPacs value of analysis
    async downloadAllBlobs(props: Props) {
        const { sessionContext, analysisId } = props;

        const url_ = this.baseUrl + `/AzureFileStorage/download-explicability?analysisId=` + analysisId;

        let options_ = <AxiosRequestConfig>{
            method: 'GET',
            url: url_,
            headers: {
                Accept: 'application/json'
            }
        };

        var response = await this.transformOptions(options_)
            .then((transformedOptions_) => {
                return this.instance.request(transformedOptions_);
            })
            .catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                        console.log('Invalid Bearer Token');
                        logoutFunc();
                        cleanLocalStorage();
                        location.reload();
                    }
                    throw 500;
                } else {
                    throw _error;
                }
            });

        if (response.data == '') {
            throw 500; // Empty images => error
        }

        const allFiles: File[] = [];
        for (let item of Object.keys(response.data)) {
            allFiles.push({
                base64: ('data:application/octet-stream;base64,' + response.data[item]) as string,
                fileName: item,
                data: null
            });
        }

        return allFiles;
    }

    protected processSend(response: AxiosResponse) {
        const status = response.status;
        if (status === 200) {
            return status;
        } else {
            return 500;
        }
    }
}

