import { createContext } from 'react';
import React, { useState, FC } from 'react';
import { useContextFactory } from '@/utils/contexts/ContextFactory';

export interface Theme {
    isLightMode: boolean;
}

export const nullTheme = {
    isLightMode: true
};

export const ThemeContext = createContext<[Theme, (theme: Theme) => void]>([nullTheme, () => {}]);

export const useThemeContext = useContextFactory('ThemeContext', ThemeContext);

export const ThemeContextProvider: FC = (props) => {
    const initTheme = () => {
        var isLightMode = localStorage.getItem('isLightMode');
        if (isLightMode !== null) {
            const newTheme: Theme = { isLightMode: isLightMode === 'true' };
            return newTheme;
        }
        return nullTheme;
    };

    const [themeInStorage, setThemeInStorage] = useState<Theme>(initTheme);

    const updateTheme = (t: Theme) => {
        localStorage.setItem('isLightMode', t.isLightMode.toString());
        setThemeInStorage(t);
    };

    const defaultThemeContext: [Theme, typeof updateTheme] = [themeInStorage, updateTheme];
    return <ThemeContext.Provider value={defaultThemeContext}>{props.children}</ThemeContext.Provider>;
};

