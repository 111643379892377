import { StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';

Font.register({
    family: 'Roboto',
    fonts: [
        { src: 'fonts/Roboto-Regular.ttf' },
        { src: 'fonts/Roboto-Bold.ttf', fontWeight: 700 },
        { src: 'fonts/Roboto-Light.ttf', fontWeight: 300 }
    ],
    format: 'truetype'
});

const medicalStyles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        backgroundColor: '#ffffff',
        paddingBottom: 80,
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: 'space-between'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0CB4FF',
        color: '#FFFFFF',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        marginLeft: 80,
        marginRight: 80,
        paddingTop: 20,
        paddingBottom: 20
    },
    doctorTitle: {
        fontSize: 20,
        marginBottom: 15,
        marginTop: 20,
        marginLeft: 10
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    maintitle: {
        fontSize: 30,
        fontWeight: 'bold'
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    text: {
        fontSize: 12,
        fontWeight: 'light'
    },
    blackbar: {
        fontSize: 1,
        width: 100,
        height: 3,
        justifyContent: 'flex-start',
        textAlign: 'left',
        backgroundColor: '#000000',
        marginLeft: 25,
        marginBottom: 5
    },
    centerContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    slot: {
        display: 'flex',
        marginBottom: 10
    },
    slotResponse: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 25
    },
    block: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        marginBottom: 10,
        border: '1px solid #000000',
        borderRadius: 10,
        padding: 10
    }
});

export default medicalStyles;

