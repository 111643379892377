import { ClassificationResult, Coronary } from '@api';
import theme from '@/utils/styles/Theme';
import RcaGreen from '@/img/2DHeart/coronaryLeft/green.png';
import RcaYellow from '@/img/2DHeart/coronaryLeft/yellow.png';
import RcaOrange from '@/img/2DHeart/coronaryLeft/orange.png';
import RcaRed from '@/img/2DHeart/coronaryLeft/red.png';
import RcaDarkRed from '@/img/2DHeart/coronaryLeft/darkred.png';
import RcaStrongDarkRed from '@/img/2DHeart/coronaryLeft/strongdarkred.png';
import LadGreen from '@/img/2DHeart/coronaryMiddle/green.png';
import LadYellow from '@/img/2DHeart/coronaryMiddle/yellow.png';
import LadOrange from '@/img/2DHeart/coronaryMiddle/orange.png';
import LadRed from '@/img/2DHeart/coronaryMiddle/red.png';
import LadDarkRed from '@/img/2DHeart/coronaryMiddle/darkred.png';
import LadStrongDarkRed from '@/img/2DHeart/coronaryMiddle/strongdarkred.png';
import CxGreen from '@/img/2DHeart/coronaryRight/green.png';
import CxYellow from '@/img/2DHeart/coronaryRight/yellow.png';
import CxOrange from '@/img/2DHeart/coronaryRight/orange.png';
import CxRed from '@/img/2DHeart/coronaryRight/red.png';
import CxDarkRed from '@/img/2DHeart/coronaryRight/darkred.png';
import CxStrongDarkRed from '@/img/2DHeart/coronaryRight/strongdarkred.png';

let colorByRadsAndStenosis = [
    {
        rads: ClassificationResult.Rads0,
        color: theme.colors.green,
        colorStenosis: theme.colors.green,
        color3D: theme.colors.green,
        hoverColor: theme.colors.greenHover,
        backgroundColor: theme.colors.green + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.green,
        stenosis: '0%',
        pathRca: RcaGreen,
        pathLad: LadGreen,
        pathCx: CxGreen
    },
    {
        rads: ClassificationResult.Rads1,
        color: theme.colors.yellow,
        colorStenosis: theme.colors.yellowStenosis,
        color3D: theme.colors.yellow,
        hoverColor: theme.colors.yellowHover,
        backgroundColor: theme.colors.yellowBackgroundStenosis,
        colorRads: 'grey',
        backgroundColorRads: theme.colors.yellowBackgroundRads,
        stenosis: '1-24%',
        pathRca: RcaYellow,
        pathLad: LadYellow,
        pathCx: CxYellow
    },
    {
        rads: ClassificationResult.Rads12,
        color: theme.colors.orange,
        colorStenosis: theme.colors.orange,
        color3D: theme.colors.orange,
        hoverColor: theme.colors.orangeHover,
        backgroundColor: theme.colors.orange + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.orange,
        stenosis: '25%',
        pathRca: RcaOrange,
        pathLad: LadOrange,
        pathCx: CxOrange
    },
    {
        rads: ClassificationResult.Rads2,
        color: theme.colors.orange,
        colorStenosis: theme.colors.orange,
        color3D: theme.colors.orange,
        hoverColor: theme.colors.orangeHover,
        backgroundColor: theme.colors.orange + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.orange,
        stenosis: '25-49%',
        pathRca: RcaOrange,
        pathLad: LadOrange,
        pathCx: CxOrange
    },
    {
        rads: ClassificationResult.Rads23,
        color: theme.colors.red,
        colorStenosis: theme.colors.red,
        color3D: theme.colors.red,
        hoverColor: theme.colors.redHover,
        backgroundColor: theme.colors.red + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.red,
        stenosis: '50%',
        pathRca: RcaRed,
        pathLad: LadRed,
        pathCx: CxRed
    },
    {
        rads: ClassificationResult.Rads3,
        color: theme.colors.red,
        colorStenosis: theme.colors.red,
        color3D: theme.colors.red,
        hoverColor: theme.colors.darkredHover,
        backgroundColor: theme.colors.red + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.red,
        stenosis: '50-69%',
        pathRca: RcaRed,
        pathLad: LadRed,
        pathCx: CxRed
    },
    {
        rads: ClassificationResult.Rads34,
        color: theme.colors.darkred,
        colorStenosis: theme.colors.darkred,
        color3D: theme.colors.darkred,
        hoverColor: theme.colors.darkredHover,
        backgroundColor: theme.colors.darkred + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.darkred,
        stenosis: '70%',
        pathRca: RcaDarkRed,
        pathLad: LadDarkRed,
        pathCx: CxDarkRed
    },
    {
        rads: ClassificationResult.Rads4,
        color: theme.colors.darkred,
        colorStenosis: theme.colors.darkred,
        color3D: theme.colors.darkred,
        hoverColor: theme.colors.darkredHover,
        backgroundColor: theme.colors.darkred + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.darkred,
        stenosis: '70-99%',
        pathRca: RcaDarkRed,
        pathLad: LadDarkRed,
        pathCx: CxDarkRed
    },
    {
        rads: ClassificationResult.Rads345,
        color: theme.colors.darkred,
        colorStenosis: theme.colors.darkred,
        color3D: theme.colors.darkred,
        hoverColor: theme.colors.darkredHover,
        backgroundColor: theme.colors.darkred + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.darkred,
        stenosis: '>50-99%',
        pathRca: RcaDarkRed,
        pathLad: LadDarkRed,
        pathCx: CxDarkRed
    },
    {
        rads: ClassificationResult.Rads5,
        color: theme.colors.strongdarkred,
        colorStenosis: theme.colors.strongdarkred,
        color3D: theme.colors.strongdarkred,
        hoverColor: theme.colors.strongdarkredHover,
        backgroundColor: theme.colors.strongdarkred + '26',
        colorRads: 'white',
        backgroundColorRads: theme.colors.strongdarkred,
        stenosis: '100%',
        pathRca: RcaStrongDarkRed,
        pathLad: LadStrongDarkRed, // 'images/2DHeart/coronaryMiddle/strongdarkred.png',
        pathCx: CxStrongDarkRed
    }
];

export const stenosisClassificationList = [
    '0%',
    '1-24%',
    '25%',
    '25-49%',
    '50%',
    '50-69%',
    '70%',
    '70-99%',
    '100%',
    '>50-99%'
];

// Take CAD-RADS enum as parameter
export const getColorByRads = (rads: String, defaultColor?: String) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].rads == rads) {
            selectedColor = colorByRadsAndStenosis[i].color;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        }
    }
    return selectedColor;
};
export const getColorByRadsBis = (rads: String, defaultColor?: String) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].rads == rads) {
            selectedColor = colorByRadsAndStenosis[i].colorStenosis;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        }
    }
    return selectedColor;
};

export const getColorByRadsBlock = (rads: String, defaultColor?: String) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].rads == rads) {
            selectedColor = colorByRadsAndStenosis[i].colorRads;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        }
    }
    return selectedColor;
};

export const getBackgroundColorByRadsBlock = (rads: String, defaultColor?: String) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].rads == rads) {
            selectedColor = colorByRadsAndStenosis[i].backgroundColorRads;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        }
    }
    return selectedColor;
};

export const getBackgroundColorByStenosisBlock = (stenosis: String, defaultColor?: String) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].stenosis == stenosis) {
            selectedColor = colorByRadsAndStenosis[i].backgroundColorRads;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        }
    }
    return selectedColor;
};

export const getPathByRads = (rads: String, coronary: Coronary) => {
    let selectedPath = '';
    if (coronary == Coronary.RCA) {
        for (var i in colorByRadsAndStenosis) {
            if (colorByRadsAndStenosis[i].rads == rads) {
                selectedPath = colorByRadsAndStenosis[i].pathRca;
                break;
            }
        }
    } else if (coronary == Coronary.LAD) {
        for (var i in colorByRadsAndStenosis) {
            if (colorByRadsAndStenosis[i].rads == rads) {
                selectedPath = colorByRadsAndStenosis[i].pathLad;
                break;
            }
        }
    } else if (coronary == Coronary.Cx) {
        for (var i in colorByRadsAndStenosis) {
            if (colorByRadsAndStenosis[i].rads == rads) {
                selectedPath = colorByRadsAndStenosis[i].pathCx;
                break;
            }
        }
    }
    return selectedPath;
};
export const getPathByStenosis = (stenosis: String, coronary: Coronary) => {
    let selectedPath = '';
    if (coronary == Coronary.RCA) {
        for (var i in colorByRadsAndStenosis) {
            if (colorByRadsAndStenosis[i].stenosis == stenosis) {
                selectedPath = colorByRadsAndStenosis[i].pathRca;
                break;
            }
        }
        if (selectedPath == '') {
            selectedPath = 'images/2DHeart/coronaryLeft/grey.png';
        }
    } else if (coronary == Coronary.LAD) {
        for (var i in colorByRadsAndStenosis) {
            if (colorByRadsAndStenosis[i].stenosis == stenosis) {
                selectedPath = colorByRadsAndStenosis[i].pathLad;
                break;
            }
        }
        if (selectedPath == '') {
            selectedPath = 'images/2DHeart/coronaryMiddle/grey.png';
        }
    } else if (coronary == Coronary.Cx) {
        for (var i in colorByRadsAndStenosis) {
            if (colorByRadsAndStenosis[i].stenosis == stenosis) {
                selectedPath = colorByRadsAndStenosis[i].pathCx;
                break;
            }
        }
        if (selectedPath == '') {
            selectedPath = 'images/2DHeart/coronaryRight/grey.png';
        }
    }
    return selectedPath;
};

export const getColorByStenosis = (stenosis: String, defaultColor?: string) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].stenosis == stenosis) {
            selectedColor = colorByRadsAndStenosis[i].colorStenosis;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        } else if (defaultColor != undefined) {
            selectedColor = defaultColor;
        }
    }
    return selectedColor;
};

export const getColorByStenosisWithoutBackground = (stenosis: String, defaultColor?: string) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].stenosis == stenosis) {
            selectedColor = colorByRadsAndStenosis[i].color;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        } else if (defaultColor != undefined) {
            selectedColor = defaultColor;
        }
    }
    return selectedColor;
};

export const getColorByStenosis3D = (stenosis: String, defaultColor?: string) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].stenosis == stenosis) {
            selectedColor = colorByRadsAndStenosis[i].color3D;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        } else if (defaultColor != undefined) {
            selectedColor = defaultColor;
        }
    }
    return selectedColor;
};

export const getHoverColorByStenosis = (stenosis: String, defaultColor?: string) => {
    if (defaultColor == '') {
        defaultColor = 'black';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].stenosis == stenosis) {
            selectedColor = colorByRadsAndStenosis[i].hoverColor;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'black') {
            selectedColor = theme.colors.black;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        } else if (defaultColor != undefined) {
            selectedColor = defaultColor;
        }
    }
    return selectedColor;
};

export const getBackgroundColorByStenosis = (stenosis: String, defaultColor?: String) => {
    if (defaultColor == '') {
        defaultColor = 'white';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].stenosis == stenosis) {
            selectedColor = colorByRadsAndStenosis[i].backgroundColor;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'white') {
            selectedColor = theme.colors.white;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        }
    }
    return selectedColor;
};

export const getBackgroundColorByRads = (rads: String, defaultColor?: String) => {
    if (defaultColor == '') {
        defaultColor = 'white';
    }
    let selectedColor = '';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].rads == rads) {
            selectedColor = colorByRadsAndStenosis[i].backgroundColor;
            break;
        }
    }
    if (selectedColor == '') {
        if (defaultColor == 'white') {
            selectedColor = theme.colors.white;
        } else if (defaultColor == 'grey') {
            selectedColor = theme.colors.grey;
        }
    }
    return selectedColor;
};

export const getStenosisByRads = (rads: String) => {
    let stenosis = 'Null';
    for (var i in colorByRadsAndStenosis) {
        if (colorByRadsAndStenosis[i].rads == rads) {
            stenosis = colorByRadsAndStenosis[i].stenosis;
            break;
        }
    }
    return stenosis;
};

