import { File as MoleculeFile } from '@/components/molecules';
import { AnalysisClient, Coronary, FileParameter, RadsAnalysisResponse } from '@api';
import { Session } from '@/utils/contexts/SessionContext';

type CommonProps = {
    /**Session context containing token and container name */
    sessionContext: Session;
    /**StoredAnalysisId used in the RadsAnalysis to store the analysis */
    storedAnalysisId: number;
    /**StoredAnalysisGuid which will be used in to create the folder in blob storage */
    analysisGuid: string;
};

type Props = {
    /**Lad files (if is27 is false) */
    ladFiles: MoleculeFile[];
    /**cx files (if is27 is false) */
    cxFiles: MoleculeFile[];
    /**rca files (if is27 is false) */
    rcaFiles: MoleculeFile[];
} & CommonProps;

type PropsFor27 = {
    /**27 files upload */
    allFiles: MoleculeFile[];
} & CommonProps;

type PropsForZip = {
    sessionContext: Session;
    zipFile: Blob;
};

/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
class SingletonAnalysis {
    private static instance: AnalysisClient;
    private static token: string;
    private static url: string | undefined;

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor() {}

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(token: string, url: string | undefined): AnalysisClient {
        if (!SingletonAnalysis.instance) {
            SingletonAnalysis.token = token;
            SingletonAnalysis.url = url;
            SingletonAnalysis.instance = new AnalysisClient({ token }, url);
        } else if (SingletonAnalysis.token !== token || SingletonAnalysis.url !== url) {
            SingletonAnalysis.instance = new AnalysisClient({ token }, url);
        }
        return SingletonAnalysis.instance;
    }
}

export const RadsAnalysisFor27 = async (props: PropsFor27) => {
    const { allFiles, sessionContext, storedAnalysisId, analysisGuid } = props;

    const client = SingletonAnalysis.getInstance(sessionContext.authResponse.token, process.env.BackendServiceURL);
    if (allFiles.length !== 0) {
        return await client.radsAnalysis(
            storedAnalysisId,
            analysisGuid,
            Coronary.NotSpecified,
            onlyBlob(allFiles),
            true
        );
    }
    return Promise.reject(new Error('allFiles seems to be null?'));
};

export const RadsAnalysis = async (props: Props) => {
    const { ladFiles, cxFiles, rcaFiles, sessionContext, storedAnalysisId, analysisGuid } = props;

    const client = SingletonAnalysis.getInstance(sessionContext.authResponse.token, process.env.BackendServiceURL);

    let arrayPromises: Promise<RadsAnalysisResponse[]>[] = [];
    if (ladFiles.length !== 0) {
        arrayPromises.push(
            client.radsAnalysis(storedAnalysisId, analysisGuid, Coronary.LAD, onlyBlob(ladFiles), false)
        );
    }
    if (cxFiles.length !== 0) {
        arrayPromises.push(client.radsAnalysis(storedAnalysisId, analysisGuid, Coronary.Cx, onlyBlob(cxFiles), false));
    }
    if (rcaFiles.length !== 0) {
        arrayPromises.push(
            client.radsAnalysis(storedAnalysisId, analysisGuid, Coronary.RCA, onlyBlob(rcaFiles), false)
        );
    }
    const mergePromise = await Promise.all(arrayPromises);
    return mergePromise.flat();
};

const onlyBlob = (files: MoleculeFile[]) => {
    const blobs: FileParameter[] = [];
    files.forEach((file) => blobs.push({ fileName: file.fileName, data: file.data }));
    return blobs;
};

