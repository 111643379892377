import React from 'react';
import { Button as DefaultMaterialButton } from '@mui/material/';
import styled from 'styled-components';

export const ButtonBlueDarkBack = styled(({ ...otherProps }) => <DefaultMaterialButton {...otherProps} />)`
    && {
        background-color: ${(props) => props.theme.colors.electricblue};
        border: 1px solid transparent;
        background: linear-gradient(145deg, #4072ff, #3660e3);
        box-shadow: 8px 8px 24px #0b3566, -8px -8px 24px #0d3b74;
        border-radius: 8px;
        width: 100%;
        color: ${(props) => props.theme.colors.white};
        margin: ${(props) => props.theme.getSpacing(4, 4, 0, 0)};
        &:disabled {
            color: ${(props) => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.darkgrey};
            background: ${(props) => props.theme.colors.darkgrey};
        }
    }
`;

export default ButtonBlueDarkBack;

