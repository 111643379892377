import React, { useState, SetStateAction, Dispatch } from 'react';
import styled from 'styled-components';
import { t } from '@/i18n.js';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type Props = {
    /**onChange */
    onChange: Dispatch<SetStateAction<string>>;
    value: string;
    id: string;
};

export const PasswordField = (props: Props) => {
    const { onChange, value, id } = props;

    const [showPassword, setShowPassword] = useState(false);

    return (
        <STextField
            id={id}
            required
            type={showPassword ? 'text' : 'password'}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            label={t('login.password')}
            variant="outlined"
            InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <SVisibility /> : <SVisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
};
export default PasswordField;

const STextField = styled(TextField)`
    margin: ${(props) => props.theme.getSpacing(2, 0, 4, 0)};
    width: 80%;
    & .MuiInput-root {
        color: darkgray;
    }
    & label {
        color: darkgray;
    }
    & .MuiTextField-root {
        color: darkgray;
    }
    & label.Mui-focused {
        color: darkgray;
    }
    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiOutlinedInput-root {
        & input {
            color: darkgray;
        }
        & fieldset {
            border-color: white;
            /* box-shadow: 5px 5px 10px #d9d9d9, -5px -5px 10px #ffffff; */
            border-radius: 5px;
        }
        &:hover fieldset {
            border-color: white;
        }
        &.Mui-focused fieldset {
            border-color: white;
        }
        &.MuiInput-input {
            color: darkgray;
        }
    }
`;

const SVisibility = styled(VisibilityIcon)`
    color: darkgray;
`;

const SVisibilityOff = styled(VisibilityOffIcon)`
    color: darkgray;
`;

