import React from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { CartesianMarkerProps } from '@nivo/core';

type Props = {
    /**Data which will be displayed, be carefull a special formatting is requried (see nivo library for more info) */
    data: BarDatum[];
    /**Keys to use to determine each serie. */
    keys: Array<string>;
    /**Key to use to index the data. */
    indexBy: string;
    /**Legend on the left of the graph*/
    legendLeft?: string;
    /**Legend on the bottom of the graph*/
    legendBottom?: string;
    /** Colors used for bars */
    colors: Array<string>;
    /**Function use to display label (for Global report mainly) */
    labels?: (d: any) => string;
    /**Markers on graph (for Global report mainly) */
    markers?: CartesianMarkerProps[];
    /**Maximum value  */
    maxValue: number;
};
export const BarChartSVG = (props: Props) => {
    const { data, keys, indexBy, legendLeft, legendBottom, colors, labels, markers, maxValue } = props;

    return (
        <ResponsiveBar
            data={data}
            keys={keys}
            margin={{ top: 20, right: 0, bottom: 20, left: 50 }}
            padding={0.3}
            indexBy={indexBy}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={colors}
            label={labels}
            enableLabel={false}
            maxValue={maxValue}
            borderRadius={4}
            axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: legendBottom,
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: legendLeft,
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            markers={markers}
        />
    );
};

export default BarChartSVG;

