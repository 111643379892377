import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Fab } from '@mui/material';
import { t } from '@/i18n.js';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Typography } from '@/components/atoms';

type Props = {
    /** If the element you desire is in viewport or not */
    inView: boolean;
    /**The route you want to go back to */
    route: string;
    /** The translation key to retrieve the right text to display on hover**/
    translationKey: string;
};

export const StickyBackButton = (props: Props) => {
    const { inView, route, translationKey } = props;
    const [hover, setHover] = useState(false);
    let refStickyButton = useRef(null);

    const history = useHistory();

    const handleClick = () => {
        history.push({
            pathname: route
        });
    };

    if (inView) {
        return <></>;
    }

    return hover ? (
        <SStickyFab onClick={handleClick} variant="extended" onMouseOut={() => setHover(false)}>
            <ArrowBackIcon ref={refStickyButton} />
            <Typography variant="button">{t(translationKey)}</Typography>
        </SStickyFab>
    ) : (
        <SStickyFab onClick={handleClick} onMouseOver={() => setHover(true)}>
            <ArrowBackIcon ref={refStickyButton} />
        </SStickyFab>
    );
};

export default StickyBackButton;

const SStickyFab = styled(Fab)`
    && {
        position: fixed;
        top: ${(props) => props.theme.getSpacing(6)};
        left: ${(props) => props.theme.getSpacing(6)};
    }
`;

