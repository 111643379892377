import React, { useEffect, useState } from 'react';
import { Select as MaterialSelect, SelectChangeEvent, FormControl, InputLabel, MenuItem } from '@mui/material';
import styled from 'styled-components';
import uuid from 'uuidv4';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import i18n from '@/i18n.js';

type Props = {
    removeOutlined?: boolean;
    /* Label to describe the Select component (like the placeholder) */
    label?: string;
    /* Fix width of the component */
    width: string;
    /* Fix height of the component */
    height?: string;
    /* Fix font size of the component */
    fontSize?: string;
    /* Disabled the selection */
    disabled?: boolean;
    /* Action onChange (from parent component) */
    onChange: (value: any) => void;
    /**Possible values */
    values: string[];
    /**Initial value (not required) */
    initialValue?: string;
    /**FormControl will be full width (not required) */
    fullWidth?: boolean;
    /**If true then the render will be executed thanks to valuesRender otherwise MenuItem */
    overrideValuesRendering?: boolean;
    /** Component to render each value*/
    valuesRender?: (value: string) => React.ReactNode;
};

export const Select = (props: Props) => {
    const {
        removeOutlined,
        label,
        width,
        height,
        fontSize,
        disabled,
        onChange,
        values,
        initialValue,
        fullWidth,
        overrideValuesRendering,
        valuesRender
    } = props;
    const [value, setValue] = useState(initialValue);
    const [themeContext, updateTheme] = useThemeContext();

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const handleChange = (event: SelectChangeEvent<{ value: any }>) => {
        setValue(event.target.value as string);
        onChange(event.target.value as string);
    };

    const id = uuid();

    return (
        <SFormControl variant={removeOutlined ? 'standard' : 'outlined'} fullWidth={fullWidth ?? false}>
            <InputLabel id={`${id}-${i18n.language}-label`}>{label}</InputLabel>
            <SSelect
                defaultValue=""
                labelId={`${id}-${i18n.language}-label`}
                id={id}
                value={value}
                onChange={handleChange}
                label={label}
                inputProps={{
                    'aria-label': label ? '' : 'Without label'
                }}
                width={width}
                height={height}
                fontSize={fontSize}
                disabled={disabled}
            >
                {overrideValuesRendering && valuesRender
                    ? Object.values(values).map((value) => valuesRender(value))
                    : Object.values(values).map((value) => (
                          <MenuItem key={value} value={value}>
                              {value}
                          </MenuItem>
                      ))}
            </SSelect>
        </SFormControl>
    );
};

export default Select;

const SSelect = styled(({ width, height, fontSize, ...otherProps }) => <MaterialSelect {...otherProps} />)`
    && {
        width: ${(props) => props.width};
        height: ${(props) => props.height};
        font-size: ${(props) => props.fontSize};
        margin: 0;
    }
`;

const SFormControl = styled(FormControl)`
    & .MuiFormLabel-root {
        background-color: ${(props) => props.theme.analysisDetails.cardBackground};
        color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.label};
        font-family: 'Inter', sans-serif;
        padding-right: 5px;
    }
    & .MuiInputBase-root {
        color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.inputText};
        & label {
            color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &:hover fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.MuiInput-input {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & .MuiSelect-icon {
            color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
    }
`;

