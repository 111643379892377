import React from 'react';
import styled from 'styled-components';
import { CircularProgress, Typography } from '@/components/atoms';

import Box from '@mui/material/Box';

type Props = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: number;
};

const CircularProgressWithLabel = (props: Props) => {
    const { value } = props;

    return (
        <>
            <div style={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <SBox>
                    <STypography variant="caption" color="textSecondary">{`${Math.round(value)}%`}</STypography>
                </SBox>
            </div>
        </>
    );
};

const STypography = styled(Typography)`
    && {
        font-size: 0.67rem;
    }
`;

const SBox = styled(Box)`
    && {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export default CircularProgressWithLabel;

