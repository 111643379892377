import React, { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { TextField, InputAdornment } from '@mui/material';

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import ReactGA from 'react-ga';

import { ButtonBlueDarkBack, Typography, CircularProgress } from '@/components/atoms';
import { ForgotPasswordRequest, UsersClient } from '@api';
import i18n, { t } from '@/i18n.js';
import { Language } from '@/utils/Language';

export const ForgotPasswordComp = () => {
    const currentHistory = useHistory();

    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const onClickModify = () => {
        setLoading(true);
        const client = new UsersClient({ token: '' }, process.env.BackendServiceURL);
        const fPRequest = new ForgotPasswordRequest({
            email,
            language: i18n.language == Language.English ? 'English' : 'French'
        });
        client.forgotPassword(fPRequest);
        currentHistory.push('/forgot-password-validation');
        ReactGA.event({
            category: 'Password',
            action: 'Forget Password'
        });
    };

    return (
        <>
            <Typography subtitle="true" variant="h3">
                {t('settings.resetMail')}
            </Typography>
            <STextFieldDiv>
                <STextField
                    required // @ts-ignore
                    onChange={(event) => setEmail(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SMailOutline />
                            </InputAdornment>
                        )
                    }}
                />
            </STextFieldDiv>

            <SBottomContainer>
                <ButtonBlueDarkBack disabled={email === ''}>
                    {!loading ? (
                        <Typography variant="button" onClick={onClickModify}>
                            {t('settings.resetPswd')}
                        </Typography>
                    ) : (
                        <CircularProgress />
                    )}
                </ButtonBlueDarkBack>
            </SBottomContainer>
        </>
    );
};

export default ForgotPasswordComp;

const SBottomContainer = styled.div`
    padding-top: ${(props) => props.theme.getSpacing(5)};
    padding-bottom: ${(props) => props.theme.getSpacing(5)};
`;

const STextFieldDiv = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: ${(props) => props.theme.getSpacing(5)};
`;

const SMailOutline = styled(MailOutlineIcon)`
    color: rgba(255, 255, 255, 0.7);
    padding: ${(props) => props.theme.getSpacing(3)};
`;

const STextField = styled(TextField)`
    width: 30%;
    border-radius: 10px;
    margin: ${(props) => props.theme.getSpacing(3, 0, 2, 0)};
    background: linear-gradient(145deg, #0c386d, #0a2f5c);
    box-shadow: 5px 5px 20px #0a2f5d, -5px -5px 20px #0c396f;
    & .MuiInput-root {
        color: white;
    }
    & label {
        color: rgba(255, 255, 255, 0.7);
    }
    & .MuiTextField-root {
        color: white;
    }
    & label.Mui-focused {
        color: white;
    }
    & .MuiInput-underline {
        border-bottom-color: rgba(255, 255, 255, 0.7);
    }
    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiOutlinedInput-root {
        & input {
            color: white;
        }
        & fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
        &:hover fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
        &.Mui-focused fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
    }
`;

