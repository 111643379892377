import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PointSymbolProps, ResponsiveLine, Serie } from '@nivo/line';
import uuid from 'uuidv4';
import i18n, { t } from '@/i18n.js';
import { DateTime } from 'luxon';

import { Typography } from '@/components/atoms';
import theme from '@/utils/styles/Theme';
import { Language } from '@/utils/Language';

export interface ILineObject {
    x: any;
    y: any;
}
export interface ILineData {
    lineDataCadRads0: ILineObject[];
    lineDataCadRads1: ILineObject[];
    lineDataCadRads12: ILineObject[];
    lineDataCadRads2: ILineObject[];
    lineDataCadRads23: ILineObject[];
    lineDataCadRads3: ILineObject[];
    lineDataCadRads34: ILineObject[];
    lineDataCadRads4: ILineObject[];
    lineDataCadRads5: ILineObject[];
}

const CustomSymbol = ({ size, color, borderWidth, borderColor }: PointSymbolProps) => {
    if (color === '#53DFA2') {
        size = size * 0.8;
    } else if (color === '#FCB97D') {
        size = size * 0.9;
    }
    return (
        <g>
            <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
            <circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
        </g>
    );
};

type Props = {
    /**Data which will be displayed, be carefull a special formatting is requried (see nivo library for more info) */
    data: ILineData;
};

export const LineChart = (props: Props) => {
    const { data } = props;
    const [colors, setColors] = useState<string[]>([]);

    const [lineData, setLineData] = useState<Serie[]>([]);
    useEffect(() => {
        const tmpLine: Serie[] = [];
        const colorsToAdd: string[] = [];
        if (data.lineDataCadRads0.length) {
            tmpLine.push({
                id: 'CAD-RADS 0',
                data: [...data.lineDataCadRads0]
            });
            colorsToAdd.push(theme.colors.green);
        }
        if (data.lineDataCadRads1.length) {
            tmpLine.push({
                id: 'CAD-RADS 1',
                data: [...data.lineDataCadRads1]
            });
            colorsToAdd.push(theme.colors.yellow);
        }
        if (data.lineDataCadRads12.length) {
            tmpLine.push({
                id: 'CAD-RADS 1-2',
                data: [...data.lineDataCadRads12]
            });
            colorsToAdd.push(theme.colors.yellowHover);
        }
        if (data.lineDataCadRads2.length) {
            tmpLine.push({
                id: 'CAD-RADS 2',
                data: [...data.lineDataCadRads2]
            });
            colorsToAdd.push(theme.colors.orange);
        }
        if (data.lineDataCadRads23.length) {
            tmpLine.push({
                id: 'CAD-RADS 2-3',
                data: [...data.lineDataCadRads23]
            });
            colorsToAdd.push(theme.colors.orangeHover);
        }
        if (data.lineDataCadRads3.length) {
            tmpLine.push({
                id: 'CAD-RADS 3',
                data: [...data.lineDataCadRads3]
            });
            colorsToAdd.push(theme.colors.red);
        }
        if (data.lineDataCadRads34.length) {
            tmpLine.push({
                id: 'CAD-RADS 3-4',
                data: [...data.lineDataCadRads34]
            });
            colorsToAdd.push(theme.colors.redHover);
        }
        if (data.lineDataCadRads4.length) {
            tmpLine.push({
                id: 'CAD-RADS 4',
                data: [...data.lineDataCadRads4]
            });
            colorsToAdd.push(theme.colors.darkred);
        }
        if (data.lineDataCadRads5.length) {
            tmpLine.push({
                id: 'CAD-RADS 5',
                data: [...data.lineDataCadRads5]
            });
            colorsToAdd.push(theme.colors.strongdarkred);
        }
        setColors(colorsToAdd);
        setLineData(tmpLine);
    }, [data]);

    if (!lineData) {
        return <></>;
    }

    const handleFormatLocale = (value: any) => {
        return DateTime.fromJSDate(value)
            .setLocale((i18n.language as Language) === Language.French ? 'fr-FR' : 'en-gb')
            .toLocaleString({ month: 'short', day: '2-digit' });
    };

    return (
        <ResponsiveLine
            margin={{ top: 20, right: 20, bottom: 60, left: 80 }}
            data={lineData}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day'
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: 'linear',
                stacked: false,
                min: 0
            }}
            axisLeft={{
                legend: t('statistics.lineCharts.number'),
                legendOffset: -40,
                format: (e: any) => Math.floor(e) === e && e
            }}
            axisBottom={{
                format: handleFormatLocale,
                tickValues: 'every 5 days',
                legend: t('statistics.lineCharts.timeScale'),
                legendOffset: 40
            }}
            colors={colors}
            curve={'monotoneX'}
            enablePointLabel={true}
            pointSymbol={CustomSymbol}
            pointSize={16}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]]
            }}
            enableSlices="x"
            useMesh={false}
            sliceTooltip={({ slice }) => {
                return (
                    <SCard>
                        <Typography variant="h3">{slice.points[0].data.xFormatted}</Typography>
                        {slice.points.map((point) => (
                            <SserieDiv key={uuid()}>
                                <STypography key={point.serieId} serieColor={point.serieColor}>
                                    {point.serieId}:
                                </STypography>
                                <Typography key={point.serieId} black>
                                    {point.data.yFormatted}
                                </Typography>
                            </SserieDiv>
                        ))}
                    </SCard>
                );
            }}
        />
    );
};

export default LineChart;

const SCard = styled.div`
    background-color: white;
    border: 1px solid black;
    width: 10rem;
    padding: ${(props) => props.theme.getSpacing(2)};
`;

const SserieDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const STypography = styled(({ serieColor, ...otherProps }) => <Typography {...otherProps} />)`
    color: ${(props) => props.serieColor};
`;

