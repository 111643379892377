import React from 'react';

import { useThemeContext } from '@/utils/contexts/ThemeContext';
import { IconButton } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';
import { makeStyles } from '@mui/styles';

import { Theme } from '@mui/material/styles';

interface StyleProps {
    root_background: string;
    root_background_hover: string;
}

type Props = {
    display: boolean;
};

export const DarkModeSwitcher = (props: Props) => {
    const { display } = props;

    const [themeContext, updateTheme] = useThemeContext();

    const propsStyle: StyleProps = {
        root_background: themeContext.isLightMode ? lightTheme.app.switchDarkMode : darkTheme.app.switchDarkMode,
        root_background_hover: themeContext.isLightMode
            ? lightTheme.app.switchDarkModeHover
            : darkTheme.app.switchDarkModeHover
    };

    const classes = useStyles(propsStyle);

    return (
        <>
            {display && (
                <IconButton
                    className={classes.root}
                    size="small"
                    onClick={() => updateTheme({ isLightMode: !themeContext.isLightMode })}
                >
                    {!themeContext.isLightMode ? (
                        <DarkModeIcon fontSize="inherit" />
                    ) : (
                        <LightModeIcon fontSize="inherit" />
                    )}
                </IconButton>
            )}
        </>
    );
};

export default DarkModeSwitcher;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    root: {
        '&.MuiIconButton-root': { color: '#FFFFFF', backgroundColor: ({ root_background }) => root_background },
        '&.MuiIconButton-root:hover': { backgroundColor: ({ root_background_hover }) => root_background_hover }
    }
}));

