import React, { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';

import { CircularProgress, SelectCharts } from '@/components/atoms';
import { ClassificationResult, IStoredAnalysis } from '@api';
import styled from 'styled-components';
import { gatherStatistics } from '@/services/StatisticsService';
import { Serie } from '@nivo/line';
import { t } from '@/i18n.js';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ILineInfo {
    /**Data which will be displayed in the graph */
    lineData: Serie[];
    /**Colors will be modified dynamically depending on how many data we have */
    colors: string[];
}

// Analyses are now only 0, 1, 2, 3, 4, 5.
// We do no longer have intermediate (1-2, 2-3, 3-4), the intermediate are shown as 2, 3, 4, with a range percentage.
// In the stats page, the 1-2 become 2, 2-3 become 3, 3-4 become 4
export type CadRadsStats = {
    [ClassificationResult.Rads0]: number;
    [ClassificationResult.Rads1]: number;
    [ClassificationResult.Rads2]: number;
    [ClassificationResult.Rads3]: number;
    [ClassificationResult.Rads4]: number;
    [ClassificationResult.Rads5]: number;
};

const InitialCadRadsStats: CadRadsStats = {
    [ClassificationResult.Rads0]: 0,
    [ClassificationResult.Rads1]: 0,
    [ClassificationResult.Rads2]: 0,
    [ClassificationResult.Rads3]: 0,
    [ClassificationResult.Rads4]: 0,
    [ClassificationResult.Rads5]: 0
};

type Props = {
    /**if the stats are still loading */
    isStatsLoading: boolean;
    /***The value for each cad-rads */
    stAnalysis: IStoredAnalysis[];
    /*** Year for the statistics */
    year: string;
    /*** Set the year for the statistics */
    setYear: Dispatch<SetStateAction<string>>;
};

export const LineChartStatisticsHome = (props: Props) => {
    const { isStatsLoading, stAnalysis, year, setYear } = props;

    const [yearValues, setYearValues] = useState(['No Analysis']);
    const today = new Date();

    useEffect(() => {
        (async function iieFunction() {
            const statisticsFetched = gatherStatistics(stAnalysis);
            const oldestStoredDate = statisticsFetched.oldestStoredDate;
            if (oldestStoredDate && oldestStoredDate.getFullYear() != 2100) {
                const oldest_year = oldestStoredDate.getFullYear();
                const current_year = today.getFullYear();
                setYearValues(
                    [...Array(current_year - oldest_year + 1).keys()].map((x) => (x + oldest_year).toString())
                );
            }
        })();
    }, [stAnalysis]);

    const getValue = (
        cadRadsStatsMonth: CadRadsStats,
        rads:
            | ClassificationResult.Rads0
            | ClassificationResult.Rads1
            | ClassificationResult.Rads2
            | ClassificationResult.Rads3
            | ClassificationResult.Rads4
            | ClassificationResult.Rads5
    ) => cadRadsStatsMonth[rads] | 0;

    const monthToStr = (month: number) => {
        const monthNames = [
            t('months.jan'),
            t('months.feb'),
            t('months.mar'),
            t('months.apr'),
            t('months.may'),
            t('months.jun'),
            t('months.jul'),
            t('months.aug'),
            t('months.sep'),
            t('months.oct'),
            t('months.nov'),
            t('months.dec')
        ];
        return monthNames[month - 1];
    };

    const getLineData = useCallback(() => {
        let res: ILineInfo = { lineData: [], colors: [] };
        let rads0LineDate = {
            id: ClassificationResult.Rads0,
            color: getColorByRads(ClassificationResult.Rads0),
            data: [{}]
        };
        let rads1LineDate = {
            id: ClassificationResult.Rads1,
            color: getColorByRads(ClassificationResult.Rads1),
            data: [{}]
        };
        let rads2LineDate = {
            id: ClassificationResult.Rads2,
            color: getColorByRads(ClassificationResult.Rads2),
            data: [{}]
        };
        let rads3LineDate = {
            id: ClassificationResult.Rads3,
            color: getColorByRads(ClassificationResult.Rads3),
            data: [{}]
        };
        let rads4LineDate = {
            id: ClassificationResult.Rads4,
            color: getColorByRads(ClassificationResult.Rads4),
            data: [{}]
        };
        let rads5LineDate = {
            id: ClassificationResult.Rads5,
            color: getColorByRads(ClassificationResult.Rads5),
            data: [{}]
        };
        let nbMonths, monthStats, nbYears, yearStats;
        if (year === 'all') {
            monthStats = gatherStatistics(stAnalysis, {
                from: new Date(parseInt(yearValues[0]), 0, 1),
                to: new Date(parseInt(yearValues[yearValues.length - 1]) + 1, 0, 1)
            });
            nbYears = yearValues.length;
            for (let i = 0; i < nbYears; i++) {
                yearStats = gatherStatistics(stAnalysis, {
                    from: new Date(parseInt(yearValues[i]), 0, 1),
                    to: new Date(parseInt(yearValues[i]), 11, 31)
                });
                rads0LineDate.data.push({
                    x: yearValues[i],
                    y: getValue(yearStats.cadRadsStats, ClassificationResult.Rads0)
                });
                rads1LineDate.data.push({
                    x: yearValues[i],
                    y: getValue(yearStats.cadRadsStats, ClassificationResult.Rads1)
                });
                rads2LineDate.data.push({
                    x: yearValues[i],
                    y: getValue(yearStats.cadRadsStats, ClassificationResult.Rads2)
                });
                rads3LineDate.data.push({
                    x: yearValues[i],
                    y: getValue(yearStats.cadRadsStats, ClassificationResult.Rads3)
                });
                rads4LineDate.data.push({
                    x: yearValues[i],
                    y: getValue(yearStats.cadRadsStats, ClassificationResult.Rads4)
                });
                rads5LineDate.data.push({
                    x: yearValues[i],
                    y: getValue(yearStats.cadRadsStats, ClassificationResult.Rads5)
                });
            }

            res.lineData.push(rads0LineDate, rads1LineDate, rads2LineDate, rads3LineDate, rads4LineDate, rads5LineDate);
            return res;
        } else {
            let year_int = parseInt(year);
            if (year_int == today.getFullYear()) {
                nbMonths = today.getMonth() + 1;
            } else {
                nbMonths = 12;
            }
            monthStats = gatherStatistics(stAnalysis, {
                from: new Date(year_int, 0, 1),
                to: new Date(year_int, 1, 1)
            });
        }
        rads0LineDate.data = [{ x: monthToStr(1), y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads0) }];
        rads1LineDate.data = [{ x: monthToStr(1), y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads1) }];
        rads2LineDate.data = [{ x: monthToStr(1), y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads2) }];
        rads3LineDate.data = [{ x: monthToStr(1), y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads3) }];
        rads4LineDate.data = [{ x: monthToStr(1), y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads4) }];
        rads5LineDate.data = [{ x: monthToStr(1), y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads5) }];

        for (let month = 2; month < nbMonths + 1; month++) {
            monthStats = gatherStatistics(stAnalysis, {
                from: new Date(parseInt(year), month - 1, 1),
                to: new Date(parseInt(year), month, 1)
            });
            rads0LineDate.data.push({
                x: monthToStr(month),
                y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads0)
            });
            rads1LineDate.data.push({
                x: monthToStr(month),
                y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads1)
            });
            rads2LineDate.data.push({
                x: monthToStr(month),
                y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads2)
            });
            rads3LineDate.data.push({
                x: monthToStr(month),
                y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads3)
            });
            rads4LineDate.data.push({
                x: monthToStr(month),
                y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads4)
            });
            rads5LineDate.data.push({
                x: monthToStr(month),
                y: getValue(monthStats.cadRadsStats, ClassificationResult.Rads5)
            });
        }

        res.lineData.push(rads0LineDate, rads1LineDate, rads2LineDate, rads3LineDate, rads4LineDate, rads5LineDate);
        return res;
    }, [stAnalysis, year]);

    // Data rendering for ChartJS
    const lineData = getLineData().lineData;

    const data = {
        datasets: Array.from({ length: lineData.length }, (_, i) => ({
            label: `CAD-RADS ${i}`,
            data: lineData[i].data,
            backgroundColor: lineData[i].color,
            borderColor: lineData[i].color,
            tension: 0.3
        }))
    };

    const options: any = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        }
    };

    return (
        <>
            {isStatsLoading ? (
                <SMiddle>
                    <CircularProgress />
                </SMiddle>
            ) : (
                <SLine>
                    <SSearchDate>
                        <SelectCharts
                            disabled={false}
                            width="6rem"
                            height="2rem"
                            fontSize="100%"
                            onChange={setYear}
                            values={yearValues}
                            value={year.toString()}
                        />
                    </SSearchDate>
                    <SChartDiv>
                        <Line data={data} options={options} />
                    </SChartDiv>
                </SLine>
            )}
        </>
    );
};

export default LineChartStatisticsHome;

const SMiddle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    height: 75vh;
`;

const SLine = styled.div`
    position: relative;
    width: 50rem;
    height: 75vh;
`;

const SChartDiv = styled.div`
    display: flex;
    width: 90%;
    height: 90%;
    align-items: center;
    padding: 10px;
`;

const SSearchDate = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

