import React from 'react';
import styled from 'styled-components';
import { Button, SvgIconProps } from '@mui/material';
import Typography from '../Typography';
import Tooltip from '@mui/material/Tooltip';

type Props = {
    icon: React.ReactElement<SvgIconProps>;
    text?: string;
    tooltipText?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled: boolean;
};

export const ButtonActionOutlined = (props: Props) => {
    const { icon, text, tooltipText, onClick, disabled } = props;

    return tooltipText ? (
        <Tooltip title={tooltipText}>
            <span>
                <SButton onClick={onClick} disabled={disabled}>
                    <SIcon>{icon}</SIcon>
                    {text ?? <Typography>{text}</Typography>}
                </SButton>
            </span>
        </Tooltip>
    ) : (
        <SButton onClick={onClick} disabled={disabled}>
            <SIcon>{icon}</SIcon>
            {text ?? <Typography>{text}</Typography>}
        </SButton>
    );
};

export default ButtonActionOutlined;

const SButton = styled(Button)`
    && {
        font-size: 16px;
        text-transform: none;
        background-color: ${(props) => props.theme.buttonLightShadow.backgroundIcon};
        color: ${(props) => props.theme.buttonLightShadow.iconColor};
        border-radius: 10px;
        min-width: 20px;
        padding: 5px 9px;
        // box-shadow: ${(props) => props.theme.buttonLightShadow.boxShadow};
        filter: ${(props) => props.theme.buttonLightShadow.filter};
        &:hover {
            background-color: ${(props) => props.theme.buttonLightShadow.hover.backgroundIcon};
            color: ${(props) => props.theme.buttonLightShadow.hover.iconColor};
            box-shadow: ${(props) => props.theme.buttonLightShadow.hover.boxShadow};
        }
    }
`;

const SIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
`;

