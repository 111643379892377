import React from 'react';
import { BarDatum, ResponsiveBarCanvas } from '@nivo/bar';
import { CartesianMarkerProps } from '@nivo/core';

type Props = {
    /**Data which will be displayed, be carefull a special formatting is requried (see nivo library for more info) */
    data: BarDatum[];
    /**Keys to use to determine each serie. */
    keys: Array<string>;
    /**Key to use to index the data. */
    indexBy: string;
    /**Legend on the left of the graph*/
    legendLeft?: string;
    /** Colors used for bars */
    colors: Array<string>;
    /**Function use to display label (for Global report mainly) */
    labels?: (d: any) => string;
    /**Markers on graph (for Global report mainly) */
    markers?: CartesianMarkerProps[];
    /**Max value */
    maxValue?: number;
};
export const BarChart = (props: Props) => {
    const { data, keys, indexBy, legendLeft, colors, labels, markers, maxValue } = props;

    return (
        <ResponsiveBarCanvas
            data={data}
            keys={keys}
            margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
            padding={0.3}
            indexBy={indexBy}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={colors}
            label={labels}
            borderRadius={10}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: legendLeft,
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            pixelRatio={1}
            maxValue={maxValue}
        />
    );
};

export default BarChart;

