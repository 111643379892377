import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '@/components/molecules';
import { Session } from '@/utils/contexts/SessionContext';

import { GetAllStoredAnalysis } from '@/services/StoredAnalysisService';

type Props = {
    currentSessionInCookie: Session;
};

export const AnalysisRedirect = (props: Props) => {
    const { currentSessionInCookie } = props;
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async function iifeFunction() {
            setIsLoading(true);
            let allStoredAnalysis = await GetAllStoredAnalysis({ sessionContext: currentSessionInCookie });
            if (allStoredAnalysis.length == 0) {
                setIsLoading(false);
                history.push('/analysis-explorer/-1');
            } else {
                const filterStored = localStorage.getItem('filterRads');
                const filter = filterStored === null ? 'ALL' : filterStored;
                const onlyNewStored = localStorage.getItem('onlyNew') !== null;
                const onlyNew = onlyNewStored ?? true;
                if (onlyNew) {
                    allStoredAnalysis = allStoredAnalysis.filter((a) => !a.hasBeenOpened);
                }
                if (filter != 'ALL') {
                    allStoredAnalysis = allStoredAnalysis.filter((a) => a.classificationResult.split(' ')[1] == filter);
                }
                const tmp = allStoredAnalysis
                    .map((index) => index.id)
                    .sort((a, b) => b - a)[0]
                    .toString();
                setIsLoading(false);
                history.push('/analysis-explorer/' + tmp);
            }
        })();
    }, []);

    return <>{isLoading ? <></> : <ErrorMessage message={'An error occurred'} />}</>;
};

export default AnalysisRedirect;
