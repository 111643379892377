import React, { useContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField, InputAdornment, MenuItem, Switch } from '@mui/material';
import styled from 'styled-components';
import i18n, { t } from '@/i18n.js';

import Tooltip from '@mui/material/Tooltip';

import { Typography, Select, ButtonOutlined, ButtonBlueAlternative } from '@/components/atoms';
import { initialMedicalPropsValue, Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';
import { Language } from '@/utils/Language';
import useLocalStorage from '@/utils/hooks/useLocalStorage';
import { stenosisClassification } from '@/components/organisms/AnalysisReportCompImages';

import {
    ClassificationResult,
    Coronary,
    CreateMedicalReportRequest,
    IStoredAnalysis,
    MedicalReport,
    PostMedicalReportRequest,
    StoredAnalysisClient
} from '@api';
import { ConclusionRadsMedicalReport } from '@/components/organisms/ConclusionRadsMedicalReport';
import {
    translateAllMedicalPropsAndGetSelectBoundaries,
    getConclusionTranslationFromAnalysisClassificationResult,
    getCalcificationTranslation
} from '@/utils/MedicalReportFormTranslation';
import { SendMedicalReportForm } from '@/services/MedicalReportService';
import { Session, getSessionCookie } from '@/utils/contexts/SessionContext';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { ResultsRads } from '@/utils/ResultsRads';
import { stenosisClassificationList, getColorByStenosisWithoutBackground } from '@/utils/ResultsRadsStenosisColors';
import InfoIcon from '@mui/icons-material/Info';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';
import { CalcificationRadsMedicalReport } from '@/components/molecules/CalcificationRadsMedicalReport';

type PropsDisabled = {
    disabled: boolean;
};

type Props = {
    /**To trigger the snackbar feedback component */
    setDisplayMedicalReportInPdf: React.Dispatch<React.SetStateAction<boolean>>;
    displayMedicalReportInPdf: boolean;
    setOpenMedicFeedback: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenRemovedFromPdfFeedback: React.Dispatch<React.SetStateAction<boolean>>;
    medicalProps: MedicalProps;
    /**Set props for the PdfDocument */
    setMedicalProps: React.Dispatch<React.SetStateAction<MedicalProps>>;
    /**The current storedAnalysis */
    storedAnalysis: IStoredAnalysis;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    medicalReportId: number;
    setMedicalReportId: React.Dispatch<React.SetStateAction<number>>;
    currentSessionInCookie: Session;
};

export const MedicalReportCreationForm = (props: Props) => {
    const {
        setDisplayMedicalReportInPdf,
        displayMedicalReportInPdf,
        setOpenMedicFeedback,
        setOpenRemovedFromPdfFeedback,
        medicalProps,
        setMedicalProps,
        storedAnalysis,
        ladResults,
        cxResults,
        rcaResults,
        medicalReportId,
        setMedicalReportId,
        currentSessionInCookie
    } = props;

    const [language, setLanguage] = useLocalStorage<Language>('i18nextLng', i18n.language as Language);

    const { refreshList } = useContext(StoredAnalysisListContext);

    const id = storedAnalysis.id;
    const [isLadCalcificationLoaded, setLadCalcificationHasLoaded] = useState<boolean>(false);
    const [doctorName, setDoctorName] = useState<string>('');
    const [technique, setTechnique] = useState<string>('');
    const [contrastQuantity, setContrastQuantity] = useState<number | undefined>(undefined);
    const [dlp, setDlp] = useState<number | undefined>(undefined);
    const [ladStenosis, setLadStenosis] = useState('');
    const [cxStenosis, setCxStenosis] = useState('');
    const [rcaStenosis, setRcaStenosis] = useState('');
    const [indication, setIndication] = useState('');
    const [cavite, setCavite] = useState<string>('');
    const [mitrale, setMitrale] = useState<string>('');
    const [aortique, setAortique] = useState<string>('');
    const [racine, setRacine] = useState<string>('');
    const [pericarde, setPericarde] = useState<string>('');
    const [commentaries, setCommentaries] = useState<string>('');
    const [conclusionCadRads, setConclusionCadRads] = useState<string>('');
    const [classificationResult, setClassificationResult] = useState<ClassificationResult>(ClassificationResult.NotSet);
    const [snackbarContext, updateSnackbar] = useSnackbarContext();

    const [storeMedicalPropsTranslated, setStoreMedicalPropsTranslated] = useState<MedicalProps>();
    const [ladCalcification, setLadCalcification] = useState<string>('');
    const [cxCalcification, setCxCalcification] = useState<string>('');
    const [rcaCalcification, setRcaCalcification] = useState<string>('');

    const [indicationsList, setIndicationList] = useState<string[]>([
        t('medicalReportForm.indication.checkUp'),
        t('medicalReportForm.indication.coronaryStenosisScreening'),
        t('medicalReportForm.indication.cardiovascularRisk'),
        t('medicalReportForm.indication.atypicalChestPain'),
        t('medicalReportForm.indication.typicalChestPain'),
        t('medicalReportForm.indication.shortbreath')
    ]);
    const [authorizedLadStenosis, setAuthorizedLadStenosis] = useState<string[]>(['', ...stenosisClassificationList]);
    const [authorizedCxStenosis, setAuthorizedCxStenosis] = useState<string[]>(['', ...stenosisClassificationList]);
    const [authorizedRcaStenosis, setAuthorizedRcaStenosis] = useState<string[]>(['', ...stenosisClassificationList]);

    const [hasLADImages, setHasLADImages] = useState<boolean>(false);
    const [hasCXImages, setHasCxImages] = useState<boolean>(false);
    const [hasRCAImages, setHasRCAImages] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const divRef = useRef<null | HTMLDivElement>(null);

    const [initialFormData, setInitialFormData] = useState<MedicalProps>();

    const initializeDataWithMedicalProps = (medicalProps: MedicalProps) => {
        let medicalPropsTranslatedAndSelectBoundaries = translateAllMedicalPropsAndGetSelectBoundaries(medicalProps);
        let medicalPropsTranslated = medicalPropsTranslatedAndSelectBoundaries.medicalPropsTranslated;
        setStoreMedicalPropsTranslated(medicalPropsTranslated);

        setAuthorizedLadStenosis(medicalPropsTranslatedAndSelectBoundaries.authorizedLadStenosis);
        setAuthorizedCxStenosis(medicalPropsTranslatedAndSelectBoundaries.authorizedCxStenosis);
        setAuthorizedRcaStenosis(medicalPropsTranslatedAndSelectBoundaries.authorizedRcaStenosis);
        setIndicationList(medicalPropsTranslatedAndSelectBoundaries.authorizedIndicationList);

        setDoctorName(medicalPropsTranslated.doctorName);
        setTechnique(medicalPropsTranslated.technique);
        setContrastQuantity(medicalPropsTranslated.contrastQuantity);
        setDlp(medicalPropsTranslated.dlp);
        setIndication(medicalPropsTranslated.indication);
        setLadStenosis(medicalPropsTranslated.ladStenosis);
        setCxStenosis(medicalPropsTranslated.cxStenosis);
        setRcaStenosis(medicalPropsTranslated.rcaStenosis);
        // Conclusion rads translation is handled in ConclusionRadsMedicalReport component
        setConclusionCadRads(medicalProps.conclusionCadRads);
        setClassificationResult(medicalProps.classificationResult);
        setCommentaries(medicalPropsTranslated.commentaries);
        setCavite(medicalPropsTranslated.cavite);
        setMitrale(medicalPropsTranslated.mitrale);
        setAortique(medicalPropsTranslated.aortique);
        setRacine(medicalPropsTranslated.racine);
        setPericarde(medicalPropsTranslated.pericarde);

        setLadCalcification(medicalPropsTranslated.ladCalcification);
        setCxCalcification(medicalPropsTranslated.cxCalcification);
        setRcaCalcification(medicalPropsTranslated.rcaCalcification);

        setHasLADImages(ladResults?.Images.length > 0 && ladResults?.RadsAnalysisResponse?.imageScores?.length > 0);
        setHasCxImages(cxResults?.Images.length > 0 && cxResults?.RadsAnalysisResponse?.imageScores?.length > 0);
        setHasRCAImages(rcaResults?.Images.length > 0 && rcaResults?.RadsAnalysisResponse?.imageScores?.length > 0);
    };

    useEffect(() => {
        if (medicalReportId != -1) {
            if (medicalReportId == 0) {
                setIsLoading(true);
                // Add CAD-RADS string corresponding to RadsAnalysisResponse, if there is no corresponding medical report in DB (medicalReportId = 0)
                let updatedInitialMedicalProps = initialMedicalPropsValue;
                let initLad = stenosisClassification(ladResults?.RadsAnalysisResponse?.classificationResult);
                let initCx = stenosisClassification(cxResults?.RadsAnalysisResponse?.classificationResult);
                let initRca = stenosisClassification(rcaResults?.RadsAnalysisResponse?.classificationResult);
                let initConclusion = getConclusionTranslationFromAnalysisClassificationResult(
                    storedAnalysis?.classificationResult
                );
                updatedInitialMedicalProps.ladStenosis = initLad;
                updatedInitialMedicalProps.cxStenosis = initCx;
                updatedInitialMedicalProps.rcaStenosis = initRca;
                updatedInitialMedicalProps.conclusionCadRads = initConclusion;

                let intermediateLadCalcification = getCalcificationTranslation(
                    ladResults.RadsAnalysisResponse.calcificationResult
                );
                let intermediateCxCalcification = getCalcificationTranslation(
                    cxResults.RadsAnalysisResponse.calcificationResult
                );
                let intermediateRcaCalcification = getCalcificationTranslation(
                    rcaResults.RadsAnalysisResponse.calcificationResult
                );
                updatedInitialMedicalProps.ladCalcification = intermediateLadCalcification;
                updatedInitialMedicalProps.cxCalcification = intermediateCxCalcification;
                updatedInitialMedicalProps.rcaCalcification = intermediateRcaCalcification;

                setMedicalProps(updatedInitialMedicalProps);

                initializeDataWithMedicalProps(updatedInitialMedicalProps);

                setHasLADImages(
                    ladResults?.Images.length > 0 && ladResults?.RadsAnalysisResponse?.imageScores?.length > 0
                );
                setHasCxImages(
                    cxResults?.Images.length > 0 && cxResults?.RadsAnalysisResponse?.imageScores?.length > 0
                );
                setHasRCAImages(
                    rcaResults?.Images.length > 0 && rcaResults?.RadsAnalysisResponse?.imageScores?.length > 0
                );

                let initialize = updatedInitialMedicalProps;
                initialize.dlp = updatedInitialMedicalProps.dlp || undefined;
                initialize.contrastQuantity = updatedInitialMedicalProps.contrastQuantity || undefined;
                setInitialFormData(initialize);
                setIsLoading(false);
            } else {
                setIsLoading(true);

                let medicalPropsTranslatedAndSelectBoundaries =
                    translateAllMedicalPropsAndGetSelectBoundaries(medicalProps);
                let medicalPropsTranslated = medicalPropsTranslatedAndSelectBoundaries.medicalPropsTranslated;

                initializeDataWithMedicalProps(medicalPropsTranslated);

                setHasLADImages(
                    ladResults?.Images.length > 0 && ladResults?.RadsAnalysisResponse?.imageScores?.length > 0
                );
                setHasCxImages(
                    cxResults?.Images.length > 0 && cxResults?.RadsAnalysisResponse?.imageScores?.length > 0
                );
                setHasRCAImages(
                    rcaResults?.Images.length > 0 && rcaResults?.RadsAnalysisResponse?.imageScores?.length > 0
                );

                let initialize = medicalPropsTranslated;
                initialize.dlp = medicalPropsTranslated.dlp || undefined;
                initialize.contrastQuantity = medicalPropsTranslated.contrastQuantity || undefined;
                setInitialFormData(initialize);
                setIsLoading(false);
            }
        } else {
            setIsLoading(true);
        }
    }, [medicalReportId]);

    useEffect(() => {
        if (i18n.language && !isLoading) {
            setLanguage(i18n.language == Language.French ? Language.French : Language.English);
        }
    }, [i18n.language]);

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true);
            let medicalPropsTranslatedAndSelectBoundaries = translateAllMedicalPropsAndGetSelectBoundaries({
                doctorName: doctorName,
                technique: technique || '',
                contrastQuantity: contrastQuantity || undefined,
                dlp: dlp || undefined,
                indication: indication || '',
                ladStenosis: ladStenosis || '',
                cxStenosis: cxStenosis || '',
                rcaStenosis: rcaStenosis || '',
                conclusionCadRads: conclusionCadRads || '',
                classificationResult: classificationResult || ClassificationResult.NotSet,
                commentaries: commentaries || '',
                cavite: cavite || '',
                mitrale: mitrale || '',
                aortique: aortique || '',
                racine: racine || '',
                pericarde: pericarde || '',
                ladCalcification: ladCalcification || '',
                cxCalcification: cxCalcification || '',
                rcaCalcification: rcaCalcification || ''
            });

            let medicalPropsTranslated = medicalPropsTranslatedAndSelectBoundaries.medicalPropsTranslated;

            setMedicalProps(medicalPropsTranslated);
            initializeDataWithMedicalProps(medicalPropsTranslated);

            setIsLoading(false);
        }
    }, [language]);

    const setCoronaryCadRads = (identifier: string) => {
        switch (identifier) {
            case Coronary.LAD:
                return setLadStenosis;
            case Coronary.Cx:
                return setCxStenosis;
            case Coronary.RCA:
                return setRcaStenosis;
            default:
                return () => {};
        }
    };

    const getInitialValueCoronary = (identifier: string) => {
        switch (identifier) {
            case Coronary.LAD:
                return ladStenosis;
            case Coronary.Cx:
                return cxStenosis;
            case Coronary.RCA:
                return rcaStenosis;
            default:
                return '';
        }
    };

    const formHasChanged = () => {
        return (
            doctorName != initialFormData?.doctorName ||
            technique != initialFormData?.technique ||
            contrastQuantity != initialFormData?.contrastQuantity ||
            dlp != initialFormData?.dlp ||
            indication != initialFormData?.indication ||
            ladStenosis != initialFormData?.ladStenosis ||
            cxStenosis != initialFormData?.cxStenosis ||
            rcaStenosis != initialFormData?.rcaStenosis ||
            conclusionCadRads != initialFormData?.conclusionCadRads ||
            commentaries != initialFormData?.commentaries ||
            cavite != initialFormData?.cavite ||
            mitrale != initialFormData?.mitrale ||
            aortique != initialFormData?.aortique ||
            racine != initialFormData?.racine ||
            pericarde != initialFormData?.pericarde ||
            ladCalcification != initialFormData?.ladCalcification ||
            cxCalcification != initialFormData?.cxCalcification ||
            rcaCalcification != initialFormData?.rcaCalcification
        );
    };

    let en = { lng: Language.English };
    let fr = { lng: Language.French };

    const renderStenosis = (val: string) => {
        if (val == '') {
            return (
                <MenuItem key={val} value="">
                    <Typography darkgrey>{t('medicalReportForm.stenosisLesion.nothing', { lng: language })}</Typography>
                </MenuItem>
            );
        }
        return (
            <MenuItem key={val} value={val}>
                <Typography chosenColor={getColorByStenosisWithoutBackground(val)}>{val}</Typography>
            </MenuItem>
        );
    };

    const updateReportWithResult = (result: MedicalReport) => {
        let medicalPropsTranslatedAndSelectBoundaries = translateAllMedicalPropsAndGetSelectBoundaries({
            doctorName: result.doctor,
            technique: result.techniqueName || '',
            contrastQuantity: result.techniqueContrastAgentQuantityML || undefined,
            dlp: result.techniqueDlpMgyCm || undefined,
            indication: result.indication || '',
            ladStenosis: result.lad || '',
            cxStenosis: result.cx || '',
            rcaStenosis: result.rca || '',
            conclusionCadRads: result.conclusion || '',
            classificationResult: result.classificationResult || ClassificationResult.NotSet,
            commentaries: result.comments || '',
            cavite: result.cardiacCavities || '',
            mitrale: result.mitralValve || '',
            aortique: result.aorticValve || '',
            racine: result.aorticRoot || '',
            pericarde: result.pericardium || '',
            ladCalcification: result.ladCalcification || '',
            cxCalcification: result.cxCalcification || '',
            rcaCalcification: result.rcaCalcification || ''
        });
        let medicalPropsTranslated = medicalPropsTranslatedAndSelectBoundaries.medicalPropsTranslated;
        setMedicalProps(medicalPropsTranslated);
        setMedicalReportId(result.id);
        setDisplayMedicalReportInPdf(true);
    };

    const handleSave = async () => {
        if (doctorName) {
            setOpenMedicFeedback(true);

            const medicalFormService = new SendMedicalReportForm(
                { token: currentSessionInCookie.authResponse.token },
                process.env.BackendServiceURL
            );

            if (medicalReportId == 0) {
                let createRequest = new CreateMedicalReportRequest({
                    storedAnalysisId: id,
                    doctor: doctorName,
                    techniqueName: technique,
                    techniqueContrastAgentQuantityML: contrastQuantity,
                    techniqueDlpMgyCm: dlp,
                    indication: indication,
                    lad: ladStenosis,
                    cx: cxStenosis,
                    rca: rcaStenosis,
                    conclusion: conclusionCadRads,
                    classificationResult: classificationResult,
                    cardiacCavities: cavite,
                    mitralValve: mitrale,
                    aorticValve: aortique,
                    aorticRoot: racine,
                    pericardium: pericarde,
                    comments: commentaries,
                    ladCalcification: ladCalcification,
                    cxCalcification: cxCalcification,
                    rcaCalcification: rcaCalcification
                });

                await medicalFormService
                    .createMedicalReport({
                        createRequest
                    })
                    .then((response) => {
                        updateReportWithResult(response.data);
                        updateInitialFormData(response.data);
                    })
                    .catch((err) => {
                        updateSnackbar({
                            snackString: t('errorOccured'),
                            severity: 'error'
                        });
                    });
                var sessionInCookie = getSessionCookie();
                var client = new StoredAnalysisClient(
                    { token: sessionInCookie.authResponse.token },
                    process.env.BackendServiceURL
                );
                refreshList(client, true);
            } else {
                let updateRequest = new PostMedicalReportRequest({
                    id: medicalReportId,
                    storedAnalysisId: id,
                    doctor: doctorName,
                    techniqueName: technique,
                    techniqueContrastAgentQuantityML: contrastQuantity,
                    techniqueDlpMgyCm: dlp,
                    indication: indication,
                    lad: ladStenosis,
                    cx: cxStenosis,
                    rca: rcaStenosis,
                    conclusion: conclusionCadRads,
                    classificationResult: classificationResult,
                    cardiacCavities: cavite,
                    mitralValve: mitrale,
                    aorticValve: aortique,
                    aorticRoot: racine,
                    pericardium: pericarde,
                    comments: commentaries,
                    ladCalcification: ladCalcification,
                    cxCalcification: cxCalcification,
                    rcaCalcification: rcaCalcification
                });

                await medicalFormService
                    .updateMedicalReport({
                        updateRequest
                    })
                    .then((response) => {
                        updateReportWithResult(response.data);
                        updateInitialFormData(response.data);
                    })
                    .catch((err) => {
                        setOpenMedicFeedback(false);
                        updateSnackbar({
                            snackString: t('errorOccured'),
                            severity: 'error'
                        });
                    });
                var sessionInCookie = getSessionCookie();
                var client = new StoredAnalysisClient(
                    { token: sessionInCookie.authResponse.token },
                    process.env.BackendServiceURL
                );
                refreshList(client, true); // Refresh analysis list on home and mini table, so medical report conclusion is up to date
            }
        }
    };

    const updateInitialFormData = (medicalReport: MedicalReport) => {
        setInitialFormData({
            doctorName: medicalReport.doctor,
            technique: medicalReport.techniqueName || '',
            contrastQuantity: medicalReport.techniqueContrastAgentQuantityML || undefined,
            dlp: medicalReport.techniqueDlpMgyCm || undefined,
            indication: medicalReport.indication || '',
            ladStenosis: medicalReport.lad || '',
            cxStenosis: medicalReport.cx || '',
            rcaStenosis: medicalReport.rca || '',
            conclusionCadRads: medicalReport.conclusion || '',
            classificationResult: medicalReport.classificationResult || ClassificationResult.NotSet,
            commentaries: medicalReport.comments || '',
            cavite: medicalReport.cardiacCavities || '',
            mitrale: medicalReport.mitralValve || '',
            aortique: medicalReport.aorticValve || '',
            racine: medicalReport.aorticRoot || '',
            pericarde: medicalReport.pericardium || '',
            ladCalcification: medicalReport.ladCalcification || '',
            cxCalcification: medicalReport.cxCalcification || '',
            rcaCalcification: medicalReport.rcaCalcification || ''
        });
    };

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lengthOfInput = event.target.value.length;
        return event.target.setSelectionRange(lengthOfInput, lengthOfInput);
    };

    return (
        <>
            {!isLoading && (
                <SRowPrincipal>
                    <SColumn>
                        <STypography>{t('medicalReportCreationForm.doctor')}</STypography>
                        <STextField
                            error={!doctorName}
                            helperText={!doctorName ? t('medicalReportCreationForm.fieldRequired') : ''}
                            onChange={(event: any) => setDoctorName(event?.target?.value)}
                            variant={'outlined' as any}
                            size="small"
                            defaultValue={doctorName}
                        />
                        <STypography>{t('medicalReportCreationForm.technique')}</STypography>
                        <STextField
                            onChange={(event: any) => setTechnique(event?.target?.value)}
                            variant={'outlined' as any}
                            size="small"
                            value={technique}
                        />
                        <STextField
                            label={t('medicalReportForm.contrastAgentQuantity')}
                            InputProps={{
                                type: 'number',
                                inputProps: { min: '0' },
                                endAdornment: <InputAdornment position="end">{'  ml'}</InputAdornment>
                            }}
                            onChange={(event: any) => {
                                if (event?.target?.value == '') {
                                    return setContrastQuantity(undefined);
                                } else {
                                    return setContrastQuantity(event?.target?.value as unknown as number);
                                }
                            }}
                            variant={'outlined' as any}
                            size="small"
                            defaultValue={contrastQuantity}
                        />
                        <STextField
                            label={t('medicalReportForm.dlp')}
                            type="number"
                            InputProps={{
                                inputProps: { min: '0' },
                                endAdornment: <InputAdornment position="end">{'  mGy * cm'}</InputAdornment>
                            }}
                            onChange={(event: any) => {
                                if (event?.target?.value == '') {
                                    return setDlp(undefined);
                                } else {
                                    return setDlp(event?.target?.value as unknown as number);
                                }
                            }}
                            variant={'outlined' as any}
                            size="small"
                            defaultValue={dlp}
                        />
                        <STypography>{t('medicalReportCreationForm.indication')}</STypography>
                        <SDiv>
                            <Select
                                key={indication}
                                initialValue={indication}
                                fullWidth
                                width="100%"
                                onChange={setIndication}
                                values={indicationsList}
                            />
                        </SDiv>
                        <STypography>{t('coronary')}</STypography>
                    </SColumn>
                    <SColumn>
                        <Autocomplete
                            value={cavite}
                            onChange={(_event, newValue: string | null) => {
                                setCavite(newValue || '');
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={[t('medicalReportForm.normales')]}
                            freeSolo
                            autoSelect
                            sx={{ display: 'flex', alignItems: 'center' }}
                            renderInput={(params: any) => (
                                <STextField
                                    {...params}
                                    label={t('medicalReportForm.cavite')}
                                    variant={'outlined' as any}
                                />
                            )}
                        />
                        <Autocomplete
                            id="mitrale"
                            key="mitrale"
                            value={mitrale}
                            onChange={(_event, newValue: string | null) => {
                                setMitrale(newValue || '');
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={[t('medicalReportForm.normale')]}
                            freeSolo
                            autoSelect
                            sx={{ display: 'flex', alignItems: 'center' }}
                            renderInput={(params: any) => (
                                <STextField
                                    {...params}
                                    label={t('medicalReportForm.mitrale')}
                                    variant={'outlined' as any}
                                />
                            )}
                        />
                        <Autocomplete
                            id="aorte"
                            key="aorte"
                            value={aortique}
                            onChange={(_event, newValue: string | null) => {
                                setAortique(newValue || '');
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={[t('medicalReportForm.normale')]}
                            freeSolo
                            autoSelect
                            sx={{ display: 'flex', alignItems: 'center' }}
                            renderInput={(params: any) => (
                                <STextField
                                    {...params}
                                    label={t('medicalReportForm.aortique')}
                                    variant={'outlined' as any}
                                />
                            )}
                        />
                        <Autocomplete
                            id="racine"
                            key="racine"
                            value={racine}
                            onChange={(_event, newValue: string | null) => {
                                setRacine(newValue || '');
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={[t('medicalReportForm.noDilatation')]}
                            freeSolo
                            autoSelect
                            sx={{ display: 'flex', alignItems: 'center' }}
                            renderInput={(params: any) => (
                                <STextField
                                    {...params}
                                    label={t('medicalReportForm.racine')}
                                    variant={'outlined' as any}
                                />
                            )}
                        />
                        <Autocomplete
                            id="pericarde"
                            key="pericarde"
                            value={pericarde}
                            onChange={(_event, newValue: string | null) => {
                                setPericarde(newValue || '');
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={[t('medicalReportForm.normal')]}
                            freeSolo
                            autoSelect
                            sx={{ display: 'flex', alignItems: 'center' }}
                            renderInput={(params: any) => (
                                <STextField
                                    {...params}
                                    label={t('medicalReportForm.pericarde')}
                                    variant={'outlined' as any}
                                />
                            )}
                        />
                        <SContainerTable>
                            <SCard>
                                <STableContainer>
                                    <STableColumnFlex>
                                        <STableHeaderDr>
                                            {t('medicalReportCreationForm.doctorInterpretation')}
                                            <Tooltip
                                                title={t('medicalReportCreationForm.doctorInterpretationTooltip') || ''}
                                            >
                                                <SInfoIcon />
                                            </Tooltip>
                                        </STableHeaderDr>
                                        <STableColumnRow>
                                            <STableHeader>
                                                {t(
                                                    'analysis.analysisReportComp.coronaryInformation.stenosisClassification'
                                                )}
                                            </STableHeader>
                                            <STableHeader>
                                                {t(
                                                    'analysis.analysisReportComp.coronaryInformation.calcificationResult'
                                                )}
                                            </STableHeader>
                                        </STableColumnRow>
                                    </STableColumnFlex>
                                    {hasRCAImages && (
                                        <STableColumn>
                                            <STableHeaderTop>{t('RCA')}</STableHeaderTop>
                                            <STableColumnRow>
                                                <div>
                                                    <SResultWithBackground>
                                                        <Select
                                                            initialValue={getInitialValueCoronary(Coronary.RCA)}
                                                            removeOutlined={true}
                                                            fullWidth
                                                            onChange={setCoronaryCadRads(Coronary.RCA)}
                                                            overrideValuesRendering
                                                            width="100%"
                                                            height="2rem"
                                                            values={authorizedRcaStenosis}
                                                            valuesRender={renderStenosis}
                                                        />
                                                    </SResultWithBackground>
                                                </div>
                                                <div>
                                                    <SResultWithBackground>
                                                        <CalcificationRadsMedicalReport
                                                            calcification={rcaCalcification}
                                                            setCalcification={setRcaCalcification}
                                                        />
                                                    </SResultWithBackground>
                                                </div>
                                            </STableColumnRow>
                                        </STableColumn>
                                    )}
                                    {hasLADImages && (
                                        <STableColumn>
                                            <STableHeaderTop>{t('LAD')}</STableHeaderTop>
                                            <STableColumnRow>
                                                <div>
                                                    <SResultWithBackground>
                                                        <Select
                                                            initialValue={getInitialValueCoronary(Coronary.LAD)}
                                                            removeOutlined={true}
                                                            fullWidth
                                                            onChange={setCoronaryCadRads(Coronary.LAD)}
                                                            overrideValuesRendering
                                                            width="100%"
                                                            height="2rem"
                                                            values={authorizedLadStenosis}
                                                            valuesRender={renderStenosis}
                                                        />
                                                    </SResultWithBackground>
                                                </div>
                                                <div>
                                                    <SResultWithBackground>
                                                        <CalcificationRadsMedicalReport
                                                            calcification={ladCalcification}
                                                            setCalcification={setLadCalcification}
                                                        />
                                                    </SResultWithBackground>
                                                </div>
                                            </STableColumnRow>
                                        </STableColumn>
                                    )}
                                    {hasCXImages && (
                                        <STableColumn>
                                            <STableHeaderTop>{t('Cx')}</STableHeaderTop>
                                            <STableColumnRow>
                                                <div>
                                                    <SResultWithBackground>
                                                        <Select
                                                            initialValue={getInitialValueCoronary(Coronary.Cx)}
                                                            removeOutlined={true}
                                                            fullWidth
                                                            onChange={setCoronaryCadRads(Coronary.Cx)}
                                                            overrideValuesRendering
                                                            width="100%"
                                                            height="2rem"
                                                            values={authorizedCxStenosis}
                                                            valuesRender={renderStenosis}
                                                        />
                                                    </SResultWithBackground>
                                                </div>
                                                <div>
                                                    <SResultWithBackground>
                                                        <CalcificationRadsMedicalReport
                                                            calcification={cxCalcification}
                                                            setCalcification={setCxCalcification}
                                                        />
                                                    </SResultWithBackground>
                                                </div>
                                            </STableColumnRow>
                                        </STableColumn>
                                    )}
                                </STableContainer>
                            </SCard>
                        </SContainerTable>
                        <SDivConclusion key={conclusionCadRads}>
                            <ConclusionRadsMedicalReport
                                defaultText={t('medicalReportCreationForm.classificationGlobal')}
                                conclusionCadRads={conclusionCadRads}
                                setConclusionCadRads={setConclusionCadRads}
                                setClassificationResult={setClassificationResult}
                            />
                        </SDivConclusion>
                        <STypography>{t('medicalReportCreationForm.conclusion')}</STypography>
                        <STextFieldComment
                            multiline
                            variant="filled"
                            rows={5}
                            placeholder={t('medicalReportCreationForm.commentsPlaceholder')}
                            defaultValue={commentaries}
                            onChange={(event: any) => setCommentaries(event.target.value)}
                            onFocus={handleFocus}
                        />
                        <SDivButton>
                            {!doctorName || !formHasChanged() ? (
                                <Tooltip
                                    title={
                                        !doctorName
                                            ? t('medicalReportCreationForm.disabledTooltip.missingDoctor') || ''
                                            : t('medicalReportCreationForm.disabledTooltip.missingChanges') || ''
                                    }
                                >
                                    <SButtonBlock disabled={true}>
                                        <ButtonOutlined
                                            text={
                                                medicalReportId == 0
                                                    ? t('medicalReportCreationForm.validateReviewAndAddPdf')
                                                    : displayMedicalReportInPdf == true
                                                    ? t('medicalReportCreationForm.updateReview')
                                                    : t('medicalReportCreationForm.updateReviewAndAddPdf')
                                            }
                                            onClick={() => handleSave()}
                                            disabled={true}
                                        />
                                    </SButtonBlock>
                                </Tooltip>
                            ) : (
                                <SButtonBlock disabled={false}>
                                    <ButtonBlueAlternative
                                        text={
                                            medicalReportId == 0
                                                ? t('medicalReportCreationForm.validateReviewAndAddPdf')
                                                : displayMedicalReportInPdf == true
                                                ? t('medicalReportCreationForm.updateReview')
                                                : t('medicalReportCreationForm.updateReviewAndAddPdf')
                                        }
                                        onClick={() => handleSave()}
                                        disabled={false}
                                    />
                                </SButtonBlock>
                            )}
                        </SDivButton>
                        <div ref={divRef}></div>
                    </SColumn>
                </SRowPrincipal>
            )}
        </>
    );
};

export default MedicalReportCreationForm;

const SRowPrincipal = styled.div`
    display: inline-block;
    width: 100%;
`;

const SColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    padding-bottom: 15px;
    width: 100%;
`;

const STypography = styled(Typography)`
    && {
        padding-left: ${(props: any) => props.theme.getSpacing(2)};
        padding-bottom: ${(props: any) => props.theme.getSpacing(3)};
        font-weight: 600;
    }
`;

const STextField = styled(TextField)`
    && {
        width: 90%;
        margin: auto;
        padding-bottom: ${(props: any) => props.theme.getSpacing(4)};
    }
    & .MuiOutlinedInput-root {
        & input {
            color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.inputText};
        }
        & fieldset {
            border-color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &:hover fieldset {
            border-color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.Mui-focused fieldset {
            border-color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.MuiInput-input {
            border-color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & svg {
            color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.inputText};
        }
        & .MuiTypography-colorTextSecondary {
            color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.label};
        }
        & .MuiTypography-root {
            color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.label};
        }
    }
    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.label};
        font-family: 'Inter', sans-serif;
        background-color: ${(props: any) => props.theme.analysisDetails.cardBackground};
        padding-right: 5px;
    }
    & .MuiInputLabel-root {
        color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.label};
    }
`;

const STextFieldComment = styled(TextField)`
    && {
        padding-bottom: 30px;
        width: 90%;
        margin: auto;
    }
    & > div {
        padding: 10px;
        border-radius: 5px;
        border: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.comment.border};
        color: ${(props: any) => props.theme.analysisDetails.medicalReport.inputFields.comment.text};
        background-color: ${(props: any) =>
            props.theme.analysisDetails.medicalReport.inputFields.comment.backgroundDefault} !important;
        &:focus {
            transition: none;
            background-color: ${(props: any) =>
                props.theme.analysisDetails.medicalReport.inputFields.comment.backgroundActive} !important;
        }
        &:hover {
            transition: none;
            background-color: ${(props: any) =>
                props.theme.analysisDetails.medicalReport.inputFields.comment.backgroundActive} !important;
        }
        &:after {
            border-bottom: 2px solid ${(props: any) => props.theme.colors.blue} !important;
        }
    }
`;

const SDiv = styled.div`
    margin: auto;
    width: 90%;
    padding-bottom: ${(props: any) => props.theme.getSpacing(4)};
`;

const SDivFfrPrediction = styled.div`
    display: flex;
    align-items: center;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    padding-top: ${(props: any) => props.theme.getSpacing(4)};
    padding-bottom: ${(props: any) => props.theme.getSpacing(4)};
`;

const SDivConclusion = styled(SDiv)`
    padding-top: ${(props: any) => props.theme.getSpacing(4)};
`;

const SButtonBlock = styled.div<PropsDisabled>`
    z-index: 1;
    cursor: ${(props: any) => (props.disabled ? 'not-allowed' : 'inherit')};
`;

const SContainerTable = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    height: 100%;
    width: 100%;
    width: 90%;
    margin: auto;
`;

const SCard = styled.div`
    background-color: ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.background};
    border-radius: 10px;
`;

const STableContainer = styled.div`
    display: flex;
    cursor: default;
`;

const STableColumn = styled.div`
    flex: 1;
    display: inline-grid;
    background-color: ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.background};
    border-radius: 10px;
    & > div {
        display: inline-grid;
        flex-direction: column;
        align-items: center;
    }
    & > div > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 50px;
    }
    & > div > div:not(:last-child) {
        border-bottom: 1px solid
            ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.borderColor};
    }
`;

const STableColumnFlex = styled.div`
    flex: 2;
    display: inline-grid;
    & > div:not(:first-child) {
        display: inline-grid;
        flex-direction: column;
    }
    & > div > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 50px;
        padding-left: 17px;
    }
    & > div > div:not(:last-child) {
        border-bottom: 1px solid
            ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.borderColor};
    }
`;

const STableColumnRow = styled.div`
    display: inline-block;
`;

const STableHeader = styled.div`
    align-items: center;
    height: 50px;
    color: ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.title};
`;

const STableHeaderDr = styled.div`
    height: 50px;
    border-bottom: 1px solid ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.borderColor};
    align-items: center;
    font-size: 14px;
    padding-left: 17px;
    color: ${(props: any) => props.theme.colors.blue};
    display: flex;
    flex-direction: row;
`;

const SResultWithBackground = styled.div`
    padding: 6px 9px 6px 9px;
    margin: 3px;
    border-radius: 10px;
    color: ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.content};
    background-color: ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.backgroundContent};
    & p {
        font-size: 16px;
    }
`;

const STableHeaderTop = styled.div`
    padding-left: 3px;
    height: 50px;
    border-bottom: 1px solid ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.borderColor};
    color: ${(props: any) => props.theme.analysisDetails.medicalReport.tableResultsUpdate.title};
`;

const SInfoIcon = styled(InfoIcon)`
    margin-left: 3px;
`;

const SDivButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 45px;
`;

