import React from 'react';
import styled from 'styled-components';

import { ResultsRads } from '@/utils/ResultsRads';
import { t } from '@/i18n.js';
import { Typography } from '@/components/atoms';

//Icons
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ErrorIcon from '@mui/icons-material/Error';
import LockIcon from '@mui/icons-material/Lock';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { Calcification, ClassificationResult, Coronary, Ffr } from '@api';
import { Tooltip } from '@mui/material';
import theme from '@/utils/styles/Theme';
import { getEntropyStr } from '@/utils/GetEntropy';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { getFfr } from '@/utils/FFRHardcode';

type Props = {
    coronary: ResultsRads;
    coronaryImagesListOpen: string;
    updateCoronaryImagesList: (arg: string) => void;
    coronaryHandler: (arg: ResultsRads) => void;
    ffrAvailable: boolean;
    ffrCalculated: boolean;
};

type SIconProps = {
    colors: string;
};

type SContainerProps = {
    backgroundColor: string;
};

export const CoronaryInformation = (props: Props) => {
    const { coronary, coronaryImagesListOpen, updateCoronaryImagesList, coronaryHandler, ffrAvailable, ffrCalculated } =
        props;

    const confidence = getEntropyStr(coronary);

    const classificationResult = coronary.RadsAnalysisResponse.classificationResult;

    const currentCoronaryName =
        coronary.RadsAnalysisResponse.coronary === Coronary.LAD
            ? t('LAD')
            : coronary.RadsAnalysisResponse.coronary === Coronary.Cx
            ? t('CX')
            : t('RCA');

    const ffr = getFfr(coronary);

    const stenosis =
        // rads 0
        classificationResult === ClassificationResult.Rads0
            ? '0%'
            : classificationResult === ClassificationResult.Rads12
            ? '25%'
            : classificationResult === ClassificationResult.Rads23
            ? '50%'
            : classificationResult === ClassificationResult.Rads34
            ? '70%'
            : classificationResult === ClassificationResult.Rads345
            ? '>50 - 99%'
            : classificationResult === ClassificationResult.Rads1
            ? '1-24%'
            : classificationResult === ClassificationResult.Rads2
            ? '25-49%'
            : classificationResult === ClassificationResult.Rads3
            ? '50-69%'
            : classificationResult === ClassificationResult.Rads4
            ? '70-99%'
            : // rads 5
            classificationResult === ClassificationResult.Rads5
            ? '100%'
            : t('An error occured...');
    const stenosis_color = getColorByRads(classificationResult, 'grey');

    const handleDisplayCoronaryImageList = (global: string, coronary: ResultsRads) => {
        coronaryHandler(coronary);
        updateCoronaryImagesList(global);
    };

    const getCalcification = (results: ResultsRads) => {
        let result = 'Null';
        let calcification = results.RadsAnalysisResponse.calcificationResult;
        if (calcification === Calcification.Minimal)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.minimal');
        else if (calcification === Calcification.Moderate)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.moderate');
        else if (calcification === Calcification.Severe)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.severe');
        else if (calcification === Calcification.Null)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.null');
        else if (calcification === Calcification.NotSpecified)
            result = t('analysis.analysisReportComp.coronaryInformation.calcification.notSpecified');
        return result;
    };

    const calcification = getCalcification(coronary);

    return (
        <>
            <SContainer
                backgroundColor={
                    coronaryImagesListOpen != 'null' && coronary.RadsAnalysisResponse.coronary == coronaryImagesListOpen
                        ? theme.colors.lightgreybis
                        : ''
                }
            >
                {coronaryImagesListOpen != 'null' &&
                coronary.RadsAnalysisResponse.coronary == coronaryImagesListOpen ? (
                    <SIconButton onClick={() => handleDisplayCoronaryImageList('null', coronary)}>
                        <CancelIcon sx={{ fontSize: 31 }} />
                    </SIconButton>
                ) : (
                    <></>
                )}
                {/* If current is open, click to close the big image. If current is not open, click to display the big image */}
                <div
                    onClick={() =>
                        coronary.RadsAnalysisResponse.coronary == coronaryImagesListOpen
                            ? handleDisplayCoronaryImageList('null', coronary)
                            : handleDisplayCoronaryImageList(coronary.RadsAnalysisResponse.coronary, coronary)
                    }
                >
                    <SRowTitle>
                        <Typography bold>{currentCoronaryName}</Typography>
                        {classificationResult === ClassificationResult.Rads345 ||
                        classificationResult === ClassificationResult.Rads34 ||
                        classificationResult === ClassificationResult.Rads3 ||
                        classificationResult === ClassificationResult.Rads4 ||
                        classificationResult === ClassificationResult.Rads5 ? (
                            <SErrorIcon colors={stenosis_color} />
                        ) : (
                            <SCircle colors={stenosis_color} />
                        )}
                    </SRowTitle>
                    <SRow>
                        <Typography size2 thin>
                            {t('analysis.analysisReportComp.coronaryInformation.stenosisClassification')}
                        </Typography>
                        {classificationResult === ClassificationResult.Rads5 ? (
                            <Typography size2 lightbold>
                                {stenosis}
                            </Typography>
                        ) : (
                            <Typography size2>{stenosis}</Typography>
                        )}
                    </SRow>
                    <SRow>
                        <Typography size2 thin>
                            {t('analysis.analysisReportComp.coronaryInformation.calcificationResult')}
                        </Typography>
                        <Typography size2>{calcification}</Typography>
                    </SRow>
                    {ffrAvailable && ffrCalculated ? (
                        <SRow>
                            <SSubRow>
                                {coronary.RadsAnalysisResponse.ffrHardcodeResult === Ffr.FfrPlus ? (
                                    <SWarningRoundedIcon colors={getColorByRads(classificationResult)} />
                                ) : (
                                    <></>
                                )}
                                <Typography size2 thin>
                                    {t('analysis.analysisReportComp.coronaryInformation.ffrPrediction')}
                                </Typography>
                            </SSubRow>
                            <Typography size2>{ffr}</Typography>
                        </SRow>
                    ) : ffrCalculated ? (
                        <SRow>
                            <Typography size2 thin>
                                {t('analysis.analysisReportComp.coronaryInformation.ffrPrediction')}
                            </Typography>
                            <Tooltip disableFocusListener disableTouchListener title={t('ffrUnavailable') || ''}>
                                <LockIcon />
                            </Tooltip>
                        </SRow>
                    ) : (
                        <></>
                    )}
                    <SRow>
                        <Typography size2 thin>
                            {t('analysis.analysisReportComp.coronaryInformation.confidenceLevel.title')}
                        </Typography>
                        <Typography size2>{confidence}</Typography>
                    </SRow>
                </div>
            </SContainer>
        </>
    );
};

export default CoronaryInformation;

const SContainer = styled.div<SContainerProps>`
    position: relative;
    width: 90%;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    user-select: none;
    margin: ${(props) => props.theme.getSpacing(2, 0, 2, 0)};
    padding: ${(props) => props.theme.getSpacing(2, 2, 2, 4)};
    background-color: ${(props: SContainerProps) => props.backgroundColor};
    border-radius: 20px;
    box-shadow: 5px 5px 10px #d9d9d9, -5px -5px 10px #ffffff;
    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.whitehover};
        transition: 0.5 ease-in-out background-color;
    }
`;

const SRowTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const SRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const SCircle = styled(FiberManualRecordIcon)<SIconProps>`
    color: ${(props: SIconProps) => props.colors};
    font-size: 1.9rem !important;
`;

const SErrorIcon = styled(ErrorIcon)<SIconProps>`
    color: ${(props: SIconProps) => props.colors};
`;

const SIconButton = styled(IconButton)`
    position: absolute !important;
    right: -19px;
    top: -16px;
    padding: 5px !important;
`;
const SWarningRoundedIcon = styled(WarningRoundedIcon)<SIconProps>`
    color: ${(props: SIconProps) => props.colors};
`;

const SSubRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

