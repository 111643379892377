import React from 'react';
import { t } from '@/i18n.js';
import { Document, Image, Page, Text, View, Font } from '@react-pdf/renderer';

import styles from '@/pdf/rads/Styles';
import { ResultsRads } from '@/utils/ResultsRads';
import { Coronary, IStoredAnalysis } from '@api';
import { File } from '@/components/molecules';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

type Props = {
    storedAnalysis: IStoredAnalysis;
    /**Results for LAD files */
    ladResults: ResultsRads;
    /**Results for Cx files */
    cxResults: ResultsRads;
    /**Results for RCA files */
    rcaResults: ResultsRads;
};

export const PdfImages = (props: Props) => {
    // Memo to prevent rerender when closing medical report dialog
    const { storedAnalysis, ladResults, cxResults, rcaResults } = props;

    const renderCommonText = () => (
        <View>
            <Text style={styles.middleTextGrey}>
                {t('pdfImages.analysisNumber')}
                {storedAnalysis.id}
            </Text>
            <Text style={styles.middleTextGrey}>{t('pdfImages.doctor', { doctor: storedAnalysis.reader || '/' })}</Text>
            <Text style={styles.middleTextGrey}>{t('pdfImages.dateReport', { date: storedAnalysis.createdOn })}</Text>
            {(!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) &&
                storedAnalysis.label &&
                storedAnalysis.label.length > 0 && <Text style={styles.middleTextGrey}>{storedAnalysis.label}</Text>}
        </View>
    );

    const filterImage = (results: ResultsRads) => {
        if (!results.RadsAnalysisResponse) {
            return;
        }
        const mostRepresentativeImageIndex = results.RadsAnalysisResponse.bestRadsScoreImageIndex;
        if (results.Images[mostRepresentativeImageIndex] == null) {
            return <Text style={styles.probaTitle}>{t('pdfImages.noData')}</Text>;
        }

        let coronary = results.RadsAnalysisResponse.coronary;
        let coronaryText = '';
        if (coronary == Coronary.LAD) {
            coronaryText = t('pdfImages.nameLAD');
        }
        if (coronary == Coronary.Cx) {
            coronaryText = t('pdfImages.nameCX');
        }
        if (coronary == Coronary.RCA) {
            coronaryText = t('pdfImages.nameRCA');
        }

        // * ----------------- Structure for each coronary -----------------
        return (
            <>
                {/* ----------------- MPR Images ----------------- */}
                <View style={styles.rowTitleMpr}>
                    <Text>
                        {t('medicalReportForm.mprImages')} - {coronaryText}
                    </Text>
                </View>
                <View style={styles.smallImageContainer}>
                    {results.Images.map((file: File, index: number) => {
                        return (
                            <View key={index}>
                                {file?.base64 && (
                                    <Image
                                        style={styles.smallImage}
                                        src={file?.base64?.replace(
                                            'data:application/octet-stream;base64',
                                            'data:image/jpeg;base64'
                                        )}
                                    />
                                )}
                            </View>
                        );
                    })}
                </View>
            </>
        );
    };

    return (
        <Document title={t('pdfImages.documentTitle') + storedAnalysis.id} author={'Spimed-AI'}>
            {/* ------------------------- RCA Results part ------------------------- */}
            {rcaResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('pdfImages.nameRCA')} style={styles.page}>
                    {renderCommonText()}
                    <View style={styles.imageContainer}>{filterImage(rcaResults)}</View>
                </Page>
            )}

            {/* ------------------------- LAD Results part ------------------------- */}
            {ladResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('pdfImages.nameLAD')} style={styles.page}>
                    {renderCommonText()}
                    <View style={styles.imageContainer}>{filterImage(ladResults)}</View>
                </Page>
            )}

            {/* ------------------------- CX Results part ------------------------- */}
            {cxResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('pdfImages.nameCX')} style={styles.page}>
                    {renderCommonText()}
                    <View style={styles.imageContainer}>{filterImage(cxResults)}</View>
                </Page>
            )}
        </Document>
    );
};

export default PdfImages;

