import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import Typography from '../Typography';

type Props = {
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled: boolean;
};

export const ButtonOutlined = (props: Props) => {
    const { text, onClick, disabled } = props;

    return (
        <SButton onClick={onClick} disabled={disabled}>
            <STypography>{text}</STypography>
        </SButton>
    );
};

export default ButtonOutlined;

const SButton = styled(Button)`
    && {
        text-transform: none;
        background-color: ${(props) => props.theme.buttonActionOutlined.background};
        color: ${(props) => props.theme.buttonActionOutlined.text};
        filter: ${(props) => props.theme.buttonActionOutlined.filter};
        border: ${(props) => props.theme.buttonActionOutlined.border};
        padding: ${(props) => props.theme.getSpacing(1, 3)};
        border-radius: 10px;
        &:hover {
            background-color: ${(props) => props.theme.buttonActionOutlined.hover.background};
        }
        &&:disabled {
            color: ${(props) => props.theme.buttonActionOutlined.disabled.text};
            background-color: ${(props) => props.theme.buttonActionOutlined.disabled.background};
        }
    }
`;

const STypography = styled(Typography)`
    && {
        font-size: 1.1em;
    }
`;

