import { useState, useEffect } from 'react';

function useLocalStorage<T>(key: string, initialValue: T) {
    const stored = localStorage.getItem(key);

    const [value, setValue] = useState<T>(() => {
        try {
            // Parse stored json or if none return initialValue
            return stored ? JSON.parse(stored) : initialValue;
        } catch (error) {
            // If error also return initialValue
            return initialValue;
        }
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue] as const;
}

export function getDeepObjectFromLocalStorage<T>(key: string, subKey: string, initialValue: T) {
    const localStoredObject = localStorage.getItem(key);
    try {
        if (localStoredObject) {
            const tmp = JSON.parse(localStoredObject)[subKey];
            return tmp ? tmp : initialValue;
        }
        return initialValue;
    } catch (error) {
        // If error also return initialValue
        return initialValue;
    }
}
export default useLocalStorage;
