import React from 'react';
import styled from 'styled-components';
import { useScreenshotContext } from '@/utils/contexts/ScreenshotContext';
import { Typography } from '@/components/atoms';
import { ReportBugForm } from '@/components/organisms';
import { t } from '@/i18n.js';

import { Session } from '@/utils/contexts/SessionContext';

type Props = {
    currentSessionInCookie: Session;
};

export const ReportBug = (props: Props) => {
    const { currentSessionInCookie } = props;

    const [screenshotContext] = useScreenshotContext();

    return (
        <SPage>
            {screenshotContext.screenString != '' ? (
                <SContainer>
                    <Typography title="true" variant="h1">
                        {t('bug.title')}
                    </Typography>
                    <ReportBugForm currentSessionInCookie={currentSessionInCookie} />
                </SContainer>
            ) : (
                <SCenter>
                    <div>{t('bug.howToTakeScreen')}</div>
                </SCenter>
            )}
        </SPage>
    );
};

export default ReportBug;

const SPage = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.page.text};
    justify-content: center;
    align-items: center;
`;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: ${(props) => props.theme.getSpacing(10)};
    color: ${(props) => props.theme.page.text};
    background-color: ${(props) => props.theme.page.background};
    margin-top: ${(props) => props.theme.getSpacing(6)};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
`;

const SCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 100vw;
`;

