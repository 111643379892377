import React from 'react';
import { RouterLink } from '@/components/atoms';
import styled from 'styled-components';

type Props = {
    id: string;
};

export const UserBlock = (props: Props) => {
    const { id } = props;

    return (
        <SRouterLink to={'/admin/user/' + id}>
            <SDiv>
                <STextDivNew>{'N° ' + id}</STextDivNew>
            </SDiv>
        </SRouterLink>
    );
};
export default UserBlock;

const SDiv = styled.div`
    margin: 0;
    color: ${(props) => props.theme.page.text};
    border-spacing: 0;
    padding: 0;
    line-height: 15px;
    justify-content: left;
    width: 100%;
    border-radius: 10%;
    padding: 5px 0 5px 5px;
    &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

const SRouterLink = styled(RouterLink)`
    width: 100%;
    margin-top: 3px;
    margin-bottom: 3px;
`;

const STextDivNew = styled.div`
    margin-top: 6px;
    font-weight: bold;
`;

