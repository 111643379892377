import { useState } from 'react';

export type Operation = {
    /**Operation is still loading */
    isLoading: boolean;
    /**Operation is successfull */
    isSuccess: boolean;
    /**If an error occured */
    message?: string;
};

export const useSuccess = (
    operation?: Operation
): [boolean, boolean, string | undefined, (operation: Operation) => void] => {
    const init = operation ?? { isLoading: true, isSuccess: false };
    const [success, setLocalSuccess] = useState<Operation>(init);
    const setSuccess = (operation: Operation) => setLocalSuccess(operation);
    return [success.isLoading, success.isSuccess, success.message, setSuccess];
};

export default useSuccess;
