import { createContext } from 'react';
import React from 'react';
import { Doctors_List, IAuthenticateResponse } from '@api';
import { useContextFactory } from '@/utils/contexts/ContextFactory';
import Cookies from 'js-cookie';
import CryptoJs from 'crypto-js';
import jwt_decode from 'jwt-decode';
import { ModeConstant } from '../constants/deploymentModeSettings';

const mysecretkey = 'I1y61fB?6M1Iv3STtAP11A@4h2q7GqSi9o@nRh';

export interface Session {
    isAuthenticated?: boolean;
    authResponse: IAuthenticateResponse;
    dicomContainerEnumeration: string; // dicomContainerEnumeration come from JWT (listDicomContainerStoragestring), it is "" or "hospital-aetitle" or "hospital-aetitle1;hospital-aetitle2;" or a bigger list separated by ;
}

export interface DoctorEntity {
    id: number;
    name: string;
    mail: string;
}

export interface UserSettings {
    isFFRAvailable: boolean;
}

export const nullAuthResponse = {
    id: 0,
    email: '',
    firstName: '',
    containerName: '',
    lastName: '',
    token: '',
    refreshToken: '',
    isPasswordUpdateRequired: true,
    hasAcceptedTerms: false,
    isFFRAvailable: false,
    isExplicabilityAvailable: false,
    isLicenseExpired: true,
    isLicenseExpireSoon: false
};

export const nullDoctorEntity = {
    id: 0,
    name: '',
    mail: ''
};

export const initialSession: Session = {
    isAuthenticated: false,
    authResponse: nullAuthResponse,
    dicomContainerEnumeration: ''
};

export const isElectronApp = (): boolean => {
    return window.location.href.includes('file://');
};

export const SessionContext = createContext<
    [Session, (authResp: IAuthenticateResponse, docResp: Doctors_List[]) => Session, () => void]
>([initialSession, () => initialSession, () => {}]);

export const useSessionContext = useContextFactory('SessionContext', SessionContext);

export const cleanLocalStorage = (): void => {
    var i18nextLng = localStorage.getItem('i18nextLng');
    var version = localStorage.getItem('version');
    localStorage.clear();
    if (i18nextLng != null) {
        localStorage.setItem('i18nextLng', i18nextLng);
    }
    if (version != null) {
        localStorage.setItem('version', version);
    }
};

export const logoutFunc = (): void => {
    setInitialSessionCookie();

    if (isElectronApp()) {
        //@ts-ignore
        window.api.setHasSetCurrentDoctor(false);
    } else {
        Cookies.remove('hasSetCurrentDoctor');
    }
};

export const setSessionCookie = (session: Session): void => {
    var cipherSession = CryptoJs.AES.encrypt(JSON.stringify(session), mysecretkey).toString();

    if (isElectronApp()) {
        //@ts-ignore
        window.api.setAuthJWT(cipherSession);
    } else {
        Cookies.remove('session');

        if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
            Cookies.set('session', cipherSession, { expires: 7 });
        } else {
            // CLOUD
            Cookies.set('session', cipherSession, { expires: 7, secure: true, sameSite: 'strict' });
        }
    }
};

export const setInitialSessionCookie = (): void => {
    var cipherSession = CryptoJs.AES.encrypt(JSON.stringify(initialSession), mysecretkey).toString();
    if (isElectronApp()) {
        //@ts-ignore
        window.api.setAuthJWT(cipherSession);
    } else {
        Cookies.remove('session');
        if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
            Cookies.set('session', cipherSession, { expires: 7 });
        } else {
            // CLOUD
            Cookies.set('session', cipherSession, { expires: 7, secure: true, sameSite: 'strict' });
        }
    }
};

export const getSessionCookie: any = (session: Session) => {
    if (isElectronApp()) {
        //@ts-ignore
        const sessionCookie = window.api.getAuthJWTsync();
        if (sessionCookie === undefined || sessionCookie == '') {
            setInitialSessionCookie();
            return initialSession;
        } else {
            var bytes = CryptoJs.AES.decrypt(sessionCookie, mysecretkey);
            var decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
            return decryptedData;
        }
    } else {
        const sessionCookie = Cookies.get('session');
        if (sessionCookie === undefined) {
            setInitialSessionCookie();
            return initialSession;
        } else {
            var bytes = CryptoJs.AES.decrypt(sessionCookie, mysecretkey);
            var decryptedData = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
            return decryptedData;
        }
    }
};

export const SessionContextProvider: React.FC = (props) => {
    const [sessionInStorage, setSessionInStorage] = React.useState<Session>(initialSession);

    const login = (authResponse: IAuthenticateResponse, allDoctors: Doctors_List[]): Session => {
        const currentDoctor: DoctorEntity = {
            id: allDoctors[0].id,
            name: allDoctors[0].name,
            mail: allDoctors[0].email
        };

        localStorage.setItem('current_doctor', JSON.stringify(currentDoctor));

        let doctorsListToDoctorsEntities: DoctorEntity[] = allDoctors.map(
            (doctor) => ({ id: doctor.id, name: doctor.name, mail: doctor.email } as DoctorEntity)
        );
        localStorage.setItem('doctors_entity', JSON.stringify(doctorsListToDoctorsEntities));

        const dicomContainerEnumeration =
            JSON.parse(JSON.stringify(jwt_decode(authResponse.token.slice(7))))?.dicom_container_name ?? '';

        const session: Session = { isAuthenticated: true, authResponse, dicomContainerEnumeration };
        setSessionInStorage(session);
        setSessionCookie(session);

        localStorage.setItem('ffr_prediction_displayed_on_pdf', 'false');

        return session;
    };

    const logout = () => {
        setInitialSessionCookie();
        if (!isElectronApp()) {
            Cookies.remove('hasSetCurrentDoctor');
        }
        setSessionInStorage(initialSession);
    };

    const defaultSessionContext: [Session, typeof login, typeof logout] = [sessionInStorage, login, logout];
    return <SessionContext.Provider value={defaultSessionContext}>{props.children}</SessionContext.Provider>;
};

