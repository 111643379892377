import React, { useState, useEffect } from 'react';
import i18n, { t } from '@/i18n.js';

import { Dialog } from '@/components/molecules';
import { Language } from '@/utils/Language';
import styled from 'styled-components';
import { Typography } from '@/components/atoms';
import { Select, FormControl, MenuItem } from '@mui/material';

type Props = {
    /**Will open the New Version Dialog if true */
    isOpen: boolean;
    /**Function executed on close */
    onClose: () => void;
    userVersion: string;
    currentVersion: string;
};

const versions = [
    '2.0.0',
    '2.0.1',
    '2.0.2',
    '2.0.3',
    '2.0.4',
    '2.0.5',
    '2.0.6',
    '2.0.7',
    '2.0.8',
    '2.0.9',
    '2.0.10',
    '2.0.11',
    '2.0.12',
    '2.1.0',
    '2.1.1',
    '2.2.0',
    '2.3.0',
    '2.3.1',
    '2.3.2',
    '2.3.3'
];

const versionTextsEn = {
    '2.0.0': `
        [October 11th, 2022] 
        CorEx 2.0.0 is a major update with significant changes in CAD-RADS calculation and design.
    `,
    '2.0.1': `
        [October 12th, 2022]
        CorEx 2.0.1 is a bugfix on FFR display:
        - Coronary RADS with result from “RADS-0” to “RADS-2” must always display a FFR result equal to “FFR minus”
    `,
    '2.0.2': `
        [October 13th, 2022]
        CorEx 2.0.2 is a bugfix to fix the FFR placement on the 3D Heart representation.
    `,
    '2.0.3': `
        [October 13th, 2022]
        CorEx 2.0.3 is a bugfix for the Password Reset feature.
    `,
    '2.0.4': `
        [October 14th, 2022]
        CorEx 2.0.4 is a bugfix to display the CAD-RADS 2-3 on the Analysis page.
    `,
    '2.0.5': `
        [October 20th, 2022]
        CorEx 2.0.5 is a bugfix to handle the upload of 27 images at once on the Manual Upload page.
    `,
    '2.0.6': `
        [October 24th, 2022]
        CorEx 2.0.6 is a bugfix to display the version on the Label.
    `,
    '2.0.7': `
        [November 4th, 2022]
        CorEx 2.0.7 is a bugfix on alert emails sent after Dicom import:
        - If the RADS is RADS 3, RADS 3-4, RADS 4 or RADS 5, a “Critical Patient Alert” email is sent to your current doctor email
    `,
    '2.0.8': `
        [December 6th, 2022]
        CorEx 2.0.8 is a bugfix on FFR display on the PDF page:
        - Coronary RADS with result from “RADS-0” to “RADS-2” must always display a FFR result equal to “FFR minus”
    `,
    '2.0.9': `
        [December 16th, 2022]
        CorEx 2.0.9 contains multiple bugfixes:
        - Fix images ordering when sent via console.
        - Coronary RADS with result “RADS-5” must always display a FFR result equal to “FFR plus”
    `,
    '2.0.10': `
        [January 16th, 2023]
        CorEx 2.0.10 is a bugfix to display the images sent by console in the correct order.
    `,
    '2.0.11': `
        [June 1st, 2023]
        CorEx 2.0.11 is a bugfix to display FFR ≤ 0.8 and FFR > 0.8 instead of FFR < 0.75 and FFR > 0.75.
    `,
    '2.0.12': `
        [August 29th, 2023]
        CorEx 2.0.12 is the label update with the new SPIMED-AI's address and the product UDI.
    `,
    '2.1.0': `
        [October 25th, 2023]
        CorEx 2.1.0 is a major update containing multiple changes:
        - General interface design update
        - Update of the 'Analysis Details' page
        - Addition of the cardiac muscle to determine the FFR level (3D heart)
        - Addition of a 'dark' mode
    `,
    '2.1.1': `
        [November 13th, 2023]
        CorEx 2.1.1 contains multiple bugfixes:
        - One user account can receive DICOM images from multiple workstation
        - From Manual Upload page, improve the transmission of DICOM format files
    `,
    '2.2.0': `
        [February 13th, 2024]
        CorEx 2.2.0 is a minor update including:
        - Security fixes
        - Statistics page improvement
        - Redesign of PDF layout. FFR-AI score and AI confidence are removed from the PDF in the "Analysis Details" page
    `,
    '2.3.0': `
        [September 12th, 2024]
        CorEx 2.3.0 is a minor update including:
        - Text detection for anonymization
        - Improvement of the manual upload page to forbid duplicated files
        - Explainability feature more comprehensive
        - Convert the downloaded images to a PDF format
        - Integration with Incepto Marketplace
    `,
    '2.3.1': `
        [September 19th, 2024]
        CorEx 2.3.1 is a Label update.
    `,
    '2.3.2': `
        [October 1st, 2024]
        CorEx 2.3.2 is a Label update and a hotfix update for a retailer deployment.
    `,
    '2.3.3': `
        [October 30th, 2024]
        CorEx 2.3.3 is a hotfix to improve the compatibility with an external imaging software solution.
    `
};

const versionTextsFr = {
    '2.0.0': `
        [11 Octobre 2022]
        CorEx 2.0.0 est une mise à jour majeure avec des changements importants dans le calcul de CAD-RADS et le design.
        `,
    '2.0.1': `
        [12 Octobre 2022]
        CorEx 2.0.1 est une correction de bogue pour la FFR :
        - Les CAD-RADS dont le résultat est compris entre "RADS-0" et "RADS-2" doivent toujours afficher un résultat FFR égal à "FFR moins"
    `,
    '2.0.2': `
        [13 Octobre 2022]
        CorEx 2.0.2 est une correction de bogue pour corriger le placement de la FFR sur la représentation 3D du cœur.
    `,
    '2.0.3': `
        [13 Octobre 2022]
        CorEx 2.0.3 est une correction de bogue concernant la réinitialisation du mot de passe en cas de mot de passe perdu.
    `,
    '2.0.4': `
        [14 Octobre 2022]
        CorEx 2.0.4 est une correction de bogue concernant l'affichage des résultats CAD-RADS 2-3 pour la page d'analyse.
    `,
    '2.0.5': `
        [20 Octobre 2022]
        CorEx 2.0.5 est une correction de bogue pour gérer le téléchargement de 27 images à la fois sur la page d'Ajout manuel d'images.
    `,
    '2.0.6': `
        [24 Octobre 2022]
        CorEx 2.0.6 est une correction de bogue pour mettre à jour le numéro de version de l'étiquette.
    `,
    '2.0.7': `
        [4 Novembre 2022]
        CorEx 2.0.7 est une correction de bogue sur les emails d'alerte envoyés après l'envoi d'images via Dicom :
        - Si le résult CAD-RADS est RADS 3, RADS 3-4, RADS 4 ou RADS 5, un email "Alerte patient critique" est envoyé à l'adresse email du docteur sélectionné dans l'interface
    `,
    '2.0.8': `
        [6 Décembre 2022]
        CorEx 2.0.8 est une correction de bogue sur l'affichage de la FFR sur la page PDF :
        - Les CAD-RADS dont le résultat est compris entre "RADS-0" et "RADS-2" doivent toujours afficher un résultat FFR égal à "FFR moins"
    `,
    '2.0.9': `
        [16 Décembre 2022]
        CorEx 2.0.9 contient plusieurs corrections de bogues :
        - Correction de l'ordre des images lorsqu'elles sont envoyées via la console
        - Le CAD-RADS avec le résultat "RADS-5" doit toujours afficher un résultat FFR égal à "FFR plus"
    `,
    '2.0.10': `
        [16 Janvier 2023]
        CorEx 2.0.10 est une correction de bogue qui permet d'afficher, dans le bon ordre, les images envoyées via console.
    `,
    '2.0.11': `
        [1 Juin 2023]
        CorEx 2.0.11 est une correction de bogue qui permet d'afficher FFR ≤ 0.8 et FFR > 0.8 au lieu de FFR < 0.75 et FFR > 0.75.
    `,
    '2.0.12': `
        [29 Août 2023]
        CorEx 2.0.12 est une mise à jour de l'étiquette contenant la nouvelle adresse de SPIMED-AI et l'UDI du produit.
    `,
    '2.1.0': `
        [25 Octobre 2023]
        CorEx 2.1.0 est une mise à jour majeure impliquant plusieurs changements :
        - Mise à jour de l’interface générale
        - Mise à jour de la page “Résultat d'analyse”
        - Ajout du muscle cardiaque pour déterminer le niveau de FFR (cœur 3D)
        - Ajout d'un mode "sombre"
    `,
    '2.1.1': `
        [13 Novembre 2023]
        CorEx 2.1.1 contient plusieurs corrections de bogues :
        - Un compte utilisateur peut recevoir des images DICOM depuis plusieurs postes d'envoi de scanner
        - Amélioration de l'envoi de fichiers en format DICOM depuis la page "Ajouter des images"
    `,
    '2.2.0': `
        [13 Février 2024]
        CorEx 2.2.0 est une mise à jour comprenant :
        - Une sécurité renforcée
        - Une amélioration de la page de Statistiques
        - Un nouveau design du PDF. La prédiction FFR et la confiance de l'IA dans le score ne sont plus visibles dans le PDF de la page "Résultat d'analyse"
    `,
    '2.3.0': `
        [12 Septembre 2024]
        CorEx 2.3.0 est une mise à jour comprenant :
        - La détection de texte pour l'anonymisation
        - La modification de la page de téléchargement manuel pour interdire les fichiers dupliqués
        - L'amélioration de la Fonctionnalité d'explicabilité pour la rendre plus exhaustive
        - La conversion des images téléchargées au format PDF
        - L'intégration avec la plateforme Incepto`,
    '2.3.1': `
        [19 Septembre 2024]
        CorEx 2.3.1 est une mise à jour de l'étiquette.`,
    '2.3.2': `
        [1 Octobre 2024]
        CorEx 2.3.2 est une mise à jour de l'étiquette et une correction de bogue pour le déploiement avec un parternaire.`,
    '2.3.3': `
        [30 Octobre 2024]
        CorEx 2.3.3 est une amélioration de la compatibilité avec un logiciel d'imagerie externe.
    `
};

/**
 * Return true if version1 is more recent than version2 (ex : 2.2.0 is more recent than 2.0.4).
 * Also return true if version1 == version2.
 */
function isMoreRecent(version1: string, version2: string) {
    if (version1 === version2) {
        return true;
    }

    const parts1 = version1.split('.').map((x) => parseInt(x, 10));
    const parts2 = version2.split('.').map((x) => parseInt(x, 10));

    for (let i = 0; i < 3; i++) {
        if (parts1[i] > parts2[i]) {
            return true;
        } else if (parts1[i] < parts2[i]) {
            return false;
        }
    }
    return false;
}

// Example: getVersionsBetween(versions, '1.1.0', '1.3.0') = ['1.2.0', '1.3.0']
function getVersionsBetween(versions: Array<string>, startVersion: string, endVersion: string) {
    const startIndex = versions.findIndex((v) => isMoreRecent(v, startVersion));
    const endIndex = versions.findIndex((v) => isMoreRecent(v, endVersion));

    if (startVersion == endVersion) {
        return versions.slice(endIndex, endIndex + 1);
    }

    if (startVersion == '' && endIndex !== -1) {
        return versions.slice(0, endIndex + 1);
    }
    // startVersion is not in versions
    if (startIndex === -1 || startIndex == 0) {
        return [];
    }
    // endVersion is not in versions: display from startVersion to the last version available
    if (endIndex === -1) {
        return versions.slice(startIndex + 1, versions.length);
    }

    return versions.slice(startIndex + 1, endIndex + 1);
}

function getTextsBetween(
    versions: string[],
    startVersion: string,
    endVersion: string,
    versionTexts: { [key: string]: string }
) {
    const versionsBetween = getVersionsBetween(versions, startVersion, endVersion);
    return versionsBetween.map((version) => versionTexts[version]);
}

function getTextForVersion(version: string, versionTexts: { [key: string]: string }) {
    return versionTexts[version] || '';
}

export const IsNewVersionDialog = (props: Props) => {
    const { isOpen, onClose, userVersion, currentVersion } = props;

    if (!isOpen) {
        return null;
    }

    const [displayedText, setDisplayedText] = useState<string[]>(['']);

    function setText(lang: string) {
        const versionText = lang === Language.French ? versionTextsFr : versionTextsEn;
        var text = getTextsBetween(versions, userVersion, currentVersion, versionText);
        setDisplayedText(text);
    }

    function setTextForOneVersion(lang: string) {
        const versionText = lang === Language.French ? versionTextsFr : versionTextsEn;
        var text = new Array(getTextForVersion(currentVersion, versionText));
        setDisplayedText(text);
    }

    const handleChange = (event: { target: { value: string } }) => {
        i18n.changeLanguage(event.target.value);
    };

    useEffect(() => {
        // IsNewVersionDialog is called in Login page : this is ensuring the functions are not called if the component is not used
        if (currentVersion == null || currentVersion == '') {
            return;
        }
        if (userVersion == '') {
            // If user did not set his version, display the version text corresponding to the logiciel version
            setTextForOneVersion(i18n.language);
            return;
        }
        // Display all the versions texts between user version and current version
        setText(i18n.language);
    }, [userVersion, currentVersion, i18n.language]);

    return (
        <Dialog
            open
            customTitle={t('isNewVersionDialog.title') + currentVersion}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
            scroll={'body'}
            width={'75%'}
            childrenButton={t('isNewVersionDialog.close')}
        >
            <SFormControl variant="standard">
                <Select labelId="language" id="language" value={i18n.language} onChange={handleChange}>
                    <MenuItem key={Language.French} value={Language.French}>
                        <Typography>Français</Typography>
                    </MenuItem>
                    <MenuItem key={Language.English} value={Language.English}>
                        <Typography>English</Typography>
                    </MenuItem>
                </Select>
            </SFormControl>
            {displayedText
                .map((element, index) => {
                    return <SParagraph key={index}>{element}</SParagraph>;
                })
                .reverse()}
            {userVersion != '' ? (
                <SParagraph>{t('isNewVersionDialog.currentUserVersion') + userVersion}.</SParagraph>
            ) : (
                <></>
            )}
        </Dialog>
    );
};

const SParagraph = styled.p`
    white-space: pre-line;
    word-wrap: break-word;
`;

export default IsNewVersionDialog;

const SFormControl = styled(FormControl)`
    & .MuiFormLabel-root {
        background-color: ${(props) => props.theme.analysisDetails.cardBackground};
        color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.label};
        font-family: 'Inter', sans-serif;
        padding-right: 5px;
    }
    & .MuiInputBase-root {
        color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.inputText};
        & label {
            color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &:hover fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.MuiInput-input {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & .MuiSelect-icon {
            color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
    }
`;

