import React from 'react';
import styled from 'styled-components';
import { t } from '@/i18n.js';
import ErrorIcon from '@mui/icons-material/Error';

import { Typography } from '@/components/atoms';

type Props = {
    /**Message under Something went wrong.. */
    message: string;
};

export const ErrorMessage = ({ message }: Props) => {
    return (
        <SMiddleCenter>
            <ErrorIcon />
            <Typography variant="h3">{t('somethingWentWrong')}</Typography>
            <Typography variant="h3">{message}</Typography>
        </SMiddleCenter>
    );
};

export default ErrorMessage;

const SMiddleCenter = styled.div`
    text-align: center;
    margin: auto;
    width: 50%;
    margin-bottom: ${(props) => props.theme.getSpacing(8)};
    margin-top: ${(props) => props.theme.getSpacing(60)};
`;
