import React, { useEffect } from 'react';
import { t } from '@/i18n.js';
import { Select, Checkbox, OutlinedInput, MenuItem, SelectChangeEvent, FormControl, InputLabel } from '@mui/material';

import styled from 'styled-components';

import { Typography } from '@/components/atoms';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';
import { DoctorEntity } from '@/utils/contexts/SessionContext';

type Props = {
    doctorsFilter: string[];
    setDoctorsFilter: React.Dispatch<React.SetStateAction<string[]>>;
};

export const FilterDoctor = (props: Props) => {
    const { doctorsFilter, setDoctorsFilter } = props;
    const [themeContext, updateTheme] = useThemeContext();

    useEffect(() => {
        localStorage.setItem('doctorsFilter', JSON.stringify(doctorsFilter));
    }, [doctorsFilter]);

    const handleChange = (event: SelectChangeEvent<typeof doctorsFilter>) => {
        const {
            target: { value }
        } = event;
        setDoctorsFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(' - ') : value
        );
    };

    const doctorsEntityString = localStorage.getItem('doctors_entity');
    const doctorsEntity = doctorsEntityString
        ? (JSON.parse(doctorsEntityString) as DoctorEntity[])
        : ([] as DoctorEntity[]);

    return (
        <SFormControlDiv>
            <SFormControl sx={{ width: '80%' }} size="small">
                <InputLabel>{t('analysisExplorer.analysisTable.reader')}</InputLabel>
                <Select
                    multiple
                    fullWidth
                    inputProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: {
                                    color: themeContext.isLightMode
                                        ? lightTheme.table.select.text
                                        : darkTheme.table.select.text,
                                    backgroundColor: themeContext.isLightMode
                                        ? lightTheme.table.select.list.background
                                        : darkTheme.table.select.list.background
                                }
                            }
                        }
                    }}
                    value={doctorsFilter}
                    onChange={handleChange}
                    label={'Reader filter'}
                    input={<OutlinedInput label="Reader filter" />}
                    renderValue={(selected) => (
                        <>
                            {selected.length > 1 ? (
                                <STypography>
                                    {t('analysisExplorer.analysisTable.readerSelected', { number: selected.length })}
                                </STypography>
                            ) : (
                                <STypography>{selected.toLocaleString()}</STypography>
                            )}
                        </>
                    )}
                >
                    {doctorsEntity.map((item, index) => (
                        <MenuItem key={index} value={item.name}>
                            <Checkbox checked={doctorsFilter.indexOf(item.name) > -1} />
                            <Typography>{item.name}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </SFormControl>
        </SFormControlDiv>
    );
};

export default FilterDoctor;

const SFormControlDiv = styled.div`
    width: 140px;
    margin-right: 7px;
`;
const STypography = styled(Typography)`
    color: ${(props) => props.theme.table.select.text};
    font-size: 15px !important;
`;

const SFormControl = styled(FormControl)`
    left: 1%;
    padding-left: ${(props) => props.theme.getSpacing(1)};
    &.MuiFormControl-root {
        width: 95%;
        min-width: 140px;
        border-radius: 10px;
    }
    & .MuiOutlinedInput-root {
        background-color: ${(props) => props.theme.table.select.background};
        border-radius: 10px;
        & input {
            color: ${(props) => props.theme.table.select.text};
        }
        & fieldset {
            border-color: ${(props) => props.theme.table.select.borderColor};
            border-radius: 10px;
        }
        &:hover fieldset {
            border-color: ${(props) => props.theme.table.select.borderColorHover};
        }
        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.table.select.borderColorHover};
        }
        &.Mui-select select {
            border-color: ${(props) => props.theme.table.select.borderColorHover};
        }
        &::selection fieldset {
            border-color: ${(props) => props.theme.table.select.borderColorHover};
        }
    }
    & label {
        color: ${(props) => props.theme.table.select.text};
    }
    & label.Mui-focused {
        color: blue;
    }
    & .MuiSvgIcon-root {
        fill: ${(props) => props.theme.table.select.iconFill};
    }
    & .MuiFormLabel-root {
        color: ${(props) => props.theme.table.select.text};
    }
    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        color: ${(props) => props.theme.colors.blue};
    }
`;

