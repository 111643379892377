import React from 'react';
import { t } from '@/i18n.js';

import { ButtonBlueWithIcon } from '@/components/atoms';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { useHistory } from 'react-router';

export const RedirectPDF = () => {
    const history = useHistory();

    /** Téléchargement de l'analyse */
    const handleRedirect = async () => {
        history.push('/stored-analysis/' + window.location.href.split('/')[window.location.href.split('/').length - 1]);
    };

    return (
        <>
            <ButtonBlueWithIcon
                icon={<PictureAsPdfIcon />}
                text={t('pdfCreation.redirectPDF')}
                onClick={handleRedirect}
                disabled={false}
            />
        </>
    );
};

export default RedirectPDF;

