import Footer from '@/components/organisms/Footer';
import navBarLeft from '@/components/organisms/navBarLeft';
import navBarRight from '@/components/organisms/navBarRight';
import PasswordModification from '@/components/organisms/PasswordModification';
import PasswordReset from '@/components/organisms/PasswordReset';
import PasswordChangeDialog from '@/components/organisms/dialogs/PasswordChangeDialog';
import ForgotPasswordComp from '@/components/organisms/ForgotPasswordComp';
import MedicalReportCreationForm from '@/components/organisms/MedicalReportCreationForm';
import TermsDialog from '@/components/organisms/dialogs/TermsDialog';
import IsNewVersionDialog from './dialogs/IsNewVersionDialog';
import AnalysisExplorerComponent from '@/components/organisms/AnalysisExplorerComponent';
import ConclusionRadsMedicalReport from '@/components/organisms/ConclusionRadsMedicalReport';
import Heart3D from '@/components/organisms/Heart3D';
import AnalysisReportCompImages from '@/components/organisms/AnalysisReportCompImages';
import AnalysisReportCompDetails from '@/components/organisms/AnalysisReportCompDetails';
import AnalysisReportImagesList from '@/components/organisms/AnalysisReportImagesList';
import AnalysisTableMini from '@/components/organisms/AnalysisTableMini';
import ReportBugForm from '@/components/organisms/ReportBugForm';
import AdminUserForm from '@/components/organisms/AdminUserForm';
import AdminStatisticsHome from '@/components/organisms/AdminStatisticsHome';

export {
    Footer,
    navBarLeft,
    navBarRight,
    PasswordModification,
    PasswordReset,
    PasswordChangeDialog,
    ForgotPasswordComp,
    MedicalReportCreationForm,
    TermsDialog,
    IsNewVersionDialog,
    AnalysisExplorerComponent,
    ConclusionRadsMedicalReport,
    Heart3D,
    AnalysisReportCompImages,
    AnalysisReportCompDetails,
    AnalysisReportImagesList,
    AnalysisTableMini,
    ReportBugForm,
    AdminUserForm,
    AdminStatisticsHome
};

