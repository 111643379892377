import React from 'react';

import { Typography } from '@/components/atoms';
import { Dialog } from '@/components/molecules';
import { t } from '@/i18n.js';

type Props = {
    /**Will open the 'License is Expired' Dialog if true */
    isOpen: boolean;
    /**Function executed on close */
    onClose: () => void;
};

export const LicenseExpiredDialog = (props: Props) => {
    const { isOpen, onClose } = props;

    return (
        <Dialog
            open={isOpen}
            customTitle={'License Expired'}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
            width={'40%'}
            childrenButton={<Typography variant="button">Close</Typography>}
        >
            <>
                <Typography title variant="h3">
                    {t('license.expired')}
                </Typography>
            </>
        </Dialog>
    );
};

export default LicenseExpiredDialog;

