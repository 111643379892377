import React from 'react';
import { Typography as DefaultMaterialTypography } from '@mui/material';
import styled from 'styled-components';

export const Typography = styled(
    ({
        red,
        blue,
        green,
        orange,
        darkblue,
        electricblue,
        darkgrey,
        black,
        white,
        lightgrey,
        yellow,
        darkred,
        strongdarkred,
        chosenColor,
        title,
        paragraph,
        thinparagraph,
        bold,
        lightbold,
        thin,
        size6,
        size2,
        small,
        ...otherProps
    }) => <DefaultMaterialTypography {...otherProps} />
)`
    && {
        ${(props) =>
            props.titlelogin &&
            `
        margin-top: ${props.theme.getSpacing(3)};
        padding-bottom: ${props.theme.getSpacing(3)};
        font-size: 8vh;
        `}
        ${(props) =>
            props.title &&
            `
        padding-bottom: ${props.theme.getSpacing(1)};
        `}
        ${(props) =>
            props.subtitle &&
            `
            margin-top: ${props.theme.getSpacing(3)};
            padding-bottom: ${props.theme.getSpacing(3)};
            font-size: 4vh;
            font-weight: 400;

        `}
        ${(props) =>
            props.subtitlelogin &&
            `
        margin-top: ${props.theme.getSpacing(3)};
        padding-bottom: ${props.theme.getSpacing(3)};
        font-size: 4vh;
        font-weight: 400;
        `}
        ${(props) =>
            props.bold &&
            `
        font-weight: 700;
        `}
        ${(props) =>
            props.lightbold &&
            `
        font-weight: 500;
        `}
        ${(props) =>
            props.thin &&
            `
        font-weight: 300;
        `}
        ${(props) =>
            props.paragraph &&
            `
        margin-top: ${props.theme.getSpacing(8)};
        margin-bottom: ${props.theme.getSpacing(4)};
        `}
        ${(props) =>
            props.thinparagraph &&
            `
        margin-top: ${props.theme.getSpacing(4)};
        margin-bottom: ${props.theme.getSpacing(2)};
        `}
        ${(props) =>
            props.size6 &&
            `
        font-size: 6vh;
        `}
        ${(props) =>
            props.size2 &&
            `
        font-size: 2vh;
        `}
        ${(props) =>
            props.small &&
            `
        font-size: 1.3vh;
        `}
        ${(props) =>
            props.red &&
            `
        color: ${props.theme.colors.red};
        `}
        ${(props) =>
            props.blue &&
            `
        color: ${props.theme.colors.blue};
        `}
                ${(props) =>
            props.orange &&
            `
        color: ${props.theme.colors.orange};
        `}
                ${(props) =>
            props.green &&
            `
        color: ${props.theme.colors.green};
        `}
                ${(props) =>
            props.darkblue &&
            `
        color: ${props.theme.colors.darkblue};
        `}
                ${(props) =>
            props.electricblue &&
            `
        color: ${props.theme.colors.electricblue};
        `}
                ${(props) =>
            props.darkgrey &&
            `
        color: ${props.theme.colors.darkgrey};
        `}
        ${(props) =>
            props.black &&
            `
        color: black;
        `}
        ${(props) =>
            props.white &&
            `
        color: white;
        `}
        ${(props) =>
            props.lightgrey &&
            `
        color: ${props.theme.colors.lightgrey};
        `}
        ${(props) =>
            props.strongdarkred &&
            `
        color: ${props.theme.colors.strongdarkred};
        `}
        ${(props) =>
            props.yellow &&
            `
        color: ${props.theme.colors.yellow};
        `}
        ${(props) =>
            props.darkred &&
            `
        color: ${props.theme.colors.darkred};
        `}
        ${(props) =>
            props.chosenColor &&
            `
        color: ${props.chosenColor};
        `}
    }
`;

export default Typography;

