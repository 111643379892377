import React, { Dispatch, useState, SetStateAction, forwardRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import i18n, { t } from '@/i18n.js';
import DatePicker from 'react-datepicker';

import { Language } from '@/utils/Language';
import { FilterButton, Typography } from '@/components/atoms';

// Icons
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeIcon from '@mui/icons-material/DateRange';

type Props = {
    startDate: null | Date;
    setStartDate: Dispatch<SetStateAction<null | Date>>;
    endDate: null | Date;
    setEndDate: Dispatch<SetStateAction<null | Date>>;
};

type CustomProps = React.HTMLProps<HTMLButtonElement>;

export const FilterDate = (props: Props) => {
    const { startDate, setStartDate, endDate, setEndDate } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const CustomInput = forwardRef<HTMLButtonElement, CustomProps>(({ value, onClick }, ref) => (
        <FilterButton onClick={onClick} selected={startDate !== null && endDate !== null}>
            <DateRangeIcon />
        </FilterButton>
    ));

    return (
        <>
            {startDate !== null && (
                <>
                    <SCloseDiv
                        onClick={() => {
                            setStartDate(null);
                            setEndDate(null);
                        }}
                    >
                        <ClearIcon />
                    </SCloseDiv>
                    <STextDiv>
                        <Typography>
                            {t('dateTimeShort', { date: startDate }) + ' - ' + t('dateTimeShort', { date: endDate })}
                        </Typography>
                    </STextDiv>
                </>
            )}
            <SZindex>
                <DatePicker
                    dateFormat={(i18n.language as Language) === Language.French ? 'dd/MM/yyyy' : 'MM/dd/yyyy'} // Modifie le format de la date en fonction de la langue
                    selected={startDate}
                    onChange={(date) => {
                        setStartDate(date![0]);
                        setEndDate(date![1]);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange // Sélectionner l'intervalle de date
                    placeholderText={t('analysisExplorer.analysisTable.dateFilter')}
                    onClickOutside={() => setIsOpen(false)}
                    customInput={<CustomInput />}
                    wrapperClassName="date_picker full-width red"
                />
                <DatePickerWrapperStyles />
            </SZindex>
        </>
    );
};

export default FilterDate;

const SZindex = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 1000;
    margin-right: 2px;
`;

const STextDiv = styled.div`
    display: flex;
    align-items: center;
    user-select: none;
    border-radius: 5px;
    color: ${(props) => props.theme.table.select.text};
    padding: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
`;

const SCloseDiv = styled.div`
    display: flex;
    align-items: center;
    border-radius: 50%;
    color: ${(props) => props.theme.colors.darkgrey};
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
    background-color: ${(props) => props.theme.colors.grey};
    transition: 0.5s background-color;
    &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.lightgrey};
        background-color: ${(props) => props.theme.colors.darkgrey};
    }
`;

const DatePickerWrapperStyles = createGlobalStyle`
    .date_picker.full-width {
        width: 100%;
    }
`;

