import React from 'react';
import { Button as DefaultMaterialButton } from '@mui/material/';
import styled, { css } from 'styled-components';

export const roundButton = styled(({ ...otherProps }) => <DefaultMaterialButton {...otherProps} />)`
    && {
        border-radius: 50%;

        ${(propsCustom) => {
            switch (propsCustom.$mode) {
                case true:
                    return css`
                        box-shadow: 5px 5px 10px #e0e0e0, -5px -5px 10px #ffffff;
                        background: ${(props) => props.theme.colors.white} linear-gradient(145deg, #e6e6e6, #ffffff);
                    `;
                default:
                    return css`
                        box-shadow: 5px 5px 10px #ebebeb, -5px -5px 10px #ffffff;
                        background-color: ${(props) => props.theme.app.buttonBackground};
                    `;
            }
        }}
        box-shadow: unset;
        background-color: ${(props) => props.theme.colors.white};
        &:hover {
            box-shadow: unset;
            background-color: ${(props) => props.theme.app.buttonBackgroundHover};
        }

        border: 1px solid transparent;
        color: ${(props) => props.theme.colors.blue};
        margin: ${(props) => props.theme.getSpacing(0, 4, 0, 0)};
    }
`;

export default roundButton;

