import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import i18n from '@/i18n.js';

import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { ButtonBlue, ButtonBlueWithIcon, CircularProgress } from '@/components/atoms';
import GetAppIcon from '@mui/icons-material/GetApp';

import { File } from '@/components/molecules';

import { filterImages } from '@/utils/FileOrdering';
import useDebounce from '@/utils/hooks/useDebounce';
import { Session } from '@/utils/contexts/SessionContext';

import PdfDocumentNoMedicalProps from '@/pdf/rads/PdfDocumentNoMedicalProps';
import { initialMedicalPropsValue, Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';
import { initialResultRads, ResultsRads } from '@/utils/ResultsRads';

import { DownloadImagesFromBackWithToken } from '@/services/ImageService';
import { useTranslation } from 'react-i18next';

import {
    Classification,
    ClassificationResult,
    Coronary,
    ImageOrderInStorage,
    IStoredAnalysis,
    StoredAnalysisClient
} from '@api';

import SpimedLogo from '@/img/spimed_logo.png';

import { translateAllMedicalPropsAndGetSelectBoundaries } from '@/utils/MedicalReportFormTranslation';
import { PDFDownloadLink } from '@react-pdf/renderer';

type DownloadAnalysisRoute = {
    /**URL is passed as an URL parameters : ID and Token*/
    url: string;
};

export const initialStoredAnalysis: IStoredAnalysis = {
    id: 0,
    label: '',
    classificationResult: ClassificationResult.Error,
    classification: Classification.SixClasses,
    imageOrderInStorage: ImageOrderInStorage.LadCxRca,
    analysisGuid: '',
    note: '',
    highLighted: false,
    reader: '',
    memo: '',
    isFromPacs: false,
    isExplicabilityComputed: false,
    hasBeenOpened: false,
    token: ''
};

type Props = {
    currentSessionInCookie: Session;
};

export const DownloadAnalysis = (props: Props) => {
    const { t } = useTranslation(); // Hook useTranslation on Pdf so the locales are updated in the front

    const { currentSessionInCookie } = props;
    const history = useHistory();
    const [snackbarContext, updateSnackbar] = useSnackbarContext();

    const { url } = useParams<DownloadAnalysisRoute>();

    const [isLoading, setIsLoading] = useState(true);
    const [isDownloadLoading, setIsDownloadLoading] = useState(true);
    const [pdfAvailable, setPdfAvailable] = useState(true);
    const [downloadDone, setDownloadDone] = useState(false);
    const [isFailure, setIsFailure] = useState(false);

    const [ladResults, setLAD] = useState<ResultsRads>(initialResultRads);
    const [cxResults, setCX] = useState<ResultsRads>(initialResultRads);
    const [rcaResults, setRCA] = useState<ResultsRads>(initialResultRads);

    const [files3D, setFiles3D] = useState<File[]>([]);

    const [storedAnalysis, setStoredAnalysis] = useState<IStoredAnalysis>(initialStoredAnalysis);
    const [patientName, setPatientName] = useState('');
    const patientNameDebounced = useDebounce(patientName, 500);

    const [medicalProps, setMedicalProps] = useState<MedicalProps>(initialMedicalPropsValue);
    const [medicalReportId, setMedicalReportId] = useState<number>(0);
    const [displayMedicalReportInPdf, setDisplayMedicalReportInPdf] = useState<boolean>(false);

    const [token, setToken] = useState<string>('');
    const [id, setId] = useState<number>(0);
    const [urlValid, setUrlValid] = useState<boolean>(false);

    useEffect(() => {
        //URL
        const urlArray = url.split('+');
        setId(parseInt(urlArray[0]));
        setToken(urlArray[1]);
        setUrlValid(true);
    }, [url]);

    useEffect(() => {
        (async function iifeFunction() {
            if (id !== 0) {
                try {
                    setIsLoading(true);
                    const client = new StoredAnalysisClient({ token: '' }, process.env.BackendServiceURL);

                    const resultsStoredDetails = await client.getDetailDownload(token);
                    if (resultsStoredDetails === null) {
                        setIsFailure(true);
                        setDownloadDone(true);
                    } else {
                        // On compare si le token est disponible et valable
                        if (resultsStoredDetails.storedAnalysis.token !== token) {
                            setIsFailure(true);
                            setDownloadDone(true);
                        } else {
                            const currentStoredAnalysis = resultsStoredDetails.storedAnalysis;
                            setStoredAnalysis(currentStoredAnalysis);
                            const results = resultsStoredDetails.radsAnalysisResponsesList;

                            const imageService = new DownloadImagesFromBackWithToken(
                                { token: '' },
                                process.env.BackendServiceURL
                            );
                            const downloadedF = await imageService
                                .downloadAllBlobsWithAnalysisToken({
                                    analysisId: currentStoredAnalysis.id,
                                    analysisToken: token
                                })
                                .catch((err) => {
                                    history.push('/home');
                                    updateSnackbar({
                                        snackString: t('snackbar.error.analysis.server'),
                                        severity: 'error'
                                    });
                                });
                            var downloadedFiles = downloadedF as File[];
                            const allFiles: File[] = await FilterBlobsByCoronary(
                                Coronary.NotSpecified,
                                downloadedFiles,
                                currentStoredAnalysis.isFromPacs
                            );
                            const ladFiles: File[] = await FilterBlobsByCoronary(
                                Coronary.LAD,
                                downloadedFiles,
                                currentStoredAnalysis.isFromPacs
                            );
                            const cxFiles: File[] = await FilterBlobsByCoronary(
                                Coronary.Cx,
                                downloadedFiles,
                                currentStoredAnalysis.isFromPacs
                            );
                            const rcaFiles: File[] = await FilterBlobsByCoronary(
                                Coronary.RCA,
                                downloadedFiles,
                                currentStoredAnalysis.isFromPacs
                            );

                            const is27 = allFiles.length > 0;
                            let indexLad: number = -1;
                            let indexCx: number = -1;
                            let indexRca: number = -1;
                            let indexForImagesLad: number = -1;
                            let indexForImagesCx: number = -1;
                            let indexForImagesRca: number = -1;
                            if (is27) {
                                const { ladI, cxI, rcaI } = getIndexesFromStoredAnalysis(
                                    currentStoredAnalysis.imageOrderInStorage
                                );
                                indexForImagesLad = ladI;
                                indexForImagesCx = cxI;
                                indexForImagesRca = rcaI;
                            }
                            indexLad = results.findIndex((pred) => pred.coronary === Coronary.LAD);
                            indexCx = results.findIndex((pred) => pred.coronary === Coronary.Cx);
                            indexRca = results.findIndex((pred) => pred.coronary === Coronary.RCA);

                            setLAD((lad) => ({
                                ...lad,
                                isLoading: false,
                                Images: is27
                                    ? indexForImagesLad !== -1
                                        ? filterImages(allFiles, ladFiles, is27, indexForImagesLad)
                                        : []
                                    : indexLad !== -1
                                    ? filterImages(allFiles, ladFiles, is27, indexLad)
                                    : [],
                                RadsAnalysisResponse:
                                    indexLad !== -1 ? results[indexLad] : initialResultRads.RadsAnalysisResponse
                            }));

                            setCX((cx) => ({
                                ...cx,
                                isLoading: false,
                                Images: is27
                                    ? indexForImagesCx !== -1
                                        ? filterImages(allFiles, cxFiles, is27, indexForImagesCx)
                                        : []
                                    : indexCx !== -1
                                    ? filterImages(allFiles, cxFiles, is27, indexCx)
                                    : [],
                                RadsAnalysisResponse:
                                    indexCx !== -1 ? results[indexCx] : initialResultRads.RadsAnalysisResponse
                            }));

                            setRCA((rca) => ({
                                ...rca,
                                isLoading: false,
                                Images: is27
                                    ? indexForImagesRca !== -1
                                        ? filterImages(allFiles, rcaFiles, is27, indexForImagesRca)
                                        : []
                                    : indexRca !== -1
                                    ? filterImages(allFiles, rcaFiles, is27, indexRca)
                                    : [],
                                RadsAnalysisResponse:
                                    indexRca !== -1 ? results[indexRca] : initialResultRads.RadsAnalysisResponse
                            }));

                            setFiles3D(allFiles.filter((file) => file.fileName.includes('3D')));

                            const medicalReportResult = resultsStoredDetails.medicalReport;
                            if (medicalReportResult.id != undefined) {
                                if (medicalReportResult.displayInPdf) {
                                    let medicalPropsTranslatedAndSelectBoundaries =
                                        translateAllMedicalPropsAndGetSelectBoundaries({
                                            doctorName: medicalReportResult.doctor,
                                            technique: medicalReportResult.techniqueName || '',
                                            contrastQuantity:
                                                medicalReportResult.techniqueContrastAgentQuantityML || undefined,
                                            dlp: medicalReportResult.techniqueDlpMgyCm || undefined,
                                            indication: medicalReportResult.indication || '',
                                            ladStenosis: medicalReportResult.lad || '',
                                            cxStenosis: medicalReportResult.cx || '',
                                            rcaStenosis: medicalReportResult.rca || '',
                                            conclusionCadRads: medicalReportResult.conclusion || '',
                                            classificationResult:
                                                medicalReportResult.classificationResult || ClassificationResult.NotSet,
                                            commentaries: medicalReportResult.comments || '',
                                            cavite: medicalReportResult.cardiacCavities || '',
                                            mitrale: medicalReportResult.mitralValve || '',
                                            aortique: medicalReportResult.aorticValve || '',
                                            racine: medicalReportResult.aorticRoot || '',
                                            pericarde: medicalReportResult.pericardium || '',
                                            ladCalcification: medicalReportResult.ladCalcification || '',
                                            cxCalcification: medicalReportResult.cxCalcification || '',
                                            rcaCalcification: medicalReportResult.rcaCalcification || ''
                                        });
                                    let medicalPropsTranslated =
                                        medicalPropsTranslatedAndSelectBoundaries.medicalPropsTranslated;
                                    setMedicalProps(medicalPropsTranslated);
                                    setMedicalReportId(medicalReportResult.id);
                                }
                                setDisplayMedicalReportInPdf(medicalReportResult.displayInPdf);
                            }

                            const timer = setTimeout(() => {
                                setIsLoading(false);
                            }, 5000);

                            return () => clearTimeout(timer);
                        }
                    }
                } catch (err) {
                    setIsLoading(false);
                    setIsFailure(true);
                }
            }
        })();
    }, [urlValid]);

    useEffect(() => {
        if (!isLoading) {
            (async function iieFunction() {
                setIsDownloadLoading(false);
            })();
        }
    }, [isLoading]);

    return (
        <>
            <div style={{ visibility: 'hidden', position: 'fixed' }}>
                <canvas width="300px" height="200px" id="canvasForCharts" />
                <canvas width="300px" height="300px" id="canvasForBullet" />
            </div>
            <SContainer>
                <SpimedImg src={SpimedLogo} />
                {!isFailure ? (
                    isLoading ? (
                        <>Loading...</>
                    ) : (
                        <>
                            <PDFDownloadLink
                                style={{ textDecoration: 'none' }}
                                document={
                                    <PdfDocumentNoMedicalProps
                                        storedAnalysis={storedAnalysis}
                                        ladResults={ladResults}
                                        cxResults={cxResults}
                                        rcaResults={rcaResults}
                                        coverImages={files3D}
                                        patientName={patientNameDebounced}
                                        language={i18n.language}
                                        date={storedAnalysis.createdOn}
                                    />
                                }
                                fileName={t('downloadAnalysis.pdfname') + '-' + storedAnalysis.id}
                            >
                                {({ blob, url, loading, error }) =>
                                    loading || isDownloadLoading ? (
                                        <ButtonBlue>
                                            <CircularProgress />
                                        </ButtonBlue>
                                    ) : (
                                        <ButtonBlueWithIcon
                                            icon={<GetAppIcon />}
                                            onClick={() => {
                                                return;
                                            }}
                                            text={t('downloadAnalysis.download')}
                                            disabled={false}
                                        />
                                    )
                                }
                            </PDFDownloadLink>
                        </>
                    )
                ) : (
                    <>{t('errorOccured')}</>
                )}
            </SContainer>
        </>
    );
};

export default DownloadAnalysis;

export const FilterBlobsByCoronary = async (
    coronary: Coronary,
    downloadFiles: File[],
    analysisComesFromDicomServer: boolean
) => {
    var files: File[] = [];

    if (analysisComesFromDicomServer && coronary === Coronary.NotSpecified) {
        for (let i = 0; i < downloadFiles.length; i++) {
            const fileName = downloadFiles[i].fileName.split('/');
            const imageNumber = fileName[2].split('.')[0];
            files.push({
                base64: downloadFiles[i].base64,
                fileName: imageNumber,
                data: ''
            });
        }
    } else {
        for (let i = 0; i < downloadFiles.length; i++) {
            const fileName = downloadFiles[i].fileName.split('/');
            const coronaryInFileName = fileName[0];
            const imageNumber = fileName[1].split('.')[0];
            if (coronaryInFileName === coronary) {
                files.push({
                    base64: downloadFiles[i].base64,
                    fileName: imageNumber,
                    data: ''
                });
            }
        }
    }

    return files.sort((a, b) => (a.fileName as unknown as number) - (b.fileName as unknown as number));
};

export const getIndexesFromStoredAnalysis = (imageOrderInStorage: ImageOrderInStorage) => {
    switch (imageOrderInStorage) {
        case ImageOrderInStorage.LadCxRca:
            return { ladI: 0, cxI: 1, rcaI: 2 };
        case ImageOrderInStorage.LadRcaCx:
            return { ladI: 0, rcaI: 1, cxI: 2 };
        case ImageOrderInStorage.CxLadRca:
            return { cxI: 0, ladI: 1, rcaI: 2 };
        case ImageOrderInStorage.CxRcaLad:
            return { cxI: 0, rcaI: 1, ladI: 2 };
        case ImageOrderInStorage.RcaCxLad:
            return { rcaI: 0, cxI: 1, ladI: 2 };
        case ImageOrderInStorage.RcaLadCx:
            return { rcaI: 0, ladI: 1, cxI: 2 };
        default:
            return { ladI: 0, cxI: 1, rcaI: 2 };
    }
};

const SContainer = styled.div`
    display: flex;
    height: 70vh;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const SpimedImg = styled.img`
    height: 20vh;
`;
