import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Link from '@mui/material/Link';

import ReactGA from 'react-ga';

import { Button, PasswordField, Typography, CircularProgress } from '@/components/atoms';
import { ResetPasswordRequest, UsersClient } from '@api';
import { PasswordValidation, validatePassword } from '@/utils/PasswordValidation';
import { t } from '@/i18n.js';

type ResetTokenParams = {
    /**The reset token is passed as an URL parameters*/
    url: string;
};

export const PasswordReset = () => {
    const { url } = useParams<ResetTokenParams>();

    const [resetToken, setResetToken] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordDontMatch, setpasswordDontMatch] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({
        allValid: true,
        mustContainData: [['', false]]
    });
    const [failedRequest, setFailedRequest] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        let urlToken = new URLSearchParams(url).get('token');
        setResetToken(urlToken || '');
        let urlEmail = new URLSearchParams(url).get('email');
        setEmail(urlEmail || '');
    }, [url]);

    useEffect(() => {
        if (password !== '') {
            setPasswordValidation(validatePassword(password));
        }
    }, [password, JSON.stringify(passwordValidation)]);

    useEffect(() => {
        setpasswordDontMatch(password !== '' && confirmPassword !== '' && password !== confirmPassword);
    }, [password, confirmPassword]);

    const onClickModify = () => {
        setLoading(true);
        const client = new UsersClient({ token: '' }, process.env.BackendServiceURL);
        const rPRequest = new ResetPasswordRequest({ email, resetToken, password });
        client
            .resetPassword(rPRequest)
            .then((resp) => {
                history.push('/reset-password-validation');
            })
            .catch((err) => {
                setLoading(false);
                setFailedRequest(true);
            });
        ReactGA.event({
            category: 'Password',
            action: 'Reset Password'
        });
    };
    return (
        <SContainer>
            <Typography paragraph variant="h3">
                {t('settings.newPswd')}
            </Typography>
            <PasswordField onChange={setPassword} value={password} id="new-password" />
            {!passwordValidation.allValid && (
                <>
                    <Typography blue variant="subtitle2">
                        {t('settings.invalidPswd')}
                    </Typography>
                    {passwordValidation.mustContainData
                        .filter((data) => !data[1])
                        .map((data: [string, boolean], index: number) => (
                            <SBaseline key={index}>
                                <ArrowRightIcon />
                                <Typography red variant="h4">
                                    {t(data[0])}
                                </Typography>
                            </SBaseline>
                        ))}
                </>
            )}
            <Typography paragraph variant="h3">
                {t('settings.confirmPswd')}
            </Typography>
            <PasswordField onChange={setConfirmPassword} value={confirmPassword} id="confirmPassword" />
            {passwordDontMatch && (
                <Typography red variant="subtitle2">
                    {t('settings.dontMatchPswd')}
                </Typography>
            )}
            <SBottomContainer>
                <Button
                    disabled={
                        !passwordValidation.allValid || passwordDontMatch || password === '' || confirmPassword === ''
                    }
                >
                    {!loading ? (
                        <Typography variant="button" onClick={onClickModify}>
                            {t('settings.resetPswd')}
                        </Typography>
                    ) : (
                        <CircularProgress />
                    )}
                </Button>
                {failedRequest ? (
                    <Typography variant="subtitle2" style={{ marginTop: '20px' }}>
                        {t('resetPassword.tokenError')}
                        <SLink onClick={() => history.push('/forgot-password')} underline="hover">
                            {t('resetPassword.forgotPasswordPage')}
                        </SLink>
                    </Typography>
                ) : (
                    <></>
                )}
            </SBottomContainer>
        </SContainer>
    );
};

export default PasswordReset;

const SContainer = styled.div`
    witdh: 20%;
`;
const SBottomContainer = styled.div`
    padding-top: ${(props) => props.theme.getSpacing(5)};
    padding-bottom: ${(props) => props.theme.getSpacing(5)};
`;

const SBaseline = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    color: ${(props) => props.theme.colors.blue};
`;

const SLink = styled(Link)`
    color: ${(props) => props.theme.colors.electricblue};
`;

