import React from 'react';
import { Button as DefaultMaterialButton } from '@mui/material/';
import styled from 'styled-components';

export const Button = styled(({ ...otherProps }) => <DefaultMaterialButton {...otherProps} />)`
    && {
        background: ${(props) => props.theme.colors.darkgrey};
        &:hover {
            background-color: ${(props) => props.theme.buttonActionFilled.hover.background};
            cursor: pointer;
        }
        border: 1px solid transparent;
        border-radius: 8px;
        color: ${(props) => props.theme.colors.white};
        padding: ${(props) => props.theme.getSpacing(1, 3)};
        margin: ${(props) => props.theme.getSpacing(0, 0, 0, 2)};
    }
`;

export default Button;

