import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FormControlLabel, TextField } from '@mui/material';
import { t } from '@/i18n.js';
import moment from 'moment';
import { SendAdminForm } from '@/services/AdminService';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Warning } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { Button, CircularProgress, Typography } from '@/components/atoms';
import { ErrorMessage, Dialog } from '@/components/molecules';
import { Session } from '@/utils/contexts/SessionContext';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import {
    AdminClient,
    User,
    UserUpdateRequestByAdmin,
    TupleOfUserAndDoctorsListWithoutUserDetailOfAndUsers_SettingsAndStoredAnalysis_UsersWithoutUserDetailOfAndUser_StorageOf as TupleOneUser,
    ITupleOfUserAndDoctorsListWithoutUserDetailOfAndUsers_SettingsAndStoredAnalysis_UsersWithoutUserDetailOfAndUser_StorageOf as ITupleOneUser,
    Users_Settings,
    UserCreationRequestByAdmin
} from '@api';
import { FormControl, FormLabel, InputAdornment, Radio, RadioGroup } from '@mui/material';
import { PasswordValidation, validatePassword } from '@/utils/PasswordValidation';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';

interface HospitalAETitle {
    hospital: string;
    aetitle: string;
}

export const initialUserDetails: ITupleOneUser = {
    item1: new User(),
    item2: [],
    item3: new Users_Settings({
        userId: 0,
        isFFRAvailable: true,
        isExplicabilityAvailable: false
    }),
    item4: [],
    item5: []
};

type Props = {
    currentSessionInCookie: Session;
    isUpdate: boolean;
    id: string;
};

export const AdminUserForm = (props: Props) => {
    const { currentSessionInCookie, isUpdate, id } = props;
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isFailure, setIsFailure] = useState(false);

    const [userDetails, setUserDetails] = useState<ITupleOneUser>(initialUserDetails);

    const [newMail, setNewMail] = useState<string>('');
    const [newFirstName, setNewFirstName] = useState<string>('');
    const [newLastName, setNewLastName] = useState<string>('');
    const [newContainerName, setNewContainerName] = useState<string>('');
    const [newHospital, setNewHospital] = useState<string>('');
    const [newAeTitle, setNewAeTitle] = useState<string>('');
    const [newFFRAvailable, setNewFFRAvailable] = useState<string>('');
    const [newExplicabilityAvailable, setNewExplicabilityAvailable] = useState<string>('');
    const [newLicenseExpireDateNull, setNewLicenseExpireDateNull] = useState<boolean>(true);
    const [newLicenseExpireDate, setNewLicenseExpireDate] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newDoctorName, setNewDoctorName] = useState<string>('');
    const [newDoctorEmail, setNewDoctorEmail] = useState<string>('');

    const [currentHospitalAETitles, setHospitalAETitles] = useState<HospitalAETitle[]>([]);

    const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({
        allValid: true,
        mustContainData: [['', false]]
    });

    const [formIsBeingSent, setFormIsBeingSent] = useState<boolean>(false);
    const [deletionFormIsBeingSent, setDeletionFormIsBeingSent] = useState<boolean>(false);
    const [openDialogDelete, setOpenDialogDelete] = useState<boolean>(false);
    const [formValidationError, setFormValidationError] = useState<string>('');
    const [snackbarContext, updateSnackbar] = useSnackbarContext();
    const [themeContext, updateTheme] = useThemeContext();

    function initForm(tupleresponse: TupleOneUser) {
        setNewMail(tupleresponse.item1.email || '');
        setNewFirstName(tupleresponse.item1.firstName || '');
        setNewLastName(tupleresponse.item1.lastName || '');
        setNewContainerName(tupleresponse.item1.containerName || '');
        setNewHospital('');
        setNewAeTitle('');
        setNewFFRAvailable(tupleresponse.item3?.isFFRAvailable?.toString() || 'false');
        setNewExplicabilityAvailable(tupleresponse.item3?.isExplicabilityAvailable?.toString() || 'false');

        if (tupleresponse.item3?.licenseExpireDate == undefined) {
            setNewLicenseExpireDateNull(true);
            setNewLicenseExpireDate('');
        } else {
            setNewLicenseExpireDateNull(false);
            setNewLicenseExpireDate(moment(tupleresponse.item3.licenseExpireDate).format('YYYY-MM-DD'));
        }
        setNewPassword('');
        setPasswordValidation({
            allValid: true,
            mustContainData: [['', false]]
        });
        setNewDoctorName('');
        setNewDoctorEmail('');

        const existingData = tupleresponse;
        const transformedData = existingData.item5.map((item) => ({
            hospital: item.hospital || 'HOSPITAL_NOT_SET_IN_DB',
            aetitle: item.aeTitle || 'AETITLE_NOT_SET_IN_DB'
        }));

        // Set the state
        setHospitalAETitles(transformedData);
    }

    useEffect(() => {
        if (id != '0') {
            (async function iifeFunction() {
                try {
                    setFormIsBeingSent(false);
                    setIsLoading(true);
                    const adminClient = new AdminClient(
                        { token: currentSessionInCookie.authResponse.token },
                        process.env.BackendServiceURL
                    );
                    adminClient.getUser(parseInt(id)).then((tupleresponse) => {
                        if (tupleresponse.item3.isFFRAvailable == undefined) {
                            // some users don't have any user settings
                            tupleresponse.item3 = new Users_Settings({
                                userId: tupleresponse.item1.id,
                                licenseExpireDate: undefined,
                                isFFRAvailable: true,
                                isExplicabilityAvailable: false
                            });
                        }
                        setUserDetails(tupleresponse);
                        setIsLoading(false);
                        setIsFailure(false);
                        initForm(tupleresponse);
                    });
                } catch (err) {
                    setFormIsBeingSent(false);
                    setIsLoading(false);
                    setIsFailure(true);
                }
            })();
        } else {
            setUserDetails(initialUserDetails);
            setIsLoading(false);
            setIsFailure(false);
            // @ts-ignore
            initForm(initialUserDetails);
        }
    }, [id]);

    useEffect(() => {
        if (newPassword !== '') {
            setPasswordValidation(validatePassword(newPassword));
        } else {
            setPasswordValidation({
                allValid: true,
                mustContainData: [['', false]]
            });
        }
    }, [newPassword, JSON.stringify(passwordValidation)]);

    const sendForm = () => {
        setFormIsBeingSent(true);

        var finalExpireDate = undefined;
        var finalForceExpireDateToNull = undefined;
        if (newLicenseExpireDateNull == true && userDetails.item3.licenseExpireDate != undefined) {
            finalForceExpireDateToNull = true;
        }
        if (newLicenseExpireDateNull == false && newLicenseExpireDate != '') {
            finalExpireDate = newLicenseExpireDate;
        }
        const sendAdminForm = new SendAdminForm(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        if (isUpdate == true) {
            const updateRequest = new UserUpdateRequestByAdmin({
                userId: Number(id),
                isFFRAvailable: newFFRAvailable == 'true' ? true : false,
                isExplicabilityAvailable: newExplicabilityAvailable == 'true' ? true : false,

                containerName: newContainerName != userDetails.item1.containerName ? newContainerName : undefined,
                aeTitle:
                    !currentHospitalAETitles.some(
                        (item) => item.hospital === newHospital && item.aetitle === newAeTitle
                    ) && newAeTitle != ''
                        ? newAeTitle
                        : undefined,
                hospital:
                    !currentHospitalAETitles.some(
                        (item) => item.hospital === newHospital && item.aetitle === newAeTitle
                    ) && newHospital != ''
                        ? newHospital
                        : undefined,
                firstName: newFirstName != userDetails.item1.firstName ? newFirstName : undefined,
                lastName: newLastName != userDetails.item1.lastName ? newLastName : undefined,
                email: newMail != userDetails.item1.email ? newMail : undefined,

                forceLicenceExpireDateToNull: finalForceExpireDateToNull,
                // @ts-ignore
                licenseExpireDate: finalExpireDate,

                passwordUpdate: newPassword != '' ? newPassword : undefined,

                newDoctorName: newDoctorName != '' ? newDoctorName : undefined,
                newDoctorEmail: newDoctorEmail != '' ? newDoctorEmail : undefined
            });

            sendAdminForm
                .updateUser({
                    sessionContext: currentSessionInCookie,
                    updateRequest
                })
                .then((response) => {
                    setFormIsBeingSent(false);
                    if (response.status != 200) {
                        updateSnackbar({
                            snackString: t('snackbar.error.admin.updateUser'),
                            severity: 'error'
                        });
                        setFormValidationError(response.data);
                    } else {
                        setFormValidationError('');
                        updateSnackbar({
                            snackString: t('snackbar.success.admin.updateUser'),
                            severity: 'success'
                        });
                        // @ts-ignore
                        initForm(response.data);
                        setUserDetails(response.data);
                    }
                });
        } else {
            const createRequest = new UserCreationRequestByAdmin({
                isFFRAvailable: newFFRAvailable == 'true' ? true : false,
                isExplicabilityAvailable: newExplicabilityAvailable == 'true' ? true : false,
                firstName: newFirstName,
                lastName: newLastName,
                email: newMail,
                passwordCreation: newPassword,
                containerName: newContainerName,

                aeTitle: newAeTitle != '' ? newAeTitle : undefined,
                hospital: newHospital != '' ? newHospital : undefined,

                forceLicenceExpireDateToNull: finalForceExpireDateToNull,
                // @ts-ignore
                licenseExpireDate: finalExpireDate,

                firstDoctorName: newDoctorName != '' ? newDoctorName : undefined,
                firstDoctorEmail: newDoctorEmail != '' ? newDoctorEmail : undefined
            });

            sendAdminForm
                .createUser({
                    sessionContext: currentSessionInCookie,
                    createRequest
                })
                .then((response) => {
                    setFormIsBeingSent(false);
                    if (response.status != 200) {
                        setFormValidationError(response.data);
                        updateSnackbar({
                            snackString: t('snackbar.error.admin.createUser'),
                            severity: 'error'
                        });
                    } else {
                        updateSnackbar({
                            snackString: t('snackbar.success.admin.createUser'),
                            severity: 'success'
                        });
                        setFormValidationError('');
                        history.push('/admin/users');
                    }
                });
        }
    };
    const deleteUser = () => {
        setDeletionFormIsBeingSent(true);

        const sendAdminForm = new SendAdminForm(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        if (isUpdate == true && id != '0') {
            sendAdminForm
                .deleteUser({
                    sessionContext: currentSessionInCookie,
                    userId: Number(id)
                })
                .then((response) => {
                    setDeletionFormIsBeingSent(false);
                    if (response.status != 204) {
                        updateSnackbar({
                            snackString: t('snackbar.error.admin.deleteUser'),
                            severity: 'error'
                        });
                        setFormValidationError(response.data);
                    } else {
                        setFormValidationError('');
                        updateSnackbar({
                            snackString: t('snackbar.success.admin.deleteUser'),
                            severity: 'success'
                        });
                        history.push('/admin/users');
                    }
                });
        } else {
            console.warn('cannot delete non existing user');
        }
    };

    return (
        <>
            {isLoading ? (
                <>
                    <CircularProgress />
                </>
            ) : !isFailure ? (
                <SPage>
                    <FormControl sx={{ width: '70vw', maxHeight: '90vh', overflowY: 'auto' }}>
                        <SContainer>
                            <SRowLarge>
                                <SLink onClick={() => history.push('/admin/users')} underline="hover">
                                    {t('back')}
                                </SLink>
                                <STypography title="true" variant="h2">
                                    {id == '0' ? t('admin.form.title.createUser') : t('admin.form.title.updateUser')}
                                </STypography>
                            </SRowLarge>
                            <SRow>
                                <STypography title="true" variant="h3">
                                    {t('admin.form.title.user')}
                                </STypography>

                                <STextField
                                    id="email"
                                    label={t('mail')}
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={(event) => {
                                        setNewMail(event.target.value);
                                    }}
                                    value={newMail}
                                    sx={{
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                        '.MuiSvgIcon-root ': {
                                            fill: `${
                                                themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                            } !important`
                                        },
                                        '& label': {
                                            color: themeContext.isLightMode
                                                ? lightTheme.page.lightText
                                                : darkTheme.page.lightText
                                        },
                                        '& label.Mui-focused': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiFilledInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            }
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                />
                                {newMail == '' && (
                                    <Typography orange variant="subtitle2">
                                        {t('admin.form.error.emptyEmail')}
                                    </Typography>
                                )}

                                <STextField
                                    id="firstName"
                                    label={t('firstName')}
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={(event) => {
                                        setNewFirstName(event.target.value);
                                    }}
                                    value={newFirstName}
                                    sx={{
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                        '.MuiSvgIcon-root ': {
                                            fill: `${
                                                themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                            } !important`
                                        },
                                        '& label': {
                                            color: themeContext.isLightMode
                                                ? lightTheme.page.lightText
                                                : darkTheme.page.lightText
                                        },
                                        '& label.Mui-focused': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiFilledInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            }
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                />
                                {newFirstName == '' && (
                                    <Typography orange variant="subtitle2">
                                        {t('admin.form.error.emptyFirstName')}
                                    </Typography>
                                )}

                                <STextField
                                    id="lastName"
                                    label={t('lastName')}
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={(event) => {
                                        setNewLastName(event.target.value);
                                    }}
                                    value={newLastName}
                                    sx={{
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                        '.MuiSvgIcon-root ': {
                                            fill: `${
                                                themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                            } !important`
                                        },
                                        '& label': {
                                            color: themeContext.isLightMode
                                                ? lightTheme.page.lightText
                                                : darkTheme.page.lightText
                                        },
                                        '& label.Mui-focused': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiFilledInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            }
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                />
                                {newLastName == '' && (
                                    <Typography orange variant="subtitle2">
                                        {t('admin.form.error.emptyLastName')}
                                    </Typography>
                                )}

                                <STextField
                                    id="containerName"
                                    label={t('admin.form.data.containerName')}
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={(event) => {
                                        setNewContainerName(event.target.value);
                                    }}
                                    value={newContainerName}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Warning />
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                        '.MuiSvgIcon-root ': {
                                            fill: `${
                                                themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                            } !important`
                                        },
                                        '& label': {
                                            color: themeContext.isLightMode
                                                ? lightTheme.page.lightText
                                                : darkTheme.page.lightText
                                        },
                                        '& label.Mui-focused': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiFilledInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            }
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                />
                                {newContainerName == '' && (
                                    <Typography orange variant="subtitle2">
                                        {t('admin.form.error.emptyContainerName')}
                                    </Typography>
                                )}

                                <STypography title="true" variant="h3">
                                    {t('admin.form.title.newHospitalAetitle')}
                                </STypography>
                                {currentHospitalAETitles.length > 0 && (
                                    <ul>
                                        {currentHospitalAETitles.map((item, index) => (
                                            <li key={index}>
                                                {item.hospital}-{item.aetitle} (hospital = <i>{item.hospital}</i> and
                                                aetitle = <i>{item.aetitle})</i>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                                <STextFieldNoMargin
                                    id="hospital"
                                    label={t('admin.form.data.hospital')}
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={(event) => {
                                        setNewHospital(event.target.value);
                                    }}
                                    value={newHospital}
                                    sx={{
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                        '.MuiSvgIcon-root ': {
                                            fill: `${
                                                themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                            } !important`
                                        },
                                        '& label': {
                                            color: themeContext.isLightMode
                                                ? lightTheme.page.lightText
                                                : darkTheme.page.lightText
                                        },
                                        '& label.Mui-focused': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiFilledInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            }
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                />
                                <STextField
                                    id="aeTitle"
                                    label={t('admin.form.data.aeTitle')}
                                    variant="standard"
                                    autoComplete="off"
                                    onChange={(event) => {
                                        setNewAeTitle(event.target.value);
                                    }}
                                    value={newAeTitle}
                                    sx={{
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                        '.MuiSvgIcon-root ': {
                                            fill: `${
                                                themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                            } !important`
                                        },
                                        '& label': {
                                            color: themeContext.isLightMode
                                                ? lightTheme.page.lightText
                                                : darkTheme.page.lightText
                                        },
                                        '& label.Mui-focused': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiFilledInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            }
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                />
                                {/\s|[A-Z]/g.test(newAeTitle) && (
                                    <div style={{ color: 'red' }}>
                                        You shouldn't have uppercase letters or spaces in the aetitle, only lowercase
                                        and -
                                    </div>
                                )}
                                {/\s|[A-Z]/g.test(newHospital) && (
                                    <div style={{ color: 'red' }}>
                                        You shouldn't have uppercase letters or spaces in the hospital, only lowercase
                                        and -
                                    </div>
                                )}
                                <STypography title="true" variant="h3">
                                    {t('admin.form.title.ffrAvailable')}
                                </STypography>

                                <FormControl
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                >
                                    <FormLabel id="radio-ffr">{t('admin.form.data.isFFRAvailable')}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="radio-ffr"
                                        name="radio-buttons-group"
                                        value={newFFRAvailable}
                                        onChange={(event) => {
                                            setNewFFRAvailable(event.target.value);
                                        }}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="True" />
                                        <FormControlLabel value="false" control={<Radio />} label="False" />
                                    </RadioGroup>
                                </FormControl>

                                <STypography title="true" variant="h3">
                                    {t('admin.form.title.explicabilityAvailable')}
                                </STypography>

                                <FormControl
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                >
                                    <FormLabel id="radio-ffr">
                                        {t('admin.form.data.isExplicabilityAvailable')}
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="radio-ffr"
                                        name="radio-buttons-group"
                                        value={newExplicabilityAvailable}
                                        onChange={(event) => {
                                            setNewExplicabilityAvailable(event.target.value);
                                        }}
                                    >
                                        <FormControlLabel value="true" control={<Radio />} label="True" />
                                        <FormControlLabel value="false" control={<Radio />} label="False" />
                                    </RadioGroup>
                                </FormControl>

                                <STypography title="true" variant="h3">
                                    {t('admin.form.title.licenceExpiration')}
                                </STypography>

                                <FormControl
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                >
                                    <FormLabel id="radio-license">{t('admin.form.data.isLicenceExpireSet')}</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="radio-license"
                                        name="radio-buttons-group-bis"
                                        value={newLicenseExpireDateNull ? 'null' : 'date'}
                                        onChange={(event) => {
                                            if (event.target.value == 'null') {
                                                setNewLicenseExpireDateNull(true);
                                            } else {
                                                setNewLicenseExpireDateNull(false);
                                            }
                                        }}
                                    >
                                        <FormControlLabel
                                            value="date"
                                            control={<Radio />}
                                            label={t('admin.form.data.yes')}
                                        />
                                        <FormControlLabel
                                            value="null"
                                            control={<Radio />}
                                            label={t('admin.form.data.no')}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {newLicenseExpireDateNull ? (
                                    <div>{t('admin.form.information.licenceExpirationNotSet')}</div>
                                ) : (
                                    <STextFieldMargin
                                        id="licenseExpireDate"
                                        label="LicenseExpireDate"
                                        type="date"
                                        value={newLicenseExpireDate}
                                        onChange={(event) => {
                                            setNewLicenseExpireDate(event.target.value);
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        sx={{
                                            color: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text,
                                            '.MuiSvgIcon-root ': {
                                                fill: `${
                                                    themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                } !important`
                                            },
                                            '& label': {
                                                color: themeContext.isLightMode
                                                    ? lightTheme.page.lightText
                                                    : darkTheme.page.lightText
                                            },
                                            '& label.Mui-focused': {
                                                color: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            },
                                            '& .MuiInput-underline:after': {
                                                borderBottomColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            },
                                            '& .MuiFilledInput-underline:after': {
                                                borderBottomColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                }
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                color: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            }
                                        }}
                                    />
                                )}

                                <STypography title="true" variant="h3">
                                    {id != '0'
                                        ? t('admin.form.title.updatePassword')
                                        : t('admin.form.title.newUserPassword')}
                                </STypography>

                                <STextField
                                    id="change-password"
                                    label={t('admin.form.data.updatePassword')}
                                    variant="standard"
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Warning />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(event) => {
                                        setNewPassword(event.target.value);
                                    }}
                                    sx={{
                                        color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text,
                                        '.MuiSvgIcon-root ': {
                                            fill: `${
                                                themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                            } !important`
                                        },
                                        '& label': {
                                            color: themeContext.isLightMode
                                                ? lightTheme.page.lightText
                                                : darkTheme.page.lightText
                                        },
                                        '& label.Mui-focused': {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        },
                                        '& .MuiInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiFilledInput-underline:after': {
                                            borderBottomColor: themeContext.isLightMode
                                                ? lightTheme.page.text
                                                : darkTheme.page.text
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                                borderColor: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text
                                            }
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.text
                                        }
                                    }}
                                />

                                {newPassword == '' && id == '0' && (
                                    <Typography orange variant="subtitle2">
                                        {t('admin.form.error.emptyPassword')}
                                    </Typography>
                                )}

                                {!passwordValidation.allValid && (
                                    <>
                                        <Typography red variant="subtitle2">
                                            {t('settings.invalidPswd')}
                                        </Typography>
                                        {passwordValidation.mustContainData
                                            .filter((data) => !data[1])
                                            .map((data: [string, boolean], index: number) => (
                                                <SBaseline key={index}>
                                                    <ArrowRightIcon />
                                                    <Typography red variant="h4">
                                                        {t(data[0])}
                                                    </Typography>
                                                </SBaseline>
                                            ))}
                                    </>
                                )}

                                <STypography title="true" variant="h3">
                                    {t('doctors')}
                                </STypography>
                                {userDetails.item2[0] != undefined && (
                                    <div>
                                        {t('doctor.1')} : {userDetails.item2[0].email}, {userDetails.item2[0].name}
                                    </div>
                                )}
                                {userDetails.item2[0] == undefined && id == '0' && (
                                    <div>{t('admin.form.information.firstDoctorCreated')}</div>
                                )}

                                {/* Remove first doctor from iteration */}
                                {userDetails.item2.slice(1).map(
                                    (doctor: { email: string; name: string }, index: number) =>
                                        doctor !== undefined && (
                                            <div key={index}>
                                                Doctor {index + 2} : {doctor.email}, {doctor.name}
                                            </div>
                                        )
                                )}

                                {userDetails.item2.length < 15 && (
                                    <>
                                        {id != '0' && (
                                            <STypography title="true" variant="h4">
                                                {t('admin.form.data.createNewDoctor')}
                                            </STypography>
                                        )}

                                        <STextFieldMargin
                                            id="newDoctorName"
                                            label={
                                                id == '0'
                                                    ? t('admin.form.data.firstDoctorName')
                                                    : t('admin.form.data.newDoctorName')
                                            }
                                            variant="standard"
                                            autoComplete="off"
                                            onChange={(event) => {
                                                setNewDoctorName(event.target.value);
                                            }}
                                            value={newDoctorName}
                                            sx={{
                                                color: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text,
                                                '.MuiSvgIcon-root ': {
                                                    fill: `${
                                                        themeContext.isLightMode
                                                            ? lightTheme.page.text
                                                            : darkTheme.page.text
                                                    } !important`
                                                },
                                                '& label': {
                                                    color: themeContext.isLightMode
                                                        ? lightTheme.page.lightText
                                                        : darkTheme.page.lightText
                                                },
                                                '& label.Mui-focused': {
                                                    color: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottomColor: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: themeContext.isLightMode
                                                            ? lightTheme.page.text
                                                            : darkTheme.page.text
                                                    }
                                                }
                                            }}
                                            inputProps={{
                                                style: {
                                                    color: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                }
                                            }}
                                        />
                                        <STextFieldMargin
                                            id="newDoctorEmail"
                                            label={
                                                id == '0'
                                                    ? t('admin.form.data.firstDoctorEmail')
                                                    : t('admin.form.data.newDoctorEmail')
                                            }
                                            variant="standard"
                                            autoComplete="off"
                                            onChange={(event) => {
                                                setNewDoctorEmail(event.target.value);
                                            }}
                                            value={newDoctorEmail}
                                            sx={{
                                                color: themeContext.isLightMode
                                                    ? lightTheme.page.text
                                                    : darkTheme.page.text,
                                                '.MuiSvgIcon-root ': {
                                                    fill: `${
                                                        themeContext.isLightMode
                                                            ? lightTheme.page.text
                                                            : darkTheme.page.text
                                                    } !important`
                                                },
                                                '& label': {
                                                    color: themeContext.isLightMode
                                                        ? lightTheme.page.lightText
                                                        : darkTheme.page.lightText
                                                },
                                                '& label.Mui-focused': {
                                                    color: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                },
                                                '& .MuiInput-underline:after': {
                                                    borderBottomColor: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                },
                                                '& .MuiFilledInput-underline:after': {
                                                    borderBottomColor: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: themeContext.isLightMode
                                                            ? lightTheme.page.text
                                                            : darkTheme.page.text
                                                    }
                                                }
                                            }}
                                            inputProps={{
                                                style: {
                                                    color: themeContext.isLightMode
                                                        ? lightTheme.page.text
                                                        : darkTheme.page.text
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </SRow>

                            <STypography blue variant="h3">
                                {t('admin.form.title.listOfChanges')}
                            </STypography>
                            {newMail != userDetails.item1.email && newMail != '' && (
                                <div>
                                    {' '}
                                    {t('admin.form.changes.newMail')} : {newMail}{' '}
                                </div>
                            )}
                            {newFirstName != userDetails.item1.firstName && newFirstName != '' && (
                                <div>
                                    {' '}
                                    {t('admin.form.changes.newFirstName')} : {newFirstName}{' '}
                                </div>
                            )}
                            {newLastName != userDetails.item1.lastName && newLastName != '' && (
                                <div>
                                    {' '}
                                    {t('admin.form.changes.newLastName')} : {newLastName}{' '}
                                </div>
                            )}
                            {newContainerName != userDetails.item1.containerName && newContainerName != '' && (
                                <div>
                                    {t('admin.form.changes.newContainerName')} : {newContainerName}{' '}
                                </div>
                            )}
                            {!currentHospitalAETitles.some(
                                (item) => item.hospital === newHospital && item.aetitle === newAeTitle
                            ) &&
                                newHospital != '' && (
                                    <div>
                                        {t('admin.form.changes.newHospital')} : {newHospital}
                                    </div>
                                )}
                            {!currentHospitalAETitles.some(
                                (item) => item.hospital === newHospital && item.aetitle === newAeTitle
                            ) &&
                                newAeTitle != '' && (
                                    <div>
                                        {t('admin.form.changes.newAeTitle')} : {newAeTitle}
                                    </div>
                                )}
                            {currentHospitalAETitles.some(
                                (item) => item.hospital === newHospital && item.aetitle === newAeTitle
                            ) && (
                                <div style={{ color: 'orange' }}>
                                    Warning for hospital-aetitle: {newHospital}-{newAeTitle} already exist for this
                                    user, will not be duplicated in DB
                                </div>
                            )}
                            {newFFRAvailable != userDetails.item3.isFFRAvailable.toString() && (
                                <div>
                                    {' '}
                                    {t('admin.form.changes.newFFRAvailable')} : {newFFRAvailable}{' '}
                                </div>
                            )}
                            {newExplicabilityAvailable != userDetails.item3.isExplicabilityAvailable.toString() && (
                                <div>
                                    {' '}
                                    {t('admin.form.changes.newExplicabilityAvailable')} : {newExplicabilityAvailable}{' '}
                                </div>
                            )}
                            {((userDetails.item3.licenseExpireDate == undefined &&
                                newLicenseExpireDateNull == false &&
                                newLicenseExpireDate != '') ||
                                (userDetails.item3.licenseExpireDate != undefined &&
                                    moment(userDetails.item3.licenseExpireDate).format('YYYY-MM-DD') !=
                                        newLicenseExpireDate &&
                                    newLicenseExpireDateNull == false)) && (
                                <div>
                                    {' '}
                                    {t('admin.form.changes.newLicenceExpireDate')} : {newLicenseExpireDate}
                                </div>
                            )}
                            {userDetails.item3.licenseExpireDate != undefined && newLicenseExpireDateNull == true && (
                                <div> {t('admin.form.changes.licenceExpireDateSetToNull')} </div>
                            )}
                            {newPassword != '' && (
                                <div>
                                    {' '}
                                    {t('admin.form.changes.newPassword')} : {newPassword}{' '}
                                </div>
                            )}
                            {newDoctorEmail != '' && newDoctorName != '' && (
                                <div> {t('admin.form.changes.newDoctor')} </div>
                            )}

                            {formValidationError != '' && (
                                <Typography red variant="h3">
                                    {JSON.stringify(formValidationError)}
                                </Typography>
                            )}

                            <SBottomContainer>
                                <SButton
                                    sx={{ boxShadow: 1 }}
                                    disabled={
                                        ((newMail == '' ||
                                            newFirstName == '' ||
                                            newLastName == '' ||
                                            newContainerName == '' ||
                                            !passwordValidation.allValid ||
                                            newPassword == '') &&
                                            id == '0') ||
                                        ((newMail == '' ||
                                            newFirstName == '' ||
                                            newLastName == '' ||
                                            newContainerName == '' ||
                                            !passwordValidation.allValid) &&
                                            id != '0')
                                    }
                                >
                                    {!formIsBeingSent ? (
                                        <Typography variant="button" onClick={sendForm}>
                                            {t('admin.form.data.save')}
                                        </Typography>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </SButton>
                            </SBottomContainer>
                            {id != '0' && (
                                <div style={{ padding: '10px' }}>
                                    <SButtonDelete sx={{ boxShadow: 1 }}>
                                        <Typography variant="button" onClick={() => setOpenDialogDelete(true)}>
                                            {t('admin.form.data.delete')}
                                        </Typography>
                                    </SButtonDelete>
                                </div>
                            )}
                            <Dialog
                                open={openDialogDelete}
                                customTitle={t('admin.form.data.delete')}
                                text={t('admin.form.data.confirmDelete')}
                                onClose={deleteUser}
                                disableChildrenButton={deletionFormIsBeingSent}
                                childrenButton={
                                    deletionFormIsBeingSent ? (
                                        <CircularProgress />
                                    ) : (
                                        <Typography red variant="button">
                                            {t('admin.form.data.delete')}
                                        </Typography>
                                    )
                                }
                                withCancel
                                disableEscapeKeyDown
                                handleCancel={() => setOpenDialogDelete(false)}
                            ></Dialog>
                        </SContainer>
                    </FormControl>
                </SPage>
            ) : (
                <ErrorMessage message={t('errorOccured')} />
            )}
        </>
    );
};

export default AdminUserForm;

const SPage = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.getSpacing(2)};
    margin: ${(props) => props.theme.getSpacing(2)};
    background-color: ${(props) => props.theme.page.background};
    color: ${(props) => props.theme.page.text};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
`;

const SContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${(props) => props.theme.page.background};
    color: ${(props) => props.theme.page.text};
`;

const SRow = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.getSpacing(2)};
`;

const SRowLarge = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.getSpacing(2)};
    width: 90%;
`;

const SBaseline = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    color: ${(props) => props.theme.colors.blue};
`;

const STextField = styled(TextField)`
    margin-top: 25px !important;
    width: 30vw;
`;
const STextFieldNoMargin = styled(TextField)`
    margin: 0 !important;
    width: 30vw;
`;
const STextFieldMargin = styled(TextField)`
    margin-top: 10px !important;
    margin-left: 30px !important;
    width: 30vw;
`;

const SBottomContainer = styled.div`
    margin: ${(props) => props.theme.getSpacing(10, 2, 4, 2)};
    width: 90%;
    align-items: center;
    display: flex;
    justify-content: center;
`;

const SButtonDelete = styled(Button)`
    && {
        margin: 50px 0 10px 0;
        color: red;
        width: fit-content;
    }
`;
const STypography = styled(Typography)`
    && {
        color: ${(props) => props.theme.colors.blue};
        margin-top: 15px;
        padding-top: ${(props) => props.theme.getSpacing(2)};
    }
`;

const SButton = styled(Button)`
    && {
        margin: 10px 0 10px 0;
    }
`;

const SLink = styled(Link)`
    cursor: pointer;
    width: 80px;
`;

