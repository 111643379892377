import { Typography, WhiteButton, CircularProgress } from '@/components/atoms';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { t } from '@/i18n.js';
import { DoctorsListClient, DoctorsListCreateRequest, UsersClient } from '@/../generated/api.client';
import { DoctorEntity, Session } from '@/utils/contexts/SessionContext';
import { useCurrentDoctorContext } from '@/utils/contexts/CurrentDoctorContext';
import AddIcon from '@/img/SVG/add_icon.svg';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField, Button } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

type Props = {
    currentSessionInCookie: Session;
};

export const DoctorSelection = (props: Props) => {
    const { currentSessionInCookie } = props;

    const doctorsEntityString = localStorage.getItem('doctors_entity');
    const doctorsEntityConst = doctorsEntityString
        ? (JSON.parse(doctorsEntityString) as DoctorEntity[])
        : ([] as DoctorEntity[]);

    const [doctorsEntity, setDoctorsEntity] = useState<DoctorEntity[]>(doctorsEntityConst);

    const history = useHistory();

    const [currentDoctorContext, updateCurrentDoctorContext] = useCurrentDoctorContext();
    const [open, setOpen] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [newDoctorName, setNewDoctorName] = useState<string>('');
    const [newDoctorMail, setNewDoctorMail] = useState<string>('');

    const setCurrentDoctorWithFirstAndRedirectoToAccount = () => {
        const doctorEntity = JSON.parse(localStorage.getItem('doctors_entity') || '{[]}');

        let doctor1 = doctorEntity[0];
        if (!(doctor1 && doctor1.id)) {
            console.error('no doctor1 in doctor entity', doctorEntity);
            return;
        }
        const usersClient = new UsersClient(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        usersClient
            .updateCurrentDoctor(doctor1.id)
            .then((authenticateResponse) => {
                localStorage.setItem('current_doctor', JSON.stringify(doctor1));
                updateCurrentDoctorContext(doctor1);
                history.push('/account');
            })
            .catch((err) => {
                // todo handle error
                // setLoading(false);
            });
    };

    const setCurrentDoctor = (doctor: DoctorEntity) => {
        const usersClient = new UsersClient(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        usersClient
            .updateCurrentDoctor(doctor.id)
            .then((authenticateResponse) => {
                localStorage.setItem('current_doctor', JSON.stringify(doctor));
                updateCurrentDoctorContext(doctor);
                history.push('/home'); // todo verify if needed
            })
            .catch((err) => {
                console.log('err in DoctorSelection', err);
            });
    };
    const createNewDoctor = async () => {
        setLoading(true);

        const doctors = new DoctorsListClient(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        const DCRequest = new DoctorsListCreateRequest({
            name: newDoctorName,
            email: newDoctorMail
        });

        await doctors
            .create(DCRequest)
            .then((docResponse) => {
                const newDoctor: DoctorEntity = {
                    id: docResponse.id,
                    name: docResponse.name,
                    mail: docResponse.email
                };

                let newDoctorsEntities = [...doctorsEntity, newDoctor];
                localStorage.setItem('doctors_entity', JSON.stringify(newDoctorsEntities));
                setDoctorsEntity([...doctorsEntity, newDoctor]);

                setLoading(false);
                setOpen(false);

                setNewDoctorName('');
                setNewDoctorMail('');
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <>
            <SBackground>
                <SRow>
                    <STypographyTitle>{t('doctor-selection.title')}</STypographyTitle>
                </SRow>
                <SDiv>
                    <SList>
                        {doctorsEntity.map((item, index) => (
                            <ListItem disablePadding key={index}>
                                <ListItemButton onClick={() => setCurrentDoctor(item)} key={'button' + index}>
                                    <ListItemText primary={item.name} key={'text' + index} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        {doctorsEntity.length < 15 && (
                            <SColumn onClick={() => setOpen(true)}>
                                <SContainer>
                                    <SImg src={AddIcon} alt="Add Doctor" />
                                </SContainer>
                                <STypography>{t('doctor-selection.addDoctor')}</STypography>
                            </SColumn>
                        )}
                    </SList>
                </SDiv>
                <SRowButton>
                    <WhiteButton onClick={() => setCurrentDoctorWithFirstAndRedirectoToAccount()}>
                        {t('doctor-selection.manageAccount')}
                    </WhiteButton>
                </SRowButton>
            </SBackground>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{t('doctor-selection.addDoctor')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('doctor-selection.addNameMail')}</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={t('doctor-selection.doctorName')}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            setNewDoctorName(event.target.value);
                        }}
                        autoComplete="off"
                    />
                    <TextField
                        margin="dense"
                        id="mail"
                        label={t('doctor-selection.doctorMail')}
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={(event) => {
                            setNewDoctorMail(event.target.value);
                        }}
                        autoComplete="off"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        <Typography variant="button">{t('doctor-selection.cancel')} </Typography>
                    </Button>
                    <Button onClick={() => createNewDoctor()} disabled={newDoctorName === '' || newDoctorMail === ''}>
                        {!loading ? (
                            <Typography variant="button">{t('doctor-selection.save')}</Typography>
                        ) : (
                            <CircularProgress />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DoctorSelection;

const STypographyTitle = styled(Typography)`
    color: white;
    font-size: 2.5rem !important;
`;

const SBackground = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: ${(props) => props.theme.colors.darkblue};
    justify-content: space-around;
    align-items: center;
`;

const SRow = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${(props) => props.theme.getSpacing(0, 0, 5, 0)};
`;

const SRowButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const SDiv = styled.div`
    width: 40vw;
    height: 50vh;
    overflow-y: auto;
    background-color: ${(props) => props.theme.colors.oxfordblue};
    border-radius: 10px;
    padding: ${(props) => props.theme.getSpacing(3, 3, 3, 3)};
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f2f2f2;
        border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #999999;
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #5d5d5d;
        border-radius: 5px;
    }
`;

const SList = styled(List)`
    color: white;
    border-radius: 10px;
    transition: transform 0.2s; /* Animation */
    && .Mui-selected,
    && .Mui-selected:hover {
        background-color: #2e5689;
        border-radius: 10px;
        transform: scale(1.01);
        &,
        & .MuiListItemIcon-root {
            color: white;
            border-radius: 10px;
            transform: scale(1.01);
        }
    }

    && .MuiListItemButton-root {
        border-radius: 10px;
    }
    && .MuiListItemButton-root:hover {
        background-color: #2e5689;
        border-radius: 10px;
        transform: scale(1.01);
        &,
        & .MuiListItemIcon-root {
            color: white;
            border-radius: 10px;
            transform: scale(1.01);
        }
    }
`;

const STypography = styled(Typography)`
    color: white;
    padding-right: 10px;
    padding-left: 10px;
    cursor: pointer;
`;

const SContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 50%;
    transition: 0.75s;
    padding: 5px;
`;

const SColumn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        ${SContainer} {
            transform: scale(1.2);
            transition: 0.5s;
        }
        ${STypography} {
            color: #a1a4a7 !important;
        }
        cursor: pointer;
    }
`;

const SImg = styled.img`
    height: 25px;
    width: 25px;
    padding-left: 11px;
`;

