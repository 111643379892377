import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RouterLink = styled(({ underline, color, ...otherProps }) => <Link {...otherProps} />)`
    && {
        color: ${(props) => (props.color ? props.color : props.theme.colors.black)};
        ${(props) =>
            !props.underline &&
            `
        text-decoration: none;
        `}
    }
`;

export default RouterLink;
