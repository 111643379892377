import React, { useState } from 'react';
import styled from 'styled-components';
import { CookieManagement, Typography } from '@/components/atoms';
import { t } from '@/i18n.js';

export const CookiePolicy = () => {
    const [openCookieManagement, setOpenCookieManagement] = useState<boolean>(false);

    return (
        <SPage>
            <STitle>
                <Typography title variant="h1">
                    {t('cookie.page.title')}
                </Typography>
            </STitle>
            <SItem>
                <Typography title variant="h2">
                    {t('cookie.page.whatIs.title')}
                </Typography>

                <Typography thinparagraph>{t('cookie.page.whatIs.text')}</Typography>
            </SItem>
            <SItem>
                <Typography title variant="h2">
                    {t('cookie.page.ourCookies.title')}
                </Typography>
                <Typography thinparagraph>{t('cookie.page.ourCookies.text')}</Typography>
                <ul>
                    <Typography thinparagraph>
                        <li>{t('cookie.page.ourCookies.one')}</li>
                    </Typography>
                    <Typography thinparagraph>
                        <li>{t('cookie.page.ourCookies.two')}</li>
                    </Typography>
                </ul>
            </SItem>
            <SItem>
                <Typography title variant="h2">
                    {t('cookie.page.manage.title')}
                </Typography>
                <Typography thinparagraph>{t('cookie.page.manage.text')}</Typography>
            </SItem>
            <SItem>
                <Typography title variant="h2">
                    {t('cookie.page.right.title')}
                </Typography>
                <Typography thinparagraph>
                    {t('cookie.page.right.text')} <SA href="mailto:support@spimed-ai.com">support@spimed-ai.com</SA>
                </Typography>
            </SItem>
            <CookieManagement
                openCookieManagement={openCookieManagement}
                setOpenCookieManagement={setOpenCookieManagement}
            />
        </SPage>
    );
};

export default CookiePolicy;

const SPage = styled.div`
    display: flex;
    width: 70vw;
    margin: ${(props) => props.theme.getSpacing(2)};
    padding: ${(props) => props.theme.getSpacing(10)};
    color: ${(props) => props.theme.page.text};
    background-color: ${(props) => props.theme.page.background};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    flex-direction: column;
    overflow-y: auto;
    *::-webkit-scrollbar,
    *::-webkit-scrollbar-thumb {
        width: 17px;
        border-radius: 13px;
        background-clip: padding-box;
        border: 5px solid transparent;
        color: red;
    }

    *::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 10px;
    }
    *::-webkit-scrollbar-thumb:hover {
        color: red;
    }
    *::-webkit-scrollbar-thumb:active {
        color: red;
    }
`;

const STitle = styled.div`
    text-align: center;
    color: ${(props) => props.theme.page.text};
`;

const SItem = styled.div`
    text-align: justify;
    color: ${(props) => props.theme.page.text};
`;

const SA = styled.a`
    text-decoration: underline;
    color: ${(props) => props.theme.page.hypertext};
    &:hover {
        cursor: pointer;
    }
`;

