import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import uuid from 'uuidv4';
import { t } from '@/i18n.js';

import { Typography, UploadButton, ToolTipable, ListToolTipable } from '@/components/atoms';
import { Dialog, CircularProgressWithLabel } from '@/components/molecules';
import { FileParameter } from '@api';
import { convertPngsToBase64 } from '@/utils/FileConverter';
import { convertDicomsToBase64 } from '@/utils/DicomConverter';
import { isDicom } from '@/utils/FileValidation';
import { Coronary } from '@api';

type OnUploadFctn = (files: File[]) => void;

type Props = {
    /**Action after an upload */
    onUpload: OnUploadFctn;
    /**Maximum number of file the user can upload on the component */
    maxFileUpload: number;
    /**Minimum number of file the user should upload on the component */
    minFileUpload?: number;
    /**Narrow the types of file see FileValidation.ts for example*/
    acceptedTypes: string[];
    coronary: Coronary;
};

type File = {
    /**Base64 version of the file */
    base64: string;
} & FileParameter;

export type { File };
/**Equivalent to HTML <input> but for file only 
 * Alert on maximum upload and wrong types
 * Transform the received file/list in @type FileInfo
 * @type FileInfo = {
    name: string;
    type: string;
    sizeInKb: number;
    base64: string | ArrayBuffer | null;
    file: object;
};
*/
interface FileData {
    base64: string;
    fileName: string;
    data: Blob;
}

function hasDuplicates(allFiles: FileData[]): boolean {
    const base64Set = new Set<string>();
    for (const file of allFiles) {
        if (base64Set.has(file.base64)) {
            return true;
        }
        base64Set.add(file.base64);
    }
    return false;
}

export const InputFile = (props: Props) => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [pageNotVisible, setPageNotVisible] = useState<boolean>(false);
    const [uncaughtCornerstoneError, setUncaughtCornerstoneError] = useState<boolean>(false);
    const [cancelLoading, setCancelLoading] = useState<boolean>(false);
    const { maxFileUpload, minFileUpload, acceptedTypes, onUpload, coronary } = props;

    const [divName, setDivName] = useState<string>('dicomImage' + coronary);

    const [openNumberMaxLimit, openNumberMaxLimitModal] = useState<boolean>(false);
    const [openMinNumberLimit, openMinNumberLimitModal] = useState<boolean>(false);
    const [openWrongDicom, openWrongDicomModal] = useState<boolean>(false);
    const [openWrongFile, openWrongFileModal] = useState<boolean>(false);
    const [openDuplicateFile, openDuplicateFileModal] = useState<boolean>(false);

    const [isPng, setIsPng] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files === null || (progress != 100 && progress != 0)) {
            if (onUpload && files === null) {
                onUpload([]);
            }
            return;
        }

        if (files.length > maxFileUpload) {
            openNumberMaxLimitModal(true);
            return;
        }

        if (minFileUpload && files.length < minFileUpload) {
            openMinNumberLimitModal(true);
            return;
        }
        onUpload([]);

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (isDicom(file.type)) {
                setIsPng(false);
                processDicom(files);
                return;
            }
        }
        processPng(files);
    };

    const id = uuid();

    const processDicom = async (files: FileList) => {
        setLoading(true);
        let allFiles: File[] = [];
        try {
            const element = document.getElementById(divName) as HTMLDivElement;
            if (element) {
                allFiles = await convertDicomsToBase64(
                    files,
                    setProgress,
                    setPageNotVisible,
                    setUncaughtCornerstoneError,
                    element,
                    divName
                );

                if (pageNotVisible || allFiles.length == 0 || cancelLoading) {
                    allFiles = [];
                    setProgress(0);
                    setLoading(false);
                    return;
                }

                let hasDuplicate = hasDuplicates(allFiles);
                if (hasDuplicate) {
                    allFiles = [];
                    openDuplicateFileModal(true);
                    setLoading(false);
                    return;
                }
            } else {
                setUploadedFiles([]);
                throw 'Error cannot find element in the DOM.';
            }
        } catch (err) {
            console.warn('err', err);
            openWrongDicomModal(true);
            setLoading(false);
            setUploadedFiles([]);
            return;
        }
        setUploadedFiles(allFiles);
        if (onUpload) {
            onUpload(allFiles);
            setLoading(false);
        }
    };

    const processPng = async (files: FileList) => {
        const allFiles: File[] = [];

        try {
            await convertPngsToBase64(files, allFiles);
        } catch (err) {
            openWrongFileModal(true);
            return;
        }
        let hasDuplicate = hasDuplicates(allFiles);
        if (hasDuplicate) {
            openDuplicateFileModal(true);
            setLoading(false);
            return;
        }

        setUploadedFiles(allFiles);
        if (onUpload) {
            onUpload(allFiles);
        }
    };

    const onCloseHandler = () => {
        setPageNotVisible(false);
        setUncaughtCornerstoneError(false);
        openNumberMaxLimitModal(false);
        openMinNumberLimitModal(false);
        openWrongDicomModal(false);
        openWrongFileModal(false);
        openDuplicateFileModal(false);
        setUploadedFiles([]);
        if (onUpload) {
            onUpload([]);
        }
    };

    useEffect(() => {
        setDivName('dicomImage' + coronary);

        // Cleanup function to cancel ongoing asynchronous tasks
        return () => {
            window.dispatchEvent(new Event('cancel_loading'));
            setCancelLoading(true);
            setLoading(false);
            setProgress(0);
            setPageNotVisible(false);
            setUncaughtCornerstoneError(false);
            openNumberMaxLimitModal(false);
            openMinNumberLimitModal(false);
            openWrongDicomModal(false);
            openWrongFileModal(false);
            openDuplicateFileModal(false);
            setUploadedFiles([]);
            if (onUpload) {
                onUpload([]);
            }
        };
    }, [coronary]);

    useEffect(() => {
        if (uncaughtCornerstoneError) {
            openWrongDicomModal(true);
            setLoading(false);
            setUploadedFiles([]);
        }
    }, [uncaughtCornerstoneError]);

    return (
        <>
            {renderModals(
                openNumberMaxLimit,
                maxFileUpload,
                openMinNumberLimit,
                isPng,
                openWrongDicom,
                openWrongFile,
                openDuplicateFile,
                pageNotVisible,
                onCloseHandler,
                minFileUpload
            )}
            <SInput
                data-testid="input-file-component"
                accept={acceptedTypes.toString()}
                id={id}
                multiple
                type="file"
                onChange={(e) => onChange(e)}
                onClick={(event) => {
                    event.currentTarget.value = '';
                }}
                disabled={progress != 0 && progress != 100}
            />
            <label htmlFor={id}>
                <UploadButton variant="contained" component="span" disabled={progress != 0 && progress != 100}>
                    <Typography data-testid="input-file-button">{t('inputFile.add')}</Typography>
                </UploadButton>
            </label>
            {loading ? (
                <SCircular>
                    <CircularProgressWithLabel value={progress} />
                </SCircular>
            ) : (
                uploadedFiles && renderFileList(uploadedFiles)
            )}
            {<SHiddenDivForDicom id={divName} />}
        </>
    );
};

export default InputFile;

const renderFileList = (uploadedFiles: File[]) => {
    switch (uploadedFiles.length) {
        case 0:
            return (
                <StyledLabel>
                    <Typography data-testid="input-no-file">{t('inputFile.noFilesSelected')}</Typography>
                </StyledLabel>
            );
        case 1:
            return <ToolTipable value={uploadedFiles[0].fileName} maxLength={'No files selected'.length} />;
        default:
            return (
                <ListToolTipable title={t('inputFile.files')} allValue={uploadedFiles.map((file) => file.fileName)} />
            );
    }
};

const renderModals = (
    openNumberMaxLimit: boolean,
    maxFileUpload: number,
    openMinNumberLimit: boolean,
    isPng: boolean,
    openWrongDicom: boolean,
    openWrongFile: boolean,
    openDuplicateFile: boolean,
    pageNotVisible: boolean,
    onCloseHandler: () => void,
    minFileUpload?: number
) => (
    <>
        {openNumberMaxLimit && (
            <Dialog
                open={openNumberMaxLimit}
                customTitle={t('inputFile.dialogs.warning')}
                text={t('inputFile.dialogs.noMoreThan', { maxFileUpload: maxFileUpload })}
                onClose={onCloseHandler}
                childrenButton={t('inputFile.dialogs.ok')}
            />
        )}
        {openMinNumberLimit && (
            <Dialog
                open={openMinNumberLimit}
                customTitle={t('inputFile.dialogs.warning')}
                text={t('inputFile.dialogs.atLeast', { minFileUpload: minFileUpload })}
                onClose={onCloseHandler}
                childrenButton={t('inputFile.dialogs.ok')}
            />
        )}
        {!isPng && openWrongDicom && (
            <Dialog
                open={openWrongDicom}
                customTitle={t('inputFile.dialogs.error')}
                text={t('inputFile.dialogs.unableParseDicom')}
                onClose={onCloseHandler}
                childrenButton={t('inputFile.dialogs.ok')}
            />
        )}
        {openWrongFile && (
            <Dialog
                open={openWrongFile}
                customTitle={t('inputFile.dialogs.error')}
                text={t('inputFile.dialogs.unableParseFile')}
                onClose={onCloseHandler}
                childrenButton={t('inputFile.dialogs.ok')}
            />
        )}
        {openDuplicateFile && (
            <Dialog
                open={openDuplicateFile}
                customTitle={t('inputFile.dialogs.error')}
                text={t('inputFile.dialogs.duplicateFiles')}
                onClose={onCloseHandler}
                childrenButton={t('inputFile.dialogs.ok')}
            />
        )}
        {pageNotVisible && (
            <Dialog
                open={pageNotVisible}
                customTitle={t('inputFile.dialogs.error')}
                text={t('inputFile.dialogs.pageNotVisible')}
                onClose={onCloseHandler}
                childrenButton={t('inputFile.dialogs.ok')}
            />
        )}
    </>
);

const SHiddenDivForDicom = styled.div`
    visibility: hidden;
    position: fixed;
    width: 2048px;
    height: 2048px;
`;

const SInput = styled.input`
    display: none;
`;

const StyledLabel = styled.label`
    padding: ${(props) => props.theme.getSpacing(0, 2)};
`;

const SCircular = styled.div`
    padding-left: ${(props) => props.theme.getSpacing(4)};
    padding-top: ${(props) => props.theme.getSpacing(1)};
`;

