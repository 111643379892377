import { Dispatch, SetStateAction } from 'react';
import { File } from '@/components/molecules';
import { RenderingEngine, Types, Enums, eventTarget } from '@cornerstonejs/core';
// @ts-ignore
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import * as cornerstoneTools from '@cornerstonejs/tools';
import initProviders from './cornerstone/initProviders';
import initCornerstoneDICOMImageLoader from './cornerstone/initCornerstoneDICOMImageLoader';
import initVolumeLoader from './cornerstone/initVolumeLoader';
import { setUseCPURendering, cache } from '@cornerstonejs/core';

const { ViewportType } = Enums;
const { ToolGroupManager } = cornerstoneTools;
const { IMAGE_RENDERED } = Enums.Events;

interface FilesCoronary {
    [key: string]: File[];
}
let allFiles: FilesCoronary = {
    dicomImageCx: [],
    dicomImageLAD: [],
    dicomImageRCA: [],
    dicomImageNotSpecified: []
};

interface Indice {
    [key: string]: number;
}
let indice: Indice = {
    dicomImageCx: -1,
    dicomImageLAD: -1,
    dicomImageRCA: -1,
    dicomImageNotSpecified: -1
};

let pageNotVisible = false;
let cancelLoading = false;

export const convertDicomsToBase64 = async (
    files: FileList,
    setProgress: Dispatch<SetStateAction<number>>,
    setPageNotVisible: Dispatch<SetStateAction<boolean>>,
    setUncaughtCornerstoneError: Dispatch<SetStateAction<boolean>>,
    element: HTMLDivElement,
    divName: string
): Promise<File[]> => {
    const toolGroupId = 'toolGroupId' + divName;
    const renderingEngineId = 'renderingEngineId' + divName;

    const handleCancelOccurred = () => {
        cancelLoading = true;
        allFiles[divName] = [];
    };

    const visibilityChangeHandler = () => {
        // domCanvas is null when changing tab is no more visible, breaking the conversion from dicom to jpg
        //, so force reupload when changing tab
        if (document.visibilityState != 'visible') {
            pageNotVisible = true;
            setPageNotVisible(true);
            allFiles[divName] = [];
        }
    };

    pageNotVisible = false;
    cancelLoading = false;
    allFiles[divName] = [];
    indice[divName] = -1;

    window.addEventListener('cancel_loading', handleCancelOccurred);
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    async function newImage(evt: Types.EventTypes.ImageRenderedEvent) {
        if (files[indice[divName] + 1]) {
            await new Promise((res) => setTimeout(res, 200));
            let canvas = viewport.getCanvas();
            const res = await fetch(canvas.toDataURL());
            const blob = await res.blob();
            allFiles[divName].push({
                base64: canvas.toDataURL('image/jpeg', 0.25),
                fileName: files[indice[divName] + 1]?.name ?? '', // to avoid console error if leaving page just before this line
                data: blob
            });
            indice[divName] = indice[divName] + 1;
        }
    }

    function imageRenderedListener(evt: Types.EventTypes.ImageRenderedEvent) {
        // Call newImage function and pass the event object
        newImage(evt);
    }

    // @ts-ignore
    element.addEventListener(IMAGE_RENDERED, imageRenderedListener);

    await initCornerstoneDICOMImageLoader();
    await initVolumeLoader();
    await initProviders();
    setProgress(0);
    setUseCPURendering(true);

    const renderingEngine = new RenderingEngine(renderingEngineId);
    const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);

    const viewportId = 'CT_STACK' + divName;
    const viewportInput = {
        viewportId,
        type: ViewportType.STACK,
        element,
        defaultOptions: {
            background: <Types.Point3>[0.2, 0, 0.2]
        }
    };

    renderingEngine.enableElement(viewportInput);

    // Get the stack viewport that was created
    let viewport = <Types.IStackViewport>renderingEngine.getViewport(viewportId);
    if (!toolGroup) {
        return allFiles[divName];
    }

    const exiting = () => {
        if (!renderingEngine.hasBeenDestroyed) {
            renderingEngine.disableElement(viewportId);
            let toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
            toolGroup?.removeViewports(renderingEngineId);
            ToolGroupManager.destroyToolGroup(toolGroupId);
            renderingEngine.destroy();
        }

        document.removeEventListener('visibilitychange', visibilityChangeHandler);
        window.removeEventListener('cancel_loading', handleCancelOccurred);
        window.removeEventListener('unhandledrejection', handleUnhandledRejection);

        //@ts-ignore
        element.removeEventListener(IMAGE_RENDERED, imageRenderedListener);
        while (element.firstElementChild) {
            element.firstElementChild.remove();
        }
        // To verify number of event listeners on canvas, write this in chrome console
        // getEventListeners(document.getElementById('dicomImageNotSpecified'))
        eventTarget.reset();
        cache.purgeCache();
    };
    const handleUnhandledRejection = (event: any) => {
        const reason = event.reason;
        exiting();
        if (reason instanceof Error && reason.stack && reason.stack.includes('Module')) {
            setProgress(0);
            setUncaughtCornerstoneError(true);
        }
    };

    toolGroup.addViewport(viewportId, renderingEngineId);
    let imagesIds: any = [];
    for (let b = 0; b < files.length; b++) {
        const file = files[b];

        const imageId = cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
        imagesIds.push(imageId);
    }

    let wait = 0;
    let maxWait = 15; // Waiting 15 seconds for image to load before throwing error

    // Add the event listener
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    for (let i = 0; i < imagesIds.length; i++) {
        if (pageNotVisible || cancelLoading) {
            indice[divName] = files.length;
            allFiles[divName] = [];
            break;
        }
        if (wait >= maxWait) {
            setProgress(0);
            exiting();
            throw new Error('Waiting too long to display dicom file');
        }
        await new Promise<number>(async (resolve, reject) => {
            await viewport
                .setStack([imagesIds[i]], 0)
                .then(async () => {
                    if (pageNotVisible || cancelLoading) {
                        resolve(i);
                    }
                    viewport.render();

                    const imageData = viewport.getImageData();

                    setProgress(Math.round(((i + 1) / files.length) * 100));
                    wait = 0;
                    while (indice[divName] < i && wait < maxWait) {
                        if (pageNotVisible || cancelLoading) {
                            resolve(i);
                        }
                        // waiting for end of screenshot, current i, indice of screenshot, divName', [divName, i, indice[divName]]);
                        await new Promise((res) => setTimeout(res, 100));
                        wait++;
                    }
                    resolve(i);
                })
                .catch((error) => {
                    exiting();
                    reject(error);
                });
        });
    }

    while (indice[divName] < files.length - 1) {
        // waiting for last file', [indice[divName], files.length]);
        await new Promise((res) => setTimeout(res, 300));
    }

    exiting();

    if (pageNotVisible) {
        return [];
    }
    return allFiles[divName];
};

