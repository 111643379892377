import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import RefreshIcon from '@mui/icons-material/Refresh';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

import { useHistory } from 'react-router-dom';

import { FilterButton, SearchField, Typography } from '@/components/atoms';

import { useSuccess } from '@/utils/hooks/useSuccess';

import { Session, getSessionCookie } from '@/utils/contexts/SessionContext';
import { ClassificationResult, IStoredAnalysis, StoredAnalysisClient } from '@api';
import AnalysisTable from '@/components/molecules/AnalysisTable';
import { FilterNew, FilterRads, FilterDate, FilterDoctor } from '@/components/atoms';
import CardHeader from '@/components/atoms/CardHeader';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import { t } from '@/i18n.js';

type Props = {
    /** Columns to display */
    columnsNeeded: string[];
    nbPages: number;
    isLoadingGraphs: boolean;
    currentId: number;
    setCurrentId: Dispatch<SetStateAction<number>>;
    pdfBlob: Blob;
    isHome: boolean;
    isHidden: boolean;
    currentSessionInCookie: Session;
};

export interface DataObject {
    [key: string]: ClassificationResult;
}

export const AnalysisExplorerComponent = (props: Props) => {
    const {
        columnsNeeded,
        nbPages,
        isLoadingGraphs,
        currentId,
        setCurrentId,
        pdfBlob,
        isHome,
        isHidden,
        currentSessionInCookie
    } = props;
    const history = useHistory();

    const {
        hasFirstLoaded,
        storedAnalysisListContext,
        storedAnalysisIdToReviewContext,
        medicalReportIdAndClassificationResultContext,
        refreshList
    } = useContext(StoredAnalysisListContext);

    const [themeContext, updateTheme] = useThemeContext();

    const [storedAnalysis, setStoredAnalysis] = useState<IStoredAnalysis[]>([]);

    // Filtre NEW -> affichage des analysis non visionnées (ou marquées comme NEW manuellement)
    const onlyNewStored = localStorage.getItem('onlyNew') !== null;
    const [onlyNew, setOnlyNew] = useState<boolean>(onlyNewStored ?? true);

    // Filtre Doctor avec plusieurs choix
    const doctorsFilterStoredBoolean = localStorage.getItem('doctorsFilter') !== null;
    const doctorsFilterStored = doctorsFilterStoredBoolean
        ? JSON.parse(localStorage.getItem('doctorsFilter') || '""')
        : [];
    const [doctorsFilter, setDoctorsFilter] = useState<string[]>(doctorsFilterStored ?? doctorsFilterStoredBoolean);

    // Filtre CAD-RADS avec plusieurs choix
    const multipleFilterStoredBoolean = localStorage.getItem('multipleFilterRads') !== null;
    const multipleFilterStored = multipleFilterStoredBoolean
        ? JSON.parse(localStorage.getItem('multipleFilterRads') || '""')
        : [];
    const [multipleFilter, setMultipleFilter] = useState<string[]>(multipleFilterStored ?? multipleFilterStoredBoolean);

    // Barre de recherche
    const [searchFilter, setSearchFilter] = useState<string>('');

    // Choix du jour
    const [startDate, setStartDate] = useState<null | Date>(null);
    const [endDate, setEndDate] = useState<null | Date>(null);

    //Première analyse du data grid après filtration
    const [firstAnalysisIDfromGrid, setFirstAnalysisIDfromGrid] = useState<number>(0);

    const [isReloadDisable, setIsReloadDisable] = useState<boolean>(false);
    const [isLoading, isSuccess, message, setSuccess] = useSuccess({ isLoading: true, isSuccess: false });

    const [counter, setCounter] = useState<number>(0);

    // Analysis Id with status "To review" (if date is > than stored in Azure variable + no medical report)
    const [storedAnalysisIdToReview, setStoredAnalysisIdToReview] = useState<number[]>([]);
    // Medical report validation result
    const [medicalReportIdAndClassificationResult, setMedicalReportIdAndClassificationResult] = useState<DataObject>(
        {}
    );

    useEffect(() => {
        if (!hasFirstLoaded) {
            var sessionInCookie = getSessionCookie();
            var client = new StoredAnalysisClient(
                { token: sessionInCookie.authResponse.token },
                process.env.BackendServiceURL
            );
            refreshList(client, true);
        }
    }, []);

    useEffect(() => {
        setStoredAnalysis(storedAnalysisListContext);
    }, [storedAnalysisListContext]);

    useEffect(() => {
        if (hasFirstLoaded) {
            setSuccess({ isLoading: false, isSuccess: true });
        }
    }, [hasFirstLoaded, storedAnalysis]);

    useEffect(() => {
        setStoredAnalysisIdToReview(storedAnalysisIdToReviewContext);
    }, [storedAnalysisIdToReviewContext]);

    useEffect(() => {
        setMedicalReportIdAndClassificationResult(medicalReportIdAndClassificationResultContext);
    }, [medicalReportIdAndClassificationResultContext]);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const handleReload = async () => {
        setIsReloadDisable(true);
        setCounter(3);
        setTimeout(() => setIsReloadDisable(false), 3000);
        var sessionInCookie = getSessionCookie();
        var client = new StoredAnalysisClient(
            { token: sessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        refreshList(client, true); // Force reload the analysis list in context
        ReactGA.event({
            category: 'Analysis Explorer',
            action: 'Refresh the data grid'
        });
    };

    return (
        <SContainer>
            {isHome ? (
                <>
                    <CardHeader title={t('analysisExplorer.title')}></CardHeader>
                    <SCardBody>
                        <SFilterDiv
                            onKeyPress={(ev: React.KeyboardEvent) => {
                                if (ev.key === 'Enter' && firstAnalysisIDfromGrid !== 0) {
                                    history.push('/analysis-explorer/' + firstAnalysisIDfromGrid.toString());
                                    ev.preventDefault();
                                }
                            }}
                        >
                            <SFilterSubDiv>
                                <SFilterButtonDiv>
                                    <FilterButton
                                        onClick={handleReload}
                                        disabled={isReloadDisable}
                                        selected={isReloadDisable}
                                    >
                                        {isReloadDisable ? <STypography>{counter}</STypography> : <SRefreshIcon />}
                                    </FilterButton>
                                </SFilterButtonDiv>
                                <SearchField
                                    onChange={setSearchFilter}
                                    searchToOpen={firstAnalysisIDfromGrid.toString()}
                                />
                            </SFilterSubDiv>
                            <SFilterSubDiv>
                                <FilterDate
                                    startDate={startDate}
                                    setStartDate={setStartDate}
                                    endDate={endDate}
                                    setEndDate={setEndDate}
                                />
                                <FilterNew onlyNew={onlyNew} setOnlyNew={setOnlyNew} />
                                <FilterDoctor doctorsFilter={doctorsFilter} setDoctorsFilter={setDoctorsFilter} />
                                <FilterRads multipleFilter={multipleFilter} setMultipleFilter={setMultipleFilter} />
                            </SFilterSubDiv>
                        </SFilterDiv>
                    </SCardBody>
                    <AnalysisTable
                        success={{ isLoading, isSuccess, message }}
                        storedAnalysis={storedAnalysis}
                        storedAnalysisIdToReview={storedAnalysisIdToReview}
                        medicalReportIdAndClassificationResult={medicalReportIdAndClassificationResult}
                        columnsNeeded={columnsNeeded}
                        nbPages={nbPages}
                        isLoading={isLoadingGraphs}
                        currentId={currentId}
                        setCurrentId={() => {}}
                        onlyNew={onlyNew}
                        multipleFilter={multipleFilter}
                        doctorsFilter={doctorsFilter}
                        searchFilter={searchFilter}
                        startDate={startDate}
                        endDate={endDate}
                        setFirstAnalysisIDfromGrid={setFirstAnalysisIDfromGrid}
                        currentSessionInCookie={currentSessionInCookie}
                        themeContext={themeContext}
                        isTableHome={true}
                    />
                </>
            ) : (
                <>
                    <SContainerTableAnalysisExplorer>
                        <SFilterHideOverflow>
                            <SFilterReduceDivMargin>
                                <FilterNew onlyNew={onlyNew} setOnlyNew={setOnlyNew} />
                                <FilterDoctor doctorsFilter={doctorsFilter} setDoctorsFilter={setDoctorsFilter} />
                                <FilterRads multipleFilter={multipleFilter} setMultipleFilter={setMultipleFilter} />
                            </SFilterReduceDivMargin>
                        </SFilterHideOverflow>
                        {!isHidden && ( // Fix width error by rendering component only if displayed
                            <AnalysisTable
                                success={{ isLoading, isSuccess, message }}
                                storedAnalysis={storedAnalysis}
                                storedAnalysisIdToReview={[]}
                                medicalReportIdAndClassificationResult={medicalReportIdAndClassificationResult}
                                columnsNeeded={columnsNeeded}
                                nbPages={nbPages}
                                isLoading={isLoadingGraphs}
                                currentId={currentId}
                                setCurrentId={setCurrentId}
                                onlyNew={onlyNew}
                                multipleFilter={multipleFilter}
                                searchFilter={searchFilter}
                                doctorsFilter={doctorsFilter}
                                startDate={null}
                                endDate={null}
                                setFirstAnalysisIDfromGrid={() => {}}
                                currentSessionInCookie={currentSessionInCookie}
                                themeContext={themeContext}
                                isTableHome={false}
                            />
                        )}
                    </SContainerTableAnalysisExplorer>
                </>
            )}
        </SContainer>
    );
};

export default AnalysisExplorerComponent;

const SContainer = styled.div`
    left: 0;
    height: 80vh;
    width: 100%;
    margin: ${(props) => props.theme.getSpacing(2)};
    background-color: ${(props) => props.theme.table.headerBackground};
    border-radius: 10px;
`;

const SContainerTableAnalysisExplorer = styled.div`
    background-color: ${(props) => props.theme.miniTable.cardBackground};
    border-radius: 10px;
    width: 100%;
`;

const SFilterHideOverflow = styled.div`
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    *::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar {
        display: none;
    }
`;

const SCardBody = styled.div`
    background-color: ${(props) => props.theme.table.headerBackground};
    color: ${(props) => props.theme.table.headerText};
`;
const SFilterDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: ${(props) => props.theme.getSpacing(3)};
    margin: ${(props) => props.theme.getSpacing(2)};
`;

const SFilterReduceDivMargin = styled.div`
    margin: ${(props) => props.theme.getSpacing(1)};
    padding: ${(props) => props.theme.getSpacing(2, 0, 0, 0)};
    width: 100%;
    display: inline-flex;
    overflow-x: auto;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    *::-webkit-scrollbar {
        display: none;
    }
`;

const SFilterSubDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const STypography = styled(Typography)`
    color: ${(props) => props.theme.table.select.text};
    width: 22px;
`;

const SRefreshIcon = styled(RefreshIcon)`
    width: 45px;
`;

const SFilterButtonDiv = styled.div`
    width: 60px;
    margin-left: 16px;
`;

