import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';

import { Typography } from '@/components/atoms';
type Props = {
    /**The value to display */
    value: string;
    /**The maximal length to stop by */
    maxLength: number;
};

const ToolTipable = (props: Props) => {
    const { value, maxLength } = props;
    if (value.length > maxLength) {
        return (
            <Tooltip title={value}>
                <StyledLabel>
                    <Typography>{`${value.slice(0, maxLength - 3)}...`}</Typography>
                </StyledLabel>
            </Tooltip>
        );
    } else {
        return (
            <StyledLabel>
                <Typography data-testid="input-short-filename">{value}</Typography>
            </StyledLabel>
        );
    }
};

export default ToolTipable;

const StyledLabel = styled.label`
    padding: ${(props) => props.theme.getSpacing(0, 2)};
`;

