import React, { useState, useEffect, useContext } from 'react';
import { AnalysisExplorerComponent } from '@/components/organisms';

import styled from 'styled-components';
import { Session, getSessionCookie } from '@/utils/contexts/SessionContext';
import { StoredAnalysisClient } from '@api';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Props = {
    currentSessionInCookie: Session;
};

export const Home = (props: Props) => {
    const { currentSessionInCookie } = props;

    const [currentId, setCurrentId] = useState(0);
    const { refreshList, storedAnalysisListContext } = useContext(StoredAnalysisListContext);

    var sessionInCookie = getSessionCookie();

    const downloadNewPacsResults = async () => {
        var client = new StoredAnalysisClient(
            { token: sessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        let analysisIdsFromPacsInDb = await client.getAllIdsFromPacs();

        const inAppSet = new Set(storedAnalysisListContext.map((item) => item.id));

        // Use filter to find elements in db that are not in local storage
        const elementsNotInApp = analysisIdsFromPacsInDb.filter((item) => !inAppSet.has(item));

        const numberOfElementsNotInApp = elementsNotInApp.length;
        if (numberOfElementsNotInApp > 0) {
            refreshList(client, true);
        }
    };

    useEffect(() => {
        // On home page, reload the pacs analysis 2 seconds after the page has loaded
        const firstHomeLoading = setTimeout(async () => {
            await downloadNewPacsResults();
        }, 2000);
        // On home page, reload the pacs analysis every 15 seconds
        const fakeWebSocket = setInterval(async () => {
            await downloadNewPacsResults();
        }, 15000);

        return () => {
            clearTimeout(firstHomeLoading);
            clearTimeout(fakeWebSocket);
        };
    });

    return (
        <SHomePage>
            <AnalysisExplorerComponent
                columnsNeeded={[
                    'id',
                    'createdOn',
                    'label',
                    'reader',
                    'memo',
                    'classificationResult',
                    'status',
                    'Notes',
                    'actions'
                ]}
                nbPages={30}
                isLoadingGraphs={true}
                currentId={currentId}
                setCurrentId={setCurrentId}
                pdfBlob={new Blob()}
                isHome={true}
                isHidden={false}
                currentSessionInCookie={currentSessionInCookie}
            />
        </SHomePage>
    );
};

export default Home;

const SHomePage = styled.div`
    display: flex;
    padding: 10px 30px 10px 30px;
    width: 100%;
    height: 100%;
`;

