export const colors = {
    white: '#FFFFFF',
    whitehover: '#F8F8F8',
    black: '#020202',
    lightgrey: '#E1E4E8',
    lightgreybis: '#ECECEE',
    grey: '#F0F0F0',
    grey3d: '#AAAAAA',
    darkgrey: '#757575',
    strongdarkgrey: '#525252',
    lightblue: '#EDF4FB',
    blue: '#55B9F3',
    bluejean: '#0CB4FF',
    electricblue: '#3C6BFC',
    skyblue: '#D6F0FF',
    green: '#3DD968', //CAD-RADS 0
    greenPastel: '#98ECB0', //FFR > 0.75 - FFR Negative
    greenHover: '#309044',
    orangeWarning: '#FF943C',
    orange: '#FF8A00', //CAD-RADS 2
    orangeHover: '#BB6105',
    gold: '#FFD700',
    yellow: '#FFE000', //CAD-RADS 1
    yellowStenosis: '#929170', //'#e3c802', //'#C1AE00', //CAD-RADS 1
    yellowBackgroundStenosis: '#f6e777',
    yellowBackgroundRads: '#ffe105',
    yellowHover: '#C0B500',
    pinkred: '#F64A77',
    red: '#FF0000', //CAD-RADS 3
    redHover: '#CD2A00',
    darkred: '#BA0000', //CAD-RADS 4
    darkredHover: '#981E00',
    strongdarkred: '#600500', //CAD-RADS 5
    strongdarkredHover: '#4F1400',
    redmunsell: '#F80040',
    darkblueFFR: '#2B3A89', //FFR < 0.75 - FFR Positive
    oxfordblue: '#011936',
    prussianblue: '#022E64',
    darkblue: '#052F62',
    darkblueunhover: '#052f6296',
    darkpurple: '#32009E',
    darkpurpleHover: '#200066',
    unhover: '#00000057',
    notSelected: '#9A9292',
    selected: '#474747',
    utility: {
        backdrop: 'rgba(5, 11, 32, 0.5)'
    }
};

export default colors;

