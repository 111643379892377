import React, { useState, useEffect } from 'react';
import i18n, { t } from '@/i18n.js';
import { saveAs } from 'file-saver';
import { ButtonBlue, ButtonBlueWithIcon, CircularProgress } from '@/components/atoms';
import GetAppIcon from '@mui/icons-material/GetApp';

import { IStoredAnalysis } from '@api';
import { Language } from '@/utils/Language';

type Props = {
    storedAnalysis: IStoredAnalysis;
    pdfBlob: Blob;
};

export const DownloadPDF = (props: Props) => {
    const { storedAnalysis, pdfBlob } = props;

    /** Téléchargement du PDF sans graph */
    const [pdfAvailable, setPdfAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Création du PDF
    useEffect(() => {
        if (storedAnalysis !== null && pdfBlob !== null) {
            (async function iieFunction() {
                setPdfAvailable(true);
            })();
        }
    }, [pdfBlob]);

    /** Téléchargement de l'analyse */
    const handleDownload = async () => {
        setIsLoading(true);

        const fileName =
            (i18n.language as Language) == Language.French
                ? 'rapport-CorEx-' + storedAnalysis.id
                : 'CorEx-report-' + storedAnalysis.id;
        saveAs(pdfBlob, fileName);
        setPdfAvailable(true);
        setIsLoading(false);
    };

    return (
        <>
            {!isLoading ? (
                <ButtonBlueWithIcon
                    icon={<GetAppIcon />}
                    text={t('pdfCreation.downloadPDF')}
                    onClick={handleDownload}
                    disabled={!pdfAvailable}
                />
            ) : (
                <ButtonBlue>
                    <CircularProgress />
                </ButtonBlue>
            )}
        </>
    );
};

export default DownloadPDF;

