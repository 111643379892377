import React from 'react';
import styled from 'styled-components';
import { ClassificationResult } from '@api';
import { getBackgroundColorByRadsBlock, getColorByRadsBlock } from '@/utils/ResultsRadsStenosisColors';

type Props = {
    /**CAD-RADS Value */
    cadRadsValue: ClassificationResult | null;
};

type SCadRadsProps = {
    color: string;
    bg: string;
};

export const overrideIntermediateCadRads = (cadRadsValue: ClassificationResult): ClassificationResult => {
    if (cadRadsValue == ClassificationResult.Rads12) {
        return ClassificationResult.Rads2;
    } else if (cadRadsValue == ClassificationResult.Rads23) {
        return ClassificationResult.Rads3;
    } else if (cadRadsValue == ClassificationResult.Rads34) {
        return ClassificationResult.Rads4;
    }
    return cadRadsValue;
};

export const CadRadsDisplay = (props: Props) => {
    const { cadRadsValue } = props;

    return cadRadsValue != null ? (
        <SCadRads bg={getBackgroundColorByRadsBlock(cadRadsValue)} color={getColorByRadsBlock(cadRadsValue)}>
            {overrideIntermediateCadRads(cadRadsValue)}
        </SCadRads>
    ) : (
        <SCadRads bg="grey" color="white">
            No result
        </SCadRads>
    );
};

export default CadRadsDisplay;

const SCadRads = styled.div<SCadRadsProps>`
    display: flex;
    border-radius: ${(props) => props.theme.getSpacing(1)};
    color: ${(props: SCadRadsProps) => props.color};
    padding: ${(props) => props.theme.getSpacing(1, 2, 1, 2)};
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
    background-color: ${(props: SCadRadsProps) => props.bg};
    user-select: none;
`;

