import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { AuthenticateResponse, AuthorizedApiBase, IConfig } from '@api';
import { nullAuthResponse } from '@/utils/contexts/SessionContext';

type Props = {
    accessToken: string;
    refreshToken: string;
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export class RefreshJwtService extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    async RefreshJwt(props: Props): Promise<AuthenticateResponse> {
        const { accessToken, refreshToken } = props;
        try {
            const url_ = this.baseUrl + `/users/refresh-token`;

            let options_ = <AxiosRequestConfig>{
                method: 'POST',
                url: url_,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                data: {
                    accessToken: accessToken,
                    refreshToken: refreshToken
                }
            };
            var response = await this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        return _error.response;
                    } else {
                        throw _error;
                    }
                });

            if (response.status != 200) {
                return response.data;
            }
            return response.data;
        } catch (err) {
            return new AuthenticateResponse(nullAuthResponse);
        }
    }
}
