import React from 'react';
import IconButton from '@mui/material/IconButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import styled from 'styled-components';

type Props = {
    fontSize?: string;
    padding?: string;
    setCurrentSubPageOpened: React.Dispatch<React.SetStateAction<string>>;
};

export const OpenPdfField = (props: Props) => {
    const { fontSize, padding, setCurrentSubPageOpened } = props;

    let spadding = padding;
    if (spadding == undefined) {
        spadding = '12px';
    }

    return (
        <SIconButton onClick={() => setCurrentSubPageOpened('pdf')}>
            <SPictureAsPdfIcon sx={{ fontSize: fontSize, padding: spadding }} />
        </SIconButton>
    );
};

export default OpenPdfField;

const SIconButton = styled(IconButton)`
    padding: 0 !important;
`;

const SPictureAsPdfIcon = styled(PictureAsPdfIcon)`
    color: ${(props) => props.theme.table.contentLightText};
`;

