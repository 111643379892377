import React from 'react';
import { Button as DefaultMaterialButton } from '@mui/material/';
import styled from 'styled-components';

export const ButtonLDMode = styled(({ ...otherProps }) => <DefaultMaterialButton {...otherProps} />)`
    && {
        text-transform: none;
        background-color: ${(props) => props.theme.buttonLDMode.background};
        color: ${(props) => props.theme.buttonLDMode.text};
        filter: ${(props) => props.theme.buttonLDMode.filter};
        padding: 0;
        margin: 0;
        width: 100%;
        border-radius: 10px;
        &:hover {
            background-color: ${(props) => props.theme.buttonLDMode.hover.background};
        }
        &&:disabled {
            color: ${(props) => props.theme.buttonLDMode.disabled.text};
            background-color: ${(props) => props.theme.buttonLDMode.disabled.background};
        }
    }
`;

export default ButtonLDMode;

