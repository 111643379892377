import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import {
    DataGrid,
    GridColumns,
    GridSortModel,
    GridValueGetterParams,
    GridCellModesModel,
    GridCellParams
} from '@mui/x-data-grid';
import i18n, { t } from '@/i18n.js';
import { Theme } from '@mui/material/styles';

import ErrorIcon from '@mui/icons-material/Error';
import { Equalizer as EqualizerIcon } from '@mui/icons-material';

import { ButtonBlueWithIcon, DateTimeField, Loader, Typography } from '@/components/atoms';

import { Operation } from '@/utils/hooks/useSuccess';
import {
    TupleOfUserAndDoctorsListWithoutUserDetailOfAndUsers_SettingsAndStoredAnalysis_UsersWithoutUserDetailOfAndUser_StorageOf as TupleOneUser,
    TupleOfUserOfAndDoctorsListWithoutUserDetailOfAndUsers_SettingsOfAndStoredAnalysis_UsersWithoutUserDetailOfAndUser_StorageOf as TupleAllUsers,
    Users_Settings
} from '@api';

import noDataRows from '@/utils/animations/no-data-rows.json';

import UserBlock from '../atoms/AnalysisTable/UserBlock';
import { useHistory } from 'react-router-dom';

import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';

type Props = {
    success: Operation;
    searchFilter: string;
    nbPages: number;
    usersDoctorsSettings: TupleAllUsers | undefined;
    setFirstUserIDfromGrid: Dispatch<SetStateAction<number>>;
};

export interface StyleProps {
    root_icon_sort: string;
    root_table_header_background: string;
    root_table_header_text: string;
    root_table_content_background: string;
    root_table_content_text: string;
    root_table_content_divider: string;
    root_cell_edit_background: string;
}

export const AdminTableHome = (props: Props) => {
    const { success, searchFilter, nbPages, usersDoctorsSettings, setFirstUserIDfromGrid } = props;
    const history = useHistory();

    const [themeContext, updateTheme] = useThemeContext();

    const [filteredResults, setFilteredResults] = useState<TupleOneUser[]>();
    const [allResults, setAllResults] = useState<TupleOneUser[]>();
    const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
    const [error, setError] = useState<boolean | null>();
    const [pageSize, setPageSize] = useState(nbPages);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'id', sort: 'desc' }]);

    const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

    const propsStyle: StyleProps = {
        root_icon_sort: themeContext.isLightMode ? lightTheme.table.iconSortColor : darkTheme.table.iconSortColor,
        root_table_header_background: themeContext.isLightMode
            ? lightTheme.table.headerBackground
            : darkTheme.table.headerBackground,
        root_table_header_text: themeContext.isLightMode ? lightTheme.table.headerText : darkTheme.table.headerText,
        root_table_content_background: themeContext.isLightMode
            ? lightTheme.table.contentBackground
            : darkTheme.table.contentBackground,
        root_table_content_text: themeContext.isLightMode ? lightTheme.table.contentText : darkTheme.table.contentText,
        root_table_content_divider: themeContext.isLightMode
            ? lightTheme.table.contentDivider
            : darkTheme.table.contentDivider,
        root_cell_edit_background: themeContext.isLightMode
            ? lightTheme.table.editBackground
            : darkTheme.table.editBackground
    };
    const classes = useStyles(propsStyle);

    useEffect(() => {
        if (!success.isSuccess && !success.isLoading) {
            setError(true);
        } else {
            setError(null);
        }
    }, [success.isSuccess, success.isLoading]);

    const onSortModelChange = useCallback((params) => {
        setSortModel(params);
    }, []);

    let columns: GridColumns = useMemo(() => {
        let col: GridColumns = [];
        let columnsRaw: GridColumns = [
            {
                field: 'id',
                flex: 0.7,
                headerName: t('admin.list.userId'),
                renderCell: (params) => (
                    <>
                        {(params.row as TupleOneUser).item1.deletedOn == null ? (
                            <UserBlock id={(params.row as TupleOneUser).item1.id.toString()} />
                        ) : (
                            <div style={{ color: 'red' }}>DELETED</div>
                        )}
                    </>
                ),
                disableColumnMenu: true
            },
            {
                field: 'email',
                flex: 2,
                headerName: t('admin.list.email'),
                type: 'string',
                valueGetter: (params: GridValueGetterParams) => (params.row as TupleOneUser).item1.email ?? '',
                disableColumnMenu: true
            },
            {
                field: 'firstName',
                flex: 1.5,
                headerName: t('admin.list.firstName'),
                type: 'string',
                valueGetter: (params: GridValueGetterParams) => (params.row as TupleOneUser).item1.firstName ?? '',
                disableColumnMenu: true
            },
            {
                field: 'lastName',
                flex: 1.5,
                headerName: t('admin.list.lastName'),
                type: 'string',
                valueGetter: (params: GridValueGetterParams) => (params.row as TupleOneUser).item1.lastName ?? '',
                disableColumnMenu: true
            },
            {
                field: 'lastLogin',
                flex: 1,
                headerName: t('admin.list.lastLogin'),
                type: 'date',
                renderCell: (params: GridCellParams) => (
                    <DateTimeField date={(params.row as TupleOneUser).item1.lastLogin} />
                ),
                valueGetter: (params: GridCellParams) =>
                    `${new Date((params.row as TupleOneUser).item1.lastLogin || '')}`
            },
            {
                field: 'isFFRAvailable',
                flex: 0.5,
                headerName: t('admin.list.ffrAvailable'),
                type: 'string',
                valueGetter: (params: GridValueGetterParams) =>
                    (params.row as TupleOneUser).item3.isFFRAvailable ? 'yes' : 'no'
            },
            {
                field: 'licenseExpireDate',
                flex: 1,
                headerName: t('admin.list.licenceExpiration'),
                type: 'date',
                renderCell: (params: GridCellParams) => (
                    <DateTimeField date={(params.row as TupleOneUser).item3.licenseExpireDate} />
                ),
                valueGetter: (params: GridCellParams) =>
                    `${new Date((params.row as TupleOneUser).item3.licenseExpireDate || '')}`
            },
            {
                field: 'lastLoginVersion',
                flex: 1,
                headerName: t('admin.list.lastLoginVersion'),
                type: 'string',
                valueGetter: (params: GridValueGetterParams) =>
                    (params.row as TupleOneUser).item3.lastLoginVersion ?? '',
                disableColumnMenu: true
            },
            {
                field: 'statistics',
                headerName: t('admin.list.statistics'),
                flex: 1,
                headerAlign: 'center',
                renderCell: (params: GridCellParams) => {
                    if ((params.row as TupleOneUser).item4.length > 0) {
                        return (
                            <ButtonBlueWithIcon
                                icon={<EqualizerIcon />}
                                text={(params.row as TupleOneUser).item4.length.toString()}
                                onClick={() =>
                                    history.push('/admin/statistics/user/' + (params.row as TupleOneUser).item1.id)
                                }
                                disabled={(params.row as TupleOneUser).item4.length == 0}
                            />
                        );
                    }
                },
                disableColumnMenu: true
            },
            {
                field: 'doctorsNb',
                flex: 1,
                headerName: t('admin.list.doctorsNb'),
                align: 'center',
                valueGetter: (params: GridValueGetterParams) => (params.row as TupleOneUser).item2.length
            },
            {
                field: 'hospital-aetitle',
                flex: 3,
                headerName: 'hospital-aetitle',
                align: 'left',
                renderCell: (params: GridCellParams) => {
                    if ((params.row as TupleOneUser).item5.length > 0) {
                        const rowObject = params.row as TupleOneUser;

                        // Filter the item5 array to get elements with userId === 1
                        const filteredItems = rowObject.item5; //.filter((item) => item.userId === 1);

                        // Create a string representation of the filtered items
                        const filteredItemsString = filteredItems
                            .map((item) => `${item.hospital}-${item.aeTitle}`)
                            .join(' | ');

                        return (
                            <>
                                <div style={{ fontSize: '11px', whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
                                    {filteredItemsString}
                                </div>
                            </>
                        );
                    }
                }
            }
        ];
        return columnsRaw;
    }, [i18n.language, selectedRows]);

    useEffect(() => {
        if (usersDoctorsSettings != undefined) {
            let allUsersDoctorsSettings = usersDoctorsSettings;
            let rows = new Array<TupleOneUser>();
            allUsersDoctorsSettings.item1.forEach((user) => {
                let doctorsListOfUser = allUsersDoctorsSettings.item2.filter((doctors) => doctors.userId == user.id);
                let userSettingsOfUser =
                    allUsersDoctorsSettings.item3.find((usersSettings) => usersSettings.userId == user.id) ??
                    new Users_Settings({
                        userId: user.id,
                        isFFRAvailable: false,
                        isExplicabilityAvailable: false
                    });
                let storedAnalysis_UserOfUser = allUsersDoctorsSettings.item4.filter(
                    (storedAnalysis_User) => storedAnalysis_User.userId == user.id
                );
                let storageOfUser = allUsersDoctorsSettings.item5.filter(
                    (user_storage) => user_storage.userId == user.id
                );
                rows.push(
                    new TupleOneUser({
                        item1: user,
                        item2: doctorsListOfUser,
                        item3: userSettingsOfUser,
                        item4: storedAnalysis_UserOfUser,
                        item5: storageOfUser
                    })
                );
            });
            if (rows.length > 0) {
                setAllResults(rows);
                setFilteredResults(rows);
            }
        }
    }, [usersDoctorsSettings]);

    useEffect(() => {
        if (searchFilter !== '' && allResults != undefined) {
            var usersLength = allResults.length;
            var filtered = allResults.filter((row) =>
                (
                    row.item1.email.toString() +
                    row.item1.firstName.toLocaleLowerCase() +
                    row.item1.lastName.toLocaleLowerCase()
                ).includes(searchFilter.toLocaleLowerCase().trim())
            );
            if (filtered.length === usersLength) {
                setFilteredResults(filtered);
                setFirstUserIDfromGrid(0);
            } else if (filtered.length > 0) {
                setFilteredResults(filtered);
                setFirstUserIDfromGrid(filtered[filtered.length - 1]?.item1?.id);
            } else if (filtered.length === 0) {
                setFilteredResults(filtered);
                setFirstUserIDfromGrid(0);
            }
        } else {
            setFirstUserIDfromGrid(0);
            setFilteredResults(allResults);
        }
    }, [searchFilter]);

    return (
        <SContainer>
            <>
                {success.isLoading ? (
                    <></>
                ) : (
                    <>
                        {filteredResults != undefined ? (
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    getRowId={(row) => row.item1.id}
                                    className={classes.root}
                                    rowHeight={60}
                                    rows={filteredResults}
                                    rowSpacingType={'border'}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[1, 5, 15, 30, 50, 100]}
                                    columns={columns}
                                    scrollbarSize={17}
                                    loading={success.isLoading}
                                    error={error}
                                    disableSelectionOnClick
                                    cellModesModel={cellModesModel}
                                    experimentalFeatures={{ newEditingApi: true }}
                                    sortModel={sortModel}
                                    onSortModelChange={onSortModelChange}
                                    componentsProps={{
                                        pagination: {
                                            labelRowsPerPage: t('analysisExplorer.analysisTable.pagination.rowsPerPage')
                                        }
                                    }}
                                    components={{
                                        NoRowsOverlay: () => (
                                            <SLoaderDiv>
                                                <Loader jsonToUse={noDataRows} width="30vw" />
                                            </SLoaderDiv>
                                        ),
                                        ErrorOverlay: () => (
                                            <SErrorContainer>
                                                <ErrorIcon />
                                                <Typography variant="h3">{t('An error occured...')}</Typography>
                                            </SErrorContainer>
                                        )
                                    }}
                                />
                            </div>
                        ) : (
                            <> NO USER </>
                        )}
                    </>
                )}
            </>
        </SContainer>
    );
};

export default AdminTableHome;

const SContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    user-select: none; //Empêche l'utilisateur de sélectionner le texte
    *::-webkit-scrollbar,
    *::-webkit-scrollbar-thumb {
        width: 17px;
        border-radius: 13px;
        background-clip: padding-box;
        border: 5px solid transparent;
        color: rgba(0, 0, 0, 0.2);
    }

    *::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 0 10px;
    }
    *::-webkit-scrollbar-thumb:hover {
        color: rgba(0, 0, 0, 0.3);
    }
    *::-webkit-scrollbar-thumb:active {
        color: rgba(0, 0, 0, 0.4);
    }
`;

const SErrorContainer = styled.div`
    text-align: center;
    width: 50%;
    margin: auto;
    padding: ${(props) => props.theme.getSpacing(4, 0)};
`;

const SLoaderDiv = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    root: {
        '& .MuiDataGrid-sortIcon': {
            color: ({ root_icon_sort }) => root_icon_sort
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-colCell:focus': {
            outline: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            visibility: 'hidden'
        },
        '&.MuiDataGrid-root': {
            border: 'none',
            width: '100%',
            backgroundColor: ({ root_table_header_background }) => root_table_header_background,
            color: ({ root_table_header_text }) => root_table_header_text
        },
        '& .MuiDataGrid-root, .MuiDataGrid-cell': {
            borderBottom: '0px !important'
        },
        '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none',
            marginLeft: '8px'
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: '0px solid'
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: '0px solid'
        },

        '& .MuiDataGrid-row': {
            border: 'none',
            outline: 'none',
            borderRadius: 10,
            marginBottom: 15,
            marginLeft: 8,
            maxWidth: '98%',
            backgroundColor: ({ root_table_content_background }) => root_table_content_background,
            color: ({ root_table_content_text }) => root_table_content_text,
            borderBottom: ({ root_table_content_divider }) => root_table_content_divider
        },
        '& .MuiDataGrid-cell': {
            border: 'none'
        },
        '& .MuiDataGrid-cell--editable': {
            cursor: 'pointer'
        },
        '& .MuiDataGrid-footerContainer': {
            borderTop: 'none'
        },
        '& .MuiTablePagination-root': {
            color: ({ root_table_header_text }) => root_table_header_text
        },
        '& .MuiTablePagination-selectIcon': {
            color: ({ root_table_header_text }) => root_table_header_text
        },
        '& .Mui-disabled': {
            color: ({ root_table_header_text }) => root_table_header_text + ' !important'
        },
        minHeight: '75vh'
    },
    row: {
        '&.MuiDataGrid-row': {
            backgroundColor: 'rgba(0, 0, 0, 0.07)'
        }
    }
}));

