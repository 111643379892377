import React from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';

import { Typography } from '@/components/atoms';
type Props = {
    /**The value to display when tooltiped */
    title: string;
    /**The maximal length to stop by */
    allValue: string[];
};

const ListToolTipable = (props: Props) => {
    const { title, allValue } = props;

    return (
        <Tooltip
            title={allValue.map((value) => (
                <div key={value}>{value}</div>
            ))}
        >
            <StyledLabel>
                <Typography>
                    {allValue.length} {title}
                </Typography>
            </StyledLabel>
        </Tooltip>
    );
};

export default ListToolTipable;

const StyledLabel = styled.label`
    padding: ${(props) => props.theme.getSpacing(0, 2)};
`;

