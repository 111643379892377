import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { t } from '@/i18n.js';
import FileOpenIcon from '@mui/icons-material/FileOpen';

import { LocationStateStoredAnalysis } from '@/pages/DisplayPdf';
import { StoredAnalysis } from '@api';
import { Session } from '@/utils/contexts/SessionContext';
import styled from 'styled-components';

type Props = {
    /**The analysis to open*/
    storedAnalysis: StoredAnalysis;
    fontSize?: string;
    padding?: string;
    currentSessionInCookie: Session;
};

export const OpenAnalysisField = (props: Props) => {
    const { storedAnalysis, fontSize, padding, currentSessionInCookie } = props;

    const history = useHistory<LocationStateStoredAnalysis>();

    const handleClick = useCallback(async () => {
        history.push({
            pathname: `/analysis-explorer/${storedAnalysis.id}`,
            search: '?subpage=PDF'
        });
    }, [storedAnalysis.id, currentSessionInCookie]);

    let spadding = padding;
    if (spadding == undefined) {
        spadding = '12px';
    }

    return (
        <Tooltip title={t('analysisExplorer.analysisTable.openAnalysis') || ''}>
            <SIconButton onClick={() => handleClick()}>
                <SFileOpenIcon sx={{ fontSize: fontSize, padding: spadding }} />
            </SIconButton>
        </Tooltip>
    );
};

export default OpenAnalysisField;

const SIconButton = styled(IconButton)`
    padding: 0 !important;
`;

const SFileOpenIcon = styled(FileOpenIcon)`
    color: ${(props) => props.theme.table.contentLightText};
`;

