import React, { createContext, useState, useMemo, ReactNode, useContext, useEffect } from 'react';
import { getSessionCookie } from './SessionContext';
import { IStoredAnalysis, StoredAnalysisClient, ClassificationResult } from '@api';

export interface DataObject {
    [key: string]: ClassificationResult;
}

interface IStoredAnalysisListContext {
    storedAnalysisListContext: IStoredAnalysis[];
    setStoredAnalysisListContext: React.Dispatch<React.SetStateAction<IStoredAnalysis[]>>;
    storedAnalysisIdToReviewContext: number[];
    medicalReportIdAndClassificationResultContext: DataObject;
    refreshList: (client: StoredAnalysisClient, forceReload: boolean | undefined) => Promise<void>;
    hasFirstLoaded: boolean;
    eraseStoredAnalysisListContext: () => void;
}

const StoredAnalysisListContext = createContext<IStoredAnalysisListContext>({
    storedAnalysisListContext: [],
    setStoredAnalysisListContext: () => {},
    storedAnalysisIdToReviewContext: [],
    medicalReportIdAndClassificationResultContext: {},
    refreshList: async (client: StoredAnalysisClient, forceReload: boolean | undefined) => {},
    hasFirstLoaded: false,
    eraseStoredAnalysisListContext: () => {}
});

const StoredAnalysisListProvider = ({ children }: { children: ReactNode }, client?: StoredAnalysisClient) => {
    const [hasFirstLoaded, setHasFirstLoaded] = useState<boolean>(false);
    const [hasBeenCalledOnce, setHasBeenCalledOnce] = useState<boolean>(false);

    const [storedAnalysisList, setStoredAnalysisListContext] = useState<IStoredAnalysis[]>([]);
    const [storedAnalysisIdToReviewContext, setStoredAnalysisIdToReviewContext] = useState<number[]>([]);
    const [medicalReportIdAndClassificationResultContext, setMedicalReportIdAndClassificationResultContext] =
        useState<DataObject>({});

    const memoizedStoreAnalysisList = useMemo(() => storedAnalysisList, [storedAnalysisList]) as IStoredAnalysis[];
    const memoizedToReview = useMemo(
        () => storedAnalysisIdToReviewContext,
        [storedAnalysisIdToReviewContext]
    ) as number[];
    const memoizedMedicalReport = useMemo(
        () => medicalReportIdAndClassificationResultContext,
        [medicalReportIdAndClassificationResultContext]
    ) as DataObject;

    const refreshList = async (client: StoredAnalysisClient, forceReload = false) => {
        var sessionInCookie = getSessionCookie();
        // Only fetch the data from the backend if it hasn't already been loaded
        if (
            sessionInCookie?.authResponse?.token != '' &&
            sessionInCookie?.authResponse?.token != 'Bearer' &&
            ((!hasFirstLoaded && !hasBeenCalledOnce) || forceReload)
        ) {
            setHasBeenCalledOnce(true);
            const response = await client.getAllAndAwaitingReview();
            setStoredAnalysisListContext(response.storedAnalysisResponsesList);
            setStoredAnalysisIdToReviewContext(response.storedAnalysisIdAwaitingReview);
            const list = response.medicalReportIdAndClassificationResultList;
            let dataObject: DataObject = {};
            // Transform the Tuple in object, and remove all the results "Not set" to avoid errors
            for (let i = 0; i < list.length; i++) {
                if (list[i].item2 != ClassificationResult.NotSet) {
                    dataObject[list[i].item1.toString()] = list[i].item2;
                }
            }
            setMedicalReportIdAndClassificationResultContext(dataObject);
            setHasFirstLoaded(true);
        }
    };

    const eraseStoredAnalysisListContext = (): void => {
        const emptyStoredAnalysisList: IStoredAnalysis[] = [];
        let emptyReportIdAndClassificationResult: DataObject = {};
        setStoredAnalysisListContext(emptyStoredAnalysisList);
        setStoredAnalysisIdToReviewContext([]);
        setMedicalReportIdAndClassificationResultContext(emptyReportIdAndClassificationResult);
        setHasFirstLoaded(false);
        setHasBeenCalledOnce(false);
    };

    useEffect(() => {
        if (!hasBeenCalledOnce) {
            var sessionInCookie = getSessionCookie();
            // Call the refreshList function once when the component mounts
            if (sessionInCookie?.authResponse?.token != '' && sessionInCookie?.authResponse?.token != 'Bearer') {
                refreshList(
                    new StoredAnalysisClient(
                        { token: sessionInCookie.authResponse.token },
                        process.env.BackendServiceURL
                    )
                );
            }
        }
    }, [hasBeenCalledOnce]);

    return (
        <StoredAnalysisListContext.Provider
            value={{
                storedAnalysisListContext: memoizedStoreAnalysisList,
                setStoredAnalysisListContext,
                storedAnalysisIdToReviewContext: memoizedToReview,
                medicalReportIdAndClassificationResultContext: memoizedMedicalReport,
                refreshList,
                hasFirstLoaded,
                eraseStoredAnalysisListContext
            }}
        >
            {children}
        </StoredAnalysisListContext.Provider>
    );
};

export { StoredAnalysisListProvider, StoredAnalysisListContext };

export const useStoredAnalysisList = () => useContext(StoredAnalysisListContext);

