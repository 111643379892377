import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Session } from '@/utils/contexts/SessionContext';
import { GetAllStoredAnalysis } from '@/services/StoredAnalysisService';
import { PieChartStatisticsHome } from '@/components/molecules/PieChartStatisticsHome';
import { LineChartStatisticsHome } from '@/components/molecules/LineChartStatisticsHome';
import { initialStoredAnalysis } from '@/pages/DisplayPdf';

type Props = {
    currentSessionInCookie: Session;
};

export const StatisticsHome = (props: Props) => {
    const { currentSessionInCookie } = props;
    const [isStatsLoading, setIsStatsLoading] = useState(false);
    const [stAnalysis, setStAnalysis] = useState([initialStoredAnalysis]);

    // Set the year
    const [year, setYear] = useState<string>(new Date().getFullYear().toString());

    useEffect(() => {
        (async function iieFunction() {
            setIsStatsLoading(true);
            const localStAnalysis = await GetAllStoredAnalysis({ sessionContext: currentSessionInCookie });
            setStAnalysis(localStAnalysis);
            setIsStatsLoading(false);
        })();
    }, []);

    return (
        <SStatisticsContainer>
            <SPieContainer>
                <PieChartStatisticsHome
                    isStatsLoading={isStatsLoading}
                    stAnalysis={stAnalysis}
                    year={year}
                    setYear={setYear}
                />
            </SPieContainer>
            <SLineContainer>
                <LineChartStatisticsHome
                    isStatsLoading={isStatsLoading}
                    stAnalysis={stAnalysis}
                    year={year}
                    setYear={setYear}
                />
            </SLineContainer>
        </SStatisticsContainer>
    );
};

export default StatisticsHome;

const SStatisticsContainer = styled.div`
    display: flex;
    align-items: start;
    flex-direction: row;
    justify-content: space-around;
    color: ${(props) => props.theme.page.text};
`;

const SPieContainer = styled.div`
    background-color: ${(props) => props.theme.page.background};
    color: ${(props) => props.theme.page.text};
    border-radius: 20px;
    margin: 2%;
    width: 28vw;
`;

const SLineContainer = styled.div`
    background-color: ${(props) => props.theme.page.background};
    color: ${(props) => props.theme.page.text};
    border-radius: 20px;
    margin: 2%;
`;

