import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@/components/atoms';

type Props = {
    title: string;
};

export const CardHeader = (props: Props) => {
    const { title } = props;
    return <STypography sx={{ fontWeight: 500 }}>{title}</STypography>;
};

export default CardHeader;

const STypography = styled(Typography)`
    && {
        font-weight: 500;
        background-color: ${(props) => props.theme.card.headerBackground};
        color: ${(props) => props.theme.card.headerBlueTitle};
        font-size: 1.2rem;
        padding: 20px 0 20px 20px;
        border-radius: 10px 10px 0px 0px;
    }
`;

