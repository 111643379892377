import * as React from 'react';
import Lottie from 'react-lottie-player';

type Props = {
    /** Json file used to display the animation */
    jsonToUse: object;
    /**Width of the animation */
    width?: string;
    /** Height of the animation */
    height?: string;
    /** Speed of the animation */
    speed?: number;
};

export const Loader = (props: Props) => {
    const { jsonToUse, width, height, speed } = props;
    return <Lottie loop animationData={jsonToUse} play style={{ width: width, height: height }} speed={speed} />;
};
export default Loader;
