import React from 'react';
import styled from 'styled-components';

type Props = {
    /**The analysis date to display*/
    reader?: string | undefined;
    hasBeenOpened?: boolean | undefined;
};

export const ReaderField = (props: Props) => {
    const { reader, hasBeenOpened } = props;

    return <>{!hasBeenOpened ? <SBoldDiv>{reader}</SBoldDiv> : <SLightDiv>{reader}</SLightDiv>}</>;
};

export default ReaderField;

const SBoldDiv = styled.div`
    font-weight: bold;
    color: ${(props) => props.theme.table.contentLightText};
`;

const SLightDiv = styled.div`
    font-weight: normal;
    color: ${(props) => props.theme.table.contentLightText};
`;

