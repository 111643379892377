import React from 'react';

import { t } from '@/i18n.js';

import { Typography } from '@/components/atoms';
import { Dialog } from '@/components/molecules';
import { PasswordModification } from '@/components/organisms';
import { Session } from '@/utils/contexts/SessionContext';


type Props = {
    /**Will open the CGU Dialog if true */
    isOpen: boolean;
    /**Will open the CGU Dialog if true */
    isFirstLogin: boolean;
    /**Function executed on close */
    onClose: () => void;
    currentSessionInCookie: Session;
};

export const PasswordChangeDialog = (props: Props) => {
    const { isOpen, isFirstLogin, onClose, currentSessionInCookie } = props;

    return (
        <Dialog
            open={isOpen}
            customTitle={t('loginPage.passwordChange.title')}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
            disableChildrenButton
            width={'40%'}
            childrenButton={null}
        >
            <>
                {isFirstLogin ? (
                    <Typography title variant="h3">
                        {t('loginPage.passwordChange.firstTimeLogin')}
                    </Typography>
                ) : (
                    <Typography title variant="h3">
                        {t('loginPage.passwordChange.expiredPassword')}
                    </Typography>
                )}
                <PasswordModification onSuccess={onClose} currentSessionInCookie={currentSessionInCookie} />
            </>
        </Dialog>
    );
};

export default PasswordChangeDialog;

