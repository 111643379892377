import React, { useCallback, useContext, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { t } from '@/i18n.js';

import DeleteIcon from '@mui/icons-material/Delete';

import ReactGA from 'react-ga';

import { Typography } from '@/components/atoms';
import { Dialog } from '@/components/molecules';
import { DeleteAnalysisService } from '@/services/DeleteAnalysisService';

import { Session } from '@/utils/contexts/SessionContext';

import { StoredAnalysis, IStoredAnalysis } from '@api';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Props = {
    /**The analysis to open*/
    currentStored: StoredAnalysis;
    currentSessionInCookie: Session;
};

export const DeleteAnalysisField = (props: Props) => {
    const { currentStored, currentSessionInCookie } = props;
    const { setStoredAnalysisListContext, storedAnalysisListContext } = useContext(StoredAnalysisListContext);

    const [isLoading, setIsLoading] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(!dialogOpen);
    };

    const onDelete = useCallback(async () => {
        setIsLoading(true);
        let storedAnalysisList = [currentStored.id];
        const deleteStoredAnalysis = new DeleteAnalysisService(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        await deleteStoredAnalysis.DeleteStoredAnalysis({ storedAnalysisList });
        setDialogOpen(false);
        const updatedRows = storedAnalysisListContext.filter((row: IStoredAnalysis) => row.id !== currentStored.id);
        setStoredAnalysisListContext(updatedRows);
        ReactGA.event({
            category: 'Analysis Explorer',
            action: 'Delete analysis'
        });
    }, [storedAnalysisListContext, currentSessionInCookie]);

    return (
        <>
            <Dialog
                open={dialogOpen}
                customTitle={t('analysisExplorer.analysisTable.deleteDialogWarning')}
                text={t('analysisExplorer.analysisTable.areYouSureYouWantToDelete', { id: currentStored.id })}
                onClose={onDelete}
                disableChildrenButton={isLoading}
                childrenButton={
                    isLoading ? (
                        <Typography variant="button">{t('analysisExplorer.analysisTable.loading')}</Typography>
                    ) : (
                        <Typography red variant="button">
                            {t('analysisExplorer.analysisTable.delete')}
                        </Typography>
                    )
                }
                withCancel
                disableEscapeKeyDown
                handleCancel={handleClick}
            />
            <Tooltip title={t('analysisExplorer.analysisTable.deleteAnalysis') || ''}>
                <IconButton onClick={handleClick}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default DeleteAnalysisField;

