import React from 'react';
import styled from 'styled-components';
import { Typography, StatisticsCounter, CircularProgress } from '@/components/atoms';

type Props = {
    /**Label to display next to the value */
    label: string;
    /** The final number to reach */
    number: number;
    /** Display circular progress if loading */
    isStatsLoading: boolean;
    /**Color for the Typograhy label */
    color?: 'red' | 'orange' | 'green';
};

const StatisticsValue = (props: Props) => {
    const { label, number, isStatsLoading, color } = props;

    return (
        <SCard>
            <Typography green={color === 'green'} orange={color === 'orange'} red={color === 'red'} variant="h3">
                {label}
            </Typography>
            {isStatsLoading ? <CircularProgress /> : <StatisticsCounter number={number} duration={3} />}
        </SCard>
    );
};

export default StatisticsValue;

const SCard = styled.div`
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding: ${(props) => props.theme.getSpacing(10)};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 20px 20px 60px #f0f0f0, -20px -20px 60px #ffffff;
    margin: ${(props) => props.theme.getSpacing(5)};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
`;

