import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClassificationResult, StoredAnalysis, StoredAnalysisClient } from '@api';
import { CircularProgress, Typography } from '@/components/atoms';
import { Session, getSessionCookie } from '@/utils/contexts/SessionContext';
import { stenosisClassification } from '@/components/organisms/AnalysisReportCompImages';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { overrideIntermediateCadRads } from '../CadRadsDisplay';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Props = {
    removeStenosisText?: boolean;
    /**The corresponding stored analysis*/
    storedAnalysis: StoredAnalysis;
    currentSessionInCookie: Session;
};

export const ClassificationResultField = (props: Props) => {
    const { removeStenosisText, storedAnalysis, currentSessionInCookie } = props;
    const { refreshList } = useContext(StoredAnalysisListContext);

    const [result, setResult] = useState<ClassificationResult>(ClassificationResult.NotSet);
    const [visibleStenosisText, setVisibleStenosisText] = useState<boolean>(true);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (storedAnalysis.classificationResult !== ClassificationResult.NotSet) {
            setResult(storedAnalysis.classificationResult);
            if (removeStenosisText) {
                setVisibleStenosisText(false);
            }
            setIsLoading(false);
        }
        // Loader if ClassificationResult.NotSet and refresh list after 10 sec
        else {
            try {
                var sessionInCookie = getSessionCookie();
                var client = new StoredAnalysisClient(
                    { token: sessionInCookie.authResponse.token },
                    process.env.BackendServiceURL
                );

                setTimeout(function () {
                    refreshList(client, true);
                }, 10000);
            } catch (err) {
                setIsLoading(true);
            }
        }
    }, [storedAnalysis.classificationResult]);

    return <>{isLoading ? <CircularProgress /> : getColorForClassification(result, visibleStenosisText)}</>;
};
export default ClassificationResultField;

const getColorForClassification = (classRes: ClassificationResult, visibleStenosisText: boolean) => {
    if (classRes != ClassificationResult.NotSet && classRes != ClassificationResult.Error) {
        return (
            <>
                {visibleStenosisText ? (
                    <SDivCenter>
                        <Typography chosenColor={getColorByRads(classRes)} lightbold>
                            {overrideIntermediateCadRads(classRes)}
                        </Typography>
                        <SText>{stenosisClassification(classRes)}</SText>
                    </SDivCenter>
                ) : (
                    <SDivLeft>
                        <STypography chosenColor={getColorByRads(classRes)} lightbold>
                            {overrideIntermediateCadRads(classRes)}
                        </STypography>
                    </SDivLeft>
                )}
            </>
        );
    }
};

const SDivCenter = styled.div`
    text-align: center;
`;

const SDivLeft = styled.div`
    text-align: start;
`;

const SText = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.table.contentLightText};
    margin-top: -4px;
    font-weight: 400;
`;

const STypography = styled(Typography)`
    && {
        font-size: 12px !important;
    }
`;

