import React from 'react';
import { Alert, AlertColor, AlertProps, Snackbar as MaterialSnackbar, SnackbarCloseReason } from '@mui/material';
import { SnackbarProps } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';

type TransitionProps = Omit<SlideProps, 'direction'>;

type Props = {
    /**Message inside of the snackbar */
    message?: string;
    /**Autohide Duration */
    customAutoHideDuration?: number;
    /**Severity (aspect/color/icon) of the alert
     * error / warning / info / success
     */
    severity?: AlertColor;
    /**On close */
    handleClose: ((event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void) | undefined;
} & SnackbarProps &
    AlertProps;

function TransitionRight(props: TransitionProps) {
    return <Slide {...props} direction="right" />;
}

const Snackbar = (props: Props) => {
    const { open, message, customAutoHideDuration, severity, onClose, handleClose } = props;
    return (
        <>
            <MaterialSnackbar
                open={open}
                autoHideDuration={customAutoHideDuration ?? 5000}
                onClose={handleClose}
                TransitionComponent={TransitionRight}
            >
                <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </MaterialSnackbar>
        </>
    );
};

export default Snackbar;

