import React, { useEffect } from 'react';
import { Typography } from '@/components/atoms';
import styled from 'styled-components';
import { MenuItem } from '@mui/material';
import { t } from '@/i18n.js';

import { ClassificationResult } from '@api';

import { Select, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';

import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';

type Props = {
    multipleFilter: string[];
    setMultipleFilter: React.Dispatch<React.SetStateAction<string[]>>;
};

type STypographyDivProps = {
    color: string;
};

type SCadRadsProps = {
    color: string;
};

export const FilterRads = (props: Props) => {
    const { multipleFilter, setMultipleFilter } = props;
    const [themeContext, updateTheme] = useThemeContext();

    /** JSON array options de filte */
    let cadRadsOptions = [
        ClassificationResult.Rads0,
        ClassificationResult.Rads1,
        ClassificationResult.Rads2,
        ClassificationResult.Rads3,
        ClassificationResult.Rads4,
        ClassificationResult.Rads5
    ];

    useEffect(() => {
        localStorage.setItem('multipleFilterRads', JSON.stringify(multipleFilter));
    }, [multipleFilter]);

    const handleChange = (event: SelectChangeEvent<typeof multipleFilter>) => {
        const {
            target: { value }
        } = event;
        setMultipleFilter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(' - ') : value
        );
    };

    return (
        <SFormControlDiv>
            <SFormControl sx={{ width: '80%' }} size="small">
                <InputLabel sx={{ fontSize: '1rem' }}>{t('analysisExplorer.analysisTable.radsFilter')}</InputLabel>
                <Select
                    multiple
                    fullWidth
                    value={multipleFilter}
                    onChange={handleChange}
                    label={'RADS Filter'}
                    input={<OutlinedInput label="RADS Filter" />}
                    inputProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: {
                                    color: themeContext.isLightMode
                                        ? lightTheme.table.select.text
                                        : darkTheme.table.select.text,
                                    backgroundColor: themeContext.isLightMode
                                        ? lightTheme.table.select.list.background
                                        : darkTheme.table.select.list.background
                                }
                            }
                        }
                    }}
                    renderValue={(selected) => (
                        <SRow>
                            {selected
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((value) => (
                                    <SCadRads key={value} color={getColorByRads(value)}>
                                        {value.split(' ')[1]}
                                    </SCadRads>
                                ))}
                        </SRow>
                    )}
                >
                    {cadRadsOptions.map((item, index) => (
                        <MenuItem key={index} value={item}>
                            <STypographyDiv color={getColorByRads(item)}>
                                <Typography>{item}</Typography>
                            </STypographyDiv>
                        </MenuItem>
                    ))}
                </Select>
            </SFormControl>
        </SFormControlDiv>
    );
};

export default FilterRads;

const SFormControlDiv = styled.div`
    width: 140px;
`;

const SFormControl = styled(FormControl)`
    padding-left: ${(props) => props.theme.getSpacing(1)};
    &.MuiFormControl-root {
        width: 95%;
        min-width: 140px;
        border-radius: 10px;
    }
    & .MuiOutlinedInput-root {
        background-color: ${(props) => props.theme.table.select.background};
        border-radius: 10px;
        & input {
            color: ${(props) => props.theme.table.select.text};
        }
        & fieldset {
            border-color: ${(props) => props.theme.table.select.borderColor};
            border-radius: 10px;
        }
        &:hover fieldset {
            border-color: ${(props) => props.theme.table.select.borderColorHover};
        }
        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.table.select.borderColorHover};
        }
        &::selection fieldset {
            border-color: ${(props) => props.theme.table.select.borderColorHover};
        }
    }
    & label {
        color: ${(props) => props.theme.table.select.text};
    }
    & label.Mui-focused {
        color: ${(props) => props.theme.table.select.text};
    }
    & .MuiSvgIcon-root {
        fill: ${(props) => props.theme.table.select.iconFill};
    }
    & .MuiFormLabel-root {
        color: ${(props) => props.theme.table.select.text};
    }
    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        color: ${(props) => props.theme.colors.blue};
    }
`;

const STypographyDiv = styled.div<STypographyDivProps>`
    color: ${(props: STypographyDivProps) => props.color};
`;

const SRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const SCadRads = styled.div<SCadRadsProps>`
    display: flex;
    border-radius: ${(props) => props.theme.getSpacing(1)};
    color: ${(props) => props.theme.colors.white};
    padding: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
    margin: ${(props) => props.theme.getSpacing(0, 0.5, 0, 0.5)};
    background-color: ${(props: SCadRadsProps) => props.color};
    user-select: none;
    width: fit-content;
`;

