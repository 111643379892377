import { StoredAnalysis, StoredAnalysisClient, StoredAnalysisCreationRequest, AuthorizedApiBase, IConfig } from '@api';
import { cleanLocalStorage, logoutFunc, Session } from '@/utils/contexts/SessionContext';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

/**
 * The Singleton class defines the `getInstance` method that lets clients access
 * the unique singleton instance.
 */
export class SingletonStoredAnalysisClient {
    private static instance: StoredAnalysisClient;
    private static token: string;
    private static url: string | undefined;

    private constructor() {}

    public static getInstance(token: string, url: string | undefined): StoredAnalysisClient {
        if (!SingletonStoredAnalysisClient.instance) {
            SingletonStoredAnalysisClient.token = token;
            SingletonStoredAnalysisClient.url = url;
            SingletonStoredAnalysisClient.instance = new StoredAnalysisClient({ token }, url);
        } else if (SingletonStoredAnalysisClient.token !== token || SingletonStoredAnalysisClient.url !== url) {
            SingletonStoredAnalysisClient.instance = new StoredAnalysisClient({ token }, url);
        }
        return SingletonStoredAnalysisClient.instance;
    }
}

type Props = {
    /**Session context containing token and container name */
    sessionContext: Session;
};

export const GetAllStoredAnalysis = async (props: Props) => {
    const { sessionContext } = props;

    const client = SingletonStoredAnalysisClient.getInstance(
        sessionContext.authResponse.token,
        process.env.BackendServiceURL
    );

    // Get all analysis of connected user
    return await client.getAll();
};

export const GetAllStoredAnalysisAndAwaitingReview = async (props: Props) => {
    const { sessionContext } = props;

    const client = SingletonStoredAnalysisClient.getInstance(
        sessionContext.authResponse.token,
        process.env.BackendServiceURL
    );

    // Get all analysis of connected user, Awaiting review indication, and medical validation
    // Awaiting review is calculated in back for this page, but in Analysis details page it is calculated in front only via existence of medicalReportId
    return await client.getAllAndAwaitingReview();
};

type DetailProps = {
    /**Analysis Id to fetch */
    analysisId: number;
} & Props;

export const GetStoredAnalysisDetail = async (props: DetailProps) => {
    const { sessionContext, analysisId } = props;
    const client = SingletonStoredAnalysisClient.getInstance(
        sessionContext.authResponse.token,
        process.env.BackendServiceURL
    );
    return await client
        .getDetail(analysisId)
        .then((_response) => {
            return _response;
        })
        .catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                    console.log('Invalid Bearer Token');
                    logoutFunc();
                    cleanLocalStorage();
                    location.reload();
                }
            }
            throw _error;
        });
};

export const SaveHighlighted = async (props: DetailProps) => {
    const { sessionContext, analysisId } = props;

    const client = SingletonStoredAnalysisClient.getInstance(
        sessionContext.authResponse.token,
        process.env.BackendServiceURL
    );

    return await client.saveHighlighted(analysisId);
};

type CreateProps = {
    /**Analysis */
    analysisCreationRequest: StoredAnalysisCreationRequest;
} & Props;

export const CreateStoredAnalysis = async (props: CreateProps) => {
    const { sessionContext, analysisCreationRequest } = props;

    const client = SingletonStoredAnalysisClient.getInstance(
        sessionContext.authResponse.token,
        process.env.BackendServiceURL
    );

    return await client.create(analysisCreationRequest);
};

type SaveStringProps = {
    /**Analysis Id to fetch */
    analysisId: number;
    /**Value for new label */
    value: string;
} & Props;

type SaveStringPropsAxios = {
    /**Analysis Id to fetch */
    analysisId: number;
    /**Value for new label */
    value: string;
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export class StoredAnalysisServiceAxios extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    async sendRequest(options_: AxiosRequestConfig) {
        var response = await this.transformOptions(options_)
            .then((transformedOptions_) => {
                return this.instance.request(transformedOptions_);
            })
            .catch((_error: any) => {
                if (isAxiosError(_error) && _error.response) {
                    if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                        console.log('Invalid Bearer Token');
                        logoutFunc();
                        cleanLocalStorage();
                        location.reload();
                    }
                    return _error.response;
                } else {
                    throw _error;
                }
            });
        return response.status;
    }

    async saveLabel(props: SaveStringPropsAxios) {
        const { analysisId, value } = props;

        const url_ = this.baseUrl + `/StoredAnalysis/save-label`;

        let options_ = <AxiosRequestConfig>{
            method: 'POST',
            url: url_,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            data: {
                analysisId: analysisId,
                label: value
            }
        };
        return this.sendRequest(options_);
    }

    async saveMemo(props: SaveStringPropsAxios) {
        const { analysisId, value } = props;

        const url_ = this.baseUrl + `/StoredAnalysis/save-memo`;

        let options_ = <AxiosRequestConfig>{
            method: 'POST',
            url: url_,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            data: {
                analysisId: analysisId,
                memo: value
            }
        };
        return this.sendRequest(options_);
    }

    async saveNote(props: SaveStringPropsAxios) {
        const { analysisId, value } = props;

        const url_ = this.baseUrl + `/StoredAnalysis/save-note`;

        let options_ = <AxiosRequestConfig>{
            method: 'POST',
            url: url_,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            data: {
                analysisId: analysisId,
                note: value
            }
        };
        return this.sendRequest(options_);
    }
}

export const SaveReader = async (props: SaveStringProps) => {
    const { sessionContext, analysisId, value } = props;

    const client = SingletonStoredAnalysisClient.getInstance(
        sessionContext.authResponse.token,
        process.env.BackendServiceURL
    );

    return await client.saveReader(analysisId, value);
};

type StoredAnalysisWithSessionProps = {
    /**Analysis*/
    storedAnalysis: StoredAnalysis;
} & Props;

export const UpdateClassificationResult = async (props: StoredAnalysisWithSessionProps) => {
    const { sessionContext, storedAnalysis } = props;

    const client = SingletonStoredAnalysisClient.getInstance(
        sessionContext.authResponse.token,
        process.env.BackendServiceURL
    );

    return await client.updateClassificationResult(storedAnalysis);
};

