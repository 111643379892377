import { AuthorizedApiBase, IConfig } from '@/../generated/api.client';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { DbEntry } from '@/components/molecules/AnalysisTable';

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

type PropsRunDicomServer = {
    email: string;
    password: string;
};

export class HybridService extends AuthorizedApiBase {
    private instance: AxiosInstance;

    constructor(configuration: IConfig, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
    }

    async CheckServerUp(): Promise<number> {
        try {
            const url_ = process.env.DICOM_SERVER_URL + '/db';

            let options_ = <AxiosRequestConfig>{
                method: 'GET',
                url: url_
            };

            var response = await this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        return _error.response;
                    } else {
                        throw _error;
                    }
                });
            return response.status;
        } catch (err) {
            console.error(err);
            return 0;
        }
    }

    async GetLocalDb(): Promise<Array<DbEntry>> {
        try {
            const url_ = process.env.DICOM_SERVER_URL + '/db';

            let options_ = <AxiosRequestConfig>{
                method: 'GET',
                url: url_
            };

            var response = await this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        return _error.response;
                    } else {
                        throw _error;
                    }
                });

            if (response.status != 200) {
                return response.data;
            }
            return response.data;
        } catch (err) {
            return [];
        }
    }

    async RunDicomServer(props: PropsRunDicomServer) {
        const { email, password } = props;
        try {
            const url_ = process.env.DICOM_SERVER_URL + '/start_dicom';

            let options_ = <AxiosRequestConfig>{
                method: 'POST',
                url: url_,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({ email: email, password: password })
            };

            var response = await this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        return _error.response;
                    } else {
                        throw _error;
                    }
                });

            if (response.status != 200) {
                return response.data;
            }
            console.log('Logged into DICOM Cloud Server');
            return response.data;
        } catch (err) {
            return [];
        }
    }
}

