import React, { useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import { Tooltip, Popper, IconButton, ClickAwayListener, TextField, Paper } from '@mui/material';
import { t } from '@/i18n.js';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

import NotesIcon from '@mui/icons-material/Notes';

import { Session } from '@/utils/contexts/SessionContext';
import { useStoredAnalysisContext } from '@/utils/contexts/StoredAnalysisContext';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { StoredAnalysisServiceAxios } from '@/services/StoredAnalysisService';

import { Badge } from '@mui/material';
import { StoredAnalysis } from '@api';
import { Theme } from '@mui/material/styles';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
    /**Analysis */
    currentStoredAnalysis: StoredAnalysis;
    /**Analysis' Note */
    storedAnalysisNote: string;
    currentSessionInCookie: Session;
};

export interface StyleProps {
    root_label_color: string;
}

export const NoteField = (props: Props) => {
    const { currentStoredAnalysis, storedAnalysisNote, currentSessionInCookie } = props;
    const { setStoredAnalysisListContext, storedAnalysisListContext } = useContext(StoredAnalysisListContext);

    const [themeContext, updateTheme] = useThemeContext();

    const [open, setOpen] = useState(false);
    const [note, setNote] = useState(storedAnalysisNote);
    const [snackbarContext, updateSnackbar] = useSnackbarContext();

    const propsStyle: StyleProps = {
        root_label_color: themeContext.isLightMode ? lightTheme.page.text : darkTheme.page.invertedText
    };
    const classes = useStyles(propsStyle);

    let ref = useRef(null);

    const handleClick = () => {
        setOpen(!open);
        (async function iie() {
            let oldNote = storedAnalysisNote;

            const storedAnalysisService = new StoredAnalysisServiceAxios(
                { token: currentSessionInCookie.authResponse.token },
                process.env.BackendServiceURL
            );

            if (note !== oldNote) {
                try {
                    storedAnalysisService
                        .saveNote({
                            analysisId: currentStoredAnalysis.id,
                            value: note
                        })
                        .then((response) => {
                            if (response == 400 || response == 500) {
                                if (response == 400) {
                                    updateSnackbar({
                                        snackString: t('snackbar.error.note.validation'),
                                        severity: 'error'
                                    });
                                } else {
                                    updateSnackbar({
                                        snackString: t('snackbar.error.note.generic'),
                                        severity: 'error'
                                    });
                                }
                                setNote(oldNote);
                                setOpen(true);
                            } else {
                                setNote(note);
                                let updatedRows = [];
                                for (const stored of storedAnalysisListContext) {
                                    let s = stored;
                                    if (s.id == currentStoredAnalysis.id) {
                                        s.note = note;
                                    }
                                    updatedRows.push(s);
                                }
                                setStoredAnalysisListContext(updatedRows);
                                updateSnackbar({
                                    snackString: t('snackbar.success.note.saved'),
                                    severity: 'success'
                                });
                            }
                        });
                } catch (err) {
                    console.log(err);
                }
            }
        })();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNote(event.target.value);
    };

    const handleFocus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const lengthOfInput = event.target.value.length;
        return event.target.setSelectionRange(lengthOfInput, lengthOfInput);
    };

    return (
        <SContainer>
            <STooltip title={t('analysisExplorer.analysisTable.openNotes') || ''}>
                <IconButton onClick={() => setOpen(true)}>
                    <Badge color="primary" variant="dot" invisible={note === null || note === ''}>
                        <SNotesIcon ref={ref} />
                    </Badge>
                </IconButton>
            </STooltip>
            <Popper open={open} anchorEl={ref.current}>
                <ClickAwayListener onClickAway={handleClick}>
                    <SPaper elevation={20}>
                        <STextField
                            className={classes.root}
                            defaultValue={note}
                            label={t('analysisExplorer.analysisTable.noteNumber', { id: currentStoredAnalysis.id })}
                            variant="outlined"
                            multiline
                            onChange={handleChange}
                            placeholder={t('analysisExplorer.analysisTable.addNotes')}
                            rows={3}
                            // rowsMax={10}
                            autoFocus={true}
                            inputProps={{ maxLength: 5000 }} // Front and back validation can differ depending on bytes => ask a lower max in the front
                            onFocus={handleFocus}
                            helperText={
                                note != null && note.length === 5000
                                    ? t('analysisExplorer.analysisTable.limitNotes')
                                    : ''
                            }
                        />
                    </SPaper>
                </ClickAwayListener>
            </Popper>
        </SContainer>
    );
};

export default NoteField;

const SNotesIcon = styled(NotesIcon)`
    color: ${(props) => props.theme.table.contentLightText};
`;

const SContainer = styled.div`
    display: flex;
    z-index: 1000;
`;

const SPaper = styled(Paper)`
    && {
        background-color: ${(props) => props.theme.table.noteTextField.outline};
        padding: ${(props) => props.theme.getSpacing(3)};
        border-radius: 10px;
        width: 28rem;
        z-index: 1000;
    }
`;

const STooltip = styled(Tooltip)`
    && {
        z-index: 1000;
    }
`;

const STextField = styled(TextField)`
    && {
        background-color: ${(props) => props.theme.table.noteTextField.background};
        width: 100%;
        border-radius: 5px;
    }
`;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    root: {
        '& .MuiFormLabel-root': {
            color: ({ root_label_color }) => root_label_color
        }
    }
}));

