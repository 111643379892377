import React, { useEffect, useState } from 'react';
import { Typography } from '@/components/atoms';

type Props = {
    /** The final number to reach */
    number: number;
    /** Total duration of the animation in **seconds** */
    duration: number;
};

const StatisticsCounter = (props: Props) => {
    const { number, duration } = props;
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        if (start === number) {
            return;
        }
        let incrementTime = (duration / number) * 1000;
        let timer = setInterval(() => {
            start += 1;
            setCount(start);
            if (start === number) {
                clearInterval(timer);
            }
        }, incrementTime);
    }, [number, duration]);

    if (number === 0) {
        return <Typography variant="h2">0</Typography>;
    }
    return <Typography variant="h2">{count}</Typography>;
};

export default StatisticsCounter;
