import CircularProgressWithLabel from '@/components/molecules/CircularProgressWithLabel';
import Dialog from '@/components/molecules/Dialog';
import InputFile from '@/components/molecules/InputFile';
import { File } from '@/components/molecules/InputFile';
import ProtectedRoute from '@/components/molecules/ProtectedRoute';
import ProtectedRouteAdmin from '@/components/molecules/ProtectedRouteAdmin';
import ErrorMessage from '@/components/molecules/ErrorMessage';
import LanguageSwitcher from '@/components/molecules/LanguageSwitcher';
import DarkModeSwitcher from '@/components/molecules/DarkModeSwitcher';
import PieChartStatistics from '@/components/molecules/PieChartStatistics';
import CoronariesDetails from '@/components/molecules/CoronariesDetails';
import DialogAnalysis from '@/components/molecules/DialogAnalysis';
import AnalysisTable from '@/components/molecules/AnalysisTable';
import AdminTableHome from '@/components/molecules/AdminTableHome';
import CalcificationRadsMedicalReport from '@/components/molecules/CalcificationRadsMedicalReport';

export {
    CircularProgressWithLabel,
    Dialog,
    InputFile,
    File,
    ProtectedRoute,
    ProtectedRouteAdmin,
    ErrorMessage,
    LanguageSwitcher,
    DarkModeSwitcher,
    PieChartStatistics,
    DialogAnalysis,
    CoronariesDetails,
    AnalysisTable,
    AdminTableHome,
    CalcificationRadsMedicalReport
};

