import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { t } from '@/i18n.js';
import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router';

import ReactGA from 'react-ga';

//MUI Icons
import {
    ExitToApp as LogoutIcon,
    HelpOutline as HelpIcon,
    Settings as SettingsIcon,
    ImportContacts as BookIcon,
    Archive as ArchiveIcon,
    Mail as MailIcon,
    Equalizer as EqualizerIcon,
    Person as PersonIcon,
    GavelRounded as GavelIcon
} from '@mui/icons-material';
import BugReportIcon from '@mui/icons-material/BugReport';

//MUI
import { Menu, MenuItem, SvgIconProps } from '@mui/material';
import Divider from '@mui/material/Divider';

import { roundButton, Typography, CircularProgress } from '@/components/atoms';
import { useSessionContext, Session } from '@/utils/contexts/SessionContext';
import { useCurrentDoctorContext } from '@/utils/contexts/CurrentDoctorContext';

//Screenshot
import html2canvas from 'html2canvas';
import { useScreenshotContext } from '@/utils/contexts/ScreenshotContext';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Item = {
    link: string;
    icon: React.ReactElement<SvgIconProps>;
    subtitle: string;
};

type Props = {
    currentSessionInCookie: Session;
};

export const NavBarRigth = (props: Props) => {
    const { currentSessionInCookie } = props;

    const [, , logout] = useSessionContext();
    const history = useHistory();

    const { eraseStoredAnalysisListContext } = React.useContext(StoredAnalysisListContext);
    const [currentDoctorContext] = useCurrentDoctorContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [screenshotContext, updateScreenshot] = useScreenshotContext();
    const [isScreenshotLoading, setIsScreenshotLoading] = useState<boolean>(false);
    const [role, setRole] = useState<string>('');

    // Afficher le nom de l'utilisateur
    const nameUser = currentSessionInCookie.isAuthenticated
        ? JSON.parse(localStorage.getItem('current_doctor') || '""').name // Fix Unexpected end of JSON input
        : '';

    // URL de la page actuelle
    const [currentUrl, setCurrentUrl] = useState<string>(
        window.location.href.split('/')[window.location.href.split('/').length - 1]
    );

    //URL de la page actuelle  (si id ou autre après nom de page)
    const [currentUrlWithId, setCurrentUrlWithId] = useState<string>(
        window.location.href.split('/')[window.location.href.split('/').length - 2]
    );

    //URL de la page actuelle pour download-analysis (si id ou autre après nom de page)
    const [currentUrlWithIdDownload, setCurrentUrlWithIdDownload] = useState<string>(
        window.location.href.split('/')[window.location.href.split('/').length - 3]
    );

    const [expanded, setExpanded] = useState<boolean>(false);

    // Liste des pages sur lesquelles ne pas afficher le composant même si utilisateur connecté
    const pagesToHideAll = ['doctor-selection', 'login', 'download-analysis'];

    const url = useParams();

    const onClickLogout = () => {
        logout();
        eraseStoredAnalysisListContext();
        handleClose();
        ReactGA.event({
            category: 'Connexion',
            action: 'Logout'
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setExpanded(false);
    };

    const expandHelp = () => {
        setExpanded(!expanded);
    };

    const takeScreen = () => {
        setIsScreenshotLoading(true);

        html2canvas(document.body, {
            allowTaint: true,
            useCORS: true
        }).then((canvas) => {
            const image = canvas.toDataURL('image/png', 0.5);

            updateScreenshot({
                screenString: Object.values({ image })[0]
            });
            setIsScreenshotLoading(false);
            // Close the navbar right
            setAnchorEl(null);
            history.push('/bugs/send-email');
        });
    };

    useEffect(() => {
        if (
            currentSessionInCookie?.authResponse?.token?.slice(7) != undefined &&
            currentSessionInCookie?.authResponse?.token?.slice(7) != ''
        ) {
            setRole(
                JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie?.authResponse?.token?.slice(7))))
                    ?.user_role || ''
            );
        } else {
            setRole('');
        }
    }, [currentSessionInCookie]);

    useEffect(() => {
        setCurrentUrl(window.location.href.split('/')[window.location.href.split('/').length - 1]);
        setCurrentUrlWithId(window.location.href.split('/')[window.location.href.split('/').length - 2]);
        setCurrentUrlWithIdDownload(window.location.href.split('/')[window.location.href.split('/').length - 3]);
    }, [currentUrl, currentUrlWithId, url]);

    return (
        <>
            {pagesToHideAll.includes(currentUrl) ||
            pagesToHideAll.includes(currentUrlWithId) ||
            pagesToHideAll.includes(currentUrlWithIdDownload) ? (
                <></>
            ) : (
                <>
                    {currentSessionInCookie.isAuthenticated ? (
                        <SNavBar>
                            <SRoundButton onClick={handleClick}>
                                {currentUrl === 'how-to' ? (
                                    <BookIcon />
                                ) : currentUrl === 'account' ? (
                                    <SettingsIcon />
                                ) : currentUrl === 'cookie-policy' ? (
                                    <ArchiveIcon />
                                ) : currentUrl === 'terms' ? (
                                    <GavelIcon />
                                ) : currentUrl === 'statistics' ? (
                                    <EqualizerIcon />
                                ) : !currentDoctorContext ? (
                                    nameUser.toLocaleUpperCase().split(' ')[1].split('')[0]
                                ) : (
                                    <PersonIcon />
                                )}
                            </SRoundButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                // getContentAnchorEl={null}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                PaperProps={{
                                    style: { width: '200px', borderRadius: 10, marginTop: 1, top: 8, right: 8 }
                                }}
                            >
                                <SLink to="/home">
                                    <MenuItem onClick={handleClose}>
                                        <SBaseline>
                                            <STypography variant="subtitle2">{currentDoctorContext.name}</STypography>
                                        </SBaseline>
                                    </MenuItem>
                                </SLink>
                                {role == 'admin' && (
                                    <div key="admin">
                                        <Divider />
                                        <STypographySubTitle color="textSecondary" display="block" variant="caption">
                                            {t('navbar.admin')}
                                        </STypographySubTitle>
                                        <SLink to="/admin/users">
                                            <MenuItem onClick={handleClose}>
                                                <SBaseline>
                                                    <STypography variant="subtitle2">
                                                        {t('navbar.usersList')}
                                                    </STypography>
                                                </SBaseline>
                                            </MenuItem>
                                        </SLink>
                                        <Divider />
                                    </div>
                                )}
                                <SLink to="/account">
                                    <SMenuItem onClick={handleClose}>
                                        <SBaseline>
                                            <SettingsIcon />
                                            <STypography variant="subtitle2">{t('navbar.account')}</STypography>
                                        </SBaseline>
                                    </SMenuItem>
                                </SLink>
                                <SLink to="/statistics">
                                    <SMenuItem onClick={handleClose}>
                                        <SBaseline>
                                            <EqualizerIcon />
                                            <STypography variant="subtitle2">{t('navbar.statistics')}</STypography>
                                        </SBaseline>
                                    </SMenuItem>
                                </SLink>
                                <SMenuItem onClick={takeScreen}>
                                    {!isScreenshotLoading ? (
                                        <SBaseline>
                                            <BugReportIcon />
                                            <STypography variant="subtitle2">{t('navbar.reportBug')}</STypography>
                                        </SBaseline>
                                    ) : (
                                        <CircularProgress />
                                    )}
                                </SMenuItem>
                                <SMenuItem onClick={() => expandHelp()}>
                                    <SBaseline>
                                        <HelpIcon />
                                        <STypography variant="subtitle2">{t('navbar.help')}</STypography>
                                    </SBaseline>
                                </SMenuItem>
                                {expanded ? (
                                    <SFakeDiv>
                                        <SLink to="/how-to">
                                            <SSubMenuItem onClick={handleClose}>
                                                <SBaseline>
                                                    <BookIcon />
                                                    <STypography variant="subtitle2">{t('navbar.howto')}</STypography>
                                                </SBaseline>
                                            </SSubMenuItem>
                                        </SLink>
                                        <SLink to="/cookie-policy">
                                            <SSubMenuItem onClick={handleClose}>
                                                <SBaseline>
                                                    <ArchiveIcon />
                                                    <STypography variant="subtitle2">{t('navbar.cookie')}</STypography>
                                                </SBaseline>
                                            </SSubMenuItem>
                                        </SLink>
                                        <SLink to="/terms">
                                            <SSubMenuItem onClick={handleClose}>
                                                <SBaseline>
                                                    <GavelIcon />
                                                    <STypography variant="subtitle2">{t('navbar.Terms')}</STypography>
                                                </SBaseline>
                                            </SSubMenuItem>
                                        </SLink>
                                    </SFakeDiv>
                                ) : (
                                    <SFakeDiv></SFakeDiv>
                                )}
                                <SA href="mailto:support@spimed-ai.com">
                                    <SMenuItem onClick={handleClose}>
                                        <SBaseline>
                                            <MailIcon />
                                            <STypography variant="subtitle2">{t('navbar.contact')}</STypography>
                                        </SBaseline>
                                    </SMenuItem>
                                </SA>
                                <Divider light />
                                <SLink to="/login">
                                    <SMenuItemLogout onClick={onClickLogout}>
                                        <SBaseline>
                                            <LogoutIcon />
                                            <STypography variant="subtitle2">{t('navbar.logout')}</STypography>
                                        </SBaseline>
                                    </SMenuItemLogout>
                                </SLink>
                            </Menu>
                        </SNavBar>
                    ) : (
                        <> </>
                    )}
                </>
            )}
        </>
    );
};

export default NavBarRigth;

const SNavBar = styled.div`
    left: 0;
    z-index: 10;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
`;

const SRoundButton = styled(roundButton)`
    && {
        border-radius: 50%;
        margin: ${(props) => props.theme.getSpacing(2, 2)};
        min-height: ${(props) => props.theme.getSpacing(16)};
    }
`;

const SMenuItem = styled(MenuItem)`
    && {
        width: 95%;
        margin: ${(props) => props.theme.getSpacing(1, 1, 1, 1)};
        border-radius: 10px;
        color: ${(props) => props.theme.colors.darkgrey};
        &:hover {
            color: ${(props) => props.theme.colors.darkblue};
            background-color: ${(props) => props.theme.colors.white};
            box-shadow: inset 4px 4px 8px #d9d9d9, inset -4px -4px 8px #ffffff;
        }
    }
`;

const SSubMenuItem = styled(MenuItem)`
    && {
        width: 90%;
        margin: ${(props) => props.theme.getSpacing(1, 1, 1, 4)};
        border-radius: 10px;
        color: ${(props) => props.theme.colors.darkgrey};
        &:hover {
            color: ${(props) => props.theme.colors.darkblue};
            background-color: ${(props) => props.theme.colors.white};
            box-shadow: inset 4px 4px 8px #d9d9d9, inset -4px -4px 8px #ffffff;
        }
    }
`;

const SMenuItemLogout = styled(MenuItem)`
    && {
        margin: ${(props) => props.theme.getSpacing(2, 1, 0, 1)};
        border-radius: 10px;
        color: ${(props) => props.theme.colors.lightgrey};
        background-color: ${(props) => props.theme.colors.darkred};
        &:hover {
            color: ${(props) => props.theme.colors.white};
            background-color: ${(props) => props.theme.colors.darkred};
            box-shadow: inset 4px 4px 8px #af3232, inset -4px -4px 8px #d53e3e;
        }
    }
`;

const STypography = styled(Typography)`
    padding-left: ${(props) => props.theme.getSpacing(3)};
`;

const STypographySubTitle = styled(Typography)`
    && {
        margin-left: 13px;
        margin-top: 5px;
    }
`;

const SBaseline = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const SLink = styled(Link)`
    text-decoration: none;
    color: ${(props) => props.theme.colors.darkgrey};
    &:hover {
        color: ${(props) => props.theme.colors.darkblue};
    }
`;

const SA = styled.a`
    text-decoration: none;
`;

const SFakeDiv = styled.div``;

