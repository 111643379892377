import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Session } from '@/utils/contexts/SessionContext';

//Icons
import ErrorIcon from '@mui/icons-material/Error';

//Components
import { initialResultRads, ResultsRads } from '@/utils/ResultsRads';
import { getColorByRads, getStenosisByRads } from '@/utils/ResultsRadsStenosisColors';
import { ClassificationResult, Coronary, IStoredAnalysis } from '@api';
import i18n, { t } from '@/i18n.js';
import { File } from '@/components/molecules';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import jwt_decode from 'jwt-decode';
import { RequestExplicability } from '@/services/ExplicabilityService';
import { DownloadExplicabilityFromBack } from '@/services/ImageService';
import { DownloadImagesFromVmBack } from '@/services/VmBlobService';

import { ButtonOutlined } from '@/components/atoms';
import { Loader } from '@/components/atoms';
import circleLoader from '@/utils/animations/circle-loader.json';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

type Display = {
    display: string;
};

type Props = {
    storedAnalysis: IStoredAnalysis;
    setCurrentSubPageOpened: React.Dispatch<React.SetStateAction<string>>;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    ladResultsExplicability: string;
    cxResultsExplicability: string;
    rcaResultsExplicability: string;
    setLadExplicability: (value: string) => void;
    setCxExplicability: (value: string) => void;
    setRcaExplicability: (value: string) => void;
    currentCoronary: Coronary;
    setCurrentCoronary: (value: Coronary) => void;
    rightCoronary: Coronary;
    setRightCoronary: (value: Coronary) => void;
    leftCoronary: Coronary;
    setLeftCoronary: (value: Coronary) => void;
    modalOpen: boolean;
    updateModal: (arg: boolean) => void;
    coronaryImagesListOpen: string;
    updateCoronaryImagesList: (arg: string) => void;
    medicalReportId: number;
    isMedicalReportValidationDisplayed: boolean;
    currentSessionInCookie: Session;
};

type SIconProps = {
    colors: string;
};

/** Fonction classification de la sténose */
export const stenosisClassification = (classificationResult: ClassificationResult) => {
    return getStenosisByRads(classificationResult);
};

export const AnalysisReportCompImages = (props: Props) => {
    const {
        storedAnalysis,
        setCurrentSubPageOpened,
        ladResults,
        cxResults,
        rcaResults,
        ladResultsExplicability,
        cxResultsExplicability,
        rcaResultsExplicability,
        setLadExplicability,
        setCxExplicability,
        setRcaExplicability,
        currentCoronary,
        setCurrentCoronary,
        rightCoronary,
        setRightCoronary,
        leftCoronary,
        setLeftCoronary,
        modalOpen,
        updateModal,
        coronaryImagesListOpen,
        updateCoronaryImagesList,
        medicalReportId,
        isMedicalReportValidationDisplayed,
        currentSessionInCookie
    } = props;

    // State declaration
    const [selectedImage, setSelectedImage] = useState<File>({
        base64: '',
        fileName: '',
        data: ''
    });

    const explicabilityAccess =
        JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7))))
            .isExplicabilityAvailable == 'True';

    // State declaration
    const [selectedImageExplicability, setSelectedImageExplicability] = useState<string>('');
    const [leftImageExplicability, setLeftImageExplicability] = useState<string>('');
    const [rightImageExplicability, setRightImageExplicability] = useState<string>('');

    const [showHeatmapSwitch, setShowHeatmapSwitch] = useState<boolean>(false);
    const [activateHeatmapSwitch, setActivateHeatmapSwitch] = useState<boolean>(true);
    const [isExplicabilitySuccessfull, setIsExplicabilitySuccessfull] = useState<boolean>(false);

    const [selectedCoronaryImageList, setSelectedCoronaryImageList] = useState<File[]>();
    const [explicabilityLoading, setExplicabilityLoading] = useState<boolean>(false);

    const handleExplicability = async () => {
        setExplicabilityLoading(true);
        const explicabilityService = new RequestExplicability(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        const resExplicability = await explicabilityService.computeExplicability({
            analysisGuid: storedAnalysis.analysisGuid
        });
        let hasLADExplicability = false;
        let hasCxExplicability = false;
        let hasRCAExplicability = false;
        let downloadedF;
        if (storedAnalysis.isExplicabilityComputed || resExplicability == 1) {
            if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
                const imageService = new DownloadImagesFromVmBack(
                    { token: currentSessionInCookie.authResponse.token },
                    process.env.BackendServiceURL
                );

                downloadedF = await imageService
                    .downloadExplicability({
                        sessionContext: currentSessionInCookie,
                        analysisGuid: storedAnalysis.analysisGuid,
                        isFromPacs: storedAnalysis.isFromPacs
                    })
                    .catch((err: any) => {
                        console.log('err dialoganalysis', err);
                    });
            } else {
                const imageService = new DownloadExplicabilityFromBack(
                    { token: currentSessionInCookie.authResponse.token },
                    process.env.BackendServiceURL
                );
                downloadedF = await imageService.downloadAllBlobs({
                    sessionContext: currentSessionInCookie,
                    analysisId: storedAnalysis.id
                });
            }
            const explicabilityFiles = downloadedF as File[];

            for (const explicabilityFile of explicabilityFiles) {
                if (explicabilityFile['fileName'].includes('RCA')) {
                    if (!explicabilityFile['fileName'].includes('FFR')) {
                        setRcaExplicability(explicabilityFile?.base64);
                        hasRCAExplicability = true;
                    }
                } else if (explicabilityFile['fileName'].includes('LAD')) {
                    if (!explicabilityFile['fileName'].includes('FFR')) {
                        setLadExplicability(explicabilityFile?.base64);
                        hasLADExplicability = true;
                    }
                } else if (explicabilityFile['fileName'].includes('Cx')) {
                    if (!explicabilityFile['fileName'].includes('FFR')) {
                        setCxExplicability(explicabilityFile?.base64);
                        hasCxExplicability = true;
                    }
                }
            }
            if (!hasLADExplicability) setLadExplicability('null');
            if (!hasCxExplicability) setCxExplicability('null');
            if (!hasRCAExplicability) setRcaExplicability('null');
        } else {
            setLadExplicability('null');
            setCxExplicability('null');
            setRcaExplicability('null');
        }
        if (resExplicability === 1 && (hasLADExplicability || hasCxExplicability || hasRCAExplicability)) {
            setIsExplicabilitySuccessfull(true);
        } else {
            setIsExplicabilitySuccessfull(false);
            // setDisplayExplicabilityError(true); // TODO snackbar
        }
        setExplicabilityLoading(false);
    };

    useEffect(() => {
        if (explicabilityAccess) {
            setShowHeatmapSwitch(true);
        }
    }, [explicabilityAccess]);

    useEffect(() => {
        switch (currentCoronary) {
            case Coronary.LAD:
                setSelectedImage(
                    ladResults.Images[ladResults.RadsAnalysisResponse.bestRadsScoreImageIndex] ?? ladResults.Images[0]
                );
                setSelectedCoronaryImageList(ladResults.Images);
                setCurrentCoronaryName(t('LAD'));
                break;
            case Coronary.Cx:
                setSelectedImage(
                    cxResults.Images[cxResults.RadsAnalysisResponse.bestRadsScoreImageIndex] ?? cxResults.Images[0]
                );
                setSelectedCoronaryImageList(cxResults.Images);
                setCurrentCoronaryName(t('CX'));
                break;
            case Coronary.RCA:
                setSelectedImage(
                    rcaResults.Images[rcaResults.RadsAnalysisResponse.bestRadsScoreImageIndex] ?? rcaResults.Images[0]
                );
                setSelectedCoronaryImageList(rcaResults.Images);
                setCurrentCoronaryName(t('RCA'));
                break;
            default:
                break;
        }
    }, [currentCoronary]);

    const [color, setColor] = useState<string>(''); // Use state of the color depending on RadsState
    const [currentCoronaryName, setCurrentCoronaryName] = useState<string>('');
    const [leftCoronaryName, setLeftCoronaryName] = useState<string>('');
    const [rightCoronaryName, setRightCoronaryName] = useState<string>('');

    //Confidence Sténose
    const [stenosis, setStenosis] = useState<string>('');
    const [stenosisLeft, setStenosisLeft] = useState<string>('');
    const [stenosisRight, setStenosisRight] = useState<string>('');

    /** Fonction de changement de couleur en fonction du résultat CAD-RADS */
    const colorPicker = (futurCoronary: ResultsRads) => {
        const classificationResult = futurCoronary.RadsAnalysisResponse.classificationResult;
        setColor(getColorByRads(classificationResult, 'black'));
    };

    const dangerousRads = [
        ClassificationResult.Rads345,
        ClassificationResult.Rads34,
        ClassificationResult.Rads3,
        ClassificationResult.Rads4,
        ClassificationResult.Rads5
    ];

    /** Détermine la couleur de la bordure en fonction de la classification et le nom des coronaires à afficher */
    useEffect(() => {
        if (currentCoronary === Coronary.LAD) {
            colorPicker(ladResults);

            setCurrentCoronaryName(t('LAD'));
            setStenosis(stenosisClassification(ladResults.RadsAnalysisResponse.classificationResult));

            setLeftCoronary(Coronary.RCA);
            setLeftCoronaryName(t('RCA'));
            setStenosisLeft(stenosisClassification(rcaResults.RadsAnalysisResponse.classificationResult));

            setRightCoronary(Coronary.Cx);
            setRightCoronaryName(t('CX'));
            setStenosisRight(stenosisClassification(cxResults.RadsAnalysisResponse.classificationResult));
        } else if (currentCoronary === Coronary.RCA) {
            colorPicker(rcaResults);

            setCurrentCoronaryName(t('RCA'));
            setStenosis(stenosisClassification(rcaResults.RadsAnalysisResponse.classificationResult));

            setLeftCoronary(Coronary.Cx);
            setLeftCoronaryName(t('CX'));
            setStenosisLeft(stenosisClassification(cxResults.RadsAnalysisResponse.classificationResult));

            setRightCoronary(Coronary.LAD);
            setRightCoronaryName(t('LAD'));
            setStenosisRight(stenosisClassification(ladResults.RadsAnalysisResponse.classificationResult));
        } else if (currentCoronary === Coronary.Cx) {
            colorPicker(cxResults);

            setCurrentCoronaryName(t('CX'));
            setStenosis(stenosisClassification(cxResults.RadsAnalysisResponse.classificationResult));

            setLeftCoronary(Coronary.LAD);
            setLeftCoronaryName(t('LAD'));
            setStenosisLeft(stenosisClassification(ladResults.RadsAnalysisResponse.classificationResult));

            setRightCoronary(Coronary.RCA);
            setRightCoronaryName(t('RCA'));
            setStenosisRight(stenosisClassification(rcaResults.RadsAnalysisResponse.classificationResult));
        } else {
        }
    }, [i18n.language, currentCoronary, leftCoronary, rightCoronary, coronaryImagesListOpen]);

    useEffect(() => {
        if (currentCoronary == Coronary.LAD) {
            setSelectedImageExplicability(ladResultsExplicability);
        }
        if (currentCoronary == Coronary.RCA) {
            setRightImageExplicability(ladResultsExplicability);
        }
        if (currentCoronary == Coronary.Cx) {
            setLeftImageExplicability(ladResultsExplicability);
        }
    }, [ladResultsExplicability, currentCoronary]);

    useEffect(() => {
        if (currentCoronary == Coronary.LAD) {
            setLeftImageExplicability(rcaResultsExplicability);
        }
        if (currentCoronary == Coronary.RCA) {
            setSelectedImageExplicability(rcaResultsExplicability);
        }
        if (currentCoronary == Coronary.Cx) {
            setRightImageExplicability(rcaResultsExplicability);
        }
    }, [rcaResultsExplicability, currentCoronary]);

    useEffect(() => {
        if (currentCoronary == Coronary.LAD) {
            setRightImageExplicability(cxResultsExplicability);
        }
        if (currentCoronary == Coronary.RCA) {
            setLeftImageExplicability(cxResultsExplicability);
        }
        if (currentCoronary == Coronary.Cx) {
            setSelectedImageExplicability(cxResultsExplicability);
        }
    }, [cxResultsExplicability, currentCoronary]);

    /** Hide 3D heart when the modal is open */
    const [statusSwitch, setStatusSwitch] = useState<boolean>(false);

    /** Initialization to Parent Component value */
    useEffect(() => {
        setStatusSwitch(false);
    }, [modalOpen]);

    /** Navigation between the three coronary / futurCoronary == la coronaire qui a été cliquée */
    const coronaryHandler = (futurCoronary: ResultsRads) => {
        if (futurCoronary.RadsAnalysisResponse.coronary === ladResults.RadsAnalysisResponse.coronary) {
            setCurrentCoronary(Coronary.LAD);
            setLeftCoronary(Coronary.RCA);
            setRightCoronary(Coronary.Cx);
        } else if (futurCoronary.RadsAnalysisResponse.coronary === rcaResults.RadsAnalysisResponse.coronary) {
            setCurrentCoronary(Coronary.RCA);
            setLeftCoronary(Coronary.Cx);
            setRightCoronary(Coronary.LAD);
        } else if (futurCoronary.RadsAnalysisResponse.coronary === cxResults.RadsAnalysisResponse.coronary) {
            setCurrentCoronary(Coronary.Cx);
            setLeftCoronary(Coronary.LAD);
            setRightCoronary(Coronary.RCA);
        }
    };

    const getCoronary = (coronary: Coronary) => {
        switch (coronary) {
            case Coronary.LAD:
                return ladResults;
            case Coronary.Cx:
                return cxResults;
            case Coronary.RCA:
                return rcaResults;
            default:
                return initialResultRads;
        }
    };

    return (
        <>
            <SFullContainer>
                <SContainerAnalyse>
                    <SContainer display="flex">
                        <SRow>
                            <SImage>
                                {activateHeatmapSwitch &&
                                explicabilityAccess &&
                                (storedAnalysis.isExplicabilityComputed || isExplicabilitySuccessfull) &&
                                selectedImageExplicability != '' &&
                                selectedImageExplicability != 'null' ? (
                                    <>
                                        <img
                                            src={selectedImageExplicability}
                                            onClick={() => setCurrentSubPageOpened('images')}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <img
                                            src={selectedImage?.base64?.replace(
                                                'data:application/octet-stream;base64',
                                                'data:image/jpeg;base64'
                                            )}
                                            alt="Selected Coronary"
                                            onClick={() => setCurrentSubPageOpened('images')}
                                        />
                                    </>
                                )}
                                {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                    <SImageDoctor>{currentCoronaryName}</SImageDoctor>
                                ) : (
                                    <>
                                        <SImageText>
                                            {currentCoronaryName} {stenosis}
                                        </SImageText>
                                        {dangerousRads.includes(
                                            getCoronary(currentCoronary).RadsAnalysisResponse.classificationResult
                                        ) ? (
                                            <SImageIcon>
                                                <SErrorIcon
                                                    colors={getColorByRads(
                                                        getCoronary(currentCoronary).RadsAnalysisResponse
                                                            .classificationResult
                                                    )}
                                                    sx={{ fontSize: '1.2rem' }}
                                                />
                                            </SImageIcon>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                                {showHeatmapSwitch && (
                                    <>
                                        {!(
                                            ladResultsExplicability !== 'null' ||
                                            cxResultsExplicability !== 'null' ||
                                            rcaResultsExplicability !== 'null'
                                        ) && explicabilityAccess ? (
                                            <>
                                                <SButtonContainerExplicability>
                                                    {explicabilityLoading ? (
                                                        <Loader jsonToUse={circleLoader} width="40px" />
                                                    ) : (
                                                        <ButtonOutlined
                                                            text={t('analysis.computeExplicability')}
                                                            onClick={handleExplicability}
                                                            disabled={false}
                                                        />
                                                    )}
                                                </SButtonContainerExplicability>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {(ladResultsExplicability !== 'null' ||
                                            cxResultsExplicability !== 'null' ||
                                            rcaResultsExplicability !== 'null') &&
                                        explicabilityAccess ? (
                                            <SSwitch>
                                                {t('analysis.explicability.title')}
                                                <FormControlLabel
                                                    sx={{ marginLeft: '-8px', marginRight: '-6px' }}
                                                    control={<HeatmapSwitch sx={{ m: 1 }} />}
                                                    checked={activateHeatmapSwitch}
                                                    onChange={() => {
                                                        setActivateHeatmapSwitch(!activateHeatmapSwitch);
                                                    }}
                                                    label=""
                                                />
                                            </SSwitch>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </SImage>
                        </SRow>
                        <SRow>
                            {getCoronary(rightCoronary).Images.length !== 0 &&
                            getCoronary(rightCoronary).RadsAnalysisResponse.imageScores.length != 0 ? (
                                <>
                                    <SSubImage onClick={() => coronaryHandler(getCoronary(rightCoronary))}>
                                        {showHeatmapSwitch &&
                                        activateHeatmapSwitch &&
                                        explicabilityAccess &&
                                        (storedAnalysis.isExplicabilityComputed || isExplicabilitySuccessfull) &&
                                        rightImageExplicability != '' &&
                                        rightImageExplicability != 'null' ? (
                                            <>
                                                <img src={rightImageExplicability} />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={
                                                        getCoronary(rightCoronary) !== initialResultRads
                                                            ? getCoronary(rightCoronary).Images[
                                                                  getCoronary(rightCoronary).RadsAnalysisResponse
                                                                      .bestRadsScoreImageIndex
                                                              ]?.base64?.replace(
                                                                  'data:application/octet-stream;base64',
                                                                  'data:image/jpeg;base64'
                                                              )
                                                            : ''
                                                    }
                                                />
                                            </>
                                        )}

                                        {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                            <SImageDoctor>{rightCoronaryName}</SImageDoctor>
                                        ) : (
                                            <>
                                                <SImageText>
                                                    {rightCoronaryName} {stenosisRight}
                                                </SImageText>
                                                {dangerousRads.includes(
                                                    getCoronary(rightCoronary).RadsAnalysisResponse.classificationResult
                                                ) ? (
                                                    <SImageIcon>
                                                        <SErrorIcon
                                                            colors={getColorByRads(
                                                                getCoronary(rightCoronary).RadsAnalysisResponse
                                                                    .classificationResult
                                                            )}
                                                            sx={{ fontSize: '1rem' }}
                                                        />
                                                    </SImageIcon>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </SSubImage>
                                </>
                            ) : (
                                <></>
                            )}
                            {getCoronary(leftCoronary).Images.length !== 0 &&
                            getCoronary(leftCoronary).RadsAnalysisResponse.imageScores.length != 0 ? (
                                <>
                                    <SSubImage onClick={() => coronaryHandler(getCoronary(leftCoronary))}>
                                        {showHeatmapSwitch &&
                                        activateHeatmapSwitch &&
                                        explicabilityAccess &&
                                        (storedAnalysis.isExplicabilityComputed || isExplicabilitySuccessfull) &&
                                        leftImageExplicability != '' &&
                                        leftImageExplicability != 'null' ? (
                                            <>
                                                <img src={leftImageExplicability} />
                                            </>
                                        ) : (
                                            <>
                                                <img
                                                    src={
                                                        getCoronary(leftCoronary) !== initialResultRads
                                                            ? getCoronary(leftCoronary).Images[
                                                                  getCoronary(leftCoronary).RadsAnalysisResponse
                                                                      .bestRadsScoreImageIndex
                                                              ]?.base64?.replace(
                                                                  'data:application/octet-stream;base64',
                                                                  'data:image/jpeg;base64'
                                                              )
                                                            : ''
                                                    }
                                                />
                                            </>
                                        )}

                                        {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                                            <>
                                                <SImageDoctor>{leftCoronaryName}</SImageDoctor>
                                            </>
                                        ) : (
                                            <>
                                                <SImageText>
                                                    {leftCoronaryName} {stenosisLeft}
                                                </SImageText>
                                                {dangerousRads.includes(
                                                    getCoronary(leftCoronary).RadsAnalysisResponse.classificationResult
                                                ) ? (
                                                    <SImageIcon>
                                                        <SErrorIcon
                                                            colors={getColorByRads(
                                                                getCoronary(leftCoronary).RadsAnalysisResponse
                                                                    .classificationResult
                                                            )}
                                                            sx={{ fontSize: '1rem' }}
                                                        />
                                                    </SImageIcon>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </SSubImage>
                                </>
                            ) : (
                                <></>
                            )}
                        </SRow>
                    </SContainer>
                </SContainerAnalyse>
            </SFullContainer>
        </>
    );
};

export default AnalysisReportCompImages;

const SFullContainer = styled.div`
    height: 92vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const SContainerAnalyse = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 calc(2vw);
    height: 100%;
`;

const SContainer = styled.div<Display>`
    display: ${(props: Display) => props.display};
    text-align: center;
    flex-direction: column;
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    user-select: none;
`;

const SRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 0 calc(0.4vw) 0;
`;

const SImage = styled.div`
    display: flex;
    position: relative;
    height: calc(29vh + 15vw);
    width: calc(29vh + 15vw);
    &:hover {
        cursor: pointer;
    }
`;

const SImageText = styled.div`
    display: flex;
    color: black;
    background-color: #ffffffa9;
    border-radius: 5px;
    padding: 0.1em 0.2em;
    height: fit-content;
    width: fit-content;
    position: absolute;
    top: 0;
    bottom: 90%;
    left: 0;
    font-size: calc(0.5vh + 10px);
    margin: ${(props) => props.theme.getSpacing(1, 0, 0, 1)};
`;

const SSwitch = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #0000008c;
    color: white;
    border-top: 1px solid white;
    padding: 6px 10px;
    height: 40px;
    width: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: calc(0.5vh + 10px);
    margin: ${(props) => props.theme.getSpacing(1, 0, 0, 1)};
`;

const SImageDoctor = styled(SImageText)`
    && {
        color: white;
        background-color: #29b5fba9;
    }
`;

const SImageIcon = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 90%;
    right: 5px;
    height: fit-content;
    width: fit-content;
    margin: ${(props) => props.theme.getSpacing(1, 0, 0, 0)};
    background-color: white;
    border-radius: 50%;
`;

const SSubImage = styled.div`
    display: flex;
    position: relative;
    height: calc(14.5vh + 7.3vw);
    width: calc(14.5vh + 7.3vw);
    margin-right: calc(0.4vw);
    &:hover {
        cursor: pointer;
    }
`;

const SErrorIcon = styled(ErrorIcon)<SIconProps>`
    color: ${(props: SIconProps) => props.colors};
`;

const HeatmapSwitch = styled(Switch)(({ theme }) => ({
    width: '61px !important',
    height: '26px !important',
    padding: '0 !important',
    margin: '5px !important',
    overflow: 'initial !important',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        paddingTop: '2px',
        margin: 2,
        transitionDuration: '170ms',
        '&.Mui-checked': {
            transform: 'translateX(31px)',
            color: '#fff',
            '& .MuiSwitch-thumb:before': {
                content: '"ON"',
                fontSize: '0.75em'
            },
            '& .MuiSwitch-thumb': {
                border: '1px solid white',
                backgroundColor: theme.colors.blue
            }
        }
    },
    '& .MuiSwitch-thumb': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#676767',
        transform: 'translateX(2px)',
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        '&:before': {
            fontSize: '0.7em',
            content: '"OFF"'
        }
    },
    '& .MuiSwitch-track': {
        border: '2px solid #fefefeeb',
        borderRadius: 90,
        backgroundColor: '#00000068 !important',
        opacity: 1
    }
}));

const SButtonContainerExplicability = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${(props) => props.theme.analysisDetails.imageComparative.explicabilityBackground};
    color: white;
    border-top: 1px solid white;
    padding: 6px 10px;
    height: 40px;
    width: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: calc(0.5vh + 10px);
    margin: ${(props) => props.theme.getSpacing(1, 0, 0, 1)};
`;

