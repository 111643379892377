import React from 'react';
import { Session } from '@/utils/contexts/SessionContext';
import { AdminUserForm } from '@/components/organisms';

type Props = {
    currentSessionInCookie: Session;
};

export const AdminCreateUser = (props: Props) => {
    const { currentSessionInCookie } = props;

    return <AdminUserForm currentSessionInCookie={currentSessionInCookie} isUpdate={false} id={'0'}></AdminUserForm>;
};

export default AdminCreateUser;
