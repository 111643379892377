import React from 'react';
import styled from 'styled-components';

import { Typography } from '@/components/atoms';
import { PasswordReset } from '@/components/organisms';

import { t } from '@/i18n.js';

export const ResetPassword = () => {
    return (
        <SColumn>
            <Typography title variant="h1">
                {t('settings.resetPswd')}
            </Typography>
            <SContainer>
                <PasswordReset />
            </SContainer>
        </SColumn>
    );
};

export default ResetPassword;

const SContainer = styled.div`
    padding: ${(props) => props.theme.getSpacing(10, 10, 10, 10)};
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 20px 20px 60px #f0f0f0, -20px -20px 60px #ffffff;
    margin-top: ${(props) => props.theme.getSpacing(6)};
    border-radius: ${(props) => props.theme.borderRadiusContainer};
`;

const SColumn = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;
`;

