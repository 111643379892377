import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from '@/i18n.js';

import { IStoredAnalysis } from '@api';
import styled from 'styled-components';

import { ButtonActionOutlined } from '@/components/atoms';
import { Typography } from '@/components/atoms';
import { Dialog } from '@/components/molecules';

import { Session } from '@/utils/contexts/SessionContext';

import { DeleteAnalysisService } from '@/services/DeleteAnalysisService';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Props = {
    currentSessionInCookie: Session;
    analysisId: number;
};

export const DeleteCurrentAnalysis = (props: Props) => {
    const { currentSessionInCookie, analysisId } = props;
    const history = useHistory();

    const { setStoredAnalysisListContext, storedAnalysisListContext } = useContext(StoredAnalysisListContext);
    const [snackbarContext, updateSnackbar] = useSnackbarContext();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setDialogOpen(false);
    };
    const handleOpen = () => {
        setDialogOpen(true);
    };

    const deleteAnalysis = async () => {
        setIsLoading(true);
        const deleteStoredAnalysis = new DeleteAnalysisService(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );

        await deleteStoredAnalysis
            .DeleteStoredAnalysis({ storedAnalysisList: [analysisId] })
            .catch((err) => {
                setIsLoading(false);
                setDialogOpen(false);
                updateSnackbar({
                    snackString: t('errorOccured'),
                    severity: 'error'
                });
            })
            .finally(() => {
                updateSnackbar({
                    snackString: t('snackbar.success.analysis.deleted'),
                    severity: 'success'
                });
                const listWithoutCurrentAnalyse = storedAnalysisListContext.filter(
                    (analyse: IStoredAnalysis) => analyse.id != analysisId
                );
                setStoredAnalysisListContext(listWithoutCurrentAnalyse);
                setIsLoading(false);
                setDialogOpen(false);
                history.push('/home');
            });
    };

    return (
        <>
            <SContainer>
                <ButtonActionOutlined
                    icon={<DeleteOutlineIcon />}
                    tooltipText={t('analysisExplorer.analysisTable.deleteAnalysis') || ''}
                    onClick={handleOpen}
                    disabled={false}
                />

                <Dialog
                    open={dialogOpen}
                    customTitle={t('analysisExplorer.analysisTable.deleteDialogWarning')}
                    text={t('analysisExplorer.analysisTable.areYouSureYouWantToDelete', { id: analysisId })}
                    onClose={deleteAnalysis}
                    disableChildrenButton={isLoading}
                    childrenButton={
                        isLoading ? (
                            <Typography variant="button">{t('analysisExplorer.analysisTable.loading')}</Typography>
                        ) : (
                            <Typography red variant="button">
                                {t('analysisExplorer.analysisTable.delete')}
                            </Typography>
                        )
                    }
                    withCancel
                    disableBackdropClick
                    disableEscapeKeyDown
                    handleCancel={handleClose}
                />
            </SContainer>
        </>
    );
};

export default DeleteCurrentAnalysis;

const SContainer = styled.div`
    display: inline-block;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

