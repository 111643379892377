import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip, IconButton } from '@mui/material';
import { t } from '@/i18n.js';
import ReactGA from 'react-ga';

import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';

import { Session } from '@/utils/contexts/SessionContext';
import { SaveHighlighted } from '@/services/StoredAnalysisService';

import { IStoredAnalysis } from '@api';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

type Props = {
    /**The analysis Id*/
    storedAnalysisId: number;
    currentSessionInCookie: Session;
};

export const HighlightedField = (props: Props) => {
    const { storedAnalysisId, currentSessionInCookie } = props;
    const { setStoredAnalysisListContext, storedAnalysisListContext } = useContext(StoredAnalysisListContext);

    const isHighlighted = useMemo(() => {
        return storedAnalysisListContext.find((str) => str.id === storedAnalysisId)?.highLighted ?? false;
    }, [storedAnalysisListContext, storedAnalysisId]);

    const handleClick = async () => {
        const highLighted = !isHighlighted;
        const updatedRows = storedAnalysisListContext.map((row: IStoredAnalysis) => {
            if (row.id === storedAnalysisId) {
                return { ...row, highLighted };
            }
            return row;
        });

        await SaveHighlighted({ sessionContext: currentSessionInCookie, analysisId: storedAnalysisId });
        setStoredAnalysisListContext(updatedRows);
        ReactGA.event({
            category: 'Analysis Explorer',
            action: 'Highlight an analysis'
        });
    };

    return (
        <Tooltip
            title={
                isHighlighted
                    ? t('analysisExplorer.analysisTable.removeFavorite') || ''
                    : t('analysisExplorer.analysisTable.addFavorite') || ''
            }
        >
            <IconButton onClick={handleClick}>
                {isHighlighted ? <SStarRoundedIcon /> : <SStarBorderRoundedIcon />}
            </IconButton>
        </Tooltip>
    );
};

export default HighlightedField;

const SStarRoundedIcon = styled(StarRoundedIcon)`
    color: ${(props) => props.theme.colors.gold};
`;

const SStarBorderRoundedIcon = styled(StarBorderRoundedIcon)`
    color: ${(props) => props.theme.colors.gold};
`;

