import React from 'react';

import { Typography } from '@/components/atoms';
import { Dialog } from '@/components/molecules';
import { t } from '@/i18n.js';

type Props = {
    /**Will open the 'License will expire soon' Dialog if true */
    isOpen: boolean;
    /**Function executed on close */
    onClose: () => void;
};

export const LicenseExpireSoonDialog = (props: Props) => {
    const { isOpen, onClose } = props;

    return (
        <Dialog
            open={isOpen}
            customTitle={'License expire soon'}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
            width={'40%'}
            childrenButton={<Typography variant="button">Close</Typography>}
        >
            <>
                <Typography title variant="h3">
                    {t('license.expireSoon')}
                </Typography>
            </>
        </Dialog>
    );
};

export default LicenseExpireSoonDialog;

