import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import { getSessionCookie, useSessionContext } from '@/utils/contexts/SessionContext';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';

export const ProtectedRoute = ({ component: Component, ...rest }: any) => {
    const [, , logout] = useSessionContext();
    const { eraseStoredAnalysisListContext } = useContext(StoredAnalysisListContext);

    return (
        <Route
            {...rest}
            render={(props) => {
                const currentSessionInCookie = getSessionCookie();
                if (!currentSessionInCookie.isAuthenticated) {
                    logout();
                    eraseStoredAnalysisListContext();
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                }
                return <Component {...props} currentSessionInCookie={currentSessionInCookie} />;
            }}
        />
    );
};

export default ProtectedRoute;

