import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { t } from '@/i18n.js';
import { useHistory } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import styled from 'styled-components';

import { Session } from '@/utils/contexts/SessionContext';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';

/** Import téléchargement des autres analyses */
import { initialResultRads, ResultsRads } from '@/utils/ResultsRads';
import { Coronary, ImageOrderInStorage } from '@api';
import { GetStoredAnalysisDetail } from '@/services/StoredAnalysisService';
import { DownloadImagesFromBack } from '@/services/ImageService';
import { DownloadImagesFromVmBack } from '@/services/VmBlobService';

import { File } from '@/components/molecules';
import { filterImages } from '@/utils/FileOrdering';
import { Loader } from '@/components/atoms';
import circleLoader from '@/utils/animations/circle-loader.json';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

type Props = {
    selectedR: number[];
    currentSessionInCookie: Session;
};

export const DownloadSelectedFiles = (props: Props) => {
    const { selectedR, currentSessionInCookie } = props;

    const history = useHistory();
    const [snackbarContext, updateSnackbar] = useSnackbarContext();

    /* Résultats par coronaire */
    const [ladResultsSelected, setLADSelected] = useState<ResultsRads[]>([]);
    const [cxResultsSelected, setCXSelected] = useState<ResultsRads[]>([]);
    const [rcaResultsSelected, setRCASelected] = useState<ResultsRads[]>([]);
    const [ladResults, setLAD] = useState<ResultsRads>(initialResultRads);
    const [cxResults, setCX] = useState<ResultsRads>(initialResultRads);
    const [rcaResults, setRCA] = useState<ResultsRads>(initialResultRads);
    const [totalCountSelected, setTotalCountSelected] = useState<number>(0);
    const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(true);

    /* Stop le useEffect pour le téléchargement du zip une seule fois par appui du bouton */
    const [zipButtonClicked, setZipButtonClicked] = useState<boolean>(false);

    /** Update liste LAD */
    useEffect(() => {
        if (!ladResults.isLoading) {
            if (ladResultsSelected.length == 0) setLADSelected([ladResults]);
            else setLADSelected((old_array) => [...old_array, ladResults]);
            setTotalCountSelected(totalCountSelected + ladResults.Images.length);
        }
    }, [ladResults]);

    /** Update liste CX */
    useEffect(() => {
        if (!cxResults.isLoading) {
            if (cxResultsSelected.length == 0) setCXSelected([cxResults]);
            else setCXSelected((old_array) => [...old_array, cxResults]);
            setTotalCountSelected(totalCountSelected + cxResults.Images.length);
        }
    }, [cxResults]);

    /** Update liste RCA */
    useEffect(() => {
        if (!rcaResults.isLoading) {
            if (rcaResultsSelected.length == 0) setRCASelected([rcaResults]);
            else setRCASelected((old_array) => [...old_array, rcaResults]);
            setTotalCountSelected(totalCountSelected + rcaResults.Images.length);
        }
    }, [rcaResults]);

    /** Chargement des résultats de l'analyse choisie afin de remplir le PDF avant download  */
    const loadAnalysis = async (idAnalysis: number) => {
        try {
            let resultsStoredDetails = await GetStoredAnalysisDetail({
                sessionContext: currentSessionInCookie,
                analysisId: idAnalysis
            }).catch((err) => {
                history.push('/home');
                updateSnackbar({
                    snackString: t('snackbar.error.analysis.server'),
                    severity: 'error'
                });
            });
            /** Avoid to create a TS(2339) Error - Property 'X' does not exist on type 'void' since :
             * let resultsStoredDetails: void | StoredAnalysisResponse
             * Now with the if statement we only have a resultStoredDetails as a StoredAnalysisResponse type
             */
            if (resultsStoredDetails !== undefined) {
                const currentStoredAnalysis = resultsStoredDetails.storedAnalysis;
                const results = resultsStoredDetails.radsAnalysisResponsesList;

                let downloadedF;
                if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
                    const imageService = new DownloadImagesFromVmBack(
                        { token: currentSessionInCookie.authResponse.token },
                        process.env.BackendServiceURL
                    );

                    downloadedF = await imageService
                        .downloadAllBlobs({
                            sessionContext: currentSessionInCookie,
                            analysisGuid: currentStoredAnalysis.analysisGuid,
                            isFromPacs: currentStoredAnalysis.isFromPacs
                        })
                        .catch((err: any) => {
                            updateSnackbar({
                                snackString: t('snackbar.error.analysis.server'),
                                severity: 'error'
                            });
                            setZipButtonClicked(false);
                        });
                    console.warn('downloadedF in DownloadSelectedFiles', downloadedF);
                } else {
                    const imageService = new DownloadImagesFromBack(
                        { token: currentSessionInCookie.authResponse.token },
                        process.env.BackendServiceURL
                    );
                    downloadedF = await imageService
                        .downloadAllBlobs({
                            sessionContext: currentSessionInCookie,
                            analysisId: idAnalysis
                        })
                        .catch((err) => {
                            history.push('/home');
                            updateSnackbar({
                                snackString: t('snackbar.error.analysis.server'),
                                severity: 'error'
                            });
                        });
                }
                var downloadedFiles = downloadedF as File[];

                const allFiles: File[] = await FilterBlobsByCoronary(
                    Coronary.NotSpecified,
                    downloadedFiles,
                    currentStoredAnalysis.isFromPacs
                );
                const ladFiles: File[] = await FilterBlobsByCoronary(
                    Coronary.LAD,
                    downloadedFiles,
                    currentStoredAnalysis.isFromPacs
                );
                const cxFiles: File[] = await FilterBlobsByCoronary(
                    Coronary.Cx,
                    downloadedFiles,
                    currentStoredAnalysis.isFromPacs
                );
                const rcaFiles: File[] = await FilterBlobsByCoronary(
                    Coronary.RCA,
                    downloadedFiles,
                    currentStoredAnalysis.isFromPacs
                );

                const is27 = allFiles.length > 0;
                let indexLad: number = -1;
                let indexCx: number = -1;
                let indexRca: number = -1;
                let indexForImagesLad: number = -1;
                let indexForImagesCx: number = -1;
                let indexForImagesRca: number = -1;
                if (is27) {
                    const { ladI, cxI, rcaI } = getIndexesFromStoredAnalysis(currentStoredAnalysis.imageOrderInStorage);
                    indexForImagesLad = ladI;
                    indexForImagesCx = cxI;
                    indexForImagesRca = rcaI;
                }
                indexLad = results.findIndex((pred) => pred.coronary === Coronary.LAD);
                indexCx = results.findIndex((pred) => pred.coronary === Coronary.Cx);
                indexRca = results.findIndex((pred) => pred.coronary === Coronary.RCA);

                setLAD((lad) => ({
                    ...lad,
                    isLoading: false,
                    Images: is27
                        ? indexForImagesLad !== -1
                            ? filterImages(allFiles, ladFiles, is27, indexForImagesLad)
                            : []
                        : indexLad !== -1
                        ? ladFiles
                        : [],
                    RadsAnalysisResponse: indexLad !== -1 ? results[indexLad] : initialResultRads.RadsAnalysisResponse
                }));

                setCX((cx) => ({
                    ...cx,
                    isLoading: false,
                    Images: is27
                        ? indexForImagesCx !== -1
                            ? filterImages(allFiles, cxFiles, is27, indexForImagesCx)
                            : []
                        : indexCx !== -1
                        ? cxFiles
                        : [],
                    RadsAnalysisResponse: indexCx !== -1 ? results[indexCx] : initialResultRads.RadsAnalysisResponse
                }));

                setRCA((rca) => ({
                    ...rca,
                    isLoading: false,
                    Images: is27
                        ? indexForImagesRca !== -1
                            ? filterImages(allFiles, rcaFiles, is27, indexForImagesRca)
                            : []
                        : indexRca !== -1
                        ? rcaFiles
                        : [],
                    RadsAnalysisResponse: indexRca !== -1 ? results[indexRca] : initialResultRads.RadsAnalysisResponse
                }));

                if (idAnalysis == selectedR[selectedR.length - 1]) setIsLoadingFiles(false);
            }
        } catch (err) {}
    };

    /** Convert Base64 to raw binary data held in a string. */
    function dataURItoBlob(dataURI: string) {
        const byteString = atob(dataURI.split(',')[1]);

        // Write the bytes of the string to an ArrayBuffer.
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // Write the ArrayBuffer to a BLOB and you're done.
        return new Blob([ab]);
    }

    /** Lancement du téléchargement de l'ensemble des images des analyses sélectionnées */
    const downloadImagesSelected = useCallback(async () => {
        setIsLoadingFiles(true);
        setZipButtonClicked(true);
        for (const idAnalysis of selectedR) {
            await loadAnalysis(idAnalysis);
        }
    }, [selectedR]);

    /** Téléchargement de l'ensemble des images de l'analyse */
    useEffect(() => {
        if (!isLoadingFiles && zipButtonClicked) {
            let zip: JSZip = new JSZip();
            let count = 0;

            for (let i = 0; i < selectedR.length; i++) {
                const ladResultsLocal = ladResultsSelected[i];
                const cxResultsLocal = cxResultsSelected[i];
                const rcaResultsLocal = rcaResultsSelected[i];
                const id = selectedR[i];
                let idFolder = zip.folder(id.toString() + '_images');

                /** Chargement des images dans le dossier LAD */
                if (ladResultsLocal.Images.length > 0) {
                    let lad = idFolder!.folder('lad');
                    for (let file = 0; file < ladResultsLocal.Images.length; file++) {
                        const fileData = dataURItoBlob(ladResultsLocal.Images[file].base64);
                        lad!.file('lad_' + ladResultsLocal.Images[file].fileName + '.png', fileData, {
                            binary: true
                        });
                        count++;
                    }
                }

                /** Chargement des images dans le dossier CX */
                if (cxResultsLocal.Images.length > 0) {
                    let cx = idFolder!.folder('cx');
                    for (let file = 0; file < cxResultsLocal.Images.length; file++) {
                        const fileData = dataURItoBlob(cxResultsLocal.Images[file].base64);
                        cx!.file('cx_' + cxResultsLocal.Images[file].fileName + '.png', fileData, {
                            binary: true
                        });
                        count++;
                    }
                }

                /** Chargement des images dans le dossier RCA */
                if (rcaResultsLocal.Images.length > 0) {
                    let rca = idFolder!.folder('rca');
                    for (let file = 0; file < rcaResultsLocal.Images.length; file++) {
                        const fileData = dataURItoBlob(rcaResultsLocal.Images[file].base64);
                        rca!.file('rca_' + rcaResultsLocal.Images[file].fileName + '.png', fileData, {
                            binary: true
                        });
                        count++;
                    }
                }
            }
            if (count === totalCountSelected) {
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    saveAs(content, 'images_selected.zip');
                });
                setZipButtonClicked(false);
                setLADSelected([]);
                setCXSelected([]);
                setRCASelected([]);
            }
        }
    }, [isLoadingFiles]);

    return (
        <>
            {zipButtonClicked ? (
                <>
                    <Loader jsonToUse={circleLoader} width="2vw" />
                </>
            ) : (
                <Tooltip title={t('options.downloadImages') || ''}>
                    <IconButton onClick={downloadImagesSelected}>
                        <SFolderCopyIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default DownloadSelectedFiles;

const FilterBlobsByCoronary = async (
    coronary: Coronary,
    downloadFiles: File[],
    analysisComesFromDicomServer: boolean
) => {
    const files: File[] = [];

    if (analysisComesFromDicomServer && coronary === Coronary.NotSpecified) {
        for (let i = 0; i < downloadFiles.length; i++) {
            const fileName = downloadFiles[i].fileName.split('/');
            const imageNumber = fileName[2].split('.')[0];
            files.push({
                base64: downloadFiles[i].base64,
                fileName: imageNumber,
                data: ''
            });
        }
    } else {
        for (let i = 0; i < downloadFiles.length; i++) {
            const fileName = downloadFiles[i].fileName.split('/');
            const coronaryInFileName = fileName[0];
            const imageNumber = fileName[1].split('.')[0];
            if (coronaryInFileName === coronary) {
                files.push({
                    base64: downloadFiles[i].base64,
                    fileName: imageNumber,
                    data: ''
                });
            }
        }
    }
    return files.sort((a, b) => (a.fileName as unknown as number) - (b.fileName as unknown as number));
};

const getIndexesFromStoredAnalysis = (imageOrderInStorage: ImageOrderInStorage) => {
    switch (imageOrderInStorage) {
        case ImageOrderInStorage.LadCxRca:
            return { ladI: 0, cxI: 1, rcaI: 2 };
        case ImageOrderInStorage.LadRcaCx:
            return { ladI: 0, rcaI: 1, cxI: 2 };
        case ImageOrderInStorage.CxLadRca:
            return { cxI: 0, ladI: 1, rcaI: 2 };
        case ImageOrderInStorage.CxRcaLad:
            return { cxI: 0, rcaI: 1, ladI: 2 };
        case ImageOrderInStorage.RcaCxLad:
            return { rcaI: 0, cxI: 1, ladI: 2 };
        case ImageOrderInStorage.RcaLadCx:
            return { rcaI: 0, ladI: 1, cxI: 2 };
        default:
            return { ladI: 0, cxI: 1, rcaI: 2 };
    }
};

const SFolderCopyIcon = styled(FolderCopyIcon)`
    color: ${(props) => props.theme.table.contentLightText};
`;

