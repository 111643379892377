import React from 'react';
import styled from 'styled-components';
import { Button, SvgIconProps } from '@mui/material';
import Typography from '../Typography';

type Props = {
    icon: React.ReactElement<SvgIconProps>;
    text: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    disabled: boolean;
};

export const ButtonActionFilled = (props: Props) => {
    const { icon, text, onClick, disabled } = props;

    return (
        <SButton onClick={onClick} disabled={disabled}>
            <SIcon>{icon}</SIcon>
            <Typography>{text}</Typography>
        </SButton>
    );
};

export default ButtonActionFilled;

const SButton = styled(Button)`
    && {
        font-size: 16px;
        text-transform: none;
        background-color: ${(props) => props.theme.buttonActionFilled.background};
        color: ${(props) => props.theme.buttonActionFilled.text};
        filter: ${(props) => props.theme.buttonActionFilled.filter};
        border: ${(props) => props.theme.buttonActionFilled.border};
        padding: ${(props) => props.theme.getSpacing(1, 3)};
        margin: ${(props) => props.theme.getSpacing(4)};
        border-radius: 10px;
        &:hover {
            background-color: ${(props) => props.theme.buttonActionFilled.hover.background};
            // color: ${(props) => props.theme.buttonActionFilled.hover.text};
            // filter: ${(props) => props.theme.buttonActionFilled.hover.filter};
            // border: ${(props) => props.theme.buttonActionFilled.hover.border};
        }
    }
`;

const SIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
`;

