import React from 'react';
import {
    Dialog as MaterialDialog,
    DialogProps,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material/';
import styled from 'styled-components';
import { t } from '@/i18n.js';

import { UploadButton, Typography } from '@/components/atoms';

type Props = {
    /**Title inside the dialog */
    customTitle: React.ReactNode | string;
    /**Text inside the dialog */
    text?: string;
    /**Function executed onClose*/
    onClose: () => void;
    /**Children node to be placed in the button at the bottom or string*/
    childrenButton?: React.ReactNode | string;
    /**Set the width of the dialog*/
    width?: string;
    /**Set the height of the dialog*/
    height?: string;
    /**Function executed onClose*/
    borderRadius?: string;
    /**Type of scroll (paper/body) */
    scroll?: string;
    /**Disable the bottom button */
    disableChildrenButton?: boolean;
    disableBackdropClick?: boolean;
    /**Add a cancel button to the modal */
    withCancel?: boolean;
    /**Function executed on click on cancel */
    handleCancel?: () => void;
} & DialogProps;

const Dialog = (props: Props) => {
    const {
        customTitle,
        text,
        onClose,
        open,
        children,
        childrenButton,
        width,
        height,
        borderRadius,
        disableBackdropClick,
        disableEscapeKeyDown,
        scroll,
        disableChildrenButton,
        withCancel,
        handleCancel
    } = props;
    const borderRad = borderRadius ?? 16;
    return (
        <div>
            <SMaterialDialog
                maxWidth={false}
                scroll={scroll}
                open={open}
                onClose={(event: any, reason: any) => {
                    console.log([disableBackdropClick, disableEscapeKeyDown, reason]);
                    if (disableBackdropClick && reason == 'backdropClick') {
                        return false;
                    }
                    if (disableEscapeKeyDown && reason == 'escapeKeyDown') {
                        return false;
                    }
                    onClose();
                }}
                PaperProps={{
                    style: {
                        width: width,
                        height: height,
                        borderRadius: borderRad
                    }
                }}
            >
                <DialogTitle>{customTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{text}</DialogContentText>
                    {children}
                </DialogContent>
                {childrenButton && (
                    <SDialogActions>
                        {withCancel && (
                            <UploadButton variant="contained" component="span" onClick={handleCancel}>
                                <Typography variant="button">{t('dialog.cancel')}</Typography>
                            </UploadButton>
                        )}
                        <UploadButton
                            variant="contained"
                            component="span"
                            onClick={onClose}
                            disabled={disableChildrenButton}
                        >
                            {childrenButton}
                        </UploadButton>
                    </SDialogActions>
                )}
            </SMaterialDialog>
        </div>
    );
};

export default Dialog;

const SDialogActions = styled(({ ...otherProps }) => <DialogActions {...otherProps} />)`
    && {
        padding-right: ${(props) => props.theme.getSpacing(6)};
        padding-bottom: ${(props) => props.theme.getSpacing(4)};
    }
`;

const SMaterialDialog = styled(({ ...otherProps }) => <MaterialDialog {...otherProps} />)`
    & .MuiBackdrop-root {
        background-color: ${(props) => props.theme.colors.utility.backdrop};
    }
`;

