import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';

import { t } from '@/i18n.js';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { Session } from '@/utils/contexts/SessionContext';
import { saveAs } from 'file-saver';

/** Import téléchargement des autres analyses */
import { initialResultRads, ResultsRads } from '@/utils/ResultsRads';
import { Classification, ClassificationResult, Coronary, ImageOrderInStorage, IStoredAnalysis } from '@api';
import { GetStoredAnalysisDetail } from '@/services/StoredAnalysisService';
import { DownloadImagesFromBack } from '@/services/ImageService';
import { DownloadImagesFromVmBack } from '@/services/VmBlobService';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

import { File } from '@/components/molecules';
import { filterImages } from '@/utils/FileOrdering';
import { Loader } from '@/components/atoms';
import circleLoader from '@/utils/animations/circle-loader.json';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { pdf } from '@react-pdf/renderer';
import PdfImages from '@/pdf/rads/PdfImages';

type Props = {
    id: number;
    currentSessionInCookie: Session;
};

const initialStoredAnalysis: IStoredAnalysis = {
    id: 0,
    label: '',
    classificationResult: ClassificationResult.Error,
    classification: Classification.SixClasses,
    imageOrderInStorage: ImageOrderInStorage.LadCxRca,
    analysisGuid: '',
    note: '',
    highLighted: false,
    reader: '',
    memo: '',
    isFromPacs: false,
    isExplicabilityComputed: false,
    hasBeenOpened: false,
    token: ''
};

export const DownloadAnalysisField = (props: Props) => {
    const { id, currentSessionInCookie } = props;
    const [snackbarContext, updateSnackbar] = useSnackbarContext();
    const history = useHistory();

    /* Test téléchargement */
    const [isLoadingAnalysisFinished, setIsLoadingAnalysisFinished] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    /* Résultats par coronaire */
    const [ladResults, setLAD] = useState<ResultsRads>(initialResultRads);
    const [cxResults, setCX] = useState<ResultsRads>(initialResultRads);
    const [rcaResults, setRCA] = useState<ResultsRads>(initialResultRads);
    /* Chargement des graphes */
    const [storedAnalysis, setStoredAnalysis] = useState<IStoredAnalysis>(initialStoredAnalysis);
    /* Stop le useEffect pour le téléchargement du zip une seule fois par appui du bouton */
    const [zipButtonClicked, setZipButtonClicked] = useState<boolean>(false);

    /** Chargement des résultats de l'analyse choisie afin de remplir le PDF avant download  */
    const loadAnalysis = (idAnalysis: number) => {
        (async function iifeFunction() {
            try {
                let resultsStoredDetails = await GetStoredAnalysisDetail({
                    sessionContext: currentSessionInCookie,
                    analysisId: idAnalysis
                }).catch((err) => {
                    updateSnackbar({
                        snackString: t('snackbar.error.analysis.server'),
                        severity: 'error'
                    });
                    setZipButtonClicked(false);
                });
                /** Avoid to create a TS(2339) Error - Property 'X' does not exist on type 'void' since :
                 * let resultsStoredDetails: void | StoredAnalysisResponse
                 * Now with the if statement we only have a resultStoredDetails as a StoredAnalysisResponse type
                 */
                if (resultsStoredDetails !== undefined) {
                    const currentStoredAnalysis = resultsStoredDetails.storedAnalysis;
                    setStoredAnalysis(currentStoredAnalysis);
                    const results = resultsStoredDetails.radsAnalysisResponsesList;

                    let downloadedF;
                    if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
                        const imageService = new DownloadImagesFromVmBack(
                            { token: currentSessionInCookie.authResponse.token },
                            process.env.BackendServiceURL
                        );

                        downloadedF = await imageService
                            .downloadAllBlobs({
                                sessionContext: currentSessionInCookie,
                                analysisGuid: currentStoredAnalysis.analysisGuid,
                                isFromPacs: currentStoredAnalysis.isFromPacs
                            })
                            .catch((err: any) => {
                                updateSnackbar({
                                    snackString: t('snackbar.error.analysis.server'),
                                    severity: 'error'
                                });
                                setZipButtonClicked(false);
                            });
                    } else {
                        const imageService = new DownloadImagesFromBack(
                            { token: currentSessionInCookie.authResponse.token },
                            process.env.BackendServiceURL
                        );

                        downloadedF = await imageService
                            .downloadAllBlobs({
                                sessionContext: currentSessionInCookie,
                                analysisId: currentStoredAnalysis.id
                            })
                            .catch((err) => {
                                updateSnackbar({
                                    snackString: t('snackbar.error.analysis.server'),
                                    severity: 'error'
                                });
                                setZipButtonClicked(false);
                            });
                    }
                    var downloadedFiles = downloadedF as File[];

                    if (downloadedF == undefined || !downloadedF || downloadedFiles.length == 0) {
                        // the DownloadImagesFromStorage doesn't do .catch((err: any) => because it iterates in all possible
                        // containername so it is easier to always return 200 in python and raise error directly here
                        console.error('No files for this analysis, force redirect to home');
                        history.push('/home');
                        updateSnackbar({
                            snackString: t('snackbar.error.analysis.server'),
                            severity: 'error'
                        });
                    }

                    const allFiles: File[] = await FilterBlobsByCoronary(
                        Coronary.NotSpecified,
                        downloadedFiles,
                        currentStoredAnalysis.isFromPacs
                    );
                    const ladFiles: File[] = await FilterBlobsByCoronary(
                        Coronary.LAD,
                        downloadedFiles,
                        currentStoredAnalysis.isFromPacs
                    );
                    const cxFiles: File[] = await FilterBlobsByCoronary(
                        Coronary.Cx,
                        downloadedFiles,
                        currentStoredAnalysis.isFromPacs
                    );
                    const rcaFiles: File[] = await FilterBlobsByCoronary(
                        Coronary.RCA,
                        downloadedFiles,
                        currentStoredAnalysis.isFromPacs
                    );

                    const is27 = allFiles.length > 0;
                    let indexLad: number = -1;
                    let indexCx: number = -1;
                    let indexRca: number = -1;
                    let indexForImagesLad: number = -1;
                    let indexForImagesCx: number = -1;
                    let indexForImagesRca: number = -1;
                    if (is27) {
                        const { ladI, cxI, rcaI } = getIndexesFromStoredAnalysis(
                            currentStoredAnalysis.imageOrderInStorage
                        );
                        indexForImagesLad = ladI;
                        indexForImagesCx = cxI;
                        indexForImagesRca = rcaI;
                    }
                    indexLad = results.findIndex((pred) => pred.coronary === Coronary.LAD);
                    indexCx = results.findIndex((pred) => pred.coronary === Coronary.Cx);
                    indexRca = results.findIndex((pred) => pred.coronary === Coronary.RCA);

                    setLAD((lad) => ({
                        ...lad,
                        isLoading: false,
                        Images: is27
                            ? indexForImagesLad !== -1
                                ? filterImages(allFiles, ladFiles, is27, indexForImagesLad)
                                : []
                            : indexLad !== -1
                            ? filterImages(allFiles, ladFiles, is27, indexLad)
                            : [],
                        RadsAnalysisResponse:
                            indexLad !== -1 ? results[indexLad] : initialResultRads.RadsAnalysisResponse
                    }));

                    setCX((cx) => ({
                        ...cx,
                        isLoading: false,
                        Images: is27
                            ? indexForImagesCx !== -1
                                ? filterImages(allFiles, cxFiles, is27, indexForImagesCx)
                                : []
                            : indexCx !== -1
                            ? filterImages(allFiles, cxFiles, is27, indexCx)
                            : [],
                        RadsAnalysisResponse: indexCx !== -1 ? results[indexCx] : initialResultRads.RadsAnalysisResponse
                    }));

                    setRCA((rca) => ({
                        ...rca,
                        isLoading: false,
                        Images: is27
                            ? indexForImagesRca !== -1
                                ? filterImages(allFiles, rcaFiles, is27, indexForImagesRca)
                                : []
                            : indexRca !== -1
                            ? filterImages(allFiles, rcaFiles, is27, indexRca)
                            : [],
                        RadsAnalysisResponse:
                            indexRca !== -1 ? results[indexRca] : initialResultRads.RadsAnalysisResponse
                    }));
                    setIsLoadingAnalysisFinished(true);
                } else {
                    setIsLoadingAnalysisFinished(false);
                    setIsFailure(true);
                }
            } catch (err) {
                setZipButtonClicked(false);
                setIsLoadingAnalysisFinished(false);
                setIsFailure(true);
            }
        })();
    };

    const handleDownload = async () => {
        const blob = await pdf(
            <PdfImages
                storedAnalysis={storedAnalysis}
                ladResults={ladResults}
                cxResults={cxResults}
                rcaResults={rcaResults}
            />
        ).toBlob();
        saveAs(blob, t('pdfImages.documentTitle') + storedAnalysis.id);
    };

    useEffect(() => {
        if (
            isLoadingAnalysisFinished &&
            ladResults !== initialResultRads &&
            cxResults !== initialResultRads &&
            rcaResults !== initialResultRads &&
            zipButtonClicked
        ) {
            setZipButtonClicked(false);
            handleDownload();
        }
    }, [ladResults, cxResults, rcaResults, isLoadingAnalysisFinished]);

    return (
        <>
            <>
                {!zipButtonClicked && !isFailure && (
                    <Tooltip title={t('options.downloadImages') || ''}>
                        <IconButton
                            onClick={() => {
                                setZipButtonClicked(true);
                                loadAnalysis(id);
                            }}
                        >
                            <SFileDownloadIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {zipButtonClicked && !isFailure && (
                    <>
                        <Loader jsonToUse={circleLoader} width="2vw" />
                    </>
                )}
                {isFailure && <>ERR</>}
            </>
        </>
    );
};

export default DownloadAnalysisField;

const FilterBlobsByCoronary = async (
    coronary: Coronary,
    downloadFiles: File[],
    analysisComesFromDicomServer: boolean
) => {
    var files: File[] = [];

    if (analysisComesFromDicomServer && coronary === Coronary.NotSpecified) {
        for (let i = 0; i < downloadFiles.length; i++) {
            const fileName = downloadFiles[i].fileName.split('/');
            const imageNumber = fileName[2].split('.')[0];
            files.push({
                base64: downloadFiles[i].base64,
                fileName: imageNumber,
                data: ''
            });
        }
    } else {
        for (let i = 0; i < downloadFiles.length; i++) {
            const fileName = downloadFiles[i].fileName.split('/');
            const coronaryInFileName = fileName[0];
            const imageNumber = fileName[1].split('.')[0];
            if (coronaryInFileName === coronary) {
                files.push({
                    base64: downloadFiles[i].base64,
                    fileName: imageNumber,
                    data: ''
                });
            }
        }
    }

    return files.sort((a, b) => (a.fileName as unknown as number) - (b.fileName as unknown as number));
};

const getIndexesFromStoredAnalysis = (imageOrderInStorage: ImageOrderInStorage) => {
    switch (imageOrderInStorage) {
        case ImageOrderInStorage.LadCxRca:
            return { ladI: 0, cxI: 1, rcaI: 2 };
        case ImageOrderInStorage.LadRcaCx:
            return { ladI: 0, rcaI: 1, cxI: 2 };
        case ImageOrderInStorage.CxLadRca:
            return { cxI: 0, ladI: 1, rcaI: 2 };
        case ImageOrderInStorage.CxRcaLad:
            return { cxI: 0, rcaI: 1, ladI: 2 };
        case ImageOrderInStorage.RcaCxLad:
            return { rcaI: 0, cxI: 1, ladI: 2 };
        case ImageOrderInStorage.RcaLadCx:
            return { rcaI: 0, ladI: 1, cxI: 2 };
        default:
            return { ladI: 0, cxI: 1, rcaI: 2 };
    }
};

const SFileDownloadIcon = styled(FileDownloadIcon)`
    color: ${(props) => props.theme.table.contentLightText};
`;

