import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';
import { t } from '@/i18n.js';

import { useSuccess } from '@/utils/hooks/useSuccess';

import {
    AdminClient,
    TupleOfUserOfAndDoctorsListWithoutUserDetailOfAndUsers_SettingsOfAndStoredAnalysis_UsersWithoutUserDetailOfAndUser_StorageOf as TupleAllUsers
} from '@api';
import { Typography, SearchField, ButtonBlueWithIcon } from '@/components/atoms';
import { AdminTableHome } from '@/components/molecules';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Session } from '@/utils/contexts/SessionContext';

type Props = {
    currentSessionInCookie: Session;
};

export const AdminUsersList = (props: Props) => {
    const { currentSessionInCookie } = props;
    const history = useHistory();

    const [usersDoctorsSettings, setUsersDoctorsSettings] = useState<TupleAllUsers>();

    // Barre de recherche
    const [searchFilter, setSearchFilter] = useState<string>('');

    //Premier user du data grid après filtration
    const [firstUserIDfromGrid, setFirstUserIDfromGrid] = useState<number>(0);

    const [isLoading, isSuccess, message, setSuccess] = useSuccess({ isLoading: true, isSuccess: false });

    const [counter, setCounter] = useState<number>(0);

    const fetchAllUsers = useCallback(async () => {
        try {
            setSuccess({ isLoading: true, isSuccess: false });
            const adminClient = new AdminClient(
                { token: currentSessionInCookie.authResponse.token },
                process.env.BackendServiceURL
            );
            adminClient.getAllUsers().then((tupleresponse) => {
                setUsersDoctorsSettings(tupleresponse);
                setSuccess({ isLoading: false, isSuccess: true });
            });
        } catch (error) {
            setSuccess({ isLoading: false, isSuccess: false, message: 'error' });
        }
    }, [currentSessionInCookie]);

    useEffect(() => {
        (async function iieFunction() {
            await fetchAllUsers();
        })();
    }, []);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    return (
        <SPage>
            <SItem>
                <Typography title variant="h2">
                    {t('admin.list.title')}
                </Typography>

                <SFilterDiv
                    onKeyPress={(ev: React.KeyboardEvent) => {
                        if (ev.key === 'Enter' && firstUserIDfromGrid !== 0) {
                            history.push('/admin/user/' + firstUserIDfromGrid);
                            ev.preventDefault();
                        }
                    }}
                >
                    <SFilterSubDiv>
                        <SearchField
                            onChange={setSearchFilter}
                            searchToOpen={firstUserIDfromGrid.toString()}
                            label={t('admin.list.search')}
                            url={'/admin/user/'}
                            width={'30vw'}
                        />
                    </SFilterSubDiv>
                </SFilterDiv>
                <SButton>
                    <ButtonBlueWithIcon
                        icon={<AccountCircleIcon />}
                        text={t('admin.createUser')}
                        onClick={() => history.push('/admin/new-user')}
                        disabled={false}
                    />
                </SButton>
            </SItem>
            <SContainer>
                <AdminTableHome
                    success={{ isLoading, isSuccess, message }}
                    nbPages={100}
                    searchFilter={searchFilter}
                    usersDoctorsSettings={usersDoctorsSettings}
                    setFirstUserIDfromGrid={setFirstUserIDfromGrid}
                />
            </SContainer>
        </SPage>
    );
};

export default AdminUsersList;

const SPage = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

const SItem = styled.div`
    display: flex;
    justify-content: space-between;
    width: 95%;
    align-items: center;
    color: ${(props) => props.theme.page.text};
    margin: ${(props) => props.theme.getSpacing(2)};
`;

const SContainer = styled.div`
    left: 0;
    width: 95%;
    height: 70%;
    z-index: 1000;
`;

const SFilterDiv = styled.div`
    display: flex;
`;

const SFilterSubDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SButton = styled.div`
    display: flex;
    width: 230px;
`;

