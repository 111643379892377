import React from 'react';
import styled from 'styled-components';

import CookieConsent from 'react-cookie-consent';

import { t } from '@/i18n.js';
import { Link } from 'react-router-dom';
import theme from '@/utils/styles/Theme';

export const CookieBanner = () => {
    return (
        <CookieConsent
            enableDeclineButton={false}
            onAccept={() => {}}
            buttonText={t('cookie.accept')}
            expires={365} //GDPR Compliance - Renew user consent every year
            flipButtons
            style={{
                background: '#203f66',
                zIndex: '3',
                position: 'relative',
                paddingLeft: '85px',
                width: 'calc(100% - 85px)'
            }}
            buttonStyle={{
                background: theme.colors.bluejean,
                color: theme.colors.white,
                borderRadius: '5px 5px 5px 5px'
            }}
        >
            {t('cookie.disclaimer')}
            {'  '}
            <SLink to="/cookie-policy">{t('cookie.learn')}</SLink>
        </CookieConsent>
    );
};

export default CookieBanner;

const SLink = styled(Link)`
    color: ${(props) => props.theme.colors.grey};
    &:hover {
        color: ${(props) => props.theme.colors.lightgrey};
    }
`;

