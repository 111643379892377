import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { t } from '@/i18n.js';

import { Typography } from '@/components/atoms';
import { Session } from '@/utils/contexts/SessionContext';
import { AdminStatisticsHome } from '@/components/organisms';

import Link from '@mui/material/Link';

type Props = {
    currentSessionInCookie: Session;
};

type AdminUserRoute = {
    id: string;
};

export const AdminUserStatistics = (props: Props) => {
    const { currentSessionInCookie } = props;
    const { id } = useParams<AdminUserRoute>();
    const history = useHistory();

    return (
        <>
            <STypographyDiv>
                <SLink sx={{ marginRight: '50px' }} onClick={() => history.push('/admin/users')} underline="hover">
                    {t('back')}
                </SLink>
                <Typography subtitle="true" variant="h2">
                    {t('statistics.title')}
                </Typography>
            </STypographyDiv>

            <AdminStatisticsHome currentSessionInCookie={currentSessionInCookie} userId={Number(id)} />
        </>
    );
};

const STypographyDiv = styled.div`
    width: 90%;
    display: flex;
    color: ${(props) => props.theme.page.text};
`;

const SLink = styled(Link)`
    cursor: pointer;
    width: 80px;
`;
export default AdminUserStatistics;

