import { ResultsRads } from '@/utils/ResultsRads';
import { Classification, ClassificationResult } from '@api';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { BarDatum } from '@nivo/bar';

export interface IPieInfoObject {
    id: Array<string> | string;
    value: Array<number> | number;
}

export interface IPieInfo {
    /**Data which will be displayed in the graph */
    pieData: IPieInfoObject[];
    /**Colors will be modified dynamically depending on how many data we have */
    colors: string[];
}

export const formatPieData = (data: ResultsRads | undefined, keys: Array<string>, colors: string[]): IPieInfo => {
    let res: IPieInfo = { pieData: [], colors: [] };
    if (data && data.RadsAnalysisResponse.imageClassifications) {
        const countClassification = data.RadsAnalysisResponse.imageClassifications.reduce(
            (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
            new Map()
        );
        if (data.RadsAnalysisResponse.classification == Classification.ThreeClasses) {
            if (countClassification.get(ClassificationResult.Rads0)) {
                res.pieData.push({ id: keys[0], value: countClassification.get(ClassificationResult.Rads0) });
                res.colors.push(colors[0]);
            }
            if (countClassification.get(ClassificationResult.Rads12)) {
                res.pieData.push({ id: keys[1], value: countClassification.get(ClassificationResult.Rads12) });
                res.colors.push(colors[1]);
            }
            if (countClassification.get(ClassificationResult.Rads345)) {
                res.pieData.push({ id: keys[2], value: countClassification.get(ClassificationResult.Rads345) });
                res.colors.push(colors[2]);
            }
        } else if (data.RadsAnalysisResponse.classification == Classification.FourClasses) {
            if (countClassification.get(ClassificationResult.Rads0)) {
                res.pieData.push({ id: keys[0], value: countClassification.get(ClassificationResult.Rads0) });
                res.colors.push(colors[0]);
            }
            if (countClassification.get(ClassificationResult.Rads12)) {
                res.pieData.push({ id: keys[1], value: countClassification.get(ClassificationResult.Rads12) });
                res.colors.push(colors[1]);
            }
            if (countClassification.get(ClassificationResult.Rads34)) {
                res.pieData.push({ id: keys[2], value: countClassification.get(ClassificationResult.Rads345) });
                res.colors.push(colors[2]);
            }
            if (countClassification.get(ClassificationResult.Rads5)) {
                res.pieData.push({ id: keys[3], value: countClassification.get(ClassificationResult.Rads5) });
                res.colors.push(colors[3]);
            }
        } else if (data.RadsAnalysisResponse.classification == Classification.SixClasses) {
            if (countClassification.get(ClassificationResult.Rads0)) {
                res.pieData.push({ id: keys[0], value: countClassification.get(ClassificationResult.Rads0) });
                res.colors.push(getColorByRads(ClassificationResult.Rads0));
            }
            if (countClassification.get(ClassificationResult.Rads1)) {
                res.pieData.push({ id: keys[1], value: countClassification.get(ClassificationResult.Rads1) });
                res.colors.push(getColorByRads(ClassificationResult.Rads1));
            }
            if (countClassification.get(ClassificationResult.Rads12)) {
                res.pieData.push({ id: keys[2], value: countClassification.get(ClassificationResult.Rads12) });
                res.colors.push(getColorByRads(ClassificationResult.Rads12));
            }
            if (countClassification.get(ClassificationResult.Rads2)) {
                res.pieData.push({ id: keys[2], value: countClassification.get(ClassificationResult.Rads2) });
                res.colors.push(getColorByRads(ClassificationResult.Rads2));
            }
            if (countClassification.get(ClassificationResult.Rads23)) {
                res.pieData.push({ id: keys[3], value: countClassification.get(ClassificationResult.Rads23) });
                res.colors.push(getColorByRads(ClassificationResult.Rads23));
            }
            if (countClassification.get(ClassificationResult.Rads3)) {
                res.pieData.push({ id: keys[3], value: countClassification.get(ClassificationResult.Rads3) });
                res.colors.push(getColorByRads(ClassificationResult.Rads3));
            }
            if (countClassification.get(ClassificationResult.Rads34)) {
                res.pieData.push({ id: keys[4], value: countClassification.get(ClassificationResult.Rads34) });
                res.colors.push(getColorByRads(ClassificationResult.Rads34));
            }
            if (countClassification.get(ClassificationResult.Rads4)) {
                res.pieData.push({ id: keys[4], value: countClassification.get(ClassificationResult.Rads4) });
                res.colors.push(getColorByRads(ClassificationResult.Rads4));
            }
            if (countClassification.get(ClassificationResult.Rads5)) {
                res.pieData.push({ id: keys[5], value: countClassification.get(ClassificationResult.Rads5) });
                res.colors.push(getColorByRads(ClassificationResult.Rads5));
            }
        }
    }
    return res;
};

export const formatBarData = (data: ResultsRads, keys: Array<string>, index: string) => {
    let res: BarDatum[] = [];
    if (
        data &&
        data.RadsAnalysisResponse.averageRads !== null &&
        data.RadsAnalysisResponse.classification == Classification.ThreeClasses
    ) {
        res = [
            {
                [index]: keys[0],
                ///!\ Read as Rads0 as we are in the ThreeClasses classification
                [keys[0]]: data.RadsAnalysisResponse.averageRads?.rads0 ?? 0
            },
            {
                [index]: keys[1],
                ///!\ Read as Rads12 as we are in the ThreeClasses classification
                [keys[1]]: data.RadsAnalysisResponse.averageRads?.rads1 ?? 0
            },
            {
                [index]: keys[2],
                ///!\ Read as Rads345 as we are in the ThreeClasses classification
                [keys[2]]: data.RadsAnalysisResponse.averageRads?.rads2 ?? 0
            }
        ];
    } else if (
        data &&
        data.RadsAnalysisResponse.averageRads !== null &&
        data.RadsAnalysisResponse.classification == Classification.FourClasses
    ) {
        res = [
            {
                [index]: keys[0],
                ///!\ Read as Rads0 as we are in the FourClasses classification
                [keys[0]]: data.RadsAnalysisResponse.averageRads?.rads0 ?? 0
            },
            {
                [index]: keys[1],
                ///!\ Read as Rads12 as we are in the FourClasses classification
                [keys[1]]: data.RadsAnalysisResponse.averageRads?.rads1 ?? 0
            },
            {
                [index]: keys[2],
                ///!\ Read as Rads34 as we are in the FourClasses classification
                [keys[2]]: data.RadsAnalysisResponse.averageRads?.rads2 ?? 0
            },
            {
                [index]: keys[3],
                ///!\ Read as Rads5 as we are in the FourClasses classification
                [keys[3]]: data.RadsAnalysisResponse.averageRads?.rads5 ?? 0
            }
        ];
    } else if (
        data &&
        data.RadsAnalysisResponse.averageRads !== null &&
        data.RadsAnalysisResponse.classification == Classification.SixClasses
    ) {
        res = [
            {
                [index]: keys[0],
                [keys[0]]: data.RadsAnalysisResponse.averageRads?.rads0
            },
            {
                [index]: keys[1],
                [keys[1]]: data.RadsAnalysisResponse.averageRads?.rads1
            },
            {
                [index]: keys[2],
                [keys[2]]: data.RadsAnalysisResponse.averageRads?.rads2
            },
            {
                [index]: keys[3],
                [keys[3]]: data.RadsAnalysisResponse.averageRads?.rads3
            },
            {
                [index]: keys[4],
                [keys[4]]: data.RadsAnalysisResponse.averageRads?.rads4
            },
            {
                [index]: keys[5],
                [keys[5]]: data.RadsAnalysisResponse.averageRads?.rads5
            }
        ];
    }
    return res;
};

