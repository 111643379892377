import { getSpacing } from '@/utils/Spacing';
import colors from '@/utils/colors';

const spacing = { unit: 'px', size: 4 };

type Theme = typeof theme;

export interface ThemeProps {
    theme: Theme;
}

export const theme = {
    colors,
    getSpacing: getSpacing(spacing),
    borderRadiusContainer: '20px'
};

export default theme;

