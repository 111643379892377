import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PDFDownloadLink } from '@react-pdf/renderer';
import i18n, { t } from '@/i18n.js';

import { Classification, ClassificationResult, ImageOrderInStorage, IStoredAnalysis } from '@api';
import { Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';

import { ButtonActionOutlined } from '@/components/atoms';
import { File } from '@/components/molecules';

import useDebounce from '@/utils/hooks/useDebounce';
import { Session } from '@/utils/contexts/SessionContext';
import { ResultsRads } from '@/utils/ResultsRads';

import PdfDocument from '@/pdf/rads/PdfDocument';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const initialStoredAnalysis: IStoredAnalysis = {
    id: 0,
    label: '',
    classificationResult: ClassificationResult.Error,
    classification: Classification.SixClasses,
    imageOrderInStorage: ImageOrderInStorage.LadCxRca,
    analysisGuid: '',
    note: '',
    highLighted: false,
    reader: '',
    memo: '',
    isFromPacs: false,
    isExplicabilityComputed: false,
    hasBeenOpened: false,
    token: ''
};

type Props = {
    storedAnalysis: IStoredAnalysis;
    currentSessionInCookie: Session;
    analysisId: number;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    medicalReportId: number;
    medicalProps: MedicalProps;
    displayMedicalReportInPdf: boolean;
    patientNameVar: string;
};

export const DownloadPdfField = (props: Props) => {
    const {
        storedAnalysis,
        currentSessionInCookie,
        analysisId,
        ladResults,
        cxResults,
        rcaResults,
        medicalReportId,
        medicalProps,
        displayMedicalReportInPdf,
        patientNameVar
    } = props;

    const [files3D, setFiles3D] = useState<File[]>([]);

    const [patientName, setPatientName] = useState(patientNameVar);
    const patientNameDebounced = useDebounce(patientName, 500);

    useEffect(() => {
        setPatientName(patientNameVar);
    }, [patientNameVar]);

    let displayFfrOnPdf = false;
    const localStorageDisplayFfr = JSON.parse(localStorage.getItem('ffr_prediction_displayed_on_pdf') || '"false"');
    if (localStorageDisplayFfr == true) {
        displayFfrOnPdf = true;
    }

    return (
        <>
            <div style={{ visibility: 'hidden', position: 'fixed' }}>
                <canvas width="300px" height="200px" id="canvasForChartsDownload" />
                <canvas width="300px" height="300px" id="canvasForBulletDownload" />
            </div>
            <SContainer>
                <PDFDownloadLink
                    style={{ textDecoration: 'none' }}
                    document={
                        <PdfDocument
                            storedAnalysis={storedAnalysis}
                            ladResults={ladResults}
                            cxResults={cxResults}
                            rcaResults={rcaResults}
                            coverImages={files3D}
                            patientName={patientNameDebounced}
                            medicalProps={medicalReportId != 0 && displayMedicalReportInPdf ? medicalProps : undefined}
                            language={i18n.language}
                            date={storedAnalysis.createdOn}
                            displayFFR={displayFfrOnPdf}
                        />
                    }
                    fileName={t('downloadAnalysis.pdfname') + '-' + storedAnalysis.id}
                >
                    {({ blob, url, loading, error }) =>
                        !error ? (
                            <ButtonActionOutlined
                                icon={<PictureAsPdfIcon />}
                                tooltipText={t('options.downloadPDF')}
                                onClick={() => {
                                    return;
                                }}
                                disabled={false}
                            />
                        ) : (
                            <>PDF ERR</>
                        )
                    }
                </PDFDownloadLink>
            </SContainer>
        </>
    );
};

export default DownloadPdfField;

const SContainer = styled.div`
    display: inline-block;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

