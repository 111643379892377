import React from 'react';
import styled from 'styled-components';
import { t } from '@/i18n.js';

import { ButtonActionOutlined } from '@/components/atoms';

import { Session } from '@/utils/contexts/SessionContext';

import { ResultsRads } from '@/utils/ResultsRads';

import { Classification, ClassificationResult, ImageOrderInStorage, IStoredAnalysis } from '@api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfImages from '@/pdf/rads/PdfImages';

export const initialStoredAnalysis: IStoredAnalysis = {
    id: 0,
    label: '',
    classificationResult: ClassificationResult.Error,
    classification: Classification.SixClasses,
    imageOrderInStorage: ImageOrderInStorage.LadCxRca,
    analysisGuid: '',
    note: '',
    highLighted: false,
    reader: '',
    memo: '',
    isFromPacs: false,
    isExplicabilityComputed: false,
    hasBeenOpened: false,
    token: ''
};

type Props = {
    currentSessionInCookie: Session;
    storedAnalysis: IStoredAnalysis;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
};

export const DownloadImagesField = (props: Props) => {
    const { currentSessionInCookie, storedAnalysis, ladResults, cxResults, rcaResults } = props;

    return (
        <>
            <SContainer>
                <PDFDownloadLink
                    style={{ textDecoration: 'none' }}
                    document={
                        <PdfImages
                            storedAnalysis={storedAnalysis}
                            ladResults={ladResults}
                            cxResults={cxResults}
                            rcaResults={rcaResults}
                        />
                    }
                    fileName={t('pdfImages.documentTitle') + storedAnalysis.id}
                >
                    {({ blob, url, loading, error }) =>
                        !error ? (
                            <ButtonActionOutlined
                                icon={<FileDownloadIcon />}
                                tooltipText={t('options.downloadImages')}
                                onClick={() => {
                                    return;
                                }}
                                disabled={false}
                            />
                        ) : (
                            <>IMG ERR</>
                        )
                    }
                </PDFDownloadLink>
            </SContainer>
        </>
    );
};

export default DownloadImagesField;

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

// OLD code if needed to download the images in a folder for admin account

// const [downloadDone, setDownloadDone] = useState(true);

// const downloadImages = () => {
//     downloadImagesFolder(analysisId, ladResults, cxResults, rcaResults);
//     setDownloadDone(true);
// };
// return (
//     <>
//         <SContainer>
//             {!downloadDone ? (
//                 <>
//                     <Loader jsonToUse={circleLoader} width="2vw" />
//                 </>
//             ) : (
//                 <ButtonActionOutlined
//                     icon={<FolderCopyIcon />}
//                     tooltipText={t('options.downloadImages')}
//                     onClick={downloadImages}
//                     disabled={false}
//                 />
//             )}
//         </SContainer>
//     </>
// );

