import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Typography } from '@/components/atoms';

import { t } from '@/i18n.js';

type Props = {
    ffr_prediction: string;
    ffr_available: boolean;
};

type ColorProps = {
    color: string;
};

type SContainerProps = {
    display: string;
};

export const FFRPrediction = (props: Props) => {
    const { ffr_prediction, ffr_available } = props;

    const [ffrPrediction, setFfrPrediction] = useState<string>('');
    const [colorFfrPrediction, setColorFfrPrediction] = useState<string>('');

    const ffrPredictionClassifier = (ffr_prediction: string) => {
        switch (ffr_prediction) {
            case 'FfrMinus':
                setFfrPrediction('> 0.8');
                setColorFfrPrediction('#cc51fc');
                break;
            case 'FfrPlus':
                setFfrPrediction('<= 0.8');
                setColorFfrPrediction('#004e70');
                break;
            default:
                setColorFfrPrediction('#004e70');
                break;
        }
    };

    useEffect(() => {
        ffrPredictionClassifier(ffr_prediction);
    }, [ffr_prediction]);

    return (
        <>
            <SContainer display={ffr_available ? 'flex' : 'none'}>
                <SHalf color="#000000">
                    <Typography>{t('analysis.analysisReportComp.coronaryInformation.ffrPrediction')}</Typography>
                </SHalf>
                <SHalf color={colorFfrPrediction}>{ffrPrediction}</SHalf>
            </SContainer>
        </>
    );
};

export default FFRPrediction;

const SHalf = styled.div<ColorProps>`
    display: flex;
    text-align: center;
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
    color: ${(props: ColorProps) => props.color};
`;

const SContainer = styled.div<SContainerProps>`
    width: 40%;
    display: ${(props: SContainerProps) => props.display};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    user-select: none;
    border-radius: 10px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: 16px 16px 32px #d3d3d3, -16px -16px 32px #fdfdfd;
    margin: ${(props) => props.theme.getSpacing(1)};
    padding: ${(props) => props.theme.getSpacing(2)};
`;

