import { createContext } from 'react';
import React from 'react';
import { useContextFactory } from '@/utils/contexts/ContextFactory';
import Cookies from 'js-cookie';
import { DoctorEntity, isElectronApp, nullDoctorEntity } from './SessionContext';
import { ModeConstant } from '../constants/deploymentModeSettings';

export interface CurrentDoctor {
    id: number;
    name: string;
    mail: string;
}

export const CurrentDoctorContext = createContext<[CurrentDoctor, (curDoc: CurrentDoctor) => void]>([
    nullDoctorEntity,
    () => {}
]);

export const hasSetCurrentDoctorCookieIsTrue: any = () => {
    if (isElectronApp()) {
        // @ts-ignore
        return window.api.getHasSetCurrentDoctor() == true;
    } else {
        return Cookies.get('hasSetCurrentDoctor') == 'true';
    }
};

export const useCurrentDoctorContext = useContextFactory('CurrentDoctorContext', CurrentDoctorContext);

export const CurrentDoctorContextProvider: React.FC = (props) => {
    const initCurrentDoctor = () => {
        const currentdoctor = localStorage.getItem('current_doctor');
        if (currentdoctor !== null) {
            return JSON.parse(currentdoctor);
        }
        return nullDoctorEntity;
    };

    const [currentDoctorInStorage, setCurrentDoctorInStorage] = React.useState<DoctorEntity>(initCurrentDoctor);

    const updateCurrentDoctor = (currentDoctor: DoctorEntity) => {
        // Cookie removed on SessionContext during logout()
        if (isElectronApp()) {
            // @ts-ignore
            window.api.setHasSetCurrentDoctor(true);
        }
        if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
            // VM
            Cookies.set('hasSetCurrentDoctor', 'true');
        } else {
            // CLOUD
            Cookies.set('hasSetCurrentDoctor', 'true', { secure: true, sameSite: 'strict' });
        }
        setCurrentDoctorInStorage(currentDoctor);
    };

    const defaultCurrentDoctorContext: [DoctorEntity, typeof updateCurrentDoctor] = [
        currentDoctorInStorage,
        updateCurrentDoctor
    ];
    return (
        <CurrentDoctorContext.Provider value={defaultCurrentDoctorContext}>
            {props.children}
        </CurrentDoctorContext.Provider>
    );
};

