import { StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';

Font.register({
    family: 'Roboto',
    src: 'fonts/Roboto-Regular.ttf'
});

const styles = StyleSheet.create({
    coverPage: {
        fontFamily: 'Roboto',
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        paddingTop: 200,
        paddingBottom: 40
    },
    page: {
        fontFamily: 'Roboto',
        backgroundColor: '#ffffff',
        padding: 20
    },
    headerContainer: {
        flexDirection: 'row',
        marginTop: 5
    },
    coronaryTitle: {
        fontSize: 10,
        marginTop: 35,
        fontWeight: 'medium',
        left: 0,
        right: 0,
        textAlign: 'center',
        position: 'absolute'
    },
    coverPageColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    analysisInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 10,
        height: 60
    },
    coverPageLogo: {
        maxHeight: 100,
        maxWidth: 200
    },
    coverPageHeartParentpng: {
        width: 250,
        height: 350,
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center'
    },
    coverPageHeartpng: {
        width: 200,
        height: 300,
        position: 'absolute'
    },
    coverPageMainTitle: {
        fontSize: 50
    },
    tableAllCoronaries: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 560,
        minWidth: 560,
        marginTop: 25,
        marginBottom: 25
    },
    tableAllCoronariesConclusion: {
        fontSize: 14,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        position: 'absolute',
        top: 5,
        right: 7
    },
    tableCoronary: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%'
    },
    resultsTableCoronaryRowElemTitle: {
        display: 'flex',
        flex: 0.5,
        fontSize: 14,
        color: '#717171',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        paddingTop: 5,
        paddingBottom: 5
    },
    rowTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 15,
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingTop: 10,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 10,
        color: '#0cb4ff'
    },
    rowTitleMpr: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 15,
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        marginTop: 10,
        paddingTop: 10,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 10,
        color: '#0cb4ff'
    },
    rowTitleCoronary: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        fontSize: 15,
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingTop: 10,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 10,
        color: '#0cb4ff'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 12,
        marginTop: 5,
        marginBottom: 10,
        paddingLeft: 3,
        paddingRight: 3
    },
    rowImage: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
        marginBottom: 10,
        paddingLeft: 3,
        paddingRight: 3
    },
    resultsTable: {
        backgroundColor: 'rgba(0,0,0,0.05)',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        display: 'flex',
        width: '100%',
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5
    },
    resultsTableRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderBottom: '0.5px solid #cbcbcb'
    },
    // ! --------------- OVERALL PART (FIRST PAGE) ---------------
    resultsTableRowElemTitleOverall: {
        display: 'flex',
        flex: 0.68,
        fontSize: 14,
        color: '#717171',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        paddingTop: 5,
        paddingBottom: 5
    },
    resultsTableRowElemOverall: {
        display: 'flex',
        flex: 1,
        fontSize: 14,
        color: '#111111',
        justifyContent: 'flex-end',
        alignItems: 'baseline',
        paddingTop: 5,
        paddingBottom: 5
    },
    resultsTableRowElemRads: {
        display: 'flex',
        flex: 1,
        fontSize: 14,
        color: '#717171',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        paddingTop: 5,
        paddingBottom: 5
    },
    resultsTableRowElemTitle: {
        display: 'flex',
        fontSize: 14,
        color: '#717171',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        paddingTop: 5,
        paddingBottom: 5
    },
    resultsTableRowElem: {
        display: 'flex',
        fontSize: 14,
        color: '#111111',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        paddingTop: 5,
        paddingBottom: 5
    },
    resultsTableRowElemCharLessOrEqual: {
        display: 'flex',
        fontSize: 14,
        color: '#111111',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingTop: 5,
        paddingBottom: 5
    },
    coronaryName: {
        display: 'flex',
        padding: 5,
        backgroundColor: '#0CB4FF',
        color: 'white',
        borderRadius: 10
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    columnCadRadsConclusion: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 10
    },
    coronaryLogo: {
        maxHeight: 50,
        maxWidth: 50,
        marginTop: 20,
        marginRight: 500,
        marginLeft: 0
    },
    title: {
        fontSize: 20,
        marginBottom: 10,
        marginLeft: 10,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    presentationImagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5
    },
    finalResult: {
        fontSize: 15,
        marginBottom: 5,
        textAlign: 'center'
    },
    probaTitle: {
        fontSize: 12,
        marginBottom: 5,
        marginTop: 5,
        fontWeight: 'medium'
    },
    textSmallScore: {
        fontSize: 10
    },
    textSmallQuality: {
        fontSize: 8
    },
    resultContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: 5
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginLeft: 10
    },
    blockContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ctImagesContainerParent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    ctImagesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%'
    },
    ctImagesAndNameParent: {
        width: '180px',
        height: '180px'
    },
    ctImages: {
        width: '180px',
        height: '180px',
        marginTop: '10px'
    },
    ctImagesCoronaryName: {
        fontSize: 14,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        position: 'absolute',
        top: 15,
        right: 10,
        width: 45,
        height: 25,
        borderRadius: 5,
        backgroundColor: '#29b5fba9',
        color: 'white'
    },
    classificationImage: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        marginTop: 30
    },
    imagePlusScoreContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    resultImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    smallImageContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    image: {
        maxHeight: 280,
        maxWidth: 280,
        minHeight: 280,
        minWidth: 280
    },
    chartsContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: -20,
        left: 280
    },
    barChart: {
        height: 200,
        width: 250,
        marginRight: 20,
        top: -25
    },
    bulletChart: {
        height: 200,
        width: 250,
        marginRight: 20,
        top: -25
    },
    pieChart: {
        width: 150,
        left: 70
    },
    globalGraph: {
        height: 160,
        width: 360
    },
    smallImage: {
        height: 170,
        width: 170,
        marginTop: 5,
        marginBottom: 5
    },
    conclusionBoard: {
        maxHeight: 440,
        maxWidth: 440
    },
    mainPageImage: {
        maxHeight: 190,
        maxWidth: 190,
        minHeight: 190,
        minWidth: 190,
        marginTop: 20,
        marginRight: 30,
        marginLeft: 30,
        border: '2pt outset #0000'
    },
    commentsTitle: {
        marginTop: 25,
        fontSize: 20,
        fontWeight: 'medium'
    },
    fill1: {
        width: 500,
        borderTop: 1,
        borderColor: '#555555'
    },
    disclaimer: {
        marginLeft: 30,
        marginRight: 30,
        fontSize: 8,
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    pageNumber: {
        fontSize: 10,
        position: 'absolute',
        justifyContent: 'center',
        bottom: 25,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'black',
        paddingTop: 7,
        paddingBottom: 7,
        marginLeft: 100,
        marginRight: 100
    },
    conclusion: {
        fontSize: 20,
        marginBottom: 10,
        marginLeft: 10,
        fontWeight: 'bold'
    },
    commentaries: {
        marginTop: 25,
        fontSize: 15,
        fontWeight: 'medium'
    },
    blueBar: {
        fontSize: 10,
        position: 'absolute',
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor: '#0CB4FF',
        height: 15,
        marginLeft: 100,
        marginRight: 100,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15
    },
    redBar: {
        fontSize: 10,
        position: 'absolute',
        justifyContent: 'center',
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
        backgroundColor: '#F80040',
        height: 15,
        marginLeft: 100,
        marginRight: 100,
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15
    },
    smallTextGrey: {
        fontSize: 11,
        color: '#252525'
    },
    middleTextGrey: {
        fontSize: 13,
        color: '#252525',
        left: 10,
        marginTop: 10
    }
});

export default styles;

