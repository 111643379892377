import React from 'react';
import { ResponsivePie } from '@nivo/pie';

type Props = {
    /**Data which will be displayed, be carefull a special formatting is requried (see nivo library for more info) */
    data: object[];
    /** Colors used for bars */
    colors: Array<string>;
    /**If a '%' needs to be added at the end of the value */
    needPercentage?: boolean;
};
export const PieChart = (props: Props) => {
    const { data, colors, needPercentage } = props;

    return (
        <ResponsivePie
            data={data}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={colors}
            enableArcLabels
            enableArcLinkLabels={false}
            valueFormat={(v) => `${v}${needPercentage ? '%' : ''}`}
            arcLabel={(d) => `${d.id}(${d.value}${needPercentage ? '%' : ''})`}
        />
    );
};

export default PieChart;
