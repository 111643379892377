import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuItem, IconButton } from '@mui/material';

import { Typography, FrenchFlag, EnglishFlag } from '@/components/atoms';
import i18n from '@/i18n.js';
import { Language } from '@/utils/Language';
import useLocalStorage from '@/utils/hooks/useLocalStorage';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';
import Menu from '@mui/material/Menu';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import LanguageIcon from '@mui/icons-material/Language';

interface StyleProps {
    root_background: string;
    root_background_hover: string;
}

export const LanguageSwitcher = () => {
    const [themeContext, updateTheme] = useThemeContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [language, setLanguage] = useLocalStorage<string>('i18nextLng', i18n.language as string);
    const [isEnglishSelected, setIsEnglishSelected] = useState<boolean>(language === 'en-US' ? true : false);
    const [isFrenchSelected, setIsFrenchSelected] = useState<boolean>(language === 'fr-FR' ? true : false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLang = (value: string) => {
        setAnchorEl(null);
        if (value === 'fr-FR') {
            setIsFrenchSelected(true);
            setIsEnglishSelected(false);
        } else {
            setIsFrenchSelected(false);
            setIsEnglishSelected(true);
        }
        setLanguage(value as Language);
        i18n.changeLanguage(value);
    };

    const propsStyle: StyleProps = {
        root_background: themeContext.isLightMode ? lightTheme.app.switchDarkMode : darkTheme.app.switchDarkMode,
        root_background_hover: themeContext.isLightMode
            ? lightTheme.app.switchDarkModeHover
            : darkTheme.app.switchDarkModeHover
    };

    const classes = useStyles(propsStyle);

    return (
        <>
            <IconButton className={classes.root} size="small" onClick={handleClick}>
                <LanguageIcon fontSize="inherit" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    style: {
                        borderRadius: 20,
                        marginTop: 2,
                        backgroundColor: themeContext.isLightMode
                            ? lightTheme.navbar.languageSwitcher.background
                            : darkTheme.navbar.languageSwitcher.background
                    }
                }}
                sx={{
                    '& .MuiMenu-list': {
                        '& .Mui-selected': {
                            backgroundColor: themeContext.isLightMode
                                ? lightTheme.navbar.languageSwitcher.backgroundSelected
                                : darkTheme.navbar.languageSwitcher.backgroundSelected
                        }
                    }
                }}
            >
                <SMenuItem onClick={() => handleLang('en-US')} selected={isEnglishSelected}>
                    <SBaseline>
                        <STypography variant="subtitle2">English</STypography>
                        <EnglishFlag />
                    </SBaseline>
                </SMenuItem>
                <SMenuItem onClick={() => handleLang('fr-FR')} selected={isFrenchSelected}>
                    <SBaseline>
                        <STypography variant="subtitle2">Français</STypography>
                        <FrenchFlag />
                    </SBaseline>
                </SMenuItem>
            </Menu>
        </>
    );
};

export default LanguageSwitcher;

const SMenuItem = styled(MenuItem)`
    && {
        color: ${(props) => props.theme.navbar.languageSwitcher.color};
        background-color: ${(props) => props.theme.navbar.languageSwitcher.background};
        margin: ${(props) => props.theme.getSpacing(1)};
        border-radius: 10px;
        &:hover {
            background-color: ${(props) => props.theme.navbar.languageSwitcher.backgroundHover};
        }
    }
`;

const STypography = styled(Typography)`
    min-width: ${(props) => props.theme.getSpacing(17)};
    color: ${(props) => props.theme.navbar.languageSwitcher.color};
`;

const SBaseline = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const SDiv = styled.div`
    display: inline-flex;
    align-items: center;
    height: 50px;
    cursor: pointer;
    width: fit-content;
    margin-right: 25px;
    margin-left: 15px;
`;

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    root: {
        '&.MuiIconButton-root': { color: '#FFFFFF', backgroundColor: ({ root_background }) => root_background },
        '&.MuiIconButton-root:hover': { backgroundColor: ({ root_background_hover }) => root_background_hover }
    }
}));

