import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as MaterialSelect } from '@mui/material';
import styled from 'styled-components';
import uuid from 'uuidv4';
import { t } from '@/i18n.js';

type Props = {
    /* Label to describe the Select component (like the placeholder) */
    label?: string;
    /* Fix width of the component */
    width: string;
    /* Fix height of the component */
    height?: string;
    /* Fix font size of the component */
    fontSize?: string;
    /* Disabled the selection */
    disabled?: boolean;
    /* Action onChange (from parent component) */
    onChange: (value: any) => void;
    /**Possible values */
    values: string[];
    /**Value */
    value?: string;
    /**FormControl will be full width (not required) */
    fullWidth?: boolean;
    /**If true then the render will be executed thanks to valuesRender otherwise MenuItem */
    overrideValuesRendering?: boolean;
    /** Component to render each value*/
    valuesRender?: (value: string) => React.ReactNode;
};

export const SelectCharts = (props: Props) => {
    const {
        label,
        width,
        height,
        fontSize,
        disabled,
        onChange,
        values,
        value,
        fullWidth,
        overrideValuesRendering,
        valuesRender
    } = props;

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as string);
    };

    const id = uuid();

    return (
        <FormControl variant="standard" fullWidth={fullWidth ?? false}>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <SSelect
                labelId={`${id}-label`}
                id={id}
                value={value}
                onChange={handleChange}
                label={label}
                inputProps={{ 'aria-label': label ? '' : 'Without label' }}
                width={width}
                height={height}
                fontSize={fontSize}
                disabled={disabled}
                disableUnderline
            >
                <MenuItem key={'all'} value={'all'}>
                    {t('statistics.total')}
                </MenuItem>
                {overrideValuesRendering && valuesRender
                    ? Object.values(values).map((value) => valuesRender(value))
                    : Object.values(values).map((value) => (
                          <MenuItem key={value} value={value}>
                              {value}
                          </MenuItem>
                      ))}
            </SSelect>
        </FormControl>
    );
};

export default SelectCharts;

const SSelect = styled(({ width, height, fontSize, ...otherProps }) => <MaterialSelect {...otherProps} />)`
    && {
        width: ${(props) => props.width};
        height: ${(props) => props.height};
        font-size: ${(props) => props.fontSize};
        color: ${(props) => props.theme.page.text};
    }
`;

