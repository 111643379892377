import React from 'react';
import styled from 'styled-components';

export const FrenchFlag = () => (
    <SVG xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-fr" viewBox="0 0 640 480">
        <g fillRule="evenodd" strokeWidth="1pt">
            <path fill="#fff" d="M0 0h640v480H0z" />
            <path fill="#00267f" d="M0 0h213.3v480H0z" />
            <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
        </g>
    </SVG>
);

export default FrenchFlag;

const SVG = styled.svg`
  width: 40px;
  margin-top: 13px;
  margin-bottom: 13px;
`;
