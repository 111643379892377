import React, { SetStateAction, useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import styled from 'styled-components';

import { Typography, Select } from '@/components/atoms';
import i18n, { t } from '@/i18n.js';
import { adaptResultConclusionToLocale } from '@/utils/MedicalReportFormTranslation';
import { Language } from '@/utils/Language';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { ClassificationResult } from '@/../generated/api.client';
import CircleIcon from '@mui/icons-material/Circle';

type Props = {
    /** Default text*/
    defaultText: string;
    /**Doctor's conclusion */
    conclusionCadRads: string;
    /**Set the conclusion */
    setConclusionCadRads: React.Dispatch<SetStateAction<string>>;
    setClassificationResult: React.Dispatch<SetStateAction<ClassificationResult>>;
};

export const ConclusionRadsMedicalReport = (props: Props) => {
    const { defaultText, conclusionCadRads, setConclusionCadRads, setClassificationResult } = props;
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [coronaryCadsRadsConclusion, setCoronaryCadsRadsConclusion] = useState<string[]>([
        t('medicalReportForm.coronaryCadsRadsConclusion.rads0'),
        t('medicalReportForm.coronaryCadsRadsConclusion.rads1'),
        t('medicalReportForm.coronaryCadsRadsConclusion.rads12'),
        t('medicalReportForm.coronaryCadsRadsConclusion.rads2'),
        t('medicalReportForm.coronaryCadsRadsConclusion.rads23'),
        t('medicalReportForm.coronaryCadsRadsConclusion.rads3'),
        t('medicalReportForm.coronaryCadsRadsConclusion.rads34'),
        t('medicalReportForm.coronaryCadsRadsConclusion.rads4'),
        t('medicalReportForm.coronaryCadsRadsConclusion.rads5')
    ]);

    const getClassificationResultViaCadsRadsConclusion = (val: string): ClassificationResult => {
        switch (val) {
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads0', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads0', en):
                return ClassificationResult.Rads0;
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads1', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads1', en):
                return ClassificationResult.Rads1;
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads12', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads12', en):
                return ClassificationResult.Rads12;
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads2', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads2', en):
                return ClassificationResult.Rads2;
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads23', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads23', en):
                return ClassificationResult.Rads23;
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads3', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads3', en):
                return ClassificationResult.Rads3;
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads34', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads34', en):
                return ClassificationResult.Rads34;
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads4', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads4', en):
                return ClassificationResult.Rads4;
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads5', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads5', en):
                return ClassificationResult.Rads5;
            default:
                return ClassificationResult.NotSet;
        }
    };
    const renderCadsRadsConclusion = (val: string) => {
        switch (val) {
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads0', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads0', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads0), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads1', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads1', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads1), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads12', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads12', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads12), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads2', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads2', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads2), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads23', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads23', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads23), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads3', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads3', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads3), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads34', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads34', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads34), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads4', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads4', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads4), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads5', fr):
            case t('medicalReportForm.coronaryCadsRadsConclusion.rads5', en):
                return (
                    <MenuItem key={val} value={val}>
                        <SSelectWithIcon>
                            <CircleIcon
                                fontSize="small"
                                sx={{ color: getColorByRads(ClassificationResult.Rads5), marginRight: '6px' }}
                            />
                            <STypography>{val}</STypography>
                        </SSelectWithIcon>
                    </MenuItem>
                );
            default:
                return (
                    <MenuItem key={val} value={val}>
                        <STypography>{val}</STypography>
                    </MenuItem>
                );
        }
    };

    useEffect(() => {
        let intermediateConclusion = [
            t('medicalReportForm.coronaryCadsRadsConclusion.rads0'),
            t('medicalReportForm.coronaryCadsRadsConclusion.rads1'),
            t('medicalReportForm.coronaryCadsRadsConclusion.rads12'),
            t('medicalReportForm.coronaryCadsRadsConclusion.rads2'),
            t('medicalReportForm.coronaryCadsRadsConclusion.rads23'),
            t('medicalReportForm.coronaryCadsRadsConclusion.rads3'),
            t('medicalReportForm.coronaryCadsRadsConclusion.rads34'),
            t('medicalReportForm.coronaryCadsRadsConclusion.rads4'),
            t('medicalReportForm.coronaryCadsRadsConclusion.rads5')
        ];
        let conclusion = adaptResultConclusionToLocale(conclusionCadRads);
        if (!intermediateConclusion.includes(conclusion) && conclusion != '') {
            intermediateConclusion.push(conclusion);
            setCoronaryCadsRadsConclusion(intermediateConclusion);
        }

        setConclusionCadRads(conclusion);
        setClassificationResult(getClassificationResultViaCadsRadsConclusion(conclusion));
        setIsLoaded(true);
    }, [conclusionCadRads, i18n.language]);

    let fr = { lng: Language.French };
    let en = { lng: Language.English };

    const handleChange = (value: string) => {
        setConclusionCadRads(value);
        setClassificationResult(getClassificationResultViaCadsRadsConclusion(value));
    };

    return (
        <>
            {/* Avoid error when conclusion is not in the list by displaying only if isLoaded */}
            {isLoaded && (
                <SDivConclusion>
                    <Select
                        initialValue={conclusionCadRads}
                        label={defaultText}
                        fullWidth
                        onChange={handleChange}
                        overrideValuesRendering
                        width="100%"
                        values={coronaryCadsRadsConclusion}
                        valuesRender={renderCadsRadsConclusion}
                    />
                </SDivConclusion>
            )}
        </>
    );
};

export default ConclusionRadsMedicalReport;

const SDivConclusion = styled.div`
    padding-bottom: ${(props) => props.theme.getSpacing(2)};
    padding-top: ${(props) => props.theme.getSpacing(2)};
`;

const SSelectWithIcon = styled.div`
    display: flex;
    align-items: center;
`;

const STypography = styled(Typography)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

