import { createTheme } from '@mui/material/styles';
import colors from '@/utils/colors';
import '@fontsource/inter';

export const MuiOverrideTheme = createTheme({
    typography: {
        h1: {
            fontSize: '2.85rem' /* 32px - 1 pigment rem = 13px */,
            fontStyle: 'normal',
            fontWeight: 500
        },
        h2: {
            fontSize: '1.25rem' /* 18px - 1 pigment rem = 13px */,
            fontStyle: 'normal',
            fontWeight: 500
        },
        h3: {
            fontSize: '1.10rem' /* 15px - 1 pigment rem = 13px */,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '1.7'
        },
        h4: {
            fontSize: '0.8rem' /* 15px - 1 pigment rem = 13px */,
            fontStyle: 'normal',
            fontWeight: 100,
            lineHeight: '1.7'
        },
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        htmlFontSize: 13,
        fontSize: 13, // By default Material UI uses this size for Button, Tabs...
        button: {
            textTransform: 'none',
            fontSize: '1.10rem'
        }
    },
    palette: {
        primary: {
            main: colors.blue
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: { textTransform: 'none' },
                contained: { boxShadow: 'none' }
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&::after': {
                        borderBottom: `2px solid ${colors.blue}`
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&$shrink': {
                        fontFamily: [
                            'Inter',
                            '-apple-system',
                            'BlinkMacSystemFont',
                            '"Segoe UI"',
                            'Roboto',
                            'Helvetica',
                            'Arial',
                            'sans-serif',
                            '"Apple Color Emoji"',
                            '"Segoe UI Emoji"',
                            '"Segoe UI Symbol"'
                        ].join(','),
                        lineHeight: 1
                    },
                    MuiInputLabel: {
                        root: {
                            fontFamily: [
                                'Inter',
                                '-apple-system',
                                'BlinkMacSystemFont',
                                '"Segoe UI"',
                                'Roboto',
                                'Helvetica',
                                'Arial',
                                'sans-serif',
                                '"Apple Color Emoji"',
                                '"Segoe UI Emoji"',
                                '"Segoe UI Symbol"'
                            ].join(',')
                        },
                        '&$shrink': {
                            fontFamily: [
                                'Inter',
                                '-apple-system',
                                'BlinkMacSystemFont',
                                '"Segoe UI"',
                                'Roboto',
                                'Helvetica',
                                'Arial',
                                'sans-serif',
                                '"Apple Color Emoji"',
                                '"Segoe UI Emoji"',
                                '"Segoe UI Symbol"'
                            ].join(','),
                            lineHeight: 1
                        }
                    },

                    fontFamily: [
                        'Inter',
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        'Helvetica',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"'
                    ].join(','),
                    '&$focused': {
                        fontFamily: [
                            'Inter',
                            '-apple-system',
                            'BlinkMacSystemFont',
                            '"Segoe UI"',
                            'Roboto',
                            'Helvetica',
                            'Arial',
                            'sans-serif',
                            '"Apple Color Emoji"',
                            '"Segoe UI Emoji"',
                            '"Segoe UI Symbol"'
                        ].join(','),
                        color: colors.blue
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&$focused $notchedOutline': {
                        borderColor: colors.blue,
                        fontFamily: [
                            'Inter',
                            '-apple-system',
                            'BlinkMacSystemFont',
                            '"Segoe UI"',
                            'Roboto',
                            'Helvetica',
                            'Arial',
                            'sans-serif',
                            '"Apple Color Emoji"',
                            '"Segoe UI Emoji"',
                            '"Segoe UI Symbol"'
                        ].join(',')
                    }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    padding: 0,
                    marginRight: 10
                },
                switchBase: {
                    color: colors.grey
                },
                colorSecondary: {
                    '&$checked': {
                        color: colors.grey,
                        '&:hover': {
                            backgroundColor: 'none'
                        }
                    }
                },
                track: {
                    // opacity: 0.2,
                    backgroundColor: colors.grey,
                    '$checked$checked + &': {
                        // opacity: 0.7,
                        backgroundColor: colors.grey
                    }
                }
            }
        }
    }
});

export default MuiOverrideTheme;

