import React from 'react';
import { CircularProgress as DefaultCircularProgress } from '@mui/material';
import styled from 'styled-components';

export const CircularProgress = styled(({ size, ...otherProps }) => (
    <DefaultCircularProgress {...otherProps} size={size ? size : 29} />
))`
    && {
        color: ${(props) => props.theme.colors.blue};
    }
`;

export default CircularProgress;

