/* eslint-disable */
import React, { forwardRef, MutableRefObject, useEffect, useRef } from 'react';
import {
    MOUSE,
    Vector2,
    Vector3,
    Vector4,
    Quaternion,
    Matrix4,
    Spherical,
    Box3,
    Sphere,
    Raycaster,
    MathUtils
} from 'three';
import { ReactThreeFiber, extend, useFrame, useThree } from '@react-three/fiber';
import CameraControlsDefault from 'camera-controls';

/*
Main package used to develop the camera controls and reset : https://www.npmjs.com/package/camera-controls
*/

declare global {
    namespace JSX {
        interface IntrinsicElements {
            cameraControlsDefault: ReactThreeFiber.Node<CameraControlsDefault, typeof CameraControlsDefault>;
        }
    }
}

const subsetOfTHREE = {
    MOUSE: MOUSE,
    Vector2: Vector2,
    Vector3: Vector3,
    Vector4: Vector4,
    Quaternion: Quaternion,
    Matrix4: Matrix4,
    Spherical: Spherical,
    Box3: Box3,
    Sphere: Sphere,
    Raycaster: Raycaster,
    MathUtils: {
        DEG2RAD: MathUtils.DEG2RAD,
        clamp: MathUtils.clamp
    }
};

CameraControlsDefault.install({ THREE: subsetOfTHREE });
extend({ CameraControlsDefault });

export const CameraControls = forwardRef<CameraControlsDefault, unknown>((_, ref) => {
    const cameraControls = useRef<CameraControlsDefault | null>(null);
    const camera = useThree((state) => state.camera);
    const renderer = useThree((state) => state.gl);
    useFrame((_, delta) => cameraControls.current?.update(delta));
    useEffect(() => () => cameraControls.current?.dispose(), []);
    return (
        <cameraControlsDefault
            //@ts-ignore
            ref={mergeRefs<CameraControlsDefault>(cameraControls, ref)}
            args={[camera, renderer.domElement]}
            maxDistance={5}
            minDistance={5}
            //@ts-ignore
            truck={[0, 0, false]} // Disable the translation using right click
        />
    );
});

export type CameraControls = CameraControlsDefault;

function mergeRefs<T>(...refs: MutableRefObject<T>[]) {
    return (instance: T): void => {
        for (const ref of refs) {
            if (typeof ref === 'function') {
                //@ts-ignore
                ref(instance);
            } else if (ref) {
                ref.current = instance;
            }
        }
    };
}
