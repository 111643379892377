import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Session } from '@/utils/contexts/SessionContext';

//MaterialUI
import { makeStyles } from '@mui/styles';
import { FormControlLabel, ImageList, ImageListItem } from '@mui/material';

//Icons
import ErrorIcon from '@mui/icons-material/Error';

//Components
import { CoronaryImageDetails } from '@/components/atoms';
import Switch from '@mui/material/Switch';

import { initialResultRads, ResultsRads } from '@/utils/ResultsRads';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { ClassificationResult, IStoredAnalysis, Coronary } from '@api';
import i18n, { t } from '@/i18n.js';
import { stenosisClassification } from '@/components/organisms/AnalysisReportCompImages';
import { File } from '@/components/molecules';

import jwt_decode from 'jwt-decode';

type Display = {
    display: string;
};

type Props = {
    storedAnalysis: IStoredAnalysis;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    ladResultsExplicability: string;
    cxResultsExplicability: string;
    rcaResultsExplicability: string;
    currentCoronary: Coronary;
    setCurrentCoronary: (value: Coronary) => void;
    rightCoronary: Coronary;
    setRightCoronary: (value: Coronary) => void;
    leftCoronary: Coronary;
    setLeftCoronary: (value: Coronary) => void;
    modalOpen: boolean;
    updateModal: (arg: boolean) => void;
    coronaryImagesListOpen: string;
    updateCoronaryImagesList: (arg: string) => void;
    open: boolean;
    setOpen: (arg: boolean) => void;
    ffrAvailable: boolean;
    currentSessionInCookie: Session;
};

type SIconProps = {
    colors: string;
};

export const AnalysisReportImagesList = (props: Props) => {
    const {
        storedAnalysis,
        ladResults,
        cxResults,
        rcaResults,
        ladResultsExplicability,
        cxResultsExplicability,
        rcaResultsExplicability,
        currentCoronary,
        setCurrentCoronary,
        rightCoronary,
        setRightCoronary,
        leftCoronary,
        setLeftCoronary,
        modalOpen,
        updateModal,
        coronaryImagesListOpen,
        updateCoronaryImagesList,
        ffrAvailable,
        currentSessionInCookie
    } = props;
    const classes = useStyles();

    // State declaration
    const [selectedImage, setSelectedImage] = useState<File>({
        base64: '',
        fileName: '',
        data: ''
    });
    const [selectedImagejpeg, setSelectedImageJpeg] = useState<string>('');

    const [selectedCoronaryImageListLAD, setSelectedCoronaryImageListLAD] = useState<string[]>([]); // useState(currentCoronary.Images);

    const [selectedCoronaryImageListCX, setSelectedCoronaryImageListCX] = useState<string[]>([]); // useState(currentCoronary.Images);

    const [selectedCoronaryImageListRCA, setSelectedCoronaryImageListRCA] = useState<string[]>([]); // useState(currentCoronary.Images);
    const explicabilityAccess =
        JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7))))
            .isExplicabilityAvailable == 'True';

    const [color, setColor] = useState<string>(''); // Use state of the color depending on RadsState
    const [currentCoronaryName, setCurrentCoronaryName] = useState<string>('');
    const [leftCoronaryName, setLeftCoronaryName] = useState<string>('');
    const [rightCoronaryName, setRightCoronaryName] = useState<string>('');

    const [selectedImageExplicability, setSelectedImageExplicability] = useState<string>('');

    //Confidence Sténose
    const [stenosis, setStenosis] = useState<string>('');
    const [stenosisLeft, setStenosisLeft] = useState<string>('');
    const [stenosisRight, setStenosisRight] = useState<string>('');

    const [activateHeatmapSwitch, setActivateHeatmapSwitch] = useState<boolean>(true);

    /** Fonction de changement de couleur en fonction du résultat CAD-RADS */
    const colorPicker = (futurCoronary: ResultsRads) => {
        const classificationResult = futurCoronary.RadsAnalysisResponse.classificationResult;
        setColor(getColorByRads(classificationResult, 'black'));
    };

    const dangerousRads = [
        ClassificationResult.Rads345,
        ClassificationResult.Rads34,
        ClassificationResult.Rads3,
        ClassificationResult.Rads4,
        ClassificationResult.Rads5
    ];

    useEffect(() => {
        if (currentCoronary === Coronary.LAD) {
            setSelectedImageExplicability(ladResultsExplicability);
        } else if (currentCoronary === Coronary.Cx) {
            setSelectedImageExplicability(cxResultsExplicability);
        } else if (currentCoronary === Coronary.RCA) {
            setSelectedImageExplicability(rcaResultsExplicability);
        }
    }, [ladResultsExplicability, cxResultsExplicability, rcaResultsExplicability]);

    /** Détermine la couleur de la bordure en fonction de la classification et le nom des coronaires à afficher */
    useEffect(() => {
        if (
            currentCoronary === Coronary.LAD &&
            ladResults.Images.length > 0 &&
            ladResults.RadsAnalysisResponse.imageScores.length > 0
        ) {
            colorPicker(ladResults);
            setCurrentCoronaryName(t('LAD'));
            setStenosis(stenosisClassification(ladResults.RadsAnalysisResponse.classificationResult));
            setSelectedImage(
                ladResults.Images[ladResults.RadsAnalysisResponse.bestRadsScoreImageIndex] ?? ladResults.Images[0]
            );
            var firstImage = ladResults?.Images[0]?.base64?.replace(
                'data:application/octet-stream;base64',
                'data:image/jpeg;base64'
            );
            setSelectedImageJpeg(firstImage);
            if (selectedCoronaryImageListLAD?.length == 0 || selectedCoronaryImageListLAD == undefined) {
                const listGoodEncoding: string[] = [];
                ladResults.Images.forEach((file: File) => {
                    // Replace MIME type if it's "application/octet-stream"
                    const updatedString = file?.base64?.replace(
                        'data:application/octet-stream;base64',
                        'data:image/jpeg;base64'
                    );

                    listGoodEncoding.push(updatedString);
                });
                setSelectedCoronaryImageListLAD(listGoodEncoding);
            }
            setSelectedImageExplicability(ladResultsExplicability);

            setLeftCoronary(Coronary.RCA);
            setLeftCoronaryName(t('RCA'));
            setStenosisLeft(stenosisClassification(rcaResults.RadsAnalysisResponse.classificationResult));

            setRightCoronary(Coronary.Cx);
            setRightCoronaryName(t('CX'));
            setStenosisRight(stenosisClassification(cxResults.RadsAnalysisResponse.classificationResult));
        } else if (
            currentCoronary === Coronary.RCA &&
            rcaResults.Images.length > 0 &&
            rcaResults.RadsAnalysisResponse.imageScores.length > 0
        ) {
            colorPicker(rcaResults);
            setCurrentCoronaryName(t('RCA'));
            setStenosis(stenosisClassification(rcaResults.RadsAnalysisResponse.classificationResult));
            setSelectedImage(
                rcaResults.Images[rcaResults.RadsAnalysisResponse.bestRadsScoreImageIndex] ?? rcaResults.Images[0]
            );
            var firstImage = rcaResults?.Images[0]?.base64?.replace(
                'data:application/octet-stream;base64',
                'data:image/jpeg;base64'
            );
            setSelectedImageJpeg(firstImage);
            if (selectedCoronaryImageListRCA?.length == 0 || selectedCoronaryImageListRCA == undefined) {
                const listGoodEncoding: string[] = [];
                rcaResults.Images.forEach((file: File) => {
                    const updatedString = file?.base64?.replace(
                        'data:application/octet-stream;base64',
                        'data:image/jpeg;base64'
                    );

                    listGoodEncoding.push(updatedString);
                });
                setSelectedCoronaryImageListRCA(listGoodEncoding);
            }
            setSelectedImageExplicability(rcaResultsExplicability);

            setLeftCoronary(Coronary.Cx);
            setLeftCoronaryName(t('CX'));
            setStenosisLeft(stenosisClassification(cxResults.RadsAnalysisResponse.classificationResult));

            setRightCoronary(Coronary.LAD);
            setRightCoronaryName(t('LAD'));
            setStenosisRight(stenosisClassification(ladResults.RadsAnalysisResponse.classificationResult));
        } else if (
            currentCoronary === Coronary.Cx &&
            cxResults.Images.length > 0 &&
            cxResults.RadsAnalysisResponse.imageScores.length > 0
        ) {
            colorPicker(cxResults);
            setCurrentCoronaryName(t('CX'));
            setStenosis(stenosisClassification(cxResults.RadsAnalysisResponse.classificationResult));
            setSelectedImage(
                cxResults.Images[cxResults.RadsAnalysisResponse.bestRadsScoreImageIndex] ?? cxResults.Images[0]
            );
            setSelectedImageExplicability(cxResultsExplicability);

            var firstImage = cxResults.Images[0]?.base64?.replace(
                'data:application/octet-stream;base64',
                'data:image/jpeg;base64'
            );
            setSelectedImageJpeg(firstImage);
            if (selectedCoronaryImageListCX?.length == 0 || selectedCoronaryImageListCX == undefined) {
                const listGoodEncoding: string[] = [];
                cxResults.Images.forEach((file: File) => {
                    const updatedString = file?.base64?.replace(
                        'data:application/octet-stream;base64',
                        'data:image/jpeg;base64'
                    );

                    listGoodEncoding.push(updatedString);
                });
                setSelectedCoronaryImageListCX(listGoodEncoding);
            }
            setLeftCoronary(Coronary.LAD);
            setLeftCoronaryName(t('LAD'));
            setStenosisLeft(stenosisClassification(ladResults.RadsAnalysisResponse.classificationResult));

            setRightCoronary(Coronary.RCA);
            setRightCoronaryName(t('RCA'));
            setStenosisRight(stenosisClassification(rcaResults.RadsAnalysisResponse.classificationResult));
        } else {
        }
    }, [i18n.language, currentCoronary, leftCoronary, rightCoronary, coronaryImagesListOpen]);

    /** Hide 3D heart when the modal is open */
    const [statusSwitch, setStatusSwitch] = useState<boolean>(false);

    /** Initialization to Parent Component value */
    useEffect(() => {
        setStatusSwitch(false);
    }, [modalOpen]);

    /** Navigation between the three coronary / futurCoronary == la coronaire qui a été cliquée */
    const coronaryHandler = (futurCoronary: ResultsRads) => {
        if (futurCoronary.RadsAnalysisResponse.coronary === Coronary.LAD) {
            setCurrentCoronary(Coronary.LAD);
            setLeftCoronary(Coronary.RCA);
            setRightCoronary(Coronary.Cx);
        } else if (futurCoronary.RadsAnalysisResponse.coronary === Coronary.RCA) {
            setCurrentCoronary(Coronary.RCA);
            setLeftCoronary(Coronary.Cx);
            setRightCoronary(Coronary.LAD);
        } else if (futurCoronary.RadsAnalysisResponse.coronary === Coronary.Cx) {
            setCurrentCoronary(Coronary.Cx);
            setLeftCoronary(Coronary.LAD);
            setRightCoronary(Coronary.RCA);
        } else {
            alert(t('analysis.analysisReportComp.utils.noCoronaryCalled'));
        }
    };

    const handleDisplayCoronaryImageList = (global: string, coronary: ResultsRads) => {
        updateCoronaryImagesList(global);
    };

    const getCoronary = (coronary: Coronary) => {
        switch (coronary) {
            case Coronary.LAD:
                return ladResults;
            case Coronary.Cx:
                return cxResults;
            case Coronary.RCA:
                return rcaResults;
            default:
                return initialResultRads;
        }
    };

    return (
        <>
            <SFullContainer>
                <SContainerAnalyse>
                    <SContainerRowDetails display="flex">
                        <CoronaryImageDetails
                            storedAnalysis={storedAnalysis}
                            ladResults={ladResults}
                            cxResults={cxResults}
                            rcaResults={rcaResults}
                            currentCoronary={currentCoronary}
                            ffrAvailable={ffrAvailable}
                            selectedImage={selectedImage}
                        ></CoronaryImageDetails>
                    </SContainerRowDetails>
                    <SContainerRow display="flex">
                        <SRowImagePrincipal>
                            <SImagePrincipal
                                onClick={() =>
                                    currentCoronary == coronaryImagesListOpen
                                        ? handleDisplayCoronaryImageList('null', getCoronary(currentCoronary))
                                        : handleDisplayCoronaryImageList(
                                              getCoronary(currentCoronary).RadsAnalysisResponse.coronary,
                                              getCoronary(currentCoronary)
                                          )
                                }
                            >
                                {[
                                    rcaResults.Images[0]?.base64?.replace(
                                        'data:application/octet-stream;base64',
                                        'data:image/jpeg;base64'
                                    ),
                                    ladResults.Images[0]?.base64?.replace(
                                        'data:application/octet-stream;base64',
                                        'data:image/jpeg;base64'
                                    ),
                                    cxResults.Images[0]?.base64?.replace(
                                        'data:application/octet-stream;base64',
                                        'data:image/jpeg;base64'
                                    )
                                ].includes(selectedImagejpeg) &&
                                activateHeatmapSwitch &&
                                selectedImageExplicability !== 'null' &&
                                explicabilityAccess ? (
                                    <img src={selectedImageExplicability} alt="Selected Explained Coronary" />
                                ) : (
                                    <img src={selectedImagejpeg} alt="Selected Coronary" />
                                )}
                                {(ladResultsExplicability !== 'null' ||
                                    cxResultsExplicability !== 'null' ||
                                    rcaResultsExplicability !== 'null') &&
                                explicabilityAccess &&
                                [
                                    rcaResults.Images[0]?.base64?.replace(
                                        'data:application/octet-stream;base64',
                                        'data:image/jpeg;base64'
                                    ),
                                    ladResults.Images[0]?.base64?.replace(
                                        'data:application/octet-stream;base64',
                                        'data:image/jpeg;base64'
                                    ),
                                    cxResults.Images[0]?.base64?.replace(
                                        'data:application/octet-stream;base64',
                                        'data:image/jpeg;base64'
                                    )
                                ].includes(selectedImagejpeg) ? (
                                    <SSwitch>
                                        {t('analysis.explicability.title')}
                                        <FormControlLabel
                                            sx={{ marginLeft: '-8px', marginRight: '-6px' }}
                                            control={<HeatmapSwitch sx={{ m: 1 }} />}
                                            checked={activateHeatmapSwitch}
                                            onChange={() => {
                                                setActivateHeatmapSwitch(!activateHeatmapSwitch);
                                            }}
                                            label=""
                                        />
                                    </SSwitch>
                                ) : (
                                    <></>
                                )}
                                <SImageText>
                                    {currentCoronaryName} {stenosis}
                                </SImageText>
                                {dangerousRads.includes(
                                    getCoronary(currentCoronary).RadsAnalysisResponse.classificationResult
                                ) ? (
                                    <SImageIcon>
                                        <SErrorIcon
                                            colors={getColorByRads(
                                                getCoronary(currentCoronary).RadsAnalysisResponse.classificationResult
                                            )}
                                            sx={{ fontSize: '1.2rem' }}
                                        />
                                    </SImageIcon>
                                ) : (
                                    <></>
                                )}
                            </SImagePrincipal>
                        </SRowImagePrincipal>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                width: '100%',
                                height: 'calc(54vh + 14vw)'
                            }}
                        >
                            <div>
                                {/* <STitleImageList>{currentCoronaryName} images</STitleImageList> */}
                                <SRowImageList>
                                    <SImageList cols={5} classes={{ root: classes.imageList }}>
                                        {currentCoronary == Coronary.LAD &&
                                        selectedCoronaryImageListLAD != undefined ? (
                                            selectedCoronaryImageListLAD.map((img, index) => (
                                                <SImageListItem key={index}>
                                                    <img
                                                        key={index}
                                                        src={img}
                                                        loading="lazy"
                                                        alt={getCoronary(currentCoronary).RadsAnalysisResponse.coronary}
                                                        style={{
                                                            border: selectedImagejpeg == img ? '3px solid' + color : '',
                                                            width: selectedImagejpeg == img ? '94%' : ''
                                                        }}
                                                        onClick={() => setSelectedImageJpeg(img)}
                                                    />
                                                </SImageListItem>
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                        {currentCoronary == Coronary.Cx && selectedCoronaryImageListCX != undefined ? (
                                            selectedCoronaryImageListCX.map((img, index) => (
                                                <SImageListItem key={index}>
                                                    <img
                                                        key={index}
                                                        src={img}
                                                        loading="lazy"
                                                        alt={getCoronary(currentCoronary).RadsAnalysisResponse.coronary}
                                                        style={{
                                                            border: selectedImagejpeg == img ? '3px solid' + color : '',
                                                            width: selectedImagejpeg == img ? '94%' : ''
                                                        }}
                                                        onClick={() => setSelectedImageJpeg(img)}
                                                    />
                                                </SImageListItem>
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                        {currentCoronary == Coronary.RCA &&
                                        selectedCoronaryImageListRCA != undefined ? (
                                            selectedCoronaryImageListRCA.map((img, index) => (
                                                <SImageListItem key={index}>
                                                    <img
                                                        key={index}
                                                        src={img}
                                                        loading="lazy"
                                                        alt={getCoronary(currentCoronary).RadsAnalysisResponse.coronary}
                                                        style={{
                                                            border: selectedImagejpeg == img ? '3px solid' + color : '',
                                                            width: selectedImagejpeg == img ? '94%' : ''
                                                        }}
                                                        onClick={() => setSelectedImageJpeg(img)}
                                                    />
                                                </SImageListItem>
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                    </SImageList>
                                </SRowImageList>
                            </div>

                            <SRow>
                                {getCoronary(rightCoronary).Images.length !== 0 &&
                                getCoronary(rightCoronary).RadsAnalysisResponse.imageScores.length > 0 ? (
                                    <>
                                        <SSubImage>
                                            <SSubImgSrc
                                                onClick={() => coronaryHandler(getCoronary(rightCoronary))}
                                                src={
                                                    getCoronary(rightCoronary) !== initialResultRads
                                                        ? getCoronary(rightCoronary).Images[
                                                              getCoronary(rightCoronary).RadsAnalysisResponse
                                                                  .bestRadsScoreImageIndex
                                                          ]?.base64
                                                        : ''
                                                }
                                            />
                                            <SImageText>
                                                {rightCoronaryName} {stenosisRight}
                                            </SImageText>
                                            {dangerousRads.includes(
                                                getCoronary(rightCoronary).RadsAnalysisResponse.classificationResult
                                            ) ? (
                                                <SLittleImageIcon>
                                                    <SErrorIcon
                                                        colors={getColorByRads(
                                                            getCoronary(rightCoronary).RadsAnalysisResponse
                                                                .classificationResult
                                                        )}
                                                        sx={{ fontSize: '1rem' }}
                                                    />
                                                </SLittleImageIcon>
                                            ) : (
                                                <></>
                                            )}
                                        </SSubImage>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {getCoronary(leftCoronary).Images.length !== 0 &&
                                getCoronary(leftCoronary).RadsAnalysisResponse.imageScores.length > 0 ? (
                                    <>
                                        <SSubImage>
                                            <SSubImgSrc
                                                onClick={() => coronaryHandler(getCoronary(leftCoronary))}
                                                src={
                                                    getCoronary(leftCoronary) !== initialResultRads
                                                        ? getCoronary(leftCoronary).Images[
                                                              getCoronary(leftCoronary).RadsAnalysisResponse
                                                                  .bestRadsScoreImageIndex
                                                          ]?.base64
                                                        : ''
                                                }
                                                style={{ marginTop: '0.2vw' }}
                                            />
                                            <SImageText>
                                                {leftCoronaryName} {stenosisLeft}
                                            </SImageText>
                                            {dangerousRads.includes(
                                                getCoronary(leftCoronary).RadsAnalysisResponse.classificationResult
                                            ) ? (
                                                <SLittleImageIcon>
                                                    <SErrorIcon
                                                        colors={getColorByRads(
                                                            getCoronary(leftCoronary).RadsAnalysisResponse
                                                                .classificationResult
                                                        )}
                                                        sx={{ fontSize: '1rem' }}
                                                    />
                                                </SLittleImageIcon>
                                            ) : (
                                                <></>
                                            )}
                                        </SSubImage>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </SRow>
                        </div>
                    </SContainerRow>
                </SContainerAnalyse>
            </SFullContainer>
        </>
    );
};

export default AnalysisReportImagesList;

const SFullContainer = styled.div`
    height: 100vh;
    background-color: ${(props) => props.theme.app.background};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 15px;
`;

const SContainerAnalyse = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100vw;
    justify-content: center;
`;

const SContainerRow = styled.div<Display>`
    display: ${(props: Display) => props.display};
    text-align: center;
    flex-direction: row;
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    user-select: none;
    // width: 95vw;
`;

const SContainerRowDetails = styled.div<Display>`
    display: ${(props: Display) => props.display};
    text-align: center;
    flex-direction: column;
    border-radius: ${(props) => props.theme.borderRadiusContainer};
    user-select: none;
    width: 15vw;
    margin-right: 15px;
`;

const SRow = styled.div`
    display: flex;
    flex-direction: column;
`;

const SRowImagePrincipal = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 0.2vw 0 0;
`;

const SImagePrincipal = styled.div`
    display: flex;
    position: relative;
    height: calc(54vh + 14vw);
    width: calc(54vh + 14vw);
    min-width: 200px;
    min-height: 200px;
    &:hover {
        cursor: pointer;
    }
`;

const SImageText = styled.div`
    display: flex;
    color: black;
    background-color: #ffffffa9;
    border-radius: 5px;
    padding: 0.1em 0.2em;
    height: fit-content;
    width: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    font-size: calc(0.5vh + 10px);
    margin: ${(props) => props.theme.getSpacing(1, 0, 0, 1)};
`;

const SImageIcon = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 90%;
    right: 5px;
    height: fit-content;
    width: fit-content;
    margin: ${(props) => props.theme.getSpacing(1, 0, 0, 0)};
    background-color: white;
    border-radius: 50%;
`;

const SLittleImageIcon = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 90%;
    left: calc(23vh - 20px);
    height: fit-content;
    width: fit-content;
    margin: ${(props) => props.theme.getSpacing(1, 0, 0, 0)};
    background-color: white;
    border-radius: 50%;
`;

const SSubImage = styled.div`
    display: flex;
    position: relative;
`;

const SErrorIcon = styled(ErrorIcon)<SIconProps>`
    color: ${(props: SIconProps) => props.colors};
`;

const SRowImageList = styled.div`
    display: flex;
    flex-direction: row;
    height: fit-content;
`;

const SImageList = styled(ImageList)`
    width: 34vw;
    height: auto;
    margin-top: 0 !important;
`;

const SImageListItem = styled(ImageListItem)`
    &:hover {
        cursor: pointer;
    }
`;

const SSubImgSrc = styled.img`
    width: 23vh;
    height: 23vh;
    &:hover {
        cursor: pointer;
    }
`;

const STitleImageList = styled.div`
    display: flex;
    align-items: flex-start;
    color: ${(props) => props.theme.analysisDetails.imageDetails.currentImageName.color};
`;

const SSwitch = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #0000008c;
    color: white;
    border-top: 1px solid white;
    padding: 6px 10px;
    height: 40px;
    width: fit-content;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: calc(0.5vh + 10px);
    margin: ${(props) => props.theme.getSpacing(1, 0, 2, 1)};
`;

const HeatmapSwitch = styled(Switch)(({ theme }) => ({
    width: '61px !important',
    height: '26px !important',
    padding: '0 !important',
    margin: '5px !important',
    overflow: 'initial !important',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        paddingTop: '2px',
        margin: 2,
        transitionDuration: '170ms',
        '&.Mui-checked': {
            transform: 'translateX(31px)',
            color: '#fff',
            '& .MuiSwitch-thumb:before': {
                content: '"ON"',
                fontSize: '0.75em'
            },
            '& .MuiSwitch-thumb': {
                border: '1px solid white',
                backgroundColor: theme.colors.blue
            }
        }
    },
    '& .MuiSwitch-thumb': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#676767',
        transform: 'translateX(2px)',
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        '&:before': {
            fontSize: '0.7em',
            content: '"OFF"'
        }
    },
    '& .MuiSwitch-track': {
        border: '2px solid #fefefeeb',
        borderRadius: 90,
        backgroundColor: '#00000068 !important',
        opacity: 1
    }
}));

const useStyles = makeStyles({
    root: {
        '&.MuiDialog-root': {},
        '&.MuiImageListItem-standard': {
            width: '9vh',
            height: '9vh',
            color: 'red'
        }
    },
    imageList: {
        '&.MuiImageList-root': {
            overflowY: 'clip',
            marginTop: '8px'
        },
        '&.MuiImageListItem-root': {
            width: '9vh',
            height: '9vh',
            color: 'pink'
        }
    },
    imageListItem: {
        '&.MuiImageListItem-root': {
            width: '9vh',
            height: '9vh',
            color: 'green'
        }
    },
    standard: {
        '&.MuiImageListItem-standard': {
            width: '9vh',
            height: '9vh',
            color: 'violet'
        }
    },
    dialog: {
        height: '90vh'
    }
});

