import React, { useState, useEffect } from 'react';
import i18n from '@/i18n.js';

import { Dialog } from '@/components/molecules';
import TermsText from '@/utils/TermsText';
import { Language } from '@/utils/Language';
import styled from 'styled-components';
import { Typography } from '@/components/atoms';
import { Select, FormControl, MenuItem } from '@mui/material';

type Props = {
    /**Will open the CGU Dialog if true */
    isOpen: boolean;
    /**Function executed on close */
    onClose: () => void;
};

export const TermsDialog = (props: Props) => {
    const { isOpen, onClose } = props;

    if (!isOpen) {
        return null;
    }
    const frTitle = "Conditions Générales d'Utilisation et Mentions Légales";
    const enTitle = 'Legal Information and Terms and Conditions of Use';
    const frButtonText = "J'ai lu et j'accepte les présentes Conditions Générales d'Utilisation et Mentions Légales.";
    const enButtonText = 'I have read and I accept the present Legal Information and Terms and Conditions of Use.';

    const [title, setTitle] = useState<string>(frTitle);
    const [buttonText, setButtonText] = useState<string>(frButtonText);
    const [isFrench, setIsFrench] = useState<boolean>(true);

    const handleChange = (event: { target: { value: string } }) => {
        i18n.changeLanguage(event.target.value);
    };

    useEffect(() => {
        setTitle(i18n.language == Language.French ? frTitle : enTitle);
        setButtonText(i18n.language == Language.French ? frButtonText : enButtonText);
        setIsFrench(i18n.language == Language.French ? true : false);
    }, [i18n.language]);

    return (
        <Dialog
            open
            customTitle={title}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
            scroll={'body'}
            width={'75%'}
            childrenButton={buttonText}
        >
            <SFormControl variant="standard">
                <Select labelId="language" id="language" value={i18n.language} onChange={handleChange}>
                    <MenuItem key={Language.French} value={Language.French}>
                        <Typography>Français</Typography>
                    </MenuItem>
                    <MenuItem key={Language.English} value={Language.English}>
                        <Typography>English</Typography>
                    </MenuItem>
                </Select>
            </SFormControl>

            {TermsText({ isFrench })}
        </Dialog>
    );
};

export default TermsDialog;

const SFormControl = styled(FormControl)`
    & .MuiFormLabel-root {
        background-color: ${(props) => props.theme.analysisDetails.cardBackground};
        color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.label};
        font-family: 'Inter', sans-serif;
        padding-right: 5px;
    }
    & .MuiInputBase-root {
        color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.inputText};
        & label {
            color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &:hover fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.MuiInput-input {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & .MuiSelect-icon {
            color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
    }
`;

