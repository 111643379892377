import * as React from 'react';
import styled from 'styled-components';

import { RouterLink } from '@/components/atoms';
import { Session, useSessionContext } from '@/utils/contexts/SessionContext';
import { useSnackbarContext } from '@/utils/contexts/SnackbarContext';
import { useEffect, useState } from 'react';
import { DarkModeSwitcher, LanguageSwitcher } from '@/components/molecules';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Snackbar, Alert } from '@mui/material';
import { Drawer, IconButton, List } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { MenuItem } from '@mui/material';
import { Typography, CircularProgress } from '@/components/atoms';
import { t } from '@/i18n.js';
import jwt_decode from 'jwt-decode';
import { useThemeContext } from '@/utils/contexts/ThemeContext';
import lightTheme from '@/themes/lightTheme';
import darkTheme from '@/themes/darkTheme';

import SpimedLogo from '@/img/spimed_logo.png';

//Screenshot
import html2canvas from 'html2canvas';
import { useScreenshotContext } from '@/utils/contexts/ScreenshotContext';
import { StoredAnalysisListContext } from '@/utils/contexts/StoredAnalysisListContext';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

type SLinkProps = {
    color: string;
};

type Props = {
    currentSessionInCookie: Session;
};

let titleByUrl = [
    { url: 'home', title: 'navbar.dashboard' },
    { url: '', title: 'navbar.dashboard' },
    { url: 'upload', title: 'navbar.upload' },
    { url: 'statistics', title: 'navbar.statistics' },
    { url: 'users', title: 'navbar.usersList' },
    { url: 'account', title: 'navbar.account' },
    { url: 'how-to', title: 'navbar.howto' },
    { url: 'cookie-policy', title: 'navbar.cookie' },
    { url: 'terms', title: 'navbar.Terms' },
    { url: 'send-email', title: 'navbar.reportBug' },
    { url: 'new-user', title: 'navbar.newUser' }
];

export const NavBarLeft = (props: Props) => {
    const { currentSessionInCookie } = props;
    const { eraseStoredAnalysisListContext } = React.useContext(StoredAnalysisListContext);
    const [themeContext, updateTheme] = useThemeContext();
    const history = useHistory();
    const isCloud = process.env.DeploymentMode == ModeConstant.CLOUD;

    const [snackbarContext, updateSnackbar] = useSnackbarContext();
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const [open, setOpen] = useState(false);

    const [, , logout] = useSessionContext();
    const [role, setRole] = useState<string>('');

    const url = useParams();
    const [isScreenshotLoading, setIsScreenshotLoading] = useState<boolean>(false);

    const currentUrl = window.location.href.split('/')[window.location.href.split('/').length - 1]; // exemple : /#/home -> home ou /#/upload -> upload
    const analysisExplorerUrl = window.location.href.split('/')[window.location.href.split('/').length - 2]; // exemple : /#/analysis-explorer/283 -> analysis-explorer (enlève l'id de l'analyse)

    //URL de la page actuelle (si id ou autre après nom de page)
    const [currentUrlWithId, setCurrentUrlWithId] = useState<string>(
        window.location.href.split('/')[window.location.href.split('/').length - 3]
    );

    const [screenshotContext, updateScreenshot] = useScreenshotContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    // Liste des pages sur lesquelles ne pas afficher le language switcher
    const pagesToHideLanguageSwitcher = ['forgot-password', 'forgot-password-validation'];
    const pagesToHideLogo = ['login'];

    // Liste des pages sur lesquelles ne pas afficher le composant même si utilisateur connecté
    const pagesToHideAll = ['doctor-selection', 'download-analysis'];
    const nonConnectedPaths = [
        'login',
        'reset-password/:id',
        'reset-password-validation',
        'information',
        'download-analysis'
    ];

    useEffect(() => {
        // updateColorsButton(currentUrl === 'home', currentUrl === 'upload', analysisExplorerUrl === 'analysis-explorer');
        setCurrentUrlWithId(window.location.href.split('/')[window.location.href.split('/').length - 2]);
    }, [currentUrl, currentUrlWithId, url]);

    useEffect(() => {
        if (snackbarContext.snackString != '') {
            setSnackbarOpen(true);
        }
    }, [snackbarContext.snackString]);

    useEffect(() => {
        if (snackbarOpen == false) {
            updateSnackbar({ snackString: '' });
        }
    }, [snackbarOpen]);

    const onClickLogout = () => {
        logout();
        eraseStoredAnalysisListContext();
        toggleDrawer(false);
    };

    const toggleDrawer = (open: boolean) => {
        setOpen(open);
    };

    const takeScreen = () => {
        if (isCloud) {
            setIsScreenshotLoading(true);
            toggleDrawer(false);
            setTimeout(function () {
                html2canvas(document.body, {
                    allowTaint: true,
                    useCORS: true
                }).then((canvas) => {
                    const image = canvas.toDataURL('image/png', 0.5);

                    updateScreenshot({
                        screenString: Object.values({ image })[0]
                    });
                    setIsScreenshotLoading(false);
                    history.push('/bugs/send-email');
                });
            }, 500);
        }
    };

    useEffect(() => {
        if (
            currentSessionInCookie?.authResponse?.token?.slice(7) != undefined &&
            currentSessionInCookie?.authResponse?.token?.slice(7) != ''
        ) {
            setRole(
                JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie?.authResponse?.token?.slice(7))))
                    ?.user_role || ''
            );
        } else {
            setRole('');
        }
    }, [currentSessionInCookie]);

    const drawerRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleWindowClick = (event: MouseEvent) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
                // Click occurred outside the drawer, trigger your onClick function here
                toggleDrawer(false);
            }
        };

        if (open) {
            // Add event listener when the drawer is open
            window.addEventListener('click', handleWindowClick);
        } else {
            // Remove event listener when the drawer is closed
            window.removeEventListener('click', handleWindowClick);
        }

        return () => {
            // Cleanup: Remove event listener when the component unmounts
            window.removeEventListener('click', handleWindowClick);
        };
    }, [open]);

    const getCurrentUrlColor = (url: string) => {
        if (url == currentUrl) {
            return '#55B9F3';
        }
        if (themeContext.isLightMode) {
            return lightTheme.navbar.textColor;
        }
        return darkTheme.navbar.textColor;
    };

    const getTitle = (url: string) => {
        let title = '';
        for (var i in titleByUrl) {
            if (titleByUrl[i].url == url) {
                title = t('' + titleByUrl[i].title);
                break;
            }
        }
        if (title == '') {
            let middleUrl = window.location.href.split('/')[window.location.href.split('/').length - 2];
            if (middleUrl == 'analysis-explorer') {
                return t('navbar.analysisResults');
            }
            if (middleUrl == 'user') {
                return t('navbar.userDetails'); // admin/users/x
            }
        }
        return title;
    };

    return (
        <>
            {pagesToHideAll.includes(currentUrl) || pagesToHideAll.includes(currentUrlWithId) ? (
                <></>
            ) : (
                <>
                    {currentSessionInCookie.isAuthenticated && !nonConnectedPaths.includes(currentUrl) ? (
                        <>
                            <SHamburgerMenu>
                                <div style={{ display: 'inline-flex', marginLeft: '15px' }}>
                                    <SHomeShortcut>
                                        <RouterLink to="/home">
                                            <SpimedImgMenu src={SpimedLogo} />
                                        </RouterLink>
                                    </SHomeShortcut>
                                    <SIconButton onClick={() => toggleDrawer(true)}>
                                        <SMenuIcon />
                                    </SIconButton>

                                    <SCurrentTitle>{getTitle(currentUrl)}</SCurrentTitle>
                                </div>
                                <Drawer
                                    anchor="left"
                                    open={open}
                                    onClick={() => toggleDrawer(false)}
                                    PaperProps={{
                                        sx: {
                                            backgroundColor: themeContext.isLightMode
                                                ? lightTheme.navbar.drawerBackground
                                                : darkTheme.navbar.drawerBackground
                                        }
                                    }}
                                >
                                    <SIconButtonClose onClick={() => toggleDrawer(false)}>
                                        <SCloseIcon />
                                    </SIconButtonClose>

                                    <div style={{ textAlign: 'center' }} onClick={() => toggleDrawer(false)}>
                                        <RouterLink to="/home">
                                            <SpimedImg src={SpimedLogo} />
                                        </RouterLink>
                                    </div>

                                    <div role="presentation" style={{ height: '80%' }}>
                                        <SList>
                                            <div onClick={() => toggleDrawer(false)}>
                                                <SLink to="/home" color={getCurrentUrlColor('home')}>
                                                    <SMenuItem onClick={() => toggleDrawer(false)}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.dashboard')}
                                                        </STypography>
                                                    </SMenuItem>
                                                </SLink>
                                                <SLink to="/upload" color={getCurrentUrlColor('upload')}>
                                                    <SMenuItem onClick={() => toggleDrawer(false)}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.upload')}
                                                        </STypography>
                                                    </SMenuItem>
                                                </SLink>
                                                <SLink to="/statistics" color={getCurrentUrlColor('statistics')}>
                                                    <SMenuItem onClick={() => toggleDrawer(false)}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.statistics')}
                                                        </STypography>
                                                    </SMenuItem>
                                                </SLink>
                                                {role == 'admin' && (
                                                    <div key="admin">
                                                        <SDivider />
                                                        <STypographySubTitle display="block" variant="caption">
                                                            {t('navbar.admin')}
                                                        </STypographySubTitle>
                                                        <SLink to="/admin/users" color={getCurrentUrlColor('users')}>
                                                            <SMenuItem onClick={() => toggleDrawer(false)}>
                                                                <STypography variant="subtitle2">
                                                                    {t('navbar.usersList')}
                                                                </STypography>
                                                            </SMenuItem>
                                                        </SLink>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <SLink to="/account" color={getCurrentUrlColor('account')}>
                                                    <SMenuItem onClick={() => toggleDrawer(false)}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.account')}
                                                        </STypography>
                                                    </SMenuItem>
                                                </SLink>
                                                <SLink to="/how-to" color={getCurrentUrlColor('how-to')}>
                                                    <SMenuItem onClick={() => toggleDrawer(false)}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.howto')}
                                                        </STypography>
                                                    </SMenuItem>
                                                </SLink>
                                                <SLink to="/cookie-policy" color={getCurrentUrlColor('cookie-policy')}>
                                                    <SMenuItem onClick={() => toggleDrawer(false)}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.cookie')}
                                                        </STypography>
                                                    </SMenuItem>
                                                </SLink>
                                                <SLink to="/terms" color={getCurrentUrlColor('terms')}>
                                                    <SMenuItem onClick={() => toggleDrawer(false)}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.Terms')}
                                                        </STypography>
                                                    </SMenuItem>
                                                </SLink>
                                                <SA href="mailto:support@spimed-ai.com">
                                                    <SMenuItem onClick={() => toggleDrawer(false)}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.contact')}
                                                        </STypography>
                                                    </SMenuItem>
                                                </SA>
                                                {isCloud && (
                                                    <SMenuItem onClick={takeScreen}>
                                                        {!isScreenshotLoading ? (
                                                            <STypographyBug variant="subtitle2">
                                                                {t('navbar.reportBug')}
                                                            </STypographyBug>
                                                        ) : (
                                                            <CircularProgress />
                                                        )}
                                                    </SMenuItem>
                                                )}

                                                <SDivider />
                                                <SRow>
                                                    <SMenuItemAlternative onClick={(event) => event.stopPropagation()}>
                                                        <DarkModeSwitcher display={true} />
                                                        <LanguageSwitcher />
                                                    </SMenuItemAlternative>
                                                </SRow>
                                                <SLink to="/login" color={'white'}>
                                                    <SMenuItemLogout onClick={onClickLogout}>
                                                        <STypography variant="subtitle2">
                                                            {t('navbar.logout')}
                                                        </STypography>
                                                    </SMenuItemLogout>
                                                </SLink>
                                            </div>
                                        </SList>
                                    </div>
                                </Drawer>
                            </SHamburgerMenu>

                            {snackbarOpen ? (
                                <Snackbar
                                    open={snackbarOpen}
                                    autoHideDuration={3000}
                                    onClose={() => setSnackbarOpen(false)}
                                >
                                    <div>
                                        <Alert
                                            onClose={() => setSnackbarOpen(false)}
                                            severity={snackbarContext.severity}
                                            sx={{ width: '100%' }}
                                        >
                                            {snackbarContext.snackString}
                                        </Alert>
                                    </div>
                                </Snackbar>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <>
                            {pagesToHideLanguageSwitcher.includes(currentUrl) || pagesToHideAll.includes(currentUrl) ? (
                                <SEnd></SEnd>
                            ) : (
                                <>
                                    {pagesToHideLogo.includes(currentUrl) ? (
                                        <></>
                                    ) : (
                                        <STop>
                                            <RouterLink to="/login">
                                                <SpimedImg src={SpimedLogo} />
                                            </RouterLink>
                                        </STop>
                                    )}

                                    <SEnd>
                                        <LanguageSwitcher />
                                    </SEnd>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default NavBarLeft;

const SpimedImg = styled.img`
    width: 3.5vw;
`;

const SHomeShortcut = styled.div`
    display: flex;
    align-items: center;
    margin-right: 4px;
`;

const SpimedImgMenu = styled.img`
    width: 30px;
    height: 30px;
`;

const STop = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`;

const SEnd = styled.div`
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    z-index: 4;
`;

const SRow = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
`;

const SHamburgerMenu = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 46px;
    background-color: ${(props) => props.theme.navbar.menuBackground};
    z-index: 1000;
    display: flex;
    justify-content: space-between;
`;

const SLink = styled(Link)<SLinkProps>`
    && {
        text-align: center;
        text-decoration: none;
        color: ${(props: SLinkProps) => props.color};
        &:hover {
            color: ${(props) => props.theme.colors.blue};
        }
    }
`;

const SMenuItem = styled(MenuItem)`
    && {
        display: block;
        text-align: center;
        margin: ${(props) => props.theme.getSpacing(1)};
        &:hover {
            color: ${(props) => props.theme.colors.blue};
        }
    }
`;

const SMenuItemAlternative = styled(MenuItem)`
    && {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin: ${(props) => props.theme.getSpacing(1)};
        &:hover {
            color: ${(props) => props.theme.colors.blue};
        }
    }
`;

const STypography = styled(Typography)`
    padding: 0;
`;

const STypographyBug = styled(Typography)`
    color: ${(props) => props.theme.navbar.textColor};
`;

const SA = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.navbar.textColor};
    &:hover {
        color: ${(props) => props.theme.colors.blue};
    }
`;
const STypographySubTitle = styled(Typography)`
    && {
        color: ${(props) => props.theme.navbar.textColor};
        margin-left: 13px;
        margin-top: 5px;
    }
`;

const SIconButton = styled(IconButton)`
    width: 50px;
`;

const SIconButtonClose = styled(IconButton)`
    && {
        height: 45px;
        width: 45px;
        margin-left: auto;
    }
`;

const SMenuItemLogout = styled(MenuItem)`
    && {
        margin: ${(props) => props.theme.getSpacing(2, 1, 0, 1)};
        color: ${(props) => props.theme.colors.darkred};
        display: block;
        text-align: center;
        &:hover {
            color: ${(props) => props.theme.colors.blue};
        }
    }
`;

const SCloseIcon = styled(CloseIcon)`
    && {
        color: ${(props) => props.theme.navbar.textColor};
    }
`;

const SList = styled(List)`
    && {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`;

const SMenuIcon = styled(MenuIcon)`
    && {
        color: ${(props) => props.theme.navbar.menuText};
    }
`;

const SDivider = styled(Divider)`
    && {
        border-color: ${(props) => props.theme.navbar.dividerColor};
    }
`;

const SCurrentTitle = styled(Typography)`
    color: ${(props) => props.theme.navbar.menuText};
    font-weight: 500 !important;
    font-size: 16px !important;
    border-bottom: 1px solid ${(props) => props.theme.navbar.menuText};
    display: flex;
    align-items: end;
    padding: 0 15px 5px 15px;
    margin-left: 20px !important;
`;

