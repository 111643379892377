import React, { useEffect, useMemo, useState } from 'react';
import i18n, { t } from '@/i18n.js';
import { Document, Image, Page, Text, View, Font } from '@react-pdf/renderer';
import { getPathByRads, getPathByStenosis } from '@/utils/ResultsRadsStenosisColors';

import styles from '@/pdf/rads/Styles';
import { ResultsRads } from '@/utils/ResultsRads';
import colors from '@/utils/colors';
import MedicalReportPage, { Props as medicalProps } from '@/pdf/rads/MedicalReportPage';
import { Language } from '@/utils/Language';
import {
    Calcification,
    Classification,
    ClassificationResult,
    Coronary,
    Ffr,
    IStoredAnalysis,
    Quality,
    RadsAnalysisResponse,
    Score
} from '@api';
import { File } from '@/components/molecules';
import { getEntropyStr } from '@/utils/GetEntropy';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { overrideIntermediateCadRads } from '@/components/atoms/CadRadsDisplay';
import { stenosisClassification } from '@/components/organisms/AnalysisReportCompImages';

// 2D HEART
import TreeGrey from '@/img/2DHeart/tree/grey.png';
import RcaGrey from '@/img/2DHeart/coronaryLeft/grey.png';
import LadGrey from '@/img/2DHeart/coronaryMiddle/grey.png';
import CxGrey from '@/img/2DHeart/coronaryRight/grey.png';
import CoronaryConclusionTableFR from '@/img/conclusion-rads-board-fr.png';
import CoronaryConclusionTableEN from '@/img/conclusion-rads-board-en.png';
import FullLogo from '@/img/full_logo.png';
import { hasEntropyWarning } from '@/components/molecules/CoronariesDetails';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

type Props = {
    storedAnalysis: IStoredAnalysis;
    /**Results for LAD files */
    ladResults: ResultsRads;
    /**Results for Cx files */
    cxResults: ResultsRads;
    /**Results for RCA files */
    rcaResults: ResultsRads;
    coverImages: File[];
    /**The patient name chosen by the user */
    patientName: string;
    /**On language update, reload the React.memo */
    language: string;
    date: Date | undefined;
    // currentSessionInCookie: Session | undefined;
};

export type PdfProps = {
    /**The images chosen by the user */
    coverImages: File[];
    /**The patient name chosen by the user */
    patientName: string;
    /**Comments written by the user */
    comments: string;
    /**Language chosen by the user */
    language: Language;
};

enum TypeOfPathology {
    OBSTRU = 'obstructive',
    NON_OBSTRU = 'non obstructive',
    NORM = 'normal'
}

export const renderDisclaimerPageNumber = () => (
    <>
        <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => `${pageNumber - 1} / ${totalPages - 1}`}
            fixed
        />
    </>
);

export const renderHeader = () => (
    <>
        <Text style={styles.blueBar} fixed>
            {' '}
        </Text>
    </>
);

export const renderFooter = () => (
    <>
        <Text style={styles.redBar} fixed>
            {' '}
        </Text>
    </>
);

const PdfDocumentNoMedicalProps = React.memo((props: Props) => {
    // Memo to prevent rerender when closing medical report dialog
    const {
        storedAnalysis,
        ladResults,
        cxResults,
        rcaResults,
        /**The patient name chosen by the user */
        patientName,
        date
    } = props;

    const [conclusionTablePath, setConclusionTablePath] = useState<string>(CoronaryConclusionTableEN);

    const [hasRcaResults, setHasRcaResults] = useState<boolean>(false);
    const [hasLadResults, setHasLadResults] = useState<boolean>(false);
    const [hasCxResults, setHasCxResults] = useState<boolean>(false);

    const [treePath, setTreePath] = useState<string>(TreeGrey); ///('images/2DHeart/tree/grey.png');
    const [rcaCoronaryPath, setRcaCoronaryPath] = useState<string>(RcaGrey);
    const [ladCoronaryPath, setLadCoronaryPath] = useState<string>(LadGrey);
    const [cxCoronaryPath, setCxCoronaryPath] = useState<string>(CxGrey);

    // Use memo so when changing the medical validation, we only call the new png, we don't call all the pngs to create the 2D heart
    const memoizedTreePath = useMemo(() => treePath, [treePath]);
    const memoizedRcaCoronaryPath = useMemo(() => rcaCoronaryPath, [rcaCoronaryPath]);
    const memoizedLadCoronaryPath = useMemo(() => ladCoronaryPath, [ladCoronaryPath]);
    const memoizedCxCoronaryPath = useMemo(() => cxCoronaryPath, [cxCoronaryPath]);

    useEffect(() => {
        if ((i18n.language as Language) === Language.French) setConclusionTablePath(CoronaryConclusionTableFR);
        else if ((i18n.language as Language) === Language.English) setConclusionTablePath(CoronaryConclusionTableEN);
    }, [i18n.language]);

    useEffect(() => {
        setHasRcaResults(rcaResults.Images.length > 0 && rcaResults?.RadsAnalysisResponse?.imageScores?.length > 0);
        if (rcaResults.Images.length > 0 && rcaResults?.RadsAnalysisResponse?.imageScores?.length > 0) {
            setRcaCoronaryPath(
                getPathByRads(
                    rcaResults.RadsAnalysisResponse.classificationResult,
                    rcaResults.RadsAnalysisResponse.coronary
                )
            );
        }
    }, [rcaResults]);

    useEffect(() => {
        setHasLadResults(ladResults.Images.length > 0 && ladResults?.RadsAnalysisResponse?.imageScores?.length > 0);
        if (ladResults.Images.length > 0 && ladResults?.RadsAnalysisResponse?.imageScores?.length > 0) {
            setLadCoronaryPath(
                getPathByRads(
                    ladResults.RadsAnalysisResponse.classificationResult,
                    ladResults.RadsAnalysisResponse.coronary
                )
            );
        }
    }, [ladResults]);

    useEffect(() => {
        setHasCxResults(cxResults.Images.length > 0 && cxResults?.RadsAnalysisResponse?.imageScores?.length > 0);
        if (cxResults.Images.length > 0 && cxResults?.RadsAnalysisResponse?.imageScores?.length > 0) {
            setCxCoronaryPath(
                getPathByRads(
                    cxResults.RadsAnalysisResponse.classificationResult,
                    cxResults.RadsAnalysisResponse.coronary
                )
            );
        }
    }, [cxResults]);

    // ! Keep for futur GlobalImage Quality
    // const getGlobalImageQuality = () => {
    //     const ladClassif = ladResults?.RadsAnalysisResponse?.qualityResult;
    //     const cxClassif = cxResults?.RadsAnalysisResponse?.qualityResult;
    //     const rcaClassif = rcaResults?.RadsAnalysisResponse?.qualityResult;
    //     let result = t('medicalReportForm.imageQuality.low');
    //     if (ladClassif === Quality.Low || cxClassif === Quality.Low || rcaClassif === Quality.Low) {
    //         result = t('medicalReportForm.imageQuality.low');
    //     } else if (ladClassif === Quality.High || cxClassif === Quality.High || rcaClassif === Quality.High) {
    //         result = t('medicalReportForm.imageQuality.high');
    //     }
    //     return result;
    // };

    const getGlobalConclusion = () => {
        return overrideIntermediateCadRads(storedAnalysis.classificationResult);
    };

    const extractRadsFromResult = (data: Score, classif: Classification) => {
        if (classif == Classification.ThreeClasses || classif == Classification.FourClasses) {
            const maxValue = Math.max(data.rads0, data.rads1, data.rads2, data.rads5);
            switch (maxValue) {
                case data.rads0:
                    return TypeOfPathology.NORM;
                case data.rads1:
                    return TypeOfPathology.NON_OBSTRU;
                case data.rads2:
                    return TypeOfPathology.OBSTRU;
                default:
                    return TypeOfPathology.OBSTRU;
            }
        } else if (classif == Classification.SixClasses) {
            const maxValue = Math.max(data.rads0, data.rads1, data.rads2, data.rads3, data.rads4, data.rads5);
            switch (maxValue) {
                case data.rads0:
                    return TypeOfPathology.NORM;
                case data.rads1:
                case data.rads2:
                    return TypeOfPathology.NON_OBSTRU;
                case data.rads3:
                case data.rads4:
                    return TypeOfPathology.OBSTRU;
                default:
                    return TypeOfPathology.OBSTRU;
            }
        } else {
            // should never be called
            return TypeOfPathology.OBSTRU;
        }
    };

    const getBackgroundColorForConclusionCover = (classRes: ClassificationResult) => {
        let color = getColorByRads(classRes.toString());
        let conclusion = overrideIntermediateCadRads(classRes);
        return (
            <View
                style={{
                    backgroundColor: color,
                    color: 'white',
                    width: 225,
                    height: 30,
                    borderRadius: 10,
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Text style={{ fontSize: 18, fontWeight: 'bold' }}>{conclusion}</Text>
            </View>
        );
    };

    const getBackgroundColorForConclusion = (classRes: ClassificationResult) => {
        let color = getColorByRads(classRes.toString());
        let conclusion = overrideIntermediateCadRads(classRes);
        return (
            <View
                style={{
                    backgroundColor: color,
                    color: 'white',
                    width: 100,
                    height: 25,
                    borderRadius: 4,
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Text>{conclusion}</Text>
            </View>
        );
    };

    const getColorForConclusionFfr = (ffrRes: string) => {
        switch (ffrRes) {
            case Ffr.FfrMinus:
                return (
                    // {/* style={{ color: colors.green }} */}
                    <>
                        <Text>{t('medicalReportForm.ischemie.negative')}</Text>
                        <Text> ({'>'} 0.8)</Text>
                    </>
                );
            case Ffr.FfrPlus:
                return (
                    <>
                        <Text style={{ color: '#a81925' }}>{t('medicalReportForm.ischemie.positive')}</Text>
                        <Text> ({'≤'} 0.8)</Text>
                    </>
                );
            case Ffr.Null:
                return <Text style={{ color: colors.black }}>{t('medicalReportForm.ischemie.notAvailable')}</Text>;
        }
    };

    const getColorForConfidence = (results: ResultsRads) => {
        let entropyStr = getEntropyStr(results);
        let warning = hasEntropyWarning(results.RadsAnalysisResponse);
        if (warning) {
            return <Text style={{ color: '#a81925' }}>{entropyStr}</Text>;
        }
        return <Text>{entropyStr}</Text>;
    };

    const getImageCalcificationIndex = (calcification: Calcification) => {
        let result = t('medicalReportForm.calciumCharge.null');
        if (calcification == Calcification.Minimal) {
            result = t('medicalReportForm.calciumCharge.minimal');
        }
        if (calcification == Calcification.Moderate) {
            result = t('medicalReportForm.calciumCharge.moderate');
        }
        if (calcification == Calcification.Severe) {
            result = t('medicalReportForm.calciumCharge.severe');
        }

        return result;
    };

    const filterImage = (results: ResultsRads) => {
        if (!results.RadsAnalysisResponse) {
            return;
        }
        const mostRepresentativeImageIndex = results.RadsAnalysisResponse.bestRadsScoreImageIndex;
        if (results.Images[mostRepresentativeImageIndex] == null) {
            return <Text style={styles.probaTitle}>{t('medicalReportForm.noData')}</Text>;
        }

        // Keep index in memory
        var storeIndexesFromResults = new Map<number, [TypeOfPathology, number]>();
        results.RadsAnalysisResponse.imageScores.forEach((res, index) =>
            storeIndexesFromResults.set(index, [
                extractRadsFromResult(res, results.RadsAnalysisResponse.classification),
                Math.max(res.rads0, res.rads1, res.rads2, res.rads3, res.rads4, res.rads5)
            ])
        );
        // Create specific order => obstructive, then non obstructive then normal
        const customOrder = {
            [TypeOfPathology.OBSTRU]: 1,
            [TypeOfPathology.NON_OBSTRU]: 2,
            [TypeOfPathology.NORM]: 3
        };
        // Reorder the map using the new order and the result maximum
        var mapOrderedWithTypeOfPathology = new Map(
            [...storeIndexesFromResults.entries()].sort(
                (a, b) =>
                    customOrder[a[1][0] as TypeOfPathology] - customOrder[b[1][0] as TypeOfPathology] ||
                    b[1][1] - a[1][1]
            )
        );

        // * ----------------- Structure for each coronary -----------------
        return (
            <>
                <View style={styles.resultImageContainer}>
                    <View style={styles.tableCoronary}>
                        {/* ----------------- Title ----------------- */}
                        <View style={styles.rowTitleCoronary}>
                            <Text>{t('medicalReportForm.airesults')}</Text>
                            <View style={styles.coronaryName}>
                                <Text>{t(results?.RadsAnalysisResponse.coronary)}</Text>
                            </View>
                        </View>

                        {/* ----------------- Results table ----------------- */}
                        <View style={styles.resultsTable}>
                            {/* ----------------- Stenosis result ----------------- */}
                            <View style={styles.resultsTableRow}>
                                <Text style={styles.resultsTableCoronaryRowElemTitle}>
                                    {t('medicalReportForm.stenosis')}
                                </Text>
                                <Text style={styles.resultsTableRowElem}>
                                    {stenosisClassification(results.RadsAnalysisResponse.classificationResult)}
                                </Text>
                            </View>

                            {/* ----------------- Confidence result ----------------- */}
                            <View style={styles.resultsTableRow}>
                                <Text style={styles.resultsTableCoronaryRowElemTitle}>
                                    {t('medicalReportForm.confidence')}
                                </Text>
                                <Text style={styles.resultsTableRowElem}>{getColorForConfidence(results)}</Text>
                            </View>

                            {/* ----------------- FFR Prediction result ----------------- */}
                            <View style={styles.resultsTableRow}>
                                <Text style={styles.resultsTableCoronaryRowElemTitle}>
                                    {t('medicalReportForm.ffrPrediction')}
                                </Text>
                                {results.RadsAnalysisResponse.ffrHardcodeResult == Ffr.FfrPlus ? (
                                    <Text style={styles.resultsTableRowElemCharLessOrEqual}>
                                        {getColorForConclusionFfr(results.RadsAnalysisResponse.ffrHardcodeResult)}
                                    </Text>
                                ) : (
                                    <Text style={styles.resultsTableRowElem}>
                                        {getColorForConclusionFfr(results.RadsAnalysisResponse.ffrHardcodeResult)}
                                    </Text>
                                )}
                            </View>

                            {/* ----------------- Calcification result ----------------- */}
                            <View style={styles.resultsTableRow}>
                                <Text style={styles.resultsTableCoronaryRowElemTitle}>
                                    {t('medicalReportForm.calcification')}
                                </Text>
                                <Text style={styles.resultsTableRowElem}>
                                    {getImageCalcificationIndex(results.RadsAnalysisResponse.calcificationResult)}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                {/* ----------------- MPR Images ----------------- */}
                <View style={styles.rowTitleMpr}>
                    <Text>{t('medicalReportForm.mprImages')}</Text>
                </View>
                <View style={styles.smallImageContainer}>
                    {results.Images.map((file: File, index: number) => {
                        return (
                            <View key={index}>
                                {file?.base64 && (
                                    <Image
                                        style={styles.smallImage}
                                        src={file?.base64?.replace(
                                            'data:application/octet-stream;base64',
                                            'data:image/jpeg;base64'
                                        )}
                                    />
                                )}
                            </View>
                        );
                    })}
                </View>
            </>
        );
    };

    return (
        <Document title={'CorEx - ' + t('medicalReportForm.report')} author={'Spimed-AI'}>
            {/* ------------------------- Cover Page ------------------------- */}
            <Page style={styles.coverPage}>
                {renderHeader()}

                {/* ------------------------- Title ------------------------- */}
                <View style={styles.coverPageMainTitle}>
                    <Text>{t('medicalReportForm.corexReport')}</Text>
                </View>
                {/* ------------------------- Patient name, label, date Report of the scan ------------------------- */}
                <View style={styles.analysisInfo}>
                    <Text>{patientName}</Text>

                    {/* {(!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.HYBRID) &&
                        storedAnalysis.label &&
                        storedAnalysis.label.length > 0 && <Text>{storedAnalysis.label}</Text>} */}

                    <Text>{t('medicalReportForm.dateReport', { date: date })}</Text>
                </View>
                {/* ------------------------- Global conclusion based on CAD-RADS ------------------------- */}
                {getBackgroundColorForConclusionCover(getGlobalConclusion())}

                {/* ------------------------- 3D Heart ------------------------- */}
                <View style={styles.coverPageHeartParentpng}>
                    <Image style={styles.coverPageHeartpng} src={memoizedTreePath} />

                    <>
                        <Image style={styles.coverPageHeartpng} src={memoizedRcaCoronaryPath} />
                        <Image style={styles.coverPageHeartpng} src={memoizedCxCoronaryPath} />
                        <Image style={styles.coverPageHeartpng} src={memoizedLadCoronaryPath} />
                    </>
                </View>

                {/* ------------------------- Analysis Id ------------------------- */}
                <View>
                    <Text style={styles.smallTextGrey}>
                        {t('pdf.analysisNumber')}
                        {storedAnalysis.id}
                    </Text>
                </View>

                {/* ------------------------- Spimed-AI Logo ------------------------- */}
                <View>
                    <Image style={styles.coverPageLogo} src={FullLogo} />
                </View>

                {renderFooter()}
            </Page>

            {/* ------------------------- Overall Result Page ------------------------- */}
            <Page bookmark={t('medicalReportForm.overallResult')} style={styles.page}>
                {renderHeader()}
                <View style={styles.tableAllCoronaries}>
                    <View style={styles.rowTitle}>
                        <Text>{t('medicalReportForm.airesults')}</Text>
                        <View style={styles.tableAllCoronariesConclusion}>
                            {getBackgroundColorForConclusion(getGlobalConclusion())}
                        </View>
                    </View>
                    <View style={styles.resultsTable}>
                        <View style={styles.resultsTableRow}>
                            <Text style={styles.resultsTableRowElemTitleOverall}>{''}</Text>
                            {/* ------------------------- RCA name (Global) ------------------------- */}
                            {hasRcaResults && (
                                <Text style={styles.resultsTableRowElemRads}>
                                    {t(rcaResults.RadsAnalysisResponse.coronary)}
                                </Text>
                            )}

                            {/* ------------------------- LAD name (Global) ------------------------- */}
                            {hasLadResults && (
                                <Text style={styles.resultsTableRowElemRads}>
                                    {t(ladResults.RadsAnalysisResponse.coronary)}
                                </Text>
                            )}

                            {/* ------------------------- CX name (Global) ------------------------- */}
                            {hasCxResults && (
                                <Text style={styles.resultsTableRowElemRads}>
                                    {t(cxResults.RadsAnalysisResponse.coronary)}
                                </Text>
                            )}
                        </View>
                        <View style={styles.resultsTableRow}>
                            <Text style={styles.resultsTableRowElemTitleOverall}>
                                {t('medicalReportForm.stenosis')}
                            </Text>
                            {/* ------------------------- RCA result (Global) ------------------------- */}
                            {hasRcaResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {stenosisClassification(rcaResults.RadsAnalysisResponse.classificationResult)}
                                </Text>
                            )}
                            {/* ------------------------- LAD result (Global) ------------------------- */}
                            {hasLadResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {stenosisClassification(ladResults.RadsAnalysisResponse.classificationResult)}
                                </Text>
                            )}
                            {/* ------------------------- CX result (Global) ------------------------- */}
                            {hasCxResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {stenosisClassification(cxResults.RadsAnalysisResponse.classificationResult)}
                                </Text>
                            )}
                        </View>
                        {/* ------------------------- Confidence part ------------------------- */}
                        <View style={styles.resultsTableRow}>
                            <Text style={styles.resultsTableRowElemTitleOverall}>
                                {t('medicalReportForm.confidence')}
                            </Text>
                            {/* ------------------------- RCA confidence (Global) ------------------------- */}
                            {hasRcaResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getColorForConfidence(rcaResults)}
                                </Text>
                            )}
                            {/* ------------------------- LAD confidence (Global) ------------------------- */}
                            {hasLadResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getColorForConfidence(ladResults)}
                                </Text>
                            )}
                            {/* ------------------------- CX confidence (Global) ------------------------- */}
                            {hasCxResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getColorForConfidence(cxResults)}
                                </Text>
                            )}
                        </View>
                        {/* ------------------------- FFR Prediction part ------------------------- */}
                        <View style={styles.resultsTableRow}>
                            <Text style={styles.resultsTableRowElemTitleOverall}>
                                {t('medicalReportForm.ffrPredictionBreak')}
                            </Text>

                            {/* ------------------------- RCA FFR Prediction (Global) ------------------------- */}
                            {hasRcaResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getColorForConclusionFfr(rcaResults.RadsAnalysisResponse.ffrHardcodeResult)}
                                </Text>
                            )}

                            {/* ------------------------- LAD FFR Prediction (Global) ------------------------- */}
                            {hasLadResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getColorForConclusionFfr(ladResults.RadsAnalysisResponse.ffrHardcodeResult)}
                                </Text>
                            )}

                            {/* ------------------------- CX FFR Prediction (Global) ------------------------- */}
                            {hasCxResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getColorForConclusionFfr(cxResults.RadsAnalysisResponse.ffrHardcodeResult)}
                                </Text>
                            )}
                        </View>
                        {/* ------------------------- Calcification row ------------------------- */}
                        <View style={styles.resultsTableRow}>
                            <Text style={styles.resultsTableRowElemTitleOverall}>
                                {t('medicalReportForm.calcification')}
                            </Text>
                            {/* ------------------------- RCA calcification (Global) ------------------------- */}
                            {hasRcaResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getImageCalcificationIndex(rcaResults.RadsAnalysisResponse.calcificationResult)}
                                </Text>
                            )}
                            {/* ------------------------- LAD calcification (Global) ------------------------- */}
                            {hasLadResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getImageCalcificationIndex(ladResults.RadsAnalysisResponse.calcificationResult)}
                                </Text>
                            )}
                            {/* ------------------------- CX calcification (Global) ------------------------- */}
                            {hasCxResults && (
                                <Text style={styles.resultsTableRowElemOverall}>
                                    {getImageCalcificationIndex(cxResults.RadsAnalysisResponse.calcificationResult)}
                                </Text>
                            )}
                        </View>
                    </View>
                </View>
                {/* ------------------------- MPR Images part ------------------------- */}
                <View style={styles.column}>
                    <View style={styles.rowTitle}>
                        <Text>{t('medicalReportForm.mprImages')}</Text>
                    </View>
                    <View style={styles.ctImagesContainerParent}>
                        <View style={styles.ctImagesContainer}>
                            {/* ------------------------- RCA Images part ------------------------- */}
                            {hasRcaResults &&
                                rcaResults.Images[rcaResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64 && (
                                    <View style={styles.ctImagesAndNameParent}>
                                        <Image
                                            style={styles.ctImages}
                                            src={rcaResults.Images[
                                                rcaResults.RadsAnalysisResponse.bestRadsScoreImageIndex
                                            ]?.base64?.replace(
                                                'data:application/octet-stream;base64',
                                                'data:image/jpeg;base64'
                                            )}
                                        />
                                        <View style={styles.ctImagesCoronaryName}>
                                            <Text>{t(rcaResults.RadsAnalysisResponse.coronary)}</Text>
                                        </View>
                                    </View>
                                )}

                            {/* ------------------------- LAD Images part ------------------------- */}
                            {hasLadResults &&
                                ladResults.Images[ladResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64 && (
                                    <View style={styles.ctImagesAndNameParent}>
                                        <Image
                                            style={styles.ctImages}
                                            src={ladResults.Images[
                                                ladResults.RadsAnalysisResponse.bestRadsScoreImageIndex
                                            ]?.base64?.replace(
                                                'data:application/octet-stream;base64',
                                                'data:image/jpeg;base64'
                                            )}
                                        />
                                        <View style={styles.ctImagesCoronaryName}>
                                            <Text>{t(ladResults.RadsAnalysisResponse.coronary)}</Text>
                                        </View>
                                    </View>
                                )}

                            {/* ------------------------- CX Images part ------------------------- */}
                            {hasCxResults &&
                                cxResults.Images[cxResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64 && (
                                    <View style={styles.ctImagesAndNameParent}>
                                        <Image
                                            style={styles.ctImages}
                                            src={cxResults.Images[
                                                cxResults.RadsAnalysisResponse.bestRadsScoreImageIndex
                                            ]?.base64?.replace(
                                                'data:application/octet-stream;base64',
                                                'data:image/jpeg;base64'
                                            )}
                                        />
                                        <View style={styles.ctImagesCoronaryName}>
                                            <Text>{t(cxResults.RadsAnalysisResponse.coronary)}</Text>
                                        </View>
                                    </View>
                                )}
                        </View>
                    </View>
                </View>

                {/* ------------------------- CAD-RADS Classification part ------------------------- */}
                <View style={styles.columnCadRadsConclusion}>
                    <View style={styles.rowTitle}>
                        <Text>{t('medicalReportForm.cadRadsConclusion')}</Text>
                    </View>
                    <View style={styles.classificationImage}>
                        <Image style={styles.conclusionBoard} src={conclusionTablePath} />
                    </View>
                </View>
                {renderDisclaimerPageNumber()}
                {renderFooter()}
            </Page>

            {/* ------------------------- RCA Results part ------------------------- */}
            {rcaResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('medicalReportForm.reportRca')} style={styles.page}>
                    {renderHeader()}
                    <View style={styles.imageContainer}>{filterImage(rcaResults)}</View>
                    {renderDisclaimerPageNumber()}
                    {renderFooter()}
                </Page>
            )}

            {/* ------------------------- LAD Results part ------------------------- */}
            {ladResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('medicalReportForm.reportLad')} style={styles.page}>
                    {renderHeader()}
                    <View style={styles.imageContainer}>{filterImage(ladResults)}</View>
                    {renderDisclaimerPageNumber()}
                    {renderFooter()}
                </Page>
            )}

            {/* ------------------------- CX Results part ------------------------- */}
            {cxResults.RadsAnalysisResponse.coronary !== Coronary.NotSpecified && (
                <Page bookmark={t('medicalReportForm.reportCx')} style={styles.page}>
                    {renderHeader()}
                    <View style={styles.imageContainer}>{filterImage(cxResults)}</View>
                    {renderDisclaimerPageNumber()}
                    {renderFooter()}
                </Page>
            )}
        </Document>
    );
});

export default PdfDocumentNoMedicalProps;

