import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClassificationResult } from '@api';
import { CircularProgress, Typography } from '@/components/atoms';
import { stenosisClassification } from '@/components/organisms/AnalysisReportCompImages';
import { getColorByRads } from '@/utils/ResultsRadsStenosisColors';
import { overrideIntermediateCadRads } from '../CadRadsDisplay';
import HowToRegIcon from '@mui/icons-material/HowToReg';

type Props = {
    removeStenosisText?: boolean;
    /**The corresponding classification result*/
    classificationResult: ClassificationResult;
};

export const ClassificationResultFieldDoctor = (props: Props) => {
    const { removeStenosisText, classificationResult } = props;
    const [result, setResult] = useState<ClassificationResult>(ClassificationResult.NotSet);
    const [visibleStenosisText, setVisibleStenosisText] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setResult(classificationResult);
        if (removeStenosisText) {
            setVisibleStenosisText(false);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setResult(classificationResult);
    }, [classificationResult]);

    return <>{isLoading ? <CircularProgress /> : getColorForClassificationDr(result, visibleStenosisText)}</>;
};
export default ClassificationResultFieldDoctor;

const getColorForClassificationDr = (classRes: ClassificationResult, visibleStenosisText: boolean) => {
    if (classRes != ClassificationResult.NotSet && classRes != ClassificationResult.Error) {
        return (
            <>
                {visibleStenosisText ? (
                    <SDivCenter>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SBlueIcon>
                                <SHowToRegIcon />
                            </SBlueIcon>
                            <Typography chosenColor={getColorByRads(classRes)} lightbold>
                                {overrideIntermediateCadRads(classRes)}
                            </Typography>
                        </div>
                        <SText>{stenosisClassification(classRes)}</SText>
                    </SDivCenter>
                ) : (
                    <SDivLeft>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SBlueIcon>
                                <SHowToRegIcon />
                            </SBlueIcon>
                            <STypography chosenColor={getColorByRads(classRes)} lightbold>
                                {overrideIntermediateCadRads(classRes)}
                            </STypography>
                        </div>
                    </SDivLeft>
                )}
            </>
        );
    }
};

const SDivCenter = styled.div`
    text-align: center;
`;

const SDivLeft = styled.div`
    text-align: start;
`;

const SText = styled.div`
    font-size: 13px;
    color: ${(props) => props.theme.table.contentLightText};
    margin-top: -4px;
    font-weight: 400;
`;

const SBlueIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: inherit;
    width: 18px;
    height: 18px;
    border-radius: 30px;
    background: ${(props) => props.theme.colors.blue};
    color: white;
    margin-right: 3px;
`;

const SHowToRegIcon = styled(HowToRegIcon)`
    font-size: 1rem !important;
`;

const STypography = styled(Typography)`
    && {
        font-size: 12px !important;
    }
`;

