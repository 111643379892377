import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AnalysisExplorerComponent } from '@/components/organisms/AnalysisExplorerComponent';
import { Session } from '@/utils/contexts/SessionContext';

type Props = {
    hideAnalysisTable: boolean;
    isLoading: boolean;
    currentId: number;
    setCurrentId: React.Dispatch<React.SetStateAction<number>>;
    pdfBlob: Blob;
    currentSessionInCookie: Session;
};

type SContainerAnalysisTableProps = {
    width: string;
    mediaWidth: string;
    display: string;
};

export const AnalysisTableMini = (props: Props) => {
    const { hideAnalysisTable, isLoading, currentId, setCurrentId, pdfBlob, currentSessionInCookie } = props;

    const [hiddenInFront, setHiddenInFront] = useState<boolean>(true);

    const [displayWithTimeout, setDisplayWithTimeout] = useState<string>('none');
    const [widthWithTimeout, setWidthWithTimeout] = useState<string>('26vw');

    // Hide or display the mini table with calculated time and width to create a better visual effect
    useEffect(() => {
        if (hideAnalysisTable) {
            setWidthWithTimeout('14vw');
            const timer = setTimeout(() => {
                setDisplayWithTimeout('none');
                // Fix error "MUI: useResizeContainer - The parent DOM element of the data grid has an empty width." by hiding the grid when the mini analysis explorer is closed
                setHiddenInFront(true);
            }, 200);

            return () => clearTimeout(timer);
        } else {
            setHiddenInFront(false); //False
            setDisplayWithTimeout('flex');
            const timer = setTimeout(() => {
                setWidthWithTimeout('26vw');
            }, 10);
            return () => clearTimeout(timer);
        }
    }, [hideAnalysisTable]);

    return (
        <>
            <SContainerAnalysisTable
                width={widthWithTimeout}
                mediaWidth={widthWithTimeout}
                display={displayWithTimeout}
            >
                <AnalysisExplorerComponent
                    columnsNeeded={['id', 'label']}
                    nbPages={30}
                    isLoadingGraphs={isLoading}
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                    pdfBlob={pdfBlob}
                    isHome={false}
                    isHidden={hiddenInFront}
                    currentSessionInCookie={currentSessionInCookie}
                />
            </SContainerAnalysisTable>
        </>
    );
};

export default AnalysisTableMini;

const SContainerAnalysisTable = styled.div<SContainerAnalysisTableProps>`
    display: ${(props: SContainerAnalysisTableProps) => props.display};
    margin-top: 60px;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: width 0.6s ease-in-out; //animation lors du changement de width
    border-radius: 10px;
    z-index: 5;
    top: 0;
    bottom: 0;
    @media (min-width: 800px) {
        width: ${(props: SContainerAnalysisTableProps) => props.width};
    }
    @media (max-width: 950px) {
        width: ${(props: SContainerAnalysisTableProps) => props.mediaWidth};
    }
`;

