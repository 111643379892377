import * as React from 'react';
import styled from 'styled-components';

import { Typography } from '@/components/atoms';

type Props = {
    /**set french language*/
    isFrench: boolean;
};

const TermsText = (props: Props) => {
    const { isFrench } = props;

    return isFrench ? FrenchText() : EnglishText();
};

export default TermsText;

const EnglishText = () => (
    <>
        <STitle variant="h2">Legal Information</STitle>
        <STypographyThin paragraph variant="subtitle2">
            The edition of the site is ensured by SPIMED-AI, a simplified Joint Stock Company capitalized at 14.321
            Euros and whose head office is at 29, rue du Faubourg Saint Jacques - 75014 PARIS, registered at the RCS of
            Paris under the number 853 720 696 and represented by Jean-François PAUL in his capacity as Chairman.
            <br />
            Intracommunity VAT number: FR96853720696
            <br />
            CorEx is hosted by Azure Microsoft, a company located in Microsoft Headquarters One Microsoft Way Redmond,
            WA 98052.
            <br />
            Website: https://azure.microsoft.com
        </STypographyThin>
        <STitle variant="h2">Terms and Conditions of Use</STitle>
        <SItalic variant="subtitle2">Updated on 30th October 2024.</SItalic>
        <Typography variant="subtitle2">Ownership of Site: Agreement to Terms and Conditions of use.</Typography>
        <STypographyThin paragraph variant="subtitle2">
            These Terms and Conditions of Use (hereinafter referred to as “TCU”) apply to the web site CorEx at the web
            address app.coronary-expert.com (referred to as the “Site”).
            <br />
            The purpose of these TCU is to define the contractual relationships and the conditions of access and use of
            the Site by the user (hereinafter referred to as the “User”). These TCU are accessible on the Site under the
            heading "Terms and Conditions of Use".
            <br />
            Please read them carefully and renew their reading each time you visit the Site.
            <br />
            The Use of the Site constitutes acceptance of these TCU.
            <br />
            Please contact us for any information you may need at : support@spimed-ai.com.
        </STypographyThin>
        <Typography variant="subtitle2">DEFINITIONS</Typography>
        <STypographyThin paragraph variant="subtitle2">
            - The terms "Anonymization", "Anonymized", "Anonymous", refer to the process, by the User, of removing
            personal identifiers, that may lead to an individual being identified from an Image as defined below, and
            thus guarantee the respect of the privacy of individuals. In this respect, any information relating to an
            identified or identifiable individual (for example: surname, first name, telephone number, physical address,
            IP address) shall not appear in any way.
            <br />
            <br />
            - The term “Image” refers to Anonymized coronary scanner images of the curvilinear multiplanar
            reconstruction type.
            <br />
            <br />- The term "Use" refers to all of the following acts:
        </STypographyThin>
        <SListItem paragraph variant="subtitle2">
            - Access/authentication to the Site using your access code provided by SPIMED-AI;
            <br />
            - Deposit/loading of Coroscanner Images (curvilinear multiplanar reconstruction), by the User in accordance
            with the functionalities which constitute its framework;
            <br />- Access to an Analysis Report for training and/or communication purposes between health
            professionals.
        </SListItem>
        <Typography variant="subtitle2">OBJECT</Typography>
        <STypographyThin paragraph variant="subtitle2">
            - SPIMED-AI is developing a web-based platform named “CorEx”.
            <br />
            <br />
            - CorEx is an artificial intelligence tool that generates a computer analysis of coronary artery scanner
            Images known as the "Analysis Report" by evaluating the degree of severity.
            <br />
            <br />
            - SPIMED-AI agrees to provide the User with access codes to the CorEx Site on an ex gratia basis.
        </STypographyThin>
        <Typography variant="subtitle2">LIABILITY</Typography>
        <STypographyThin paragraph variant="subtitle2">
            The User will carry out the analyses under his/her sole and entire responsibility. THESE ANALYSES WILL BE CARRIED
            OUT BY THE USER ON ANONYMISED MEDICAL IMAGES IN ORDER TO MAKE IMPOSSIBLE THE IDENTIFICATION OF AN INDIVIDUAL
            AS DEFINED IN 1.2 DEFINITIONS OF THESE TCU HEREBY.
        </STypographyThin>
        <Typography variant="subtitle2">TECHNICAL ADVISORS</Typography>
        <STypographyThin paragraph variant="subtitle2">
            The technical advisors designated by SPIMED-AI for the follow-up of the User experience are:
            <br />
            - THÉOPHILE ADOUARD MAIL: SUPPORT@SPIMED-AI.COM
            <br />
            <br />
            The medical correspondent designated by SPIMED-AI for the follow-up of the Medical Use will be:
            <br />- DOCTOR JEAN-FRANÇOIS PAUL MAIL: JFPAUL@SPIMED-AI.COM
        </STypographyThin>
        <Typography variant="subtitle2">INTELLECTUAL PROPERTY</Typography>
        <STypographyThin paragraph variant="subtitle2">
            The user acknowledges that all patrimonial rights on the Site belongs to SPIMED-AI and that these TCU does
            not involve any transfer of intellectual property rights.
            <br />
            In this respect, all text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos,
            sounds, music, artwork and computer code (collectively, "Content"), including but not limited to the design,
            structure, selection, coordination, expression, "look and feel" and arrangement of such Content, contained
            on the Site is owned by SPIMED-AI, and is protected by trade dress, copyright, patent and trademark laws,
            and various other intellectual property rights and unfair competition laws.
        </STypographyThin>
        <Typography variant="subtitle2">DATA PROCESSING</Typography>
        <STypographyThin paragraph variant="subtitle2">
            SPIMED-AI undertakes that security measures will be implemented to ensure the security of the data
            processed. In this respect, SPIMED-AI undertakes to comply with the legislation in force and, in particular,
            with the General Data Protection Regulation (EU) 2016/679 of the European Parliament and the Council of 27
            April 2016.
            <br />
            SPIMED-AI undertakes to ensure that no personal data may be retained, stored, collected, disseminated or
            recorded, whatever the medium.
            <br />
            For any questions relating to the protection of privacy, please contact: support@spimed-ai.com.
        </STypographyThin>
        <Typography variant="subtitle2">ACCESS TO THE SITE</Typography>
        <STypographyThin paragraph variant="subtitle2">
            The User undertakes to allow access to the Site only to its personnel or any person the User has under
            his/her responsibility as part of his employment for whom such access is necessary, said personnel having
            been informed by the User that the Site is covered by these TCU and having undertaken to use the Site in
            accordance with them. The User assumes the risks associated with the use of his/her login and password. The
            Site declines all responsibility.
        </STypographyThin>
        <Typography variant="subtitle2">RIGHT TO USE THE SITE</Typography>
        <STypographyThin paragraph variant="subtitle2">
            The Site must be used in accordance with the stipulations of these TCU.
            <br />
            <br />
            The User is forbidden to make any direct or indirect provision to a third party, such as by transfer, rental
            or loan.
        </STypographyThin>
        <Typography variant="subtitle2">TERMINATION</Typography>
        <STypographyThin paragraph variant="subtitle2">
            These TCU are concluded solely for the analysis phase of the Site. They will come into
            force on the date of acceptance of this document by the User and can be updated by the manufacturer side 
            without prior acceptation from any other parties.
            <br />
            If, for any reason, we believe that you have not complied with these TCU, we may, at our sole discretion,
            cancel your access to the Site immediately and without prior notice by emailing you at the address you have
            registered stating that the agreement has terminated.
        </STypographyThin>
        <Typography variant="subtitle2">JURISDICTION</Typography>
        <STypographyThin paragraph variant="subtitle2">
            It is expressly understood that any dispute relating to the Use of the Site or to the execution,
            interpretation or validity of TCU shall be subject to French law and the French courts.
            <br />
            In the event of a dispute, the User undertakes to contact SPIMED-AI as a matter of priority in order to
            attempt to resolve amicably any dispute that may arise between the parties. In the absence of conciliation
            within one (1) month, the French courts will have sole jurisdiction.
            <br />
            By pursuing, you agree to the present Terms and Conditions of Use.
        </STypographyThin>
    </>
);

const FrenchText = () => (
    <>
        <STitle variant="h2">MENTIONS LÉGALES</STitle>
        <STypographyThin paragraph variant="subtitle2">
            L’édition du site est assurée par SPIMED-AI, Société par Actions Simplifiées, au capital de 14.321 Euros
            dont le siège est au 29, rue du Faubourg Saint Jacques, 75014 PARIS immatriculée au RCS de Paris sous le
            numéro 853 720 696 et représentée par Jean-François PAUL en sa qualité de Président.
            <br />
            N° de TVA intracommunautaire : FR96853720696 ci-après la « Société ».
            <br />
            L'hébergeur du site CorEx est la société Azure Microsoft, Microsoft Headquarters One Microsoft Way Redmond,
            WA 98052.
            <br />
            Site web : https://azure.microsoft.com
        </STypographyThin>
        <STitle variant="h2">CONDITIONS GÉNÉRALES D’UTILISATION DU SITE CORONARY-EXPERT</STitle>
        <SItalic variant="subtitle2">Mise à jour de ces CGU le 30 octobre 2024.</SItalic>
        <STypographyThin paragraph variant="subtitle2">
            Les présentes Conditions Générales d’Utilisation (ci-après dénommé « CGU ») s'appliquent au site web
            CORONARY-EXPERT à l'adresse app.coronary-expert.com (désigné par le terme le « Site »).
            <br />
            Ces CGU ont pour objet de définir les relations contractuelles, les conditions d’accès et d’utilisation du
            Site par l’utilisateur (ci-après dénommé l’ « Utilisateur»). Les présentes CGU sont accessibles sur le Site
            à la rubrique « CGU ». Veuillez les lire attentivement.
            <br />
            L’Utilisateur est invité à lire avec la plus grande attention le présent document et à renouveler sa lecture
            à chaque fois qu’il consulte le Site.
            <br />
            L’utilisation du Site constitue acceptation desdites Conditions Générales d’Utilisation.
            <br />
            Pour toute question concernant le Site, veuillez nous contacter à l’adresse suivante : support@spimed-ai.com
        </STypographyThin>
        <Typography variant="subtitle2">DEFINITIONS</Typography>
        <STypographyThin paragraph variant="subtitle2">
            - Les termes « Anonymisation », « Anonymisé », « Anonyme », désignent le fait, par l’Utilisateur, de rendre
            impossible l’identification d’une personne à partir d’une Image tel que défini infra, et ainsi garantir le
            respect de la vie privée de personne. A ce titre, toute information se rapportant à une personne physique
            identifiée ou identifiable (par exemple: nom, prénom, numéro de téléphone, adresse physique, adresse IP) ne
            devront en aucune manière apparaître.
            <br />
            <br />
            - Le terme « Image » désigne les Images de scanner coronaire, de type reconstruction multiplanaire
            curviligne, préalablement Anonymisées.
            <br />
            <br />- Le terme « Utilisation » désigne l’ensemble des actes suivants :
        </STypographyThin>
        <SListItem paragraph variant="subtitle2">
            • L’accès/authentification au Site à l’aide des codes d’accès fournis par SPIMED-AI ;
            <br />
            • Le dépôt/chargement d’Images de coroscanner (de type reconstruction multiplanaire curviligne), par
            l’Utilisateur conformément à des fonctionnalités qui en constituent le cadre ;
            <br />• L’accès à un Rapport d’Analyse à fins de formation et/ou de communication entre professionnels de
            santé.
        </SListItem>
        <Typography variant="subtitle2">OBJET</Typography>
        <STypographyThin paragraph variant="subtitle2">
            - SPIMED-AI développe une plateforme en ligne dénommée « CORONARY-EXPERT » ou « CorEx »
            <br />- CorEx est un outil d’intelligence artificielle, qui génère une analyse informatique des Images de
            scanner des artères coronaires dénommée « Rapport d’Analyse », en évaluant leur degré de sévérité, à des
            fins de formation médicale et de communication entre professionnels de santé.
            <br />
            - L’Utilisateur est intéressé par le Site susmentionné, pour les besoins de tests et d’expérimentation en
            tant que PROFESSIONNEL DE SANTE. L’Utilisateur s’engage en outre à obtenir les éventuelles autorisations à
            l’Utilisation décrite dans les présentes CGU éventuellement nécessaires en cas d’activité salariée,
            hospitalière (privée ou publique).
            <br />- SPIMED-AI convient de mettre gratuitement à disposition de l’Utilisateur, des codes d’accès au Site
            CorEx.
            <br />- Ces présentes CGU sont conclues à des fins de test. <br />- Sauf mention expresse figurant dans les
            présentes CGU, tout autre Utilisation est interdite.
        </STypographyThin>
        <Typography variant="subtitle2">RESPONSABILITES</Typography>
        <STypographyThin paragraph variant="subtitle2">
            L’Utilisateur effectuera les analyses sous sa seule et entière responsabilité. CES ANALYSES SERONT REALISES PAR
            L’UTILISATEUR SUR DES IMAGES MEDICALES ANONYMISEES DE FAÇON A NE POUVOIR EN AUCUNE MANIERE IDENTIFIER UNE
            PERSONNE PHYSIQUE COMME DEFINI EN DEFINITIONS DES PRÉSENTES CGU.
        </STypographyThin>
        <Typography variant="subtitle2">CORRESPONDANTS TECHNIQUES</Typography>
        <STypographyThin paragraph variant="subtitle2">
            Le correspondant technique désigné par SPIMED-AI pour le suivi de l’Utilisateur sera : <br />- THÉOPHILE
            ADOUARD MAIL : SUPPORT@SPIMED-AI.COM
            <br /> Le correspondant médical désigné par SPIMED-AI pour le suivi de l’Utilisation médicale sera :<br /> -
            DOCTEUR JEAN-FRANÇOIS PAUL MAIL : JFPAUL@SPIMED-AI.COM
        </STypographyThin>
        <Typography variant="subtitle2">PROPRIÉTÉ INTELLECTUELLE</Typography>
        <STypographyThin paragraph variant="subtitle2">
            L’Utilisateur reconnaît que les droits patrimoniaux sur le Site sont détenus par SPIMED-AI et que les
            présentes CGU n'entraîne aucun transfert de propriété à son profit.
            <br />
            À ce titre, l'ensemble des textes, graphiques, interfaces utilisateur, interfaces visuelles, photographies,
            marques commerciales, logos, sons, musiques, illustrations et codes informatiques (collectivement désignés
            par le terme le « Contenu »), notamment le design, la structure, la sélection, la coordination,
            l'expression, l'aspect et la convivialité, la présentation et l'agencement de ce Contenu, figurant sur le
            Site est détenu par SPIMED-AI, et est protégé par la législation sur l'habillage commercial, les droits
            d'auteur, les brevets et les marques, et diverses autres lois applicables en matière de propriété
            intellectuelle et de concurrence déloyale.
        </STypographyThin>
        <Typography variant="subtitle2">TRAITEMENT DES DONNÉES</Typography>
        <STypographyThin paragraph variant="subtitle2">
            SPIMED-AI s’engage à ce que des mesures de sécurité soient mises en œuvre pour s’assurer de la sécurité des
            données traitées. À ce titre, SPIMED-AI s’engage au respect des législations en vigueur et notamment du
            règlement général (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016, relatif à la
            protection des données.
            <br />
            SPIMED-AI s’engage à ce qu’aucune donnée à caractère personnel ne puissent être conservée, stockée,
            collectée, diffusée ou enregistrée, quel qu’en soit le support.
            <br />
            Pour toute question relative à la protection de la vie privée, veuillez contacter : support@spimed-ai.com.
        </STypographyThin>
        <Typography variant="subtitle2">ACCÈS AU SITE</Typography>
        <STypographyThin paragraph variant="subtitle2">
            L’Utilisateur s'engage à n'autoriser l'accès au Site qu'à son personnel médical ou les personnes que
            l’Utilisateur a sous sa responsabilité dans le cadre de son activité professionnel et pour lequel un tel
            accès est nécessaire, ledit personnel ayant été informé par l’Utilisateur que le Site est couvert par les
            présentes Conditions d’Utilisation et s'étant engagé à utiliser le Site conformément à celles-ci.
            L’Utilisateur assume les risques liés à l'utilisation de son identifiant et mot de passe. Le Site décline
            toute responsabilité.
        </STypographyThin>
        <Typography variant="subtitle2">DROIT D’UTILISATION DU SITE</Typography>
        <STypographyThin paragraph variant="subtitle2">
            Le Site doit être utilisée conformément aux stipulations des présentes CGU. 
            <br />
            <br />
            Il est notamment interdit à l'Utilisateur de procéder à toute mise à disposition, directe ou indirecte au
            bénéfice d'un tiers, notamment par cession, location ou prêt.
        </STypographyThin>
        <Typography variant="subtitle2">DUREE ET RESILIATION</Typography>
        <STypographyThin paragraph variant="subtitle2">
            Les présentes CGU sont conclues uniquement pour la phase d'analyse sur le Site. Ils
            entreront en vigueur à la date d’acceptation du présent document par l’Utilisateur et peuvent être mises 
            à jour par le fabricant sans acceptation des autres parties.
            <br />
            En cas de manquements de l’Utilisateur à l'exécution de ses obligations, SPIMED-AI aura la possibilité de
            résilier les présentes CGU de plein droit, sans préavis ni formalités judiciaires par tout moyen permettant
            d’en accuser réception. Dans cette hypothèse, l’accès au Site est désactivé.
        </STypographyThin>
        <Typography variant="subtitle2">COMPETENCE</Typography>
        <STypographyThin paragraph variant="subtitle2">
            Il est expressément entendu que tout litige lié à l’Utilisation du Site ou à l’exécution, l’interprétation
            ou la validité des présentes Conditions Générales d’Utilisation sera soumis à la loi française et aux
            juridictions françaises.
            <br />
            En cas de litige, l’Utilisateur s’engage à contacter en priorité SPIMED-AI afin de tenter de résoudre à
            l’amiable tout différend susceptible d’intervenir entre les parties. A défaut de conciliation dans le délai
            d’un (1) mois, les tribunaux français seront les seuls compétents.
            <br />
            En poursuivant, vous acceptez les présentes Conditions Générales d’Utilisation.
        </STypographyThin>
    </>
);

const STitle = styled(Typography)`
    display: flex;
    text-decoration: underline;
    justify-content: center;
`;

const SItalic = styled(Typography)`
    font-style: italic;
    && {
        font-weight: 100;
    }
`;

const STypographyThin = styled(Typography)`
    && {
        font-weight: 100;
    }
`;

const SListItem = styled(Typography)`
    && {
        font-weight: 100;
        padding-left: ${(props) => props.theme.getSpacing(15)};
    }
`;

