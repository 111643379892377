import { initialResultRads, ResultsRads } from '@/utils/ResultsRads';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Session } from '@/utils/contexts/SessionContext';
import jwt_decode from 'jwt-decode';
import { useHistory } from 'react-router';

// MUI
import { Dialog, DialogTitle, IconButton, Tooltip as CoreTooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Icons
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import BugReportIcon from '@mui/icons-material/BugReport';

// Components
import { Typography, Loader, ButtonBlue, BarChartSVG, CircularProgress } from '@/components/atoms';
import { File } from '@/components/molecules';
import { Ffr, IStoredAnalysis } from '@api';
import { DownloadExplicabilityFromBack } from '@/services/ImageService';
import { DownloadImagesFromVmBack } from '@/services/VmBlobService';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

// Translation
import { t } from '@/i18n.js';

// Loader
import circleLoader from '@/utils/animations/circle-loader.json';

// Explicability
import { RequestExplicability } from '@/services/ExplicabilityService';
import theme from '@/utils/styles/Theme';

//Screenshot
import html2canvas from 'html2canvas';
import { useScreenshotContext } from '@/utils/contexts/ScreenshotContext';
import { formatBarData } from '@/utils/Graphs';

type Props = {
    storedAnalysis: IStoredAnalysis;
    open: boolean;
    setOpen: (value: boolean) => void;
    updateModal: (value: boolean) => void;
    onClose: (value: string) => void;
    color: string;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    ladResultsExplicability: string;
    setLadExplicability: (value: string) => void;
    cxResultsExplicability: string;
    setCxExplicability: (value: string) => void;
    rcaResultsExplicability: string;
    setRcaExplicability: (value: string) => void;
    ladResultsExplicabilityFFR: string;
    setLadExplicabilityFFR: (value: string) => void;
    cxResultsExplicabilityFFR: string;
    setCxExplicabilityFFR: (value: string) => void;
    rcaResultsExplicabilityFFR: string;
    setRcaExplicabilityFFR: (value: string) => void;
    ffrAvailable: boolean;
    switchExplicability: boolean;
    setSwitchExplicability: (value: boolean) => void;
    currentSessionInCookie: Session;
};

type SArrowDivProps = {
    left: string;
    top: string;
};

type Display = {
    display: string;
};

type Visibility = {
    visibility: string;
};

type ImageWithGraphs = {
    coronaryResults: ResultsRads;
    resultsImage: string;
    coronaryName: string;
    barGraph: JSX.Element;
};

type ImageWithExplicability = {
    coronaryResults: ResultsRads;
    resultImage: string;
    coronaryName: string;
    explicabilityImageRads: string;
    explicabilityImageFFR: string;
};

//Nivo
export const chartKeys = ['0', '1', '2', '3', '4', '5'];
export const barChartIndexBy = 'rad';
export const colors = [
    theme.colors.green,
    theme.colors.yellow,
    theme.colors.orange,
    theme.colors.red,
    theme.colors.darkred,
    theme.colors.strongdarkred
];

export const SliderImages = (props: Props) => {
    const {
        storedAnalysis,
        onClose,
        open,
        setOpen,
        updateModal,
        color,
        ladResults,
        cxResults,
        rcaResults,
        ladResultsExplicability,
        setLadExplicability,
        cxResultsExplicability,
        setCxExplicability,
        rcaResultsExplicability,
        setRcaExplicability,
        ladResultsExplicabilityFFR,
        setLadExplicabilityFFR,
        cxResultsExplicabilityFFR,
        setCxExplicabilityFFR,
        rcaResultsExplicabilityFFR,
        setRcaExplicabilityFFR,
        ffrAvailable,
        switchExplicability,
        setSwitchExplicability,
        currentSessionInCookie
    } = props;
    const classes = useStyles();
    const history = useHistory();

    /* Screenshot */
    const [screenshotContext, updateScreenshot] = useScreenshotContext();
    const [isScreenshotLoading, setIsScreenshotLoading] = useState<boolean>(false);

    const [isExplicabilitySuccessfull, setIsExplicabilitySuccessfull] = useState<boolean>(false);
    const [explicabilityLoading, setExplicabilityLoading] = useState<boolean>(false);
    const [displayExplicabilityError, setDisplayExplicabilityError] = useState<boolean>(false);

    /* Use state pour test des images des coronaires */
    const [ladResultsImage, setLadResultsImage] = useState<string>('');
    const [cxResultsImage, setCxResultsImage] = useState<string>('');
    const [rcaResultsImage, setRcaResultsImage] = useState<string>('');

    //JWT-DECODE Mail
    const authorizedEmail = JSON.parse(
        JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7)))
    ).email;

    /* Boolean si compte a accès à la fonctionnalité explicabilité */
    const explicabilityAccess =
        JSON.parse(JSON.stringify(jwt_decode(currentSessionInCookie.authResponse.token.slice(7))))
            .isExplicabilityAvailable == 'True';

    /* Use state pour rerender l'explicabilité */
    const [imageWithExplicability, setImageWithExplicability] = useState<Array<ImageWithExplicability>>([]);

    /** Does the FFR is calculated ? */
    const ffrCalculated =
        ladResults.RadsAnalysisResponse.ffrResult !== Ffr.Null ||
        cxResults.RadsAnalysisResponse.ffrResult !== Ffr.Null ||
        rcaResults.RadsAnalysisResponse.ffrResult !== Ffr.Null;

    /** JSON array affichage des 3 images avec leur graph */
    let imageWithGraphs: Array<ImageWithGraphs> = [
        {
            coronaryResults: rcaResults,
            resultsImage: rcaResultsImage,
            coronaryName: t('RCA'),
            barGraph: (
                <BarChartSVG
                    data={formatBarData(rcaResults, chartKeys, barChartIndexBy)}
                    keys={chartKeys}
                    indexBy={barChartIndexBy}
                    colors={colors}
                    legendLeft={t('analysis.analysisReportComp.coronaryInformation.graph.left')}
                    legendBottom={t('analysis.analysisReportComp.coronaryInformation.graph.bottom')}
                    maxValue={1}
                />
            )
        },
        {
            coronaryResults: ladResults,
            resultsImage: ladResultsImage,
            coronaryName: t('LAD'),
            barGraph: (
                <BarChartSVG
                    data={formatBarData(ladResults, chartKeys, barChartIndexBy)}
                    keys={chartKeys}
                    indexBy={barChartIndexBy}
                    colors={colors}
                    legendLeft={t('analysis.analysisReportComp.coronaryInformation.graph.left')}
                    legendBottom={t('analysis.analysisReportComp.coronaryInformation.graph.bottom')}
                    maxValue={1}
                />
            )
        },
        {
            coronaryResults: cxResults,
            resultsImage: cxResultsImage,
            coronaryName: t('CX'),
            barGraph: (
                <BarChartSVG
                    data={formatBarData(cxResults, chartKeys, barChartIndexBy)}
                    keys={chartKeys}
                    indexBy={barChartIndexBy}
                    colors={colors}
                    legendLeft={t('analysis.analysisReportComp.coronaryInformation.graph.left')}
                    legendBottom={t('analysis.analysisReportComp.coronaryInformation.graph.bottom')}
                    maxValue={1}
                />
            )
        }
    ];

    /** JSON array images avec explicability */
    useEffect(() => {
        setImageWithExplicability([
            {
                coronaryResults: rcaResults,
                resultImage: rcaResultsImage,
                coronaryName: t('RCA'),
                explicabilityImageRads: rcaResultsExplicability,
                explicabilityImageFFR: rcaResultsExplicabilityFFR
            },
            {
                coronaryResults: ladResults,
                resultImage: ladResultsImage,
                coronaryName: t('LAD'),
                explicabilityImageRads: ladResultsExplicability,
                explicabilityImageFFR: ladResultsExplicabilityFFR
            },
            {
                coronaryResults: cxResults,
                resultImage: cxResultsImage,
                coronaryName: t('CX'),
                explicabilityImageRads: cxResultsExplicability,
                explicabilityImageFFR: cxResultsExplicabilityFFR
            }
        ]);
    }, [
        ladResultsImage,
        cxResultsImage,
        rcaResultsImage,
        ladResultsExplicability,
        cxResultsExplicability,
        rcaResultsExplicability
    ]);

    /** Calcul de l'explicabilité */
    const handleExplicability = async () => {
        setExplicabilityLoading(true);
        const explicabilityService = new RequestExplicability(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        const resExplicability = await explicabilityService.computeExplicability({
            analysisGuid: storedAnalysis.analysisGuid
        });
        if (storedAnalysis.isExplicabilityComputed || resExplicability == 1) {
            let downloadedF;
            if (!process.env.DeploymentMode || process.env.DeploymentMode == ModeConstant.ONPREMISE) {
                const imageService = new DownloadImagesFromVmBack(
                    { token: currentSessionInCookie.authResponse.token },
                    process.env.BackendServiceURL
                );

                downloadedF = await imageService
                    .downloadExplicability({
                        sessionContext: currentSessionInCookie,
                        analysisGuid: storedAnalysis.analysisGuid,
                        isFromPacs: storedAnalysis.isFromPacs
                    })
                    .catch((err: any) => {
                        console.log('err dialoganalysis', err);
                    });
            } else {
                const imageService = new DownloadExplicabilityFromBack(
                    { token: currentSessionInCookie.authResponse.token },
                    process.env.BackendServiceURL
                );
                downloadedF = await imageService.downloadAllBlobs({
                    sessionContext: currentSessionInCookie,
                    analysisId: storedAnalysis.id
                });
            }

            const explicabilityFiles = downloadedF as File[];
            let hasLADExplicabilityFFR = false;
            let hasLADExplicability = false;
            let hasCxExplicabilityFFR = false;
            let hasCxExplicability = false;
            let hasRCAExplicabilityFFR = false;
            let hasRCAExplicability = false;

            for (const explicabilityFile of explicabilityFiles) {
                if (explicabilityFile['fileName'].includes('RCA')) {
                    if (explicabilityFile['fileName'].includes('FFR')) {
                        setRcaExplicabilityFFR(explicabilityFile.base64);
                        hasRCAExplicabilityFFR = true;
                    } else {
                        setRcaExplicability(explicabilityFile.base64);
                        hasRCAExplicability = true;
                    }
                } else if (explicabilityFile['fileName'].includes('LAD')) {
                    if (explicabilityFile['fileName'].includes('FFR')) {
                        setLadExplicabilityFFR(explicabilityFile.base64);
                        hasLADExplicabilityFFR = true;
                    } else {
                        setLadExplicability(explicabilityFile.base64);
                        hasLADExplicabilityFFR = true;
                        hasLADExplicability = true;
                    }
                } else if (explicabilityFile['fileName'].includes('Cx')) {
                    if (explicabilityFile['fileName'].includes('FFR')) {
                        setCxExplicabilityFFR(explicabilityFile.base64);
                        hasCxExplicabilityFFR = true;
                    } else {
                        setCxExplicability(explicabilityFile.base64);
                        hasCxExplicability = true;
                    }
                }
            }
            if (!hasLADExplicabilityFFR) setLadExplicabilityFFR('null');
            if (!hasLADExplicability) setLadExplicability('null');
            if (!hasCxExplicabilityFFR) setCxExplicabilityFFR('null');
            if (!hasCxExplicability) setCxExplicability('null');
            if (!hasRCAExplicabilityFFR) setRcaExplicabilityFFR('null');
            if (!hasRCAExplicability) setRcaExplicability('null');
        } else {
            setLadExplicability('null');
            setCxExplicability('null');
            setRcaExplicability('null');
            setLadExplicabilityFFR('null');
            setCxExplicabilityFFR('null');
            setRcaExplicabilityFFR('null');
        }
        if (resExplicability === 1) {
            setIsExplicabilitySuccessfull(true);
        } else {
            setIsExplicabilitySuccessfull(false);
            setDisplayExplicabilityError(true);
        }
        setExplicabilityLoading(false);
        setSwitchExplicability(true);
    };

    /** Détection de l'explicabilité disponible */
    useEffect(() => {
        if (
            ladResultsExplicability !== 'null' ||
            cxResultsExplicability !== 'null' ||
            rcaResultsExplicability !== 'null'
        ) {
            setIsExplicabilitySuccessfull(true);
        } else {
            setIsExplicabilitySuccessfull(false);
        }
    }, [ladResults, cxResults, rcaResults, storedAnalysis]);

    /** Détection si coronaire nulle */
    useEffect(() => {
        if (!ladResults.Images.length || ladResults.RadsAnalysisResponse.imageScores.length == 0) {
            setLadResultsImage('noImage');
            setLadExplicability('null');
            setLadExplicabilityFFR('null');
        } else if (ladResults.Images.length) {
            setLadResultsImage(ladResults.Images[ladResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64);
        }

        if (!cxResults.Images.length || cxResults.RadsAnalysisResponse.imageScores.length == 0) {
            setCxResultsImage('noImage');
            setCxExplicability('null');
            setCxExplicabilityFFR('null');
        } else if (cxResults.Images.length) {
            setCxResultsImage(cxResults.Images[cxResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64);
        }

        if (!rcaResults.Images.length || rcaResults.RadsAnalysisResponse.imageScores.length == 0) {
            setRcaResultsImage('noImage');
            setRcaExplicability('null');
            setRcaExplicabilityFFR('null');
        } else if (rcaResults.Images.length) {
            setRcaResultsImage(rcaResults.Images[rcaResults.RadsAnalysisResponse.bestRadsScoreImageIndex]?.base64);
        }
    }, [storedAnalysis]);

    /* Screenshot */
    const takeScreen = () => {
        setIsScreenshotLoading(true);
        html2canvas(document.body, {
            allowTaint: true,
            useCORS: true
        }).then((canvas) => {
            const image = canvas.toDataURL('image/png', 0.5);

            updateScreenshot({
                screenString: Object.values({ image })[0]
            });
            setIsScreenshotLoading(false);
            // Redirect to '/bugs/send-email' when screenshot is taken
            history.push('/bugs/send-email');
        });
    };

    return (
        <SDialog
            className={classes.root}
            onClose={onClose}
            open={open}
            maxWidth={'lg'}
            fullWidth={true}
            classes={{ paper: classes.dialog }}
        >
            <DialogTitle>
                <SLeft>
                    <SDivExplicability display={!explicabilityAccess || isExplicabilitySuccessfull ? 'none' : 'flex '}>
                        <SButtonExplicability
                            display={isExplicabilitySuccessfull ? 'none' : 'flex '}
                            onClick={handleExplicability}
                        >
                            <Typography variant="button">
                                {explicabilityLoading ? (
                                    <Loader jsonToUse={circleLoader} width="3rem" />
                                ) : (
                                    t('analysis.analysisReportComp.sliderImages.computeExplicability')
                                )}
                            </Typography>
                        </SButtonExplicability>
                        {displayExplicabilityError ? (
                            <SText>{t('analysis.analysisReportComp.sliderImages.computeExplicabilityError')}</SText>
                        ) : (
                            <></>
                        )}
                    </SDivExplicability>
                    <SDivMargin visibility={isExplicabilitySuccessfull ? 'visible' : 'hidden'}>
                        {explicabilityAccess ? (
                            <ToggleButtonGroup value={switchExplicability} exclusive>
                                <ToggleButton
                                    value={true}
                                    aria-label="left aligned"
                                    onClick={() => setSwitchExplicability(true)}
                                >
                                    {t('analysis.analysisReportComp.sliderImages.explicability')}
                                </ToggleButton>
                                <ToggleButton
                                    value={false}
                                    aria-label="centered"
                                    onClick={() => setSwitchExplicability(false)}
                                >
                                    {t('analysis.analysisReportComp.sliderImages.statistics')}
                                </ToggleButton>
                            </ToggleButtonGroup>
                        ) : (
                            <></>
                        )}
                    </SDivMargin>
                </SLeft>
                <SRight>
                    <SDivMargin visibility="visible">
                        {!isScreenshotLoading ? (
                            <CoreTooltip title={t('navbar.reportBug') || ''}>
                                <IconButton onClick={takeScreen}>
                                    <BugReportIcon />
                                </IconButton>
                            </CoreTooltip>
                        ) : (
                            <CircularProgress />
                        )}
                    </SDivMargin>
                    <SClose
                        onClick={() => {
                            setOpen(false);
                            updateModal(false);
                        }}
                    />
                </SRight>
            </DialogTitle>
            <SDialogContent>
                <SColumn>
                    <SRowGlobal display={!isExplicabilitySuccessfull || !switchExplicability ? 'flex' : 'none'}>
                        {imageWithGraphs.map((item, index) => (
                            <SSubColumn key={index} display={!(item.resultsImage === 'noImage') ? 'flex' : 'none'}>
                                <Typography>{item.coronaryName}</Typography>
                                {
                                    //Avoid 404 error by calling src on noImage, even if it is display none
                                    item.resultsImage == 'noImage' ? (
                                        <></>
                                    ) : (
                                        <SImage3Coronaries src={item.resultsImage} alt="Selected" color={color} />
                                    )
                                }
                                <SBarChart>{item.barGraph}</SBarChart>
                            </SSubColumn>
                        ))}
                    </SRowGlobal>
                    <SRowGlobal display={isExplicabilitySuccessfull && switchExplicability ? 'flex' : 'none'}>
                        {imageWithExplicability.map((item, index) => (
                            <SSubColumn
                                key={index}
                                display={
                                    !(item.coronaryResults === initialResultRads) && item.resultImage !== 'noImage'
                                        ? 'flex'
                                        : 'none'
                                }
                            >
                                <STypography>{item.coronaryName}</STypography>
                                {item.resultImage != 'noImage' ? (
                                    <SImage3Coronaries
                                        src={item.resultImage}
                                        alt="Selected"
                                        title={item.coronaryName + ' selected'}
                                        color={color}
                                    />
                                ) : (
                                    <></>
                                )}
                                {item.explicabilityImageRads != 'null' ? (
                                    <SImage3Coronaries
                                        src={item.explicabilityImageRads}
                                        alt="Heatmap Image"
                                        title={
                                            item.coronaryName +
                                            ': ' +
                                            t('analysis.analysisReportComp.sliderImages.explicability')
                                        }
                                        color={color}
                                    />
                                ) : (
                                    <></>
                                )}
                            </SSubColumn>
                        ))}
                    </SRowGlobal>
                </SColumn>
            </SDialogContent>
        </SDialog>
    );
};

export default SliderImages;

const SDialog = styled(Dialog)`
    box-shadow: 41px 41px 82px #c3c3c3, -41px -41px 82px #fdfdfd;
`;

const SBarChart = styled.div`
    height: 30vh;
    width: 15vw;
    display: flex;
`;

const SDialogContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

const SRowGlobal = styled.div<Display>`
    display: ${(props: Display) => props.display};
    flex-direction: row;
    justify-content: space-evenly;
    margin: ${(props) => props.theme.getSpacing(1)};
    z-index: 10;
`;

const SColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: ${(props) => props.theme.getSpacing(1)};
`;

const SSubColumn = styled.div<Display>`
    display: ${(props: Display) => props.display};
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

const SImage3Coronaries = styled.img`
    border: solid 2px;
    margin: ${(props) => props.theme.getSpacing(3)};
    height: 38vh;
`;

const SClose = styled(CloseRoundedIcon)`
    &:hover {
        cursor: pointer;
    }
`;

const SButtonExplicability = styled(ButtonBlue)`
    display: ${(props: Display) => props.display};
    width: 250px !important;
`;

const SText = styled.div`
    width: 520px;
    margin-left: 20px;
    font-size: 15px;
    color: orange;
`;

const SDivExplicability = styled.div<Display>`
    display: ${(props: Display) => props.display};
`;

const SLeft = styled.div`
    display: flex;
    float: left;
    align-items: center;
    justify-content: space-between;
`;

const SRight = styled.div`
    float: right;
    align-items: center;
    display: inline-flex;
`;

const SDivMargin = styled.div<Visibility>`
    visibility: ${(props: Visibility) => props.visibility};
    display: ${(props: Visibility) => (props.visibility == 'hidden' ? 'none' : '')};
    margin: ${(props) => props.theme.getSpacing(0, 2, 0, 2)};
    align-items: center;
`;

const STypography = styled(Typography)`
    text-align: center;
    align-items: center;
    justify-content: center;
`;

/** Styles */
const useStyles = makeStyles({
    root: {
        '&.MuiDialog-root': {}
    },
    imageList: {
        '&.MuiImageList-root': {
            overflowY: 'clip'
        }
    },
    dialog: {
        height: '90vh'
    }
});

