import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useHistory as useHistoryDom } from 'react-router-dom';

import ReactGA from 'react-ga';
import styled from 'styled-components';
import { InputAdornment } from '@mui/material';

import { ButtonBlueDarkBack, CircularProgress, Loader, Typography } from '@/components/atoms';
import { IsNewVersionDialog, PasswordChangeDialog, TermsDialog } from '@/components/organisms';
import { t } from '@/i18n.js';
import { getSessionCookie, initialSession, useSessionContext } from '@/utils/contexts/SessionContext';
import { AuthenticateRequest, DoctorsListClient, UsersClient } from '@api';

import theme from '@/utils/styles/Theme';

//MUI
import Link from '@mui/material/Link';

// MUI Icons
import LicenseExpireSoonDialog from '@/components/organisms/dialogs/LicenseExpireSoonDialog';
import LicenseExpiredDialog from '@/components/organisms/dialogs/LicenseExpiredDialog';
import MailOutline from '@mui/icons-material/MailOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import jwt_decode from 'jwt-decode';
import { RefreshJwtService } from '@/services/RefreshJwtService';
import { HybridService } from '@/services/HybridService';

// Animations
import loadingAnimation from '@/utils/animations/loading_heart.json';
import { ModeConstant } from '@/utils/constants/deploymentModeSettings';

// IPC Inter-Process Communication
// import { ipcRenderer } from 'electron';

declare const VERSION: String;

type SContainerProps = {
    width: string;
    backgroundColor: string;
    boxShadow: string;
    filter: string;
};

type SVisibilityProps = {
    visibility: string;
};

interface newLoginVersion {
    currentVersion: string;
    userLastLoginVersion: string;
}

const defaultLoginVersionProps = { currentVersion: '', userLastLoginVersion: '' };

export const Login = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [localDbIsLoading, setLocalDbIsLoading] = useState<boolean>(true);
    const [wrongIdentification, setWrongIdentification] = useState<boolean>(false);
    const [newLoginVersion, setNewLoginVersion] = useState<newLoginVersion>(defaultLoginVersionProps);
    const [isCGUOpen, setIsCGUOpen] = useState<boolean>(false);
    const [isNewLoginVersionOpen, setIsNewLoginVersionOpen] = useState<boolean>(false);
    const [isLicenseExpiredOpen, setIsLicenseExpiredOpen] = useState<boolean>(false);
    const [isLicenseExpireSoonOpen, setIsLicenseExpireSoonOpen] = useState<boolean>(false);
    const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);
    const [, login, logout] = useSessionContext();
    const [currentSessionInCookie, updateCurrentSession] = useState(initialSession);

    const currentHistory = useHistory();
    const history = useHistoryDom();

    const [isFormFocused, setIsFormFocused] = useState<boolean>(false);
    const [isEmailInputFilled, setIsEmailInputFilled] = useState<boolean>(false);
    const [isPasswordInputFilled, setIsPasswordInputFilled] = useState<boolean>(false);

    function timeout(delay: number) {
        return new Promise((res) => setTimeout(res, delay));
    }

    // useEffect(() => {
    //     (async function iifeFunction() {
    //         if (process.env.Mode === 'Electron') {
    //             // ipcRenderer.invoke('db-get-all-patient', {}).then((response) => {
    //             //     console.log(response)
    //             // }).catch((error) => {
    //             //     console.error(error);
    //             // })
    //             // const getLocalDbService = new HybridService({ token: '' });
    //             // let isServerUp = (await getLocalDbService.CheckServerUp()) == 200;
    //             // while (!isServerUp) {
    //             //     await timeout(1000);
    //             //     isServerUp = (await getLocalDbService.CheckServerUp()) == 200;
    //             // }
    //             // console.log('SERVER UP - LOCAL DB');
    //             // setLocalDbIsLoading(false);
    //         } else {
    //             setLocalDbIsLoading(false);
    //         }
    //     })();
    // }, []);

    useEffect(() => {
        if (process.env.DeploymentMode && process.env.DeploymentMode == ModeConstant.HYBRID) {
            (async function iifeFunction() {
                setLocalDbIsLoading(true);
                const getLocalDbService = new HybridService({ token: '' });
                let isServerUp = (await getLocalDbService.CheckServerUp()) == 200;
                while (!isServerUp) {
                    await timeout(1000);
                    isServerUp = (await getLocalDbService.CheckServerUp()) == 200;
                }
                console.log('Local DICOM server UP');
                setLocalDbIsLoading(false);
            })();
        } else {
            setLocalDbIsLoading(false);
        }
    }, []);

    const onLogin = async () => {
        if (!localDbIsLoading) {
            setLoading(true);
            setIsEmailInputFilled(false);
            setIsPasswordInputFilled(false);
            setIsFormFocused(false);
            const client = new UsersClient({ token: '' }, process.env.BackendServiceURL);
            const authRequest = new AuthenticateRequest({ email, password });

            await client
                .authenticate(authRequest)
                .then(async (authResponse) => {
                    const doctorsClient = new DoctorsListClient(
                        { token: authResponse.token },
                        process.env.BackendServiceURL
                    );
                    await doctorsClient.getAll().then((docResponse) => {
                        const mysession = login(authResponse, docResponse);

                        setWrongIdentification(false);

                        updateCurrentSession(mysession);

                        // We compare the last version the user used (based on the information stored into the browser)
                        let version = localStorage.getItem('version') || '';
                        let lastLoginVersion =
                            JSON.parse(JSON.stringify(jwt_decode(authResponse.token.slice(7)))).lastLoginVersion ?? '';
                        let lastLoginVersionIsUpToDate = lastLoginVersion == version;

                        setNewLoginVersion({
                            currentVersion: version,
                            userLastLoginVersion: lastLoginVersion
                        });

                        let hasAcceptedTerms =
                            JSON.parse(JSON.stringify(jwt_decode(authResponse.token.slice(7)))).hasAcceptedTerms ==
                            'True';
                        setIsCGUOpen(!hasAcceptedTerms);

                        let isPasswordUpdateRequired =
                            JSON.parse(JSON.stringify(jwt_decode(authResponse.token.slice(7))))
                                .isPasswordUpdateRequired == 'True';
                        setIsPasswordChangeOpen(isPasswordUpdateRequired);

                        let isLicenseExpired =
                            JSON.parse(JSON.stringify(jwt_decode(authResponse.token.slice(7)))).isLicenseExpired ==
                            'True';
                        setIsLicenseExpiredOpen(isLicenseExpired);

                        let isLicenseExpireSoon =
                            JSON.parse(JSON.stringify(jwt_decode(authResponse.token.slice(7)))).isLicenseExpireSoon ==
                                'True' && !isLicenseExpired;
                        setIsLicenseExpireSoonOpen(isLicenseExpireSoon);

                        if (lastLoginVersion === '') {
                            setNewLoginVersion({
                                currentVersion: VERSION.toString(),
                                userLastLoginVersion: VERSION.toString()
                            });
                            lastLoginVersionIsUpToDate = false;
                            setIsNewLoginVersionOpen(true);
                        } else {
                            setIsNewLoginVersionOpen(!lastLoginVersionIsUpToDate);
                        }

                        if (
                            !isLicenseExpired &&
                            !isLicenseExpireSoon &&
                            !isPasswordUpdateRequired &&
                            hasAcceptedTerms &&
                            lastLoginVersionIsUpToDate
                        ) {
                            currentHistory.push('/doctor-selection');
                        }
                    });
                })
                .catch((err) => {
                    setLoading(false);
                    setWrongIdentification(true);
                });
        }
    };

    const onNewVersionCloseUpdateLoginVersion = async () => {
        var sessionInCookie = getSessionCookie();
        updateCurrentSession(sessionInCookie);

        const client = new UsersClient({ token: sessionInCookie.authResponse.token }, process.env.BackendServiceURL);
        await client.updateLastLoginVersion(newLoginVersion.currentVersion).then(async () => {
            const refreshJwtService = new RefreshJwtService(
                { token: currentSessionInCookie.authResponse.token },
                process.env.BackendServiceURL
            );
            await refreshJwtService
                .RefreshJwt({
                    accessToken: currentSessionInCookie.authResponse.token,
                    refreshToken: currentSessionInCookie.authResponse.refreshToken
                })
                .then((authResponse) => {
                    const doctorsClient = new DoctorsListClient(
                        { token: authResponse.token },
                        process.env.BackendServiceURL
                    );
                    doctorsClient.getAll().then((docResponse) => {
                        login(authResponse, docResponse);
                        if (!isLicenseExpiredOpen && !isCGUOpen && !isLicenseExpireSoonOpen) {
                            currentHistory.push('/doctor-selection');
                        }
                    });
                });
        });
        setIsNewLoginVersionOpen(false);
    };

    const onAcceptCGU = async () => {
        var sessionInCookie = getSessionCookie();
        updateCurrentSession(sessionInCookie);

        const client = new UsersClient({ token: sessionInCookie.authResponse.token }, process.env.BackendServiceURL);
        await client.acceptTerms().then(async () => {
            const refreshJwtService = new RefreshJwtService(
                { token: sessionInCookie.authResponse.token },
                process.env.BackendServiceURL
            );
            await refreshJwtService
                .RefreshJwt({
                    accessToken: sessionInCookie.authResponse.token,
                    refreshToken: sessionInCookie.authResponse.refreshToken
                })
                .then((authResponse) => {
                    const doctorsClient = new DoctorsListClient(
                        { token: authResponse.token },
                        process.env.BackendServiceURL
                    );
                    doctorsClient.getAll().then((docResponse) => {
                        login(authResponse, docResponse);
                        if (!isNewLoginVersionOpen) {
                            currentHistory.push('/doctor-selection');
                        }
                    });
                });
        });
        setIsCGUOpen(false);
    };

    const onPasswordChangeClose = async () => {
        var sessionInCookie = getSessionCookie();

        updateCurrentSession(sessionInCookie);
        const refreshJwtService = new RefreshJwtService(
            { token: sessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        await refreshJwtService
            .RefreshJwt({
                accessToken: sessionInCookie.authResponse.token,
                refreshToken: sessionInCookie.authResponse.refreshToken
            })
            .then((authResponse) => {
                const doctorsClient = new DoctorsListClient(
                    { token: authResponse.token },
                    process.env.BackendServiceURL
                );
                doctorsClient.getAll().then((docResponse) => {
                    login(authResponse, docResponse);
                    if (!isLicenseExpiredOpen && !isCGUOpen && !isNewLoginVersionOpen && !isLicenseExpireSoonOpen) {
                        currentHistory.push('/doctor-selection');
                    }
                });
            });
        setIsPasswordChangeOpen(false);
    };

    const onLicenseExpiredClose = () => {
        setIsLicenseExpiredOpen(false);
        if (!isCGUOpen && !isNewLoginVersionOpen && !isPasswordChangeOpen) {
            currentHistory.push('/doctor-selection');
        }
        setLoading(false);
    };

    const onLicenseExpireSoonClose = () => {
        setIsLicenseExpireSoonOpen(false);
        if (!isLicenseExpiredOpen && !isCGUOpen && !isNewLoginVersionOpen && !isPasswordChangeOpen) {
            currentHistory.push('/doctor-selection');
        }
    };

    return (
        <>
            <IsNewVersionDialog
                isOpen={isNewLoginVersionOpen}
                onClose={onNewVersionCloseUpdateLoginVersion}
                userVersion={newLoginVersion.userLastLoginVersion}
                currentVersion={newLoginVersion.currentVersion}
            />
            <TermsDialog isOpen={isCGUOpen} onClose={onAcceptCGU} />
            <LicenseExpiredDialog isOpen={isLicenseExpiredOpen} onClose={onLicenseExpiredClose} />
            <LicenseExpireSoonDialog isOpen={isLicenseExpireSoonOpen} onClose={onLicenseExpireSoonClose} />
            <PasswordChangeDialog
                isOpen={isPasswordChangeOpen}
                isFirstLogin={isPasswordChangeOpen && isCGUOpen}
                onClose={onPasswordChangeClose}
                currentSessionInCookie={currentSessionInCookie}
            />
            <SRowDiv>
                <SContainerMain
                    width={isFormFocused ? '50%' : loading && !isFormFocused ? '98%' : '70%'}
                    backgroundColor={theme.colors.white}
                    boxShadow={''}
                    filter={''}
                >
                    {localDbIsLoading ? (
                        <>
                            <Loader jsonToUse={loadingAnimation} speed={1} height="60vh" width="100%" />
                            <Typography titlelogin="true" darkblue variant="h1">
                                CorEx
                            </Typography>
                            <Typography subtitle="true" darkblue variant="h2">
                                {t('loginPage.loadingDicom')}
                            </Typography>
                            <Typography>{VERSION}</Typography>
                        </>
                    ) : (
                        <>
                            <SImg src={'images/logo_login.png'} />
                            <Typography titlelogin="true" darkblue variant="h1">
                                CorEx
                            </Typography>
                            <Typography subtitle="true" darkblue variant="h2">
                                {t('loginPage.subtitle')}
                            </Typography>
                            <Typography>{VERSION}</Typography>
                        </>
                    )}
                </SContainerMain>
                <SContainer
                    width={isFormFocused ? '50%' : loading && !isFormFocused ? '0%' : '30%'}
                    backgroundColor={theme.colors.darkblue}
                    boxShadow={''}
                    filter={''}
                    onKeyPress={(ev: React.KeyboardEvent) => {
                        if (ev.key === 'Enter') {
                            onLogin();
                            ev.preventDefault();
                        }
                    }}
                >
                    <SContainerLoginForm>
                        <STitle visibility={loading ? 'hidden' : 'visible'}>
                            <Typography white variant="h3">
                                {t('login.signIn')}
                            </Typography>
                        </STitle>
                        <SSubContainer visibility={loading ? 'hidden' : 'visible'}>
                            <STextField
                                id="login-email"
                                required
                                disabled={localDbIsLoading}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                    if (event.target.value !== '') {
                                        setIsEmailInputFilled(true);
                                    } else if (event.target.value === '') {
                                        setIsEmailInputFilled(false);
                                    }
                                }}
                                label={t('login.email')}
                                variant="outlined"
                                onFocus={() => setIsFormFocused(true)}
                                onBlur={() => setIsFormFocused(isEmailInputFilled || isPasswordInputFilled)}
                                InputLabelProps={{ required: false }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SMailOutline />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </SSubContainer>
                        <SSubContainer visibility={loading ? 'hidden' : 'visible'}>
                            <STextField
                                id="login-password"
                                required
                                disabled={localDbIsLoading}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    if (event.target.value !== '') {
                                        setIsPasswordInputFilled(true);
                                    } else if (event.target.value === '') {
                                        setIsPasswordInputFilled(false);
                                    }
                                }}
                                onFocus={() => setIsFormFocused(true)}
                                onBlur={() => setIsFormFocused(isEmailInputFilled || isPasswordInputFilled)}
                                label={t('login.password')}
                                variant="outlined"
                                InputLabelProps={{ required: false }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <SVisibility /> : <SVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </SSubContainer>
                        <SBottomContainer visibility={loading ? 'hidden' : 'visible'}>
                            {wrongIdentification && (
                                <>
                                    <Typography red variant="h3">
                                        {t('login.emailOrPasswordIncorrect')}
                                    </Typography>
                                </>
                            )}
                            <ButtonBlueDarkBack
                                disabled={email === '' || password === '' || loading || localDbIsLoading}
                                onKeyPress={(ev: React.KeyboardEvent<typeof ButtonBlueDarkBack>) => {
                                    if (ev.key === 'Enter') {
                                        onLogin();
                                        ev.preventDefault();
                                    }
                                }}
                                onClick={onLogin}
                            >
                                {!loading ? <>{t('navbar.login')}</> : <CircularProgress />}
                            </ButtonBlueDarkBack>
                            <SLinkDiv>
                                <Typography white variant="h4">
                                    <SLink onClick={() => history.push('/forgot-password')} underline="hover">
                                        {t('login.forgot')}
                                    </SLink>
                                </Typography>
                            </SLinkDiv>
                        </SBottomContainer>
                    </SContainerLoginForm>
                    <SContactContainer visibility={loading ? 'hidden' : 'visible'}>
                        <Typography white variant="h2">
                            <SLinkContact href="mailto:support@spimed-ai.com" underline="hover">
                                {t('login.contact')}
                            </SLinkContact>
                        </Typography>
                        <SDivInformation>
                            <Typography white variant="h2">
                                <SLinkContact onClick={() => history.push('/information')} underline="hover">
                                    {t('login.information')}
                                </SLinkContact>
                            </Typography>
                        </SDivInformation>
                    </SContactContainer>
                </SContainer>
            </SRowDiv>
        </>
    );
};

export default Login;

const SRowDiv = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
    overflow: hidden; /* Hide scrollbars */
`;

const SContainerMain = styled.div<SContainerProps>`
    display: flex;
    width: ${(props: SContainerProps) => props.width};
    background-color: ${(props: SContainerProps) => props.backgroundColor};
    box-shadow: ${(props: SContainerProps) => props.boxShadow};
    filter: ${(props: SContainerProps) => props.filter};
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: width 0.75s; //animation lors du changement de width
    @media (max-width: 700px) {
        display: none;
    }
`;

const SContainer = styled.div<SContainerProps>`
    display: flex;
    width: ${(props: SContainerProps) => props.width};
    background-color: ${(props: SContainerProps) => props.backgroundColor};
    box-shadow: ${(props: SContainerProps) => props.boxShadow};
    filter: ${(props: SContainerProps) => props.filter};
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: width 0.75s; //animation lors du changement de width
    @media (max-width: 700px) {
        width: 100%;
    }
`;

const SContainerLoginForm = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    user-select: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const SSubContainer = styled.div<SVisibilityProps>`
    visibility: ${(props: SVisibilityProps) => props.visibility};
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: ${(props) => props.theme.getSpacing(2, 0, 2, 0)};
`;

const SBottomContainer = styled.div<SVisibilityProps>`
    visibility: ${(props: SVisibilityProps) => props.visibility};
    padding-top: ${(props) => props.theme.getSpacing(5)};
    padding-bottom: ${(props) => props.theme.getSpacing(5)};
    width: 60%;
`;

const STitle = styled.div<SVisibilityProps>`
    visibility: ${(props: SVisibilityProps) => props.visibility};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    left: 0;
    margin: ${(props) => props.theme.getSpacing(0, 0, 6, 2)};
`;

const SMailOutline = styled(MailOutline)`
    color: rgba(255, 255, 255, 0.7);
    padding: ${(props) => props.theme.getSpacing(3)};
`;

const STextField = styled(TextField)`
    color: white;
    width: 60%;
    border-radius: 10px;
    margin: ${(props) => props.theme.getSpacing(2, 0, 2, 0)};
    background: linear-gradient(145deg, #0c386d, #0a2f5c);
    box-shadow: 5px 5px 20px #0a2f5d, -5px -5px 20px #0c396f;
    & .MuiInput-root {
        color: white;
    }
    & label {
        color: rgba(255, 255, 255, 0.7);
    }
    & .MuiTextField-root {
        color: white;
    }
    & label.Mui-focused {
        color: white;
    }
    & .MuiInput-underline {
        border-bottom-color: rgba(255, 255, 255, 0.7);
    }
    & .MuiInput-underline:after {
        border-bottom-color: white;
    }
    & .MuiOutlinedInput-root {
        & input {
            color: white;
            &:-webkit-autofill {
                -webkit-text-fill-color: white;
                caret-color: white;
                box-shadow: 0 0 0 50px #0b3160 inset !important;
                border-radius: 10px 0 0 10px;
            }
            &:-webkit-autofill:hover {
                box-shadow: 0 0 0 50px #0b3160 inset !important;
            }
            &:-webkit-autofill:focus {
                box-shadow: 0 0 0 50px #0b3160 inset !important;
            }
        }
        & fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
        &:hover fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
        &.Mui-focused fieldset {
            border-radius: 10px;
            border-color: rgba(255, 255, 255, 0);
        }
    }
`;

const SImg = styled.img`
    height: 60vh;
    transition: transform 1.2s ease-in-out;
`;

const SLinkDiv = styled.div`
    display: flex;
    margin: ${(props) => props.theme.getSpacing(2, 0, 0, 0)};
`;

const SLink = styled(Link)`
    color: ${(props) => props.theme.colors.electricblue};
`;

const SDivInformation = styled.div`
    margin: ${(props) => props.theme.getSpacing(0, 0, 0, 4)};
`;

const SLinkContact = styled(Link)`
    color: ${(props) => props.theme.colors.white};
`;

const SVisibility = styled(Visibility)`
    color: rgba(255, 255, 255, 0.7);
`;

const SVisibilityOff = styled(VisibilityOff)`
    color: rgba(255, 255, 255, 0.7);
`;

const SContactContainer = styled.div<SVisibilityProps>`
    visibility: ${(props: SVisibilityProps) => props.visibility};
    display: flex;
    justify-content: flex-end;
    width: 90%;
    margin: ${(props) => props.theme.getSpacing(0, 3, 3, 0)};
`;

