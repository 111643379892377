import React, { SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Typography } from '@/components/atoms';
import i18n, { t } from '@/i18n.js';
import { Language } from '@/utils/Language';
import { Select, FormControl, MenuItem } from '@mui/material';

type Props = {
    calcification: string;
    setCalcification: React.Dispatch<SetStateAction<string>>;
};

export const CalcificationRadsMedicalReport = (props: Props) => {
    const { calcification, setCalcification } = props;

    const [localCalcification, setLocalCalcification] = useState<string>('');

    let fr = { lng: Language.French };
    let en = { lng: Language.English };

    const frenchTrad = [
        '',
        t('medicalReportForm.calciumCharge.null', fr),
        t('medicalReportForm.calciumCharge.minimal', fr),
        t('medicalReportForm.calciumCharge.moderate', fr),
        t('medicalReportForm.calciumCharge.severe', fr)
    ];
    const englishTrad = [
        '',
        t('medicalReportForm.calciumCharge.null', en),
        t('medicalReportForm.calciumCharge.minimal', en),
        t('medicalReportForm.calciumCharge.moderate', en),
        t('medicalReportForm.calciumCharge.severe', en)
    ];

    useEffect(() => {
        setLocalCalcification(calcification);
    }, [calcification]); //, authorizedCalcification

    const handleChange = (event: { target: { value: string } }) => {
        setCalcification(event.target.value);
    };

    return (
        <>
            {i18n.language == Language.English && englishTrad.includes(localCalcification) && (
                <SFormControl variant="standard">
                    <Select
                        labelId="calcification-label"
                        id="calcification"
                        value={localCalcification}
                        onChange={handleChange}
                    >
                        <MenuItem value="">
                            <Typography darkgrey>{t('medicalReportForm.calciumCharge.nothing', en)}</Typography>
                        </MenuItem>
                        <MenuItem key={englishTrad[1]} value={englishTrad[1]}>
                            <Typography> {englishTrad[1]}</Typography>
                        </MenuItem>
                        <MenuItem key={englishTrad[2]} value={englishTrad[2]}>
                            <Typography> {englishTrad[2]}</Typography>
                        </MenuItem>
                        <MenuItem key={englishTrad[3]} value={englishTrad[3]}>
                            <Typography> {englishTrad[3]}</Typography>
                        </MenuItem>
                        <MenuItem key={englishTrad[4]} value={englishTrad[4]}>
                            <Typography>{englishTrad[4]}</Typography>
                        </MenuItem>
                    </Select>
                </SFormControl>
            )}
            {i18n.language == Language.French && frenchTrad.includes(localCalcification) && (
                <SFormControl variant="standard">
                    <Select
                        labelId="calcification-label"
                        id="calcification"
                        value={localCalcification}
                        onChange={handleChange}
                    >
                        <MenuItem key="" value="">
                            <Typography darkgrey> {t('medicalReportForm.calciumCharge.nothing', fr)}</Typography>
                        </MenuItem>
                        <MenuItem key={frenchTrad[1]} value={frenchTrad[1]}>
                            <Typography> {frenchTrad[1]}</Typography>
                        </MenuItem>
                        <MenuItem key={frenchTrad[2]} value={frenchTrad[2]}>
                            <Typography> {frenchTrad[2]}</Typography>
                        </MenuItem>
                        <MenuItem key={frenchTrad[3]} value={frenchTrad[3]}>
                            <Typography> {frenchTrad[3]}</Typography>
                        </MenuItem>
                        <MenuItem key={frenchTrad[4]} value={frenchTrad[4]}>
                            <Typography>{frenchTrad[4]}</Typography>
                        </MenuItem>
                    </Select>
                </SFormControl>
            )}
        </>
    );
};

export default CalcificationRadsMedicalReport;

const SFormControl = styled(FormControl)`
    & .MuiFormLabel-root {
        background-color: ${(props) => props.theme.analysisDetails.cardBackground};
        color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.label};
        font-family: 'Inter', sans-serif;
        padding-right: 5px;
    }
    & .MuiInputBase-root {
        color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.inputText};
        & label {
            color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &:hover fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.Mui-focused fieldset {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        &.MuiInput-input {
            border-color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
        & .MuiSelect-icon {
            color: ${(props) => props.theme.analysisDetails.medicalReport.inputFields.borderColor};
        }
    }
`;

