import { createContext } from 'react';
import React from 'react';
import { useContextFactory } from '@/utils/contexts/ContextFactory';
import { AlertColor } from '@mui/material';

export interface Snackbar {
    snackString: string; //bas64
    /**Severity (aspect/color/icon) of the alert
     * error / warning / info / success. Is success by default.
     */
    severity?: AlertColor;
}

export const nullSnackbar = {
    snackString: '',
    severity: undefined
};

export const SnackbarContext = createContext<[Snackbar, (snack: Snackbar) => void]>([nullSnackbar, () => {}]);

export const useSnackbarContext = useContextFactory('SnackbarContext', SnackbarContext);

export const SnackbarContextProvider: React.FC = (props) => {
    const initSnackbar = () => {
        return nullSnackbar;
    };

    const [snackbarInStorage, setSnackbarInStorage] = React.useState<Snackbar>(initSnackbar);

    const updateSnackbar = (snackbar: Snackbar) => {
        if (snackbar.severity == undefined) {
            snackbar.severity = 'success';
        }
        setSnackbarInStorage(snackbar);
    };

    const defaultSnackbarContext: [Snackbar, typeof updateSnackbar] = [snackbarInStorage, updateSnackbar];
    return <SnackbarContext.Provider value={defaultSnackbarContext}>{props.children}</SnackbarContext.Provider>;
};
