import React from 'react';
import i18n from '@/i18n.js';
import { Language } from '@/utils/Language';
import styled from 'styled-components';

import FrenchLabel from '@/img/label_fr.png';
import EnglishLabel from '@/img/label_en.png';
import FrenchLabelSwitzerland from '@/img/label_fr_switzerland.png';
import EnglishLabelSwitzerland from '@/img/label_en_switzerland.png';

export const Information = () => {
    return <>{i18n.language === Language.French ? <>
            <SImg src={FrenchLabel} /> 
            <SImg src={FrenchLabelSwitzerland} />
            <h3>Etiquette Suisse</h3>
        </>
        :
        <>
            <SImg src={EnglishLabel} />
            <SImg src={EnglishLabelSwitzerland} />
            <h3>Switzerland Label</h3>
        </>
    }</>;
};

export default Information;

const SImg = styled.img`
    margin-top: 3vh;
    width: 83vh;
`;

