import { Tooltip } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

type Props = {
    message: string;
};

const Information = (props: Props) => {
    const { message } = props;

    return (
        <STooltip title={message}>
            <SInfoIcon sx={{ fontSize: 15 }} />
        </STooltip>
    );
};

export default Information;

const STooltip = styled(Tooltip)`
    &:hover {
        cursor: help;
    }
`;

const SInfoIcon = styled(InfoIcon)`
    font-size: 0.2rem;
    margin: ${(props) => props.theme.getSpacing(0, 1, 0, 1)};
`;

