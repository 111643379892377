import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

type Props = {
    /**The analysis memo or label to display*/
    text: string;
};

export const MemoLabelField = (props: Props) => {
    const { text } = props;

    return (
        <Tooltip title={text || ''}>
            {text != null && text != '' ? <SMemoLabelContainer>{text}</SMemoLabelContainer> : <></>}
        </Tooltip>
    );
};

export default MemoLabelField;

const SMemoLabelContainer = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: ${(props) => props.theme.buttonDarkShadow.background};
    filter: ${(props) => props.theme.buttonDarkShadow.dropShadowRgba};
    border-radius: ${(props) => props.theme.buttonDarkShadow.borderRadius};
    color: ${(props) => props.theme.buttonDarkShadow.text};
    padding: 8px;
`;

