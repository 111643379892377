import React from 'react';
import { StoredAnalysis } from '@api';
import styled from 'styled-components';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

type Props = {
    id: string;
    storedAnalysis: StoredAnalysis;
};

export const PatientBlock = (props: Props) => {
    const { id, storedAnalysis } = props;

    return (
        <>
            {!storedAnalysis.hasBeenOpened ? (
                <>
                    <SFiberManualRecordIcon fontSize="small" />
                    <SDiv>
                        <SPIDiv>
                            <STextDivNew>{'n° ' + id}</STextDivNew>
                        </SPIDiv>
                    </SDiv>
                </>
            ) : (
                <SDiv>
                    <SPIDivHigh>
                        <STextDiv>{'n° ' + id}</STextDiv>{' '}
                    </SPIDivHigh>
                </SDiv>
            )}
        </>
    );
};
export default PatientBlock;

const SDiv = styled.div`
    display: flex;
    margin: 0;
    border-spacing: 0;
    padding: 0;
    line-height: 15px;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 30px;
    border-radius: 10%;
    padding: 5px 0 5px 5px;
    &:hover {
        cursor: pointer;
        box-shadow: inset 0px 10px 20px 2px ${(props) => props.theme.patientBlock.hoverColor};
    }
`;

const SPIDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const SPIDivHigh = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
`;
const STextDiv = styled.div`
    color: ${(props) => props.theme.table.contentText};
    margin-top: 6px;
`;

const STextDivNew = styled.div`
    color: ${(props) => props.theme.table.contentText};
    margin-top: 6px;
    font-weight: bold;
`;

const SFiberManualRecordIcon = styled(FiberManualRecordIcon)`
    // margin: ${(props) => props.theme.getSpacing(0, 1, 0, 0)};
    margin: 0 7px 0 0;
    color: ${(props) => props.theme.colors.blue};
`;

