import * as React from 'react';
import Lottie from 'react-lottie-player';

type Props = {
    /** Json file used to display the animation */
    jsonToUse: object;
    /**Width of the animation */
    width?: string;
    /** Height of the animation */
    height?: string;
};

export const Animation = (props: Props) => {
    const { jsonToUse, width, height } = props;
    return <Lottie loop={false} animationData={jsonToUse} play style={{ width: width, height: height }} />;
};
export default Animation;
