import React from 'react';
import styled from 'styled-components';

//MaterialUI
import { Tooltip } from '@mui/material';

//Components
import { CoronariesDetails } from '@/components/molecules';
import {
    CadRadsDisplay,
    DownloadPdfField,
    DownloadImagesField,
    DeleteCurrentAnalysis,
    ButtonOutlined
} from '@/components/atoms';

import { ResultsRads } from '@/utils/ResultsRads';
import { IStoredAnalysis } from '@api';
import { t } from '@/i18n.js';
import { Session } from '@/utils/contexts/SessionContext';
import { Props as MedicalProps } from '@/pdf/rads/MedicalReportPage';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { overrideIntermediateCadRads } from '../atoms/CadRadsDisplay';

type Props = {
    medicalReportId: number;
    medicalProps: MedicalProps;
    storedAnalysis: IStoredAnalysis;
    ladResults: ResultsRads;
    cxResults: ResultsRads;
    rcaResults: ResultsRads;
    ffrAvailable: boolean;
    currentSessionInCookie: Session;
    setCurrentSubPageOpened: React.Dispatch<React.SetStateAction<string>>;
    isMedicalReportValidationDisplayed: boolean;
    setIsMedicalReportValidationDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
    displayMedicalReportInPdf: boolean;
    patientNameVar: string;
};

export const AnalysisReportCompDetails = (props: Props) => {
    const {
        medicalReportId,
        medicalProps,
        storedAnalysis,
        ladResults,
        cxResults,
        rcaResults,
        ffrAvailable,
        currentSessionInCookie,
        setCurrentSubPageOpened,
        isMedicalReportValidationDisplayed,
        setIsMedicalReportValidationDisplayed,
        displayMedicalReportInPdf,
        patientNameVar
    } = props;

    return (
        <>
            <SFullContainer>
                <SVibility>
                    <SRowPatient>
                        <SSubColumnPatient>
                            <STypography>Patient n°{storedAnalysis.id}</STypography>
                            <STypography>{storedAnalysis.label}</STypography>
                        </SSubColumnPatient>
                        {medicalReportId > 0 && isMedicalReportValidationDisplayed ? (
                            <Tooltip
                                title={
                                    t('analysis.analysisReportComp.aiResultsAndRads', {
                                        rads: overrideIntermediateCadRads(storedAnalysis.classificationResult)
                                    }) || ''
                                }
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <SBlueIcon>
                                        <SHowToRegIcon />
                                    </SBlueIcon>
                                    <CadRadsDisplay cadRadsValue={medicalProps.classificationResult} />
                                </div>
                            </Tooltip>
                        ) : (
                            <CadRadsDisplay cadRadsValue={storedAnalysis.classificationResult} />
                        )}
                    </SRowPatient>
                    <SCardTop>
                        <SCardStatusAndAction>
                            <SStatus>
                                <SStatusTitle>{t('medicalReportStatus.title')} </SStatusTitle>
                                {medicalReportId > 0 ? (
                                    <SStatusValueCompleted>{t('medicalReportStatus.completed')}</SStatusValueCompleted>
                                ) : (
                                    <SStatusValueAwaiting>{t('medicalReportStatus.awaiting')}</SStatusValueAwaiting>
                                )}
                            </SStatus>
                            <SAction>
                                <SButtonContainer>
                                    <ButtonOutlined
                                        text={
                                            medicalReportId > 0
                                                ? t('medicalReportStatus.action.update')
                                                : t('medicalReportStatus.action.review')
                                        }
                                        onClick={() => setCurrentSubPageOpened('pdf')}
                                        disabled={false}
                                    />
                                </SButtonContainer>
                            </SAction>
                        </SCardStatusAndAction>

                        <SButtonsList>
                            <DownloadImagesField
                                ladResults={ladResults}
                                cxResults={cxResults}
                                rcaResults={rcaResults}
                                storedAnalysis={storedAnalysis}
                                currentSessionInCookie={currentSessionInCookie}
                            />

                            <DownloadPdfField
                                storedAnalysis={storedAnalysis}
                                ladResults={ladResults}
                                cxResults={cxResults}
                                rcaResults={rcaResults}
                                analysisId={storedAnalysis.id}
                                currentSessionInCookie={currentSessionInCookie}
                                medicalReportId={medicalReportId}
                                medicalProps={medicalProps}
                                displayMedicalReportInPdf={displayMedicalReportInPdf}
                                patientNameVar={patientNameVar}
                            />

                            <DeleteCurrentAnalysis
                                analysisId={storedAnalysis.id}
                                currentSessionInCookie={currentSessionInCookie}
                            />
                        </SButtonsList>
                    </SCardTop>

                    <CoronariesDetails
                        medicalReportId={medicalReportId}
                        medicalProps={medicalProps}
                        ladResults={ladResults}
                        cxResults={cxResults}
                        rcaResults={rcaResults}
                        ffrAvailable={ffrAvailable}
                        isMedicalReportValidationDisplayed={isMedicalReportValidationDisplayed}
                        setIsMedicalReportValidationDisplayed={setIsMedicalReportValidationDisplayed}
                        currentSessionInCookie={currentSessionInCookie}
                    />
                </SVibility>
            </SFullContainer>
        </>
    );
};

export default AnalysisReportCompDetails;

const SFullContainer = styled.div`
    height: 92vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 30vw;
`;

const SRowPatient = styled.div`
    height: 5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 1000;
`;

const SSubColumnPatient = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 5px;
`;

const SVibility = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    width: 100%;
    min-width: 17vw;
`;

const SCardTop = styled.div`
    && {
        background-color: ${(props) => props.theme.analysisDetails.cardBackground};
        border-radius: 10px;
        width: 100%;
        margin: 15px 0 15px 0;
    }
`;

const SButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;

const SButtonsList = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0 10px 10px;
    width: 40%;
`;

const SCardStatusAndAction = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
`;

const SStatus = styled.div`
    display: flex;
    flex-direction: row;
`;

const SAction = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const SStatusTitle = styled.div`
    color: ${(props) => props.theme.analysisDetails.status.title};
`;

const SStatusValueCompleted = styled.div`
    margin-left: 10px;
    color: ${(props) => props.theme.analysisDetails.status.value};
`;

const SStatusValueAwaiting = styled.div`
    margin-left: 10px;
    color: ${(props) => props.theme.colors.blue};
`;

const STypography = styled.div`
    font-size: 17px;
    font-weight: 500;
    color: ${(props) => props.theme.analysisDetails.color};
`;

const SBlueIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: inherit;
    width: 22px;
    height: 22px;
    border-radius: 30px;
    background: ${(props) => props.theme.colors.blue};
    color: white;
`;

const SHowToRegIcon = styled(HowToRegIcon)`
    font-size: 1.1rem !important;
`;

