import { File } from '@/components/molecules';
import { cleanLocalStorage, logoutFunc, Session } from '@/utils/contexts/SessionContext';
import { AuthorizedApiBase, IConfig } from '@/../generated/api.client';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type SessionProps = {
    /**Session context containing token and container name */
    sessionContext: Session;
};

type Props = {
    analysisGuid: string;
    isFromPacs: boolean;
    isExplicabilityFiles?: boolean;
} & SessionProps;

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export class DownloadImagesFromVmBack extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    // Download images or images for dicom server, depending on the isFromPacs value of analysis,
    // Can also download explicability images if the non mandatory props "isExplicabilityFiles" is set to true
    async downloadAllBlobs(props: Props) {
        const { sessionContext, analysisGuid, isFromPacs, isExplicabilityFiles } = props;
        const allFiles: File[] = [];

        let backendUrl = '/VmFileStorage/get-files';
        if (isExplicabilityFiles == true) {
            backendUrl = '/VmFileStorage/get-explicability-files';
        }

        if (!isFromPacs) {
            const containerName = sessionContext.authResponse.containerName;
            const url_ =
                process.env.BackendServiceURL +
                `${backendUrl}?containerName=${containerName}&analysisGuid=${analysisGuid}`;

            let options_ = <AxiosRequestConfig>{
                method: 'GET',
                url: url_,
                headers: {
                    Accept: 'application/json'
                }
            };

            var response = await this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                            console.log('Invalid Bearer Token');
                            logoutFunc();
                            cleanLocalStorage();
                            location.reload();
                        }
                        throw 500;
                    } else {
                        throw _error;
                    }
                });

            if (response.data == '') {
                throw 500; // Empty images => error
            }

            for (let item of Object.keys(response.data)) {
                allFiles.push({
                    base64: response.data[item].base64 as string,
                    fileName: response.data[item].fileName,
                    data: null
                });
            }
            return allFiles;
        } else {
            let dicomContainerEnumeration = sessionContext.dicomContainerEnumeration;

            // Split the string by ;
            var dicomList = dicomContainerEnumeration.split(';');

            // Remove any empty strings from the resulting array
            dicomList = dicomList.filter(function (item) {
                return item.trim() !== '';
            });

            if (dicomList.length == 0) {
                console.warn('downloadAllBlobsForDicomServer error: no hospital-aetitle for this user');
                throw 500;
            } else if (dicomList.length == 1) {
                const dicomContainerName = dicomList[0];
                const url_ =
                    this.baseUrl + `${backendUrl}?containerName=${dicomContainerName}&analysisGuid=${analysisGuid}`;

                let options_ = <AxiosRequestConfig>{
                    method: 'GET',
                    url: url_,
                    headers: {
                        Accept: 'application/json'
                    }
                };

                var response = await this.transformOptions(options_)
                    .then((transformedOptions_) => {
                        return this.instance.request(transformedOptions_);
                    })
                    .catch((_error: any) => {
                        if (isAxiosError(_error) && _error.response) {
                            if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                                console.log('Invalid Bearer Token');
                                logoutFunc();
                                cleanLocalStorage();
                                location.reload();
                            }
                            throw 500;
                        } else {
                            throw _error;
                        }
                    });

                if (response.data == '') {
                    // TODO vérifier si c'est response.data ou response.json
                    throw 500; // Empty images => error
                }

                for (let item of Object.keys(response.data)) {
                    allFiles.push({
                        base64: response.data[item].base64 as string,
                        fileName: response.data[item].fileName,
                        data: null
                    });
                }

                return allFiles;
            } else {
                for (const dicomContainerName of dicomList) {
                    const url_ =
                        this.baseUrl + `${backendUrl}?containerName=${dicomContainerName}&analysisGuid=${analysisGuid}`;

                    let options_ = <AxiosRequestConfig>{
                        method: 'GET',
                        url: url_,
                        headers: {
                            Accept: 'application/json'
                        }
                    };

                    var response = await this.transformOptions(options_)
                        .then((transformedOptions_) => {
                            return this.instance.request(transformedOptions_);
                        })
                        .catch((_error: any) => {
                            if (isAxiosError(_error) && _error.response) {
                                if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                                    console.log('Invalid Bearer Token');
                                    logoutFunc();
                                    cleanLocalStorage();
                                    location.reload();
                                }
                                throw 500;
                            } else {
                                throw _error;
                            }
                        });

                    for (let item of Object.keys(response.data)) {
                        allFiles.push({
                            base64: response.data[item].base64 as string,
                            fileName: response.data[item].fileName,
                            data: null
                        });
                    }
                    if (allFiles.length) {
                        return allFiles;
                    }
                }
            }
        }

        if (!allFiles.length) {
            throw 500; // Empty images => error
        }

        return allFiles;
    }

    async downloadExplicability(props: Props) {
        const { sessionContext, analysisGuid, isFromPacs } = props;
        return this.downloadAllBlobs({ sessionContext, analysisGuid, isFromPacs, isExplicabilityFiles: true });
    }

    protected processSend(response: AxiosResponse) {
        const status = response.status;
        if (status === 200) {
            return status;
        } else {
            return 500;
        }
    }
}

