import { cleanLocalStorage, logoutFunc } from '@/utils/contexts/SessionContext';
import { getClassificationIntStringByEnumString } from '@/utils/ResultsRads';
import {
    AuthorizedApiBase,
    ClassificationResult,
    CreateMedicalReportRequest,
    IConfig,
    PostMedicalReportRequest
} from '@api';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

type PropsCreate = {
    createRequest: CreateMedicalReportRequest;
};

type PropsUpdate = {
    updateRequest: PostMedicalReportRequest;
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}

export class SendMedicalReportForm extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(configuration: IConfig, baseUrl?: string, instance?: AxiosInstance) {
        super(configuration);
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    async createMedicalReport(props: PropsCreate) {
        const { createRequest } = props;

        let formData = new FormData();

        formData.append('StoredAnalysisId', createRequest.storedAnalysisId.toString());
        formData.append('Doctor', createRequest.doctor);
        createRequest.techniqueName != undefined ? formData.append('TechniqueName', createRequest.techniqueName) : null;
        createRequest.techniqueContrastAgentQuantityML != undefined
            ? formData.append(
                  'TechniqueContrastAgentQuantityML',
                  createRequest.techniqueContrastAgentQuantityML.toString()
              )
            : null;
        createRequest.techniqueDlpMgyCm != undefined
            ? formData.append('TechniqueDlpMgyCm', createRequest.techniqueDlpMgyCm.toString())
            : null;
        createRequest.indication != undefined ? formData.append('Indication', createRequest.indication) : null;
        createRequest.lad != undefined ? formData.append('LAD', createRequest.lad) : null;
        createRequest.cx != undefined ? formData.append('Cx', createRequest.cx) : null;
        createRequest.rca != undefined ? formData.append('RCA', createRequest.rca) : null;
        createRequest.conclusion != undefined ? formData.append('Conclusion', createRequest.conclusion) : null;
        createRequest.cardiacCavities != undefined
            ? formData.append('CardiacCavities', createRequest.cardiacCavities)
            : null;
        createRequest.mitralValve != undefined ? formData.append('MitralValve', createRequest.mitralValve) : null;
        createRequest.aorticValve != undefined ? formData.append('AorticValve', createRequest.aorticValve) : null;
        createRequest.aorticRoot != undefined ? formData.append('AorticRoot', createRequest.aorticRoot) : null;
        createRequest.classificationResult != undefined
            ? formData.append(
                  'ClassificationResult',
                  getClassificationIntStringByEnumString(createRequest.classificationResult)
              )
            : formData.append(
                  'ClassificationResult',
                  getClassificationIntStringByEnumString(ClassificationResult.NotSet)
              );
        createRequest.pericardium != undefined ? formData.append('Pericardium', createRequest.pericardium) : null;
        createRequest.comments != undefined ? formData.append('Comments', createRequest.comments) : null;

        createRequest.ladCalcification != undefined
            ? formData.append('LADCalcification', createRequest.ladCalcification)
            : null;
        createRequest.cxCalcification != undefined
            ? formData.append('CxCalcification', createRequest.cxCalcification)
            : null;
        createRequest.rcaCalcification != undefined
            ? formData.append('RCACalcification', createRequest.rcaCalcification)
            : null;

        try {
            const url_ = this.baseUrl + `/MedicalReport/create`;

            let options_ = <AxiosRequestConfig>{
                data: formData,
                method: 'POST',
                url: url_,
                headers: {
                    Accept: 'application/json'
                }
            };

            return this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                            console.log('Invalid Bearer Token');
                            logoutFunc();
                            cleanLocalStorage();
                            location.reload();
                        }
                        return _error.response;
                    } else {
                        throw _error;
                    }
                })
                .then((_response: AxiosResponse) => {
                    return this.transformResult(url_, _response, (_response: AxiosResponse) =>
                        this.processSend(_response)
                    );
                });
        } catch (err) {
            return err;
        }
    }

    async updateMedicalReport(props: PropsUpdate) {
        const { updateRequest } = props;

        let formData = new FormData();
        formData.append('Id', updateRequest.id.toString());
        formData.append('StoredAnalysisId', updateRequest.storedAnalysisId.toString());
        formData.append('Doctor', updateRequest.doctor);
        updateRequest.techniqueName != undefined ? formData.append('TechniqueName', updateRequest.techniqueName) : null;
        updateRequest.techniqueContrastAgentQuantityML != undefined
            ? formData.append(
                  'TechniqueContrastAgentQuantityML',
                  updateRequest.techniqueContrastAgentQuantityML.toString()
              )
            : null;
        updateRequest.techniqueDlpMgyCm != undefined
            ? formData.append('TechniqueDlpMgyCm', updateRequest.techniqueDlpMgyCm.toString())
            : null;
        updateRequest.indication != undefined ? formData.append('Indication', updateRequest.indication) : null;
        updateRequest.lad != undefined ? formData.append('LAD', updateRequest.lad) : null;
        updateRequest.cx != undefined ? formData.append('Cx', updateRequest.cx) : null;
        updateRequest.rca != undefined ? formData.append('RCA', updateRequest.rca) : null;
        updateRequest.conclusion != undefined ? formData.append('Conclusion', updateRequest.conclusion) : null;
        updateRequest.classificationResult != undefined
            ? formData.append(
                  'ClassificationResult',
                  getClassificationIntStringByEnumString(updateRequest.classificationResult)
              )
            : formData.append(
                  'ClassificationResult',
                  getClassificationIntStringByEnumString(ClassificationResult.NotSet)
              );
        updateRequest.cardiacCavities != undefined
            ? formData.append('CardiacCavities', updateRequest.cardiacCavities)
            : null;
        updateRequest.mitralValve != undefined ? formData.append('MitralValve', updateRequest.mitralValve) : null;
        updateRequest.aorticValve != undefined ? formData.append('AorticValve', updateRequest.aorticValve) : null;
        updateRequest.aorticRoot != undefined ? formData.append('AorticRoot', updateRequest.aorticRoot) : null;
        updateRequest.pericardium != undefined ? formData.append('Pericardium', updateRequest.pericardium) : null;
        updateRequest.comments != undefined ? formData.append('Comments', updateRequest.comments) : null;

        updateRequest.ladCalcification != undefined
            ? formData.append('LADCalcification', updateRequest.ladCalcification)
            : null;
        updateRequest.cxCalcification != undefined
            ? formData.append('CxCalcification', updateRequest.cxCalcification)
            : null;
        updateRequest.rcaCalcification != undefined
            ? formData.append('RCACalcification', updateRequest.rcaCalcification)
            : null;

        try {
            const url_ = this.baseUrl + `/MedicalReport/update`;

            let options_ = <AxiosRequestConfig>{
                data: formData,
                method: 'POST',
                url: url_,
                headers: {
                    Accept: 'application/json'
                }
            };

            return this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                            console.log('Invalid Bearer Token');
                            logoutFunc();
                            cleanLocalStorage();
                            location.reload();
                        }
                        return _error.response;
                    } else {
                        throw _error;
                    }
                })
                .then((_response: AxiosResponse) => {
                    return this.transformResult(url_, _response, (_response: AxiosResponse) =>
                        this.processSend(_response)
                    );
                });
        } catch (err) {
            return err;
        }
    }

    async remove(storedAnalysisId: number) {
        try {
            const url_ = this.baseUrl + `/MedicalReport/remove?storedAnalysisId=` + storedAnalysisId;

            let options_ = <AxiosRequestConfig>{
                method: 'PATCH',
                url: url_,
                headers: {
                    Accept: 'application/json'
                }
            };

            return this.transformOptions(options_)
                .then((transformedOptions_) => {
                    return this.instance.request(transformedOptions_);
                })
                .catch((_error: any) => {
                    if (isAxiosError(_error) && _error.response) {
                        if (_error.response.headers['www-authenticate']?.includes('Bearer')) {
                            console.log('Invalid Bearer Token');
                            logoutFunc();
                            cleanLocalStorage();
                            location.reload();
                        }
                        return _error.response;
                    } else {
                        throw _error;
                    }
                })
                .then((_response: AxiosResponse) => {
                    return this.transformResult(url_, _response, (_response: AxiosResponse) =>
                        this.processSend(_response)
                    );
                });
        } catch (err) {
            return err;
        }
    }

    protected processSend(response: AxiosResponse) {
        return response;
    }
}

