import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import styled from 'styled-components';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import ReactGA from 'react-ga';

import { ButtonBlue, PasswordField, Typography, CircularProgress, ButtonLDMode } from '@/components/atoms';
import { UsersClient } from '@api';
import { Session } from '@/utils/contexts/SessionContext';
import { PasswordValidation, validatePassword } from '@/utils/PasswordValidation';
import { t } from '@/i18n.js';

type Props = {
    /**Set boolean only after success */
    onSuccess?: Dispatch<SetStateAction<boolean>>;
    currentSessionInCookie: Session;
};
export const PasswordModification = (props: Props) => {
    const { onSuccess, currentSessionInCookie } = props;
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [sucess, setSuccess] = useState<{ isSuccess: boolean; message: string }>({ isSuccess: false, message: '' });
    const [passwordDontMatch, setpasswordDontMatch] = useState(false);
    const [oldAndNewAreSame, setoldAndNewAreSame] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({
        allValid: true,
        mustContainData: [['', false]]
    });

    useEffect(() => {
        if (password !== '') {
            setPasswordValidation(validatePassword(password));
        }
    }, [password, JSON.stringify(passwordValidation)]);

    useEffect(() => {
        setpasswordDontMatch(password !== '' && confirmPassword !== '' && password !== confirmPassword);
    }, [password, confirmPassword]);

    useEffect(() => {
        setoldAndNewAreSame(
            password !== '' &&
                confirmPassword !== '' &&
                currentPassword !== '' &&
                password == currentPassword &&
                confirmPassword == currentPassword
        );
    }, [password, confirmPassword, currentPassword]);

    const onClickModify = () => {
        setLoading(true);
        const client = new UsersClient(
            { token: currentSessionInCookie.authResponse.token },
            process.env.BackendServiceURL
        );
        client
            .changePassword(password, currentPassword)
            .then((user) => {
                setLoading(false);
                ReactGA.event({
                    category: 'Password',
                    action: 'Modify Password'
                });
                if (user.id === 0) {
                    if (password == currentPassword) {
                        setSuccess({ isSuccess: false, message: t('settings.sameOldAndNewPswd') });
                    } else {
                        setSuccess({ isSuccess: false, message: t('settings.wrongCurrentPswd') });
                    }
                } else {
                    setSuccess({ isSuccess: true, message: t('settings.successChangePswd') });
                    setCurrentPassword('');
                    setPassword('');
                    setConfirmPassword('');
                    if (onSuccess) {
                        onSuccess(true);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                setSuccess({ isSuccess: false, message: 'An error occured...' });
            });
    };
    return (
        <>
            <SContainer>
                <SRow>
                    <Typography bold>{t('settings.curPswd')}</Typography>
                    <PasswordField onChange={setCurrentPassword} value={currentPassword} id="password" />
                </SRow>
                <SRow>
                    <Typography bold>{t('settings.newPswd')}</Typography>
                    <PasswordField onChange={setPassword} value={password} id="new-password" />
                    {!passwordValidation.allValid && (
                        <>
                            <Typography blue variant="subtitle2">
                                {t('settings.invalidPswd')}
                            </Typography>
                            {passwordValidation.mustContainData
                                .filter((data) => !data[1])
                                .map((data: [string, boolean], index: number) => (
                                    <SBaseline key={index}>
                                        <ArrowRightIcon />
                                        <Typography red variant="h4">
                                            {t(data[0])}
                                        </Typography>
                                    </SBaseline>
                                ))}
                        </>
                    )}
                </SRow>
                <SRow>
                    <Typography bold>{t('settings.confirmPswd')}</Typography>
                    <PasswordField onChange={setConfirmPassword} value={confirmPassword} id="confirmPassword" />
                    {passwordDontMatch && (
                        <Typography red variant="subtitle2">
                            {t('settings.dontMatchPswd')}
                        </Typography>
                    )}
                    {oldAndNewAreSame && (
                        <Typography red variant="subtitle2">
                            {t('settings.sameOldAndNewPswd')}
                        </Typography>
                    )}
                </SRow>

                <SBottomContainer>
                    <ButtonLDMode
                        disabled={
                            !passwordValidation.allValid ||
                            passwordDontMatch ||
                            oldAndNewAreSame ||
                            currentPassword === '' ||
                            password === '' ||
                            confirmPassword === ''
                        }
                    >
                        {!loading ? (
                            <STypographyButton variant="button" onClick={onClickModify}>
                                {t('settings.updatePswd')}
                            </STypographyButton>
                        ) : (
                            <div style={{ padding: '5px' }}>
                                <CircularProgress />
                            </div>
                        )}
                    </ButtonLDMode>
                    {sucess.message && (
                        <STypography red={!sucess.isSuccess} variant="h4">
                            {t(sucess.message)}
                        </STypography>
                    )}
                </SBottomContainer>
            </SContainer>
        </>
    );
};

export default PasswordModification;

const SContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const SBottomContainer = styled.div`
    margin: ${(props) => props.theme.getSpacing(10, 2, 4, 2)};
`;

const SRow = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => props.theme.getSpacing(2)};
`;

const STypography = styled(Typography)`
    && {
        padding-top: ${(props) => props.theme.getSpacing(2)};
    }
`;

const STypographyButton = styled(Typography)`
    padding: 5px;
    width: 100%;
`;

const SBaseline = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    color: ${(props) => props.theme.colors.blue};
`;

